import * as yup from "yup";

export const resetPasswordSchema = yup.object().shape({
	userName: yup.string().required("User name is required."),
});

export const newPasswordSchema = yup.object().shape({
	password: yup.string().required("Password is required."),
	confirmPassword: yup
		.string()
		.required("Password and confirm password is required.")
		.oneOf(
			[yup.ref("password")],
			"Confirm passwords must be match with password."
		),
});
