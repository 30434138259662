import {
	TableRow,
	TableCell,
	Tooltip,
	Button,
	Card,
	CardContent,
	Typography,
	Collapse,
} from "@mui/material";
import { CrossRedIcon, LocationIcon } from "assests/images";
import React, { useContext, useState } from "react";
import { IHaloHR } from "utility/interfaces";
import { AddEditHaloWorkPlaceModal } from "./add-edit-halo-workplace-modal";
import { createCommonAPICall, openSucessErrorModal } from "utility/helpers";
import haloService from "services/halo-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { WarningModal } from "components";

type ComponentProps = {
	row: IHaloHR;
	isSmallDevice: boolean;
	getHaloWorkplaceInfo: any;
};

export const HaloWorkPlaceTableRow = (props: ComponentProps) => {
	// Extract props
	const { row, isSmallDevice, getHaloWorkplaceInfo } = props;

	// Page level variables
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// State variales
	const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);

	const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);

	const [isForceDeleteWarningOpen, setIsForceDeleteWarningOpen] =
		useState(false);

	const [empCount, setEmpCount] = useState(0);

	// Handle events
	const handleAddEditModalOpen = () => setIsAddEditModalOpen(true);

	const handleAddEditModalClose = () => setIsAddEditModalOpen(false);

	const handleConfirmDelete = async (isForceDelete: boolean) => {
		console.log("delete", row.workPlaceId, isForceDelete);
		const requestBody = {
			workPlaceId: row.workPlaceId,
			isForceDelete: isForceDelete,
		};

		const data = await createCommonAPICall({
			requestBody,
			apiService: haloService.deleteHaloWorkPlace,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			console.log(data);
			if (data.data) {
				setEmpCount(data.data.empCount);
				setIsForceDeleteWarningOpen(true);
			} else {
				openSucessErrorModal(
					setSuccessErrorContext,
					"Success",
					data.message,
					true
				);
				getHaloWorkplaceInfo();
			}
		}
	};

	return (
		<>
			{!isSmallDevice ? (
				<TableRow>
					<TableCell className="min-w-160px">{row.workPlaceName}</TableCell>
					<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
						<Tooltip title="Edit Employee">
							<Button
								id="table-button-edit"
								variant="outlined"
								className="action-btn-primary action-btn"
								style={{ marginRight: 4 }}
								onClick={handleAddEditModalOpen}
							>
								Edit
							</Button>
						</Tooltip>
						<Tooltip title="Delete Employee">
							<Button
								id="table-button-edit"
								variant="outlined"
								className="action-btn-primary action-btn"
								style={{ marginRight: 4 }}
								onClick={() => setIsDeleteWarningOpen(true)}
							>
								Delete
							</Button>
						</Tooltip>
					</TableCell>
				</TableRow>
			) : (
				<Card className="bg-white">
					<div className="card-container">
						<Collapse in={true} timeout="auto" unmountOnExit>
							<CardContent>
								<div>
									<span>
										<img src={LocationIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Work Place Name:&nbsp;
										<span>{row.workPlaceName}</span>
									</Typography>
								</div>
								<div className="table-cell-btn-group">
									<Button variant="outlined" onClick={handleAddEditModalOpen}>
										Edit
									</Button>
									<Button
										variant="outlined"
										onClick={() => setIsDeleteWarningOpen(true)}
									>
										Delete
									</Button>
								</div>
							</CardContent>
						</Collapse>
					</div>
				</Card>
			)}

			{/* Page modals */}
			<AddEditHaloWorkPlaceModal
				isOpen={isAddEditModalOpen}
				handleClose={handleAddEditModalClose}
				workPlaceData={row}
				getWorkPlaceData={getHaloWorkplaceInfo}
			/>

			<WarningModal
				isModalOpen={isDeleteWarningOpen}
				handleOnClickOk={() => handleConfirmDelete(false)}
				handleOnClickCloseModal={() => setIsDeleteWarningOpen(false)}
				title="Confirmation"
				warningMessage="Are you sure you want to delete this WorkPlace?"
				okButtonText="Confirm"
				closeButtonText="Cancel"
				warningIcon={CrossRedIcon}
			/>

			<WarningModal
				isModalOpen={isForceDeleteWarningOpen}
				handleOnClickOk={() => handleConfirmDelete(true)}
				handleOnClickCloseModal={() => setIsForceDeleteWarningOpen(false)}
				title="Confirmation"
				warningMessage={
					"This WorkPlace is associated with " +
					empCount +
					" employees, if you delete this workplace those employees will also be deleted. Are you sure you want to proceed?"
				}
				okButtonText="Confirm"
				closeButtonText="Cancel"
				warningIcon={CrossRedIcon}
			/>
		</>
	);
};
