import { yupResolver } from "@hookform/resolvers/yup";
import {
	Box,
	Button,
	IconButton,
	Modal,
	TextField,
	Typography,
} from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import { CustomIntlTelInput } from "components";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CountryData } from "react-intl-tel-input-18";
import { commonModalStyle, getTypeWiseClassName } from "utility/helpers";
import { getTypeNameByTypeId } from "utility/helpers/dashboard";
import { IRequest } from "utility/interfaces";
import { sendAgreementSchema } from "utility/validations";

type ComponentProps = {
	isSendAgreementModalOpen: boolean;
	sendAgreementModalClose: any;
	handleOnClickSend: any;
	row: IRequest;
};

export function SendAgreementModal(props: ComponentProps) {
	// Page level interface
	interface ISendAgreement {
		phoneNumber: string;
		email: string;
	}

	// Extract Props
	const {
		isSendAgreementModalOpen,
		sendAgreementModalClose,
		handleOnClickSend,
		row,
	} = props;

	// useStates
	const [formValues, setFormValues] = useState<ISendAgreement>({
		phoneNumber: "",
		email: "",
	});
	const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

	const [rerender, setRerender] = useState<number | null>(null);

	const resetFormValue = () => {
		setFormValues({
			phoneNumber: "",
			email: "",
		});
	};

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
	} = useForm({
		values: formValues,
		resolver: yupResolver(sendAgreementSchema),
	});

	// useEffects
	useEffect(() => {
		if (isSendAgreementModalOpen && row.phone && row.email) {
			setFormValues({
				phoneNumber: row.phone,
				email: row.email,
			});
			setRerender(0);
		} else {
			reset();
			clearErrors();
			resetFormValue();
		}
	}, [isSendAgreementModalOpen]);

	useEffect(() => {
		setRerender(1);
	}, [rerender]);

	// Events and functions
	const handleFormValueChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setFormValues({ ...formValues, [e.target.name]: e.target.value });
	};

	const handleSendAgreement = () => {
		handleOnClickSend(formValues);
	};

	const handleModalClose = () => {
		sendAgreementModalClose();
	};

	const handlePhoneNumberChange = (
		isValid: boolean,
		phoneNumber: string,
		countryData: CountryData
	) => {
		setIsPhoneNumberValid(isValid);
		let formattedPhoneNumber =
			phoneNumber != ""
				? phoneNumber.includes("+")
					? phoneNumber
					: `+${countryData.dialCode} ${phoneNumber}`
				: "";
		setFormValues({ ...formValues, phoneNumber: formattedPhoneNumber });
	};

	return (
		<>
			<Modal
				open={isSendAgreementModalOpen}
				onClose={sendAgreementModalClose}
				className="send-modal select-input-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">Send Agreement</Typography>
						<IconButton onClick={handleModalClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<div>
							<div>
								<Typography className="agreement-modal-type">
									{/* TODO: Dynamic setup */}
									<span
										className={getTypeWiseClassName(row.requestTypeId)}
									></span>
									{getTypeNameByTypeId(row.requestTypeId)}
								</Typography>
							</div>
							<Typography variant="body1" className="instruction">
								To Send Agreement please make sure you are updating the correct
								contact information below for the responsible party.
							</Typography>
							{rerender && rerender > 0 && (
								<CustomIntlTelInput
									isPhoneNumberValid={isPhoneNumberValid}
									onChange={handlePhoneNumberChange}
									onBlur={() => {}}
									value={formValues.phoneNumber}
								/>
							)}

							{/* <TextField
								{...register("phoneNumber")}
								name="phoneNumber"
								id="block"
								label="Phone Number"
								type="number"
								value={formValues.phoneNumber}
								onChange={handleFormValueChange}
								fullWidth
								error={errors?.phoneNumber?.message ? true : false}
								helperText={errors?.phoneNumber?.message?.toString()}
							/> */}
							<TextField
								{...register("email")}
								className="mt-24px"
								name="email"
								id="block"
								label="Email"
								value={formValues.email}
								onChange={handleFormValueChange}
								fullWidth
								error={errors?.email?.message ? true : false}
								helperText={errors?.email?.message?.toString()}
							/>
						</div>
						<div className="modal-footer">
							<Button
								variant="contained"
								onClick={() => {
									if (formValues.phoneNumber === "" || !isPhoneNumberValid) {
										setIsPhoneNumberValid(false);
										handleSubmit(() => {})();
									} else {
										handleSubmit(handleSendAgreement)();
									}
								}}
							>
								Send
							</Button>
							<Button variant="outlined" onClick={handleModalClose}>
								Cancel
							</Button>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
