import React from "react";
import { Link, Menu, MenuItem } from "@mui/material";
import {
	AnalysisDarkIcon,
	AnalysisIcon,
	DocnotesIcon,
	DocnotesIconDark,
	ErrorDarkIcon,
	ErrorDarkModeIcon,
	NotesDarkIcon,
	NotesIcon,
	UploadfileIcon,
	UploadfileIconDark,
} from "assests/images";
import { useNavigate } from "react-router-dom";
import {
	IRegionRequest,
	IRegionGridButtons,
	IRegionSearchCriteria,
} from "utility/interfaces";
import { AppRoutings } from "utility/enums/app-routings";
import { getRegionTabStatusWiseName } from "utility/helpers";

type ComponentProps = {
	row: IRegionRequest;
	tableMenuOpen: boolean;
	tableUser: any;
	handleTableMenuClose: any;
	gridButtonsPermissions: IRegionGridButtons;
	searchCriteria: IRegionSearchCriteria;
};

export function RegionTableRowMenu(props: ComponentProps) {
	// Page level variables
	const navigate = useNavigate();

	// Handle events and functions
	const handleOnClickCall = () => {
		console.log(row.phoneNumber);
		handleTableMenuClose();
	};

	// Extract Props
	const {
		row,
		tableMenuOpen,
		tableUser,
		handleTableMenuClose,
		gridButtonsPermissions,
		searchCriteria,
	} = props;

	return (
		<Menu
			id="table-menu"
			anchorEl={tableUser}
			open={tableMenuOpen}
			className="new-table-menu"
			onClose={handleTableMenuClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
		>
			{gridButtonsPermissions.isEdit ? (
				<MenuItem
					onClick={() => {
						navigate(AppRoutings.ViewCase.replace(":caseId", row.queryString), {
							state: {
								searchCriteria,
								backURL: AppRoutings.Region.replace(
									":tabStatus",
									getRegionTabStatusWiseName(
										searchCriteria?.RequestStatusId
									).toLocaleLowerCase()
								),
								isEdit: true,
							},
						});
					}}
				>
					<Link>
						<img src={AnalysisIcon} alt="edit" className="light-icon" />
						<img src={AnalysisDarkIcon} alt="edit" className="dark-icon" />
						Edit
					</Link>
				</MenuItem>
			) : null}
			{gridButtonsPermissions.isCall ? (
				<MenuItem onClick={handleOnClickCall}>
					<Link
						href={
							"tel:" +
							row.phoneNumber
								?.replaceAll("(", "")
								.replaceAll(")", "")
								.replaceAll("-", "")
								.replaceAll(" ", "")
						}
					>
						<img src={UploadfileIcon} alt="call" className="light-icon" />
						<img src={UploadfileIconDark} alt="call" className="dark-icon" />
						Call
					</Link>
				</MenuItem>
			) : null}
			{gridButtonsPermissions.isHouseCall ? (
				<MenuItem>
					<Link>
						<img src={NotesIcon} alt="h-call" className="light-icon" />
						<img src={NotesDarkIcon} alt="h-call" className="dark-icon" />
						House Call
					</Link>
				</MenuItem>
			) : null}
			{gridButtonsPermissions.isCloseCase ? (
				<MenuItem
					onClick={() => {
						navigate(
							AppRoutings.CloseCase.replace(":caseId", row.queryString),
							{
								state: {
									searchCriteria,
									backURL: AppRoutings.Region.replace(
										":tabStatus",
										getRegionTabStatusWiseName(
											searchCriteria?.RequestStatusId
										).toLocaleLowerCase()
									),
								},
							}
						);
					}}
				>
					<Link>
						<img src={ErrorDarkIcon} alt="close-case" className="light-icon" />
						<img
							src={ErrorDarkModeIcon}
							alt="close-case"
							className="dark-icon"
						/>
						Close Case
					</Link>
				</MenuItem>
			) : null}
			{gridButtonsPermissions.isFollowup ? (
				<MenuItem>
					<Link>
						<img src={DocnotesIcon} alt="followup" className="light-icon" />
						<img src={DocnotesIconDark} alt="followup" className="dark-icon" />
						Followup
					</Link>
				</MenuItem>
			) : null}
		</Menu>
	);
}
