import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
	Autocomplete,
	Tooltip,
} from "@mui/material";
import { ArrowBack } from "assests/images";
import { useNavigate, useParams } from "react-router-dom";
import {
	IDropDownList,
	IFillPDFRequest,
	IFormRequest,
	ISaveOrderDetailsRequest,
	IVendor,
} from "utility/interfaces";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { sendOrderSchema } from "utility/validations";
import { AppRoutings } from "utility/enums/app-routings";
import { createCommonAPICall, getTabStatusWiseName } from "utility/helpers";
import dashboardService from "services/dashboard-service";
import { HttpStatusCodes } from "utility/enums/http-status-codes";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { SendOrderPDFModal } from "components";

const Orders: React.FC = () => {
	// Page level interface
	interface ISendOrder {
		requestId: number;
		businessId: number;
		business: string;
		refill: number;
		businessContact: string;
		email: string;
		faxNumber: string;
		prescription: string;
	}

	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// get query parameters
	const { caseId } = useParams();
	// get state value
	const { state } = window.history;

	// useStates
	const [formValues, setFormValues] = useState<ISendOrder>({
		requestId: 0,
		businessId: 0,
		business: "",
		refill: 1,
		businessContact: "",
		email: "",
		faxNumber: "",
		prescription: "",
	});

	const [businessList, setBusinessList] = useState<IDropDownList[]>([
		{
			text: "Please select a profession.",
			value: 0,
		},
	]);
	const [vendors, setVendors] = useState<IVendor[]>([]);
	const [professionList, setProfessionList] = useState<IDropDownList[]>([]);
	const [selectedProfession, setSelectedProfession] = useState<number | null>(
		null
	);
	const [refillList, setRefillList] = useState<IDropDownList[]>([
		{
			text: "Not required",
			value: 1,
		},
		{
			text: "1",
			value: 2,
		},
		{
			text: "2",
			value: 3,
		},
		{
			text: "3",
			value: 4,
		},
		{
			text: "4",
			value: 5,
		},
		{
			text: "5",
			value: 6,
		},
	]);
	const [isSendOrderPDFModalOpen, setIsSendOrderPDFModalOpen] = useState(false);
	const [filePath, setFilePath] = useState("");

	// Handled events and functions
	const getSendOrderInfo = async () => {
		const requestBody: IFormRequest = {
			Id: caseId ? caseId : "",
		};
		const data = await createCommonAPICall({
			requestBody: requestBody,
			apiService: dashboardService.getSendOrderInfo,
			showSuccessMessage: false,
			showErrorMessage: true,
		});

		if (data && data.isSuccessfull) {
			console.log(data);
			setProfessionList(data.data.professionList);
			// setVendors(data.data.vendorNameList);
			// data.data.vendorNameList.map((business: any) => {
			// 	businesses.push({
			// 		text: business.vendorName,
			// 		value: business.vendorId,
			// 	});
			// });
			// setBusinessList(businesses);
			setFormValues({ ...formValues, requestId: data.data.requestId });
		}
	};

	const handleOnFormValueChange = (e: any) => {
		setFormValues({ ...formValues, [e.target.name]: e.target.value });
	};
	const handleOnBusinessValueChange = (value: number | null) => {
		let selectedVendor = vendors.find((e) => e.vendorId == value);
		// let selectedVendor = vendors.find((e) => e.vendorName == value);
		console.log(selectedVendor);
		if (selectedVendor) {
			setFormValues({
				...formValues,
				business: selectedVendor.vendorName,
				faxNumber: selectedVendor.faxNumber,
				email: selectedVendor.email,
				businessContact: selectedVendor.businessContact,
				businessId: selectedVendor.vendorId,
			});
		} else {
			setFormValues({
				requestId: formValues.requestId,
				businessId: 0,
				business: "",
				refill: 1,
				businessContact: "",
				email: "",
				faxNumber: "",
				prescription: "",
			});
		}
	};

	const handleOnClickBackCancel = () =>
		navigate(
			AppRoutings.Dashboard.replace(
				":tabStatus",
				getTabStatusWiseName(
					state?.usr?.searchCriteria?.RequestStatusId
				).toLocaleLowerCase()
			),
			{
				state: {
					searchCriteria: state?.usr?.searchCriteria,
				},
			}
		);
	const handleOnClickSubmit = () => {
		console.log("On click submit : Send order", formValues);
		const requestBody: IFillPDFRequest = {
			RequestId: formValues.requestId,
			VendorId: formValues.businessId,
			VendorName: formValues.business,
			FaxNumber: formValues.faxNumber ?? "",
			BusinessContact: formValues.businessContact ?? "",
			Prescription: formValues.prescription,
			RefillCount: formValues.refill,
			Email: formValues.email ?? "",
		};
		createCommonAPICall({
			requestBody: requestBody,
			apiService: dashboardService.fillPDFToSendOrder,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		}).then((data) => {
			console.log("Recieved Data:", data);
			setFilePath(data.data.filePath);
			setIsSendOrderPDFModalOpen(true);
		});
	};

	const handleClickPDFModalClose = () => setIsSendOrderPDFModalOpen(false);

	const handleSendConfirm = () => {
		const requestBody: ISaveOrderDetailsRequest = {
			RequestId: formValues.requestId,
			VendorId: formValues.businessId,
			VendorName: formValues.business,
			FaxNumber: formValues.faxNumber,
			BusinessContact: formValues.businessContact,
			Email: formValues.email,
			FileName: filePath,
		};
		createCommonAPICall({
			requestBody: requestBody,
			apiService: dashboardService.saveOrderDetails,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		}).then((data) => {
			if (data.statusCode == HttpStatusCodes.Ok) {
				navigate(
					AppRoutings.Dashboard.replace(
						":tabStatus",
						getTabStatusWiseName(
							state?.usr?.searchCriteria?.RequestStatusId
						).toLocaleLowerCase()
					),
					{
						state: {
							searchCriteria: state?.usr?.searchCriteria,
						},
					}
				);
			}
		});
	};

	const getBusinessList = async () => {
		if (selectedProfession && selectedProfession > 0) {
			const data = await createCommonAPICall({
				requestBody: {
					id: selectedProfession.toString(),
				},
				apiService: dashboardService.getBusinessByProfession,
				showErrorMessage: true,
				showSuccessMessage: false,
				setSuccessErrorContext,
			});

			if (data && data.isSuccessfull) {
				let businesses: IDropDownList[] = [];
				setVendors(data.data.vendorNameList);
				if (data.data.vendorNameList.length > 0) {
					data.data.vendorNameList.map((business: any) => {
						businesses.push({
							text: business.vendorName,
							value: business.vendorId,
						});
					});
					setBusinessList(businesses);
				} else {
					setBusinessList([
						{
							text: "Selected Profession does not have any business.",
							value: 0,
						},
					]);

					setFormValues({
						requestId: formValues.requestId,
						businessId: 0,
						business: "",
						refill: 1,
						businessContact: "",
						email: "",
						faxNumber: "",
						prescription: "",
					});
				}
			}
		}
	};

	// Use Effects
	useEffect(() => {
		getSendOrderInfo();
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		getBusinessList();
	}, [selectedProfession]);

	// Page level variables
	const navigate = useNavigate();

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(sendOrderSchema),
	});

	return (
		<main className="main-content">
			<div
				className="overlay"
				onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
			></div>
			<Box className="request-container-box">
				<div className="request-header">
					<Typography variant="h2">Send Order</Typography>
					<Button variant="outlined" onClick={handleOnClickBackCancel}>
						<img src={ArrowBack} alt="arrow" />
						Back
					</Button>
				</div>
				<Box className="request-box">
					<div className="orders-input-container">
						<Grid container columnSpacing={{ sm: 2, lg: 3 }}>
							<Grid item xs={12} sm={6}>
								<FormControl fullWidth>
									<InputLabel id="profession-label">
										Select Profession
									</InputLabel>
									<Select
										labelId="profession-label"
										id="profession"
										value={selectedProfession}
										name="profession"
										label="Select Profession"
										fullWidth
										MenuProps={{ className: "custom-select-dropdown" }}
										onChange={(event) => {
											if (event.target.value !== null)
												setSelectedProfession(
													typeof event.target.value == "number"
														? event.target.value
														: parseInt(event.target.value)
												);
										}}
									>
										{professionList.map((profession) => {
											return (
												<MenuItem
													key={profession.value}
													value={profession.value}
												>
													{profession.text}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Autocomplete
									className="c-autotextbox"
									freeSolo
									options={businessList.map((option) => {
										return { label: option.text, value: option.value };
									})}
									onChange={(event: any, newValue: any) => {
										handleOnBusinessValueChange(newValue?.value);
									}}
									value={{
										label: formValues.business,
										value: formValues.businessId,
									}}
									getOptionDisabled={(option) => option.value === 0}
									renderInput={(params) => (
										<TextField
											{...register("business")}
											{...params}
											label="Business"
											variant="outlined"
											onChange={(e) => {
												setFormValues({
													...formValues,
													business: e.target.value ? e.target.value : "",
												});
											}}
											value={formValues.business}
											error={errors?.business?.message ? true : false}
											helperText={errors?.business?.message?.toString()}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									id="businessContact"
									label="Business contact"
									variant="outlined"
									value={formValues?.businessContact}
									name="businessContact"
									onChange={handleOnFormValueChange}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									id="email"
									{...register("email")}
									label="Email"
									variant="outlined"
									name="email"
									value={formValues?.email}
									onChange={handleOnFormValueChange}
									helperText={errors?.email?.message?.toString()}
									error={errors?.email?.message ? true : false}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									id="fax-num"
									label="Fax Number"
									variant="outlined"
									name="faxNumber"
									value={formValues?.faxNumber}
									onChange={handleOnFormValueChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									{...register("prescription")}
									id="physican-notes"
									label="Prescription or Order details"
									multiline
									className="textarea"
									maxRows={1}
									inputProps={{
										style: {
											minHeight: "80px",
											overflow: "auto",
										},
									}}
									name="prescription"
									onChange={handleOnFormValueChange}
									fullWidth
									value={formValues?.prescription}
									error={errors?.prescription?.message ? true : false}
									helperText={errors?.prescription?.message?.toString()}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormControl fullWidth className="select-input">
									<InputLabel id="refill-label">Number Of Refill</InputLabel>
									<Select
										labelId="refill-label"
										id="refill-name"
										value={formValues?.refill}
										label="Number Of Refill"
										name="refill"
										onChange={handleOnFormValueChange}
										MenuProps={{
											className: "select-input-modal",
										}}
									>
										{refillList?.map((refill) => {
											return (
												<MenuItem key={refill.value} value={refill.value}>
													{refill.text}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</div>
					<div className="request-btn-group orders-btn-group">
						<Button
							variant="contained"
							onClick={handleSubmit(handleOnClickSubmit)}
						>
							<Tooltip title="Submit">
								<>Submit</>
							</Tooltip>
						</Button>
						<Button variant="outlined" onClick={handleOnClickBackCancel}>
							<Tooltip title="Cancel">
								<>Cancel</>
							</Tooltip>
						</Button>
					</div>
				</Box>
			</Box>
			<SendOrderPDFModal
				isPDFModalOpen={isSendOrderPDFModalOpen}
				handleClickPDFModalClose={handleClickPDFModalClose}
				filePath={filePath}
				handleClickSend={handleSendConfirm}
			/>
		</main>
	);
};

export default Orders;
