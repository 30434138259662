import { yupResolver } from "@hookform/resolvers/yup";
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	FormControl,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import { TimeSheetFormTableRow } from "components";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { UserContext } from "contexts/user-context";
import React, { useContext, useEffect, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import timehseetService from "services/timehseet-service";
import { AccountType } from "utility/enums/request-status";
import { createCommonAPICall } from "utility/helpers";
import {
	IBiWeeklyTimesheetInfo,
	IFillTimesheetDetailsInfo,
} from "utility/interfaces";
import { DailyTimesheetSchema } from "utility/validations";

type ComponentProps = {
	biweeklyTimesheetInfo: IBiWeeklyTimesheetInfo;
	getBiWeeklyTimesheetInfo: any;
	isFieldsDisabled?: boolean;
};

type IsFormValid = {
	index: number;
	isValid: boolean;
};

export const BiWeeklyTimesheetTable = (props: ComponentProps) => {
	// Extract props
	const { biweeklyTimesheetInfo, getBiWeeklyTimesheetInfo, isFieldsDisabled } =
		props;

	// Local variables
	const contextUser = useContext(UserContext);
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// State variables
	const [formValuesArr, setFormValuesArr] = useState<
		IFillTimesheetDetailsInfo[]
	>([]);
	const [isChanged, setIsChanged] = useState(false);
	const [isSubmitted, setIsSubmited] = useState(false);
	const [formValuesValidObj, setFormValuesValidObj] = useState<IsFormValid[]>(
		[]
	);
	const [invoiceValues, setInvoiceValues] = useState({
		shiftInvoice: 0,
		nightShiftInvoice: 0,
		housecallInvoice: 0,
		phoneConsultInvoice: 0,
		totalInvoice: 0,
	});

	// Handle events
	const handleOnChangeFormValues = (
		index: number,
		name: string,
		value: any
	) => {
		let changedFormValue = formValuesArr.at(index);
		if (changedFormValue) {
			changedFormValue = { ...changedFormValue, [name]: value };
			let newArr = formValuesArr;
			newArr[index] = changedFormValue;
			setFormValuesArr(newArr);
			setIsChanged(!isChanged);
		}
	};

	const handleClickSave = async () => {
		console.log("valid submit", formValuesArr);

		const requestBody = {
			providerTimesheetViews: formValuesArr,
		};
		console.log(requestBody);
		const data = await createCommonAPICall({
			requestBody,
			apiService: timehseetService.submitTimesheetData,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			getBiWeeklyTimesheetInfo();
		}
		setFormValuesValidObj([]);
		setIsSubmited(false);
	};

	const changeInvoice = () => {
		if (biweeklyTimesheetInfo.timesheets[0]) {
			let totalShiftHours = 0,
				totalNightShift = 0,
				totalHouseCallDuration = 0,
				totalPhoneConsultDuration = 0;
			formValuesArr.forEach((row, index) => {
				if (index > 0) {
					if (row.shiftDuration)
						totalShiftHours += parseInt(row.shiftDuration.toString());
					if (row.weekendHoliday) totalNightShift++;
					if (row.housecallDuration)
						totalHouseCallDuration += parseInt(
							row.housecallDuration.toString()
						);
					if (row.phoneConsultDuration)
						totalPhoneConsultDuration += parseInt(
							row.phoneConsultDuration.toString()
						);
				}
			});
			let firstObj = biweeklyTimesheetInfo.timesheets[0];
			let shiftInvoice = totalShiftHours * firstObj.shiftPayRate;
			let nightShiftInvoice = totalNightShift * firstObj.nightShiftPayRate;
			let housecallInvoice = totalHouseCallDuration * firstObj.housecallPayRate;
			let phoneConsultInvoice =
				totalPhoneConsultDuration * firstObj.phoneConsultPayRate;
			let totalInvoice =
				shiftInvoice +
				nightShiftInvoice +
				housecallInvoice +
				phoneConsultInvoice;

			setInvoiceValues({
				shiftInvoice,
				nightShiftInvoice,
				housecallInvoice,
				phoneConsultInvoice,
				totalInvoice,
			});
		}
	};

	const handleSetFormValidObj = (isValid: boolean, index: number) => {
		if (isValid) {
			let obj = {
				index,
				isValid,
			};
			if (formValuesValidObj.find((x) => x.index === index) === undefined)
				formValuesValidObj.push(obj);
			let newArr = formValuesValidObj.sort((a, b) => {
				return a.index - b.index;
			});
			setFormValuesValidObj(newArr);
			setIsChanged(!isChanged);
		} else {
			if (formValuesValidObj.find((v) => v.index == index)) {
				let newArr = formValuesValidObj.filter((r) => r.index !== index);
				setFormValuesValidObj(newArr);
				setIsChanged(!isChanged);
			}
		}
	};

	// Use effects
	useEffect(() => {
		if (biweeklyTimesheetInfo.timesheets.length > 0) {
			setFormValuesArr(biweeklyTimesheetInfo.timesheets);
			let firstObj = biweeklyTimesheetInfo.timesheets[0];
			setInvoiceValues({
				shiftInvoice: firstObj.shiftInvoice,
				nightShiftInvoice: firstObj.nightShiftInvoice,
				housecallInvoice: firstObj.housecallInvoice,
				phoneConsultInvoice: firstObj.phoneConsultInvoice,
				totalInvoice: firstObj.totalInvoice,
			});
		}
	}, [biweeklyTimesheetInfo]);

	useEffect(() => {
		console.log("Use effect", formValuesArr);
		changeInvoice();
	}, [formValuesArr]);

	useEffect(() => {
		changeInvoice();
		if (isSubmitted && formValuesValidObj.length == formValuesArr.length - 1) {
			handleClickSave();
		} else {
			setIsSubmited(false);
			setFormValuesValidObj([]);
		}
	}, [isSubmitted, isChanged]);

	return (
		<>
			{biweeklyTimesheetInfo.timesheets.length > 0 ? (
				<>
					<TableContainer
						className="upload-table-container"
						sx={{ display: { xs: "none", md: "block" } }}
					>
						<Table className="upload-table">
							<TableHead>
								<TableRow>
									<TableCell className="min-w-100">Date</TableCell>
									<TableCell sx={{ maxWidth: 70 }} className="min-w-70">
										On-call Hours
									</TableCell>
									<TableCell className="min-w-100">Total Hours</TableCell>
									<TableCell sx={{ maxWidth: 70 }} className="min-w-70">
										Weekend/ Holiday
									</TableCell>
									<TableCell className="min-w-100">
										Number of Housecalls
									</TableCell>
									<TableCell className="min-w-100">
										Number of Phone Consults
									</TableCell>
									{contextUser.userRoleId === AccountType.Admin && (
										<TableCell className="min-w-100">Total</TableCell>
									)}
								</TableRow>
							</TableHead>
							<TableBody>
								{biweeklyTimesheetInfo.timesheets.map((row, index) => (
									<>
										{index > 0 && formValuesArr.at(index) && (
											<TimeSheetFormTableRow
												key={index}
												fillTimesheetDetailsInfo={row}
												formValues={formValuesArr[index]}
												handleOnChangeFormValues={(name: string, value: any) =>
													handleOnChangeFormValues(index, name, value)
												}
												isFieldDisabled={isFieldsDisabled}
												isSmallDevice={false}
												isSubmitted={isSubmitted}
												setFormValidObject={(isValid: boolean) => {
													handleSetFormValidObj(isValid, index);
												}}
											/>
										)}
									</>
								))}
								{contextUser.userRoleId === AccountType.Admin &&
									biweeklyTimesheetInfo.timesheets.at(0) && (
										<>
											<TableRow>
												<TableCell>Payrate</TableCell>
												<TableCell></TableCell>
												<TableCell>
													<FormControl sx={{ marginBottom: 0 }}>
														<TextField
															className="form-table-field"
															value={
																biweeklyTimesheetInfo.timesheets.at(0)
																	?.shiftPayRate
															}
															disabled
														/>
													</FormControl>
												</TableCell>
												<TableCell>
													<FormControl sx={{ marginBottom: 0 }}>
														<TextField
															className="form-table-field"
															value={
																biweeklyTimesheetInfo.timesheets.at(0)
																	?.nightShiftPayRate
															}
															disabled
														/>
													</FormControl>
												</TableCell>
												<TableCell>
													<FormControl sx={{ marginBottom: 0 }}>
														<TextField
															className="form-table-field"
															value={
																biweeklyTimesheetInfo.timesheets.at(0)
																	?.housecallPayRate
															}
															disabled
														/>
													</FormControl>
												</TableCell>
												<TableCell>
													<FormControl sx={{ marginBottom: 0 }}>
														<TextField
															className="form-table-field"
															value={
																biweeklyTimesheetInfo.timesheets.at(0)
																	?.phoneConsultPayRate
															}
															disabled
														/>
													</FormControl>
												</TableCell>
												<TableCell></TableCell>
											</TableRow>
											<TableRow>
												<TableCell>Invoice Total</TableCell>
												<TableCell></TableCell>
												<TableCell>
													<FormControl sx={{ marginBottom: 0 }}>
														<TextField
															className="form-table-field"
															value={invoiceValues.shiftInvoice}
															disabled
														/>
													</FormControl>
												</TableCell>
												<TableCell>
													<FormControl sx={{ marginBottom: 0 }}>
														<TextField
															className="form-table-field"
															value={invoiceValues.nightShiftInvoice}
															disabled
														/>
													</FormControl>
												</TableCell>
												<TableCell>
													<FormControl sx={{ marginBottom: 0 }}>
														<TextField
															className="form-table-field"
															value={invoiceValues.housecallInvoice}
															disabled
														/>
													</FormControl>
												</TableCell>
												<TableCell>
													<FormControl sx={{ marginBottom: 0 }}>
														<TextField
															className="form-table-field"
															value={invoiceValues.phoneConsultInvoice}
															disabled
														/>
													</FormControl>
												</TableCell>
												<TableCell>
													<FormControl sx={{ marginBottom: 0 }}>
														<TextField
															className="form-table-field"
															value={invoiceValues.totalInvoice}
															disabled
														/>
													</FormControl>
												</TableCell>
											</TableRow>
										</>
									)}
							</TableBody>
						</Table>
					</TableContainer>
					<Box
						className="tabledata-cards-group custom-card-margin"
						sx={{ display: { xs: "block", md: "none" } }}
					>
						{biweeklyTimesheetInfo.timesheets.map((row, index) => (
							<>
								{index > 0 && formValuesArr.at(index) && (
									<TimeSheetFormTableRow
										key={index}
										fillTimesheetDetailsInfo={row}
										formValues={formValuesArr[index]}
										handleOnChangeFormValues={(name: string, value: any) =>
											handleOnChangeFormValues(index, name, value)
										}
										isFieldDisabled={isFieldsDisabled}
										isSmallDevice={true}
										isSubmitted={isSubmitted}
										setFormValidObject={(isValid: boolean) => {
											handleSetFormValidObj(isValid, index);
										}}
									/>
								)}
							</>
						))}
						{contextUser.userRoleId === AccountType.Admin &&
							biweeklyTimesheetInfo.timesheets.at(0) && (
								<>
									<Card className="bg-white">
										<div className="card-container">
											<CardHeader
												title={<React.Fragment>Payrate</React.Fragment>}
											/>
											<CardContent className="card-header-content">
												<Typography className="timesheet-form-rows">
													Shift Payrate:&nbsp;
													<FormControl sx={{ marginBottom: 0 }}>
														<TextField
															className="form-table-field"
															value={
																biweeklyTimesheetInfo.timesheets.at(0)
																	?.shiftPayRate
															}
															disabled
														/>
													</FormControl>
												</Typography>
												<Typography className="timesheet-form-rows">
													Weekend/Holiday Payrate:&nbsp;
													<FormControl sx={{ marginBottom: 0 }}>
														<TextField
															className="form-table-field"
															value={
																biweeklyTimesheetInfo.timesheets.at(0)
																	?.nightShiftPayRate
															}
															disabled
														/>
													</FormControl>
												</Typography>
												<Typography className="timesheet-form-rows">
													Housecall Payrate:&nbsp;
													<FormControl sx={{ marginBottom: 0 }}>
														<TextField
															className="form-table-field"
															value={
																biweeklyTimesheetInfo.timesheets.at(0)
																	?.housecallPayRate
															}
															disabled
														/>
													</FormControl>
												</Typography>
												<Typography className="timesheet-form-rows">
													Phone Consult Payrate:&nbsp;
													<FormControl sx={{ marginBottom: 0 }}>
														<TextField
															className="form-table-field"
															value={
																biweeklyTimesheetInfo.timesheets.at(0)
																	?.phoneConsultPayRate
															}
															disabled
														/>
													</FormControl>
												</Typography>
											</CardContent>
										</div>
									</Card>
									<Card className="bg-white">
										<div className="card-container">
											<CardHeader
												title={<React.Fragment>Total Invoice</React.Fragment>}
											/>
											<CardContent className="card-header-content">
												<Typography className="timesheet-form-rows">
													Shift Invoice:&nbsp;
													<FormControl sx={{ marginBottom: 0 }}>
														<TextField
															className="form-table-field"
															value={invoiceValues.shiftInvoice}
															disabled
														/>
													</FormControl>
												</Typography>
												<Typography className="timesheet-form-rows">
													Weeked/Holiday Invoice:&nbsp;
													<FormControl sx={{ marginBottom: 0 }}>
														<TextField
															className="form-table-field"
															value={invoiceValues.nightShiftInvoice}
															disabled
														/>
													</FormControl>
												</Typography>
												<Typography className="timesheet-form-rows">
													Housecall Invoice:&nbsp;
													<FormControl sx={{ marginBottom: 0 }}>
														<TextField
															className="form-table-field"
															value={invoiceValues.housecallInvoice}
															disabled
														/>
													</FormControl>
												</Typography>
												<Typography className="timesheet-form-rows">
													Phone Consult Invoice:&nbsp;
													<FormControl sx={{ marginBottom: 0 }}>
														<TextField
															className="form-table-field"
															value={invoiceValues.phoneConsultInvoice}
															disabled
														/>
													</FormControl>
												</Typography>
												<Typography className="timesheet-form-rows">
													Total:&nbsp;
													<FormControl sx={{ marginBottom: 0 }}>
														<TextField
															className="form-table-field"
															value={invoiceValues.totalInvoice}
															disabled
														/>
													</FormControl>
												</Typography>
											</CardContent>
										</div>
									</Card>
								</>
							)}
					</Box>
					<br />
					<div className="request-btn-group">
						<Button
							variant="contained"
							onClick={() => setIsSubmited(true)}
							disabled={
								biweeklyTimesheetInfo.timesheets[0].isAdminApproved ||
								(biweeklyTimesheetInfo.timesheets.some(
									(t) => t.isSubmitted !== undefined && t.isSubmitted == true
								) &&
									contextUser.userRoleId === AccountType.Physician)
							}
						>
							Submit
						</Button>
					</div>
				</>
			) : (
				<Typography variant="h5" className="no-record" sx={{ padding: 3 }}>
					No Record(s) Found
				</Typography>
			)}
		</>
	);
};
