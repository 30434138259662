import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { createCommonAPICall } from "utility/helpers";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import {
	IDropDownList,
	IAddUpdateBusiness,
	IVendorDetails,
} from "utility/interfaces";
import { AddBusinessForm } from "components";
import vendorService from "services/vendor-service";
import { useParams } from "react-router";

const UpdateBusiness: React.FC = () => {
	// get query parameters
	const businessId = useParams()?.businessId || "";

	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// handled method(s)
	const setUpFormValues = (details: IVendorDetails) => {
		const professionTypes: IDropDownList[] = [];
		details.professionsList.forEach((profession) => {
			const professionList: IDropDownList = {
				value: profession.value,
				text: profession.text,
			};
			professionTypes.push(professionList);
		});
		setFormValues({
			vendorId: details.vendor.vendorId,
			vendorName: details.vendor.vendorName,
			profession: details.vendor.profession,
			faxNumber: details.vendor.faxNumber,
			phoneNumber: details.vendor.phoneNumber,
			email: details.vendor.email,
			businessContact: details.vendor.businessContact,
			street: details.vendor.street,
			city: details.vendor.city,
			state: details.vendor.state,
			zip: details.vendor.zip,
			professionTypes,
		});
		setRenderer(1);
	};

	// useStates
	const [formValues, setFormValues] = React.useState<IAddUpdateBusiness>({
		vendorName: "",
		profession: 0,
		faxNumber: "",
		phoneNumber: "",
		email: "",
		businessContact: null,
		street: null,
		city: null,
		state: null,
		zip: null,
	});

	const [renderer, setRenderer] = useState(0);

	const getUpdateVendorDetails = async () => {
		const data = await createCommonAPICall({
			requestBody: businessId,
			apiService: vendorService.getVendorDetails,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.data) setUpFormValues(data.data);
	};

	// useEffect
	useEffect(() => {
		getUpdateVendorDetails();
	}, []);

	return (
		<Box>
			<AddBusinessForm
				formValues={formValues}
				setFormValues={setFormValues}
				title="Update Business"
				renderer={renderer}
			/>
		</Box>
	);
};

export default UpdateBusiness;
