import { Box, Button, Modal, Typography } from "@mui/material";
import { WarningIcon } from "assests/images";
import React from "react";
import { successErrorModalStyle } from "utility/helpers";

type ComponentProps = {
	isModalOpen: boolean;
	handleOnClickCloseModal: any;
	handleOnClickOk: any;
	warningMessage: string;
	title: string;
	okButtonText: string | undefined;
	closeButtonText: string | undefined;
	warningIcon?: any;
};

export function WarningModal(props: ComponentProps) {
	// Extract Props
	const {
		isModalOpen,
		handleOnClickCloseModal,
		title,
		warningMessage,
		okButtonText,
		closeButtonText,
		handleOnClickOk,
		warningIcon,
	} = props;

	// Events and functions
	const handleOnClickOkButton = () => {
		handleOnClickOk();
		handleOnClickCloseModal();
	};

	return (
		<>
			<Modal
				open={isModalOpen}
				onClose={handleOnClickCloseModal}
				className="reset-modal"
			>
				<Box sx={successErrorModalStyle}>
					<img
						src={warningIcon ? warningIcon : WarningIcon}
						alt="warning"
						className="reset-img"
					/>
					<Typography variant="h2">{title}</Typography>
					<Typography variant="body1">{warningMessage}</Typography>
					<div className="warning-modal-btn-group">
						{okButtonText && (
							<Button variant="contained" onClick={handleOnClickOkButton}>
								{okButtonText}
							</Button>
						)}
						{closeButtonText && (
							<Button variant="outlined" onClick={handleOnClickCloseModal}>
								{closeButtonText}
							</Button>
						)}
					</div>
				</Box>
			</Modal>
		</>
	);
}
