import React, { useContext, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	Box,
	Button,
	Chip,
	FormControl,
	FormHelperText,
	IconButton,
	InputLabel,
	MenuItem,
	Modal,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { ChipDeleteIcon, CloseWhiteIcon } from "assests/images";
import { useForm } from "react-hook-form";
import { commonModalStyle, createCommonAPICall } from "utility/helpers";
import { cancelCaseSchema } from "utility/validations";
import dashboardService from "services/dashboard-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { ICancelCaseInfo, ICancelCaseRequest } from "utility/interfaces";

type ComponentProps = {
	cancelCaseModalOpen: boolean;
	cancelCaseModalClose: any;
	queryString: string;
	requestId: number;
	requestStatus: number;
};
const ITEM_HEIGHT = 32;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
	className: "select-input-modal custom-select-dropdown",
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
		},
	},
};

export function CancelCaseModal(props: ComponentProps) {
	// Page level interface
	interface ICancelCase {
		reasons: string[];
		note: string;
		physicianNote: string;
	}

	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// useStates
	const [formValues, setFormValues] = useState<ICancelCase>({
		reasons: [],
		note: "",
		physicianNote: "",
	});
	const [cancelCaseModalInfo, setCancelCaseModalInfo] =
		// useState<ICancelCaseInfo | null>(null);
		useState<ICancelCaseInfo>({
			requestId: 0,
			fullName: "",
			selectedCaseTags: "",
			notes: "",
			physicianNotes: "",
			adminCaseTags: [],
		});

	// Extract Props
	const {
		cancelCaseModalOpen,
		cancelCaseModalClose,
		queryString,
		requestId,
		requestStatus,
	} = props;

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		reset,
		setValue,
	} = useForm({
		resolver: yupResolver(cancelCaseSchema),
	});

	// Events and functions
	const handleOnChangeNote = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setFormValues({ ...formValues, note: e.target.value });
	};
	const handleDeleteSelectedReason = (value: string) => {
		setFormValues({
			...formValues,
			reasons: formValues.reasons.filter((reason) => reason !== value),
		});
	};
	const handleOnCancelReasonChange = (event: SelectChangeEvent<string[]>) => {
		console.log(event);
		const selectedReason = event.target.value as string[];
		console.log(formValues);
		let tempReasons: string[] = [];
		selectedReason.forEach((x) => {
			tempReasons.push(x.toString());
		});

		setFormValues({
			...formValues,
			reasons: tempReasons.filter(
				(item, index) => tempReasons.indexOf(item) === index
			),
		});
	};
	const getCancelCaseModalInfo = async () => {
		const data = await createCommonAPICall({
			requestBody: { requestId: queryString },
			apiService: dashboardService.getCancelCaseModalInfo,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.isSuccessfull && data.data) {
			// console.log("Here", data.data);
			setCancelCaseModalInfo(data.data);
			setFormValues({
				note: data.data?.notes || "",
				reasons: data.data?.selectedCaseTags?.split(",") || [],
				physicianNote: data.data?.physicianNotes || "",
			});
			return;
		}
		cancelCaseModalClose(false);
	};
	const handleOnClickConfirm = async () => {
		const request: ICancelCaseRequest = {
			additionalNotes: formValues.note,
			requestId: queryString,
			reasonsForCancellationIds: formValues.reasons.toString(),
			requestStatus: requestStatus,
		};
		// console.log(request);
		const data = await createCommonAPICall({
			requestBody: request,
			apiService: dashboardService.cancelCase,
			showSuccessMessage: true,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.isSuccessfull) {
			console.log(data.data, data);
			cancelCaseModalClose(true);
		}
	};

	// useEffects
	useEffect(() => {
		reset();
		clearErrors();
		if (cancelCaseModalOpen) getCancelCaseModalInfo();
	}, [cancelCaseModalOpen]);
	useEffect(() => {
		Object.entries(formValues).forEach((child) => {
			setValue(child[0] as any, child[1] || "");
		});
	}, [formValues]);
	return (
		<>
			<Modal
				open={cancelCaseModalOpen}
				onClose={() => cancelCaseModalClose(false)}
				className="send-modal select-input-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">Confirm Cancellation</Typography>
						<IconButton onClick={() => cancelCaseModalClose(false)}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<div>
							<Typography variant="h6" className="patient-name">
								Patient Name :&nbsp;
								<span>{cancelCaseModalInfo?.fullName}</span>
							</Typography>
							{cancelCaseModalInfo.additionalNotes?.transferNotes && (
								<div style={{ marginBottom: "15px" }}>
									<Typography variant="h6">
										Transfer Notes :&nbsp;&nbsp;&nbsp;
									</Typography>
									<span>
										{cancelCaseModalInfo.additionalNotes?.transferNotes}
									</span>
								</div>
							)}
							{cancelCaseModalInfo.additionalNotes?.adminNotes && (
								<div style={{ marginBottom: "15px" }}>
									<Typography variant="h6">
										Admin Notes :&nbsp;&nbsp;&nbsp;
									</Typography>
									<span>{cancelCaseModalInfo.additionalNotes?.adminNotes}</span>
								</div>
							)}
							{cancelCaseModalInfo.additionalNotes?.physicianNotes && (
								<div style={{ marginBottom: "15px" }}>
									<Typography variant="h6">
										Physician Notes :&nbsp;&nbsp;&nbsp;
									</Typography>
									<span>
										{cancelCaseModalInfo.additionalNotes?.physicianNotes}
									</span>
								</div>
							)}
							{cancelCaseModalInfo.additionalNotes?.patientNotes && (
								<div style={{ marginBottom: "15px" }}>
									<Typography variant="h6">
										Patient Notes :&nbsp;&nbsp;&nbsp;
									</Typography>
									<span>
										{cancelCaseModalInfo.additionalNotes?.patientNotes}
									</span>
								</div>
							)}
							{cancelCaseModalInfo.additionalNotes
								?.adminSelectedCancellationNotes && (
								<div style={{ marginBottom: "15px" }}>
									<Typography variant="h6">
										Admin Cancellation Notes :&nbsp;&nbsp;&nbsp;
									</Typography>
									<span>
										{
											cancelCaseModalInfo.additionalNotes
												?.adminSelectedCancellationNotes
										}
									</span>
								</div>
							)}
							{cancelCaseModalInfo.additionalNotes
								?.physicianSelectedCancellationNotes && (
								<div style={{ marginBottom: "15px" }}>
									<Typography variant="h6">
										Physician Cancellation Notes :&nbsp;&nbsp;&nbsp;
									</Typography>
									<span>
										{
											cancelCaseModalInfo.additionalNotes
												?.physicianSelectedCancellationNotes
										}
									</span>
								</div>
							)}
							{cancelCaseModalInfo.additionalNotes?.patientAdditionalNotes && (
								<div style={{ marginBottom: "15px" }}>
									<Typography variant="h6">
										Patient Cancellation Notes :&nbsp;&nbsp;&nbsp;
									</Typography>
									<span>
										{
											cancelCaseModalInfo.additionalNotes
												?.patientAdditionalNotes
										}
									</span>
								</div>
							)}
							<FormControl fullWidth>
								<InputLabel id="reason-label">
									Reasons for Cancellation
								</InputLabel>
								<Select
									{...register("reasons")}
									labelId="reason-label"
									id="reason"
									value={formValues.reasons}
									multiple
									error={errors?.reasons?.message ? true : false}
									onChange={handleOnCancelReasonChange}
									input={
										<OutlinedInput
											id="select-reason-chip"
											label="Reasons for Cancellation"
										/>
									}
									renderValue={(selected) => (
										<div className="select-chips">
											{selected.map((value, index) => (
												<Chip
													clickable
													key={index}
													label={
														cancelCaseModalInfo.adminCaseTags.find(
															(tag) => tag.value.toString() === value
														)?.text || ""
													}
													deleteIcon={
														<div
															onMouseDown={(event) => event.stopPropagation()}
															onClick={() => handleDeleteSelectedReason(value)}
														>
															<img src={ChipDeleteIcon} alt="close" />
														</div>
													}
													onClick={() => handleDeleteSelectedReason(value)}
													onDelete={() => handleDeleteSelectedReason(value)}
												/>
											))}
										</div>
									)}
									MenuProps={MenuProps}
								>
									{cancelCaseModalInfo?.adminCaseTags?.map((reason, index) => (
										<MenuItem key={index} value={reason.value}>
											{reason.text}
										</MenuItem>
									))}
								</Select>
								<FormHelperText error id="component-error-text">
									<>{errors?.reasons?.message}</>
								</FormHelperText>
							</FormControl>
							{cancelCaseModalInfo?.physicianNotes && (
								<TextField
									id="block"
									label="Provide Additional Notes"
									value={cancelCaseModalInfo?.physicianNotes || ""}
									multiline
									maxRows={1}
									inputProps={{ className: "min-h-88px" }}
									fullWidth
								/>
							)}
							<TextField
								{...register("note")}
								id="block"
								label="Provide Additional Notes"
								value={formValues.note}
								name="note"
								onChange={handleOnChangeNote}
								multiline
								maxRows={1}
								inputProps={{ className: "min-h-88px" }}
								fullWidth
								error={errors?.note?.message ? true : false}
								helperText={errors?.note?.message?.toString()}
							/>
						</div>
						<div className="modal-footer">
							<Tooltip title="Confirm">
								<Button
									variant="contained"
									onClick={handleSubmit(handleOnClickConfirm)}
								>
									Confirm
								</Button>
							</Tooltip>
							<Tooltip title="Cancel">
								<Button
									variant="outlined"
									onClick={() => cancelCaseModalClose(false)}
								>
									Cancel
								</Button>
							</Tooltip>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
