import {
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TextField,
	Box,
	Tooltip,
	Button,
} from "@mui/material";
import { CustomDatePicker } from "components";
import React, { useState } from "react";
import { IDropDownList, ISMSLogsSearchCriteria } from "utility/interfaces";

type ComponentProps = {
	searchCriteria: ISMSLogsSearchCriteria;
	handleOnSearchCriteriaChange: any;
	roles: IDropDownList[];
};

export const SMSLogsTableSearch = (props: ComponentProps) => {
	// Extract props
	const { searchCriteria, handleOnSearchCriteriaChange, roles } = props;

	// use state
	const [formValues, setFormValues] = useState<ISMSLogsSearchCriteria>({
		roleId: searchCriteria.roleId,
		receiverName: "",
		mobileNumber: "",
		PageIndexId: 0,
		PageSize: searchCriteria.PageSize,
		SortBy: searchCriteria.SortBy,
		SortOrder: searchCriteria.SortOrder,
		SearchBy: "",
	});

	// handle events
	const handleFormValueChange = (name: string, value: any) => {
		setFormValues({ ...formValues, [name]: value });
	};

	const handleOnClickSearch = () => {
		handleOnSearchCriteriaChange(formValues);
	};

	const handleOnRoleChange = (value: number | string) => {
		handleOnSearchCriteriaChange({
			...searchCriteria,
			PageIndexId: 0,
			roleId: value,
		});
	};

	const handleOnClearForm = () => {
		handleOnSearchCriteriaChange({
			roleId: 0,
			receiverName: "",
			emailId: "",
			PageIndexId: 0,
			PageSize: searchCriteria.PageSize,
			SortBy: searchCriteria.SortBy,
			SortOrder: searchCriteria.SortOrder,
			SearchBy: "",
		});
		setFormValues({
			roleId: 0,
			receiverName: "",
			mobileNumber: "",
			PageIndexId: 0,
			PageSize: searchCriteria.PageSize,
			SortBy: searchCriteria.SortBy,
			SortOrder: searchCriteria.SortOrder,
			SearchBy: "",
		});
	};

	return (
		<>
			<div>
				<div className="history-table-header">
					<Grid
						container
						columnSpacing={{ sm: 2, lg: 3 }}
						sx={{ marginBottom: "15px" }}
					>
						<Grid item xs={12} md={4} laptop={2}>
							<FormControl fullWidth className="select-input">
								<InputLabel id="role-label">Search by role</InputLabel>
								<Select
									labelId="role-label"
									id="role"
									name="role"
									value={searchCriteria.roleId}
									onChange={(event) => {
										handleOnRoleChange(event.target.value);
									}}
									label="Search by role"
									MenuProps={{
										className: "select-input-modal",
									}}
								>
									{roles.map((role) => {
										return (
											<MenuItem key={role.value} value={role.value}>
												{role.text}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={4} laptop={2}>
							<TextField
								fullWidth
								id="receiverName"
								label="Receiver Name"
								variant="outlined"
								name="receiverName"
								value={formValues.receiverName}
								onChange={(e) =>
									handleFormValueChange(e.target.name, e.target.value)
								}
							/>
						</Grid>
						<Grid item xs={12} md={4} laptop={2}>
							<TextField
								fullWidth
								id="mobileNumber"
								label="Mobile Number"
								variant="outlined"
								name="mobileNumber"
								value={formValues.mobileNumber}
								onChange={(e) =>
									handleFormValueChange(e.target.name, e.target.value)
								}
							/>
						</Grid>
						<Grid item xs={12} md={4} laptop={2}>
							<CustomDatePicker
								value={formValues.createdDate?.toString()}
								label="Created Date"
								onChange={(name: string, value: any) =>
									handleFormValueChange(name, value ? new Date(value) : null)
								}
								disabled={false}
								name="createdDate"
							/>
						</Grid>
						<Grid item xs={12} md={4} laptop={2}>
							<CustomDatePicker
								value={formValues.sentDate?.toString()}
								label="Sent Date"
								onChange={(name: string, value: any) =>
									handleFormValueChange(name, value ? new Date(value) : null)
								}
								disabled={false}
								name="sentDate"
							/>
						</Grid>
						<Grid item xs={12} md={4} laptop={2}>
							<Box
								sx={{
									display: "flex",
									columnGap: "12px",
									justifyContent: "flex-end",
								}}
							>
								<div className="history-search-btn">
									<Tooltip title="Click To Search">
										<Button variant="contained" onClick={handleOnClickSearch}>
											<span className="button-link">Search</span>
										</Button>
									</Tooltip>
								</div>
								<div className="history-search-btn">
									<Tooltip title="Click To Clear filters">
										<Button variant="outlined" onClick={handleOnClearForm}>
											<span className="button-link">Clear</span>
										</Button>
									</Tooltip>
								</div>
							</Box>
						</Grid>
					</Grid>
				</div>
			</div>
		</>
	);
};
