import { Box, Button, Typography } from "@mui/material";
import { ArrowBack } from "assests/images";
import { EmailLogsTable } from "components";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import historyService from "services/history-service";
import { Constants } from "utility/enums/constants";
import { SortOrder } from "utility/enums/sort-order";
import { createCommonAPICall } from "utility/helpers";
import {
	IDropDownList,
	IEmailLogsInfo,
	IEmailLogsSearchCriteria,
} from "utility/interfaces";

const EmailLogs: React.FC = () => {
	// Use context
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// Page level variables
	const navigate = useNavigate();

	// use state
	const [searchCriteria, setSearchCriteria] =
		useState<IEmailLogsSearchCriteria>({
			roleId: 0,
			receiverName: "",
			emailId: "",
			PageIndexId: 0,
			PageSize: Constants.DefaultTablePageSize.valueOf(),
			SortBy: "CreateDate",
			SortOrder: SortOrder.descending,
			SearchBy: "",
		});

	const [emailLogsInfo, setEmailLogsInfo] = useState<IEmailLogsInfo>({
		emailLogs: [],
		totalRecords: 0,
	});

	const [roles, setRoles] = useState<IDropDownList[]>([]);

	// handle events
	const handleOnClickBackCancel = () => navigate(-1);

	const handleOnSearchCriteriaChange = (
		newSearchCriteria: IEmailLogsSearchCriteria
	) => {
		setSearchCriteria(newSearchCriteria);
	};

	const getEmailLogs = async () => {
		let strCreatedDate = searchCriteria.createdDate
			? new Date(searchCriteria.createdDate).toLocaleString("en-us")
			: null;
		let strSentDate = searchCriteria.sentDate
			? new Date(searchCriteria.sentDate).toLocaleString("en-us")
			: null;

		const data = await createCommonAPICall({
			requestBody: {
				...searchCriteria,
				roleId: searchCriteria.roleId == 0 ? null : searchCriteria.roleId,
				receiverName:
					searchCriteria.receiverName == ""
						? null
						: searchCriteria.receiverName,
				emailId: searchCriteria.emailId == "" ? null : searchCriteria.emailId,
				strCreatedDate:
					strCreatedDate == "Invalid Date" ? null : strCreatedDate,
				strSentDate: strSentDate == "Invalid Date" ? null : strSentDate,
			},
			apiService: historyService.getEmailLogs,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});
		if (data && data.isSuccessfull) {
			console.log(data);
			setEmailLogsInfo({
				emailLogs: data.data.emailLogs,
				totalRecords: data.data.totalRecords,
			});
			setRoles(data.data.roles);
		}
	};

	// Use Effects
	useEffect(() => {
		getEmailLogs();
	}, [searchCriteria]);

	return (
		<Box>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="tab-item-header">
					<Typography variant="h2">Email Logs (Gmail)</Typography>
					<Button variant="outlined" onClick={handleOnClickBackCancel}>
						<img src={ArrowBack} alt="arrow" />
						Back
					</Button>
				</Box>
				<EmailLogsTable
					searchCriteria={searchCriteria}
					handleOnSearchCriteriaChange={handleOnSearchCriteriaChange}
					emailLogsInfo={emailLogsInfo}
					roles={roles}
				/>
			</main>
		</Box>
	);
};

export default EmailLogs;
