import React, { useContext, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	Box,
	Button,
	Grid,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import {
	AdministratorIcon,
	ArrowBack,
	DoctorDarkIcon,
	PatientDarkIcon,
	TransferIcon,
} from "assests/images";
import { useNavigate, useParams } from "react-router-dom";
import { AppRoutings } from "utility/enums/app-routings";
import { getTabStatusWiseName, createCommonAPICall } from "utility/helpers";
import dashboardService from "services/dashboard-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { IViewNoteInfo, ISaveViewNotes } from "utility/interfaces";
import { saveViewNoteSchema } from "utility/validations";
import { useForm } from "react-hook-form";
import { AccountType, InformationTabTypes } from "utility/enums/request-status";
import Dashboard from "./dashboard";
import { UserContext } from "contexts/user-context";

const ViewNotes: React.FC = () => {
	// get query parameters
	const { caseId } = useParams();

	// Page level interface
	interface ISaveViewNote {
		patientNotes: string;
		transferNotes: string;
		physicianNotes: string;
		adminSelectedCancellationNotes: string;
		adminNotes: string;
		note: string;
	}

	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const contextUser = useContext(UserContext);
	const navigate = useNavigate();

	// get state value
	const { state } = window.history;

	// useStates
	const [formValues, setFormValues] = useState<ISaveViewNote>({
		patientNotes: "",
		transferNotes: "",
		physicianNotes: "",
		adminSelectedCancellationNotes: "",
		adminNotes: "",
		note: "",
	});

	const [viewNotePageInfo, setViewNotePageInfo] =
		React.useState<IViewNoteInfo | null>({
			queryString: "",
			requestId: 0,
			requestDTYId: 0,
			patientNotes: "",
			physicianNotes: "",
			adminNotes: "",
			transferNotes: "",
			notes: "",
			adminSelectedCancellationNotes: "",
			physicianSelectedCancellationNotes: "",
			patientSelectedCancellationNotes: "",
		});

	// const [adminNotes, setAdminNotes] = useState(viewNotePageInfo?.notes);

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		reset,
		setValue,
	} = useForm({
		resolver: yupResolver(saveViewNoteSchema),
	});

	// Handled events and functions
	const handleOnClickBackCancel = () =>
		navigate(
			state?.usr?.backURL ||
				AppRoutings.Dashboard.replace(
					":tabStatus",
					getTabStatusWiseName(
						state?.usr?.searchCriteria?.RequestStatusId
					).toLocaleLowerCase()
				),
			{
				state: {
					searchCriteria: state?.usr?.searchCriteria,
					backURL: state?.usr?.prevBackURL,
				},
			}
		);
	const handleOnChangeAdminNote = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setFormValues({
			...formValues,
			note: e.target.value,
		});
	};
	const handleOnClickSaveChanges = async () => {
		const request: ISaveViewNotes = {
			requestId: caseId!,
			requestDTYId: viewNotePageInfo?.requestDTYId!,
			notes: formValues.note,
		};
		console.log(request);
		const data = await createCommonAPICall({
			requestBody: request,
			apiService: dashboardService.saveViewNotes,
			showSuccessMessage: true,
			showErrorMessage: true,
			setSuccessErrorContext,
		}).then((data) => {
			if (data.isSuccessfull) {
				getNoteDetails();
			}
		});
	};
	const getNoteDetails = async () => {
		const data = await createCommonAPICall({
			requestBody: { requestId: caseId },
			apiService: dashboardService.getViewNoteInfo,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.data && data.isSuccessfull) {
			setViewNotePageInfo(data.data);
			setFormValues({
				patientNotes: data.data?.patientNotes || "",
				transferNotes: data.data?.transferNotes || "",
				physicianNotes: data.data?.physicianNotes || "",
				adminSelectedCancellationNotes:
					data.data?.adminSelectedCancellationNotes || "",
				adminNotes: data.data?.adminNotes || "",
				note: data.data?.notes || "",
			});
			return;
		}
	};

	// useEffects
	useEffect(() => {
		reset();
		clearErrors();
		if (caseId) getNoteDetails();
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		Object.entries(formValues).forEach((child) => {
			setValue(child[0] as any, child[1] || "");
		});
	}, [formValues]);

	return (
		<Box>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="request-container-box">
					<div className="request-header">
						<Typography variant="h2">Notes</Typography>
						<Button variant="outlined" onClick={handleOnClickBackCancel}>
							<img src={ArrowBack} alt="arrow" />
							Back
						</Button>
					</div>
					<Grid container spacing={3}>
						{/* Removed as per client changes 22 Aug, 2023 */}
						{/* <Grid item xs={12} md={6}>
							<Box className="notes-box">
								<img src={PatientDarkIcon} alt="patient" />
								<div>
									<Typography variant="h6">Patient Notes</Typography>
									<Typography variant="body1">
										{formValues.patientNotes || "-"}
									</Typography>
								</div>
							</Box>
						</Grid> */}
						<Grid item xs={12} md={6}>
							<Box className="notes-box">
								<img src={TransferIcon} alt="transfer" />
								<div>
									<Typography variant="h6">Transfer Notes</Typography>
									<Typography variant="body1">
										{formValues.transferNotes || "-"}
									</Typography>
								</div>
							</Box>
						</Grid>
						<Grid item xs={12} md={6}>
							<Box className="notes-box">
								<img src={DoctorDarkIcon} alt="Doctor" />
								<div>
									<Typography variant="h6">Physician Notes</Typography>
									<Typography variant="body1">
										{formValues.physicianNotes || "-"}
									</Typography>
								</div>
							</Box>
						</Grid>
						{formValues.adminSelectedCancellationNotes && (
							<Grid item xs={12} md={6}>
								<Box className="notes-box">
									<img src={DoctorDarkIcon} alt="Doctor" />
									<div>
										<Typography variant="h6">
											Admin Cancellation Notes
										</Typography>
										<Typography variant="body1">
											{formValues.adminSelectedCancellationNotes || "-"}
										</Typography>
									</div>
								</Box>
							</Grid>
						)}
						{viewNotePageInfo?.patientAdditionalNotes && (
							<Grid item xs={12} md={6}>
								<Box className="notes-box">
									<img src={PatientDarkIcon} alt="Doctor" />
									<div>
										<Typography variant="h6">
											Patient Cancellation Notes
										</Typography>
										<Typography variant="body1">
											{viewNotePageInfo?.patientAdditionalNotes || "-"}
										</Typography>
									</div>
								</Box>
							</Grid>
						)}
						<Grid item xs={12} md={6}>
							<Box className="notes-box">
								<img src={AdministratorIcon} alt="Administrator" />
								<div>
									<Typography variant="h6">Admin Notes</Typography>
									<Typography variant="body1">
										{formValues.adminNotes || "-"}
									</Typography>
								</div>
							</Box>
						</Grid>
					</Grid>

					<Box sx={{ mt: 3 }} className="request-box">
						<Grid container spacing={3} sx={{ mt: 0.5 }}>
							<Grid item xs={12} md={12}>
								<TextField
									{...register("note")}
									inputProps={{
										style: { minHeight: "80px" },
									}}
									id="admin-notes"
									label={
										<React.Fragment>
											{contextUser.userRoleId == AccountType.Admin
												? "Admin Notes"
												: "Physician Notes"}
										</React.Fragment>
									}
									multiline
									value={formValues.note}
									rows={2}
									fullWidth
									name="physicianNote"
									onChange={handleOnChangeAdminNote}
									error={errors?.note?.message ? true : false}
									helperText={errors?.note?.message?.toString()}
								/>
							</Grid>
						</Grid>
						<div className="request-btn-group">
							<Tooltip title="Save Changes">
								<Button
									onClick={handleSubmit(handleOnClickSaveChanges)}
									variant="contained"
								>
									Save Changes
								</Button>
							</Tooltip>
						</div>
					</Box>
				</Box>
			</main>
		</Box>
	);
};

export default ViewNotes;
