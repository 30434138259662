import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	Checkbox,
	TableSortLabel,
	Box,
	TableBody,
	TableContainer,
} from "@mui/material";
import { CheckIcon, UncheckIcon } from "assests/images";
import React, { useState } from "react";
import { SortOrder } from "utility/enums/sort-order";
import { IProviderInfoSearchCriteria, IShiftsInfo } from "utility/interfaces";
import { RequestShiftsTableRow } from "./table-row";
import { visuallyHidden } from "@mui/utils";

type ComponentProps = {
	searchCriteria: IProviderInfoSearchCriteria;
	shiftsInfo: IShiftsInfo;
	handleChangeSearchCriteria: any;
	selectedShifts: number[];
	setSelectedShifts: any;
	selectAll: boolean;
	setSelectAll: any;
};

export function AllRequestedShiftsTable(props: ComponentProps) {
	// Extract props
	const {
		searchCriteria,
		shiftsInfo,
		handleChangeSearchCriteria,
		selectedShifts,
		setSelectedShifts,
		selectAll,
		setSelectAll,
	} = props;

	// Handle events

	const handleOnChangeSortDirection = (sortBy: string) => {
		handleChangeSearchCriteria({
			...searchCriteria,
			SortBy: sortBy,
			SortOrder:
				searchCriteria.SortOrder === SortOrder.ascending
					? SortOrder.descending
					: SortOrder.ascending,
		});
	};

	const CheckedboxIcon = () => {
		return <img src={CheckIcon} alt="checkbox" />;
	};
	const CheckboxIcon = () => {
		return <img src={UncheckIcon} alt="checkbox" />;
	};

	const handleSelectShift = (shiftDetailsId: number) => {
		if (selectedShifts.includes(shiftDetailsId)) {
			let changedSelected = selectedShifts.filter((s) => s !== shiftDetailsId);
			setSelectedShifts(changedSelected);
			if (changedSelected.length !== shiftsInfo.shifts.length)
				setSelectAll(false);
		} else {
			let changedSelected = [...selectedShifts, shiftDetailsId];
			if (changedSelected.length === shiftsInfo.shifts.length) {
				setSelectAll(true);
			}
			setSelectedShifts(changedSelected);
		}
	};

	const handleSelectAll = () => {
		if (selectAll) {
			setSelectedShifts([]);
			setSelectAll(false);
		} else {
			let allShifts = shiftsInfo.shifts.map((s) => {
				return s.shiftDetailId;
			});
			setSelectedShifts(allShifts);
			setSelectAll(true);
		}
	};

	return (
		<>
			<TableContainer
				className="upload-table-container"
				sx={{ display: { xs: "none", laptop: "block" } }}
			>
				<Table className="upload-table checkbox-table">
					<TableHead>
						<TableRow>
							<TableCell className="min-w-100" align="center">
								<Checkbox
									onClick={handleSelectAll}
									icon={<CheckboxIcon />}
									checkedIcon={<CheckedboxIcon />}
									checked={selectAll}
									disableRipple
									disableFocusRipple
									inputProps={{
										"aria-labelledby": `enhanced-table-checkbox--1`,
									}}
								/>
							</TableCell>
							<TableCell
								sortDirection={searchCriteria.SortOrder}
								className="min-w-200"
							>
								<TableSortLabel
									active={searchCriteria.SortBy === "PhysicianName"}
									direction={searchCriteria.SortOrder}
									onClick={() => handleOnChangeSortDirection("PhysicianName")}
								>
									Staff
									<Box component="span" sx={visuallyHidden}>
										{searchCriteria.SortOrder === SortOrder.descending
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								</TableSortLabel>
							</TableCell>
							<TableCell
								sortDirection={searchCriteria.SortOrder}
								className="min-w-160"
							>
								<TableSortLabel
									active={searchCriteria.SortBy === "Day"}
									direction={searchCriteria.SortOrder}
									onClick={() => handleOnChangeSortDirection("Day")}
								>
									Day
									<Box component="span" sx={visuallyHidden}>
										{searchCriteria.SortOrder === SortOrder.descending
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								</TableSortLabel>
							</TableCell>
							<TableCell
								sortDirection={searchCriteria.SortOrder}
								className="min-w-160"
							>
								<TableSortLabel
									active={searchCriteria.SortBy === "Time"}
									direction={searchCriteria.SortOrder}
									onClick={() => handleOnChangeSortDirection("Time")}
								>
									Time
									<Box component="span" sx={visuallyHidden}>
										{searchCriteria.SortOrder === SortOrder.descending
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								</TableSortLabel>
							</TableCell>
							<TableCell sx={{ width: 200 }}>
								<TableSortLabel
									active={searchCriteria.SortBy === "Region"}
									direction={searchCriteria.SortOrder}
									onClick={() => handleOnChangeSortDirection("Region")}
								>
									Region
									<Box component="span" sx={visuallyHidden}>
										{searchCriteria.SortOrder === SortOrder.descending
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								</TableSortLabel>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{shiftsInfo.shifts.map((row, index) => {
							return (
								<TableRow hover tabIndex={-1} key={index}>
									<RequestShiftsTableRow
										index={index}
										row={row}
										handleOnSelectRow={handleSelectShift}
										selected={selectedShifts.includes(row.shiftDetailId)}
										isSmallDevice={false}
									/>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<Box
				sx={{ display: { xs: "block", laptop: "none" } }}
				className="tabledata-cards-group"
			>
				{shiftsInfo.shifts.map((row, index) => {
					return (
						<RequestShiftsTableRow
							key={index}
							index={index}
							row={row}
							handleOnSelectRow={handleSelectShift}
							selected={selectedShifts.includes(row.shiftDetailId)}
							isSmallDevice={true}
						/>
					);
				})}
			</Box>
		</>
	);
}
