import React, { useContext, useEffect } from "react";
import { Box } from "@mui/material";

import { ICreateRole, IRoleDetails, IDropDownList } from "utility/interfaces";
import { RoleForm } from "components";
import { createCommonAPICall } from "utility/helpers";
import roleService from "services/role-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { useParams } from "react-router";

const UpdateRole: React.FC = () => {
	// get query parameters
	const roleId = useParams()?.roleId || "";

	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// useStates
	const [formValues, setFormValues] = React.useState<ICreateRole>({
		name: "",
		roleId: 0,
		accountType: 0,
		roleMenus: [],
		accountTypes: [],
	});

	// handled method(s)
	const setUpFormValues = (details: IRoleDetails) => {
		const accountTypes: IDropDownList[] = [];
		details.accountTypes.forEach((account) => {
			const accountTypeList: IDropDownList = {
				value: account.value,
				text: account.text,
			};
			accountTypes.push(accountTypeList);
		});
		const formValue: ICreateRole = {
			roleId: details.role.roleId,
			name: details.role.name || "",
			accountType:
				details.accountTypes.filter((type) => type.isSelected)[0]?.value ||
				details.accountTypes[0].value,
			roleMenus: details.menus,
			accountTypes: accountTypes,
		};
		setFormValues(formValue);
	};
	const getRoleDetails = async () => {
		const data = await createCommonAPICall({
			requestBody: roleId,
			apiService: roleService.getRoleDetails,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.data) setUpFormValues(data.data);
	};

	// useEffect
	useEffect(() => {
		getRoleDetails();
	}, []);

	return (
		<Box>
			<RoleForm
				formValues={formValues}
				setFormValues={setFormValues}
				title="Update Role"
			/>
		</Box>
	);
};

export default UpdateRole;
