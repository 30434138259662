import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	Button,
	FormControl,
	FormGroup,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Typography,
} from "@mui/material";
import { ArrowBack } from "assests/images";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ICreateRole, ICreatUpdateRoleRequest } from "utility/interfaces";
import { cresteRoleSchema } from "utility/validations";
import { createCommonAPICall } from "utility/helpers";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import roleService from "services/role-service";
import { CheckBoxComponent } from "components";
import { AccountType } from "utility/enums/request-status";

type ComponentProps = {
	formValues: ICreateRole;
	setFormValues: any;
	title: string;
};

export function RoleForm(props: ComponentProps) {
	// Extract props
	const { formValues, setFormValues, title } = props;

	// useSates
	const [isMenuPermissionError, setIsMenuPermissionError] = useState(false);

	//Page level local variable
	const navigate = useNavigate();
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// handled events and functions
	const handleOnClickBackCancel = () => navigate(-1);
	const handleOnclickSave = async () => {
		const selectedMenus = formValues.roleMenus
			.filter((menu) => menu.isChecked)
			.map(({ menuId }) => menuId);

		if (
			selectedMenus.length === 0 &&
			formValues.accountType !== AccountType.Business &&
			formValues.accountType !== AccountType.Patient &&
			formValues.accountType !== AccountType.PatientAccount
		) {
			setIsMenuPermissionError(true);
			return;
		} else setIsMenuPermissionError(false);

		const requestBody: ICreatUpdateRoleRequest = {
			accountType: formValues.accountType,
			name: formValues.name,
			roleMenuIds: selectedMenus.length === 0 ? "" : selectedMenus.join(","),
		};
		if (formValues.roleId > 0) requestBody.roleId = formValues.roleId;
		const data = await createCommonAPICall({
			requestBody: requestBody,
			apiService:
				formValues.roleId && formValues.roleId > 0
					? roleService.updateRole
					: roleService.createRole,
			showSuccessMessage: true,
			showErrorMessage: true,
			setSuccessErrorContext,
		});

		if (data.isSuccessfull) {
			handleOnClickBackCancel();
		}
	};
	const handleOnChangeFormValue = (
		e:
			| SelectChangeEvent<string>
			| React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		if (e.target.name === "accountType") {
			const menus = formValues.roleMenus.map((menu) => {
				menu.isChecked = false;
				return menu;
			});
			setFormValues({
				...formValues,
				[e.target.name]: e.target.value,
				roleMenus: menus,
			});
			return;
		}
		setFormValues({ ...formValues, [e.target.name]: e.target.value });
	};
	const handleOnChangeCheckbox = (menuId: number) => {
		const menus = formValues.roleMenus.map((menu) => {
			if (menu.menuId === menuId) menu.isChecked = !menu.isChecked;
			return menu;
		});

		setFormValues({ ...formValues, roleMenus: menus });
	};

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<ICreateRole>({
		resolver: yupResolver(cresteRoleSchema),
	});

	// useEffects
	useEffect(() => {
		reset();
	}, []);

	return (
		<>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="request-container-box">
					<div className="request-header">
						<Typography variant="h2">{title}</Typography>
						<Button variant="outlined" onClick={handleOnClickBackCancel}>
							<img src={ArrowBack} alt="arrow" />
							Back
						</Button>
					</div>
					<Box className="request-box">
						<Typography variant="h4">Details</Typography>
						<div>
							<Grid container columnSpacing={{ sm: 2, lg: 3 }}>
								<Grid item xs={12} sm={6}>
									<TextField
										error={errors?.name?.message ? true : false}
										{...register("name")}
										fullWidth
										id="name"
										label="Role Name"
										variant="outlined"
										name="name"
										autoFocus
										value={formValues.name}
										helperText={errors?.name?.message?.toString()}
										onChange={handleOnChangeFormValue}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<FormControl fullWidth className="select-input">
										<InputLabel id="status-label">Account Type</InputLabel>
										<Select
											labelId="AccountTypes"
											id="accountType"
											value={formValues?.accountType.toString()}
											{...register("accountType")}
											label="Account Type"
											name="accountType"
											onChange={handleOnChangeFormValue}
											error={errors?.accountType?.message ? true : false}
											MenuProps={{
												className: "select-input-modal",
											}}
										>
											{formValues.accountTypes?.map((type, index) => {
												return (
													<MenuItem key={index} value={type.value}>
														{type.text}
													</MenuItem>
												);
											})}
										</Select>
										<FormHelperText error id="component-error-text">
											<>{errors?.accountTypes?.message}</>
										</FormHelperText>
									</FormControl>
								</Grid>
							</Grid>
							<FormControl component="fieldset" className="radio-inputs">
								<FormGroup row>
									{formValues.roleMenus
										.filter(
											(menuType) =>
												menuType.accountType === formValues?.accountType ||
												formValues?.accountType === 0
										)
										.map((menu, index) => {
											return (
												<CheckBoxComponent
													key={index}
													label={menu.name}
													isChecked={menu.isChecked}
													handleOnChangeCheckbox={() =>
														handleOnChangeCheckbox(menu.menuId)
													}
												/>
											);
										})}
								</FormGroup>
								<FormHelperText error id="component-error-text">
									<>
										{isMenuPermissionError
											? "*Select atleast one permission."
											: ""}
									</>
								</FormHelperText>
							</FormControl>
						</div>
						<div className="request-btn-group">
							<Button
								onClick={handleSubmit(handleOnclickSave)}
								variant="contained"
							>
								Save
							</Button>
							<Button variant="outlined" onClick={handleOnClickBackCancel}>
								Cancel
							</Button>
						</div>
					</Box>
				</Box>
			</main>
		</>
	);
}
