import React from "react";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from "@mui/material";
import { SortOrder } from "utility/enums/sort-order";
import { IHistoryInfo, IHistoryInfoSearchCriteria } from "utility/interfaces";
import {
	HistoryInfoTableRowData,
	HistoryInfoTableSearch,
	TablePagination,
} from "components";

type ComponentProps = {
	searchCriteria: IHistoryInfoSearchCriteria;
	handleOnSearchCriteriaChange: any;
	historyPageInfo: IHistoryInfo | null;
	selectedRows: number[];
	handleOnSelectRow: any;
};

export function HistoryInfoTable(props: ComponentProps) {
	// Extract Props
	const {
		searchCriteria,
		handleOnSearchCriteriaChange,
		historyPageInfo,
		selectedRows,
		handleOnSelectRow,
	} = props;

	// Handled events and functions
	const handleOnPageNumberChange = (value: number) => {
		handleOnSearchCriteriaChange({ ...searchCriteria, PageIndexId: value });
	};
	const handleOnChangeSortDirection = (sortBy: string) => {
		handleOnSearchCriteriaChange({
			...searchCriteria,
			SortBy: sortBy,
			SortOrder:
				searchCriteria.SortOrder === SortOrder.ascending
					? SortOrder.descending
					: SortOrder.ascending,
		});
	};
	return (
		<Box className="table-box">
			<HistoryInfoTableSearch
				handleOnSearchCriteriaChange={handleOnSearchCriteriaChange}
			/>
			{historyPageInfo && historyPageInfo?.totalRecords > 0 ? (
				<>
					<TableContainer sx={{ display: { xs: "none", sm: "block" } }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell
										sx={{ maxWidth: 50 }}
										className="min-w-50"
									></TableCell>
									<TableCell
										sx={{ textAlign: "center", maxWidth: 140 }}
										className="min-w-140"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "patientName"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("patientName")}
										>
											Patient Name
										</TableSortLabel>
									</TableCell>
									<TableCell
										sx={{ textAlign: "center", maxWidth: 140 }}
										className="min-w-140"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "phoneNumber"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("phoneNumber")}
										>
											Phone Number
										</TableSortLabel>
									</TableCell>
									<TableCell
										sx={{ textAlign: "center", maxWidth: 160 }}
										className="min-w-160"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "email"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("email")}
										>
											Email
										</TableSortLabel>
									</TableCell>
									<TableCell
										sx={{ textAlign: "center", maxWidth: 120 }}
										className="min-w-120"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "dateOfService"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() =>
												handleOnChangeSortDirection("dateOfService")
											}
										>
											Date Of Service
										</TableSortLabel>
									</TableCell>
									<TableCell
										sx={{ textAlign: "center", maxWidth: 130 }}
										className="min-w-130"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "caseCloseDate"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() =>
												handleOnChangeSortDirection("caseCloseDate")
											}
										>
											Case Closed Date
										</TableSortLabel>
									</TableCell>
									<TableCell
										sx={{ textAlign: "center", maxWidth: 130 }}
										className="min-w-130"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "requestor"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("requestor")}
										>
											Requestor
										</TableSortLabel>
									</TableCell>
									<TableCell
										sx={{ textAlign: "center", maxWidth: 140 }}
										className="min-w-140"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "provider"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("provider")}
										>
											Provider
										</TableSortLabel>
									</TableCell>
									<TableCell
										sx={{ textAlign: "center", maxWidth: 130 }}
										className="min-w-130"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "confirmation"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() =>
												handleOnChangeSortDirection("confirmation")
											}
										>
											Confirmation
										</TableSortLabel>
									</TableCell>
									<TableCell
										sx={{ textAlign: "center", maxWidth: 80 }}
										className="min-w-80"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "zipCode"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("zipCode")}
										>
											Zip Code
										</TableSortLabel>
									</TableCell>
									<TableCell
										sx={{ textAlign: "center", maxWidth: 160 }}
										className="min-w-160"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "transactionId"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() =>
												handleOnChangeSortDirection("transactionId")
											}
										>
											Transaction Id
										</TableSortLabel>
									</TableCell>
									<TableCell
										sx={{ textAlign: "center" }}
										className="min-w-100 max-w-100"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "status"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("status")}
										>
											Status
										</TableSortLabel>
									</TableCell>
									<TableCell
										align="center"
										sx={{ maxWidth: 110 }}
										className="min-w-110"
									>
										Action
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{historyPageInfo?.histories?.map((row, index) => (
									<HistoryInfoTableRowData
										key={index}
										row={row}
										isSmallDevice={false}
										searchCriteria={searchCriteria}
										selectedRows={selectedRows}
										handleOnSelectRow={handleOnSelectRow}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Box
						sx={{ display: { xs: "block", sm: "none" } }}
						className="tabledata-cards-group"
					>
						{historyPageInfo?.histories?.map((row, index) => (
							<HistoryInfoTableRowData
								key={index}
								row={row}
								isSmallDevice={true}
								searchCriteria={searchCriteria}
								selectedRows={selectedRows}
								handleOnSelectRow={handleOnSelectRow}
							/>
						))}
					</Box>
					<TablePagination
						currentPageNumber={searchCriteria.PageIndexId}
						handleOnPageNumberChange={handleOnPageNumberChange}
						totalRecords={historyPageInfo?.totalRecords || 0}
						rowCount={historyPageInfo?.histories?.length || 0}
					/>
				</>
			) : (
				<Typography variant="h5" sx={{ padding: 3 }}>
					No Record(s) Found{" "}
				</Typography>
			)}
		</Box>
	);
}
