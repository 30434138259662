import { Box, Button, Modal, Typography } from "@mui/material";
import { PasswordReset, CrossRedIcon } from "assests/images";
import {
	SuccessErrorModalContext,
	SuccessErrorModalDispatchContext,
} from "contexts/success-error-context";
import React, { useContext } from "react";
import { successErrorModalStyle } from "utility/helpers";

export function SuccessErrorModal() {
	const successErrorContext = useContext(SuccessErrorModalContext);
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const handleClickClose = () => {
		setSuccessErrorContext({
			isSuccessErrorOpen: false,
			title: "",
			message: "",
			isSuccess: true,
		});
		if (successErrorContext && successErrorContext.handleOnClickOk)
			successErrorContext.handleOnClickOk();
	};

	return (
		<>
			<Modal
				open={successErrorContext?.isSuccessErrorOpen}
				onClose={handleClickClose}
				className="reset-modal"
			>
				<Box sx={successErrorModalStyle}>
					<img
						src={successErrorContext?.isSuccess ? PasswordReset : CrossRedIcon}
						alt="Done"
						className="reset-img"
					/>
					<Typography variant="h2">{successErrorContext?.title}</Typography>
					<Typography variant="body1">
						{successErrorContext?.message}
					</Typography>
					<Button onClick={handleClickClose} variant="contained">
						Ok
					</Button>
				</Box>
			</Modal>
		</>
	);
}
