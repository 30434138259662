import React, { useEffect, useRef, useState } from "react";
import { IProviderLocationInfo } from "utility/interfaces";
import { Wrapper } from "@googlemaps/react-wrapper";
import { Constants } from "utility/enums/constants";
import { User } from "assests/images";
import { getUserLocation } from "utility/helpers";

type ComponentProps = {
	providerData: IProviderLocationInfo[];
};

export const GoogleMap = (props: ComponentProps) => {
	// Element ref
	const ref = useRef<HTMLDivElement>(null);

	// Extract props
	const { providerData } = props;

	// State variables
	const mapCenterLat = useRef(38.9072); // Defualt latitude for Washington DC
	const mapCenterLong = useRef(-77.0369); // Defualt longitude for Washington DC

	// Handle events
	const handleOnGetLocationSuccess = (position: GeolocationPosition) => {
		console.log(position.coords.latitude, position.coords.longitude);
		mapCenterLat.current = position.coords.latitude;
		mapCenterLong.current = position.coords.longitude;
		handleRenderMap();
	};

	const handleOnGetLocationError = (error: any) => {
		console.error(error);
		handleRenderMap();
	};

	const handleRenderMap = () => {
		console.log(ref);
		if (ref.current) {
			var mapCenter = new google.maps.LatLng(
				mapCenterLat.current,
				mapCenterLong.current
			);
			var mapOptions = {
				zoom: 15,
				center: mapCenter,
				mapTypeId: google.maps.MapTypeId.ROADMAP,
			};
			var map = new window.google.maps.Map(ref.current, mapOptions);

			providerData.forEach((provider) => {
				new google.maps.Marker({
					position: { lat: provider.latitude, lng: provider.longitude },
					map: map,
					title: provider.physicianName,
					icon: {
						url: provider.photoPath != "" ? provider.photoPath : User,
						size: new google.maps.Size(500, 500),
						origin: new google.maps.Point(0, 0),
						anchor: new google.maps.Point(0, 20),
						scaledSize: new google.maps.Size(30, 30),
						labelOrigin: new google.maps.Point(20, 35),
					},
					label: {
						text: provider.physicianName,
						fontWeight: "700",
						fontSize: "14px",
						// fontFamily: '"Courier New", Courier,Monospace',
						fontFamily: "Poppins,sans-serif",
						color: "dark blue",
					},
				});
			});
		} else {
			setTimeout(handleRenderMap, 500);
		}
	};

	// use effects
	useEffect(() => {
		getUserLocation(handleOnGetLocationSuccess, handleOnGetLocationError);
		//handleRenderMap();
	}, [providerData]);

	return (
		<Wrapper apiKey={Constants.GoogleKey.valueOf()}>
			<div ref={ref} className="map-view"></div>
		</Wrapper>
	);
};
