import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from "@mui/material";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import React, { useContext, useEffect, useState } from "react";
import haloService from "services/halo-service";
import { Constants } from "utility/enums/constants";
import { SortOrder } from "utility/enums/sort-order";
import { createCommonAPICall } from "utility/helpers";
import { IHaloHR, IHaloHRInfo, ISearchCriteria } from "utility/interfaces";
import { visuallyHidden } from "@mui/utils";
import { HaloHRTableRow } from "./table-row";
import { AddEditHaloHRModal, TablePagination } from "components";

type ComponentProps = {
	handleClickEdit: any;
	isAddEditModalOpen: boolean;
	handleModalClose: any;
	haloHRData: IHaloHR | null;
};

export const HaloHRTable = (props: ComponentProps) => {
	// Extract props
	const { handleClickEdit, isAddEditModalOpen, handleModalClose, haloHRData } =
		props;

	// Page level local variables
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// State Variables
	const [searchCriteria, setSearchCriteria] = useState<ISearchCriteria>({
		PageIndexId: 0,
		PageSize: Constants.DefaultTablePageSize.valueOf(),
		SortBy: "ModifiedDate",
		SortOrder: SortOrder.descending,
		SearchBy: "",
	});

	const [haloHRPageInfo, setHaloHRPageInfo] = useState<IHaloHRInfo>({
		halos: [],
		totalRecords: 0,
	});

	// Handle Events
	const handleOnSearchCriteriaChange = (newSearchCriteria: ISearchCriteria) => {
		setSearchCriteria(newSearchCriteria);
	};

	const handleOnPageNumberChange = (value: number) => {
		setSearchCriteria({ ...searchCriteria, PageIndexId: value });
	};
	const handleOnChangeSortDirection = (sortBy: string) => {
		setSearchCriteria({
			...searchCriteria,
			SortBy: sortBy,
			SortOrder:
				searchCriteria.SortOrder === SortOrder.ascending
					? SortOrder.descending
					: SortOrder.ascending,
		});
	};

	const getHaloHRInfo = async () => {
		const data = await createCommonAPICall({
			requestBody: searchCriteria,
			apiService: haloService.getHaloAdministratorsGrid,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			setHaloHRPageInfo(data.data);
		}
	};

	// Use effects
	useEffect(() => {
		getHaloHRInfo();
	}, [searchCriteria]);

	return (
		<>
			{haloHRPageInfo.totalRecords > 0 ? (
				<>
					<TableContainer
						className="upload-table-container"
						sx={{ display: { xs: "none", laptop: "block" } }}
					>
						<Table className="upload-table conclude-table">
							<TableHead>
								<TableRow>
									<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
										<TableSortLabel
											active={searchCriteria.SortBy === "username"}
											direction={searchCriteria.SortOrder}
											onClick={() => handleOnChangeSortDirection("username")}
										>
											Username
											<Box component="span" sx={visuallyHidden}>
												{searchCriteria.SortOrder === SortOrder.descending
													? "sorted descending"
													: "sorted ascending"}
											</Box>
										</TableSortLabel>
									</TableCell>
									<TableCell className="min-w-180px">
										<TableSortLabel
											active={searchCriteria.SortBy === "email"}
											direction={searchCriteria.SortOrder}
											onClick={() => handleOnChangeSortDirection("email")}
										>
											Email Address
											<Box component="span" sx={visuallyHidden}>
												{searchCriteria.SortOrder === SortOrder.descending
													? "sorted descending"
													: "sorted ascending"}
											</Box>
										</TableSortLabel>
									</TableCell>
									<TableCell className="min-w-200px">
										<TableSortLabel
											active={searchCriteria.SortBy === "WorkPlaceName"}
											direction={searchCriteria.SortOrder}
											onClick={() =>
												handleOnChangeSortDirection("WorkPlaceName")
											}
										>
											Work Place Name
											<Box component="span" sx={visuallyHidden}>
												{searchCriteria.SortOrder === SortOrder.descending
													? "sorted descending"
													: "sorted ascending"}
											</Box>
										</TableSortLabel>
									</TableCell>
									<TableCell>Action</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{haloHRPageInfo.halos.map((row, index) => (
									<HaloHRTableRow
										row={row}
										isSmallDevice={false}
										key={index}
										handleClickEdit={handleClickEdit}
										getHaloHRInfo={getHaloHRInfo}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Box
						sx={{ display: { xs: "block", laptop: "none" } }}
						className="tabledata-cards-group"
					>
						{haloHRPageInfo.halos.map((row, index) => (
							<HaloHRTableRow
								row={row}
								isSmallDevice={true}
								key={index}
								handleClickEdit={handleClickEdit}
								getHaloHRInfo={getHaloHRInfo}
							/>
						))}
					</Box>
					<div className="small-table-pagination">
						<TablePagination
							currentPageNumber={searchCriteria.PageIndexId}
							handleOnPageNumberChange={handleOnPageNumberChange}
							totalRecords={haloHRPageInfo.totalRecords || 0}
							rowCount={haloHRPageInfo.halos.length || 0}
						/>
					</div>
				</>
			) : (
				<Typography variant="h5" sx={{ padding: 3 }}>
					No Record(s) Found{" "}
				</Typography>
			)}
			{/* Page Modals */}
			<AddEditHaloHRModal
				isOpen={isAddEditModalOpen}
				handleClose={handleModalClose}
				haloHRData={haloHRData}
				getHaloHRInfo={getHaloHRInfo}
			/>
		</>
	);
};
