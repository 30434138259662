import {
	Box,
	Button,
	FormControl,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import { ArrowBack } from "assests/images";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Constants } from "utility/enums/constants";
import { SortOrder } from "utility/enums/sort-order";
import {
	IDropDownList,
	IProviderDropdownList,
	IProviderInfoSearchCriteria,
} from "utility/interfaces";
import { IShiftsInfo } from "utility/interfaces";
import {
	AllRequestedShiftsTable,
	CurrentMonthShiftsTable,
	TablePagination,
	WarningModal,
} from "components";
import { createCommonAPICall } from "utility/helpers";
import roleService from "services/role-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";

const ReviewShifts: React.FC = () => {
	// use context
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// Page level variables
	const navigate = useNavigate();

	// use States
	const [regions, setRegions] = useState<IDropDownList[]>([
		{
			text: "All Regions",
			value: 0,
		},
		{
			text: "region1",
			value: 1,
		},
		{
			text: "region2",
			value: 2,
		},
		{
			text: "region3",
			value: 3,
		},
		{
			text: "region4",
			value: 4,
		},
	]);
	const [searchCriteria, setSearchCriteria] =
		useState<IProviderInfoSearchCriteria>({
			PageIndexId: 0,
			PageSize: Constants.DefaultTablePageSize.valueOf(),
			SortOrder: SortOrder.ascending,
			SortBy: "PhysicianName",
			SearchBy: "",
			RegionId: 0,
		});
	const [shiftsInfo, setShiftsInfo] = useState<IShiftsInfo>({
		totalRecords: 0,
		shifts: [],
	});
	const [physicianList, setPhysicianList] = useState<IProviderDropdownList[]>(
		[]
	);
	const [selectedShifts, setSelectedShifts] = useState<number[]>([]);
	const [selectAll, setSelectAll] = useState(false);
	const [isWarningOpen, setIsWarningOpen] = useState(false);
	const [title, setTitle] = useState("");
	const [message, setMessage] = useState("");
	const [selectedView, setSelectedView] = useState("A");

	// Handle events
	const handleOnClickBackCancel = () => navigate(-1);

	const getShiftsDetails = async (selectedView?: string) => {
		console.log(searchCriteria);
		const data = await createCommonAPICall({
			requestBody: { ...searchCriteria, selectedView: selectedView ?? "A" },
			apiService: roleService.getRequestedShiftsGrid,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			console.log(data);
			setShiftsInfo({
				shifts: data.data.shifts,
				totalRecords: data.data.totalRecords,
			});
			setRegions(data.data.regions);
			setPhysicianList(data.data.physicianList);
		}
	};

	const handleOnPageNumberChange = (value: number) => {
		setSearchCriteria({ ...searchCriteria, PageIndexId: value });
	};

	const handleOnChangeSortDirection = (sortBy: string) => {
		setSearchCriteria({
			...searchCriteria,
			SortBy: sortBy,
			SortOrder:
				searchCriteria.SortOrder === SortOrder.ascending
					? SortOrder.descending
					: SortOrder.ascending,
		});
	};

	const handleApproveSelected = async () => {
		if (selectedShifts.length === 0) {
			setTitle("");
			setMessage("Please select atleast one shift to approve.");
			handleWarningModelOpen();
		} else {
			console.log(selectedShifts);
			let strShiftDetailsIds = selectedShifts.join(",");
			console.log(strShiftDetailsIds);
			let regions =
				searchCriteria.RegionId > 0
					? searchCriteria.RegionId.toString()
					: "1,2,3,4";
			const requestBody = {
				strShiftDetailsIds,
				selectedView,
				regions,
			};
			const data = await createCommonAPICall({
				requestBody,
				apiService: roleService.approveRequestedShifts,
				showErrorMessage: true,
				showSuccessMessage: true,
				setSuccessErrorContext,
			});

			if (data && data.isSuccessfull) {
				getShiftsDetails(selectedView);
				setSelectedShifts([]);
				setSelectAll(false);
			}
		}
	};

	const handleDeleteSelected = async () => {
		if (selectedShifts.length === 0) {
			setMessage("Please select atleast one shift to delete.");
			setTitle("");
			handleWarningModelOpen();
		} else {
			setMessage("Are you sure to delete these shifts?");
			setTitle("Confirmation");
			handleWarningModelOpen();
		}
	};

	const handleConfirmDelete = async () => {
		console.log(selectedShifts);
		let strShiftDetailsIds = selectedShifts.join(",");
		console.log(strShiftDetailsIds);
		let regions =
			searchCriteria.RegionId > 0
				? searchCriteria.RegionId.toString()
				: "1,2,3,4";
		const requestBody = {
			strShiftDetailsIds,
			selectedView,
			regions,
		};
		const data = await createCommonAPICall({
			requestBody,
			apiService: roleService.deleteRequestedShifts,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.isSuccessfull) {
			getShiftsDetails(selectedView);
			setSelectedShifts([]);
			setSelectAll(false);
		}
	};

	const handleWarningModelOpen = () => {
		setIsWarningOpen(true);
	};

	const handleWarningModelClose = () => {
		setIsWarningOpen(false);
	};

	const handleChangeView = async () => {
		setSelectAll(false);
		setSelectedShifts([]);
		if (selectedView === "A") {
			await getShiftsDetails("C");
			setSelectedView("C");
		} else {
			await getShiftsDetails("A");
			setSelectedView("A");
		}
	};

	// use effects
	useEffect(() => {
		getShiftsDetails(selectedView);
	}, [searchCriteria]);

	return (
		<Box>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="request-container-box">
					<div className="request-header">
						<Typography variant="h2">Requested Shifts</Typography>
						<Button variant="outlined" onClick={handleOnClickBackCancel}>
							<img src={ArrowBack} alt="arrow" />
							Back
						</Button>
					</div>
					<Box className="request-box">
						<div className="table-header region-table-header responsive-header">
							<FormControl className="region-search">
								<Select
									labelId="region-label"
									id="region"
									value={searchCriteria.RegionId}
									name="region"
									label="Search by region"
									onChange={(event) => {
										setSearchCriteria({
											...searchCriteria,
											PageIndexId: 0,
											RegionId: +event.target.value,
										});
									}}
									sx={{ marginBottom: "15px" }}
								>
									{regions.map((region) => {
										return (
											<MenuItem key={region.value} value={region.value}>
												{region.text}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
							<div
								className="request-btn-group wrap-sm"
								style={{ paddingTop: 0 }}
							>
								<Button
									variant="contained"
									color="success"
									sx={{ marginBottom: "15px" }}
									onClick={handleChangeView}
								>
									{selectedView === "A"
										? "View Current Month Shifts"
										: "View All Shifts"}
								</Button>
								<Button
									variant="contained"
									color="success"
									sx={{ marginBottom: "15px" }}
									onClick={handleApproveSelected}
								>
									Approved Selected
								</Button>
								<Button
									variant="contained"
									color="error"
									sx={{ marginBottom: "15px" }}
									onClick={handleDeleteSelected}
								>
									Delete Selected
								</Button>
								{/* <Button
									variant={selectAll ? "contained" : "outlined"}
									sx={{ marginLeft: "15px" }}
									onClick={handleSelectAll}
								>
									{selectAll ? "Unselect All" : "Select All"}
								</Button> */}
							</div>
						</div>
						{shiftsInfo && shiftsInfo.totalRecords > 0 ? (
							<>
								{selectedView === "A" ? (
									<AllRequestedShiftsTable
										shiftsInfo={shiftsInfo}
										handleChangeSearchCriteria={setSearchCriteria}
										searchCriteria={searchCriteria}
										selectedShifts={selectedShifts}
										setSelectedShifts={setSelectedShifts}
										selectAll={selectAll}
										setSelectAll={setSelectAll}
									/>
								) : (
									<CurrentMonthShiftsTable
										shiftsInfo={shiftsInfo}
										handleChangeSearchCriteria={setSearchCriteria}
										searchCriteria={searchCriteria}
										selectedShifts={selectedShifts}
										setSelectedShifts={setSelectedShifts}
										selectAll={selectAll}
										setSelectAll={setSelectAll}
										physicianList={physicianList}
									/>
								)}
								<div className="small-table-pagination">
									<TablePagination
										currentPageNumber={searchCriteria.PageIndexId}
										handleOnPageNumberChange={handleOnPageNumberChange}
										totalRecords={shiftsInfo?.totalRecords || 0}
										rowCount={
											selectedView === "A"
												? shiftsInfo?.shifts?.length || 0
												: physicianList.length
										}
									/>
								</div>
							</>
						) : (
							<Typography
								variant="h5"
								className="no-record"
								sx={{ padding: 3 }}
							>
								No Record(s) Found
							</Typography>
						)}
					</Box>
				</Box>
			</main>

			{/* Page Modals */}
			<WarningModal
				isModalOpen={isWarningOpen}
				handleOnClickOk={
					title == "" ? handleWarningModelClose : handleConfirmDelete
				}
				okButtonText="Ok"
				title={title}
				warningMessage={message}
				handleOnClickCloseModal={handleWarningModelClose}
				closeButtonText={title == "" ? "" : "Cancel"}
			/>
		</Box>
	);
};

export default ReviewShifts;
