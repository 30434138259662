import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import React from "react";
import { commonModalStyle } from "utility/helpers";

type DocValuesType = {
	title: string;
	message: string;
	docType: number;
};

type ComponentProps = {
	isAlreadyFinalizedModalOpen: boolean;
	alreadyFinalizedModalClose: any;
	handleOnClickDownload: any;
	handleOnClickSend: any;
	values: DocValuesType;
	handleClickRegenarate?: any;
};

export function AlreadyFinalizedModal(props: ComponentProps) {
	const {
		isAlreadyFinalizedModalOpen,
		alreadyFinalizedModalClose,
		handleOnClickDownload,
		handleOnClickSend,
		values,
		handleClickRegenarate,
	} = props;

	return (
		<>
			<Modal
				open={isAlreadyFinalizedModalOpen}
				onClose={alreadyFinalizedModalClose}
				className="send-modal select-input-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">{values.title}</Typography>
						{/* <Typography variant="h5">Encounter Form</Typography> */}
						<IconButton onClick={alreadyFinalizedModalClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						{values.message}
						{/* Encounter form is finalized successfully! */}
						<div className="record-btn-group wrap-sm request-btn-group">
							<Button
								variant="contained"
								color="primary"
								onClick={() => handleOnClickDownload(values.docType)}
							>
								Download
							</Button>
							{values.docType == 2 && (
								<Button
									variant="contained"
									color="secondary"
									onClick={() => {
										handleOnClickSend(values.docType);
									}}
								>
									Send Email
								</Button>
							)}
							{values.docType == 2 && (
								<Button
									variant="contained"
									color="warning"
									onClick={handleClickRegenarate}
								>
									Re-generate Form
								</Button>
							)}
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
