import { yupResolver } from "@hookform/resolvers/yup";
import {
	Button,
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Tooltip,
	Typography,
} from "@mui/material";
import { AccountIcon, ArrowBack } from "assests/images";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import authService from "services/auth-service";
import { createCommonAPICall } from "utility/helpers";
import { IHttpsResponse } from "utility/interfaces";
import { resetPasswordSchema } from "utility/validations";
import { ResetPasswordModal } from "./reset-password-modal";

type ComponentProps = {
	handleClickBackToLogin: any;
};

export function ForgotPasswordForm(props: ComponentProps) {
	// Extract Props
	const { handleClickBackToLogin } = props;

	// useStates
	const [isResetPwdopen, setIsResetPwdopen] = React.useState(false);
	const [userName, setUserName] = React.useState("");

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(resetPasswordSchema),
	});

	// Page level local variables
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// Handled events
	const handleClickReset = async () => {
		const data: IHttpsResponse<any> = await createCommonAPICall({
			requestBody: userName,
			apiService: authService.forgotPassword,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) setIsResetPwdopen(true);
	};

	return (
		<>
			<Typography variant="h1">Reset Your Password</Typography>
			<form onSubmit={handleSubmit(handleClickReset)}>
				<div
					className="login-form-control"
					style={{ display: "flex", flexDirection: "column" }}
				>
					<FormControl variant="outlined">
						<InputLabel htmlFor="username">Username</InputLabel>
						<OutlinedInput
							id="username"
							{...register("userName")}
							autoFocus
							error={errors?.userName?.message ? true : false}
							value={userName}
							onChange={(e) => {
								setUserName(e.target.value);
							}}
							className="with-icon"
							endAdornment={
								<InputAdornment tabIndex={-1} position="end">
									<IconButton tabIndex={-1} edge="end">
										<img tabIndex={-1} src={AccountIcon} alt="account" />
									</IconButton>
								</InputAdornment>
							}
							label="Username"
						/>
						<FormHelperText error id="component-error-text">
							<>{errors?.userName?.message}</>
						</FormHelperText>
					</FormControl>
					<Tooltip title="Reset Password">
						<Button variant="contained" focusRipple type="submit">
							Reset Password
						</Button>
					</Tooltip>
					<Button onClick={handleClickBackToLogin} className="back-login">
						<img src={ArrowBack} alt="arrow" />
						Back to Login
					</Button>
					<ResetPasswordModal
						isResetPwdopen={isResetPwdopen}
						handleClickBackToLogin={handleClickBackToLogin}
						handleOutsideClick={() => setIsResetPwdopen(false)}
					/>
				</div>
			</form>
		</>
	);
}
