import React from "react";
import { Box, Button, Typography, Modal, IconButton } from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
// import { Text, View } from "@react-pdf/renderer";
import { commonModalStyle } from "utility/helpers";
import { IItemizedCost } from "utility/interfaces";
// import { CustomPDFViewer } from "components";

type ComponentProps = {
	invoiceModalOpen: boolean;
	onInvoiceModalClose: any;
	invoiceData: IItemizedCost;
};

export function ItemizedInvoiceModal(props: ComponentProps) {
	// Extract Props
	const { invoiceModalOpen, onInvoiceModalClose, invoiceData } = props;

	const pdfContent = (
		<>
			{/* <View>
				<Text
					style={{
						marginTop: 5,
						fontSize: 50,
						textAlign: "center",
						width: "100%",
						maxWidth: "100%",
					}}
				>
					INVOICE
				</Text>
				<Text
					style={{
						marginTop: 10,
						fontSize: 20,
						textAlign: "center",
						width: "100%",
						maxWidth: "100%",
					}}
				>
					Patient Name:
				</Text>
				<Text
					style={{
						marginTop: 10,
						fontSize: 20,
						textAlign: "center",
						width: "100%",
						maxWidth: "100%",
					}}
				>
					Confirmation #:
				</Text>
				<Text
					style={{
						marginTop: 10,
						fontSize: 20,
						textAlign: "center",
						width: "100%",
						maxWidth: "100%",
					}}
				>
					Date Of Service:
				</Text>
				<Text
					style={{
						marginTop: 10,
						marginLeft: 10,
						fontSize: 20,
						textAlign: "left",
						maxWidth: "50%",
					}}
				>
					Doctors To You, LLC{" "}
				</Text>
				<Text
					style={{
						marginTop: 10,
						marginLeft: 10,
						fontSize: 20,
						textAlign: "left",
						maxWidth: "50%",
					}}
				>
					1101 Pennsylvania Avenue, Suite 300 Washington, DC 20004.{" "}
				</Text>
				<Text
					style={{
						marginTop: 10,
						marginLeft: 10,
						fontSize: 20,
						textAlign: "left",
						maxWidth: "50%",
					}}
				>
					Phone: (202) 545-3279
				</Text>
				<Text
					style={{
						marginTop: 10,
						marginLeft: 10,
						fontSize: 20,
						textAlign: "left",
						maxWidth: "50%",
					}}
				>
					Fax: (202) 545-3888
				</Text>
				<Text
					style={{
						marginTop: 10,
						marginLeft: 10,
						fontSize: 20,
						textAlign: "left",
						maxWidth: "50%",
					}}
				>
					Email: care@doctorstoyou.com
				</Text>
			</View> */}
		</>
	);

	// Events and functions
	const handleOnClickConfirm = async (event: any) => {
		event.preventDefault();
		const da = document.getElementById("pdf-viewer")?.children[0] as any;
		var xhr = new XMLHttpRequest();
		xhr.responseType = "blob";
		xhr.onload = function () {
			var recoveredBlob = xhr.response;
			const reader = new FileReader();
			reader.addEventListener("loadend", (e: any) => {
				var byteArray = Array.from(new Uint8Array(e.target.result));
				// onInvoiceModalClose();
			});
			reader.readAsArrayBuffer(recoveredBlob);
		};
		xhr.open("GET", da.src);
		xhr.send();
	};

	return (
		<>
			<Modal
				open={invoiceModalOpen}
				onClose={onInvoiceModalClose}
				className="send-modal select-input-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">Itemized Cost</Typography>
						<IconButton onClick={onInvoiceModalClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<div>
							<Typography variant="body1" className="instruction">
								Are you sure, you want to proceed with the following data?
							</Typography>
							<div id="pdf-viewer" style={{ height: 500 }}>
								{/* <CustomPDFViewer content={pdfContent} /> */}
							</div>
						</div>
						<div className="modal-footer">
							<Button variant="contained" onClick={handleOnClickConfirm}>
								Confirm
							</Button>
							<Button variant="outlined" onClick={onInvoiceModalClose}>
								Cancel
							</Button>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
