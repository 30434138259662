import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Collapse,
	TableCell,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import {
	CalendarIcon,
	NotesPrimaryIcon,
	PhonePrimaryIcon,
	StatusCheckPrimaryIcon,
} from "assests/images";
import { CheckBoxComponent, ExpandMore } from "components";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import moment from "moment";
import React, { useContext, useState } from "react";
import historyService from "services/history-service";
import { createCommonAPICall } from "utility/helpers";
import { IBlockHistory } from "utility/interfaces";

type ComponentProps = {
	row: IBlockHistory;
	isSmallDevice: boolean;
	getBlockHistoryInfo: any;
};

export const BlockHistoryTableRow = (props: ComponentProps) => {
	//Page level local variables
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// Extract props
	const { row, isSmallDevice, getBlockHistoryInfo } = props;

	// useStates
	const [expanded, setExpanded] = useState(false);

	// Handled events and functions
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const handleClickUnblock = async () => {
		const data = await createCommonAPICall({
			requestBody: { Id: row.queryString },
			apiService: historyService.unblockRequest,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			getBlockHistoryInfo();
		}
	};

	return (
		<>
			{!isSmallDevice ? (
				<>
					<TableRow className="bg-white">
						<TableCell component="th" scope="row" className="wrap min-w-160">
							{row.patientfullName}
						</TableCell>
						<TableCell component="th" scope="row" className="wrap min-w-140">
							{row.phoneNumber}
						</TableCell>
						<TableCell component="th" scope="row" className="wrap min-w-150">
							{row.email}
						</TableCell>
						<TableCell component="th" scope="row" className="wrap min-w-130">
							{row.createdDate
								? moment(row.createdDate).format("MMM D, YYYY")
								: "-"}
						</TableCell>
						<TableCell component="th" scope="row" className="wrap min-w-160">
							{row.reason}
						</TableCell>
						<TableCell component="th" scope="row" className="wrap min-w-130">
							<CheckBoxComponent
								isChecked={row.isActive}
								handleOnChangeCheckbox={() => {}}
								label=""
								disabled
							/>
						</TableCell>
						<TableCell component="th" scope="row" className="wrap min-w-130">
							<Tooltip title="Unblock case">
								<Button
									id="table-button"
									className="action-btn action-btn-primary"
									onClick={handleClickUnblock}
								>
									Unblock
								</Button>
							</Tooltip>
						</TableCell>
					</TableRow>
				</>
			) : (
				// Mobile view
				<Card className="bg-white">
					<div className="card-container">
						<ExpandMore
							expand={expanded}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label="show more"
						>
							<CardHeader
								title={
									<React.Fragment>
										<span>{row.patientfullName}</span>
									</React.Fragment>
								}
							/>
							<CardContent className="card-header-content">
								<div className="card-subheader">
									<Typography variant="body2" color="text.secondary">
										{row.email}
									</Typography>
								</div>
							</CardContent>
						</ExpandMore>
						<Collapse in={expanded} timeout="auto" unmountOnExit>
							<CardContent>
								<div>
									<span>
										<img src={PhonePrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Phone:&nbsp;
										<span>{row.phoneNumber}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={CalendarIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Created Date:&nbsp;
										<span>
											{row.createdDate
												? moment(row.createdDate).format("MMM D, YYYY")
												: "-"}
										</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={NotesPrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Notes:&nbsp;
										<span>{row.phoneNumber}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={StatusCheckPrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Is Active:&nbsp;
										<span>{row.isActive ? "Yes" : "No"}</span>
									</Typography>
								</div>
								<div className="table-cell-btn-group">
									<Button variant="outlined" onClick={handleClickUnblock}>
										Unblock
									</Button>
								</div>
							</CardContent>
						</Collapse>
					</div>
				</Card>
			)}
		</>
	);
};
