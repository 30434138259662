import React, { useState } from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import {
	DocRequestIcon,
	ExportallIcon,
	ExportIcon,
	PhysicianIcon,
	SendIcon,
} from "assests/images";
import { useNavigate } from "react-router-dom";
import { AppRoutings } from "utility/enums/app-routings";
import { getTabStatusWiseName } from "utility/helpers";
import { IDashboardSearchCriteria } from "utility/interfaces";
import { UserContext } from "contexts/user-context";
import { AccountType } from "utility/enums/request-status";
import { RequestDTYSupportModal } from "components";

type ComponentProps = {
	handleClickSendLink: any;
	handleClickExport: any;
	handleClickExportAll: any;
	searchCriteria: IDashboardSearchCriteria;
};

export function DashboardTableTitle(props: ComponentProps) {
	// Extract Props
	const {
		handleClickSendLink,
		handleClickExport,
		handleClickExportAll,
		searchCriteria,
	} = props;

	const userRole = React.useContext(UserContext);
	// Page level local variables
	const navigate = useNavigate();

	// State variable
	const [requestDTYSupportOpen, setRequestDTYSupportOpen] = useState(false);

	return (
		<Box className="tab-item-header">
			<Typography variant="h2">
				Patients{" "}
				<span className="new">
					&nbsp;&#40;{getTabStatusWiseName(searchCriteria.RequestStatusId)}&#41;
				</span>
			</Typography>
			<Box className="tab-button-groups">
				<Tooltip title="Send Link">
					<Button variant="contained" onClick={handleClickSendLink}>
						<img src={SendIcon} alt="send link" />
						<span className="button-link">Send Link </span>
					</Button>
				</Tooltip>
				<Tooltip title="Create Requests">
					<Button
						variant="contained"
						onClick={() => {
							navigate(AppRoutings.CreateRequest, {
								state: {
									searchCriteria,
								},
							});
						}}
					>
						<img src={DocRequestIcon} alt="Create Request" />
						<span className="button-link">Create Requests</span>
					</Button>
				</Tooltip>
				{userRole.userRoleId == AccountType.Admin ? (
					<div>
						<Tooltip title="Export">
							<Button variant="contained" onClick={handleClickExport}>
								<img src={ExportIcon} alt="export" />
								<span className="button-link">Export</span>
							</Button>
						</Tooltip>
						<Tooltip title="Export All">
							<Button variant="contained" onClick={handleClickExportAll}>
								<img src={ExportallIcon} alt="export" />
								<span className="button-link">Export All</span>
							</Button>
						</Tooltip>
						<Tooltip title="Request DTY Support">
							<Button
								variant="contained"
								onClick={() => setRequestDTYSupportOpen(true)}
							>
								<img src={PhysicianIcon} alt="send link" />
								<span className="button-link">Request DTY Support</span>
							</Button>
						</Tooltip>
					</div>
				) : (
					""
				)}
			</Box>

			{/* Page modal */}
			<RequestDTYSupportModal
				modalOpen={requestDTYSupportOpen}
				onModalClose={() => {
					setRequestDTYSupportOpen(false);
				}}
				regionId={searchCriteria.RegionId}
			/>
		</Box>
	);
}
