import {
	Box,
	Button,
	FormControl,
	IconButton,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import { ArrowBack } from "assests/images";
import { UserContext } from "contexts/user-context";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountType } from "utility/enums/request-status";
import {
	IDropDownList,
	IScheduleData,
	IShiftSchedule,
	IWeeklyCoverageHours,
} from "utility/interfaces";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { createCommonAPICall } from "utility/helpers";
import roleService from "services/role-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { addDays } from "utility/helpers/common";
import { AppRoutings } from "utility/enums/app-routings";
import {
	AddEditShiftModal,
	CustomDatePopover,
	DayView,
	DevExSchedularView,
	MonthView,
	WeekView,
} from "components";

const Scheduling: React.FC = () => {
	// use context
	const contextUser = useContext(UserContext);
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// Use state
	const [regions, setRegions] = useState<IDropDownList[]>([
		{
			text: "All Regions",
			value: 0,
		},
		{
			text: "region1",
			value: 1,
		},
		{
			text: "region2",
			value: 2,
		},
		{
			text: "region3",
			value: 3,
		},
		{
			text: "region4",
			value: 4,
		},
	]);
	const [selectedRegion, setSelectedRegion] = useState(0);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [isCalenderOpen, setIsCalenderOpen] = useState(false);
	const [calenderRef, setCalenderRef] = useState<HTMLButtonElement | null>(
		null
	);
	const [selectedView, setSelectedView] = useState("M");
	const [dateString, setDateString] = useState("");
	const [scheduleData, setScheduleData] = useState<IScheduleData>({
		physicianList: [],
		shifts: [],
	});
	const [weeklyCoverageHours, setWeeklyCoverageHours] = useState<
		IWeeklyCoverageHours[]
	>([]);

	const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
	const [selectedShift, setSelectedshift] = useState<IShiftSchedule | null>(
		null
	);
	const [loggedInPhysicianId, setLoggedInPhysicianId] = useState<number | null>(
		null
	);
	const [isSmallDevice, setIsSmallDevice] = useState(false);
	const [shiftHistory, setShiftHistory] = useState<string>();

	//Page level local variable
	const navigate = useNavigate();

	// Handled events and functions
	const getShiftDetails = async () => {
		let startDate = "",
			endDate = "";
		switch (selectedView) {
			case "D":
				startDate = moment(selectedDate).format("MM/DD/YYYY");
				break;
			case "W":
				startDate = moment(selectedDate).startOf("week").format("MM/DD/YYYY");
				endDate = moment(selectedDate).endOf("week").format("MM/DD/YYYY");
				break;
			default:
				startDate = moment(selectedDate).startOf("month").format("MM/DD/YYYY");
				endDate = moment(selectedDate).endOf("month").format("MM/DD/YYYY");
				break;
		}

		const requestBody = {
			regionId: selectedRegion,
			startDate,
			endDate,
		};
		const data = await createCommonAPICall({
			requestBody,
			apiService: roleService.getShifts,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			console.log(data);
			setRegions(data.data.regionListFull);
			setScheduleData({
				physicianList: data.data.physicianList,
				shifts: data.data.shifts,
			});
			setWeeklyCoverageHours(data.data.weeklyCoverageHours);
			setLoggedInPhysicianId(data.data.loggedInPhysicianId);
		}
	};

	const handleOnClickBackCancel = () => navigate(-1);

	const handleDatePrevious = () => {
		switch (selectedView) {
			case "M":
				setSelectedDate(
					new Date(selectedDate.setMonth(selectedDate.getMonth() - 1))
				);
				break;
			case "W":
				setSelectedDate(addDays(selectedDate, -7));
				break;
			case "D":
				setSelectedDate(addDays(selectedDate, -1));
				break;
		}
	};

	const handleDateNext = () => {
		switch (selectedView) {
			case "M":
				setSelectedDate(
					new Date(selectedDate.setMonth(selectedDate.getMonth() + 1))
				);
				break;
			case "W":
				setSelectedDate(addDays(selectedDate, 7));
				break;
			case "D":
				setSelectedDate(addDays(selectedDate, 1));
				break;
		}
	};

	const handleChangeDateString = () => {
		if (contextUser.userRoleId === AccountType.Admin) {
			switch (selectedView) {
				case "M":
					setDateString(moment(selectedDate).format("MMM YYYY"));
					break;
				case "W":
					let today = new Date(selectedDate);
					let startDate = new Date(
						today.setDate(today.getDate() - today.getDay())
					);
					let endDate = new Date(
						today.setDate(today.getDate() - today.getDay() + 6)
					);
					let strDate = `${moment(startDate).format("MMM DD")} - ${moment(
						endDate
					).format("MMM DD, YYYY")}`;
					setDateString(strDate);
					break;
				case "D":
					setDateString(moment(selectedDate).format("dddd, MMM D, YYYY"));
					break;
			}
		} else {
			setDateString(
				`Schedule for: ${moment(selectedDate).format("MMM")} 1 - ${moment(
					selectedDate
				).format("MMM")} ${moment(selectedDate).daysInMonth()},  ${moment(
					selectedDate
				).format("YYYY")}`
			);
		}
	};

	const handleAddEditModalOpen = () => {
		setIsAddEditModalOpen(true);
	};

	const handleAddEditModalClose = () => {
		setShiftHistory(undefined);
		setIsAddEditModalOpen(false);
	};

	const handleShiftEditClick = async (shiftData: IShiftSchedule) => {
		if (loggedInPhysicianId) {
			if (shiftData == null || loggedInPhysicianId == shiftData.physicianId) {
				setSelectedshift(shiftData);
				handleAddEditModalOpen();
			}
		} else {
			if (new Date(shiftData.shiftDate) < new Date()) {
				let history = await getPhysicianShiftHistory(shiftData);
				if (history !== " \n\n ") setShiftHistory(history);
			}
			setSelectedshift(shiftData);
			handleAddEditModalOpen();
		}
	};

	const getPhysicianShiftHistory = async (shiftData: IShiftSchedule) => {
		const requestBody = {
			physicianId: shiftData.physicianId,
			shiftDate: shiftData.shiftDate,
			startTime: shiftData.startFull,
			endTime: shiftData.endFull,
		};

		const data = await createCommonAPICall({
			requestBody,
			apiService: roleService.getPhysicianShiftHistory,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			console.log(data);
			return data.data;
		}
		return "";
	};

	const onResize = () => {
		if (window.innerWidth > 1361) {
			setIsSmallDevice(false);
		} else {
			setIsSmallDevice(true);
		}
	};

	// Use effects
	useEffect(() => {
		handleChangeDateString();
		getShiftDetails();
	}, [selectedView, selectedDate, selectedRegion]);

	useEffect(() => {
		onResize();
		window.addEventListener("resize", onResize);
		return () => {
			window.removeEventListener("resize", onResize);
		};
	}, []);

	return (
		<Box>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="request-container-box">
					<div className="request-header">
						<Typography variant="h2">
							{contextUser.userRoleId === AccountType.Admin
								? "Scheduling"
								: "My Schedule"}
						</Typography>
						<Button variant="outlined" onClick={handleOnClickBackCancel}>
							<img src={ArrowBack} alt="arrow" />
							Back
						</Button>
					</div>
					{contextUser.userRoleId === AccountType.Admin && (
						<div className="request-header responsive-header">
							<div
								className="schedule-regions"
								style={{ marginBottom: "15px" }}
							>
								<FormControl className="region-search">
									<Select
										labelId="region-label"
										id="region"
										value={selectedRegion}
										name="region"
										label="Search by region"
										onChange={(event) => {
											if (typeof event.target.value == "number")
												setSelectedRegion(event.target.value);
											else setSelectedRegion(parseInt(event.target.value));
										}}
									>
										{regions.map((region) => {
											return (
												<MenuItem key={region.value} value={region.value}>
													{region.text}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</div>
							<div
								className="request-btn-group small-center"
								style={{ paddingTop: 0, marginBottom: "15px" }}
							>
								<Button
									variant="contained"
									onClick={() => {
										navigate(AppRoutings.ProviderOnCall);
									}}
								>
									Providers on Call
								</Button>
								<Button
									variant="contained"
									sx={{ marginLeft: "15px" }}
									onClick={() => {
										navigate(AppRoutings.ReviewShifts);
									}}
								>
									Shifts for review
								</Button>
								<Button
									variant="contained"
									sx={{ marginLeft: "15px" }}
									onClick={() => {
										setSelectedshift(null);
										handleAddEditModalOpen();
									}}
								>
									Add new shift
								</Button>
							</div>
						</div>
					)}
					<Typography variant="h4" fontWeight={500} marginBottom={"25px"}>
						{dateString}
					</Typography>
					<div className="request-header responsive-header">
						<div className="small-center">
							<IconButton
								className="custom-icon-btn"
								onClick={handleDatePrevious}
							>
								<ArrowBackIosNewIcon />
							</IconButton>
							<IconButton
								size="large"
								onClick={(e) => {
									setIsCalenderOpen(!isCalenderOpen);
									setCalenderRef(e.currentTarget);
								}}
							>
								<CalendarMonthIcon />
							</IconButton>
							<IconButton className="custom-icon-btn" onClick={handleDateNext}>
								<ArrowForwardIosIcon />
							</IconButton>
						</div>
						<div className="header-rigth-btn">
							<div className="request-header small-center request-btn-group">
								<Box>
									<span className="legend pending"></span>
									<p style={{ display: "inline" }}>Pending Shifts</p>
								</Box>
								<Box>
									<span className="legend approved"></span>
									<p style={{ display: "inline" }}>Approved Shifts</p>
								</Box>
							</div>
							<div
								className="small-center request-btn-group"
								style={{ paddingTop: 0 }}
							>
								{contextUser.userRoleId === AccountType.Admin ? (
									<>
										<Button
											variant={selectedView === "D" ? "contained" : "outlined"}
											sx={{ marginLeft: "15px" }}
											onClick={() => setSelectedView("D")}
										>
											Day
										</Button>
										<Button
											variant={selectedView === "W" ? "contained" : "outlined"}
											sx={{ marginLeft: "15px" }}
											onClick={() => setSelectedView("W")}
										>
											Week
										</Button>
										<Button
											variant={selectedView === "M" ? "contained" : "outlined"}
											sx={{ marginLeft: "15px" }}
											onClick={() => setSelectedView("M")}
										>
											Month
										</Button>
									</>
								) : (
									<Button
										variant="contained"
										color="success"
										onClick={() => {
											setSelectedshift(null);
											handleAddEditModalOpen();
										}}
									>
										Add New Shift
									</Button>
								)}
							</div>
						</div>
					</div>
					{isSmallDevice ? (
						<DevExSchedularView
							shifts={scheduleData.shifts}
							selectedView={selectedView}
							handleEditShiftClick={handleShiftEditClick}
							currentDate={selectedDate.toDateString()}
							weeklyCoverageHours={weeklyCoverageHours}
						/>
					) : (
						<div>
							{selectedView === "M" && (
								<MonthView
									scheduleData={scheduleData}
									currDate={selectedDate}
									weeklyCoverageHours={weeklyCoverageHours}
									handleEditShiftClick={handleShiftEditClick}
									loggedInPhysicianId={loggedInPhysicianId ?? undefined}
								/>
							)}
							{selectedView === "W" && (
								<WeekView
									scheduleData={scheduleData}
									currDate={selectedDate}
									weeklyCoverageHours={weeklyCoverageHours}
									handleEditShiftClick={handleShiftEditClick}
								/>
							)}
							{selectedView === "D" && (
								<DayView
									scheduleData={scheduleData}
									currDate={selectedDate}
									weeklyCoverageHours={weeklyCoverageHours}
									handleEditShiftClick={handleShiftEditClick}
								/>
							)}
						</div>
					)}
				</Box>
			</main>
			<CustomDatePopover
				isOpen={isCalenderOpen}
				anchorEl={calenderRef}
				onChange={(e: Date) => {
					setIsCalenderOpen(false);
					setSelectedDate(e);
				}}
				date={selectedDate}
				handleClose={() => setIsCalenderOpen(false)}
			/>
			<AddEditShiftModal
				isOpen={isAddEditModalOpen}
				handleClose={handleAddEditModalClose}
				shiftData={selectedShift}
				regions={regions.filter((r) => r.value != 0)}
				refreshCalender={getShiftDetails}
				loggedInPhysicianId={loggedInPhysicianId ?? undefined}
				shiftHistory={shiftHistory}
			/>
		</Box>
	);
};

export default Scheduling;
