import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	Button,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import {
	ArrowBack,
	DeleteIcon,
	DownloadIcon,
	UploadcloudBlueIcon,
} from "assests/images";
import { useNavigate, useParams } from "react-router-dom";
import { IDocumentInfo } from "utility/interfaces";
import {
	createCommonAPICall,
	getFileIconByType,
	getTabStatusWiseName,
	openSucessErrorModal,
} from "utility/helpers";
import { AppRoutings } from "utility/enums/app-routings";
import dashboardService from "services/dashboard-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { CLOUD_URL } from "config";
import { PreviewFileModal, WarningModal } from "components";
import PreviewIcon from "@mui/icons-material/Preview";

const ConcludeCare: React.FC = () => {
	// get query parameters
	const { caseId } = useParams();

	// Context Values
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// useStates
	const [providerNotes, setProviderNotes] = React.useState<string>("");
	const [patientFullName, setPatientFullName] = useState("");
	const [confirmDeleteModalOpen, setConfirmDeleteModal] = useState(false);
	const [confirmConculdeCareModal, setConfirmConcludeCareModal] =
		useState(false);
	const [deletedFileId, setDeletedFileId] = useState(0);
	// TODO: Dynamic
	const [documentList, setDocumentList] = React.useState<IDocumentInfo[]>([
		// {
		// 	requestWiseFileID: 1,
		// 	fileName: "document 1",
		// 	mimeType: "image",
		// 	url: "https://www.africau.edu/images/default/sample.pdf",
		// 	createdDate: "2005-10-02",
		// 	docType: 1,
		// },
		// {
		// 	requestWiseFileID: 2,
		// 	fileName: "document 2",
		// 	mimeType: "image",
		// 	url: "https://www.africau.edu/images/default/sample.pdf",
		// 	createdDate: "2005-10-02",
		// 	docType: 1,
		// },
		// {
		// 	requestWiseFileID: 3,
		// 	fileName: "document 3",
		// 	mimeType: "pdf",
		// 	url: "https://www.africau.edu/images/default/sample.pdf",
		// 	createdDate: "2008-10-02",
		// 	docType: 1,
		// },
		// {
		// 	requestWiseFileID: 4,
		// 	fileName: "document 4",
		// 	mimeType: "image",
		// 	url: "https://www.africau.edu/images/default/sample.pdf",
		// 	createdDate: "2009-10-02",
		// 	docType: 1,
		// },
	]);
	const [previewUrl, setPreviewUrl] = useState("");

	// get state value
	const { state } = window.history;

	// Handled events and functions
	const handleOnClickBackCancel = () =>
		navigate(
			AppRoutings.Dashboard.replace(
				":tabStatus",
				getTabStatusWiseName(
					state?.usr?.searchCriteria?.RequestStatusId
				).toLocaleLowerCase()
			),
			{
				state: {
					searchCriteria: state?.usr?.searchCriteria,
				},
			}
		);

	const handleOnClickSave = () => {
		console.log("On save button click handle here.", providerNotes);
		if (documentList.findIndex((doc) => doc.docType != 0) == -1) {
			openSucessErrorModal(
				setSuccessErrorContext,
				"Error",
				"Please Finalize Encounter Form to Close this Case!!!",
				false
			);
		} else {
			handleConfirmConcludeCareOpen();
		}
	};

	const handleConfirmConcludeCareOpen = () => {
		setConfirmConcludeCareModal(true);
	};

	const handleConfirmConcludeCareClose = () => {
		setConfirmConcludeCareModal(false);
	};

	const handleClickConfirmConclude = () => {
		console.log(caseId);
		const requestBody = {
			id: caseId ? caseId : "",
			providerNotes,
		};
		createCommonAPICall({
			requestBody,
			apiService: dashboardService.confirmConcludeCare,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		}).then((data) => {
			if (data.isSuccessfull) {
				navigate(
					AppRoutings.Dashboard.replace(
						":tabStatus",
						getTabStatusWiseName(
							state?.usr?.searchCriteria?.RequestStatusId
						).toLocaleLowerCase()
					),
					{
						state: {
							searchCriteria: state?.usr?.searchCriteria,
						},
					}
				);
			}
		});
	};

	// Handled event(s)
	const onSelectNewFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
		const fileNames: string[] = [];
		let formData = new FormData();
		if (event.target.files && event.target.files.length > 0) {
			Array.prototype.forEach.call(event.target.files, (file: File) => {
				fileNames.push(file.name);
				formData.append("files", file);
			});
			console.log("Upload login here", fileNames);
		}
		formData.append("requestId", caseId ? caseId : "");
		formData.append("RequestGuid", "");
		formData.append("docType", "12");

		createCommonAPICall({
			requestBody: formData,
			apiService: dashboardService.postUploadFiles,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		}).then((data) => {
			console.log(data);
			for (let i = 0; i < data.data.length; i++) {
				let file = data.data[i];
				let uploadedDoc = {
					requestWiseFileID: file.attachmentID,
					fileName: file.fileName,
					mimeType: file.mimeType.split("/")[1],
					url: `${CLOUD_URL}${file.path.replaceAll("/", "%2F")}?alt=media`,
					createdDate: new Date().toDateString(),
					docType: 0,
				};
				setDocumentList([...documentList, uploadedDoc]);
			}
		});
	};

	const handleClickConfirmDeleteModalOpen = () => {
		setConfirmDeleteModal(true);
	};

	const handleClickConfirmDeleteModalClose = () => {
		setConfirmDeleteModal(false);
	};

	const handleFileDelete = (requestWiseFileId: number) => {
		setDeletedFileId(requestWiseFileId);
		handleClickConfirmDeleteModalOpen();
	};

	const handleConfirmDelete = async () => {
		console.log("Deleted", deletedFileId);
		const requestBody = {
			requestWiseFileId: deletedFileId.toString(),
			requestId: caseId ? caseId : "",
		};

		createCommonAPICall({
			requestBody,
			apiService: dashboardService.deleteFiles,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		}).then((data) => {
			// let deletedFile = documentList.findIndex(
			// 	(doc) => doc.requestWiseFileID == deletedFileId
			// );
			// let afterDeleteList = documentList;
			// afterDeleteList.splice(deletedFile, 1);
			// setDocumentList(afterDeleteList);
			if (data.isSuccessfull) {
				getNoteDetails();
			}
		});
	};

	const getNoteDetails = () => {
		// API call here
		console.log(caseId);
		const requestBody = {
			id: caseId ? caseId : "",
		};
		createCommonAPICall({
			requestBody,
			apiService: dashboardService.getConcludeCareInfo,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		}).then((data) => {
			setPatientFullName(data.data.fullName);
			setDocumentList(data.data.filesList);
		});
	};

	// Page level variables
	const navigate = useNavigate();

	// useEffects
	useEffect(() => {
		if (caseId) getNoteDetails();
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="request-container-box">
					<div className="request-header">
						<Typography variant="h2">Conclude Care</Typography>
						<Button variant="outlined" onClick={handleOnClickBackCancel}>
							<img src={ArrowBack} alt="arrow" />
							Back
						</Button>
					</div>
					<Box className="request-box">
						<div className="confirm-number uploaded-data">
							<label>Patient Name</label>
							{/* <Typography variant="h4">Rajesh Satvara</Typography> */}
							<Typography variant="h4">{patientFullName}</Typography>
						</div>
						<div className="attachment">
							<Typography variant="h4">Encounter Forms</Typography>
							<Button
								variant="outlined"
								component="label"
								disableElevation
								disableRipple
								disableFocusRipple
								className="min-w-111px"
							>
								<img src={UploadcloudBlueIcon} alt="upload" />
								<span>Upload</span>
								<input
									onChange={onSelectNewFiles}
									hidden
									accept="*"
									multiple
									type="file"
								/>
							</Button>
						</div>
						<TableContainer className="upload-table-container conclude-table-container">
							<Table className="upload-table conclude-table">
								<TableHead>
									<TableRow>
										<TableCell className="min-w-210">Documents</TableCell>
										<TableCell align="center" sx={{ width: 142 }}>
											Actions
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{documentList.map((row, index) => {
										return (
											<TableRow hover tabIndex={-1} key={row.requestWiseFileID}>
												<TableCell
													component="th"
													scope="row"
													className="upload-file-col"
												>
													<div className="upload-file">
														<img
															src={getFileIconByType(row.mimeType)}
															alt="file"
														/>
														<span>{row.fileName}</span>
													</div>
												</TableCell>
												<TableCell align="center">
													<div className="upload-actions">
														<Tooltip title="Download">
															<IconButton
																disableFocusRipple
																disableRipple
																href={row.url}
															>
																<img src={DownloadIcon} alt="download" />
															</IconButton>
														</Tooltip>
														<Tooltip title="Preview">
															<IconButton
																id="previewBtn"
																disableFocusRipple
																disableRipple
																onClick={() => setPreviewUrl(row.url)}
															>
																<PreviewIcon />
															</IconButton>
														</Tooltip>
														<Tooltip title="Delete">
															<IconButton
																disabled={row.docType != 0}
																disableFocusRipple
																disableRipple
																onClick={() =>
																	handleFileDelete(row.requestWiseFileID)
																}
															>
																<img src={DeleteIcon} alt="delete" />
															</IconButton>
														</Tooltip>
													</div>
												</TableCell>
											</TableRow>
										);
									})}

									{documentList.length == 0 && (
										<TableRow>
											<TableCell align="center">No Documents Found</TableCell>
											<TableCell align="center"></TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
						<div>
							<Typography variant="body1" className="input-title">
								Provider Notes
							</Typography>
							<TextField
								id="description"
								label="Provider Notes"
								multiline
								maxRows={1}
								value={providerNotes}
								onChange={(e) => {
									setProviderNotes(e.target.value);
								}}
								className="textarea"
								inputProps={{
									style: {
										minHeight: "80px",
										overflow: "auto",
									},
								}}
								fullWidth
							/>
						</div>
						<div className="request-btn-group">
							<Button variant="contained" onClick={handleOnClickSave}>
								Conclude Care
							</Button>
						</div>
					</Box>
				</Box>
				<WarningModal
					isModalOpen={confirmDeleteModalOpen}
					handleOnClickCloseModal={handleClickConfirmDeleteModalClose}
					handleOnClickOk={handleConfirmDelete}
					warningMessage={"Are you sure you want to delete this File?"}
					title={"Confirmation"}
					okButtonText={"Delete"}
					closeButtonText={"Cancel"}
				></WarningModal>
				<WarningModal
					isModalOpen={confirmConculdeCareModal}
					handleOnClickCloseModal={handleConfirmConcludeCareClose}
					handleOnClickOk={handleClickConfirmConclude}
					warningMessage={"Are you sure you want to Close this Case?"}
					title={"Confirmation"}
					okButtonText={"Ok"}
					closeButtonText={"Cancel"}
				></WarningModal>
				<PreviewFileModal
					isModelOpen={previewUrl !== ""}
					handleModalClose={() => setPreviewUrl("")}
					url={previewUrl}
				/>
			</main>
		</>
	);
};

export default ConcludeCare;
