import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	Tooltip,
	Typography,
} from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import { commonModalStyle } from "utility/helpers";
import { toast } from "react-toastify";
import { IDropDownList } from "utility/interfaces";

type ComponentProps = {
	modalOpen: boolean;
	onModalClose: any;
};

export function SendHistoryModal(props: ComponentProps) {
	// Extract Props
	const { modalOpen, onModalClose } = props;

	// useStates
	const [physician, setPhysician] = useState("");
	const [physicianList, setPhysicianList] = useState<IDropDownList[]>([
		{
			text: "physician 1",
			value: 1,
		},
		{
			text: "physician 2",
			value: 2,
		},
		{
			text: "physician 22",
			value: 22,
		},
		{
			text: "physician 21",
			value: 21,
		},
	]);

	// Events and functions
	const handleOnClickSend = () => {
		if (!physician) {
			toast.error("Please select physician.");
			return;
		}
		console.log(physician);
	};

	// useEffects
	useEffect(() => {
		setPhysician("");
	}, [modalOpen]);

	return (
		<>
			<Modal
				open={modalOpen}
				onClose={onModalClose}
				className="send-modal select-input-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">Send History</Typography>
						<IconButton onClick={onModalClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<div>
							<FormControl fullWidth className="select-input radio-inputs">
								<InputLabel id="business-label">Select Physician</InputLabel>
								<Select
									labelId="business-label"
									id="business-name"
									name="physician"
									value={physician}
									onChange={(e) => setPhysician(e.target.value)}
									label="Select Physician"
									MenuProps={{
										className: "select-input-modal",
									}}
								>
									{physicianList.map((physician, index) => {
										return (
											<MenuItem key={index} value={physician.value}>
												{physician.text}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</div>
						<div className="modal-footer">
							<Button
								variant="contained"
								disabled={physician ? false : true}
								onClick={handleOnClickSend}
							>
								Send
							</Button>
							<Tooltip title="Cancel">
								<Button variant="outlined" onClick={onModalClose}>
									Cancel
								</Button>
							</Tooltip>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
