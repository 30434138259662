import { styled } from "@mui/material/styles";
import { Button, ButtonProps } from "@mui/material";
import React from "react";

interface ExpandMoreProps extends ButtonProps {
	expand: boolean;
}

export const ExpandMore = styled((props: ExpandMoreProps) => {
	const { expand, ...other } = props;
	return (
		<Button {...other} disableFocusRipple disableElevation disableRipple />
	);
})(({ theme, expand }) => ({
	marginLeft: "auto",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));
