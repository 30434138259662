import React, { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getTabStatusWiseName } from "utility/helpers";
import { AppRoutings } from "utility/enums/app-routings";
import { IDocumentInfo } from "utility/interfaces";
import { ViewDocuments } from "components";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { createCommonAPICall } from "utility/helpers";
import dashboardService from "services/dashboard-service";

const ViewUploads: React.FC = () => {
	// get query parameters
	const { caseId } = useParams();

	// get state value
	const { state } = window.history;

	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// useStates
	// TODO: Dynamic
	const [documentList, setDocumentList] = useState<IDocumentInfo[]>([]);
	// ([
	// 	{
	// 		id: 1,
	// 		name: "document 1",
	// 		type: "image",
	// 		url: "https://www.africau.edu/images/default/sample.pdf",
	// 		uploadDate: "2006-10-02",
	// 	},
	// 	{
	// 		id: 2,
	// 		name: "document 2",
	// 		type: "image",
	// 		url: "https://www.africau.edu/images/default/sample.pdf",
	// 		uploadDate: "2005-10-02",
	// 	},
	// 	{
	// 		id: 3,
	// 		name: "document 3",
	// 		type: "pdf",
	// 		url: "https://www.africau.edu/images/default/sample.pdf",
	// 		uploadDate: "2004-10-02",
	// 	},
	// 	{
	// 		id: 4,
	// 		name: "document 4",
	// 		type: "image",
	// 		url: "https://www.africau.edu/images/default/sample.pdf",
	// 		uploadDate: "2010-10-02",
	// 	},
	// ]);
	const [patientName, setPatientName] = useState("");
	const [confirmationNumber, setConfirmationNumber] = useState("");

	// Handled events and functions
	const handleOnClickBackCancel = () =>
		navigate(
			state?.usr?.backURL
				? state?.usr?.backURL
				: AppRoutings.Dashboard.replace(
						":tabStatus",
						getTabStatusWiseName(
							state?.usr?.searchCriteria?.RequestStatusId
						).toLocaleLowerCase()
					),
			{
				state: {
					searchCriteria: state?.usr?.searchCriteria,
					backURL: state?.usr?.prevBackURL,
				},
			}
		);

	const getUploadDetails = async () => {
		//console.log(caseId);
		const data = await createCommonAPICall({
			requestBody: { requestId: caseId },
			apiService: dashboardService.getViewUploadsInfo,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		console.log(data.data.fileNames);
		if (data.data && data.isSuccessfull) {
			setPatientName(
				data.data.clientFirstName + " " + data.data.clientLastName
			);
			setConfirmationNumber(data.data.confirmationNumber);
			if (data.data.fileNames != null && data.data.fileNames.length > 0)
				setDocumentList(data.data.fileNames);
			else setDocumentList([]);
		}
	};

	// Page level variables
	const navigate = useNavigate();

	// useEffects
	useEffect(() => {
		if (caseId) getUploadDetails();
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<ViewDocuments
				requestId={caseId!}
				patientName={patientName}
				confirmationNo={confirmationNumber}
				documentList={documentList}
				handleOnClickBackCancel={handleOnClickBackCancel}
				callAfterUpload={getUploadDetails}
			/>
		</>
	);
};

export default ViewUploads;
