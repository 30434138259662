import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Constants } from "utility/enums/constants";
import { SortOrder } from "utility/enums/sort-order";
import {
	ICancelHistorySearchCriteria,
	ICancelHistoryList,
} from "utility/interfaces";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { CancelHistoryTable } from "components";

const CancelHistory: React.FC = () => {
	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// useStates
	const [cancelHistoryPageInfo, setCancelHistoryPageInfo] =
		React.useState<ICancelHistoryList | null>(null);

	const [searchCriteria, setSearchCriteria] =
		useState<ICancelHistorySearchCriteria>({
			PageIndexId: 0,
			PageSize: Constants.DefaultTablePageSize.valueOf(),
			SortOrder: SortOrder.ascending,
			SortBy: "",
			SearchBy: "",
			name: "",
			date: "",
			email: "",
			phoneNumber: "",
		});

	// Handled events and functions
	const handleOnSearchCriteriaChange = (
		newSearchCriteria: ICancelHistorySearchCriteria
	) => {
		setSearchCriteria(newSearchCriteria);
	};
	const getHistoryPageGrids = async () => {
		console.log(searchCriteria);
		// const data = await createCommonAPICall({
		// 	requestBody: searchCriteria,
		// 	apiService: historyService.getSearchFields,
		// 	showSuccessMessage: false,
		// 	showErrorMessage: true,
		// 	setSuccessErrorContext,
		// });
		// if (data && data.data) setHistoryPageInfo(data.data);
		setCancelHistoryPageInfo({
			histories: [
				{
					id: 1,
					queryString: "Renish ribadiya",
					patientName: "Renish ribadiya",
					phoneNumber: "Renish ribadiya",
					email: "renishkumar.ribadiya@tatvasoft.com",
					modifiedDate: "Jun 8, 2018",
					cancelledByAdminNote:
						"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
					cancelledByPhysicianNote:
						"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
				},
				{
					id: 1324,
					queryString: "Renish ribadiya",
					patientName: "Renish ribadiya",
					phoneNumber: "Renish ribadiya",
					email: "renishkumar.ribadiya@tatvasoft.com",
					modifiedDate: "Jun 8, 2018",
					cancelledByAdminNote:
						"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
					cancelledByPhysicianNote:
						"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
				},
				{
					id: 1222,
					queryString: "Renish ribadiya",
					patientName: "Renish ribadiya",
					phoneNumber: "Renish ribadiya",
					email: "renishkumar.ribadiya@tatvasoft.com",
					modifiedDate: "Jun 8, 2018",
					cancelledByAdminNote:
						"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
					cancelledByPhysicianNote:
						"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
				},
				{
					id: 133,
					queryString: "Renish ribadiya",
					patientName: "Renish ribadiya",
					phoneNumber: "Renish ribadiya",
					email: "renishkumar.ribadiya@tatvasoft.com",
					modifiedDate: "Jun 8, 2018",
					cancelledByAdminNote:
						"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
					cancelledByPhysicianNote:
						"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
				},
				{
					id: 13,
					queryString: "Renish ribadiya",
					patientName: "Renish ribadiya",
					phoneNumber: "Renish ribadiya",
					email: "renishkumar.ribadiya@tatvasoft.com",
					modifiedDate: "Jun 8, 2018",
					cancelledByAdminNote:
						"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
					cancelledByPhysicianNote:
						"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
				},
				{
					id: 112,
					queryString: "Renish ribadiya",
					patientName: "Renish ribadiya",
					phoneNumber: "Renish ribadiya",
					email: "renishkumar.ribadiya@tatvasoft.com",
					modifiedDate: "Jun 8, 2018",
					cancelledByAdminNote:
						"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
					cancelledByPhysicianNote:
						"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
				},
				{
					id: 122,
					queryString: "Renish ribadiya",
					patientName: "Renish ribadiya",
					phoneNumber: "Renish ribadiya",
					email: "renishkumar.ribadiya@tatvasoft.com",
					modifiedDate: "Jun 8, 2018",
					cancelledByAdminNote:
						"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
					cancelledByPhysicianNote:
						"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
				},
				{
					id: 12,
					queryString: "Renish ribadiya",
					patientName: "Renish ribadiya",
					phoneNumber: "Renish ribadiya",
					email: "renishkumar.ribadiya@tatvasoft.com",
					modifiedDate: "Jun 8, 2018",
					cancelledByAdminNote:
						"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
					cancelledByPhysicianNote:
						"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
				},
				{
					id: 111,
					queryString: "Renish ribadiya",
					patientName: "Renish ribadiya",
					phoneNumber: "Renish ribadiya",
					email: "renishkumar.ribadiya@tatvasoft.com",
					modifiedDate: "Jun 8, 2018",
					cancelledByAdminNote:
						"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
					cancelledByPhysicianNote:
						"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
				},
				{
					id: 11,
					queryString: "Renish ribadiya",
					patientName: "Renish ribadiya",
					phoneNumber: "Renish ribadiya",
					email: "renishkumar.ribadiya@tatvasoft.com",
					modifiedDate: "Jun 8, 2018",
					cancelledByAdminNote:
						"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
					cancelledByPhysicianNote:
						"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
				},
			],
			totalRecords: 100,
		});
	};

	//Page level local variable
	const navigate = useNavigate();

	// useEffects
	useEffect(() => {
		getHistoryPageGrids();
	}, [searchCriteria]);

	return (
		<>
			<Box>
				<div className="chatbox-overlay"></div>
				<main className="main-content">
					<div
						className="overlay"
						onClick={() => document.body.classList.toggle("sidebar-toggle")}
					></div>
					<Box className="tab-item-header">
						<Typography variant="h2">Cancel History</Typography>
					</Box>
					<CancelHistoryTable
						handleOnSearchCriteriaChange={handleOnSearchCriteriaChange}
						searchCriteria={searchCriteria}
						rowData={cancelHistoryPageInfo}
					/>
				</main>
			</Box>
		</>
	);
};

export default CancelHistory;
