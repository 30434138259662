import { ViewState } from "@devexpress/dx-react-scheduler";
import {
	AppointmentTooltip,
	Appointments,
	DayView,
	MonthView,
	Scheduler,
	WeekView,
} from "@devexpress/dx-react-scheduler-material-ui";
import { IconButton, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { IShiftSchedule, IWeeklyCoverageHours } from "utility/interfaces";

type ComponentProps = {
	shifts: IShiftSchedule[];
	selectedView: string;
	currentDate: string;
	handleEditShiftClick: any;
	weeklyCoverageHours: IWeeklyCoverageHours[];
};

interface CustomDayScaleProps extends WeekView.DayScaleCellProps {
	weeklyCoverageHours: IWeeklyCoverageHours[];
}

function CustomAppointment({
	style,
	children,
	data,
	onclick,
	...restProps
}: any) {
	return (
		<Appointments.Appointment
			data={data}
			{...restProps}
			style={{
				...style,
			}}
			onClick={() => onclick(data)}
			className={data.status == 1 ? "redShifts" : "greenShifts"}
		>
			{children}
		</Appointments.Appointment>
	);
}

function TimeScaleCustom() {
	return <></>;
}

const dayScaleCell = ({
	startDate,
	today,
	weeklyCoverageHours,
}: CustomDayScaleProps) => (
	<td style={{ height: "50px", textAlign: "center" }}>
		<p
			style={{
				color: today ? "#00bce7" : "#000",
				fontSize: "15px",
				fontWeight: today ? "bold" : "normal",
			}}
		>
			{Intl.DateTimeFormat("en-US", {
				weekday: "short",
			}).format(startDate)}
		</p>
		<p
			style={{
				color: today ? "#00bce7" : "#000",
				fontSize: "15px",
				fontWeight: today ? "bold" : "normal",
			}}
		>
			{Intl.DateTimeFormat("en-US", {
				day: "2-digit",
			}).format(startDate)}
		</p>
		<p
			style={{
				fontSize: "12px",
				border: "1px solid #e0e0e0",
			}}
		>
			{weeklyCoverageHours.find(
				(day) => new Date(day.date).getDate() == new Date(startDate).getDate()
			)?.totalHours ?? 0}
			/24
		</p>
	</td>
);

export function DevExSchedularView(props: ComponentProps) {
	// Extract props
	const {
		shifts,
		selectedView,
		currentDate,
		handleEditShiftClick,
		weeklyCoverageHours,
	} = props;

	const handleEditClick = (data: IShiftSchedule) => {
		handleEditShiftClick(data);
	};

	return (
		<>
			<Paper>
				<Scheduler data={shifts}>
					<ViewState
						currentDate={currentDate}
						currentViewName={
							selectedView === "M"
								? "Month"
								: selectedView === "W"
									? "Week"
									: "Day"
						}
					/>
					<DayView cellDuration={60} />
					<WeekView
						cellDuration={60}
						timeScaleLayoutComponent={TimeScaleCustom}
						dayScaleCellComponent={(p) =>
							dayScaleCell({ ...p, weeklyCoverageHours: weeklyCoverageHours })
						}
					/>
					<MonthView />
					<Appointments
						appointmentComponent={(appointmentProps) =>
							CustomAppointment({
								...appointmentProps,
								onclick: handleEditClick,
							})
						}
					/>
				</Scheduler>
			</Paper>
		</>
	);
}
