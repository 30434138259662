import React, { useContext, useEffect, useState } from "react";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { UserContext } from "contexts/user-context";
import {
	Box,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import {
	IDropDownList,
	IPendingBiWeeklyTimesheet,
	ITimeSheetDetailsSearchCriteria,
	ITimesheetDetailsInfo,
	ITimesheetReimbursementInfo,
} from "utility/interfaces";
import { AccountType } from "utility/enums/request-status";
import profileService from "services/profile-service";
import { createCommonAPICall } from "utility/helpers";
import timehseetService from "services/timehseet-service";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "assests/images";
import { Constants } from "utility/enums/constants";
import { SortOrder } from "utility/enums/sort-order";
import {
	PendingTimesheettable,
	TimeSheetDetailsTable,
	TimesheetReimbursementTable,
} from "components";
import { AppRoutings } from "utility/enums/app-routings";

const TimesheetDetails: React.FC = () => {
	// Context variables
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const contextUser = useContext(UserContext);
	const navigate = useNavigate();

	// State variables
	const [searchCriteria, setSearchCriteria] =
		useState<ITimeSheetDetailsSearchCriteria>({
			PageIndexId: 0,
			PageSize: Constants.DefaultTablePageSize.valueOf(),
			SortBy: "timesheetdate",
			SortOrder: SortOrder.ascending,
			SearchBy: "",
			physicianId: 0,
			timeSheetPeriodId: 0,
		});
	const [physicianList, setPhysicianList] = useState<IDropDownList[]>([]);
	const [isTimesheetApproved, setIsTimesheetApproved] = useState(false);
	const [isFinalized, setIsFinalized] = useState(false);
	const [queryString, setQueryString] = useState("");
	const [pendingTimesheetInfo, setPendingTimesheetInfo] =
		useState<IPendingBiWeeklyTimesheet>({
			startDate: "",
			endDate: "",
			queryString: "",
		});
	const [timesheetPeriodList, setTimesheetPeriodList] = useState<
		IDropDownList[]
	>([]);
	const [dailyTimesheetInfo, setDailyTimesheetInfo] =
		useState<ITimesheetDetailsInfo>({
			totalRecords: 0,
			timeSheet: [],
		});

	const [timeSheetReimbursementInfo, setTimeSheetReimbursementInfo] =
		useState<ITimesheetReimbursementInfo>({
			totalRecords: 0,
			timeSheetReimbursement: [],
		});

	// Handle events
	const getTableFilters = async () => {
		let tsPeriodId = await getTimesheetDropdown();
		if (contextUser.userRoleId === AccountType.Admin) {
			let providerId = await getPhysicianDropDownList();
			setSearchCriteria({
				...searchCriteria,
				timeSheetPeriodId: tsPeriodId,
				physicianId: providerId,
			});
		}
		if (contextUser.userRoleId == AccountType.Physician) {
			const data = await createCommonAPICall({
				apiService: profileService.getLoggedInUserId,
			});
			setSearchCriteria({
				...searchCriteria,
				timeSheetPeriodId: tsPeriodId,
				physicianId: data.data.id,
			});
		}
	};
	const getTimesheetDropdown = async () => {
		const data = await createCommonAPICall({
			apiService: timehseetService.getTimesheetPeriodDropDownList,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});
		if (data.isSuccessfull) {
			setTimesheetPeriodList(data.data);
			return data.data[0].value;
		}
		return 0;
	};

	const getPhysicianDropDownList = async () => {
		const data = await createCommonAPICall({
			apiService: profileService.getPhysicianDropDownList,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});
		console.log(data);
		if (data.isSuccessfull) {
			setPhysicianList(data.data);
			console.log(physicianList);
			return data.data[0].value;
		}
		return 0;
	};

	const handleOnClickBackCancel = () => navigate(-1);

	const handleOnChangeSearchCriteria = (
		searchCriteria: ITimeSheetDetailsSearchCriteria
	) => {
		setSearchCriteria(searchCriteria);
	};

	const getTimeSheetGrid = async () => {
		const data = await createCommonAPICall({
			requestBody: searchCriteria,
			apiService: timehseetService.getTimeSheetGrid,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			console.log(data);
			setIsTimesheetApproved(data.data.isTimeSheetApproved);
			if (data.data.isTimeSheetApproved) {
				setDailyTimesheetInfo(data.data.timeSheet);
				setTimeSheetReimbursementInfo(data.data.timeSheetReimbursement);
				setQueryString(data.data.queryString);
			} else {
				setIsFinalized(data.data.isFinalized);
				if (data.data.isFinalized) {
					setPendingTimesheetInfo(data.data.biWeeklyTimesheet);
				}
			}
		}
	};

	// Use effects
	useEffect(() => {
		getTableFilters();
	}, []);

	useEffect(() => {
		if (searchCriteria.physicianId > 0 && searchCriteria.timeSheetPeriodId > 0)
			getTimeSheetGrid();
	}, [searchCriteria]);

	return (
		<Box>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="request-container-box">
					<div className="request-header">
						<Typography variant="h2">Timesheets</Typography>
						<Button variant="outlined" onClick={handleOnClickBackCancel}>
							<img src={ArrowBack} alt="arrow" />
							Back
						</Button>
					</div>
					<Box className="request-box">
						<div className="request-header responsive-header">
							<div
								style={{
									width:
										contextUser.userRoleId === AccountType.Admin
											? "100%"
											: "60%",
								}}
							>
								{contextUser.userRoleId === AccountType.Admin && (
									<FormControl className="region-search timesheet-filters">
										<InputLabel id="physician-label">
											Search by Physician
										</InputLabel>
										<Select
											labelId="physician-label"
											id="physician"
											value={searchCriteria.physicianId}
											name="physician"
											label="Search by Physician"
											onChange={(event) => {
												setSearchCriteria({
													...searchCriteria,
													physicianId: +event.target.value,
												});
											}}
											MenuProps={{ className: "custom-select-dropdown" }}
										>
											{physicianList.map((physician) => {
												return (
													<MenuItem
														key={physician.value}
														value={physician.value}
													>
														{physician.text}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								)}

								<FormControl className="region-search timesheet-filters">
									<InputLabel id="timesheetperiod-label">
										Search by Timesheetperiod
									</InputLabel>
									<Select
										labelId="timesheetperiod-label"
										id="timesheetperiod"
										value={searchCriteria.timeSheetPeriodId}
										name="timesheetperiod"
										label="Search by Timesheetperiod"
										onChange={(event) => {
											setSearchCriteria({
												...searchCriteria,
												timeSheetPeriodId: +event.target.value,
											});
										}}
										MenuProps={{ className: "custom-select-dropdown" }}
									>
										{timesheetPeriodList.map((timesheetPeriod) => {
											return (
												<MenuItem
													key={timesheetPeriod.value}
													value={timesheetPeriod.value}
												>
													{timesheetPeriod.text}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</div>
							{queryString !== "" && (
								<Button
									variant="outlined"
									onClick={() => {
										navigate(
											AppRoutings.BiWeeklyTimesheet.replace(
												":data",
												queryString
											),
											{
												state: {
													backURL: AppRoutings.Invoicing,
												},
											}
										);
									}}
								>
									Finalize Timesheet
								</Button>
							)}
						</div>

						{isTimesheetApproved ? (
							<TimeSheetDetailsTable dailyTimesheetInfo={dailyTimesheetInfo} />
						) : (
							<>
								{isFinalized ? (
									<>
										<Typography variant="h4">
											Pending Bi-Weekly timesheet
										</Typography>
										<PendingTimesheettable
											pendingTimesheetInfo={pendingTimesheetInfo}
										/>
									</>
								) : (
									<Typography
										variant="h5"
										className="no-record"
										sx={{ padding: 3 }}
									>
										{
											physicianList.find(
												(t) => t.value == searchCriteria.physicianId
											)?.text
										}{" "}
										has not finalized the timesheet in specified time period.
									</Typography>
								)}
							</>
						)}
					</Box>
					{isTimesheetApproved && (
						<Box className="request-box another-table">
							<Typography variant="h4">TimeSheet Reimbursement</Typography>
							<TimesheetReimbursementTable
								timesheetReimbursementInfo={timeSheetReimbursementInfo}
								searchCriteria={searchCriteria}
								handleOnChangeSearchCriteria={handleOnChangeSearchCriteria}
							/>
						</Box>
					)}
				</Box>
			</main>
		</Box>
	);
};

export default TimesheetDetails;
