import React, { useContext, useState } from "react";
import {
	Button,
	TableCell,
	TableRow,
	ButtonProps,
	Card,
	CardContent,
	Typography,
	Collapse,
	CardHeader,
	Checkbox,
	Tooltip,
} from "@mui/material";
import {
	AdministratorIcon,
	CalendarIcon,
	CheckIcon,
	CrossRedIcon,
	DoctorPrimaryIcon,
	LocationIcon,
	MailPrimaryIcon,
	NotesIcon,
	NotesPrimaryIcon,
	PatientPrimaryIcon,
	PhonePrimaryIcon,
	PhysicianIcon,
	PhysicianSecondaryIcon,
	StatusCheckPrimaryIcon,
	UncheckIcon,
} from "assests/images";
import { styled } from "@mui/material/styles";
import {
	IHistoryRequest,
	IRequestDataSearchCriteria,
} from "utility/interfaces";
import moment from "moment";
import { createCommonAPICall } from "utility/helpers";
import historyService from "services/history-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { useNavigate } from "react-router-dom";
import { AppRoutings } from "utility/enums/app-routings";
import { ViewMoreModal, WarningModal } from "components";

type ComponentProps = {
	row: IHistoryRequest;
	isSmallDevice: boolean;
	searchCriteria: IRequestDataSearchCriteria;
	getRequestData: any;
	handleSelectRow: Function;
	isRowSelected: boolean;
};

interface ExpandMoreProps extends ButtonProps {
	expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
	const { expand, ...other } = props;
	return (
		<Button {...other} disableFocusRipple disableElevation disableRipple />
	);
})(({ theme, expand }) => ({
	marginLeft: "auto",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));

export function RequestDataTableRow(props: ComponentProps) {
	// useStates
	const [expanded, setExpanded] = useState(false);
	const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);
	const [isViewMoreModalOpen, setIsViewMoreModalOpen] = useState(false);

	// Use context
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// Extract Props
	const {
		row,
		isSmallDevice,
		searchCriteria,
		getRequestData,
		handleSelectRow,
		isRowSelected,
	} = props;

	// page level variables
	const navigate = useNavigate();

	// Handled events and functions
	const handleClickDelete = async () => {
		console.log(row.queryString);
		const requestBody = {
			Id: row.queryString,
		};

		const data = await createCommonAPICall({
			requestBody,
			apiService: historyService.deleteRequestData,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			getRequestData();
		}
	};

	const handleOnClickRequestData = (event: any) => {
		console.log(event.target.id);
		if (event.target.id !== "table-button") {
			navigate(AppRoutings.ViewCase.replace(":caseId", row.queryString), {
				state: {
					searchCriteria,
					backURL: AppRoutings.HistoryRequestData,
				},
			});
		}
	};

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const CheckedboxIcon = () => {
		return <img src={CheckIcon} alt="checkbox" />;
	};
	const CheckboxIcon = () => {
		return <img src={UncheckIcon} alt="checkbox" />;
	};

	return (
		<>
			{!isSmallDevice ? (
				<>
					<TableRow className="bg-white" onClick={handleOnClickRequestData}>
						<TableCell padding="checkbox" align="center">
							<Checkbox
								onClick={(e) => {
									e.stopPropagation();
									handleSelectRow(row.requestId);
								}}
								icon={<CheckboxIcon />}
								checkedIcon={<CheckedboxIcon />}
								checked={isRowSelected}
								disableRipple
								disableFocusRipple
							/>
						</TableCell>
						<TableCell
							component="th"
							scope="row"
							style={{ maxWidth: 50 }}
							className="wrap min-w-50"
						>
							{row.patientName}
						</TableCell>
						<TableCell style={{ maxWidth: 70 }} className="wrap min-w-70">
							{row.requestorType != "" ? row.requestorType : "-"}
						</TableCell>
						<TableCell style={{ maxWidth: 50 }} className="wrap min-w-50">
							{row.dateOfService
								? moment(row.dateOfService).format("MMM D,YYYY")
								: "-"}
						</TableCell>
						<TableCell style={{ maxWidth: 50 }} className="wrap min-w-50">
							{row.caseClosedDate
								? moment(row.caseClosedDate).format("MMM D,YYYY")
								: "-"}
						</TableCell>
						<TableCell style={{ maxWidth: 90 }} className="wrap min-w-90">
							{row.email}
						</TableCell>
						<TableCell style={{ maxWidth: 70 }} className="wrap min-w-70">
							{row.phoneNumber}
						</TableCell>
						<TableCell style={{ maxWidth: 70 }} className="wrap min-w-70">
							{row.address}
						</TableCell>
						<TableCell style={{ maxWidth: 50 }} className="wrap min-w-50">
							{row.zipCode ?? "-"}
						</TableCell>
						{/* <TableCell style={{ maxWidth: 70 }} className="wrap min-w-70">
							{row.transactionStatus != "" ? row.transactionStatus : "-"}
						</TableCell> */}
						<TableCell style={{ maxWidth: 70 }} className="wrap min-w-70">
							{row.requestStatus && row.requestStatus !== ""
								? row.requestStatus
								: "-"}
						</TableCell>
						<TableCell style={{ maxWidth: 70 }} className="wrap min-w-70">
							{row.physicianName}
						</TableCell>
						<TableCell style={{ maxWidth: 70 }} className="wrap min-w-70">
							{row.physicianNotes && row.physicianNotes !== ""
								? row.physicianNotes
								: "-"}
						</TableCell>
						<TableCell style={{ maxWidth: 70 }} className="wrap min-w-70">
							{row.adminNotes ?? "-"}
						</TableCell>
						<TableCell style={{ maxWidth: 70 }} className="wrap min-w-70">
							{row.cancellationReasons && row.cancellationReasons !== "" ? (
								row.cancellationReasons.split(";").length > 1 ? (
									<Tooltip title="Click to view more">
										<div
											style={{ cursor: "pointer" }}
											onClick={(e) => {
												e.stopPropagation();
												setIsViewMoreModalOpen(true);
											}}
										>
											{row.cancellationReasons.split(";")[0]} ...
										</div>
									</Tooltip>
								) : (
									<>{row.cancellationReasons}</>
								)
							) : (
								"-"
							)}
						</TableCell>
						<TableCell style={{ maxWidth: 70 }} className="wrap min-w-70">
							{row.cancelledByProviderNotes ?? "-"}
						</TableCell>
						<TableCell style={{ maxWidth: 70 }} className="wrap min-w-70">
							{row.clientNotes ?? "-"}
						</TableCell>
						<TableCell align="center">
							<span className="table-cell-title">Actions</span>
							<Button
								id="table-button"
								onClick={() => setIsDeleteWarningOpen(true)}
								className="action-btn action-btn-primary"
							>
								Delete
							</Button>
						</TableCell>
					</TableRow>
				</>
			) : (
				// Mobile view
				<Card className="bg-white">
					<div className="card-container">
						<ExpandMore
							expand={expanded}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label="show more"
						>
							<CardHeader
								title={
									<React.Fragment>
										<Checkbox
											onClick={(e) => {
												e.stopPropagation();
												handleSelectRow(row.requestId);
											}}
											icon={<CheckboxIcon />}
											checkedIcon={<CheckedboxIcon />}
											checked={isRowSelected}
											disableRipple
											disableFocusRipple
										/>
										<span>{row.patientName}</span>
									</React.Fragment>
								}
							/>
							<CardContent className="card-header-content">
								<div className="card-subheader">
									<Typography variant="body2" color="text.secondary">
										{row.address}
									</Typography>
								</div>
							</CardContent>
						</ExpandMore>
						<Collapse in={expanded} timeout="auto" unmountOnExit>
							<CardContent>
								<div>
									<span>
										<img src={PatientPrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Requestor:&nbsp;
										<span>
											{row.requestorType !== "" ? row.requestorType : "-"}
										</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={CalendarIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Date Of Service:&nbsp;
										<span>
											{row.dateOfService
												? moment(row.dateOfService).format("MMM D,YYYY")
												: "-"}
										</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={CalendarIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Case Closed Date:&nbsp;
										<span>
											{row.caseClosedDate
												? moment(row.caseClosedDate).format("MMM D,YYYY")
												: "-"}
										</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={MailPrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Email:&nbsp;
										<span>{row.email}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={PhonePrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Phone:&nbsp;
										<span>{row.phoneNumber}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={LocationIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Address:&nbsp;
										<span>{row.address}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={LocationIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										ZipCode:&nbsp;
										<span>{row.zipCode ?? "-"}</span>
									</Typography>
								</div>
								{/* <div>
									<span>
										<img src={StatusCheckPrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Transaction Status:&nbsp;
										<span>{row.transactionStatus ?? "-"}</span>
									</Typography>
								</div> */}
								<div>
									<span>
										<img src={StatusCheckPrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Request Status:&nbsp;
										<span>
											{row.requestStatus && row.requestStatus !== ""
												? row.requestStatus
												: "-"}
										</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={DoctorPrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Provider:&nbsp;
										<span>{row.physicianName}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={NotesPrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Provider Note:&nbsp;
										<span>
											{row.physicianNotes && row.physicianNotes !== ""
												? row.physicianNotes
												: "-"}
										</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={NotesPrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Cancelled By Provider Note:&nbsp;
										<span>{row.cancelledByProviderNotes ?? "-"}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={NotesPrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Admin Note:&nbsp;
										<span>{row.adminNotes ?? "-"}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={NotesPrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Cancellation Reasons:&nbsp;
										<span>{row.cancellationReasons ?? "-"}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={NotesPrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Patient Note:&nbsp;
										<span>{row.clientNotes ?? "-"}</span>
									</Typography>
								</div>
								<div className="table-cell-btn-group">
									<Button
										variant="outlined"
										onClick={() => setIsDeleteWarningOpen(true)}
									>
										Delete Permanently
									</Button>
									<Button variant="outlined" onClick={handleOnClickRequestData}>
										View Case
									</Button>
								</div>
							</CardContent>
						</Collapse>
					</div>
				</Card>
			)}

			{/* Page Modals */}
			<WarningModal
				isModalOpen={isDeleteWarningOpen}
				handleOnClickCloseModal={() => setIsDeleteWarningOpen(false)}
				title="Confirmation"
				warningMessage="Are you sure you want to delete this request permanently?"
				okButtonText="Confirm"
				closeButtonText="Cancel"
				handleOnClickOk={handleClickDelete}
				warningIcon={CrossRedIcon}
			/>

			{row.cancellationReasons && (
				<ViewMoreModal
					isOpen={isViewMoreModalOpen}
					handleClose={() => setIsViewMoreModalOpen(false)}
					notes={row.cancellationReasons}
					seperator={";"}
					title="Cancellation Reasons"
				/>
			)}
		</>
	);
}
