export enum AppRoutings {
	// Account
	Root = "/",
	ResetPassword = "/account/reset-password",
	NotFound = "/*",
	AccessDenied = "/access-denied",
	SSOLogin = "/sso-login",

	// Dashboard
	DesignerDashboard = "/designer-dashboard",
	CreateRequest1 = "/designer-dashboard/create-request",
	DesignerViewNotes = "/designer-dashboard/view-notes",
	DesignerViewCase = "/designer-dashboard/view-case",
	DesignerViewUploads = "/designer-dashboard/view-uploads",
	DesignerOrders = "/designer-dashboard/orders",
	DesignerDoctorsNote = "/designer-dashboard/doctors-note",
	DesignerConcludeCare = "/designer-dashboard/conclude-care",
	DesignerCloseCase = "/designer-dashboard/close-case",
	DesignerItemizedCosts = "/designer-dashboard/close-case/itemized-costs",
	ChatHistory = "/designer-dashboard/chat-history",
	DesignerNotFound = "*",
	Dashboard = "/dashboard/:tabStatus",
	CreateRequest = "/dashboard/request",
	ViewNotes = "/dashboard/view-notes/:caseId",
	ViewCase = "/dashboard/view-case/:caseId",
	ViewUploads = "/dashboard/view-uploads/:caseId",
	Orders = "/dashboard/order/:caseId",
	DoctorsNote = "/dashboard/doctor-note/:caseId",
	ConcludeCare = "/dashboard/conclude-care/:caseId",
	CloseCase = "/dashboard/close-case/:caseId",
	ItemizedCosts = "/dashboard/close-case/itemized-costs/:caseId",
	Encounter = "/dashboard/encounter/:caseId",

	// Profile
	Region = "/profile/regions/:tabStatus",
	MyProfile = "/profile/my-profile",
	Chats = "/chats",

	// Provider
	// Role = "/access",
	// CreateRole = "/access/create",
	// UpdateRole = "/access/update/:roleId",
	Role = "/access/account-access",
	CreateRole = "/access/account-access/create",
	UpdateRole = "/access/account-access/update/:roleId",
	ProviderInfo = "/provider/info",
	EditProvider = "/provider/edit-provider/:providerId",
	CreateProvider = "/provider/create-provider/:providerId",
	ProviderPayrate = "/provider/provider-payrate/:providerId",
	// Accounts = "/provider/accounts",
	Accounts = "/access/user-access",
	CreateAdmin = "/access/user-access/create-admin/:adminId",
	EditAdmin = "/access/user-access/edit-admin/:adminId",
	Scheduling = "/provider/scheduling",
	ProviderOnCall = "/provider/on-call",
	ReviewShifts = "/provider/review-shifts",
	Invoicing = "/provider/invoicing",
	FillDailyTimeSheet = "/provider/daily-timesheet-details/:data",
	BiWeeklyTimesheet = "/provider/bi-weekly-timesheet/:data",
	SubmitTimesheetSuccess = "/provider/timesheet/success",
	ProviderLocation = "/provider/location",

	// Profession
	// Vendors = "/profession/vendors",
	Vendors = "/partners",
	AddBusiness = "/profession/vendors/add",
	UpdateBusiness = "/profession/vendors/update/:businessId",
	ProfessionInfo = "/profession/info",

	// History
	HistoryInfo = "/history/info",
	PatientRecord = "/history/patient/:patientId",
	HistoryRequestData = "/history/request-data",
	CancelHistory = "/history/cancel",
	PatientHistory = "/history/patient-records",
	EmailLogs = "/history/email-logs",
	SMSLogs = "/history/sms-logs",
	BlockHistory = "/history/block",

	// Halo Pages
	HaloHR = "/halo/hr",
	HaloRequest = "/halo/request",
	HaloEmployee = "/halo/employee",
	HaloWorkplace = "/halo/workplace",
}
