import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from "@mui/material";
import React from "react";
import { SortOrder } from "utility/enums/sort-order";
import { IHaloEmployeesInfo, ISearchCriteria } from "utility/interfaces";
import { visuallyHidden } from "@mui/utils";
import { HaloEmployeeTableRow } from "./table-row";
import { TablePagination } from "components";

type ComponentProps = {
	haloEmployeePageInfo: IHaloEmployeesInfo;
	searchCriteria: ISearchCriteria;
	handleChangeSearchCriteria: any;
	getHaloEmpData: any;
};

export const HaloEmployeeTable = (props: ComponentProps) => {
	// Extract props
	const {
		haloEmployeePageInfo,
		searchCriteria,
		handleChangeSearchCriteria,
		getHaloEmpData,
	} = props;

	// Handle events
	const handleOnPageNumberChange = (value: number) => {
		handleChangeSearchCriteria({ ...searchCriteria, PageIndexId: value });
	};
	const handleOnChangeSortDirection = (sortBy: string) => {
		handleChangeSearchCriteria({
			...searchCriteria,
			SortBy: sortBy,
			SortOrder:
				searchCriteria.SortOrder === SortOrder.ascending
					? SortOrder.descending
					: SortOrder.ascending,
		});
	};

	return (
		<>
			{haloEmployeePageInfo.totalRecords > 0 ? (
				<>
					<TableContainer
						className="upload-table-container"
						sx={{ display: { xs: "none", laptop: "block" } }}
					>
						<Table className="upload-table conclude-table">
							<TableHead>
								<TableRow>
									<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
										<TableSortLabel
											active={searchCriteria.SortBy === "FirstName"}
											direction={searchCriteria.SortOrder}
											onClick={() => handleOnChangeSortDirection("FirstName")}
										>
											Patient name
											<Box component="span" sx={visuallyHidden}>
												{searchCriteria.SortOrder === SortOrder.descending
													? "sorted descending"
													: "sorted ascending"}
											</Box>
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
										<TableSortLabel
											active={searchCriteria.SortBy === "email"}
											direction={searchCriteria.SortOrder}
											onClick={() => handleOnChangeSortDirection("email")}
										>
											Email
											<Box component="span" sx={visuallyHidden}>
												{searchCriteria.SortOrder === SortOrder.descending
													? "sorted descending"
													: "sorted ascending"}
											</Box>
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
										<TableSortLabel
											active={searchCriteria.SortBy === "WorkPlaceName"}
											direction={searchCriteria.SortOrder}
											onClick={() =>
												handleOnChangeSortDirection("WorkPlaceName")
											}
										>
											Work Place Name
											<Box component="span" sx={visuallyHidden}>
												{searchCriteria.SortOrder === SortOrder.descending
													? "sorted descending"
													: "sorted ascending"}
											</Box>
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
										Action
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{haloEmployeePageInfo.employees.map((row, index) => (
									<HaloEmployeeTableRow
										row={row}
										isSmallDevice={false}
										key={index}
										getHaloEmpData={getHaloEmpData}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Box
						sx={{ display: { xs: "block", laptop: "none" } }}
						className="tabledata-cards-group"
					>
						{haloEmployeePageInfo.employees.map((row, index) => (
							<HaloEmployeeTableRow
								row={row}
								isSmallDevice={true}
								key={index}
								getHaloEmpData={getHaloEmpData}
							/>
						))}
					</Box>
					<div className="small-table-pagination">
						<TablePagination
							currentPageNumber={searchCriteria.PageIndexId}
							handleOnPageNumberChange={handleOnPageNumberChange}
							totalRecords={haloEmployeePageInfo.totalRecords || 0}
							rowCount={haloEmployeePageInfo.employees.length || 0}
						/>
					</div>
				</>
			) : (
				<Typography variant="h5" sx={{ padding: 3 }}>
					No Record(s) Found{" "}
				</Typography>
			)}
		</>
	);
};
