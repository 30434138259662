import { MenuContext, MenuDispatchContext } from "contexts/menu-context";
import React, { useContext, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AppRoutings } from "utility/enums/app-routings";
import { IMenu, IMenuPermissions } from "utility/interfaces";
import tokenManager from "./token-manager";
import { UserContext } from "contexts/user-context";
import { AccountType } from "utility/enums/request-status";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { Constants } from "utility/enums/constants";
import { UpdateLocationConfirmationModal, WarningModal } from "components";
import { createCommonAPICall, getUserLocation } from "utility/helpers";
import dashboardService from "services/dashboard-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";

type ComponentProps = {
	element: any;
	menuKey: string | undefined;
};

export const ProtectedRoute = (props: ComponentProps) => {
	// Extract Props
	const { element, menuKey } = props;
	const sessionToken = tokenManager.getToken();
	const menuDetails: IMenu = useContext(MenuContext);
	const contextUser = useContext(UserContext);
	const setMenuContext = useContext(MenuDispatchContext);
	const menuPermissions: IMenuPermissions[] = menuDetails.menus || [];

	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	const [locationHubConnection, setLocationHubConnection] =
		useState<HubConnection | null>(null);

	const [isUpdateLocationModalOpen, setIsUpdateLocationModal] = useState(false);

	const checkPathHaveAccess = () => {
		const permission =
			menuPermissions.filter((menu) => menu.MenuKey === menuKey).length > 0
				? true
				: false;
		return permission;
	};

	const handleUpdateLocationModalOpen = () => {
		setIsUpdateLocationModal(true);
	};

	const handleUpdateLocationModalClose = () => {
		setIsUpdateLocationModal(false);
	};

	const handleConnectLocationHub = () => {
		const newConnection = new HubConnectionBuilder()
			.withUrl(Constants.LocationHubUrl)
			.withAutomaticReconnect()
			.build();

		setLocationHubConnection(newConnection);
	};

	const handleCheckProviderOnCall = () => {
		if (locationHubConnection) {
			console.log("call: ", contextUser.email);
			locationHubConnection.send("CheckProviderOnCall", contextUser.email);
		}
	};

	const handleStartInterval = () => {
		return setInterval(handleCheckProviderOnCall, 300000);
	};

	const handleUpdateLocation = async (
		position: GeolocationPosition,
		isAutomaticLocationUpdate: boolean | null
	) => {
		const requestBody = {
			latitude: position.coords.latitude,
			longitude: position.coords.longitude,
			isAutomaticLocationUpdate,
		};
		console.log("New physician location: ", requestBody);
		const data = await createCommonAPICall({
			requestBody,
			apiService: dashboardService.updateProviderLocation,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});
	};

	const handleOnClickUpdate = (isAutomaticLocationUpdate: boolean | null) => {
		getUserLocation(
			(position: GeolocationPosition) =>
				handleUpdateLocation(position, isAutomaticLocationUpdate),
			(err: GeolocationPositionError) => console.log(err)
		);
	};

	useEffect(() => {
		if (menuKey !== "")
			setMenuContext({
				...menuDetails,
				selectedMenuKey: menuKey,
			});

		if (
			contextUser &&
			contextUser.userRoleId === AccountType.Physician &&
			checkPathHaveAccess() &&
			menuKey &&
			navigator &&
			navigator.geolocation
		) {
			handleConnectLocationHub();
		}
	}, [element]);

	useEffect(() => {
		if (locationHubConnection) {
			locationHubConnection
				.start()
				.then(() => {
					locationHubConnection.on("ChangeProviderLocation", (data) => {
						console.log("Provider on call", data);
						if (data.isOnCall && !data.isAutomaticLocationUpdate) {
							handleUpdateLocationModalOpen();
						} else if (data.isOnCall && data.isAutomaticLocationUpdate) {
							handleOnClickUpdate(null);
						}
					});

					let interval = handleStartInterval();

					return () => {
						clearInterval(interval);
					};
				})
				.catch((e) => console.log("Connection failed: ", e));
		}
	}, [locationHubConnection]);

	return sessionToken ? (
		<>
			{checkPathHaveAccess() && menuKey ? (
				element || <Outlet />
			) : (
				<Navigate to={AppRoutings.AccessDenied} />
			)}
			<UpdateLocationConfirmationModal
				isModalOpen={isUpdateLocationModalOpen}
				handleOnClickCloseModal={handleUpdateLocationModalClose}
				handleOnClickUpdate={handleOnClickUpdate}
			/>
		</>
	) : (
		<Navigate to={AppRoutings.Root + "?back=" + window.location.pathname} />
	);
};
