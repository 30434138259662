import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { TimeSheetFormTableRow } from "components";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { IFillTimesheetDetailsInfo } from "utility/interfaces";

type ComponentProps = {
	fillTimesheetDetailsInfo: IFillTimesheetDetailsInfo;
	formValues: IFillTimesheetDetailsInfo;
	handleOnChangeFormValues: any;
	formValidator: UseFormReturn<IFillTimesheetDetailsInfo, any>;
};

export const FillDailyTimeSheetTable = (props: ComponentProps) => {
	// Extract props
	const {
		fillTimesheetDetailsInfo,
		formValidator,
		formValues,
		handleOnChangeFormValues,
	} = props;

	return (
		<>
			<TableContainer
				className="upload-table-container"
				sx={{ display: { xs: "none", md: "block" } }}
			>
				<Table className="upload-table">
					<TableHead>
						<TableRow>
							<TableCell className="min-w-100">Date</TableCell>
							<TableCell sx={{ maxWidth: 70 }} className="min-w-70">
								On-call Hours
							</TableCell>
							<TableCell className="min-w-100">Total Hours</TableCell>
							<TableCell sx={{ maxWidth: 70 }} className="min-w-70">
								Weekend/ Holiday
							</TableCell>
							<TableCell className="min-w-100">Number of Housecalls</TableCell>
							<TableCell className="min-w-100">
								Number of Phone Consults
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TimeSheetFormTableRow
							fillTimesheetDetailsInfo={fillTimesheetDetailsInfo}
							formValidator={formValidator}
							formValues={formValues}
							handleOnChangeFormValues={handleOnChangeFormValues}
							isSmallDevice={false}
						/>
					</TableBody>
				</Table>
			</TableContainer>
			<Box
				className="tabledata-cards-group custom-card-margin"
				sx={{ display: { xs: "block", md: "none" } }}
			>
				<TimeSheetFormTableRow
					fillTimesheetDetailsInfo={fillTimesheetDetailsInfo}
					formValidator={formValidator}
					formValues={formValues}
					handleOnChangeFormValues={handleOnChangeFormValues}
					isSmallDevice={true}
				/>
			</Box>
		</>
	);
};
