import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { ArrowBack } from "assests/images";
import { useNavigate, useParams } from "react-router-dom";
import { AppRoutings } from "utility/enums/app-routings";
import { IEncounter } from "utility/interfaces";
import {
	createCommonAPICall,
	getTabStatusWiseName,
	openSucessErrorModal,
} from "utility/helpers";
import {
	CustomDatePicker,
	CustomIntlTelInput,
	CustomTimePicker,
} from "components";
import dashboardService from "services/dashboard-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import dayjs from "dayjs";
import { UserContext } from "contexts/user-context";
import { AccountType } from "utility/enums/request-status";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { encounterFormSchema } from "utility/validations";
import { CountryData } from "react-intl-tel-input-18";

const Encounter: React.FC = () => {
	// Page level variables
	const navigate = useNavigate();
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const contextUser = useContext(UserContext);

	// get query parameters
	const { caseId } = useParams();

	// get state value
	const { state } = window.history;

	// useStates
	const [formValues, setFormValues] = useState<IEncounter>({
		requestId: 0,
		firstName: "",
		lastName: "",
		dateOfBirth: "",
		location: "",
		date: "",
		phoneNumber: "",
		email: "",
		historyOfPresentIllnessOrInjury: "",
		medicalHistory: "",
		allergies: "",
		medications: "",
		temp: "",
		hr: "",
		rr: "",
		bp1: "",
		bp2: "",
		o2: "",
		pain: "",
		heent: "",
		cv: "",
		chest: "",
		abd: "",
		extr: "",
		skin: "",
		neuro: "",
		other: "",
		diagnosis: "",
		treatmentPlan: "",
		medicationsDispensed: "",
		procedures: "",
		followup: "",
		isFinalize: false,
	});

	const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
	const [renderer, setRenderer] = useState(0);

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
	} = useForm({
		values: formValues,
		resolver: yupResolver(encounterFormSchema),
	});

	// Handled events and functions
	const handleOnChangeFormValue = (name: string, value: any) => {
		setFormValues({ ...formValues, [name]: value });
	};
	const getEncounterDetails = () => {
		console.log(caseId);
		const requestBody = {
			Id: caseId ? caseId : "",
		};
		createCommonAPICall({
			requestBody,
			apiService: dashboardService.getEncounterFormInfo,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		}).then((data) => {
			console.log(data);
			let dateOfService = data.data.dateOfService;
			if (data.data.date != "") dateOfService = data.data.date;
			setFormValues({
				...data.data,
				date: dayjs(dateOfService),
			});
			setRenderer(1);
			// console.log(formValues);
		});
	};
	const handleOnClickBackCancel = () =>
		navigate(
			AppRoutings.Dashboard.replace(
				":tabStatus",
				getTabStatusWiseName(
					state?.usr?.searchCriteria?.RequestStatusId
				).toLocaleLowerCase()
			),
			{
				state: {
					searchCriteria: state?.usr?.searchCriteria,
				},
			}
		);

	const handleClickFinalize = () => {
		const requestBody: IEncounter = { ...formValues, isFinalize: true };
		saveEncounterForm(requestBody);
	};

	const handleOnclickSave = () => {
		saveEncounterForm({ ...formValues, isFinalize: false });
	};

	const saveEncounterForm = (requestBody: IEncounter) => {
		createCommonAPICall({
			requestBody: requestBody,
			apiService: dashboardService.saveEncounterForm,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		}).then((data) => {
			if (data.data && requestBody.isFinalize) {
				openSucessErrorModal(
					setSuccessErrorContext,
					"Success",
					"Encounter Form finalized.",
					true
				);
				navigate(
					AppRoutings.Dashboard.replace(
						":tabStatus",
						getTabStatusWiseName(
							state?.usr?.searchCriteria?.RequestStatusId
						).toLocaleLowerCase()
					),
					{
						state: {
							searchCriteria: state?.usr?.searchCriteria,
						},
					}
				);
			} else if (data.data) {
				openSucessErrorModal(
					setSuccessErrorContext,
					"Success",
					"Encounter Form saved.",
					true
				);
			}
		});
	};

	const handlePhoneNumberChange = (
		isValid: boolean,
		phoneNumber: string,
		countryData: CountryData
	) => {
		setIsPhoneNumberValid(isValid);
		let formattedPhoneNumber =
			phoneNumber != ""
				? phoneNumber.includes("+")
					? phoneNumber
					: `+${countryData.dialCode} ${phoneNumber}`
				: "";
		setFormValues({ ...formValues, phoneNumber: formattedPhoneNumber });
		// onChangeDetails("conciergePhoneNumber", formattedPhoneNumber);
	};

	// useEffects
	useEffect(() => {
		if (caseId) getEncounterDetails();
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="request-container-box">
					<div className="request-header">
						<Typography variant="h2">Encounter Form</Typography>
						<Button variant="outlined" onClick={handleOnClickBackCancel}>
							<img src={ArrowBack} alt="arrow" />
							Back
						</Button>
					</div>
					<Box className="request-box">
						<div>
							<Typography className="total-cost">
								Medical Report-Confidential
							</Typography>
							<div style={{ marginTop: 20 }}>
								<Grid container columnSpacing={{ xs: 2, lg: 3 }}>
									<Grid item xs={12} sm={6}>
										<TextField
											{...register("firstName")}
											autoFocus
											fullWidth
											id="first-name"
											label="First Name"
											variant="outlined"
											name="firstName"
											value={formValues.firstName}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
											error={errors?.firstName?.message ? true : false}
											helperText={errors?.firstName?.message?.toString()}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											{...register("lastName")}
											fullWidth
											id="last-name"
											label="Last Name"
											variant="outlined"
											name="lastName"
											value={formValues.lastName}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
											error={errors?.lastName?.message ? true : false}
											helperText={errors?.lastName?.message?.toString()}
										/>
									</Grid>
									<Grid item xs={12} sm={12}>
										<TextField
											{...register("location")}
											fullWidth
											id="location"
											label="Location"
											variant="outlined"
											name="location"
											value={formValues.location}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
											error={errors?.location?.message ? true : false}
											helperText={errors?.location?.message?.toString()}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<CustomDatePicker
											register={register}
											error={errors?.dateOfBirth?.message ? true : false}
											message={errors?.dateOfBirth?.message?.toString()}
											value={formValues.dateOfBirth}
											label="Date Of Birth"
											onChange={handleOnChangeFormValue}
											name="dateOfBirth"
											disabled={false}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<CustomDatePicker
											register={register}
											error={errors?.date?.message ? true : false}
											message={errors?.date?.message?.toString()}
											value={formValues.date}
											label="Date"
											onChange={handleOnChangeFormValue}
											name="date"
											disabled={false}
										/>
									</Grid>

									<Grid item xs={12} sm={6}>
										{renderer > 0 && (
											<CustomIntlTelInput
												isPhoneNumberValid={isPhoneNumberValid}
												onChange={handlePhoneNumberChange}
												onBlur={() => {}}
												value={formValues.phoneNumber}
											/>
										)}

										{/* <TextField
											{...register("phoneNumber")}
											fullWidth
											id="phoneNumber"
											label="Phone Number"
											variant="outlined"
											type="number"
											name="phoneNumber"
											value={formValues.phoneNumber}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
											error={errors?.phoneNumber?.message ? true : false}
											helperText={errors?.phoneNumber?.message?.toString()}
										/> */}
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											{...register("email")}
											fullWidth
											id="email"
											label="Email"
											variant="outlined"
											name="email"
											value={formValues.email}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
											error={errors?.email?.message ? true : false}
											helperText={errors?.email?.message?.toString()}
										/>
									</Grid>
								</Grid>
								<Grid container columnSpacing={{ xs: 2, lg: 3 }}>
									<Grid item xs={12} sm={6}>
										<TextField
											inputProps={{
												style: { minHeight: "50px" },
											}}
											fullWidth
											multiline
											rows={1}
											id="present-illness"
											label="History Of Present Illness Or Injury"
											variant="outlined"
											name="historyOfPresentIllnessOrInjury"
											value={formValues.historyOfPresentIllnessOrInjury}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											inputProps={{
												style: { minHeight: "50px" },
											}}
											fullWidth
											multiline
											rows={1}
											id="medical-history"
											label="Medical History"
											variant="outlined"
											name="medicalHistory"
											value={formValues.medicalHistory}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											inputProps={{
												style: { minHeight: "50px" },
											}}
											fullWidth
											multiline
											rows={1}
											id="medication"
											label="Medications"
											variant="outlined"
											name="medications"
											value={formValues.medications}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											{...register("allergies")}
											inputProps={{
												style: { minHeight: "50px" },
											}}
											fullWidth
											multiline
											rows={1}
											id="allergies"
											label="Allergies"
											variant="outlined"
											name="allergies"
											value={formValues.allergies}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
											error={errors?.allergies?.message ? true : false}
											helperText={errors?.allergies?.message?.toString()}
										/>
									</Grid>
								</Grid>
								<Grid container columnSpacing={{ xs: 2, lg: 3 }}>
									<Grid item xs={6} sm={4}>
										<TextField
											fullWidth
											id="temp"
											label="Temp"
											variant="outlined"
											name="temp"
											value={formValues.temp}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={6} sm={4}>
										<TextField
											fullWidth
											id="hr"
											label="HR"
											variant="outlined"
											name="hr"
											value={formValues.hr}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={6} sm={4}>
										<TextField
											fullWidth
											id="rr"
											label="RR"
											variant="outlined"
											name="rr"
											value={formValues.rr}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={6} sm={2}>
										<TextField
											{...register("bp1")}
											error={errors.bp1?.message ? true : false}
											helperText={errors.bp1?.message}
											fullWidth
											id="bp1"
											label="Blood Pressure(Systolic)"
											variant="outlined"
											name="bp1"
											type="number"
											value={formValues.bp1}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={6} sm={2}>
										<TextField
											{...register("bp2")}
											error={errors.bp2?.message ? true : false}
											helperText={errors.bp2?.message}
											fullWidth
											id="bp1"
											label="Blood Pressure(Diastolic)"
											variant="outlined"
											name="bp2"
											type="number"
											value={formValues.bp2}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={6} sm={4}>
										<TextField
											fullWidth
											id="o2"
											label="O2"
											variant="outlined"
											name="o2"
											value={formValues.o2}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={6} sm={4}>
										<TextField
											fullWidth
											id="pain"
											label="Pain"
											variant="outlined"
											name="pain"
											value={formValues.pain}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											fullWidth
											multiline
											inputProps={{
												style: { minHeight: "50px" },
											}}
											rows={1}
											id="heent"
											label="Heent"
											variant="outlined"
											name="heent"
											value={formValues.heent}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											fullWidth
											multiline
											inputProps={{
												style: { minHeight: "50px" },
											}}
											rows={1}
											id="cv"
											label="CV"
											variant="outlined"
											name="cv"
											value={formValues.cv}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											fullWidth
											multiline
											inputProps={{
												style: { minHeight: "50px" },
											}}
											rows={1}
											id="chest"
											label="Chest"
											variant="outlined"
											name="chest"
											value={formValues.chest}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											fullWidth
											multiline
											inputProps={{
												style: { minHeight: "50px" },
											}}
											rows={1}
											id="abd"
											label="ABD"
											variant="outlined"
											name="abd"
											value={formValues.abd}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											fullWidth
											multiline
											inputProps={{
												style: { minHeight: "50px" },
											}}
											rows={1}
											id="extr"
											label="Extr"
											variant="outlined"
											name="extr"
											value={formValues.extr}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											fullWidth
											multiline
											inputProps={{
												style: { minHeight: "50px" },
											}}
											rows={1}
											id="skin"
											label="Skin"
											variant="outlined"
											name="skin"
											value={formValues.skin}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											fullWidth
											multiline
											inputProps={{
												style: { minHeight: "50px" },
											}}
											rows={1}
											id="neuro"
											label="Neuro"
											variant="outlined"
											name="neuro"
											value={formValues.neuro}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											fullWidth
											multiline
											inputProps={{
												style: { minHeight: "50px" },
											}}
											rows={1}
											id="other"
											label="Other"
											variant="outlined"
											name="other"
											value={formValues.other}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											fullWidth
											multiline
											inputProps={{
												style: { minHeight: "50px" },
											}}
											rows={1}
											id="diagnosis"
											label="Diagnosis"
											variant="outlined"
											name="diagnosis"
											value={formValues.diagnosis}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											{...register("treatmentPlan")}
											fullWidth
											multiline
											inputProps={{
												style: { minHeight: "50px" },
											}}
											rows={1}
											id="treatmentPlan"
											label="Treatment Plan"
											variant="outlined"
											name="treatmentPlan"
											value={formValues.treatmentPlan}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
											error={errors?.treatmentPlan?.message ? true : false}
											helperText={errors?.treatmentPlan?.message?.toString()}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											{...register("medicationsDispensed")}
											fullWidth
											multiline
											inputProps={{
												style: { minHeight: "50px" },
											}}
											rows={1}
											id="medicationsDispensed"
											label="Medications Dispensed"
											variant="outlined"
											name="medicationsDispensed"
											value={formValues.medicationsDispensed}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
											error={
												errors?.medicationsDispensed?.message ? true : false
											}
											helperText={errors?.medicationsDispensed?.message?.toString()}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											{...register("procedures")}
											fullWidth
											multiline
											inputProps={{
												style: { minHeight: "50px" },
											}}
											rows={1}
											id="procedures"
											label="Procedures"
											variant="outlined"
											name="procedures"
											value={formValues.procedures}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
											error={errors?.procedures?.message ? true : false}
											helperText={errors?.procedures?.message?.toString()}
										/>
									</Grid>
									<Grid item xs={12} sm={12}>
										<TextField
											{...register("followup")}
											fullWidth
											multiline
											inputProps={{
												style: { minHeight: "50px" },
											}}
											rows={1}
											id="followup"
											label="Followup"
											variant="outlined"
											name="followup"
											value={formValues.followup}
											onChange={(e) =>
												handleOnChangeFormValue(e.target.name, e.target.value)
											}
											error={errors?.followup?.message ? true : false}
											helperText={errors?.followup?.message?.toString()}
										/>
									</Grid>
								</Grid>
							</div>
						</div>
						<div className="request-btn-group">
							<Button
								variant="contained"
								onClick={handleSubmit(handleOnclickSave)}
							>
								Save Changes
							</Button>
							{contextUser.userRoleId == AccountType.Physician && (
								<Button
									variant="contained"
									color="secondary"
									onClick={handleSubmit(handleClickFinalize)}
								>
									Finalize
								</Button>
							)}
							<Button variant="outlined" onClick={handleOnClickBackCancel}>
								Cancel
							</Button>
						</div>
					</Box>
				</Box>
			</main>
		</>
	);
};

export default Encounter;
