import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
	NewIconActive,
	NewIconDefault,
	WorkingIconDefault,
	CheckedIconDefault,
	CheckedIconActive,
	WorkingIconActive,
	ConcludeIconDefault,
	ConcludeIconActive,
	TocloseIconDefault,
	TocloseIconActive,
	UnpaidIconActive,
	UnpaidIconDefault,
} from "assests/images";
import {
	InformationTab,
	SendLinkModal,
	DashboardTable,
	DashboardTableTitle,
} from "components";
import {
	IDashboardSearchCriteria,
	IInformationTab,
	IDashboardInfo,
} from "utility/interfaces";
import { Constants } from "utility/enums/constants";
import dashboardService from "services/dashboard-service";
import { SortOrder } from "utility/enums/sort-order";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import {
	AccountType,
	InformationTabTypes,
	RequestTypes,
} from "utility/enums/request-status";
import { useNavigate, useParams } from "react-router-dom";
import {
	getTabStatusIdByName,
	getTabStatusWiseName,
	createCommonAPICall,
	exportToExcel,
} from "utility/helpers";
import moment from "moment";
import { toast } from "react-toastify";
import { AppRoutings } from "utility/enums/app-routings";
import * as FileSaver from "file-saver";
import { UserContext } from "contexts/user-context";
import tokenManager from "utility/auth-guards/token-manager";

const Dashboard: React.FC = () => {
	// get query parameters
	const { tabStatus } = useParams();
	const { state } = window.history;
	const userrole = useContext(UserContext);

	// useStates
	const [isSendLinkOpen, setIsSendLinkOpen] = useState(false);
	const [showDob, setShowDob] = useState(true);
	let refreshInterval: any = null;

	const [dashboardPageInfo, setDashboardPageInfo] =
		useState<IDashboardInfo | null>(null);
	const [searchCriteria, setSearchCriteria] =
		useState<IDashboardSearchCriteria>({
			PageIndexId: state?.usr?.searchCriteria?.PageIndexId || 0,
			PageSize:
				state?.usr?.searchCriteria?.PageSize ||
				Constants.DefaultTablePageSize.valueOf(),
			SortOrder: state?.usr?.searchCriteria?.SortOrder || SortOrder.ascending,
			SortBy: state?.usr?.searchCriteria?.SortBy || "",
			SearchBy: state?.usr?.searchCriteria?.SearchBy || "",
			RequestTypeId:
				state?.usr?.searchCriteria?.RequestTypeId || RequestTypes.All,
			RequestStatusId:
				state?.usr?.searchCriteria?.RequestStatusId ||
				getTabStatusIdByName(tabStatus ? tabStatus : ""),
			RegionId: 0,
		});

	// Handled events and functions
	const handleClickSendLink = () => setIsSendLinkOpen(true);
	const handleClickExport = async () => {
		const exportData: any = [];
		dashboardPageInfo?.requests?.forEach((request) => {
			exportData.push({
				"Patient Name": request.name,
				"Date Of Birth": request.dateOfBirth,
				Email: request.email,
				"Phone Number": request.phone,
				"Provider Name": request.physicianName,
				Address: request.address,
				"Date Of Service": request.acceptedDate,
				Notes: request.transferNotes,
			});
		});
		const columnWiseWidth = [
			{ width: 25 }, // Patient Name
			{ width: 20 }, // Date Of Birth
			{ width: 35 }, // Email
			{ width: 20 }, // Phone Number
			{ width: 25 }, // Provider Name
			{ width: 50 }, // Address
			{ width: 20 }, // Date Of Service
			{ width: 70 }, // Notes
		];
		if (exportData.length > 0)
			exportToExcel(
				exportData,
				columnWiseWidth,
				`Export_Patients_${moment(new Date()).format("MM/DD/YYYY HH:mm:ss")}`
			);
		else toast.error("No record(s) here to export");
	};
	const handleClickExportAll = async () => {
		console.log("handleClickExportAll");
		const request: IDashboardSearchCriteria = {
			PageIndexId: searchCriteria?.PageIndexId || 0,
			PageSize:
				searchCriteria?.PageSize || Constants.DefaultTablePageSize.valueOf(),
			SortOrder: searchCriteria?.SortOrder || SortOrder.ascending,
			SortBy: searchCriteria?.SortBy || "",
			SearchBy: searchCriteria?.SearchBy || "",
			RequestTypeId: searchCriteria?.RequestTypeId || RequestTypes.All,
			RequestStatusId:
				searchCriteria?.RequestStatusId ||
				getTabStatusIdByName(tabStatus ? tabStatus : ""),
			RegionId: searchCriteria.RegionId,
		};
		console.log(request);
		const data = await createCommonAPICall({
			requestBody: request,
			apiService: dashboardService.exportAll,
			showSuccessMessage: true,
			showErrorMessage: true,
			setSuccessErrorContext,
		}).then((response) => {
			console.log(response);
			const exportData: any = [];
			response.data?.forEach((record: any) => {
				exportData.push({
					"Patient Name": record.name,
					"Date Of Birth": record.dateOfBirth,
					Email: record.email,
					"Phone Number": record.phone,
					"Provider Name": record.physicianName,
					Address: record.address,
					"Date Of Service": record.acceptedDate,
					Notes: record.transferNotes,
				});
			});
			const columnWiseWidth = [
				{ width: 25 }, // Patient Name
				{ width: 20 }, // Date Of Birth
				{ width: 35 }, // Email
				{ width: 20 }, // Phone Number
				{ width: 25 }, // Provider Name
				{ width: 50 }, // Address
				{ width: 20 }, // Date Of Service
				{ width: 70 }, // Notes
			];
			if (exportData.length > 0)
				exportToExcel(
					exportData,
					columnWiseWidth,
					`Export_Patients_${moment(new Date()).format("MM/DD/YYYY HH:mm:ss")}`
				);
			else toast.error("No record(s) here to export");
		});
	};

	const handleClickSendLinkClose = () => setIsSendLinkOpen(false);
	const handleOnSearchCriteriaChange = (
		newSearchCriteria: IDashboardSearchCriteria
	) => {
		setSearchCriteria(newSearchCriteria);
	};
	const getDashboardDetails = async () => {
		let pathName = window.location.pathname;
		console.log("Search: ", searchCriteria);
		const requestBody = {
			...searchCriteria,
			RequestStatusId: getTabStatusIdByName(
				pathName.substring(pathName.lastIndexOf("/") + 1)
			),
		};
		console.log("requestBody: ", requestBody);
		const data = await createCommonAPICall({
			requestBody,
			apiService: dashboardService.getDashboardInfo,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		console.log(data.data);
		if (data && data.data) setDashboardPageInfo(data.data);

		setTimeout(() => {
			const yOffset = tokenManager.getYOffset();
			if (yOffset) {
				console.log(parseInt(yOffset));
				window.scrollTo(0, parseInt(yOffset));
				tokenManager.removeYOffset();
			}
		}, 0);
	};
	const handleInfoTabChange = (tab: IInformationTab) => {
		const newSearchCriteria = {
			PageIndexId: 0,
			PageSize: Constants.DefaultTablePageSize.valueOf(),
			SortOrder: SortOrder.descending,
			SortBy: "ModifiedDate",
			SearchBy: "",
			RequestTypeId: RequestTypes.All,
			RequestStatusId: tab.type,
			RegionId: 0,
		};
		navigate(
			`${AppRoutings.Dashboard.replace(
				":tabStatus",
				`${getTabStatusWiseName(tab.type).toLowerCase()}`
			)}`
		);
		handleOnSearchCriteriaChange(newSearchCriteria);
		if (
			userrole.userRoleId == AccountType.Physician &&
			tab.type == InformationTabTypes.New
		) {
			setShowDob(false);
		} else {
			setShowDob(true);
		}
		console.log("TABCHANGE : " + showDob);
	};

	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const navigate = useNavigate();

	console.log(dashboardPageInfo?.requestCounts);
	const [requestCount, setRequestCounts] = useState<IInformationTab[]>([
		{
			id: 1,
			type: InformationTabTypes.New,
			defaultIcon: NewIconDefault,
			activeIcon: NewIconActive,
			title: "New",
			count: dashboardPageInfo?.requestCounts.newCount || 0,
			class: "new",
		},
		{
			id: 2,
			type: InformationTabTypes.Pending,
			defaultIcon: WorkingIconDefault,
			activeIcon: WorkingIconActive,
			title: "Pending",
			count: dashboardPageInfo?.requestCounts.workingCount || 0,
			class: "working",
		},
		{
			id: 3,
			type: InformationTabTypes.Active,
			defaultIcon: CheckedIconDefault,
			activeIcon: CheckedIconActive,
			title: "Active",
			count: dashboardPageInfo?.requestCounts.reservedCount || 0,
			class: "active",
		},
		{
			id: 4,
			type: InformationTabTypes.Conclude,
			defaultIcon: ConcludeIconDefault,
			activeIcon: ConcludeIconActive,
			title: "Conclude",
			count: dashboardPageInfo?.requestCounts.concludeCount || 0,
			class: "conclude",
		},
	]);

	const informationTabs: IInformationTab[] = [
		{
			id: 1,
			type: InformationTabTypes.New,
			defaultIcon: NewIconDefault,
			activeIcon: NewIconActive,
			title: "New",
			count: dashboardPageInfo?.requestCounts.newCount || 0,
			class: "new",
		},
		{
			id: 2,
			type: InformationTabTypes.Pending,
			defaultIcon: WorkingIconDefault,
			activeIcon: WorkingIconActive,
			title: "Pending",
			count: dashboardPageInfo?.requestCounts.workingCount || 0,
			class: "working",
		},
		{
			id: 3,
			type: InformationTabTypes.Active,
			defaultIcon: CheckedIconDefault,
			activeIcon: CheckedIconActive,
			title: "Active",
			count: dashboardPageInfo?.requestCounts.reservedCount || 0,
			class: "active",
		},
		{
			id: 4,
			type: InformationTabTypes.Conclude,
			defaultIcon: ConcludeIconDefault,
			activeIcon: ConcludeIconActive,
			title: "Conclude",
			count: dashboardPageInfo?.requestCounts.concludeCount || 0,
			class: "conclude",
		},
	];

	if (userrole.userRoleId == AccountType.Admin) {
		informationTabs.push(
			{
				id: 5,
				type: InformationTabTypes.Closed,
				defaultIcon: TocloseIconDefault,
				activeIcon: TocloseIconActive,
				title: "To Close",
				count: dashboardPageInfo?.requestCounts.closeCount || 0,
				class: "to-close",
			},
			{
				id: 6,
				type: InformationTabTypes.UnPaid,
				defaultIcon: UnpaidIconDefault,
				activeIcon: UnpaidIconActive,
				title: "Unpaid",
				count: dashboardPageInfo?.requestCounts.unPaidCount || 0,
				class: "unpaid",
			}
		);
	}

	useEffect(() => {
		if (userrole.userRoleId == AccountType.Admin) {
			setRequestCounts([
				...requestCount,
				{
					id: 5,
					type: InformationTabTypes.Closed,
					defaultIcon: TocloseIconDefault,
					activeIcon: TocloseIconActive,
					title: "To Close",
					count: dashboardPageInfo?.requestCounts.closeCount || 0,
					class: "to-close",
				},
				{
					id: 6,
					type: InformationTabTypes.UnPaid,
					defaultIcon: UnpaidIconDefault,
					activeIcon: UnpaidIconActive,
					title: "Unpaid",
					count: dashboardPageInfo?.requestCounts.unPaidCount || 0,
					class: "unpaid",
				},
			]);
		}
		let interval = setInterval(() => {
			console.log("interval called: ", new Date().toLocaleTimeString());
			getDashboardDetails();
		}, 60000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	// useEffects
	useEffect(() => {
		if (
			userrole.userRoleId == AccountType.Physician &&
			searchCriteria.RequestStatusId == InformationTabTypes.New
		) {
			setShowDob(false);
		}
		getDashboardDetails();
	}, [searchCriteria]);

	return (
		<Box>
			<div className="chatbox-overlay"></div>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<InformationTab
					tabs={informationTabs}
					currentTabValue={+searchCriteria.RequestStatusId}
					handleTabChange={handleInfoTabChange}
					repeatSequence={6}
				/>
				<DashboardTableTitle
					searchCriteria={searchCriteria}
					handleClickSendLink={handleClickSendLink}
					handleClickExport={handleClickExport}
					handleClickExportAll={handleClickExportAll}
				/>
				<DashboardTable
					getDashboardDetails={getDashboardDetails}
					dashboardPageInfo={dashboardPageInfo}
					searchCriteria={searchCriteria}
					handleOnSearchCriteriaChange={handleOnSearchCriteriaChange}
					isShowDob={showDob}
				/>
			</main>
			{/* Page modals */}
			<SendLinkModal
				handleClickSendLinkClose={handleClickSendLinkClose}
				isSendLinkOpen={isSendLinkOpen}
			/>
		</Box>
	);
};

export default Dashboard;
