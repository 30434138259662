import {
	Card,
	CardContent,
	CardHeader,
	Collapse,
	TableCell,
	TableRow,
	Typography,
} from "@mui/material";
import {
	DoctorPrimaryIcon,
	LocationIcon,
	PhonePrimaryIcon,
	StatusCheckPrimaryIcon,
} from "assests/images";
import { ExpandMore } from "components";
import React, { useState } from "react";
import { IHaloRequest } from "utility/interfaces";

type ComponentProps = {
	row: IHaloRequest;
	isSmallDevice: boolean;
};

export const HaloRequestsTableRow = (props: ComponentProps) => {
	// Extract props
	const { row, isSmallDevice } = props;

	// Use state
	const [expanded, setExpanded] = useState(false);

	// handle events
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<>
			{!isSmallDevice ? (
				<TableRow>
					<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
						{row.clientName ?? "-"}
					</TableCell>
					<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
						{row.phoneNumber ?? "-"}
					</TableCell>
					<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
						{row.email ?? "-"}
					</TableCell>
					<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
						{row.workPlaceName ?? "-"}
					</TableCell>
					<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
						{row.statusType ?? "-"}
					</TableCell>
					<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
						{row.physicianName ?? "-"}
					</TableCell>
					<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
						{row.address ?? "-"}
					</TableCell>
				</TableRow>
			) : (
				<Card className="bg-white">
					<div className="card-container">
						<ExpandMore
							expand={expanded}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label="show more"
						>
							<CardHeader
								title={
									<React.Fragment>
										<span>{row.clientName}</span>
									</React.Fragment>
								}
							/>
							<CardContent className="card-header-content">
								<div className="card-subheader">
									<Typography variant="body2" color="text.secondary">
										{row.email}
									</Typography>
								</div>
							</CardContent>
						</ExpandMore>
						<Collapse in={expanded} timeout="auto" unmountOnExit>
							<CardContent>
								<div>
									<span>
										<img src={PhonePrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Phone Number:&nbsp;
										<span>{row.phoneNumber ?? "-"}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={LocationIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Work Place Name:&nbsp;
										<span>{row.workPlaceName ?? "-"}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={StatusCheckPrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Status:&nbsp;
										<span>{row.statusType ?? "-"}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={DoctorPrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Physician Name:&nbsp;
										<span>{row.physicianName ?? "-"}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={LocationIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Address:&nbsp;
										<span>{row.address ?? "-"}</span>
									</Typography>
								</div>
								<div></div>
							</CardContent>
						</Collapse>
					</div>
				</Card>
			)}
		</>
	);
};
