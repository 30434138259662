import { TextField } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
	ClockIcon,
	ClockPrimaryIcon,
	ClockSecondaryIcon,
} from "assests/images";
import React, { SyntheticEvent } from "react";

type ComponentProps = {
	value: string | undefined;
	label: string;
	onChange: any;
	name: string;
	disabled: boolean;
	register?: any;
	error?: boolean;
	message?: string;
	minuteStep?: number;
};

export function CustomTimePicker(props: ComponentProps) {
	const {
		value,
		onChange,
		label,
		name,
		disabled,
		register,
		error,
		message,
		minuteStep,
	} = props;
	function datePickerIcon() {
		return (
			<>
				<img src={ClockSecondaryIcon} alt="time" />
				{/* <img src={ClockIcon} alt="time" /> */}
			</>
		);
	}
	return (
		<>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<TimePicker
					closeOnSelect={false}
					disabled={disabled}
					label={label}
					value={value || null}
					// {...(register ? { ...register(name) } : "")}
					onChange={(newValue) => {
						onChange(name, newValue);
					}}
					components={{
						OpenPickerIcon: datePickerIcon,
					}}
					onError={() => {
						console.log("Error");
					}}
					minutesStep={minuteStep ? minuteStep : 1}
					PopperProps={{ className: "z-index-max" }}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							id="time"
							className="datepicker"
							autoComplete="on"
							inputProps={{
								...params.inputProps,
							}}
							error={error}
							helperText={message ? message : ""}
						/>
					)}
				/>
			</LocalizationProvider>
		</>
	);
}
