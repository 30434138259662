import { yupResolver } from "@hookform/resolvers/yup";
import {
	Box,
	Button,
	IconButton,
	Modal,
	TextField,
	Typography,
} from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import haloService from "services/halo-service";
import roleService from "services/role-service";
import { AccountType } from "utility/enums/request-status";
import { commonModalStyle, createCommonAPICall } from "utility/helpers";
import { IHaloHR } from "utility/interfaces";
import { AddHaloHRSchema, EditHaloHRSchema } from "utility/validations";

type ComponentProps = {
	isOpen: boolean;
	handleClose: any;
	haloHRData: IHaloHR | null;
	getHaloHRInfo: any;
};

export const AddEditHaloHRModal = (props: ComponentProps) => {
	// Extract props
	const { isOpen, handleClose, haloHRData, getHaloHRInfo } = props;

	// Page level local variables
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// State variables
	const [formValues, setFormValues] = useState<IHaloHR>({
		queryString: "",
		administratorId: 0,
		username: "",
		firstName: "",
		lastName: "",
		password: "",
		workPlaceName: "",
		email: "",
		workPlaceId: 0,
	});

	// Form validator
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
	} = useForm({
		values: formValues,
		resolver: yupResolver(haloHRData ? EditHaloHRSchema : AddHaloHRSchema),
	});

	// Handle events
	const handleCloseModal = () => {
		clearFormValues();
		clearErrors();
		handleClose();
	};

	const clearFormValues = () => {
		setFormValues({
			queryString: "",
			administratorId: 0,
			username: "",
			firstName: "",
			lastName: "",
			password: "",
			workPlaceName: "",
			email: "",
			workPlaceId: 0,
		});
	};

	const handleOnChangeFormValue = (name: string, value: any) => {
		setFormValues({ ...formValues, [name]: value });
	};

	const handleGenerateUsername = async () => {
		if (formValues.firstName != "" && formValues.lastName != "") {
			let username = `HA.${formValues.lastName}.${formValues.firstName[0]}`;
			console.log(username);
			const requestBody = {
				oldUsername: username,
				firstName: formValues.firstName,
				lastName: formValues.lastName,
				role: AccountType.HaloAdministrator,
			};
			const data = await createCommonAPICall({
				requestBody,
				apiService: roleService.getFreshUsername,
				showErrorMessage: true,
				showSuccessMessage: false,
				setSuccessErrorContext,
			});

			if (data && data.isSuccessfull) {
				console.log(data);
				setFormValues({ ...formValues, username: data.data });
			}
		} else {
			setFormValues({ ...formValues, username: "" });
		}
	};

	const handleOnClickSave = async (isUpdate: boolean) => {
		let apiService = haloService.createHaloAdministrator;
		if (isUpdate) {
			// Update Halo HR
			apiService = haloService.updateHaloAdministrator;
		}

		const data = await createCommonAPICall({
			requestBody: formValues,
			apiService: apiService,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			handleCloseModal();
			getHaloHRInfo();
		} else {
			if (isUpdate && haloHRData) setFormValues(haloHRData);
			else clearFormValues();
		}
	};

	// Use effects
	useEffect(() => {
		if (isOpen && haloHRData) setFormValues(haloHRData);
	}, [isOpen]);

	return (
		<>
			<Modal open={isOpen} onClose={handleClose} className="send-modal">
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">
							{haloHRData ? "Edit HR" : "Create HR"}
						</Typography>
						<IconButton onClick={handleCloseModal}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<div>
							<TextField
								{...register("username")}
								error={errors.username?.message ? true : false}
								helperText={errors.username?.message?.toString()}
								name="username"
								id="username"
								label="Username"
								value={formValues.username}
								fullWidth
								disabled
							/>
							<TextField
								{...register("firstName")}
								error={errors.firstName?.message ? true : false}
								helperText={errors.firstName?.message?.toString()}
								name="firstName"
								id="firstName"
								label="First Name"
								value={formValues.firstName}
								fullWidth
								onChange={(e) =>
									handleOnChangeFormValue(e.target.name, e.target.value)
								}
								{...(haloHRData
									? {}
									: { onBlurCapture: handleGenerateUsername })}
							/>
							<TextField
								{...register("lastName")}
								error={errors.lastName?.message ? true : false}
								helperText={errors.lastName?.message?.toString()}
								name="lastName"
								id="lastName"
								label="Last Name"
								value={formValues.lastName}
								fullWidth
								onChange={(e) =>
									handleOnChangeFormValue(e.target.name, e.target.value)
								}
								{...(haloHRData
									? {}
									: { onBlurCapture: handleGenerateUsername })}
							/>
							<TextField
								{...register("password")}
								error={errors.password?.message ? true : false}
								helperText={errors.password?.message?.toString()}
								name="password"
								id="password"
								label="Password"
								type="password"
								fullWidth
								value={formValues.password}
								onChange={(e) =>
									handleOnChangeFormValue(e.target.name, e.target.value)
								}
							/>
							<TextField
								{...register("workPlaceName")}
								error={errors.workPlaceName?.message ? true : false}
								helperText={errors.workPlaceName?.message?.toString()}
								name="workPlaceName"
								id="workPlaceName"
								label="Work Place Name"
								value={formValues.workPlaceName}
								fullWidth
								onChange={(e) =>
									handleOnChangeFormValue(e.target.name, e.target.value)
								}
							/>
							<TextField
								{...register("email")}
								error={errors.email?.message ? true : false}
								helperText={errors.email?.message?.toString()}
								name="email"
								id="email"
								label="Email"
								value={formValues.email}
								fullWidth
								onChange={(e) =>
									handleOnChangeFormValue(e.target.name, e.target.value)
								}
							/>
						</div>
						<div className="modal-footer">
							{haloHRData ? (
								<Button
									variant="contained"
									onClick={handleSubmit(() => handleOnClickSave(true))}
								>
									Update
								</Button>
							) : (
								<Button
									variant="contained"
									onClick={handleSubmit(() => handleOnClickSave(false))}
								>
									Create
								</Button>
							)}
							<Button variant="outlined" onClick={handleCloseModal}>
								Cancel
							</Button>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
};
