import { AxiosResponse } from "axios";
import {
	ICreatUpdateRoleRequest,
	IRoleDetails,
	IRoleInfo,
	ISearchCriteria,
	IHttpsResponse,
	IAcocuntInfoSearchCriteria,
	IProviderInfoSearchCriteria,
	IProviderInfo,
	IFormRequest,
	ISendMessageRequest,
	IDropDownList,
	IMDsOnCallRequest,
	IMDsOnCallResponse,
	IPayrateInfo,
	IGetUsernameRequest,
	IProviderLocationInfo,
} from "utility/interfaces";
import { ProtectedEndPoints } from "./api-end-points";
import httpClient from "./base-service";

const getGridRoles = async (
	searchCriteria: ISearchCriteria
): Promise<AxiosResponse<IHttpsResponse<IRoleInfo>>> =>
	httpClient.post<IHttpsResponse<IRoleInfo>>(
		ProtectedEndPoints.GetGridRoles,
		searchCriteria
	);

const getNewRoleDetails = async (): Promise<
	AxiosResponse<IHttpsResponse<IRoleDetails>>
> =>
	httpClient.get<IHttpsResponse<IRoleDetails>>(
		ProtectedEndPoints.GetNewRoleDetails
	);

const getRoleDetails = async (
	roleId: string
): Promise<AxiosResponse<IHttpsResponse<IRoleDetails>>> =>
	httpClient.post<IHttpsResponse<IRoleDetails>>(
		ProtectedEndPoints.GetRoleDetails,
		{
			roleId,
		}
	);

const createRole = async (
	request: ICreatUpdateRoleRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(ProtectedEndPoints.CreateRole, request);

const updateRole = async (
	request: ICreatUpdateRoleRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(ProtectedEndPoints.UpdateRole, request);

const deleteRole = async (
	roleId: number
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(ProtectedEndPoints.DeleteRole, {
		roleId,
	});

const getProvidersInfo = (
	request: IProviderInfoSearchCriteria
): Promise<AxiosResponse<IHttpsResponse<IProviderInfo>>> =>
	httpClient.post<IHttpsResponse<IProviderInfo>>(
		ProtectedEndPoints.GetProvidersInfo,
		request
	);

const getEditProviderProfile = (
	request: IFormRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.GetEditProviderProfile,
		request
	);

const updateProviderProfile = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.UpdateProviderProfile,
		request
	);

const sendMsgProvider = (
	request: ISendMessageRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.SendMsgProvider,
		request
	);

const changeProviderNotifications = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.ChangeProviderNotifications,
		request
	);

const getAccountsGrid = (
	request: IAcocuntInfoSearchCriteria
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.GetAccountsGrid,
		request
	);

const getShifts = (request: any): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(ProtectedEndPoints.GetShifts, request);

const saveShift = (request: any): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(ProtectedEndPoints.SaveShift, request);

const getPhysicianListByRegionIds = (
	request: number[]
): Promise<AxiosResponse<IHttpsResponse<IDropDownList[]>>> =>
	httpClient.post<IHttpsResponse<IDropDownList[]>>(
		ProtectedEndPoints.GetPhysicianListByRegionIds,
		request
	);

const changeShiftStatus = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.ChangeShiftStatus,
		request
	);

const deleteShift = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(ProtectedEndPoints.DeleteShift, request);

const getMDOnCall = (
	request: IMDsOnCallRequest
): Promise<AxiosResponse<IHttpsResponse<IMDsOnCallResponse>>> =>
	httpClient.post<IHttpsResponse<IMDsOnCallResponse>>(
		ProtectedEndPoints.GetMDOnCall,
		request
	);

const getRequestedShiftsGrid = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.GetRequestedShiftsGrid,
		request
	);

const approveRequestedShifts = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.ApproveRequestedShifts,
		request
	);

const deleteRequestedShifts = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.DeleteRequestedShifts,
		request
	);

const getPhysicianShiftHistory = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.GetPhysicianShiftHistory,
		request
	);

const deletePhysician = (
	request: IFormRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.DeletePhysician,
		request
	);

const getProviderPayrate = (
	request: IFormRequest
): Promise<AxiosResponse<IHttpsResponse<IPayrateInfo[]>>> =>
	httpClient.post<IHttpsResponse<IPayrateInfo[]>>(
		ProtectedEndPoints.GetProviderPayrate,
		request
	);

const saveProviderPayrate = (
	request: IPayrateInfo
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.SaveProviderPayrate,
		request
	);

const getFreshUsername = (
	request: IGetUsernameRequest
): Promise<AxiosResponse<IHttpsResponse<string>>> =>
	httpClient.post<IHttpsResponse<string>>(
		ProtectedEndPoints.GetFreshUsername,
		request
	);

const getProviderLocation = (): Promise<
	AxiosResponse<IHttpsResponse<IProviderLocationInfo[]>>
> => httpClient.get(ProtectedEndPoints.GetProviderLocation);

const getEditAdminProfile = (
	request: IFormRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.GetEditAdminProfile,
		request
	);

const saveAdminProfile = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.SaveAdminProfile,
		request
	);

const deleteAdminAccount = (
	request: IFormRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.DeleteAdminAccount,
		request
	);

export default {
	getGridRoles,
	getNewRoleDetails,
	getRoleDetails,
	updateRole,
	createRole,
	deleteRole,
	getProvidersInfo,
	getEditProviderProfile,
	updateProviderProfile,
	sendMsgProvider,
	changeProviderNotifications,
	getAccountsGrid,
	getShifts,
	saveShift,
	getPhysicianListByRegionIds,
	changeShiftStatus,
	deleteShift,
	getMDOnCall,
	getRequestedShiftsGrid,
	approveRequestedShifts,
	deleteRequestedShifts,
	getPhysicianShiftHistory,
	deletePhysician,
	getProviderPayrate,
	saveProviderPayrate,
	getFreshUsername,
	getProviderLocation,
	getEditAdminProfile,
	saveAdminProfile,
	deleteAdminAccount,
};
