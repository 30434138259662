import {
	Box,
	Button,
	FormControl,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Tooltip,
	Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { SortOrder } from "utility/enums/sort-order";
import { Constants } from "utility/enums/constants";
import {
	IAccountInfo,
	IAcocuntInfoSearchCriteria,
	IDropDownList,
} from "utility/interfaces";
import { AccountType } from "utility/enums/request-status";
import { visuallyHidden } from "@mui/utils";
import { AccountsInfoTableRow, TablePagination } from "components";
import { createCommonAPICall } from "utility/helpers";
import roleService from "services/role-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { useNavigate } from "react-router-dom";
import { AppRoutings } from "utility/enums/app-routings";
import { UserContext } from "contexts/user-context";

const Accounts: React.FC = () => {
	// Local level variables
	const navigate = useNavigate();

	// use context
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const contextUser = useContext(UserContext);

	// useStates
	const [searchCriteria, setSearchCriteria] =
		useState<IAcocuntInfoSearchCriteria>({
			PageIndexId: 0,
			PageSize: Constants.DefaultTablePageSize.valueOf(),
			SortOrder: SortOrder.ascending,
			SortBy: "AccountType",
			SearchBy: "",
			AccountId: 0,
		});
	// TODO: Dynamic
	const [accountInfo, setAccountInfo] = useState<IAccountInfo>({
		totalRecords: 3,
		accounts: [],
	});

	const [accountTypes, setAccountTypes] = useState<IDropDownList[]>([
		{
			text: "All",
			value: 0,
		},
		{
			text: "Admin",
			value: AccountType.Admin,
		},
		{
			// text: "Physician",
			text: "Clinical",
			value: AccountType.Physician,
		},
		// {
		// 	text: "Business",
		// 	value: AccountType.Business,
		// },
		// {
		// 	text: "VIP",
		// 	value: AccountType.VIP,
		// },
	]);

	// Handled event(s)
	const handleOnPageNumberChange = (value: number) => {
		setSearchCriteria({ ...searchCriteria, PageIndexId: value });
	};

	const handleOnChangeSortDirection = (sortBy: string) => {
		setSearchCriteria({
			...searchCriteria,
			SortBy: sortBy,
			SortOrder:
				searchCriteria.SortOrder === SortOrder.ascending
					? SortOrder.descending
					: SortOrder.ascending,
		});
	};

	const getAccountsInfo = async () => {
		const data = await createCommonAPICall({
			requestBody: searchCriteria,
			apiService: roleService.getAccountsGrid,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});
		if (data.isSuccessfull) {
			console.log(data);
			setAccountInfo(data.data);
		}
	};

	const handleOnClickEdit = (queryString: string, accountType: string) => {
		if (accountType === "Admin") {
			console.log("Open edit admin page", queryString);
			navigate(AppRoutings.EditAdmin.replace(":adminId", queryString));
		} else if (accountType === "Clinical") {
			console.log("Open edit physician page", queryString);
			navigate(AppRoutings.EditProvider.replace(":providerId", queryString));
		}
	};

	const handleOnClickAdd = () => {
		if (searchCriteria.AccountId === AccountType.Admin) {
			console.log("Open create admin page");
			navigate(AppRoutings.CreateAdmin.replace(":adminId", "TSpuh9yOuaA="));
		} else if (searchCriteria.AccountId === AccountType.Physician) {
			navigate(
				AppRoutings.CreateProvider.replace(":providerId", "TSpuh9yOuaA=")
			);
		}
	};

	// use effects
	useEffect(() => {
		getAccountsInfo();
	}, [searchCriteria]);

	return (
		<>
			<Box>
				<main className="main-content">
					<div
						className="overlay"
						onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
					></div>
					<Box className="request-container-box">
						<div className="request-header">
							<Typography variant="h2">User Access</Typography>
						</div>
						<Box className="request-box">
							<div className="table-header region-table-header">
								<FormControl className="region-search">
									<Select
										labelId="region-label"
										id="region"
										value={searchCriteria.AccountId}
										name="region"
										label="Search by Region"
										onChange={(event) => {
											setSearchCriteria({
												...searchCriteria,
												PageIndexId: 0,
												AccountId: +event.target.value,
											});
										}}
									>
										{accountTypes.map((account) => {
											return (
												<MenuItem key={account.value} value={account.value}>
													{account.text}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
								{searchCriteria.AccountId !== 0 &&
									(contextUser.isSuperAdmin ||
										searchCriteria.AccountId !== AccountType.Admin) && (
										<div className="tab-button-groups">
											<Tooltip title="Add new Account">
												<Button
													variant="contained"
													style={{ paddingTop: "9px", paddingBottom: "9px" }}
													onClick={handleOnClickAdd}
												>
													Add
												</Button>
											</Tooltip>
										</div>
									)}
							</div>
							{accountInfo.accounts.length > 0 ? (
								<>
									<TableContainer
										className="upload-table-container"
										sx={{ display: { xs: "none", laptop: "block" } }}
									>
										<Table className="upload-table checkbox-table">
											<TableHead>
												<TableRow>
													<TableCell
														sortDirection={searchCriteria.SortOrder}
														className="min-w-120"
													>
														<TableSortLabel
															active={searchCriteria.SortBy === "AccountType"}
															direction={searchCriteria.SortOrder}
															onClick={() =>
																handleOnChangeSortDirection("AccountType")
															}
														>
															Account type
															<Box component="span" sx={visuallyHidden}>
																{searchCriteria.SortOrder ===
																SortOrder.descending
																	? "sorted descending"
																	: "sorted ascending"}
															</Box>
														</TableSortLabel>
													</TableCell>
													<TableCell
														sortDirection={searchCriteria.SortOrder}
														className="min-w-200"
													>
														<TableSortLabel
															active={searchCriteria.SortBy === "accountPoc"}
															direction={searchCriteria.SortOrder}
															onClick={() =>
																handleOnChangeSortDirection("accountPoc")
															}
														>
															Account POC
															<Box component="span" sx={visuallyHidden}>
																{searchCriteria.SortOrder ===
																SortOrder.descending
																	? "sorted descending"
																	: "sorted ascending"}
															</Box>
														</TableSortLabel>
													</TableCell>
													<TableCell
														sortDirection={searchCriteria.SortOrder}
														className="min-w-160"
													>
														<TableSortLabel
															active={searchCriteria.SortBy === "phone"}
															direction={searchCriteria.SortOrder}
															onClick={() =>
																handleOnChangeSortDirection("phone")
															}
														>
															Phone
															<Box component="span" sx={visuallyHidden}>
																{searchCriteria.SortOrder ===
																SortOrder.descending
																	? "sorted descending"
																	: "sorted ascending"}
															</Box>
														</TableSortLabel>
													</TableCell>
													<TableCell
														sortDirection={searchCriteria.SortOrder}
														className="min-w-120"
													>
														<TableSortLabel
															active={searchCriteria.SortBy === "status"}
															direction={searchCriteria.SortOrder}
															onClick={() =>
																handleOnChangeSortDirection("status")
															}
														>
															Status
															<Box component="span" sx={visuallyHidden}>
																{searchCriteria.SortOrder ===
																SortOrder.descending
																	? "sorted descending"
																	: "sorted ascending"}
															</Box>
														</TableSortLabel>
													</TableCell>
													<TableCell
														sortDirection={searchCriteria.SortOrder}
														className="min-w-100"
													>
														<TableSortLabel
															active={searchCriteria.SortBy === "openRequests"}
															direction={searchCriteria.SortOrder}
															onClick={() =>
																handleOnChangeSortDirection("openRequests")
															}
														>
															Open Requests
															<Box component="span" sx={visuallyHidden}>
																{searchCriteria.SortOrder ===
																SortOrder.descending
																	? "sorted descending"
																	: "sorted ascending"}
															</Box>
														</TableSortLabel>
													</TableCell>
													<TableCell align="center" sx={{ width: 142 }}>
														Actions
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{accountInfo.accounts.map((row, index) => {
													return (
														<TableRow hover tabIndex={-1} key={index}>
															<AccountsInfoTableRow
																index={index}
																row={row}
																isSmallDevice={false}
																handleClickEdit={handleOnClickEdit}
															/>
														</TableRow>
													);
												})}
											</TableBody>
										</Table>
									</TableContainer>
									<Box
										sx={{ display: { xs: "block", laptop: "none" } }}
										className="tabledata-cards-group"
									>
										{accountInfo.accounts.map((row, index) => {
											return (
												<AccountsInfoTableRow
													index={index}
													row={row}
													isSmallDevice={true}
													handleClickEdit={handleOnClickEdit}
												/>
											);
										})}
									</Box>
									<div className="small-table-pagination">
										<TablePagination
											currentPageNumber={searchCriteria.PageIndexId}
											handleOnPageNumberChange={handleOnPageNumberChange}
											totalRecords={accountInfo?.totalRecords || 0}
											rowCount={accountInfo?.accounts?.length || 0}
										/>
									</div>
								</>
							) : (
								<Typography
									variant="h5"
									className="no-record"
									sx={{ padding: 3 }}
								>
									No Record(s) Found
								</Typography>
							)}
						</Box>
					</Box>
				</main>
			</Box>
		</>
	);
};

export default Accounts;
