import React, { useState } from "react";
import { Box, Button, Grid, TextField, Tooltip } from "@mui/material";
import { Constants } from "utility/enums/constants";
import { SortOrder } from "utility/enums/sort-order";
import { IPatientHistorySearchCriteria } from "utility/interfaces";

type ComponentProps = {
	handleOnSearchCriteriaChange: any;
};

export function PatientHistoryTableSearch(props: ComponentProps) {
	// Extract Props
	const { handleOnSearchCriteriaChange } = props;

	// useStates
	const [formValues, setFormValues] = useState<IPatientHistorySearchCriteria>({
		PageIndexId: 0,
		PageSize: Constants.DefaultTablePageSize.valueOf(),
		SortOrder: SortOrder.descending,
		SortBy: "CreatedDate",
		SearchBy: "",
		firstName: "",
		lastName: "",
		email: "",
		phoneNumber: "",
	});

	// Handled events and functions
	const handleFormValueChange = (name: string, value: any) => {
		setFormValues({ ...formValues, [name]: value });
	};
	const handleOnClearForm = () => {
		setFormValues({
			PageIndexId: 0,
			PageSize: Constants.DefaultTablePageSize.valueOf(),
			SortOrder: SortOrder.descending,
			SortBy: "CreatedDate",
			SearchBy: "",
			firstName: "",
			lastName: "",
			email: "",
			phoneNumber: "",
		});
		handleOnSearchCriteriaChange({
			PageIndexId: 0,
			PageSize: Constants.DefaultTablePageSize.valueOf(),
			SortOrder: SortOrder.descending,
			SortBy: "CreatedDate",
			SearchBy: "",
			firstName: null,
			lastName: null,
			email: null,
			phoneNumber: null,
		});
	};

	const handleOnClickSearch = () => {
		handleOnSearchCriteriaChange({
			...formValues,
			firstName: formValues.firstName != "" ? formValues.firstName : null,
			lastName: formValues.lastName != "" ? formValues.lastName : null,
			email: formValues.email != "" ? formValues.email : null,
			phoneNumber: formValues.phoneNumber != "" ? formValues.phoneNumber : null,
		});
	};

	return (
		<>
			<div>
				<div className="history-table-header">
					<Grid container columnSpacing={{ sm: 2, lg: 3 }}>
						<Grid item xs={12} sm={3}>
							<TextField
								fullWidth
								id="firstName"
								label="First Name"
								variant="outlined"
								name="firstName"
								value={formValues.firstName}
								onChange={(e) =>
									handleFormValueChange("firstName", e.target.value)
								}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextField
								fullWidth
								id="lastName"
								label="Last Name"
								variant="outlined"
								name="lastName"
								value={formValues.lastName}
								onChange={(e) =>
									handleFormValueChange("lastName", e.target.value)
								}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextField
								fullWidth
								id="email"
								label="Email"
								variant="outlined"
								name="email"
								value={formValues.email}
								onChange={(e) => handleFormValueChange("email", e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextField
								fullWidth
								id="phoneNumber"
								label="Phone Number"
								variant="outlined"
								name="phoneNumber"
								value={formValues.phoneNumber}
								onChange={(e) =>
									handleFormValueChange("phoneNumber", e.target.value)
								}
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Box
								sx={{
									display: "flex",
									columnGap: "12px",
									justifyContent: "flex-end",
									marginBottom: "14px",
								}}
							>
								<div className="history-search-btn">
									<Tooltip title="Click To Search">
										<Button variant="outlined" onClick={handleOnClearForm}>
											<span className="button-link">Clear</span>
										</Button>
									</Tooltip>
								</div>
								<div className="history-search-btn">
									<Tooltip title="Click To Search">
										<Button variant="contained" onClick={handleOnClickSearch}>
											<span className="button-link">Search</span>
										</Button>
									</Tooltip>
								</div>
							</Box>
						</Grid>
					</Grid>
				</div>
			</div>
		</>
	);
}
