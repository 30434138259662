import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Constants } from "utility/enums/constants";
import { SortOrder } from "utility/enums/sort-order";
import {
	IPatientHistorySearchCriteria,
	IPatientHistory,
} from "utility/interfaces";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { PatientHistoryTable } from "components";
import historyService from "services/history-service";
import { createCommonAPICall } from "utility/helpers";

const PatientHistory: React.FC = () => {
	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// useStates
	const [patientHistoryPageInfo, setPatientHistoryPageInfo] =
		React.useState<IPatientHistory | null>(null);

	const [searchCriteria, setSearchCriteria] =
		useState<IPatientHistorySearchCriteria>({
			PageIndexId: 0,
			PageSize: Constants.DefaultTablePageSize.valueOf(),
			SortOrder: SortOrder.descending,
			SortBy: "CreatedDate",
			SearchBy: "",
			firstName: null,
			lastName: null,
			email: null,
			phoneNumber: null,
		});

	// Handled events and functions
	const handleOnSearchCriteriaChange = (
		newSearchCriteria: IPatientHistorySearchCriteria
	) => {
		setSearchCriteria(newSearchCriteria);
	};
	const getHistoryPageGrids = async () => {
		console.log(searchCriteria);
		const data = await createCommonAPICall({
			requestBody: searchCriteria,
			apiService: historyService.getPatientRecordsGrid,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.isSuccessfull) {
			setPatientHistoryPageInfo({
				requests: data.data.requests,
				totalRecords: data.data.totalRecords,
			});
		}
	};

	// useEffects
	useEffect(() => {
		getHistoryPageGrids();
	}, [searchCriteria]);

	return (
		<>
			<Box>
				<div className="chatbox-overlay"></div>
				<main className="main-content">
					<div
						className="overlay"
						onClick={() => document.body.classList.toggle("sidebar-toggle")}
					></div>
					<Box className="tab-item-header">
						<Typography variant="h2">Patient History</Typography>
					</Box>
					<PatientHistoryTable
						handleOnSearchCriteriaChange={handleOnSearchCriteriaChange}
						searchCriteria={searchCriteria}
						rowData={patientHistoryPageInfo}
					/>
				</main>
			</Box>
		</>
	);
};

export default PatientHistory;
