import moment from "moment";
import React, { MouseEvent, useEffect, useState } from "react";
import { ViewAllShiftsModal } from "./view-all-shifts-modal";
import {
	IScheduleData,
	IShiftSchedule,
	IWeeklyCoverageHours,
} from "utility/interfaces";

type ComponentProps = {
	scheduleData: IScheduleData;
	currDate: Date;
	weeklyCoverageHours: IWeeklyCoverageHours[];
	handleEditShiftClick: any;
	loggedInPhysicianId?: number;
};

export const MonthView = (props: ComponentProps) => {
	// Exract props
	const {
		scheduleData,
		currDate,
		weeklyCoverageHours,
		handleEditShiftClick,
		loggedInPhysicianId,
	} = props;

	// use state
	const [objDate, setObjDate] = useState({
		fullDate: currDate,
		days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
		fullDays: [
			"Sunday",
			"Monday",
			"Tueday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
		],
		months: [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		],
	});
	const [isViewAllShiftModalOpen, setIsViewAllShiftsModalOpen] =
		useState(false);
	const [viewAllModalData, setViewAllModalData] = useState<IShiftSchedule[]>(
		[]
	);
	const [viewAllModalDate, setViewAllModalDate] = useState("");

	// Handle events
	const renderMonthView = () => {
		let ele = document.getElementById("monthViewDiv");
		if (ele) {
			ele.innerHTML = "";
			let table = document.createElement("table"),
				tBody = document.createElement("tbody");
			if (scheduleData.physicianList) {
				let calender = {
					getFirstDay: function (year: number, month: number) {
						var d = new Date(year, month, 1);
						return d.getDay();
					},
					getLastDay: function (year: number, month: number) {
						var d = new Date(year, month + 1, 0);
						return d.getDate();
					},
					setTodaysDate: function () {
						var date = new Date(),
							month = date.getMonth(),
							year = date.getFullYear();
						calender.generateCalender(year, month);
					},
					getMonth: function (month: number) {
						return objDate.months[month];
					},
					generateCalender: function (year: number, month: number) {
						var firstDay = calender.getFirstDay(year, month),
							lastDay = calender.getLastDay(year, month),
							tr = "",
							td = "",
							z = 1;

						for (var i = 0; i <= 41; i++) {
							if ((i % 7 == 0 || i == 41) && i != 0) {
								tr += "<tr>" + td + "</tr>";
								td = "";
							}
							if (i >= firstDay && z <= lastDay) {
								var tbody = calender.getTableFromData(
									scheduleData.shifts,
									month + 1 + "/" + z + "/" + year
								);
								td +=
									"<td style='padding: 0px'><div><table class=''>" +
									tbody +
									"</table></div></td>";
								z++;
							} else if (i > 34) {
							} else {
								td +=
									"<td><div><table ><tbody><tr><th style='padding: 7px;'>&nbsp;</th></tr><tr><td></td></tr><tr><td></td></tr><tr><td></td></tr><tr><td></td></tr><tr><td></td></tr></tbody></table></div></td>";
							}
						}

						var Thtr = document.createElement("tr");

						for (var k = 0; k < objDate.days.length; k++) {
							var $th = document.createElement("th");

							var divEle = document.createElement("div");
							divEle.textContent = objDate.days[k];
							divEle.classList.add("monthTitle");

							$th.append(divEle);
							Thtr.append($th);
						}
						tBody.innerHTML = "";
						tBody.appendChild(Thtr);
						tBody.innerHTML += tr;
						table.appendChild(tBody);
						table.classList.add("monthgrid");
						if (ele) ele.appendChild(table);
					},

					getTableFromData: function (
						shiftData: IShiftSchedule[],
						date: string
					) {
						var $innTable = document.createElement("table"),
							$tbody = document.createElement("tbody"),
							$th = document.createElement("th"),
							$thTr = document.createElement("tr");
						$th.textContent = moment(date, "MM/DD/YYYY").format("D");

						$th.setAttribute(
							"name",
							"coverage_" + moment(date, "MM/DD/YYYY").format("D")
						);
						$th.setAttribute("shift", moment(date, "MM/DD/YYYY").format("D"));

						// $th.classList.add("");
						$thTr.append($th);
						$tbody.append($thTr);

						var data = shiftData.filter(function (el) {
							return (
								moment(el.date).format("MM/DD/YYYY") ==
								moment(date).format("MM/DD/YYYY")
							);
						});

						$tbody.setAttribute("data-allData", JSON.stringify(data));

						var isExtra = data.length > 5;

						for (var l = 0; l < 5; l++) {
							var $innTr = document.createElement("tr"),
								$innTd = document.createElement("td");

							if (l == 4 && isExtra) {
								var $a = document.createElement("a");
								$a.textContent = "VIEW ALL (" + (data.length - l) + " MORE)";
								$a.classList.add("viewMoreBtn");
								$a.setAttribute("data-date", data[0].date);
								$innTd.append($a);

								$innTd.setAttribute(
									"style",
									`text-align: center; cursor: pointer`
								);
							} else {
								if (data[l]) {
									$innTd.classList.add("monthShifts");

									$innTd.setAttribute("ShiftDetail", data[l].id.toString());
									$innTd.setAttribute(
										"PhysicianId",
										data[l].physicianId.toString()
									);

									$innTd.innerHTML =
										"<span style='pointer-events: none'>" +
										data[l].startFull +
										" - " +
										data[l].endFull +
										" / " +
										data[l].name +
										"</span>";

									if (data[l].status == 1) $innTd.classList.add("redShifts");
									else $innTd.classList.add("greenShifts");

									// if (moment(data[i].date).utc() < moment(dDate).utc())
									var shiftDate = moment(
										moment(data[l].date).startOf("day").format("MM/DD/YYYY")
									);
									var curDate = moment(
										moment().startOf("day").format("MM/DD/YYYY")
									);
									if (shiftDate < curDate)
										$innTd.classList.add("disableShifts");

									if (
										loggedInPhysicianId &&
										loggedInPhysicianId !== data[l].physicianId
									)
										$innTd.classList.add("disableShifts");
								} else {
									$innTd.classList.add("createShifts");
								}
							}
							$innTr.append($innTd);
							$tbody.append($innTr);
						}

						$innTable.append($tbody);
						return $innTable.innerHTML;
					},
				};

				calender.generateCalender(currDate.getFullYear(), currDate.getMonth());
			}
			renderMonthCoverageColors();
			document.querySelectorAll(".monthShifts").forEach((e) => {
				e.addEventListener("click", (eve) => {
					let td = eve.target as HTMLTableColElement;
					let selectedShift = scheduleData.shifts.find(
						(s) => s.id.toString() == td.getAttribute("shiftDetail")
					);
					handleEditShiftClick(selectedShift);
				});
			});

			document.querySelectorAll(".viewMoreBtn").forEach((e) => {
				e.addEventListener("click", (eve) => {
					let a = eve.target as HTMLAnchorElement;
					let date = a.getAttribute("data-date");
					if (date) showPopup(date);
				});
			});

			if (loggedInPhysicianId) {
				document.querySelectorAll(".createShifts").forEach((e) => {
					e.addEventListener("click", () => {
						handleEditShiftClick(null);
					});
				});
			}
		}
	};

	const showPopup = (date: string) => {
		console.log(date);
		setViewAllModalDate(date);
		let data = scheduleData.shifts.filter((s) => {
			return s.date == date;
		});
		console.log(data);
		setViewAllModalData(data);
		handleViewShiftsModalOpen();
	};

	const handleViewShiftsModalOpen = () => {
		setIsViewAllShiftsModalOpen(true);
	};

	const handleViewShiftsModalClose = () => {
		setIsViewAllShiftsModalOpen(false);
	};

	const renderMonthCoverageColors = () => {
		var currentDate = moment(currDate).startOf("month");
		var initialized = false;
		var date = moment();
		var thElements = document.querySelectorAll("th[name^='coverage']");

		thElements.forEach(function (val, i) {
			var day = val.getAttribute("shift")!;

			if (!initialized) {
				date = moment(
					new Date(currentDate.year(), currentDate.month(), parseInt(day))
				);
				initialized = true;
			} else {
				date.add(1, "day");
			}

			var dayMatch = weeklyCoverageHours.filter(function (val2) {
				return (
					moment(val2.date).format("MM/DD/YYYY") === date.format("MM/DD/YYYY")
				);
			});

			if (dayMatch.length !== 0) {
				if (dayMatch[0].totalHours === 24) {
					val.classList.add("greyCoverage");
				} else if (
					dayMatch[0].totalHours >= 16 &&
					dayMatch[0].totalHours < 24
				) {
					val.classList.add("orangeCoverage");
				} else {
					val.classList.add("pinkCoverage");
				}
			} else {
				val.classList.add("pinkCoverage");
			}
		});
	};

	// use effects
	useEffect(() => {
		renderMonthView();
	});

	return (
		<>
			<div id="monthViewDiv"></div>
			{/* Page modals */}
			<ViewAllShiftsModal
				isOpen={isViewAllShiftModalOpen}
				handleClose={handleViewShiftsModalClose}
				date={viewAllModalDate}
				shifts={viewAllModalData}
				handleShiftClick={handleEditShiftClick}
				isRequestedShiftPage={false}
			/>
		</>
	);
};
