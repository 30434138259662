import { Box, Button, Typography } from "@mui/material";
import { ArrowBack } from "assests/images";
import { GoogleMap } from "components";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import roleService from "services/role-service";
import { createCommonAPICall } from "utility/helpers";
import { IHttpsResponse, IProviderLocationInfo } from "utility/interfaces";

const ProviderLocation: React.FC = () => {
	// Page level local variable
	const navigate = useNavigate();
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// State variables
	const [providerLocations, setProviderLocations] = useState<
		IProviderLocationInfo[]
	>([]);

	// Handle events
	const handleOnClickBackCancel = () => navigate(-1);

	const getPhysicianLocation = async () => {
		const data: IHttpsResponse<IProviderLocationInfo[]> =
			await createCommonAPICall({
				apiService: roleService.getProviderLocation,
				showErrorMessage: true,
				showSuccessMessage: false,
				setSuccessErrorContext,
			});

		if (data && data.isSuccessfull) {
			console.log(data.data);
			setProviderLocations(data.data);
		}
	};

	// Use effects
	useEffect(() => {
		getPhysicianLocation();
	}, []);

	return (
		<Box>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="request-container-box">
					<div className="request-header">
						<Typography variant="h2">Provider Location</Typography>
						<Button variant="outlined" onClick={handleOnClickBackCancel}>
							<img src={ArrowBack} alt="arrow" />
							Back
						</Button>
					</div>
					<Box className="request-box">
						{providerLocations.length > 0 && (
							<GoogleMap providerData={providerLocations} />
						)}
					</Box>
				</Box>
			</main>
		</Box>
	);
};

export default ProviderLocation;
