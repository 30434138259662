import React, { useState, useEffect, useContext } from "react";
import {
	Button,
	FormControl,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import { IDashboardSearchCriteria, IDropDownList } from "utility/interfaces";
import { getTypeWiseClassName } from "utility/helpers";
import { AccountType, RequestTypes } from "utility/enums/request-status";
import { Constants } from "utility/enums/constants";
import { UserContext } from "contexts/user-context";
import { env } from "process";

type ComponentProps = {
	searchCriteria: IDashboardSearchCriteria;
	handleOnSearchCriteriaChange: any;
	regions: IDropDownList[];
};
interface ITypes {
	title: string;
	type: number;
}

const types: ITypes[] = [
	{ title: "All", type: RequestTypes.All },
	{ title: "Patient", type: RequestTypes.Patient },
	{ title: "Family/Friend", type: RequestTypes.Family },
	{ title: "Business", type: RequestTypes.Business },
	{ title: "Concierge", type: RequestTypes.Concierge },
	{ title: "VIP", type: RequestTypes.VIP },
	// { title: "Declined By", type: RequestTypes.Declined },
];

export function DashboardTableSearch(props: ComponentProps) {
	// Extract Props
	const { handleOnSearchCriteriaChange, searchCriteria, regions } = props;

	// Use context
	const contextUser = useContext(UserContext);

	// useStates
	const [searchText, setSearchText] = useState("");

	// Handled events and functions
	const handleOnSearchTextChange = (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setSearchText(e.target.value);
	};
	const handleOnTypeChange = (type: number) => {
		const newSearchCriteria = {
			...searchCriteria,
			PageIndexId: 0,
			RequestTypeId: type,
		};
		handleOnSearchCriteriaChange(newSearchCriteria);
	};
	const handleSearchKeywordKeyUp = (event: any) => {
		// Enter keycode = 13
		if (event.keyCode === 13 || event.keyCode === 9) {
			const newSearchCriteria = {
				...searchCriteria,
				PageIndexId: 0,
				SearchBy: event.target.value.trim(),
			};
			handleOnSearchCriteriaChange(newSearchCriteria);
		}
	};

	// useEffects
	useEffect(() => {
		setSearchText(searchCriteria.SearchBy);
	}, [searchCriteria]);

	return (
		<>
			<div className="table-header">
				<div className="table-input-group">
					<TextField
						id="search"
						placeholder="Search Patients"
						variant="outlined"
						onKeyDown={handleSearchKeywordKeyUp}
						value={searchText}
						onChange={handleOnSearchTextChange}
						style={{ marginRight: "15px", marginBottom: "10px" }}
					/>
					{contextUser.userRoleId === AccountType.Admin && (
						<FormControl fullWidth className="region-search">
							<Select
								labelId="region-label"
								id="region"
								value={searchCriteria.RegionId}
								name="region"
								label="Search by Region"
								fullWidth
								onChange={(event) => {
									handleOnSearchCriteriaChange({
										...searchCriteria,
										PageIndexId: 0,
										PageSize: Constants.DefaultTablePageSize.valueOf(),
										RegionId: event.target.value,
									});
								}}
							>
								{regions.map((region) => {
									return (
										<MenuItem key={region.value} value={region.value}>
											{region.text}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					)}
				</div>

				<div className="table-btn-group">
					{types.map((item) => {
						return (
							<Button
								disableElevation
								disableRipple
								key={item.type}
								onClick={() => handleOnTypeChange(item.type)}
								className={
									item.type === searchCriteria.RequestTypeId
										? item.type == RequestTypes.All
											? "search-all active"
											: "active"
										: ""
								}
							>
								{item.type !== RequestTypes.All ? (
									<span className={getTypeWiseClassName(item.type)}></span>
								) : null}
								{item.title}
							</Button>
						);
					})}
				</div>
			</div>
		</>
	);
}
