import React, { useContext, useEffect } from "react";
import { Box } from "@mui/material";
import { ICreateRole, IRoleDetails, IDropDownList } from "utility/interfaces";
import { RoleForm } from "components";
import roleService from "services/role-service";
import { createCommonAPICall } from "utility/helpers";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";

const CreateRole: React.FC = () => {
	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// useStates
	const [formValues, setFormValues] = React.useState<ICreateRole>({
		roleId: 0,
		name: "",
		accountType: 0,
		roleMenus: [],
		accountTypes: [],
	});

	// handled method(s)
	const setUpFormValues = (details: IRoleDetails) => {
		const accountTypes: IDropDownList[] = [];

		details.accountTypes.forEach((account) => {
			const accountTypeList: IDropDownList = {
				value: account.value,
				text: account.text,
			};
			accountTypes.push(accountTypeList);
		});
		const formValue: ICreateRole = {
			roleId: 0,
			name: "",
			accountType:
				details.accountTypes.filter((type) => type.isSelected)[0]?.value ||
				details.accountTypes[0].value,
			roleMenus: details.menus,
			accountTypes: accountTypes,
		};
		setFormValues(formValue);
	};

	const getCreateRoleDetails = async () => {
		const data = await createCommonAPICall({
			apiService: roleService.getNewRoleDetails,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.data) setUpFormValues(data.data);
	};

	// useEffect
	useEffect(() => {
		getCreateRoleDetails();
	}, []);

	return (
		<Box>
			<RoleForm
				formValues={formValues}
				setFormValues={setFormValues}
				title="Create Role"
			/>
		</Box>
	);
};

export default CreateRole;
