import React, { useContext, useState } from "react";
import { Box, Button, TableCell, Tooltip } from "@mui/material";
import { IRole } from "utility/interfaces";
import { WarningModal } from "components";
import { CrossRedIcon } from "assests/images";
import { useNavigate } from "react-router";
import { AppRoutings } from "utility/enums/app-routings";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";

type ComponentProps = {
	row: IRole;
	handleOnClickDelete: any;
};

export function RoleTableRow(props: ComponentProps) {
	// Extract Props
	const { row, handleOnClickDelete } = props;

	// Page level variable
	const navigate = useNavigate();
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// useStates
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	return (
		<>
			<TableCell component="th" scope="row" className="upload-file-col">
				<Box className="display-small-table-label">Name: &nbsp;</Box>
				<span>{row.name}</span>
			</TableCell>
			<TableCell>
				<Box className="display-small-table-label">Account Type: &nbsp;</Box>

				<span>{row.accountTypeName}</span>
			</TableCell>
			<TableCell align="center">
				<div className="upload-actions">
					<Tooltip title="Edit">
						<Button
							id="table-button-edit"
							variant="outlined"
							className="action-btn-primary action-btn"
							style={{ marginRight: 4 }}
							onClick={() =>
								navigate(
									AppRoutings.UpdateRole.replace(":roleId", row.queryString)
								)
							}
						>
							Edit
						</Button>
					</Tooltip>
					<Tooltip title="Delete">
						<Button
							onClick={() => setDeleteModalOpen(true)}
							variant="outlined"
							className="action-btn-primary action-btn"
							id="table-button-delete"
						>
							Delete
						</Button>
					</Tooltip>
				</div>
			</TableCell>
			<WarningModal
				isModalOpen={deleteModalOpen}
				handleOnClickCloseModal={() => setDeleteModalOpen(false)}
				handleOnClickOk={() => handleOnClickDelete(row)}
				warningMessage={`Are sure, you want to delete role, ${row.name}?`}
				title={"Delete confirmation"}
				okButtonText={"Delete"}
				closeButtonText={"Cancel"}
				warningIcon={CrossRedIcon}
			/>
		</>
	);
}
