import React, { useState } from "react";
import {
	Button,
	TableCell,
	TableRow,
	ButtonProps,
	Card,
	CardContent,
	Typography,
	Collapse,
	CardHeader,
} from "@mui/material";
import { CalendarIcon } from "assests/images";
import { styled } from "@mui/material/styles";
import {
	ICancelHistory,
	ICancelHistorySearchCriteria,
} from "utility/interfaces";

type ComponentProps = {
	row: ICancelHistory;
	isSmallDevice: boolean;
	searchCriteria: ICancelHistorySearchCriteria;
};

interface ExpandMoreProps extends ButtonProps {
	expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
	const { expand, ...other } = props;
	return (
		<Button {...other} disableFocusRipple disableElevation disableRipple />
	);
})(({ theme, expand }) => ({
	marginLeft: "auto",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));

export function CancelHistoryTableRowData(props: ComponentProps) {
	// useStates
	const [expanded, setExpanded] = useState(false);

	// Extract Props
	const { row, isSmallDevice, searchCriteria } = props;

	// Handled events and functions
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<>
			{!isSmallDevice ? (
				<>
					<TableRow className="bg-white">
						<TableCell component="th" scope="row" className="wrap min-w-160">
							{row.patientName}
						</TableCell>
						<TableCell className="wrap min-w-140">{row.phoneNumber}</TableCell>
						<TableCell className="wrap min-w-150">{row.email}</TableCell>
						<TableCell className="wrap min-w-130">{row.modifiedDate}</TableCell>
						<TableCell style={{ maxWidth: 330 }} className="wrap min-w-330">
							{row.cancelledByAdminNote}
						</TableCell>
						<TableCell style={{ maxWidth: 330 }} className="wrap min-w-330">
							{row.cancelledByPhysicianNote}
						</TableCell>
					</TableRow>
				</>
			) : (
				// Mobile view
				<Card className="bg-white">
					<div className="card-container">
						<ExpandMore
							expand={expanded}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label="show more"
						>
							<CardHeader
								title={
									<React.Fragment>
										<span>{row.patientName}</span>
									</React.Fragment>
								}
							/>
							<CardContent className="card-header-content">
								<div className="card-subheader">
									<Typography variant="body2" color="text.secondary">
										{row.email}
									</Typography>
								</div>
								<div className="card-subheader">
									<Typography variant="body2" color="text.secondary">
										{row.phoneNumber}
									</Typography>
								</div>
							</CardContent>
						</ExpandMore>
						<Collapse in={expanded} timeout="auto" unmountOnExit>
							<CardContent>
								<div>
									<span>
										<img src={CalendarIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Modified Date:&nbsp;
										<span>{row.modifiedDate}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={CalendarIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Cancelled By Admin Note:&nbsp;
										<span>{row.cancelledByAdminNote}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={CalendarIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Cancelled By Physician Note:&nbsp;
										<span>{row.cancelledByPhysicianNote}</span>
									</Typography>
								</div>

								<div className="table-cell-btn-group"></div>
							</CardContent>
						</Collapse>
					</div>
				</Card>
			)}
		</>
	);
}
