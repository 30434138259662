import React, { useEffect } from "react";
import { Pagination, Typography } from "@mui/material";
import { Constants } from "utility/enums/constants";

type ComponentProps = {
	currentPageNumber: number;
	handleOnPageNumberChange: any;
	totalRecords: number;
	rowCount: number;
};

export function TablePagination(props: ComponentProps) {
	const {
		handleOnPageNumberChange,
		totalRecords,
		rowCount,
		currentPageNumber,
	} = props;

	// useStates

	const onPageNumberChange = (e: any, value: number) => {
		handleOnPageNumberChange(+value - 1);
	};

	const calculatePageNumbers = () => {
		return `${currentPageNumber * Constants.DefaultTablePageSize + 1}-${
			currentPageNumber * Constants.DefaultTablePageSize + rowCount
		} of ${totalRecords}`;
	};

	return (
		<div className="table-pagination">
			<Typography variant="h6" className="pagination-row">
				{calculatePageNumbers()}
			</Typography>
			<Pagination
				page={currentPageNumber + 1}
				onChange={onPageNumberChange}
				count={Math.ceil(totalRecords / Constants.DefaultTablePageSize)}
				variant="outlined"
				shape="rounded"
			/>
		</div>
	);
}
