export enum Constants {
	// Values -------
	DefaultTablePageSize = 20,
	AppTokenKey = "app_token",
	UserDetails = "user_details",
	MenuPermissions = "menu_permissions",
	DtySecretKey = "dty_secret",
	ChatCount = "chat_count",
	PageYOffset = "page_y_offset",
	TrackIdKey = "track_ids",
	LocalAppEncryptkey = "123456789",

	// Local
	// AdminId = 1,
	// HubUrl = "https://localhost:7080/chat-hub",
	// LocationHubUrl = "https://localhost:7080/location-hub",

	// Stage, live
	AdminId = 34,
	// HubUrl = "http://34.148.250.95:91/chat-hub",
	// LocationHubUrl = "http://34.148.250.95:91/location-hub",

	HubUrl = "https://api2.doctorstoyou.com/chat-hub",
	LocationHubUrl = "https://api2.doctorstoyou.com/location-hub",

	// // Old Stage Api
	// HubUrl = "http://172.174.160.184:91/chat-hub",
	// LocationHubUrl = "http://172.174.160.184:91/location-hub",

	RingCentralScript = "https://ringcentral.github.io/ringcentral-embeddable/adapter.js",
	// GoogleKey = "",
	GoogleKey = "AIzaSyAfEZn4DREuddHRFBoGw4Q83DHL_YyHQfk",
	GeocodeKey = "AIzaSyC6oU-5BUHNiFf5Mdm8pAEf1-qBjQnJfzQ",
	MaxImageSize = 10048576,
	ProviderBusyDefaultMessage = "This is Doctors To You. We received your request but unfortunately we are fully booked and recommend you seek alternate medical care as soon as possible.",
}
