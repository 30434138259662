import React from "react";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from "@mui/material";
import { SortOrder } from "utility/enums/sort-order";
import {
	IPatientHistory,
	IPatientHistorySearchCriteria,
} from "utility/interfaces";
import {
	PatientHistoryTableRowData,
	PatientHistoryTableSearch,
	TablePagination,
} from "components";

type ComponentProps = {
	searchCriteria: IPatientHistorySearchCriteria;
	handleOnSearchCriteriaChange: any;
	rowData: IPatientHistory | null;
};

export function PatientHistoryTable(props: ComponentProps) {
	// Extract Props
	const { searchCriteria, handleOnSearchCriteriaChange, rowData } = props;

	// Handled events and functions
	const handleOnPageNumberChange = (value: number) => {
		handleOnSearchCriteriaChange({ ...searchCriteria, PageIndexId: value });
	};
	const handleOnChangeSortDirection = (sortBy: string) => {
		handleOnSearchCriteriaChange({
			...searchCriteria,
			SortBy: sortBy,
			SortOrder:
				searchCriteria.SortOrder === SortOrder.ascending
					? SortOrder.descending
					: SortOrder.ascending,
		});
	};
	return (
		<Box className="table-box">
			<PatientHistoryTableSearch
				handleOnSearchCriteriaChange={handleOnSearchCriteriaChange}
			/>
			{rowData && rowData?.totalRecords > 0 ? (
				<>
					<TableContainer sx={{ display: { xs: "none", laptop: "block" } }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell className="min-w-160">
										<TableSortLabel
											active={searchCriteria.SortBy === "firstName"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("firstName")}
										>
											First Name
										</TableSortLabel>
									</TableCell>
									<TableCell className="min-w-140">
										<TableSortLabel
											active={searchCriteria.SortBy === "lastName"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("lastName")}
										>
											Last Name
										</TableSortLabel>
									</TableCell>
									<TableCell className="min-w-150">
										<TableSortLabel
											active={searchCriteria.SortBy === "email"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("email")}
										>
											Email
										</TableSortLabel>
									</TableCell>
									<TableCell className="min-w-130">
										<TableSortLabel
											active={searchCriteria.SortBy === "mobile"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("mobile")}
										>
											Phone
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ maxWidth: 330 }} className="min-w-330">
										Address
									</TableCell>
									<TableCell align="center" sx={{ width: 142 }}>
										Actions
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{rowData?.requests?.map((row, index) => (
									<PatientHistoryTableRowData
										key={index}
										row={row}
										isSmallDevice={false}
										searchCriteria={searchCriteria}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Box
						sx={{ display: { xs: "block", laptop: "none" } }}
						className="tabledata-cards-group"
					>
						{rowData?.requests?.map((row, index) => (
							<PatientHistoryTableRowData
								key={index}
								row={row}
								isSmallDevice={true}
								searchCriteria={searchCriteria}
							/>
						))}
					</Box>
					<TablePagination
						currentPageNumber={searchCriteria.PageIndexId}
						handleOnPageNumberChange={handleOnPageNumberChange}
						totalRecords={rowData?.totalRecords || 0}
						rowCount={rowData?.requests?.length || 0}
					/>
				</>
			) : (
				<Typography variant="h5" sx={{ padding: 3 }}>
					No Record(s) Found{" "}
				</Typography>
			)}
		</Box>
	);
}
