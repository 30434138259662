import { useContext, useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import { Button, IconButton } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { isMobile } from "utility/helpers";
import { PromptEventDispatchContext } from "contexts/prompt-event-context";

export const InstallationBanner = () => {
	// Context variable
	const setPromptEventContext = useContext(PromptEventDispatchContext);

	// State varables
	const [isInstallBannerOpen, setIsInstallBannerOpen] = useState(false);
	const [promptEvent, setPromptEvent] = useState<Event | null>(null);

	// Handle events
	const handleBeforeInstallEvent = (event: Event) => {
		var isBannerShown = sessionStorage.getItem("IsBannerShown");
		if (!isBannerShown) {
			event.preventDefault();
			setPromptEvent(event);
			setPromptEventContext(event);
			setIsInstallBannerOpen(true);
			sessionStorage.setItem("IsBannerShown", "true");
		}
	};
	const handleBannerClose = () => {
		setIsInstallBannerOpen(false);
	};

	const handleInstall = async () => {
		if (promptEvent) {
			let deferredPrompt = promptEvent as any;
			deferredPrompt.prompt();

			// Find out whether the user confirmed the installation or not
			const { outcome } = await deferredPrompt.userChoice;
			// The deferredPrompt can only be used once.
			deferredPrompt = null;
			// Act on the user's choice
			if (outcome === "accepted") {
				console.log("User accepted the install prompt.");
			} else if (outcome === "dismissed") {
				console.log("User dismissed the install prompt");
			}
		}
		handleBannerClose();
	};

	// Custom action component
	const action = (
		<React.Fragment>
			<Button color="primary" size="large" onClick={handleInstall}>
				Install
			</Button>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleBannerClose}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	// Use effect
	useEffect(() => {
		if (isMobile()) {
			window.addEventListener("beforeinstallprompt", handleBeforeInstallEvent);
		}

		return () => {
			window.removeEventListener(
				"beforeinstallprompt",
				handleBeforeInstallEvent
			);
		};
	}, []);

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={isInstallBannerOpen}
				onClose={handleBannerClose}
				action={action}
				message="To have secure access to our provider portal, click here to install."
			/>
		</>
	);
};
