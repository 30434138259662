import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { SortOrder } from "utility/enums/sort-order";
import { Constants } from "utility/enums/constants";
import roleService from "services/role-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import {
	IProfessionInfo,
	IProfessions,
	IHttpsResponse,
	ISearchCriteria,
} from "utility/interfaces";
import { createCommonAPICall } from "utility/helpers";
import { ProfessionInfoTableRow, TablePagination } from "components";

type ComponentProps = {
	handleOnClickEdit: any;
};
export function ProfessionInfoTable(props: ComponentProps) {
	// Extract Props
	const { handleOnClickEdit } = props;

	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// useStates
	const [searchCriteria, setSearchCriteria] = useState<ISearchCriteria>({
		PageIndexId: 0,
		PageSize: Constants.DefaultTablePageSize.valueOf(),
		SortOrder: "asc",
		SortBy: "",
		SearchBy: "",
	});
	const [professionPageInfo, setProfessionPageInfo] =
		React.useState<IProfessions>({
			professions: [
				{
					professionName: "profession 1",
					status: "active",
					professionId: 1,
				},
				{
					professionName: "profession 2",
					status: "active",
					professionId: 2,
				},
				{
					professionName: "profession 3",
					status: "active",
					professionId: 3,
				},
				{
					professionName: "profession 4",
					status: "active",
					professionId: 4,
				},
			],
			totalRecords: 0,
		});

	// Handled events and functions
	const handleOnPageNumberChange = (value: number) => {
		setSearchCriteria({ ...searchCriteria, PageIndexId: value });
	};
	const handleOnChangeSortDirection = (sortBy: string) => {
		setSearchCriteria({
			...searchCriteria,
			SortBy: sortBy,
			SortOrder:
				searchCriteria.SortOrder === SortOrder.ascending
					? SortOrder.descending
					: SortOrder.ascending,
		});
	};

	const getProfessionGrids = async () => {
		const data = await createCommonAPICall({
			requestBody: searchCriteria,
			apiService: roleService.getGridRoles,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.data) console.log(data.data);
	};
	const handleOnClickDelete = async (row: IProfessionInfo) => {
		const data: IHttpsResponse<any> = await createCommonAPICall({
			requestBody: row.professionId || 0,
			apiService: roleService.deleteRole, // TODO here
			showSuccessMessage: true,
			showErrorMessage: true,
			setSuccessErrorContext,
			successMessage: "Record deleted successfully",
		});
		if (data && data.isSuccessfull) {
			const updatedProfessions = professionPageInfo.professions.filter(
				(profession) => profession.professionId !== row.professionId
			);
			setProfessionPageInfo({
				...professionPageInfo,
				professions: updatedProfessions,
			});
		}
	};

	// useEffects
	useEffect(() => {
		getProfessionGrids();
	}, [searchCriteria]);

	return (
		<>
			<TableContainer className="upload-table-container">
				<Table className="upload-table conclude-table">
					<TableHead>
						<TableRow>
							<TableCell
								sortDirection={searchCriteria.SortOrder}
								className="min-w-200"
							>
								<TableSortLabel
									active={searchCriteria.SortBy === "professionName"}
									direction={searchCriteria.SortOrder}
									onClick={() => handleOnChangeSortDirection("professionName")}
								>
									Profession Name
									<Box component="span" sx={visuallyHidden}>
										{searchCriteria.SortOrder === SortOrder.descending
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								</TableSortLabel>
							</TableCell>
							<TableCell
								sortDirection={searchCriteria.SortOrder}
								className="min-w-200"
							>
								<TableSortLabel
									active={searchCriteria.SortBy === "status"}
									direction={searchCriteria.SortOrder}
									onClick={() => handleOnChangeSortDirection("status")}
								>
									Status
									<Box component="span" sx={visuallyHidden}>
										{searchCriteria.SortOrder === SortOrder.descending
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								</TableSortLabel>
							</TableCell>
							<TableCell align="center" sx={{ width: 142 }}>
								Actions
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{professionPageInfo.professions.map((row, index) => {
							return (
								<TableRow hover tabIndex={-1} key={index}>
									<ProfessionInfoTableRow
										row={row}
										handleOnClickDelete={handleOnClickDelete}
										handleOnClickEdit={handleOnClickEdit}
									/>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<div className="small-table-pagination">
				<TablePagination
					currentPageNumber={searchCriteria.PageIndexId}
					handleOnPageNumberChange={handleOnPageNumberChange}
					totalRecords={professionPageInfo?.totalRecords || 0}
					rowCount={professionPageInfo?.professions?.length || 0}
				/>
			</div>
		</>
	);
}
