import { Modal, Box, Typography, Button } from "@mui/material";
import { WarningIcon } from "assests/images";
import { CheckBoxComponent } from "components";
import React, { useState } from "react";
import { successErrorModalStyle } from "utility/helpers";

type ComponentProps = {
	isModalOpen: boolean;
	handleOnClickCloseModal: any;
	handleOnClickUpdate: any;
};

export const UpdateLocationConfirmationModal = (props: ComponentProps) => {
	// Extract props
	const { handleOnClickCloseModal, isModalOpen, handleOnClickUpdate } = props;

	// State variables
	const [isAutomaticLocationUpdate, setIsAutomaticLocationUpdate] =
		useState(false);

	// Handle events
	const handleOnClose = () => {
		setIsAutomaticLocationUpdate(false);
		handleOnClickCloseModal();
	};

	return (
		<>
			<Modal
				open={isModalOpen}
				onClose={handleOnClickCloseModal}
				className="reset-modal"
			>
				<Box sx={successErrorModalStyle}>
					<img src={WarningIcon} alt="warning" className="reset-img" />
					<Typography variant="h2">Confirmation</Typography>
					<Typography variant="body1">
						Do you want to update your location?
					</Typography>
					<CheckBoxComponent
						label={"Remember my choice"}
						isChecked={isAutomaticLocationUpdate}
						handleOnChangeCheckbox={() =>
							setIsAutomaticLocationUpdate(!isAutomaticLocationUpdate)
						}
					/>
					<div className="warning-modal-btn-group">
						<Button
							variant="contained"
							onClick={() => {
								handleOnClickUpdate(isAutomaticLocationUpdate);
								handleOnClose();
							}}
						>
							Update
						</Button>
						<Button variant="outlined" onClick={handleOnClose}>
							Cancel
						</Button>
					</div>
				</Box>
			</Modal>
		</>
	);
};
