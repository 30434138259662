import { DocIcon, JpgIcon, PdfIcon } from "assests/images";
import { FileMimeTypes } from "utility/enums/file-mime-type";
import { toast } from "react-toastify";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { IMenuPermissions } from "utility/interfaces";

const loaderDiv =
	typeof window !== "undefined" && document.getElementById("loader");

export const showLoader = () => {
	if (loaderDiv) {
		loaderDiv.style.opacity = "1";
		loaderDiv.style.visibility = "visible";
	}
};

export const hideLoader = () => {
	if (loaderDiv) {
		loaderDiv.style.opacity = "0";
		loaderDiv.style.visibility = "hidden";
	}
};

export const addDays = (date: Date, noOfDays: number) => {
	var newDate = new Date(date);
	newDate.setDate(newDate.getDate() + noOfDays);
	return newDate;
};

export const commonModalStyle = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
	borderRadius: "10px",
};

export const successErrorModalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 556,
	borderRadius: "10px",
	bgcolor: "background.paper",
	padding: "76px 74px 70px",
	textAlign: "center",
	"@media (max-width:599px)": {
		width: "calc(100% - 30px)",
		padding: "30px",
	},
};

export const getFileIconByType = (type: string) => {
	switch (type) {
		case FileMimeTypes.image:
			return JpgIcon;
		case FileMimeTypes.pdf:
			return PdfIcon;
		default:
			return DocIcon;
	}
};

export const getArrayOfNumber = (start: number, end: number): number[] => {
	var rows = [],
		i = start - 1,
		len = end;
	while (++i <= len) rows.push(i);
	return rows;
};

export const copyTextOnClipBoard = (text: string) => {
	if (text) {
		navigator.clipboard.writeText(text);
		toast.success("Text Copied");
	} else {
		toast.error("There is nothing to copy.");
	}
};

export const openSucessErrorModal = (
	setSuccessErrorContext: any,
	title: string,
	message: string,
	isSuccess: boolean,
	handleOnClickOk: any = undefined
) => {
	setSuccessErrorContext({
		isSuccessErrorOpen: true,
		title,
		message,
		isSuccess,
		handleOnClickOk,
	});
};

export const exportToExcel = (
	dataObject: any,
	coumnWiseWidth: any,
	fileName: string
) => {
	const ws = XLSX.utils.json_to_sheet(dataObject);
	ws["!cols"] = coumnWiseWidth;
	const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
	const excelBuffer = XLSX.write(wb, {
		bookType: "xlsx",
		type: "array",
	});
	const Newdata = new Blob([excelBuffer], {
		type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
	});
	FileSaver.saveAs(Newdata, fileName);
};

export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
};

export const getComparator = <Key extends keyof any>(
	order: any,
	orderBy: Key
): ((a: { [key in Key]: string }, b: { [key in Key]: string }) => number) => {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = <T>(
	array: T[],
	comparator: (a: T, b: T) => number
) => {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
};

export const DateInputFormat = "MM/DD/YYYY";

export const menuPermissions: IMenuPermissions[] = [
	// Dashboard
	{
		Id: 1,
		ParentId: 0,
		Title: "Dashboard",
		URL: "/dashboard/new",
		MenuKey: "dashboard",
	},
	// Profile
	{
		Id: 2,
		ParentId: 0,
		Title: "Profile",
		URL: null,
		MenuKey: "profile",
	},

	// Provider
	{
		Id: 3,
		ParentId: 0,
		Title: "Provider",
		URL: null,
		MenuKey: "provider",
	},

	// Profession
	{
		Id: 4,
		ParentId: 0,
		Title: "Profession",
		URL: null,
		MenuKey: "profession",
	},

	// Halo
	{
		Id: 5,
		ParentId: 0,
		Title: "Halo",
		URL: null,
		MenuKey: "halo",
	},

	// History
	{
		Id: 6,
		ParentId: 0,
		Title: "History",
		URL: null,
		MenuKey: "history",
	},

	// Profile sub menus
	{
		Id: 7,
		ParentId: 2,
		Title: "Regions",
		URL: "/profile/regions/unassigned",
		MenuKey: "profile/regions",
	},
	{
		Id: 8,
		ParentId: 2,
		Title: "My Profile",
		URL: "/profile/my-profile",
		MenuKey: "profile/my-profile",
	},
	{
		Id: 9,
		ParentId: 2,
		Title: "Chats",
		URL: "/profile/chats",
		MenuKey: "profile/chats",
	},

	// Provider sub menus
	{
		Id: 10,
		ParentId: 3,
		Title: "Role",
		URL: "/provider/role",
		MenuKey: "provider/role",
	},
	{
		Id: 11,
		ParentId: 3,
		Title: "Provider",
		URL: "/provider/info",
		MenuKey: "provider/info",
	},
	{
		Id: 12,
		ParentId: 3,
		Title: "Scheduling",
		URL: "/provider/scheduling",
		MenuKey: "provider/scheduling",
	},
	{
		Id: 13,
		ParentId: 3,
		Title: "Accounts",
		URL: "/provider/accounts",
		MenuKey: "provider/accounts",
	},
	{
		Id: 14,
		ParentId: 3,
		Title: "Provider Location",
		URL: "/provider/location",
		MenuKey: "provider/location",
	},
	{
		Id: 15,
		ParentId: 3,
		Title: "Invoicing",
		URL: "/provider/invoicing",
		MenuKey: "provider/invoicing",
	},

	// Profession sub menus
	{
		Id: 16,
		ParentId: 4,
		Title: "Vendors",
		URL: "/profession/vendors",
		MenuKey: "profession/vendors",
	},
	{
		Id: 17,
		ParentId: 4,
		Title: "Profession",
		URL: "/profession/info",
		MenuKey: "profession/info",
	},

	// Halo sub menus
	{
		Id: 18,
		ParentId: 5,
		Title: "Halo HR",
		URL: "/halo/hr",
		MenuKey: "halo/hr",
	},
	{
		Id: 19,
		ParentId: 5,
		Title: "Halo Request",
		URL: "/halo/request",
		MenuKey: "halo/request",
	},
	{
		Id: 20,
		ParentId: 5,
		Title: "Halo Employee",
		URL: "/halo/employee",
		MenuKey: "halo/employee",
	},
	{
		Id: 21,
		ParentId: 5,
		Title: "Halo Workplace",
		URL: "/halo/workplace",
		MenuKey: "halo/workplace",
	},

	// History sub menus
	{
		Id: 22,
		ParentId: 6,
		Title: "History",
		URL: "/history/info",
		MenuKey: "history/info",
	},
	{
		Id: 23,
		ParentId: 6,
		Title: "Request Data",
		URL: "/history/request-data",
		MenuKey: "history/request-data",
	},
	{
		Id: 24,
		ParentId: 6,
		Title: "logs",
		URL: "/history/logs",
		MenuKey: "history/logs",
	},
	{
		Id: 25,
		ParentId: 6,
		Title: "Cancel History",
		URL: "/history/cancel",
		MenuKey: "history/cancel",
	},
	{
		Id: 26,
		ParentId: 6,
		Title: "Patient History",
		URL: "/history/patient",
		MenuKey: "history/patient",
	},
	{
		Id: 27,
		ParentId: 6,
		Title: "Block History",
		URL: "/history/block",
		MenuKey: "history/block",
	},
];

export const getUserLocation = (successCallback: any, errorCallback: any) => {
	navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
};

export const startTrackingProvider = (successCallback: any): number => {
	return navigator.geolocation.watchPosition(successCallback);
};

export const isMobile = () => {
	const regex =
		/Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
	return regex.test(navigator.userAgent);
};

export const isIOS = () => {
	const regex = /iPhone|iPad|iPod/i;
	return regex.test(navigator.userAgent);
};
