import * as yup from "yup";

export const DailyTimesheetSchema = yup.object().shape({
	shiftDuration: yup
		.number()
		.typeError("Total hours must be a number")
		.required("Please enter total hours.")
		.lessThan(24, "Value cannot exceed 24"),
	housecallDuration: yup
		.number()
		.typeError("housecallDuration must be a number")
		.lessThan(24, "Value cannot exceed 24"),
	phoneConsultDuration: yup
		.number()
		.typeError("phoneConsultDuration must be a number")
		.lessThan(24, "Value cannot exceed 24"),
});
