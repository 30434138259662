import React, { useEffect, useState, useContext } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { ArrowBack, CalendarDarkIcon, LocationIcon } from "assests/images";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ICreateRequest, IRegions } from "utility/interfaces";
import { DatePicker, DesktopDatePicker } from "@mui/x-date-pickers";
import { AppRoutings } from "utility/enums/app-routings";
import { getTabStatusWiseName } from "utility/helpers";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { createCommonAPICall } from "utility/helpers";
import dashboardService from "services/dashboard-service";
import Geocode from "react-geocode";
import { hideLoader, openSucessErrorModal } from "../../utility/helpers/common";
import { UserContext } from "contexts/user-context";
import { AccountType } from "utility/enums/request-status";
import { CustomIntlTelInput, PhoneTypeModal } from "components";
import {
	createRequestPhoneNumberSchema,
	createRequestSchema,
} from "utility/validations";
import { CountryData } from "react-intl-tel-input-18";
import { Constants } from "utility/enums/constants";

function datepickerIcon() {
	return <img src={CalendarDarkIcon} alt="calendar" />;
}

const CreateRequest: React.FC = () => {
	// get state value
	const { state } = window.history;
	Geocode.setApiKey(Constants.GeocodeKey);

	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const userRole = useContext(UserContext);

	// useStates
	const [formValues, setFormValues] = useState<ICreateRequest>({
		firstName: "",
		lastName: "",
		phoneNumber: "",
		email: "",
		dateOfBirth: undefined,
		street: "",
		city: "",
		state: "",
		zipCode: "",
		room: "",
		physicianNote: "",
		adminNote: "",
		clientRegionId: 0,
		radioRequestorPhoneType: "M",
	});

	const [txtstate, setTxtState] = useState<string[]>([]);
	const physicianregion = [];
	const [stateResponse, setStateResponse] = useState<IRegions[]>([]);
	const [phoneTypeModelOpen, setphoneTypeModelOpen] = useState(false);
	const [phoneType, setphoneType] = useState("");
	const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

	// Page level variables
	const navigate = useNavigate();

	const getRegions = async () => {
		const data = await createCommonAPICall({
			apiService: dashboardService.getRegions,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		console.log(data.data);
		if (data && data.data) {
			for (let i = 0; i < data.data.length; i++) {
				txtstate.push(data.data[i].name);
			}
			setStateResponse(data.data);
			// if ('@DTY.Helper.Helper.SessionUtility.LoggedInPhysicianId' == '0')
			//     physicianregion.push(e.Text);
		}
	};

	useEffect(() => {
		getRegions();
		window.scrollTo(0, 0);
	}, []);

	const onRadioChange = (event: string) => {
		setphoneType(event);
		setFormValues({ ...formValues, radioRequestorPhoneType: event });
		phoneTypeModelClose();
	};

	// Handled events and functions
	const handleOnclickSave = async () => {
		var address =
			formValues.street +
			" " +
			formValues.city +
			", " +
			formValues.state +
			" " +
			formValues.zipCode;

		Geocode.fromAddress(address).then(
			async (response) => {
				const { lat, lng } = response.results[0].geometry.location;
				console.log(lat, lng);
				var isValid = await CheckValidRegion(response.results[0]);

				let strDateOfBirth = formValues.dateOfBirth
					? new Date(formValues.dateOfBirth).toLocaleString("en-us")
					: "";
				let isDateValid = true;
				if (strDateOfBirth == "Invalid Date") {
					isDateValid = false;
				}

				if (isValid && isDateValid) {
					// console.log("Click", formValues);

					const request: ICreateRequest = {
						firstName: formValues.firstName,
						lastName: formValues.lastName,
						phoneNumber: formValues.phoneNumber,
						email: formValues.email,
						dateOfBirth: formValues.dateOfBirth,
						strDateOfBirth: formValues.dateOfBirth
							? new Date(formValues.dateOfBirth).toLocaleString("en-us")
							: "",
						street: formValues.street,
						city: formValues.city,
						state: formValues.state,
						zipCode: formValues.zipCode,
						room: formValues.room,
						physicianNote: formValues.physicianNote,
						adminNote: formValues.adminNote,
						clientRegionId: formValues.clientRegionId,
						radioRequestorPhoneType: formValues.radioRequestorPhoneType,
					};
					console.log(request);
					const data = createCommonAPICall({
						requestBody: request,
						apiService: dashboardService.createRequest,
						showSuccessMessage: true,
						showErrorMessage: true,
						setSuccessErrorContext,
					}).then(() => {
						navigate(
							AppRoutings.Dashboard.replace(
								":tabStatus",
								getTabStatusWiseName(
									state?.usr?.searchCriteria?.RequestStatusId
								).toLocaleLowerCase()
							),
							{
								state: {
									searchCriteria: state?.usr?.searchCriteria,
								},
							}
						);
					});
				} else if (!isDateValid) {
					hideLoader();
					openSucessErrorModal(
						setSuccessErrorContext,
						"Error",
						"Please enter valid Date of Birth.",
						false
					);
				} else {
					hideLoader();
					openSucessErrorModal(
						setSuccessErrorContext,
						"Error",
						"At this time, we are only able to service select areas in DC, Maryland, Virginia, and New York City.",
						false
					);
				}
			},
			(error) => {
				console.error(error);
			}
		);
	};
	const handleOnChangeFormValue = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		value: any
	) => {
		setFormValues({ ...formValues, [e.target.name]: value });
	};

	const handleOnChangePhoneNumber = (e: any, value: any) => {
		setFormValues({ ...formValues, [e.target.name]: value });
		// setphoneTypeModelOpen(true);
	};
	const validatePhoneNumber = async () => {
		const data = getValues();
		console.log(data);
		// try catch block for yup validate function. abortEarly: false so it returns all errors on our form.
		try {
			await createRequestPhoneNumberSchema.validate(data, {
				abortEarly: false,
			});
			// if passes, data is valid
			alert("draft schema valid!");
			return true;
		} catch (error: any) {
			// loop over all errors and setError to use them in react-hook-form
			error.inner?.map((inner: any, index: any) => {
				const { type, path, errors } = inner;
				return setError(path, { type, message: errors[index] });
			});
			return false;
		}
	};

	const handleOnBlurPhoneNumber = async () => {
		if (formValues.phoneNumber !== "") setphoneTypeModelOpen(true);
	};

	const handleOnChangeDateOfBirth = (value: any) => {
		setFormValues({ ...formValues, dateOfBirth: value });
	};
	const handleOnClickBackCancel = () =>
		navigate(
			AppRoutings.Dashboard.replace(
				":tabStatus",
				getTabStatusWiseName(
					state?.usr?.searchCriteria?.RequestStatusId
				).toLocaleLowerCase()
			),
			{
				state: {
					searchCriteria: state?.usr?.searchCriteria,
				},
			}
		);

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setError,
	} = useForm({
		values: formValues,
		resolver: yupResolver(createRequestSchema),
	});

	const phoneTypeModelClose = () => {
		setphoneTypeModelOpen(false);
	};

	const CheckValidRegion = async (places: any) => {
		// get state from address
		var isValidState = false;
		var stateName;
		for (var component in places["address_components"]) {
			for (var i in places["address_components"][component]["types"]) {
				if (
					places["address_components"][component]["types"][i] ==
					"administrative_area_level_1"
				) {
					stateName = places["address_components"][component]["long_name"];
					for (var j = 0; j < txtstate.length; j++) {
						if (stateName == txtstate[j]) {
							isValidState = true;
							for (let s = 0; s < stateResponse.length; s++) {
								if (stateResponse[s].name == stateName) {
									formValues.clientRegionId = stateResponse[s].regionId;
								}
							}
						}
					}
				}
			}
		}
		if (isValidState && isValidState == true) {
			const zipCodesRes = await createCommonAPICall({
				apiService: dashboardService.getAllZipCodes,
				showErrorMessage: true,
				showSuccessMessage: false,
				setSuccessErrorContext,
			});

			if (zipCodesRes && zipCodesRes.isSuccessfull) {
				console.log(zipCodesRes);
				if (
					!zipCodesRes.data.some(
						(zip: any) => zip.zipCode == formValues.zipCode
					)
				) {
					return false;
				} else {
					return true;
				}
			}
			return false;
		} else {
			console.log(
				"At this time, we" +
					"'" +
					"re only able to service select areas in DC, Maryland, Virginia, and New York City."
			);
		}
	};

	const handleGetLoaction = () => {
		var address =
			formValues.street +
			" " +
			formValues.city +
			", " +
			formValues.state +
			" " +
			formValues.zipCode;
		var url = "https://www.google.com/maps/search/?api=1&query=" + address + "";
		// var url = "http://www.google.com/maps?q=" + address + "";
		window.open(url, "_blank");
	};

	const handleVerifyAddress = () => {
		var address =
			formValues.street +
			" " +
			formValues.city +
			", " +
			formValues.state +
			" " +
			formValues.zipCode;
		Geocode.fromAddress(address).then(
			async (response) => {
				const { lat, lng } = response.results[0].geometry.location;
				console.log(lat, lng);
				var isValid = await CheckValidRegion(response.results[0]);
				if (!isValid) {
					hideLoader();
					openSucessErrorModal(
						setSuccessErrorContext,
						"Error",
						"At this time, we are only able to service select areas in DC, Maryland, Virginia, and New York City.",
						false
					);
				}
			},
			(error) => {
				console.error(error);
			}
		);
	};

	const handlePhoneNumberChange = (
		isValid: boolean,
		phoneNumber: string,
		countryData: CountryData
	) => {
		setIsPhoneNumberValid(isValid);
		let formattedPhoneNumber =
			phoneNumber != ""
				? phoneNumber.includes("+")
					? phoneNumber
					: `+${countryData.dialCode} ${phoneNumber}`
				: "";
		setFormValues({ ...formValues, phoneNumber: formattedPhoneNumber });
		// onChangeDetails("conciergePhoneNumber", formattedPhoneNumber);
	};

	return (
		<>
			<Box>
				<main className="main-content">
					<div
						className="overlay"
						onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
					></div>
					<Box className="request-container-box">
						<div className="request-header">
							<Typography variant="h2">Submit Information</Typography>
							<Button variant="outlined" onClick={handleOnClickBackCancel}>
								<img src={ArrowBack} alt="arrow" />
								Back
							</Button>
						</div>
						<Box className="request-box">
							<Typography variant="h4">Patient</Typography>
							<div>
								<Grid container columnSpacing={{ sm: 2, lg: 3 }}>
									<Grid item xs={12} sm={6}>
										<TextField
											error={errors?.firstName?.message ? true : false}
											{...register("firstName")}
											fullWidth
											id="first-name"
											label="First Name"
											variant="outlined"
											name="firstName"
											value={formValues.firstName}
											helperText={errors?.firstName?.message?.toString()}
											onChange={(e) =>
												handleOnChangeFormValue(e, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											error={errors?.lastName?.message ? true : false}
											{...register("lastName")}
											fullWidth
											id="last-name"
											label="Last Name"
											variant="outlined"
											name="lastName"
											value={formValues.lastName}
											helperText={errors?.lastName?.message?.toString()}
											onChange={(e) =>
												handleOnChangeFormValue(e, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<CustomIntlTelInput
											isPhoneNumberValid={isPhoneNumberValid}
											onChange={handlePhoneNumberChange}
											onBlur={handleOnBlurPhoneNumber}
											value={formValues.phoneNumber}
											name="phoneNumber"
										/>
										{/* <TextField
											error={errors?.phoneNumber?.message ? true : false}
											fullWidth
											id="ph-num"
											{...register("phoneNumber")}
											label="Phone Number"
											variant="outlined"
											name="phoneNumber"
											value={formValues.phoneNumber}
											helperText={errors?.phoneNumber?.message?.toString()}
											onChange={(e) =>
												handleOnChangePhoneNumber(e, e.target.value)
											}
											onBlurCapture={handleOnBlurPhoneNumber}
										/> */}
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											{...register("email")}
											fullWidth
											id="email"
											label="Email"
											variant="outlined"
											name="email"
											value={formValues.email}
											helperText={errors?.email?.message?.toString()}
											error={errors?.email?.message ? true : false}
											onChange={(e) =>
												handleOnChangeFormValue(e, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DatePicker
												label={
													<React.Fragment>
														Date of Birth&nbsp;<span>&#40;Optional&#41;</span>
													</React.Fragment>
												}
												value={formValues?.dateOfBirth || null}
												onChange={(newValue) => {
													handleOnChangeDateOfBirth(newValue);
												}}
												components={{
													OpenPickerIcon: datepickerIcon,
												}}
												renderInput={(params) => (
													<TextField
														error={errors?.dateOfBirth?.message ? true : false}
														{...params}
														fullWidth
														id="dob"
														className="with-icon"
														autoComplete="off"
														inputProps={{
															...params.inputProps,
														}}
														helperText={errors?.dateOfBirth?.message?.toString()}
													/>
												)}
											/>
											{/* <DesktopDatePicker
												label={
													<React.Fragment>
														Date of Birth&nbsp;<span>&#40;Optional&#41;</span>
													</React.Fragment>
												}
												value={formValues?.dateOfBirth || null}
												onChange={(newValue) => {
													handleOnChangeDateOfBirth(newValue);
												}}
												components={{
													OpenPickerIcon: datepickerIcon,
												}}
												renderInput={(params) => (
													<TextField
														error={false}
														{...params}
														fullWidth
														id="dob"
														className="with-icon"
														autoComplete="off"
														inputProps={{
															...params.inputProps,
														}}
													/>
												)}
											/> */}
										</LocalizationProvider>
									</Grid>
								</Grid>
							</div>
							<div>
								<Typography variant="h4">Location</Typography>
								<Grid container columnSpacing={{ sm: 2, lg: 3 }}>
									<Grid item xs={12} sm={6}>
										<TextField
											{...register("street")}
											fullWidth
											id="street"
											label="Street"
											variant="outlined"
											name="street"
											value={formValues.street}
											helperText={errors?.street?.message?.toString()}
											error={errors?.street?.message ? true : false}
											onChange={(e) =>
												handleOnChangeFormValue(e, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											{...register("city")}
											fullWidth
											id="city"
											label="City"
											variant="outlined"
											helperText={errors?.city?.message?.toString()}
											error={errors?.city?.message ? true : false}
											name="city"
											value={formValues.city}
											onChange={(e) =>
												handleOnChangeFormValue(e, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											fullWidth
											{...register("state")}
											id="state"
											label="State"
											variant="outlined"
											name="state"
											value={formValues.state}
											helperText={errors?.state?.message?.toString()}
											error={errors?.state?.message ? true : false}
											onChange={(e) =>
												handleOnChangeFormValue(e, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											fullWidth
											id="zipcode"
											label={
												<React.Fragment>
													Zip Code&nbsp;<span>&#40;Optional&#41;</span>
												</React.Fragment>
											}
											value={formValues.zipCode}
											variant="outlined"
											name="zipCode"
											onChange={(e) =>
												handleOnChangeFormValue(e, e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											fullWidth
											id="first-name"
											label={
												<React.Fragment>
													Room #&nbsp;<span>&#40;Optional&#41;</span>
												</React.Fragment>
											}
											name="room"
											value={formValues.room}
											onChange={(e) =>
												handleOnChangeFormValue(e, e.target.value)
											}
											variant="outlined"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<div className="verify-btn-group">
											<Button variant="outlined" onClick={handleVerifyAddress}>
												Verify
											</Button>
											<Button variant="outlined" onClick={handleGetLoaction}>
												<img src={LocationIcon} alt="location" />
												Map
											</Button>
										</div>
									</Grid>
								</Grid>
							</div>
							<div>
								<Typography variant="h4">Notes</Typography>
								{userRole.userRoleId == AccountType.Physician && (
									<TextField
										inputProps={{
											style: { minHeight: "80px" },
										}}
										id="physican-notes"
										label={
											<React.Fragment>
												Physician Notes&nbsp;<span>&#40;Optional&#41;</span>
											</React.Fragment>
										}
										multiline
										rows={2}
										fullWidth
										value={formValues.physicianNote}
										name="physicianNote"
										onChange={(e) => handleOnChangeFormValue(e, e.target.value)}
									/>
								)}
								{userRole.userRoleId == AccountType.Admin && (
									<TextField
										inputProps={{
											style: { minHeight: "80px" },
										}}
										id="physican-notes"
										label={
											<React.Fragment>
												Admin Notes&nbsp;<span>&#40;Optional&#41;</span>
											</React.Fragment>
										}
										multiline
										rows={2}
										value={formValues.adminNote}
										fullWidth
										name="adminNote"
										onChange={(e) => handleOnChangeFormValue(e, e.target.value)}
									/>
								)}
							</div>
							<div className="request-btn-group">
								<Button
									onClick={() => {
										if (!isPhoneNumberValid || formValues.phoneNumber == "") {
											setIsPhoneNumberValid(false);
											handleSubmit(() => {})();
										} else {
											handleSubmit(handleOnclickSave)();
										}
										console.log(errors);
									}}
									variant="contained"
								>
									Save
								</Button>
								<Button variant="outlined" onClick={handleOnClickBackCancel}>
									Cancel
								</Button>
							</div>
						</Box>
					</Box>
				</main>
			</Box>
			<PhoneTypeModal
				phoneTypeModelOpen={phoneTypeModelOpen}
				handlePhoneTypeModelClose={phoneTypeModelClose}
				onSelectPhoneType={onRadioChange}
			/>
		</>
	);
};

export default CreateRequest;
