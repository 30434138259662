import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import React from "react";
import { SortOrder } from "utility/enums/sort-order";
import {
	ITimeSheetDetailsSearchCriteria,
	ITimesheetDetailsInfo,
} from "utility/interfaces";
import { TimeSheetDetailsTableRow } from "./timesheet-details-table-row";

type ComponentProps = {
	dailyTimesheetInfo: ITimesheetDetailsInfo;
};

export const TimeSheetDetailsTable = (props: ComponentProps) => {
	// Extract props
	const { dailyTimesheetInfo } = props;

	return (
		<>
			{dailyTimesheetInfo.totalRecords > 0 ? (
				<>
					<TableContainer
						className="upload-table-container"
						sx={{ display: { xs: "none", md: "block" } }}
					>
						<Table className="upload-table">
							<TableHead>
								<TableRow>
									<TableCell className="min-w-120">Shift Date</TableCell>
									<TableCell className="min-w-100">Shift</TableCell>
									<TableCell className="min-w-100">
										Nightshift Weekend
									</TableCell>
									<TableCell className="min-w-100">Housecall</TableCell>
									<TableCell className="min-w-100">
										Housecall Nights Weekend
									</TableCell>
									<TableCell className="min-w-100">Phone Consults</TableCell>
									<TableCell className="min-w-100">
										Phone Consults Nights Weekend
									</TableCell>
									<TableCell className="min-w-100">Batch Testing</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{dailyTimesheetInfo.timeSheet.map((row, index) => (
									<TimeSheetDetailsTableRow
										key={index}
										row={row}
										isSmallDevice={false}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Box
						sx={{ display: { xs: "block", md: "none" } }}
						className="tabledata-cards-group"
					>
						{dailyTimesheetInfo.timeSheet.map((row, index) => (
							<TimeSheetDetailsTableRow
								key={index}
								row={row}
								isSmallDevice={true}
							/>
						))}
					</Box>
				</>
			) : (
				<Typography variant="h5" className="no-record" sx={{ padding: 3 }}>
					No Record(s) Found
				</Typography>
			)}
		</>
	);
};
