import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from "@mui/material";
import React from "react";
import {
	IDropDownList,
	ISMSLogsInfo,
	ISMSLogsSearchCriteria,
} from "utility/interfaces";
import { SMSLogsTableSearch } from "./table-search";
import { SortOrder } from "utility/enums/sort-order";
import { SMSLogsTableRow } from "./table-row";
import { TablePagination } from "components";

type ComponentProps = {
	searchCriteria: ISMSLogsSearchCriteria;
	handleOnSearchCriteriaChange: any;
	smsLogsInfo: ISMSLogsInfo;
	roles: IDropDownList[];
};

export const SMSLogsTable = (props: ComponentProps) => {
	// Extract Props
	const { searchCriteria, handleOnSearchCriteriaChange, smsLogsInfo, roles } =
		props;

	//handle events
	const handleOnChangeSortDirection = (sortBy: string) => {
		handleOnSearchCriteriaChange({
			...searchCriteria,
			SortBy: sortBy,
			SortOrder:
				searchCriteria.SortOrder === SortOrder.ascending
					? SortOrder.descending
					: SortOrder.ascending,
		});
	};

	const handleOnPageNumberChange = (value: number) => {
		handleOnSearchCriteriaChange({ ...searchCriteria, PageIndexId: value });
	};

	return (
		<Box className="table-box">
			<SMSLogsTableSearch
				searchCriteria={searchCriteria}
				handleOnSearchCriteriaChange={handleOnSearchCriteriaChange}
				roles={roles}
			/>
			{smsLogsInfo.totalRecords > 0 ? (
				<>
					<TableContainer sx={{ display: { xs: "none", laptop: "block" } }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										<TableSortLabel
											active={searchCriteria.SortBy === "FirstName"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("FirstName")}
										>
											Recipient
										</TableSortLabel>
									</TableCell>
									<TableCell>
										<TableSortLabel
											active={searchCriteria.SortBy === "ActionName"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("ActionName")}
										>
											Action
										</TableSortLabel>
									</TableCell>
									<TableCell>
										<TableSortLabel
											active={searchCriteria.SortBy === "RoleName"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("RoleName")}
										>
											Role Name
										</TableSortLabel>
									</TableCell>
									<TableCell>
										<TableSortLabel
											active={searchCriteria.SortBy === "MobileNumber"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() =>
												handleOnChangeSortDirection("MobileNumber")
											}
										>
											Mobile Number
										</TableSortLabel>
									</TableCell>
									<TableCell>
										<TableSortLabel
											active={searchCriteria.SortBy === "CreateDate"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("CreateDate")}
										>
											Create Date
										</TableSortLabel>
									</TableCell>
									<TableCell>
										<TableSortLabel
											active={searchCriteria.SortBy === "SentDate"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("SentDate")}
										>
											Sent Date
										</TableSortLabel>
									</TableCell>
									<TableCell>
										<TableSortLabel
											active={searchCriteria.SortBy === "IsSMSSent"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("IsSMSSent")}
										>
											Sent
										</TableSortLabel>
									</TableCell>
									<TableCell>
										<TableSortLabel
											active={searchCriteria.SortBy === "SentTries"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("SentTries")}
										>
											Sent Tries
										</TableSortLabel>
									</TableCell>
									<TableCell>
										<TableSortLabel
											active={searchCriteria.SortBy === "ConfirmationNumber"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() =>
												handleOnChangeSortDirection("ConfirmationNumber")
											}
										>
											Confirmation Number
										</TableSortLabel>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{smsLogsInfo.smsLogs.map((row) => (
									<SMSLogsTableRow
										key={row.smsLogID}
										row={row}
										isSmallDevice={false}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Box
						sx={{ display: { xs: "block", laptop: "none" } }}
						className="tabledata-cards-group"
					>
						{smsLogsInfo.smsLogs.map((row) => (
							<SMSLogsTableRow
								key={row.smsLogID}
								row={row}
								isSmallDevice={true}
							/>
						))}
					</Box>
					<TablePagination
						currentPageNumber={searchCriteria.PageIndexId}
						handleOnPageNumberChange={handleOnPageNumberChange}
						totalRecords={smsLogsInfo.totalRecords || 0}
						rowCount={smsLogsInfo?.smsLogs?.length || 0}
					/>
				</>
			) : (
				<Typography variant="h5" sx={{ padding: 3 }}>
					No Record(s) Found{" "}
				</Typography>
			)}
		</Box>
	);
};
