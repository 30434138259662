import {
	Modal,
	Box,
	Typography,
	IconButton,
	TextField,
	Tooltip,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from "@mui/material";
import myCustomTheme from "app.theme";
import { CloseWhiteIcon } from "assests/images";
import { UserContext } from "contexts/user-context";
import React, { useContext, useEffect, useState } from "react";
import profileService from "services/profile-service";
import { AccountType } from "utility/enums/request-status";
import { commonModalStyle, createCommonAPICall } from "utility/helpers";
import { IDropDownList } from "utility/interfaces";

type ComponentProps = {
	addChatModalOpen: boolean;
	handleAddChatModalClose: any;
	handleClickOk: any;
};

type PhysicianSelectProps = {
	physicianList: IDropDownList[];
	handlePhysicianSelect: any;
	selectedPhy: number;
};

const PhysicianSelect = (props: PhysicianSelectProps) => {
	return (
		<>
			<FormControl fullWidth className="select-input">
				<InputLabel id="hours-label">Select Physician</InputLabel>
				<Select
					labelId="hours-label"
					id="hours-name"
					value={props.selectedPhy}
					name="hours"
					onChange={(e) => props.handlePhysicianSelect(e.target.value)}
					MenuProps={{
						className: "select-input-modal custom-select-dropdown",
					}}
				>
					{props.physicianList?.map((physician) => {
						return (
							<MenuItem key={physician.value} value={physician.value}>
								{physician.text}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
		</>
	);
};

export const AddChatModal = (props: ComponentProps) => {
	//Use Context
	const contextUser = useContext(UserContext);

	//Extract Props
	const { addChatModalOpen, handleAddChatModalClose, handleClickOk } = props;

	// Use State
	const [physicianList, setPhysicianList] = useState<IDropDownList[]>([]);
	const [selectedPhy, setSelectedPhy] = useState(0);

	// Handle events and functions
	const handlePhysicianSelect = (value: number) => {
		setSelectedPhy(value);
	};

	const handleBeforeClose = () => {
		setSelectedPhy(physicianList[0]?.value || 0);
		handleAddChatModalClose();
	};

	// Use effect
	useEffect(() => {
		if (addChatModalOpen && contextUser.userRoleId === AccountType.Admin) {
			createCommonAPICall({
				apiService: profileService.getPhysicianDropDownList,
			}).then((data) => {
				console.log(data);
				if (data.isSuccessfull) {
					setSelectedPhy(data.data[0].value);
					setPhysicianList(data.data);
				}
			});
		}
	}, [addChatModalOpen]);

	return (
		<>
			<Modal
				open={addChatModalOpen}
				onClose={handleBeforeClose}
				className="send-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">
							{contextUser.userRoleId === AccountType.Admin
								? "Chat with Physician"
								: "Chat with Admin"}
						</Typography>
						<IconButton onClick={handleBeforeClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						{contextUser.userRoleId === AccountType.Admin ? (
							<PhysicianSelect
								physicianList={physicianList}
								handlePhysicianSelect={handlePhysicianSelect}
								selectedPhy={selectedPhy}
							/>
						) : (
							<Typography variant="subtitle1">
								Do you want to chat with Admin?
							</Typography>
						)}
						<div className="modal-footer">
							<Tooltip title="Confirm">
								<Button
									variant="contained"
									onClick={() => handleClickOk(selectedPhy)}
								>
									Chat
								</Button>
							</Tooltip>
							<Tooltip title="Cancel">
								<Button variant="outlined" onClick={handleBeforeClose}>
									Cancel
								</Button>
							</Tooltip>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
};
