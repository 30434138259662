import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import moment from "moment";
import React from "react";
import { commonModalStyle } from "utility/helpers";

type ComponentProps = {
	isOpen: boolean;
	handleClose: any;
	date: string;
	shifts: any;
	handleShiftClick: any;
	isRequestedShiftPage: boolean;
};

export const ViewAllShiftsModal = (props: ComponentProps) => {
	// Extract props
	const {
		isOpen,
		handleClose,
		date,
		shifts,
		handleShiftClick,
		isRequestedShiftPage,
	} = props;

	return (
		<>
			<Modal open={isOpen} onClose={handleClose} className="send-modal">
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">All Shifts</Typography>
						<IconButton onClick={handleClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						{isRequestedShiftPage ? (
							<div className="scrollable-modal">
								<table className="table table-bordered">
									<tbody>
										{shifts.map((s: any, index: number) => {
											return (
												<tr key={index}>
													<td className="monthShifts disableShifts">
														{s.startFull}-{s.endFull} on{" "}
														{moment(new Date(s.day)).format("MM/DD/YYYY")}
														<br />
														at {s.region}
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						) : (
							<div className="scrollable-modal">
								<table className="table table-bordered">
									<tbody>
										<tr style={{ background: "#fff" }}>
											<th
												style={{
													textAlign: "center",
													fontWeight: 500,
													padding: "8px",
												}}
											>
												{date}
											</th>
										</tr>
										{shifts.map((s: any, index: number) => {
											return (
												<tr key={index}>
													<td
														className={`monthShifts ${
															s.status == 1 ? "redShifts" : "greenShifts"
														} ${
															new Date().getTime() > new Date(date).getTime()
																? "disableShifts"
																: ""
														}`}
														onClick={() => {
															handleShiftClick(s);
															handleClose();
														}}
													>
														{s.startFull}-{s.endFull}
														<br />
														{s.name} at {s.region}
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						)}

						<div className="modal-footer">
							<Button variant="outlined" onClick={handleClose}>
								Close
							</Button>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
};
