import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import React from "react";
import { commonModalStyle } from "utility/helpers";

type ComponentProps = {
	isOpen: boolean;
	handleClose: any;
	notes: string;
	seperator: string;
	title: string;
};

export const ViewMoreModal = (props: ComponentProps) => {
	// Extract props
	const { isOpen, handleClose, notes, seperator, title } = props;
	return (
		<>
			<Modal open={isOpen} onClose={handleClose} className="send-modal">
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">{title}</Typography>
						<IconButton onClick={handleClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<div>
							{notes.split(seperator).map((note) => (
								<Typography variant="subtitle1">{note}</Typography>
							))}
						</div>
						<div className="modal-footer">
							<Button variant="outlined" onClick={handleClose}>
								Close
							</Button>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
};
