import { RegionInformationTabStatus } from "utility/enums/region-tab-status";

export const getRegionTabStatusWiseName = (status?: number) => {
	switch (status) {
		case RegionInformationTabStatus.FollowUp:
			return "Followup";
		case RegionInformationTabStatus.Closed:
			return "Close";
		case RegionInformationTabStatus.Unassigned:
			return "Unassigned";
		case RegionInformationTabStatus.Working:
			return "Working";
		default:
			return "";
	}
};

export const getRegionTabStatusIdByName = (name: string) => {
	switch (name.toLowerCase().toString()) {
		case "followup":
			return RegionInformationTabStatus.FollowUp;
		case "close":
			return RegionInformationTabStatus.Closed;
		case "unassigned":
			return RegionInformationTabStatus.Unassigned;
		case "working":
			return RegionInformationTabStatus.Working;
		default:
			return RegionInformationTabStatus.Unassigned;
	}
};
