import {
	Box,
	Button,
	FormControl,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import { ArrowBack } from "assests/images";
import { ProviderGrid } from "components";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { UserContext } from "contexts/user-context";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import roleService from "services/role-service";
import { AppRoutings } from "utility/enums/app-routings";
import { createCommonAPICall } from "utility/helpers";
import { IDropDownList, IProviderGridInfo } from "utility/interfaces";

const ProviderOnCall: React.FC = () => {
	// use context
	const contextUser = useContext(UserContext);
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// Page level variables
	const navigate = useNavigate();

	// use States
	const [regions, setRegions] = useState<IDropDownList[]>([]);
	const [selectedRegion, setSelectedRegion] = useState(0);
	const [onDutyPhysician, setOnDutyPhysician] = useState<IProviderGridInfo[]>(
		[]
	);
	const [offDutyPhysician, setOffDutyPhysician] = useState<IProviderGridInfo[]>(
		[]
	);

	// Handle events
	const handleOnClickBackCancel = () => navigate(-1);

	const getMDsONCall = async () => {
		const requestBody = {
			regionId: selectedRegion,
		};

		const data = await createCommonAPICall({
			requestBody,
			apiService: roleService.getMDOnCall,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			setRegions(data.data.regions);
			setOnDutyPhysician(data.data.mDsOnCall);
			setOffDutyPhysician(data.data.mDsOffDuty);
			console.log(data);
		}
	};

	// Use effects
	useEffect(() => {
		getMDsONCall();
	}, [selectedRegion]);

	return (
		<Box>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="request-container-box">
					<div className="request-header">
						<Typography variant="h2">MDs On Call</Typography>
						<Button variant="outlined" onClick={handleOnClickBackCancel}>
							<img src={ArrowBack} alt="arrow" />
							Back
						</Button>
					</div>
					<div className="request-header  responsive-header">
						<div className="schedule-regions" style={{ marginBottom: "10px" }}>
							<FormControl className="region-search">
								<Select
									labelId="region-label"
									id="region"
									value={selectedRegion}
									name="region"
									label="Search by region"
									onChange={(event) => {
										if (typeof event.target.value == "number")
											setSelectedRegion(event.target.value);
										else setSelectedRegion(parseInt(event.target.value));
									}}
								>
									{regions.map((region) => {
										return (
											<MenuItem key={region.value} value={region.value}>
												{region.text}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</div>
						<div
							className="small-center request-btn-group"
							style={{ paddingTop: 0, marginBottom: "10px" }}
						>
							<Button
								variant="contained"
								onClick={() => {
									navigate(AppRoutings.Scheduling);
								}}
							>
								Calender View
							</Button>
							<Button
								variant="contained"
								sx={{ marginLeft: "15px" }}
								onClick={() => {
									navigate(AppRoutings.ReviewShifts);
								}}
							>
								Shifts for review
							</Button>
						</div>
					</div>
					<Box className="request-box">
						<Typography variant="h4">MD's On Call</Typography>
						<ProviderGrid physicianList={onDutyPhysician} />
						<Typography variant="h4">Physicians Off Duty</Typography>
						<ProviderGrid physicianList={offDutyPhysician} />
					</Box>
				</Box>
			</main>
		</Box>
	);
};

export default ProviderOnCall;
