import React, { useState } from "react";
import {
	Button,
	TableCell,
	TableRow,
	ButtonProps,
	Card,
	CardContent,
	Typography,
	Collapse,
	CardHeader,
} from "@mui/material";
import { CalendarIcon } from "assests/images";
import { styled } from "@mui/material/styles";
import {
	IHistoryData,
	IPatientHistorySearchCriteria,
} from "utility/interfaces";
import { useNavigate } from "react-router-dom";
import { AppRoutings } from "utility/enums/app-routings";

type ComponentProps = {
	row: IHistoryData;
	isSmallDevice: boolean;
	searchCriteria: IPatientHistorySearchCriteria;
};

interface ExpandMoreProps extends ButtonProps {
	expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
	const { expand, ...other } = props;
	return (
		<Button {...other} disableFocusRipple disableElevation disableRipple />
	);
})(({ theme, expand }) => ({
	marginLeft: "auto",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));

export function PatientHistoryTableRowData(props: ComponentProps) {
	// useStates
	const [expanded, setExpanded] = useState(false);

	// Extract Props
	const { row, isSmallDevice, searchCriteria } = props;

	// page level variables
	const navigate = useNavigate();

	// Handled events and functions
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const handleOnClickExplore = () => {
		console.log(row.userIdQueryString);
		navigate(
			AppRoutings.PatientRecord.replace(":patientId", row.userIdQueryString)
		);
	};

	return (
		<>
			{!isSmallDevice ? (
				<>
					<TableRow className="bg-white">
						<TableCell component="th" scope="row" className="wrap min-w-160">
							{row.firstName}
						</TableCell>
						<TableCell className="wrap min-w-140">{row.lastName}</TableCell>
						<TableCell className="wrap min-w-150">{row.email}</TableCell>
						<TableCell className="wrap min-w-130">{row.mobile}</TableCell>
						<TableCell style={{ maxWidth: 330 }} className="wrap min-w-330">
							{row.address}
						</TableCell>
						<TableCell align="center">
							<span className="table-cell-title">Actions</span>
							<Button
								id="table-button"
								className="action-btn action-btn-primary"
								onClick={handleOnClickExplore}
							>
								Explore
							</Button>
						</TableCell>
					</TableRow>
				</>
			) : (
				// Mobile view
				<Card className="bg-white">
					<div className="card-container">
						<ExpandMore
							expand={expanded}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label="show more"
						>
							<CardHeader
								title={
									<React.Fragment>
										<span>
											{row.firstName}&nbsp;{row.lastName}
										</span>
									</React.Fragment>
								}
							/>
							<CardContent className="card-header-content">
								<div className="card-subheader">
									<Typography variant="body2" color="text.secondary">
										{row.address}
									</Typography>
								</div>
								<div className="card-subheader">
									<Typography variant="body2" color="text.secondary">
										{row.mobile}
									</Typography>
								</div>
							</CardContent>
						</ExpandMore>
						<Collapse in={expanded} timeout="auto" unmountOnExit>
							<CardContent>
								<div>
									<span>
										<img src={CalendarIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Email:&nbsp;
										<span>{row.email}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={CalendarIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Phone:&nbsp;
										<span>{row.mobile}</span>
									</Typography>
								</div>
								<div className="table-cell-btn-group">
									<Button variant="outlined" onClick={handleOnClickExplore}>
										Explore
									</Button>
								</div>
							</CardContent>
						</Collapse>
					</div>
				</Card>
			)}
		</>
	);
}
