import { ArrowBack, Logo } from "assests/images";
import "./loader-style.css";
import React, { useEffect } from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { hideLoader } from "utility/helpers";
import { AppRoutings } from "utility/enums/app-routings";

export function AccessDenied() {
	const navigate = useNavigate();
	useEffect(() => {
		hideLoader();
	}, []);
	return (
		<div className="loader-wrapper">
			<div className="loader-inner">
				<img className="loader-icon tree-mask" src={Logo} alt="" />
				<Typography className="pt-2 color-white" variant="h5">
					Access denied (403)
				</Typography>
				<Button
					onClick={() => {
						navigate(AppRoutings.Root);
					}}
					className="back-login font-size-20"
				>
					<img src={ArrowBack} alt="arrow" />
					Go Back
				</Button>
			</div>
		</div>
	);
}
