import React from "react";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from "@mui/material";
import { IRegionInfo, IRegionSearchCriteria } from "utility/interfaces";
import { SortOrder } from "utility/enums/sort-order";
import {
	RegionTableRowData,
	TablePagination,
	RegionTableSearch,
} from "components";

type ComponentProps = {
	searchCriteria: IRegionSearchCriteria;
	handleOnSearchCriteriaChange: any;
	regionPageInfo: IRegionInfo | null;
};

export function RegionTable(props: ComponentProps) {
	// Extract Props
	const { searchCriteria, handleOnSearchCriteriaChange, regionPageInfo } =
		props;

	// Handled events and functions
	const handleOnPageNumberChange = (value: number) => {
		handleOnSearchCriteriaChange({ ...searchCriteria, PageIndexId: value });
	};
	const handleOnChangeSortDirection = (sortBy: string) => {
		handleOnSearchCriteriaChange({
			...searchCriteria,
			SortBy: sortBy,
			SortOrder:
				searchCriteria.SortOrder === SortOrder.ascending
					? SortOrder.descending
					: SortOrder.ascending,
		});
	};

	return (
		<Box className="table-box">
			<RegionTableSearch
				handleOnSearchCriteriaChange={handleOnSearchCriteriaChange}
				searchCriteria={searchCriteria}
				regions={regionPageInfo?.regions || []}
			/>
			{regionPageInfo && regionPageInfo?.totalRecords > 0 ? (
				<>
					<TableContainer sx={{ display: { xs: "none", laptop: "block" } }}>
						<Table>
							<TableHead>
								<TableRow>
									{regionPageInfo?.gridColumns?.isDisplayWaitTime && (
										<TableCell sx={{ maxWidth: 130 }} className="min-w-130">
											<TableSortLabel
												active={searchCriteria.SortBy === "CreatedDate"}
												direction={
													searchCriteria.SortOrder === SortOrder.descending
														? "asc"
														: "desc"
												}
												onClick={() =>
													handleOnChangeSortDirection("CreatedDate")
												}
											>
												Wait Time
											</TableSortLabel>
										</TableCell>
									)}
									{regionPageInfo?.gridColumns?.isDisplayClientMember && (
										<TableCell sx={{ maxWidth: 170 }} className="min-w-170">
											<TableSortLabel
												active={searchCriteria.SortBy === "Client"}
												direction={
													searchCriteria.SortOrder === SortOrder.ascending
														? "asc"
														: "desc"
												}
												onClick={() => handleOnChangeSortDirection("Client")}
											>
												Client/Member
											</TableSortLabel>
										</TableCell>
									)}
									{regionPageInfo?.gridColumns?.isDisplayBusiness && (
										<TableCell sx={{ maxWidth: 150 }} className="min-w-150">
											<TableSortLabel
												active={searchCriteria.SortBy === "businessName"}
												direction={
													searchCriteria.SortOrder === SortOrder.ascending
														? "asc"
														: "desc"
												}
												onClick={() =>
													handleOnChangeSortDirection("businessName")
												}
											>
												Business
											</TableSortLabel>
										</TableCell>
									)}
									{regionPageInfo?.gridColumns?.isDisplayAddress && (
										<TableCell className="min-w-200">
											<TableSortLabel
												active={searchCriteria.SortBy === "Address"}
												direction={
													searchCriteria.SortOrder === SortOrder.ascending
														? "asc"
														: "desc"
												}
												onClick={() => handleOnChangeSortDirection("Address")}
											>
												Address
											</TableSortLabel>
										</TableCell>
									)}
									{regionPageInfo?.gridColumns?.isDisplayRequestStatus && (
										<TableCell sx={{ maxWidth: 130 }} className="min-w-130">
											<TableSortLabel
												active={searchCriteria.SortBy === "RequestStatusValue"}
												direction={
													searchCriteria.SortOrder === SortOrder.ascending
														? "asc"
														: "desc"
												}
												onClick={() =>
													handleOnChangeSortDirection("RequestStatusValue")
												}
											>
												Status
											</TableSortLabel>
										</TableCell>
									)}
									{regionPageInfo?.gridColumns?.isDisplayPhysician && (
										<TableCell sx={{ maxWidth: 200 }} className="min-w-200">
											<TableSortLabel
												active={searchCriteria.SortBy === "PhysicianName"}
												direction={
													searchCriteria.SortOrder === SortOrder.ascending
														? "asc"
														: "desc"
												}
												onClick={() =>
													handleOnChangeSortDirection("PhysicianName")
												}
											>
												Physician
											</TableSortLabel>
										</TableCell>
									)}
									{regionPageInfo?.gridColumns?.isDisplayRegion && (
										<TableCell className="min-w-100 max-w-100">
											<TableSortLabel
												active={searchCriteria.SortBy === "RegionName"}
												direction={
													searchCriteria.SortOrder === SortOrder.ascending
														? "asc"
														: "desc"
												}
												onClick={() =>
													handleOnChangeSortDirection("RegionName")
												}
											>
												Region
											</TableSortLabel>
										</TableCell>
									)}
									<TableCell align="center" className="min-w-110">
										Actions
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{regionPageInfo?.requests?.map((row, index) => (
									<RegionTableRowData
										key={index}
										row={row}
										isSmallDevice={false}
										gridButtonsPermissions={regionPageInfo.gridButtons}
										gridColumnPermissions={regionPageInfo.gridColumns}
										searchCriteria={searchCriteria}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Box
						sx={{ display: { xs: "block", laptop: "none" } }}
						className="tabledata-cards-group"
					>
						{regionPageInfo?.requests?.map((row, index) => (
							<RegionTableRowData
								key={index}
								row={row}
								isSmallDevice={true}
								gridButtonsPermissions={regionPageInfo.gridButtons}
								gridColumnPermissions={regionPageInfo.gridColumns}
								searchCriteria={searchCriteria}
							/>
						))}
					</Box>
					<TablePagination
						currentPageNumber={searchCriteria.PageIndexId}
						handleOnPageNumberChange={handleOnPageNumberChange}
						totalRecords={regionPageInfo?.totalRecords || 0}
						rowCount={regionPageInfo?.requests?.length || 0}
					/>
				</>
			) : (
				<Typography variant="h5" sx={{ padding: 3 }}>
					No Record(s) Found{" "}
				</Typography>
			)}
		</Box>
	);
}
