import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { ArrowBack } from "assests/images";
import { PatientRecordTable } from "components";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { Constants } from "utility/enums/constants";
import { SortOrder } from "utility/enums/sort-order";
import { ISearchCriteria, IPatientRecord } from "utility/interfaces";
import { createCommonAPICall } from "utility/helpers";
import historyService from "services/history-service";
import { AppRoutings } from "utility/enums/app-routings";

const PatientRecord: React.FC = () => {
	// get query parameters
	const patientId = useParams()?.patientId || "";

	// Page level variable
	const navigate = useNavigate();
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// useStates
	const [searchCriteria, setSearchCriteria] = useState<ISearchCriteria>({
		PageIndexId: 0,
		PageSize: Constants.DefaultTablePageSize.valueOf(),
		SortOrder: SortOrder.descending,
		SortBy: "createdDate",
		SearchBy: "",
	});
	const [patientHistoryPageInfo, setPatientHistoryPageInfo] =
		React.useState<IPatientRecord>({
			requests: [],
			totalRecords: 0,
		});

	// handle events
	const getPatientDashboardData = async () => {
		const requestBody = {
			id: patientId,
			...searchCriteria,
		};

		const data = await createCommonAPICall({
			requestBody,
			apiService: historyService.getPatientDashBoardGrid,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			console.log(data.data);
			setPatientHistoryPageInfo(data.data);
		}
	};

	const handleChangeSearchCriteria = (newSearchCriteria: ISearchCriteria) => {
		setSearchCriteria(newSearchCriteria);
	};

	useEffect(() => {
		getPatientDashboardData();
	}, [searchCriteria]);

	return (
		<>
			<Box>
				<div className="chatbox-overlay"></div>
				<main className="main-content">
					<div
						className="overlay"
						onClick={() => document.body.classList.toggle("sidebar-toggle")}
					></div>
					<div className="request-header">
						<Typography variant="h2">Patient Record</Typography>
						<Button
							variant="outlined"
							onClick={() => navigate(AppRoutings.PatientHistory)}
						>
							<img src={ArrowBack} alt="arrow" />
							Back
						</Button>
					</div>
					<Box className="request-box">
						<div className="attachment">
							<Box
								className="selected-action-btn"
								sx={{ justifyContent: "flex-end", width: "100%" }}
							></Box>
						</div>
						<PatientRecordTable
							patientHistoryPageInfo={patientHistoryPageInfo}
							searchCriteria={searchCriteria}
							handleChangeSearchCriteria={handleChangeSearchCriteria}
							patientId={patientId}
						/>
					</Box>
				</main>
			</Box>
		</>
	);
};

export default PatientRecord;
