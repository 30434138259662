import {
	FormControl,
	FormLabel,
	FormControlLabel,
	Checkbox,
	InputLabel,
	MenuItem,
	Select,
	FormHelperText,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { IDropDownList } from "utility/interfaces";

type ComponentProps = {
	selectedDays: number[];
	handleSelectedDaysChange: any;
	repeatEnd: number;
	onChangeRepeatEnd: any;
	repeatEndOptions: IDropDownList[];
	weekdaysCheckbox: number[];
};

export const RepeatShift = (props: ComponentProps) => {
	// Extract props
	const {
		selectedDays,
		handleSelectedDaysChange,
		repeatEnd,
		onChangeRepeatEnd,
		repeatEndOptions,
		weekdaysCheckbox,
	} = props;

	// Page level variables
	const weekdays = [
		{
			dayNo: 0,
			dayText: "Sunday",
		},
		{
			dayNo: 1,
			dayText: "Monday",
		},
		{
			dayNo: 2,
			dayText: "Tuesday",
		},
		{
			dayNo: 3,
			dayText: "Wednesday",
		},
		{
			dayNo: 4,
			dayText: "Thursday",
		},
		{
			dayNo: 5,
			dayText: "Friday",
		},
		{
			dayNo: 6,
			dayText: "Saturday",
		},
	];

	//useStates
	const [isValueChanged, setIsValueChanged] = useState(false);

	// Handle Events
	const onChangeRepeatDays = (e: ChangeEvent<HTMLInputElement>) => {
		let val = parseInt(e.target.value);
		if (selectedDays.includes(val)) {
			selectedDays.splice(selectedDays.indexOf(val), 1);
		} else {
			selectedDays.push(val);
		}
		handleSelectedDaysChange(selectedDays);
		setIsValueChanged(!isValueChanged);
	};

	return (
		<div>
			<FormControl component="fieldset" variant="standard">
				<FormLabel component="legend">Repeat Days</FormLabel>
				<div
					style={{
						display: "inline-flex",
						flexWrap: "wrap",
					}}
				>
					{weekdays.map((day) => {
						return (
							<FormControlLabel
								control={
									<Checkbox
										checked={selectedDays.includes(day.dayNo)}
										onChange={onChangeRepeatDays}
										value={day.dayNo}
										name={`every${day.dayText}`}
									/>
								}
								label={`Every ${day.dayText}`}
								disabled={!weekdaysCheckbox.includes(day.dayNo)}
							/>
						);
					})}
				</div>
			</FormControl>
			<FormControl fullWidth>
				<InputLabel id="region-label">Repeat End</InputLabel>
				<Select
					labelId="region-label"
					id="region"
					value={repeatEnd}
					name="region"
					onChange={onChangeRepeatEnd}
					disabled={repeatEndOptions.length === 1}
				>
					{repeatEndOptions.map((option) => {
						return (
							<MenuItem key={option.value} value={option.value}>
								{option.text}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
		</div>
	);
};
