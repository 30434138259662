import React from "react";
import myCustomTheme from "app.theme";
import { useNavigate } from "react-router-dom";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { AddIcon } from "assests/images";
import { RoleTable } from "components";
import { AppRoutings } from "utility/enums/app-routings";

const Role: React.FC = () => {
	// Page level variable
	const navigate = useNavigate();

	return (
		<>
			<Box>
				<main className="main-content">
					<div
						className="overlay"
						onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
					></div>
					<Box className="request-container-box">
						<div className="request-header">
							<Typography variant="h2">Account Access</Typography>
							{/* <Typography variant="h2">Roles</Typography> */}
						</div>
						<Box className="request-box">
							<div className="attachment">
								<Box
									className="selected-action-btn w-100"
									sx={{ justifyContent: "flex-end" }}
								>
									<Tooltip title="Create Role">
										<Button
											variant="outlined"
											disableElevation
											disableRipple
											disableFocusRipple
											className="min-w-111px min-w-sm-0"
											onClick={() => navigate(AppRoutings.CreateRole)}
										>
											<img src={AddIcon} alt="download" />
											<span>Create Access</span>
										</Button>
									</Tooltip>
								</Box>
							</div>
							<RoleTable />
						</Box>
					</Box>
				</main>
			</Box>
		</>
	);
};

export default Role;
