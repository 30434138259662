import { AxiosResponse } from "axios";
import {
	IBlockHistoryList,
	ICancelHistorySearchCriteria,
	IEmailLogsSearchCriteria,
	IFormRequest,
	IHistoryInfo,
	IHistorySearchFields,
	IHttpsResponse,
	IPatientHistory,
	IPatientHistorySearchCriteria,
	IPatientRecord,
	IRequestDataSearchCriteria,
	ISMSLogsSearchCriteria,
} from "utility/interfaces";
import { ProtectedEndPoints } from "./api-end-points";
import httpClient from "./base-service";

const getSearchFields = async (): Promise<
	AxiosResponse<IHttpsResponse<IHistorySearchFields>>
> =>
	httpClient.get<IHttpsResponse<IHistorySearchFields>>(
		ProtectedEndPoints.GetHistorySearchFields
	);

const getHistoryGrids = async (): Promise<
	AxiosResponse<IHttpsResponse<IHistoryInfo>>
> =>
	httpClient.get<IHttpsResponse<IHistoryInfo>>(
		ProtectedEndPoints.GetHistoryGrids
	);

const getEmailLogs = (
	request: IEmailLogsSearchCriteria
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.GetEmailLogs,
		request
	);

const getSMSLogs = (
	request: ISMSLogsSearchCriteria
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(ProtectedEndPoints.GetSMSLogs, request);

const getRequestDataGrid = (
	request: IRequestDataSearchCriteria
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.GetRequestDataGrid,
		request
	);

const getPatientRecordsGrid = (
	request: IPatientHistorySearchCriteria
): Promise<AxiosResponse<IHttpsResponse<IPatientHistory>>> =>
	httpClient.post<IHttpsResponse<IPatientHistory>>(
		ProtectedEndPoints.GetPatientRecordsGrid,
		request
	);

const deleteRequestData = (
	request: IFormRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.DeleteRequestData,
		request
	);

const getPatientDashBoardGrid = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<IPatientRecord>>> =>
	httpClient.post<IHttpsResponse<IPatientRecord>>(
		ProtectedEndPoints.GetPatientDashBoardGrid,
		request
	);

const getBlockHistoryGrid = (
	request: ICancelHistorySearchCriteria
): Promise<AxiosResponse<IHttpsResponse<IBlockHistoryList>>> =>
	httpClient.post<IHttpsResponse<IBlockHistoryList>>(
		ProtectedEndPoints.GetBlockHistoryGrid,
		request
	);

const unblockRequest = (
	request: IFormRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.UnblockRequest,
		request
	);

const exportAllCases = (
	request: IRequestDataSearchCriteria
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.ExportAllCases,
		request
	);

const deleteMultipleRequests = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.DeleteMultipleRequests,
		request
	);

export default {
	getSearchFields,
	getHistoryGrids,
	getEmailLogs,
	getSMSLogs,
	getRequestDataGrid,
	getPatientRecordsGrid,
	deleteRequestData,
	getPatientDashBoardGrid,
	getBlockHistoryGrid,
	unblockRequest,
	exportAllCases,
	deleteMultipleRequests,
};
