import { yupResolver } from "@hookform/resolvers/yup";
import {
	Modal,
	Box,
	Typography,
	IconButton,
	TextField,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Tooltip,
	FormHelperText,
} from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { commonModalStyle, getUserLocation } from "utility/helpers";
import { IDropDownList } from "utility/interfaces";
import { saveCallTypeSchema } from "utility/validations";

type ComponentProps = {
	isSelectCallTypeModalOpen: boolean;
	selectCallTypeModalClose: any;
	handleOnClickSave: any;
};

export function SelectCallTypeModal(props: ComponentProps) {
	const {
		isSelectCallTypeModalOpen,
		selectCallTypeModalClose,
		handleOnClickSave,
	} = props;

	const [formValues, setFormValues] = useState({
		isHouseCallOrConsult: 0,
	});

	const [callTypeError, setCallTypeError] = useState("");

	const resetFormValue = () => {
		setFormValues({
			isHouseCallOrConsult: 0,
		});
	};

	const handleOnClickSubmit = (formValues: any) => {
		if (formValues.isHouseCallOrConsult == 1) {
			getUserLocation(
				(position: GeolocationPosition) => {
					handleOnClickSave(
						formValues,
						position.coords.latitude,
						position.coords.longitude
					);
				},
				(error: any) => {
					console.log(error);
					handleOnClickSave(formValues, null, null);
				}
			);
		} else {
			handleOnClickSave(formValues, null, null);
		}
	};

	// Yup resolver
	const {
		reset,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(saveCallTypeSchema),
	});

	useEffect(() => {
		reset();
		resetFormValue();
		setCallTypeError("");
	}, [isSelectCallTypeModalOpen]);

	return (
		<>
			<Modal
				open={isSelectCallTypeModalOpen}
				onClose={selectCallTypeModalClose}
				className="send-modal select-input-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">Select Type Of Care</Typography>
						<IconButton onClick={selectCallTypeModalClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<div style={{ textAlign: "center" }}>
							<Button
								variant={
									formValues.isHouseCallOrConsult === 1
										? "contained"
										: "outlined"
								}
								onClick={() =>
									setFormValues({ ...formValues, isHouseCallOrConsult: 1 })
								}
								style={{ margin: "0px 10px 10px 10px" }}
							>
								Housecall
							</Button>
							<Button
								variant={
									formValues.isHouseCallOrConsult === 2
										? "contained"
										: "outlined"
								}
								onClick={() =>
									setFormValues({ ...formValues, isHouseCallOrConsult: 2 })
								}
								style={{ margin: "0px 10px 10px 10px" }}
							>
								Consult
							</Button>
							<FormHelperText error>{callTypeError}</FormHelperText>
						</div>

						{/* <FormControl fullWidth className="select-input">
							<InputLabel id="refill-label">Type of Care</InputLabel>
							<Select
								{...register("isHouseCallOrConsult")}
								labelId="refill-label"
								id="refill-name"
								value={formValues?.isHouseCallOrConsult}
								label="Number Of Refill"
								name="isHouseCallOrConsult"
								onChange={handleOnFormValueChange}
								MenuProps={{
									className: "select-input-modal",
								}}
								error={errors.isHouseCallOrConsult?.message ? true : false}
							>
								{callTypeList?.map((callType) => {
									return (
										<MenuItem key={callType.value} value={callType.value}>
											{callType.text}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl> */}
						{/* {formValues.isHouseCallOrConsult == 1 && (
							<FormControl fullWidth className="select-input">
								<InputLabel id="hours-label">
									Select Arrival TIme(hrs)
								</InputLabel>
								<Select
									labelId="hours-label"
									id="hours-name"
									value={formValues?.hours}
									name="hours"
									onChange={handleOnFormValueChange}
									MenuProps={{
										className: "select-input-modal",
									}}
								>
									{hoursList?.map((hour) => {
										return (
											<MenuItem key={hour.value} value={hour.value}>
												{hour.text}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						)} */}
						<Box className="modal-footer">
							<Button
								variant="contained"
								onClick={handleSubmit(() => {
									if (formValues.isHouseCallOrConsult > 0) {
										handleOnClickSubmit(formValues);
										setCallTypeError("");
									} else setCallTypeError("Please select care type.");
								})}
							>
								Save
							</Button>
							<Button variant="outlined" onClick={selectCallTypeModalClose}>
								Cancel
							</Button>
						</Box>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
