import React, { useContext, useEffect, useRef, useState } from "react";
import {
	Box,
	Button,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	Grid,
	TableSortLabel,
	Tooltip,
} from "@mui/material";
import { ArrowBack, DownloadIcon, CallIconPrimary } from "assests/images";
import { useNavigate, useParams } from "react-router-dom";
import { IDocumentInfo } from "utility/interfaces";
import {
	createCommonAPICall,
	getComparator,
	getFileIconByType,
	hideLoader,
	isIOS,
	isMobile,
	openSucessErrorModal,
	showLoader,
	stableSort,
} from "utility/helpers";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { closeCaseSchema } from "utility/validations";
import { visuallyHidden } from "@mui/utils";
import moment from "moment";
import {
	CustomDatePicker,
	CustomIntlTelInput,
	PreviewFileModal,
	ViewInvoiceModal,
	WarningModal,
} from "components";
import dashboardService from "services/dashboard-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { INVOICE_LINK } from "config";
import { CountryData } from "react-intl-tel-input-18";
import PreviewIcon from "@mui/icons-material/Preview";
import { Constants } from "utility/enums/constants";

const CloseCase: React.FC = () => {
	// get state value
	const { state } = window.history;

	// Context Values
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// Page level interfaces
	interface ICloseCase {
		firstName: string;
		lastName: string;
		dateOfBirth: string;
		phoneNumber: string;
		email: string;
		updatedPhoneNumber: string;
		updatedEmail: string;
		patientId: string;
	}
	// get query parameters
	const { caseId } = useParams();

	// useStates
	type Order = "asc" | "desc";

	const isPageHidden = useRef(false);

	const [orderDirection, setOrderDirection] = useState<Order>("asc");
	const [isEdit, setIsEdit] = useState(false);
	const [confirmCloseCaseModal, setConfirmCloseCaseModal] = useState(false);
	const [formValues, setFormValues] = useState<ICloseCase>({
		firstName: "",
		lastName: "",
		email: "",
		dateOfBirth: "",
		phoneNumber: "",
		updatedPhoneNumber: "",
		updatedEmail: "",
		patientId: "",
	});
	const [invoiceId, setInvoiceId] = useState<string | null>(null);
	const [invoiceLink, setInvoiceLink] = useState<string | null>(null);

	const [isViewInvoiceModalOpen, setIsViewInvoiceModalOpen] = useState(false);
	const [invoiceModalMessage, setInvoiceModalMessage] = useState("");

	// TODO: Dynamic
	const [documentList, setDocumentList] = useState<IDocumentInfo[]>([]);

	const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
	const [renderer, setRenderer] = useState(0);
	const [previewUrl, setPreviewUrl] = useState("");
	const [isAppOpenInfoModalOpen, setIsAppOpenInfoModalOpen] = useState(false);

	const [ringCentral, setringCentral] = useState<null | HTMLCollection>(null);

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		reset,
	} = useForm({
		resolver: yupResolver(closeCaseSchema),
	});

	// Handled events and functions
	const handleOnClickBackCancel = () =>
		navigate(state?.usr?.backURL, {
			state: {
				searchCriteria: state?.usr?.searchCriteria,
			},
		});

	// const handleOnClickEnterCost = () => {
	// 	navigate(
	// 		AppRoutings.ItemizedCosts.replace(":caseId", caseId ? caseId : ""),
	// 		{
	// 			state: {
	// 				searchCriteria: state?.usr?.searchCriteria,
	// 			},
	// 		}
	// 	);
	// };
	const onFormValueChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setFormValues({ ...formValues, [e.target.name]: e.target.value });
	};
	const handleOnClickCloseCase = () => {
		console.log("On close button click handle here.");
		handleConfirmCloseCaseModalOpen();
	};

	const handleConfimCloseCase = () => {
		const requestBody = {
			id: caseId ? caseId : "",
			providerNotes: "",
		};

		createCommonAPICall({
			requestBody,
			apiService: dashboardService.confirmConcludeCare,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		}).then((data) => {
			if (data.isSuccessfull) {
				navigate(state?.usr?.backURL, {
					state: {
						searchCriteria: state?.usr?.searchCriteria,
					},
				});
			}
		});
	};

	const handleOnClickSave = () => {
		const requestBody = {
			Id: caseId ? caseId : "",
			Email: formValues.updatedEmail,
			PhoneNumber: formValues.updatedPhoneNumber,
		};
		createCommonAPICall({
			requestBody,
			apiService: dashboardService.updateEmailAndPhone,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		}).then((data) => {
			console.log(data);
			if (data.isSuccessfull) {
				setFormValues({
					...formValues,
					email: formValues.updatedEmail,
					phoneNumber: formValues.updatedPhoneNumber,
				});
				setIsEdit(!isEdit);
			}
		});
	};
	const handleOnClickCancle = () => {
		clearErrors();
		reset();
		setFormValues({
			...formValues,
			updatedEmail: formValues.email,
			updatedPhoneNumber: formValues.phoneNumber,
		});
		setIsEdit(!isEdit);
	};

	// Handled event(s)
	const handleOnChangeSortDirection = () => {
		const isAsc = orderDirection === "asc";
		setOrderDirection(isAsc ? "desc" : "asc");
	};
	const getNoteDetails = () => {
		// TODO API call here
		console.log(caseId);
		const requestBody = {
			Id: caseId ? caseId : "",
		};
		createCommonAPICall({
			requestBody,
			apiService: dashboardService.getCloseCaseInfo,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		}).then((data) => {
			console.log(data.data);
			setDocumentList(data.data.fileList);
			setFormValues({
				...formValues,
				firstName: data.data.firstName,
				lastName: data.data.lastName,
				email: data.data.email,
				dateOfBirth: data.data.dateOfBirth,
				phoneNumber: data.data.phoneNumber,
				patientId: data.data.confirmationNumber,
			});
			setInvoiceId(data.data.invoiceId);
			let link = `${INVOICE_LINK}${data.data.invoiceId}`;
			setInvoiceLink(link);
			setRenderer(1);
		});
	};

	const handleConfirmCloseCaseModalOpen = () => {
		setConfirmCloseCaseModal(true);
	};

	const handleConfirmCloseCaseModalClose = () => {
		setConfirmCloseCaseModal(false);
	};

	const handleClickCreateInvoice = () => {
		if (caseId && invoiceId == null) {
			const requestBody = {
				Id: caseId,
			};

			createCommonAPICall({
				requestBody,
				apiService: dashboardService.generateInvoice,
				showErrorMessage: true,
				showSuccessMessage: false,
				setSuccessErrorContext,
			}).then((data) => {
				if (data && data.isSuccessfull) {
					console.log(data);
					setInvoiceModalMessage(data.message);
					setInvoiceId(data.data.invoiceId);
					let link = `${INVOICE_LINK}${data.data.invoiceId}`;
					setInvoiceLink(link);
					handleViewInvoiceModalOpen();
				}
			});
		} else {
			console.log("Already have Invoice.");
			setInvoiceModalMessage(
				"You have already created an invoice for this case!"
			);
			handleViewInvoiceModalOpen();
		}
	};

	const handleViewInvoiceModalOpen = () => {
		setIsViewInvoiceModalOpen(true);
	};

	const handleViewInvoiceModalClose = () => {
		setIsViewInvoiceModalOpen(false);
	};

	const handlePhoneNumberChange = (
		isValid: boolean,
		phoneNumber: string,
		countryData: CountryData
	) => {
		setIsPhoneNumberValid(isValid);
		let formattedPhoneNumber =
			phoneNumber != ""
				? phoneNumber.includes("+")
					? phoneNumber
					: `+${countryData.dialCode} ${phoneNumber}`
				: "";
		setFormValues({ ...formValues, updatedPhoneNumber: formattedPhoneNumber });
	};

	const handleVisibiltyChange = () => {
		if (document.visibilityState === "hidden") {
			hideLoader();
			isPageHidden.current = true;
			// setIsPageHidden(true);
		}
	};

	const handleOnCall = (phone: string) => {
		if (isMobile()) {
			console.log("Mobile device detected");
			try {
				var w = window.parent ? window.parent : window;
				showLoader();
				w.location.assign("rcapp://r/dialer?number=" + phone);
				document.addEventListener("visibilitychange", handleVisibiltyChange);
				setTimeout(() => {
					hideLoader();
					if (!isPageHidden.current) {
						setIsAppOpenInfoModalOpen(true);
					}
					document.removeEventListener(
						"visibilitychange",
						handleVisibiltyChange
					);
					isPageHidden.current = false;
				}, 5000);
				// w.location.assign("rcmobile://call?number=" + row.phone);
			} catch (e) {
				openSucessErrorModal(
					setSuccessErrorContext,
					"Error in calling",
					"Something went wrong for calling",
					false
				);
			}
		} else {
			console.log("Desktop device detected");
			document.getElementById("rc-widget")!.style.display = "block";
			const btn1 = document.querySelector(".Adapter_button.Adapter_popup");
			const btn2 = document.querySelector(".Adapter_button.Adapter_toggle");
			btn1?.addEventListener("click", () => {
				const popup = document.querySelector(".Adapter_right");
				if (popup!.classList.contains("Adapter_minimized")) {
					popup!.setAttribute("style", "display : none");
				} else {
					popup!.setAttribute("style", "display : block");
				}
			});
			btn2?.addEventListener("click", () => {
				const popup = document.querySelector(".Adapter_right");
				if (popup!.classList.contains("Adapter_minimized")) {
					popup!.setAttribute("style", "display : none");
				} else {
					popup!.setAttribute("style", "display : block");
				}
			});
		}
	};

	// Page level variables
	const navigate = useNavigate();

	// useEffects
	useEffect(() => {
		if (caseId) getNoteDetails();
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		var script = document.createElement("script");
		script.setAttribute("id", "ringCentral");
		script.src = Constants.RingCentralScript;
		var rcs0 = document.getElementsByTagName("script")[0];
		rcs0.parentNode!.insertBefore(script, rcs0);

		document.body.appendChild(script);

		const test = document.getElementsByClassName("Adapter_right");
		if (test) {
			setringCentral(test);
		}

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	useEffect(() => {
		clearErrors();
		reset();
	}, [isEdit]);

	return (
		<>
			<Box>
				<main className="main-content">
					<div
						className="overlay"
						onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
					></div>
					<Box className="request-container-box">
						<div className="request-header">
							<Typography variant="h2">Close Case</Typography>
							<Button variant="outlined" onClick={handleOnClickBackCancel}>
								<img src={ArrowBack} alt="arrow" />
								Back
							</Button>
						</div>
						<Box className="request-box">
							<div className="request-box-header">
								<div className="confirm-number uploaded-data">
									<label>Patient Name</label>
									<Typography variant="h4">
										{formValues.firstName} {formValues.lastName}
										<span>&nbsp;&#40;{formValues.patientId}&#41;</span>
									</Typography>
								</div>
								{/* <div className="close-case-btn-group">
									<Button
										variant="outlined"
										disableElevation
										disableFocusRipple
										disableRipple
										className="btn-costs"
										onClick={handleOnClickEnterCost}
									>
										Enter Costs
									</Button>
								</div> */}
								<div className="close-case-btn-group">
									<Button
										variant="outlined"
										disableElevation
										disableFocusRipple
										disableRipple
										className="btn-costs"
										onClick={handleClickCreateInvoice}
									>
										Create Invoice Through Quickbooks
									</Button>
								</div>
							</div>
							<div className="attachment">
								<Typography variant="h4">Encounter</Typography>
							</div>
							<TableContainer className="upload-table-container conclude-table-container">
								<Table className="upload-table conclude-table">
									<TableHead>
										<TableRow>
											<TableCell className="min-w-210"></TableCell>
											<TableCell
												sortDirection={orderDirection}
												className="min-w-160"
											>
												<TableSortLabel
													active={true}
													direction={orderDirection}
													onClick={handleOnChangeSortDirection}
												>
													Upload Date
													<Box component="span" sx={visuallyHidden}>
														{orderDirection === "desc"
															? "sorted descending"
															: "sorted ascending"}
													</Box>
												</TableSortLabel>
											</TableCell>
											<TableCell align="center" sx={{ width: 142 }}>
												Actions
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{stableSort(
											documentList,
											getComparator(orderDirection, "createdDate")
										).map((row, index) => {
											return (
												<TableRow
													hover
													tabIndex={-1}
													key={row.requestWiseFileID}
												>
													<TableCell
														component="th"
														scope="row"
														className="upload-file-col"
													>
														<div className="upload-file">
															<img
																src={getFileIconByType(row.mimeType)}
																alt="file"
															/>
															<span>{row.fileName}</span>
														</div>
													</TableCell>
													<TableCell>
														{moment(row.createdDate).format("MMM D, YYYY")}
													</TableCell>
													<TableCell align="center">
														<div className="upload-actions">
															<Tooltip title="Download">
																<IconButton
																	disableFocusRipple
																	disableRipple
																	href={row.url}
																>
																	<img src={DownloadIcon} alt="download" />
																</IconButton>
															</Tooltip>
															<Tooltip title="Preview">
																<IconButton
																	id="previewBtn"
																	disableFocusRipple
																	disableRipple
																	onClick={() => setPreviewUrl(row.url)}
																>
																	<PreviewIcon />
																</IconButton>
															</Tooltip>
														</div>
													</TableCell>
												</TableRow>
											);
										})}
										{documentList.length == 0 && (
											<TableRow>
												<TableCell align="center"></TableCell>
												<TableCell align="left">No Documents Found</TableCell>
												<TableCell align="center"></TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</TableContainer>
							<div>
								<Typography variant="h4">Patient Information</Typography>
								<Grid container columnSpacing={{ sm: 2, lg: 3 }}>
									<Grid item xs={12} sm={6}>
										<TextField
											fullWidth
											id="first-name"
											label="First Name"
											variant="outlined"
											value={formValues.firstName}
											disabled
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											fullWidth
											id="last-name"
											label="Last Name"
											variant="outlined"
											value={formValues.lastName}
											disabled
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<CustomDatePicker
											label="Date of Birth"
											onChange={undefined}
											name=""
											value={formValues.dateOfBirth}
											disabled={true}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<div className="input-with-button">
											{renderer > 0 && (
												<div style={{ width: "100%" }}>
													<CustomIntlTelInput
														isPhoneNumberValid={isPhoneNumberValid}
														onChange={handlePhoneNumberChange}
														onBlur={() => {}}
														value={
															isEdit
																? formValues.updatedPhoneNumber
																: formValues.phoneNumber
														}
														disabled={!isEdit}
													/>
												</div>
											)}

											{/* <FormControl variant="outlined" fullWidth>
												<InputLabel htmlFor="phone-num">
													Phone Number
												</InputLabel>
												<OutlinedInput
													{...register("updatedPhoneNumber")}
													id="phone-num"
													type="tel"
													name="updatedPhoneNumber"
													onChange={onFormValueChange}
													value={
														isEdit
															? formValues.updatedPhoneNumber
															: formValues.phoneNumber
													}
													disabled={!isEdit}
													fullWidth
													error={
														errors?.updatedPhoneNumber?.message ? true : false
													}
													className="with-icon"
													endAdornment={
														<InputAdornment position="end">
															<IconButton
																edge="end"
																disableFocusRipple
																disableRipple
															>
																<img src={SmartphoneIcon} alt="phone" />
															</IconButton>
														</InputAdornment>
													}
													label="phone-num"
												/>
												<FormHelperText error id="component-error-text">
													<>{errors?.updatedPhoneNumber?.message}</>
												</FormHelperText>
											</FormControl> */}
											<Tooltip title="Call">
												<IconButton
													onClick={() =>
														handleOnCall(
															isEdit
																? formValues.updatedPhoneNumber
																: formValues.phoneNumber
														)
													}
												>
													<img src={CallIconPrimary} alt="call" />
												</IconButton>
											</Tooltip>
										</div>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											{...register("updatedEmail")}
											fullWidth
											id="email"
											label="Email"
											variant="outlined"
											name="updatedEmail"
											onChange={onFormValueChange}
											value={
												isEdit ? formValues.updatedEmail : formValues.email
											}
											disabled={!isEdit}
											error={errors?.updatedEmail?.message ? true : false}
											helperText={errors?.updatedEmail?.message?.toString()}
										/>
									</Grid>
								</Grid>
							</div>
							{/* <div>
								<Typography variant="h4">Requester</Typography>
								<div>
									<Grid container columnSpacing={{ sm: 2, lg: 3 }}>
										<Grid item xs={12} sm={6}>
											<TextField
												fullWidth
												id="full-name"
												label="Full Name"
												variant="outlined"
												value="Rajesh Shah"
												disabled
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<FormControl variant="outlined" fullWidth>
												<InputLabel htmlFor="phone-num2">
													Phone Number
												</InputLabel>
												<OutlinedInput
													id="phone-num2"
													type="tel"
													value="(147) 147 - 1470"
													disabled
													fullWidth
													className="with-icon"
													endAdornment={
														<InputAdornment position="end">
															<IconButton
																edge="end"
																disableFocusRipple
																disableRipple
															>
																<img src={SmartphoneIcon} alt="phone" />
															</IconButton>
														</InputAdornment>
													}
													label="phone-num"
												/>
											</FormControl>
										</Grid>
									</Grid>
								</div>
							</div> */}
							{isEdit ? (
								<div className="request-btn-group">
									<Tooltip title="Save">
										<Button
											variant="contained"
											onClick={() => {
												if (
													formValues.updatedPhoneNumber === "" ||
													!isPhoneNumberValid
												) {
													setIsPhoneNumberValid(false);
													handleSubmit(() => {})();
												} else {
													handleSubmit(handleOnClickSave)();
												}
											}}
										>
											Save
										</Button>
									</Tooltip>
									<Tooltip title="Cancel">
										<Button variant="outlined" onClick={() => setIsEdit(false)}>
											Cancel
										</Button>
									</Tooltip>
								</div>
							) : (
								<div className="request-btn-group">
									<Tooltip title="Edit">
										<Button variant="contained" onClick={handleOnClickCancle}>
											Edit
										</Button>
									</Tooltip>
									<Tooltip title="Close Case">
										<Button variant="outlined" onClick={handleOnClickCloseCase}>
											Close Case
										</Button>
									</Tooltip>
								</div>
							)}
						</Box>
					</Box>
				</main>

				{/* Close case confirmation modal (warning modal) */}
				<WarningModal
					isModalOpen={confirmCloseCaseModal}
					handleOnClickCloseModal={handleConfirmCloseCaseModalClose}
					handleOnClickOk={handleConfimCloseCase}
					warningMessage={
						invoiceId != null
							? "Are you sure you want to Close this Case?"
							: "There is no invoice created for this case, still you want to Close this Case?"
					}
					title={"Confirmation"}
					okButtonText={"Ok"}
					closeButtonText={"Cancel"}
				></WarningModal>

				{/* View Invoice Modal */}
				<ViewInvoiceModal
					isViewInvoiceModalOpen={isViewInvoiceModalOpen}
					viewInvoiceModalClose={handleViewInvoiceModalClose}
					message={invoiceModalMessage}
					invoiceLink={invoiceLink ? invoiceLink : ""}
				/>
				<PreviewFileModal
					isModelOpen={previewUrl !== ""}
					handleModalClose={() => setPreviewUrl("")}
					url={previewUrl}
				/>

				<WarningModal
					isModalOpen={isAppOpenInfoModalOpen}
					handleOnClickOk={() => {
						var fallbackUrl =
							"https://play.google.com/store/apps/details?id=com.glip.mobile";
						if (isIOS()) {
							fallbackUrl =
								"https://apps.apple.com/in/app/ringcentral/id715886894";
						}

						window.location.assign(fallbackUrl);
					}}
					handleOnClickCloseModal={() => setIsAppOpenInfoModalOpen(false)}
					okButtonText="Install"
					closeButtonText="Cancel"
					title="Redirecting..."
					warningMessage="If RingCentral App is installed it will redirect you automatically, if not installed please install the app by clicking Install."
				/>
			</Box>
		</>
	);
};

export default CloseCase;
