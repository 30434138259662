import {
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography,
	Box,
} from "@mui/material";
import React from "react";
import {
	ITimeSheetDetailsSearchCriteria,
	ITimesheetReimbursementInfo,
} from "utility/interfaces";
import { TimesheetReimbursementTableRow } from "./timesheet-reimbursement-table-row";
import { TablePagination } from "components";

type ComponentProps = {
	timesheetReimbursementInfo: ITimesheetReimbursementInfo;
	searchCriteria: ITimeSheetDetailsSearchCriteria;
	handleOnChangeSearchCriteria: any;
};

export const TimesheetReimbursementTable = (props: ComponentProps) => {
	// Extract props
	const {
		timesheetReimbursementInfo,
		handleOnChangeSearchCriteria,
		searchCriteria,
	} = props;

	// Handle events
	const handleOnPageNumberChange = (value: number) => {
		handleOnChangeSearchCriteria({ ...searchCriteria, PageIndexId: value });
	};

	return (
		<>
			{timesheetReimbursementInfo.totalRecords > 0 ? (
				<>
					<TableContainer
						className="upload-table-container"
						sx={{ display: { xs: "none", md: "block" } }}
					>
						<Table className="upload-table">
							<TableHead>
								<TableRow>
									<TableCell className="min-w-160">Date</TableCell>
									<TableCell className="min-w-100">Item</TableCell>
									<TableCell className="min-w-100">Amount</TableCell>
									<TableCell className="min-w-100">Bill</TableCell>
									<TableCell className="min-w-100">Action</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{timesheetReimbursementInfo.timeSheetReimbursement.map(
									(row, index) => (
										<TimesheetReimbursementTableRow
											key={index}
											row={row}
											isSmallDevice={false}
										/>
									)
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<Box
						sx={{ display: { xs: "block", md: "none" } }}
						className="tabledata-cards-group"
					>
						{timesheetReimbursementInfo.timeSheetReimbursement.map(
							(row, index) => (
								<TimesheetReimbursementTableRow
									key={index}
									row={row}
									isSmallDevice={true}
								/>
							)
						)}
					</Box>
					<div className="small-table-pagination">
						<TablePagination
							currentPageNumber={searchCriteria.PageIndexId}
							handleOnPageNumberChange={handleOnPageNumberChange}
							totalRecords={timesheetReimbursementInfo.totalRecords}
							rowCount={
								timesheetReimbursementInfo.timeSheetReimbursement.length
							}
						/>
					</div>
				</>
			) : (
				<Typography variant="h5" className="no-record" sx={{ padding: 3 }}>
					No Record(s) Found
				</Typography>
			)}
		</>
	);
};
