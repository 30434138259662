import React from "react";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from "@mui/material";
import {
	ICancelHistorySearchCriteria,
	ICancelHistoryList,
} from "utility/interfaces";
import {
	TablePagination,
	CancelHistoryTableRowData,
	CancelHistoryTableSearch,
} from "components";
import { SortOrder } from "utility/enums/sort-order";

type ComponentProps = {
	searchCriteria: ICancelHistorySearchCriteria;
	handleOnSearchCriteriaChange: any;
	rowData: ICancelHistoryList | null;
};

export function CancelHistoryTable(props: ComponentProps) {
	// Extract Props
	const { searchCriteria, handleOnSearchCriteriaChange, rowData } = props;

	// Handled events and functions
	const handleOnPageNumberChange = (value: number) => {
		handleOnSearchCriteriaChange({ ...searchCriteria, PageIndexId: value });
	};
	const handleOnChangeSortDirection = (sortBy: string) => {
		handleOnSearchCriteriaChange({
			...searchCriteria,
			SortBy: sortBy,
			SortOrder:
				searchCriteria.SortOrder === SortOrder.ascending
					? SortOrder.descending
					: SortOrder.ascending,
		});
	};

	return (
		<Box className="table-box">
			<CancelHistoryTableSearch
				handleOnSearchCriteriaChange={handleOnSearchCriteriaChange}
			/>
			{rowData && rowData?.totalRecords > 0 ? (
				<>
					<TableContainer sx={{ display: { xs: "none", sm: "block" } }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell className="min-w-160">
										<TableSortLabel
											active={searchCriteria.SortBy === "patientName"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("patientName")}
										>
											Patient Name
										</TableSortLabel>
									</TableCell>
									<TableCell className="min-w-140">
										<TableSortLabel
											active={searchCriteria.SortBy === "phoneNumber"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("phoneNumber")}
										>
											Phone
										</TableSortLabel>
									</TableCell>
									<TableCell className="min-w-150">
										<TableSortLabel
											active={searchCriteria.SortBy === "email"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("email")}
										>
											Email
										</TableSortLabel>
									</TableCell>
									<TableCell className="min-w-130">
										<TableSortLabel
											active={searchCriteria.SortBy === "modifiedDate"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() =>
												handleOnChangeSortDirection("modifiedDate")
											}
										>
											Modified Date
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ maxWidth: 330 }} className="min-w-330">
										<TableSortLabel
											active={searchCriteria.SortBy === "cancelledByAdminNote"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() =>
												handleOnChangeSortDirection("cancelledByAdminNote")
											}
										>
											Cancelled By Admin Note
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ maxWidth: 330 }} className="min-w-330">
										<TableSortLabel
											active={
												searchCriteria.SortBy === "cancelledByPhysicianNote"
											}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() =>
												handleOnChangeSortDirection("cancelledByPhysicianNote")
											}
										>
											Cancelled By Physician Note
										</TableSortLabel>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{rowData?.histories?.map((row, index) => (
									<CancelHistoryTableRowData
										key={index}
										row={row}
										isSmallDevice={false}
										searchCriteria={searchCriteria}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Box
						sx={{ display: { xs: "block", sm: "none" } }}
						className="tabledata-cards-group"
					>
						{rowData?.histories?.map((row, index) => (
							<CancelHistoryTableRowData
								key={index}
								row={row}
								isSmallDevice={true}
								searchCriteria={searchCriteria}
							/>
						))}
					</Box>
					<TablePagination
						currentPageNumber={searchCriteria.PageIndexId}
						handleOnPageNumberChange={handleOnPageNumberChange}
						totalRecords={rowData?.totalRecords || 0}
						rowCount={rowData?.histories?.length || 0}
					/>
				</>
			) : (
				<Typography variant="h5" sx={{ padding: 3 }}>
					No Record(s) Found{" "}
				</Typography>
			)}
		</Box>
	);
}
