import React, { useEffect, useState, useRef } from "react";
import {
	IconButton,
	InputAdornment,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import {
	CloseWhiteIcon,
	LinkIcon,
	MaximizeIcon,
	MinimizeIcon,
	MoodIcon,
	PhysicianIcon,
	PhysicianSecondaryIcon,
	Profile,
	SendIcon,
	VerticalMenuIcon,
} from "assests/images";
import { ChatMenu } from "./chat-menu";
import { IChatBoxInfo } from "utility/interfaces";
import { AccountType } from "utility/enums/request-status";
import { UserContext } from "contexts/user-context";
import { AudioVideoRecordModal } from "components";
import moment from "moment";
import { User } from "assests/images";
import { CLOUD_URL } from "config";

type ComponentProps = {
	handleOnCloseChat: any;
	handleOnMinimized: any;
	chatBoxInfo: IChatBoxInfo;
	index: number;
	sendMessage: Function;
	sendAudioVideo: Function;
	onKeyHandler: Function;
	handleGroupChatStart?: Function;
};

export function ChatBox(props: ComponentProps) {
	// useStates
	const [chatMenu, setChatMenu] = React.useState<null | HTMLElement>(null);
	const [isChatMenuRight, setIsChatMenuRight] = React.useState(false);
	const [minimizeClass, setMinimizeClass] = React.useState("maximize-chatbox");

	const [isAudioVideoModalOpen, setIsAudioVideoModalOpen] = useState(false);
	// Extract Props
	const { handleOnCloseChat, chatBoxInfo, handleOnMinimized, index } = props;
	const userrole = React.useContext(UserContext);

	const [message, setMessage] = useState("");
	const [connection, setConnection] = useState<null | signalR.HubConnection>(
		null
	);

	const sendMessage = async (msg: string) => {
		// if (connection.connectionStarted) {
		try {
			await connection!.send("SendMessage", msg);
		} catch (e) {
			console.log(e);
		}
		// } else {
		// 	alert("No connection to server yet.");
		// }
	};

	// Handled events and functions
	const handleOnClickMinimized = () => {
		if (chatBoxInfo.isMinimized) setMinimizeClass("maximize-chatbox");
		else setMinimizeClass("minimize-chatbox");

		handleOnMinimized(chatBoxInfo.receiverId, chatBoxInfo.senderId);
	};
	const chatMenuOpen = Boolean(chatMenu);
	const handleChatMenuClick = (
		event: React.MouseEvent<HTMLElement>,
		isRightSide: boolean
	) => {
		setIsChatMenuRight(isRightSide);
		setChatMenu(event.currentTarget);
	};

	const handleChatMenuClose = () => {
		setChatMenu(null);
	};

	const handleClickAudioVideoOpen = () => {
		setIsAudioVideoModalOpen(true);
	};

	const handleClickAudioVideoClose = () => setIsAudioVideoModalOpen(false);

	const handleSendAudioVideo = (mediaFile: Blob) => {
		console.log(mediaFile);
		let {
			senderId,
			receiverId,
			isChatWithAdmin,
			isChatWithPatient,
			confirmationNumber,
		} = chatBoxInfo;
		let roleId = userrole.userRoleId;
		props.sendAudioVideo(
			mediaFile,
			senderId,
			receiverId,
			isChatWithPatient
				? AccountType.Patient
				: chatBoxInfo.isChatWithAdmin
					? AccountType.Admin
					: AccountType.Physician,
			confirmationNumber,
			roleId == 1 ? AccountType.Admin : AccountType.Physician
		);
	};

	const handleStartGroupChat = async () => {
		console.log("Start group chat!", chatBoxInfo.receiverId);
		if (props.handleGroupChatStart)
			props.handleGroupChatStart(chatBoxInfo.senderId, chatBoxInfo.receiverId);
		await handleOnCloseChat(
			chatBoxInfo.receiverId,
			chatBoxInfo.senderId,
			userrole.userRoleId == 1 ? AccountType.Admin : AccountType.Physician,
			chatBoxInfo.isChatWithPatient
				? AccountType.Patient
				: chatBoxInfo.isChatWithAdmin
					? AccountType.Admin
					: AccountType.Physician
		);
	};

	console.log(chatBoxInfo, userrole);

	// useEffects
	useEffect(() => {
		if (chatBoxInfo.isMinimized) setMinimizeClass("minimize-chatbox");
		else setMinimizeClass("maximize-chatbox");
	}, [chatBoxInfo]);

	return (
		<div className={`chat-popover chat-${index} ${minimizeClass}`}>
			<div className="popover-header">
				<Typography variant="h5">
					<img
						src={
							chatBoxInfo.chatView.length > 0 &&
							userrole.userRoleId !== AccountType.Physician
								? chatBoxInfo.chatView[0].receiverPhoto == undefined
									? User
									: `${CLOUD_URL}Physician%2F${chatBoxInfo.receiverId}%2F${chatBoxInfo.chatView[0].receiverPhoto}?alt=media`
								: User
						}
						alt="profile"
					/>
					{chatBoxInfo.receiverName}
				</Typography>
				<div className="chat-action-group">
					{chatBoxInfo.isChatWithPatient &&
						chatBoxInfo.physicianName !== "" &&
						userrole.userRoleId === AccountType.Admin && (
							<Tooltip title="Start group chat">
								<IconButton
									onClick={handleStartGroupChat}
									disableFocusRipple
									disableRipple
								>
									<img src={PhysicianIcon} alt="Start Group Chat" />
								</IconButton>
							</Tooltip>
						)}
					{chatBoxInfo.isMinimized ? (
						<IconButton
							disableFocusRipple
							disableRipple
							onClick={handleOnClickMinimized}
						>
							<img src={MaximizeIcon} alt="maximize" />
						</IconButton>
					) : (
						<IconButton
							disableFocusRipple
							disableRipple
							className="chatbox-size-btn minimize-btn"
							onClick={handleOnClickMinimized}
						>
							<img src={MinimizeIcon} alt="minimize" />
						</IconButton>
					)}
					<IconButton
						onClick={(e) => {
							handleOnCloseChat(
								chatBoxInfo.receiverId,
								chatBoxInfo.senderId,
								userrole.userRoleId == 1
									? AccountType.Admin
									: AccountType.Physician,
								chatBoxInfo.isChatWithPatient
									? AccountType.Patient
									: chatBoxInfo.isChatWithAdmin
										? AccountType.Admin
										: AccountType.Physician
							);
						}}
						disableFocusRipple
						disableRipple
					>
						<img src={CloseWhiteIcon} alt="close" />
					</IconButton>
				</div>
			</div>
			<div className="popover-body">
				<div className="popover-scroll">
					<div className="popover-scroll-inner">
						{/* <span className="day">Today</span> */}
						{chatBoxInfo.chatView.map((item, index) => {
							const cdate = new Date(item.createdDate);
							const today = new Date();
							const msgDateTime =
								moment(today).format("DD-MM-YYYY") ===
								moment(cdate).format("DD-MM-YYYY")
									? new Intl.DateTimeFormat("en-US", {
											hour: "2-digit",
											minute: "2-digit",
										}).format(cdate)
									: new Intl.DateTimeFormat("en-US", {
											day: "2-digit",
											month: "2-digit",
											year: "2-digit",
											hour: "2-digit",
											minute: "2-digit",
										}).format(cdate);
							return userrole.userRoleId == 1 ? (
								// chatBoxInfo.chatView[index].userId == userrole.userId &&
								chatBoxInfo.chatView[index].senderTypeId ==
								parseInt(AccountType.Admin.toString()) ? (
									<div className="messages right">
										<IconButton
											onClick={(e) => handleChatMenuClick(e, true)}
											aria-haspopup="true"
											disableFocusRipple
											disableRipple
										>
											<img src={VerticalMenuIcon} alt="menu" />
										</IconButton>
										<div
											className="message"
											dangerouslySetInnerHTML={{ __html: item.message }}
										></div>
										<Typography variant="body1">{msgDateTime}</Typography>
									</div>
								) : (
									<div className="messages left">
										<IconButton
											onClick={(e) => handleChatMenuClick(e, true)}
											aria-haspopup="true"
											disableFocusRipple
											disableRipple
										>
											<img src={VerticalMenuIcon} alt="menu" />
										</IconButton>
										<div
											className="message"
											dangerouslySetInnerHTML={{ __html: item.message }}
										></div>
										<Typography variant="body1">{msgDateTime}</Typography>
									</div>
								)
							) : // chatBoxInfo.chatView[index].userId == userrole.userId &&
							chatBoxInfo.chatView[index].senderTypeId ==
							  parseInt(AccountType.Physician.toString()) ? (
								<div className="messages right">
									<IconButton
										onClick={(e) => handleChatMenuClick(e, true)}
										aria-haspopup="true"
										disableFocusRipple
										disableRipple
									>
										<img src={VerticalMenuIcon} alt="menu" />
									</IconButton>
									<div
										className="message"
										dangerouslySetInnerHTML={{ __html: item.message }}
									></div>
									<Typography variant="body1">{msgDateTime}</Typography>
								</div>
							) : (
								<div className="messages left">
									<IconButton
										onClick={(e) => handleChatMenuClick(e, true)}
										aria-haspopup="true"
										disableFocusRipple
										disableRipple
									>
										<img src={VerticalMenuIcon} alt="menu" />
									</IconButton>
									<div
										className="message"
										dangerouslySetInnerHTML={{ __html: item.message }}
									></div>
									<Typography variant="body1">{msgDateTime}</Typography>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<div className="popover-footer">
				<TextField
					placeholder="Type a message"
					id="chat"
					fullWidth
					className="with-icon-left"
					value={message}
					onKeyDown={(e) => {
						props
							.onKeyHandler(
								e,
								message,
								chatBoxInfo.senderId,
								chatBoxInfo.receiverId,
								chatBoxInfo.isChatWithPatient
									? AccountType.Patient
									: chatBoxInfo.isChatWithAdmin
										? AccountType.Admin
										: AccountType.Physician,
								chatBoxInfo.confirmationNumber,
								userrole.userRoleId == 1
									? AccountType.Admin
									: AccountType.Physician
							)
							.then(() => {
								if (e.code == "Enter") {
									setMessage("");
								}
							});
					}}
					onChange={(e) => setMessage(e.target.value)}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<IconButton edge="end" disableFocusRipple disableRipple>
									<img src={MoodIcon} alt="smiley" />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
				<IconButton
					className="contained"
					onClick={() => {
						props
							.sendMessage(
								message,
								chatBoxInfo.senderId,
								chatBoxInfo.receiverId,
								chatBoxInfo.isChatWithPatient
									? AccountType.Patient
									: chatBoxInfo.isChatWithAdmin
										? AccountType.Admin
										: AccountType.Physician,
								chatBoxInfo.confirmationNumber,
								userrole.userRoleId == 1
									? AccountType.Admin
									: AccountType.Physician
							)
							.then(() => setMessage(""));
					}}
				>
					<img src={SendIcon} alt="send" />
				</IconButton>
				<IconButton className="outlined" onClick={handleClickAudioVideoOpen}>
					<img src={LinkIcon} alt="link" />
				</IconButton>
			</div>
			<ChatMenu
				handleChatMenuClose={handleChatMenuClose}
				isRight={isChatMenuRight}
				chatMenu={chatMenu}
				chatMenuOpen={chatMenuOpen}
			/>

			<AudioVideoRecordModal
				isAudioVideoModalOpen={isAudioVideoModalOpen}
				handleClickAudioVideoClose={handleClickAudioVideoClose}
				handleSendAudioVideo={handleSendAudioVideo}
			/>
		</div>
	);
}
