import React, { useContext, useState } from "react";
import {
	Box,
	Button,
	Checkbox,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from "@mui/material";
import { SortOrder } from "utility/enums/sort-order";
import {
	IHistoryRequestData,
	IHistorySearchFields,
	IRequestDataSearchCriteria,
} from "utility/interfaces";
import {
	RequestDataTableRow,
	RequestDataTableSearch,
	TablePagination,
	WarningModal,
} from "components";
import { createCommonAPICall } from "utility/helpers";
import historyService from "services/history-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { CheckIcon, CrossRedIcon, UncheckIcon } from "assests/images";

type ComponentProps = {
	searchCriteria: IRequestDataSearchCriteria;
	handleOnSearchCriteriaChange: any;
	rowData: IHistoryRequestData | null;
	searchFields: IHistorySearchFields;
	getRequestData: any;
};

export function RequestDataTable(props: ComponentProps) {
	// Context variables
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// Extract Props
	const {
		searchCriteria,
		handleOnSearchCriteriaChange,
		rowData,
		searchFields,
		getRequestData,
	} = props;

	// State variables
	const [selectedRequests, setSelectedRequests] = useState<number[]>([]);

	const [isDeleteWarningModalOpen, setIsDeleteWarningModalOpen] =
		useState(false);

	const [selectAll, setSelectAll] = useState(false);

	// Handled events and functions
	const handleOnPageNumberChange = (value: number) => {
		setSelectAll(false);
		setSelectedRequests([]);
		handleOnSearchCriteriaChange({ ...searchCriteria, PageIndexId: value });
	};
	const handleOnChangeSortDirection = (sortBy: string) => {
		setSelectAll(false);
		setSelectedRequests([]);
		handleOnSearchCriteriaChange({
			...searchCriteria,
			SortBy: sortBy,
			SortOrder:
				searchCriteria.SortOrder === SortOrder.ascending
					? SortOrder.descending
					: SortOrder.ascending,
		});
	};

	const handleSelectRow = (requestId: number) => {
		console.log(requestId);
		if (selectedRequests.includes(requestId)) {
			let changedSelected = selectedRequests.filter((val) => val !== requestId);
			setSelectedRequests(changedSelected);
			if (changedSelected.length !== rowData?.requests?.length) {
				setSelectAll(false);
			}
		} else {
			let changedSelected = [...selectedRequests, requestId];
			setSelectedRequests(changedSelected);
			if (changedSelected.length === rowData?.requests?.length) {
				setSelectAll(true);
			}
		}
	};

	const handleSelectAll = () => {
		if (selectAll) {
			setSelectedRequests([]);
			setSelectAll(false);
		} else {
			let allRequests = rowData?.requests?.map((r) => {
				return r.requestId;
			});
			if (allRequests) {
				setSelectedRequests(allRequests);
				setSelectAll(true);
			}
		}
	};

	const handleDeleteSelectedRequest = async () => {
		console.log("Delete request: ", selectedRequests);
		const requestBody = {
			ids: selectedRequests,
		};
		const data = await createCommonAPICall({
			requestBody,
			apiService: historyService.deleteMultipleRequests,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			setSelectedRequests([]);
			setSelectAll(false);
			getRequestData();
		}
	};

	const CheckedboxIcon = () => {
		return <img src={CheckIcon} alt="checkbox" />;
	};
	const CheckboxIcon = () => {
		return <img src={UncheckIcon} alt="checkbox" />;
	};

	return (
		<Box className="table-box">
			<RequestDataTableSearch
				handleOnSearchCriteriaChange={(
					searchCriteria: IRequestDataSearchCriteria
				) => {
					setSelectedRequests([]);
					handleOnSearchCriteriaChange(searchCriteria);
				}}
				searchFields={searchFields}
				selectedRequests={selectedRequests}
				handleDeleteSelectedClick={() => setIsDeleteWarningModalOpen(true)}
				selectAll={selectAll}
				handleSelectAll={handleSelectAll}
			/>
			{rowData && rowData?.totalRecords > 0 ? (
				<>
					<TableContainer sx={{ display: { xs: "none", laptop: "block" } }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										<Checkbox
											onClick={handleSelectAll}
											icon={<CheckboxIcon />}
											checkedIcon={<CheckedboxIcon />}
											checked={selectAll}
											disableRipple
											disableFocusRipple
											inputProps={{
												"aria-labelledby": `enhanced-table-checkbox--1`,
											}}
										/>
									</TableCell>
									<TableCell
										sx={{ textAlign: "center", maxWidth: 50 }}
										className="min-w-50"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "patientName"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("patientName")}
										>
											Patient Name
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ maxWidth: 70 }} className="min-w-70">
										<TableSortLabel
											active={searchCriteria.SortBy === "RequestorTypeId"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() =>
												handleOnChangeSortDirection("RequestorTypeId")
											}
										>
											Requestor
										</TableSortLabel>
									</TableCell>
									<TableCell
										sx={{ textAlign: "center", maxWidth: 50 }}
										className="min-w-50"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "dateOfService"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() =>
												handleOnChangeSortDirection("dateOfService")
											}
										>
											Date Of Service
										</TableSortLabel>
									</TableCell>
									<TableCell
										sx={{ textAlign: "center", maxWidth: 50 }}
										className="min-w-50"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "CaseClosedDate"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() =>
												handleOnChangeSortDirection("CaseClosedDate")
											}
										>
											Close Case Date
										</TableSortLabel>
									</TableCell>
									<TableCell
										sx={{ textAlign: "center" }}
										className="min-w-100 max-w-100"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "email"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("email")}
										>
											Email
										</TableSortLabel>
									</TableCell>
									<TableCell
										sx={{ textAlign: "center", maxWidth: 70 }}
										className="min-w-70"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "phoneNumber"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("phoneNumber")}
										>
											Phone Number
										</TableSortLabel>
									</TableCell>
									<TableCell
										sx={{ textAlign: "center", maxWidth: 70 }}
										className="min-w-70"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "address"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("address")}
										>
											Address
										</TableSortLabel>
									</TableCell>
									<TableCell
										sx={{ textAlign: "center", maxWidth: 50 }}
										className="min-w-50"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "zipCode"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("zipCode")}
										>
											Zip
										</TableSortLabel>
									</TableCell>
									{/* <TableCell
										sx={{ textAlign: "center", maxWidth: 70 }}
										className="min-w-70"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "transactionStatus"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() =>
												handleOnChangeSortDirection("transactionStatus")
											}
										>
											Transaction Status
										</TableSortLabel>
									</TableCell> */}
									<TableCell
										sx={{ textAlign: "center", maxWidth: 70 }}
										className="min-w-70"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "RequestStatusValue"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() =>
												handleOnChangeSortDirection("RequestStatusValue")
											}
										>
											Request Status
										</TableSortLabel>
									</TableCell>
									<TableCell
										sx={{ textAlign: "center", maxWidth: 70 }}
										className="min-w-70"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "physicianName"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() =>
												handleOnChangeSortDirection("physicianName")
											}
										>
											Physician
										</TableSortLabel>
									</TableCell>
									<TableCell
										sx={{ textAlign: "center", maxWidth: 70 }}
										className="min-w-70"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "PhysicianNotes"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() =>
												handleOnChangeSortDirection("PhysicianNotes")
											}
										>
											Physician Note
										</TableSortLabel>
									</TableCell>
									<TableCell
										sx={{ textAlign: "center", maxWidth: 70 }}
										className="min-w-70"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "AdminNotes"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("AdminNotes")}
										>
											Admin Note
										</TableSortLabel>
									</TableCell>
									<TableCell
										sx={{ textAlign: "center", maxWidth: 70 }}
										className="min-w-70"
									>
										Cancellation Reasons
									</TableCell>
									<TableCell
										sx={{ textAlign: "center", maxWidth: 70 }}
										className="min-w-70"
									>
										<TableSortLabel
											active={
												searchCriteria.SortBy === "CancelledByProviderNotes"
											}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() =>
												handleOnChangeSortDirection("CancelledByProviderNotes")
											}
										>
											Additional Cancellation Notes
										</TableSortLabel>
									</TableCell>
									<TableCell
										sx={{ textAlign: "center", maxWidth: 70 }}
										className="min-w-70"
									>
										<TableSortLabel
											active={searchCriteria.SortBy === "ClientNotes"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("ClientNotes")}
										>
											Patient Note
										</TableSortLabel>
									</TableCell>
									<TableCell
										align="center"
										sx={{ maxWidth: 60 }}
										className="min-w-60"
									>
										Delete Permanently
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{rowData?.requests?.map((row, index) => (
									<RequestDataTableRow
										key={index}
										row={row}
										isSmallDevice={false}
										searchCriteria={searchCriteria}
										getRequestData={getRequestData}
										handleSelectRow={handleSelectRow}
										isRowSelected={selectedRequests.includes(row.requestId)}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Box
						sx={{ display: { xs: "block", laptop: "none" } }}
						className="tabledata-cards-group"
					>
						{rowData?.requests?.map((row, index) => (
							<RequestDataTableRow
								key={index}
								row={row}
								isSmallDevice={true}
								searchCriteria={searchCriteria}
								getRequestData={getRequestData}
								handleSelectRow={handleSelectRow}
								isRowSelected={selectedRequests.includes(row.requestId)}
							/>
						))}
					</Box>
					<TablePagination
						currentPageNumber={searchCriteria.PageIndexId}
						handleOnPageNumberChange={handleOnPageNumberChange}
						totalRecords={rowData?.totalRecords || 0}
						rowCount={rowData?.requests?.length || 0}
					/>
				</>
			) : (
				<Typography variant="h5" sx={{ padding: 3 }}>
					No Record(s) Found{" "}
				</Typography>
			)}
			<WarningModal
				isModalOpen={isDeleteWarningModalOpen}
				handleOnClickCloseModal={() => setIsDeleteWarningModalOpen(false)}
				handleOnClickOk={handleDeleteSelectedRequest}
				warningMessage="Are you sure you want to delete these request permanently?"
				title="Confirmation"
				warningIcon={CrossRedIcon}
				okButtonText="Delete"
				closeButtonText="Cancel"
			/>
		</Box>
	);
}
