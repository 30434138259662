import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { CustomIntlTelInput } from "components";
import { UserContext } from "contexts/user-context";
import React, { useContext, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { CountryData } from "react-intl-tel-input-18";
import { AccountType } from "utility/enums/request-status";
import { IDropDownList, IMyProfileInfo } from "utility/interfaces";

type ComponentProps = {
	formValues: IMyProfileInfo;
	handleOnChangeFormValue: any;
	stateList: IDropDownList[];
	formValidator: UseFormReturn<IMyProfileInfo, any>;
	getMyProfile: any;
	handleSaveProfile: any;
	isNew: boolean;
	renderer: number;
	isPhoneNumberValid: boolean;
	setIsPhoneNumberValid: any;
	isAdminProfile: boolean;
	isMyProfile: boolean;
};

export function MailingAndBillingInformation(props: ComponentProps) {
	// Use Context
	const contextUser = useContext(UserContext);

	// Extract Props
	const {
		formValues,
		handleOnChangeFormValue,
		stateList,
		formValidator,
		getMyProfile,
		handleSaveProfile,
		isNew,
		renderer,
		isPhoneNumberValid,
		setIsPhoneNumberValid,
		isAdminProfile,
		isMyProfile,
	} = props;

	const { handleSubmit } = formValidator;

	// useStates
	const [isEditBillingInformation, setIsEditBillingInformation] =
		useState(false);

	// Handle events
	const handlePhoneNumberChange = (
		isValid: boolean,
		phoneNumber: string,
		countryData: CountryData
	) => {
		setIsPhoneNumberValid(isValid || phoneNumber === "");
		let formattedPhoneNumber =
			phoneNumber != ""
				? phoneNumber.includes("+")
					? phoneNumber
					: `+${countryData.dialCode} ${phoneNumber}`
				: "";
		handleOnChangeFormValue({
			target: {
				name: "alternativePhone",
				value: formattedPhoneNumber,
			},
		});
	};

	return (
		<>
			<Typography variant="h4">Mailing & Billing Information</Typography>
			<div>
				<Grid container columnSpacing={{ sm: 2, lg: 3 }}>
					<Grid item xs={12} sm={6}>
						<TextField
							disabled={!isNew && !isEditBillingInformation}
							id="address-1"
							label="Address 1"
							value={formValues.address1}
							name="address1"
							onChange={handleOnChangeFormValue}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							disabled={!isNew && !isEditBillingInformation}
							id="block"
							label="Address 2"
							value={formValues.address2}
							name="address2"
							onChange={handleOnChangeFormValue}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							disabled={!isNew && !isEditBillingInformation}
							fullWidth
							id="city"
							label="City"
							variant="outlined"
							name="city"
							value={formValues.city}
							onChange={handleOnChangeFormValue}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl fullWidth className="select-input">
							<InputLabel id="state-label">State</InputLabel>
							<Select
								disabled={!isNew && !isEditBillingInformation}
								labelId="state"
								id="state"
								value={formValues?.state || 0}
								label="State"
								name="state"
								onChange={handleOnChangeFormValue}
								MenuProps={{
									className: "select-input-modal",
								}}
							>
								{stateList?.map((state) => {
									return (
										<MenuItem key={state.value} value={state.value}>
											{state.text}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							disabled={!isNew && !isEditBillingInformation}
							fullWidth
							id="zip"
							label="Zip"
							variant="outlined"
							name="zip"
							value={formValues.zip}
							onChange={handleOnChangeFormValue}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						{renderer > 0 && (
							<CustomIntlTelInput
								isPhoneNumberValid={isPhoneNumberValid}
								onBlur={() => {}}
								onChange={handlePhoneNumberChange}
								disabled={!isNew && !isEditBillingInformation}
								value={formValues.alternativePhone ?? ""}
							/>
						)}

						{/* <TextField
							disabled={!isNew && !isEditBillingInformation}
							fullWidth
							id="alt-phone"
							label="Alternative Phone"
							variant="outlined"
							name="alternativePhone"
							value={formValues.alternativePhone}
							onChange={handleOnChangeFormValue}
						/> */}
					</Grid>
				</Grid>
			</div>
			<div className="request-btn-group">
				{isEditBillingInformation ? (
					<>
						<Tooltip title="Save">
							<Button
								onClick={() => {
									if (!isPhoneNumberValid) {
										setIsPhoneNumberValid(false);
										handleSubmit(() => {})();
									} else {
										setIsEditBillingInformation(false);
										handleSubmit(handleSaveProfile)();
									}
								}}
								variant="contained"
							>
								Save
							</Button>
						</Tooltip>
						<Tooltip title="Cancel">
							<Button
								variant="outlined"
								onClick={() => {
									getMyProfile();
									setIsEditBillingInformation(false);
								}}
							>
								Cancel
							</Button>
						</Tooltip>
					</>
				) : (
					<>
						{(contextUser.isSuperAdmin ||
							(isAdminProfile && isMyProfile) ||
							!isAdminProfile) &&
							contextUser.userRoleId === AccountType.Admin &&
							!isNew && (
								<Tooltip title="Edit">
									<Button
										variant="contained"
										onClick={() => {
											setIsEditBillingInformation(true);
										}}
									>
										Edit
									</Button>
								</Tooltip>
							)}
					</>
				)}
			</div>
		</>
	);
}
