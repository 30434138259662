import { yupResolver } from "@hookform/resolvers/yup";
import {
	Card,
	CardContent,
	CardHeader,
	FormControl,
	FormHelperText,
	TableCell,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import { CheckBoxComponent } from "components";
import { UserContext } from "contexts/user-context";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { AccountType } from "utility/enums/request-status";
import { IFillTimesheetDetailsInfo } from "utility/interfaces";
import { DailyTimesheetSchema } from "utility/validations";

type ComponentProps = {
	fillTimesheetDetailsInfo: IFillTimesheetDetailsInfo;
	formValues: IFillTimesheetDetailsInfo;
	handleOnChangeFormValues: any;
	formValidator?: UseFormReturn<IFillTimesheetDetailsInfo, any>;
	isSubmitted?: boolean;
	setFormValidObject?: any;
	isSmallDevice: boolean;
	isFieldDisabled?: boolean;
};

export const TimeSheetFormTableRow = (props: ComponentProps) => {
	// Extract props
	const {
		fillTimesheetDetailsInfo,
		formValues,
		handleOnChangeFormValues,
		formValidator,
		isSubmitted,
		setFormValidObject,
		isSmallDevice,
		isFieldDisabled,
	} = props;

	// Local varibales
	const contextUser = useContext(UserContext);

	// Form validations
	const {
		register,
		formState: { errors },
	} = formValidator ?? { register: null, formState: { errors: null } };

	const {
		register: register2,
		formState: { errors: errors2 },
		handleSubmit,
	} = useForm({
		values: formValues,
		resolver: yupResolver(DailyTimesheetSchema),
	});

	// use effects
	useEffect(() => {
		if (isSubmitted)
			handleSubmit(
				() => setFormValidObject(true),
				() => setFormValidObject(false)
			)();
	}, [isSubmitted]);

	return (
		<>
			{!isSmallDevice ? (
				<TableRow>
					<TableCell>
						{formValues.timeSheetDate
							? moment(formValues.timeSheetDate).format("MM/DD/YYYY")
							: ""}
					</TableCell>
					<TableCell sx={{ maxWidth: 70 }} className="min-w-70">
						{formValues.totalHours ?? 0}
					</TableCell>
					<TableCell sx={{ maxWidth: 170 }} className="min-w-100">
						<FormControl sx={{ marginBottom: 0 }}>
							<TextField
								fullWidth
								{...(register
									? register("shiftDuration")
									: register2("shiftDuration"))}
								error={
									errors?.shiftDuration?.message ||
									errors2.shiftDuration?.message
										? true
										: false
								}
								value={formValues.shiftDuration}
								className="form-table-field"
								name="shiftDuration"
								id="shiftDuration"
								onChange={(e) =>
									handleOnChangeFormValues(e.target.name, e.target.value)
								}
								disabled={isFieldDisabled}
							/>
							<FormHelperText error className="text-wrap">
								{errors?.shiftDuration?.message?.toString() ||
									errors2.shiftDuration?.message?.toString()}
							</FormHelperText>
						</FormControl>
					</TableCell>
					<TableCell align="center" sx={{ maxWidth: 70 }} className="min-w-70">
						<CheckBoxComponent
							label=""
							disabled={isFieldDisabled}
							isChecked={formValues.weekendHoliday}
							handleOnChangeCheckbox={() =>
								handleOnChangeFormValues(
									"weekendHoliday",
									!formValues.weekendHoliday
								)
							}
						/>
					</TableCell>
					<TableCell sx={{ maxWidth: 200 }} className="min-w-100">
						<FormControl sx={{ marginBottom: 0 }}>
							<TextField
								{...(register
									? register("housecallDuration")
									: register2("housecallDuration"))}
								error={
									errors?.housecallDuration?.message ||
									errors2.housecallDuration?.message
										? true
										: false
								}
								className="form-table-field"
								value={formValues.housecallDuration ?? undefined}
								name="housecallDuration"
								id="housecallDuration"
								onChange={(e) =>
									handleOnChangeFormValues(e.target.name, e.target.value)
								}
								disabled={isFieldDisabled}
							/>
							<FormHelperText error className="text-wrap">
								{errors?.housecallDuration?.message?.toString() ||
									errors2.housecallDuration?.message?.toString()}
							</FormHelperText>
						</FormControl>
					</TableCell>
					<TableCell sx={{ maxWidth: 200 }} className="min-w-100">
						<FormControl sx={{ marginBottom: 0 }}>
							<TextField
								{...(register
									? register("phoneConsultDuration")
									: register2("phoneConsultDuration"))}
								error={
									errors?.phoneConsultDuration?.message ||
									errors2.phoneConsultDuration?.message
										? true
										: false
								}
								className="form-table-field"
								value={formValues.phoneConsultDuration ?? undefined}
								name="phoneConsultDuration"
								id="phoneConsultDuration"
								onChange={(e) =>
									handleOnChangeFormValues(e.target.name, e.target.value)
								}
								disabled={isFieldDisabled}
							/>
							<FormHelperText error className="text-wrap">
								{errors?.phoneConsultDuration?.message?.toString() ||
									errors2.phoneConsultDuration?.message?.toString()}
							</FormHelperText>
						</FormControl>
					</TableCell>
					{contextUser.userRoleId === AccountType.Admin && (
						<TableCell sx={{ maxWidth: 200 }} className="min-w-100"></TableCell>
					)}
				</TableRow>
			) : (
				<Card className="bg-white">
					<div className="card-container">
						<CardHeader
							title={
								<React.Fragment>
									<span>
										{formValues.timeSheetDate
											? moment(formValues.timeSheetDate).format("MM/DD/YYYY")
											: ""}
									</span>
								</React.Fragment>
							}
						/>
						<CardContent className="card-header-content">
							<Typography>
								On-Call Hours:&nbsp;
								<span>{formValues.totalHours}</span>
							</Typography>
							<Typography className="timesheet-form-rows">
								Total hours:&nbsp;
								<FormControl sx={{ marginBottom: 0 }}>
									<TextField
										fullWidth
										{...(register
											? register("shiftDuration")
											: register2("shiftDuration"))}
										error={
											errors?.shiftDuration?.message ||
											errors2.shiftDuration?.message
												? true
												: false
										}
										value={formValues.shiftDuration}
										className="form-table-field"
										name="shiftDuration"
										id="shiftDuration"
										onChange={(e) =>
											handleOnChangeFormValues(e.target.name, e.target.value)
										}
										disabled={isFieldDisabled}
									/>
									<FormHelperText error className="text-wrap">
										{errors?.shiftDuration?.message?.toString() ||
											errors2.shiftDuration?.message?.toString()}
									</FormHelperText>
								</FormControl>
							</Typography>
							<Typography>
								Weekend/Holiday:&nbsp;&nbsp;&nbsp;
								<CheckBoxComponent
									label=""
									disabled={isFieldDisabled}
									isChecked={formValues.weekendHoliday}
									handleOnChangeCheckbox={() =>
										handleOnChangeFormValues(
											"weekendHoliday",
											!formValues.weekendHoliday
										)
									}
								/>
							</Typography>
							<Typography className="timesheet-form-rows">
								Number of Housecalls:&nbsp;
								<FormControl sx={{ marginBottom: 0 }}>
									<TextField
										{...(register
											? register("housecallDuration")
											: register2("housecallDuration"))}
										error={
											errors?.housecallDuration?.message ||
											errors2.housecallDuration?.message
												? true
												: false
										}
										className="form-table-field"
										value={formValues.housecallDuration ?? undefined}
										name="housecallDuration"
										id="housecallDuration"
										onChange={(e) =>
											handleOnChangeFormValues(e.target.name, e.target.value)
										}
										disabled={isFieldDisabled}
									/>
									<FormHelperText error className="text-wrap">
										{errors?.housecallDuration?.message?.toString() ||
											errors2.housecallDuration?.message?.toString()}
									</FormHelperText>
								</FormControl>
							</Typography>
							<Typography className="timesheet-form-rows">
								Number of Phone Consults:&nbsp;
								<FormControl sx={{ marginBottom: 0 }}>
									<TextField
										{...(register
											? register("phoneConsultDuration")
											: register2("phoneConsultDuration"))}
										error={
											errors?.phoneConsultDuration?.message ||
											errors2.phoneConsultDuration?.message
												? true
												: false
										}
										className="form-table-field"
										value={formValues.phoneConsultDuration ?? undefined}
										name="phoneConsultDuration"
										id="phoneConsultDuration"
										onChange={(e) =>
											handleOnChangeFormValues(e.target.name, e.target.value)
										}
										disabled={isFieldDisabled}
									/>
									<FormHelperText error className="text-wrap">
										{errors?.phoneConsultDuration?.message?.toString() ||
											errors2.phoneConsultDuration?.message?.toString()}
									</FormHelperText>
								</FormControl>
							</Typography>
						</CardContent>
					</div>
				</Card>
			)}
		</>
	);
};
