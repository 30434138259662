import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from "@mui/material";
import React from "react";
import { CancelHistoryTableSearch } from "../cancel-history/table-search";
import { SortOrder } from "utility/enums/sort-order";
import {
	IBlockHistoryList,
	ICancelHistorySearchCriteria,
} from "utility/interfaces";
import { TablePagination } from "components";
import { BlockHistoryTableRow } from "./table-row";

type ComponentProps = {
	blockHistoryPageInfo: IBlockHistoryList;
	handleOnSearchCriteriaChange: any;
	searchCriteria: ICancelHistorySearchCriteria;
	getBlockHistoryInfo: any;
};

export const BlockHistoryTable = (props: ComponentProps) => {
	// Extract props
	const {
		handleOnSearchCriteriaChange,
		blockHistoryPageInfo,
		searchCriteria,
		getBlockHistoryInfo,
	} = props;

	// Handled events and functions
	const handleOnPageNumberChange = (value: number) => {
		handleOnSearchCriteriaChange({ ...searchCriteria, PageIndexId: value });
	};
	const handleOnChangeSortDirection = (sortBy: string) => {
		handleOnSearchCriteriaChange({
			...searchCriteria,
			SortBy: sortBy,
			SortOrder:
				searchCriteria.SortOrder === SortOrder.ascending
					? SortOrder.descending
					: SortOrder.ascending,
		});
	};

	return (
		<Box className="table-box">
			<CancelHistoryTableSearch
				handleOnSearchCriteriaChange={handleOnSearchCriteriaChange}
			/>
			{blockHistoryPageInfo.totalRecords > 0 ? (
				<>
					<TableContainer sx={{ display: { xs: "none", laptop: "block" } }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell className="min-w-160">
										<TableSortLabel
											active={searchCriteria.SortBy === "patientName"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("patientName")}
										>
											Patient Name
										</TableSortLabel>
									</TableCell>
									<TableCell className="min-w-140">
										<TableSortLabel
											active={searchCriteria.SortBy === "phoneNumber"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("phoneNumber")}
										>
											Phone Number
										</TableSortLabel>
									</TableCell>
									<TableCell className="min-w-150">
										<TableSortLabel
											active={searchCriteria.SortBy === "email"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("email")}
										>
											Email
										</TableSortLabel>
									</TableCell>
									<TableCell className="min-w-130">
										<TableSortLabel
											active={searchCriteria.SortBy === "CreatedDate"}
											direction={
												searchCriteria.SortOrder === SortOrder.ascending
													? "asc"
													: "desc"
											}
											onClick={() => handleOnChangeSortDirection("CreatedDate")}
										>
											Created Date
										</TableSortLabel>
									</TableCell>
									<TableCell className="min-w-160">Notes</TableCell>
									<TableCell className="min-w-130">Is Active</TableCell>
									<TableCell className="min-w-130">Action</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{blockHistoryPageInfo.requests?.map((row, index) => (
									<BlockHistoryTableRow
										key={index}
										row={row}
										isSmallDevice={false}
										getBlockHistoryInfo={getBlockHistoryInfo}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Box
						sx={{ display: { xs: "block", laptop: "none" } }}
						className="tabledata-cards-group"
					>
						{blockHistoryPageInfo.requests?.map((row, index) => (
							<BlockHistoryTableRow
								key={index}
								row={row}
								isSmallDevice={true}
								getBlockHistoryInfo={getBlockHistoryInfo}
							/>
						))}
					</Box>
					<TablePagination
						currentPageNumber={searchCriteria.PageIndexId}
						handleOnPageNumberChange={handleOnPageNumberChange}
						totalRecords={blockHistoryPageInfo?.totalRecords || 0}
						rowCount={blockHistoryPageInfo?.requests?.length || 0}
					/>
				</>
			) : (
				<>
					<Typography variant="h5" sx={{ padding: 3 }}>
						No Record(s) Found{" "}
					</Typography>
				</>
			)}
		</Box>
	);
};
