import { Box, Button, Modal, Typography } from "@mui/material";
import { WarningIcon } from "assests/images";
import React, { useContext } from "react";
import dashboardService from "services/dashboard-service";
import { createCommonAPICall, successErrorModalStyle } from "utility/helpers";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { RequestStatus } from "utility/enums/request-status";
import { IAcceptcaseRequest, IRequest } from "utility/interfaces";

type ComponentProps = {
	isModalOpen: boolean;
	handleAccpetCaseModalClose: any;
	queryString: string;
};

export function AcceptCaseModal(props: ComponentProps) {
	// Extract Props
	const { isModalOpen, handleAccpetCaseModalClose, queryString } = props;
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// Events and functions
	const handleOnClickOkButton = async () => {
		const request: IAcceptcaseRequest = {
			requestId: queryString,
			status: parseInt(RequestStatus.Accepted.toString()),
		};
		console.log(request);
		const data = await createCommonAPICall({
			requestBody: request,
			apiService: dashboardService.acceptCase,
			showSuccessMessage: true,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.isSuccessfull) handleAccpetCaseModalClose(true);
	};

	return (
		<>
			<Modal
				open={isModalOpen}
				onClose={handleAccpetCaseModalClose}
				className="reset-modal"
			>
				<Box sx={successErrorModalStyle}>
					<Typography variant="h2">Accept Case</Typography>
					<Typography variant="body1">
						<>
							• Are sure you want to Accept this request?
							<br />• Once Accept this request then you are not able to Decline
							this request.
						</>
					</Typography>
					<div className="warning-modal-btn-group">
						<Button variant="contained" onClick={handleOnClickOkButton}>
							Accept
						</Button>
						<Button variant="outlined" onClick={handleAccpetCaseModalClose}>
							Cancel
						</Button>
					</div>
				</Box>
			</Modal>
		</>
	);
}
