import { Box, Button, TextField, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import React, { useContext, useEffect } from "react";
import { createCommonAPICall } from "utility/helpers";
import dashboardService from "services/dashboard-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";

type ComponentProps = {
	requestId: number;
	clientName: string;
	phoneNumber: string;
	initiateTime: string;
};

export const WhatsAppChatBox = (props: ComponentProps) => {
	// use context
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// Extract props
	const { requestId, clientName, phoneNumber, initiateTime } = props;

	return (
		<>
			<Box className="whatsApp-chat-popup">
				<Box className="popover-header">
					{clientName} ({phoneNumber})
				</Box>
				<Box className="chat-popover-body">
					<div className="popover-scroll-inner">
						<div className="messages right">
							<div className="message">
								<h3>Welcome,</h3>
								<p>Welcome to our profile on WhatsApp</p>
							</div>
							<Typography variant="body1">{initiateTime}</Typography>
						</div>
						{/* <div className="messages left">
							<div className="message">Good Morning</div>
							<Typography variant="body1">11:45 AM</Typography>
						</div> */}
					</div>
				</Box>
				<div className="chat-popover-footer">
					<TextField
						name="message"
						id="message"
						label="Message"
						className="w-250px"
					/>
					<Button variant="contained">
						<SendIcon />
					</Button>
				</div>
			</Box>
		</>
	);
};
