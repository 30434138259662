import {
	RequestStatus,
	RequestTypes,
	InformationTabTypes,
} from "utility/enums/request-status";

export const getTypeWiseClassName = (type?: number) => {
	switch (type) {
		case RequestTypes.Patient:
			return "bg-patient";
		case RequestTypes.Family:
			return "bg-family";
		case RequestTypes.Business:
			return "bg-business";
		case RequestTypes.Concierge:
			return "bg-concierge";
		case RequestTypes.VIP:
			return "bg-vip";
		// case RequestTypes.Declined:
		// 	return "bg-declined";
		default:
			return "";
	}
};

export const getTypeNameByTypeId = (type?: number) => {
	switch (type) {
		case RequestTypes.Patient:
			return "Patient";
		case RequestTypes.Family:
			return "Family/Friend";
		case RequestTypes.Business:
			return "Business";
		case RequestTypes.Concierge:
			return "Concierge";
		case RequestTypes.VIP:
			return "VIP";
		// case RequestTypes.Declined:
		// 	return "Declined By";
		default:
			return "";
	}
};

export const getRequestStatusValue = (status?: number) => {
	switch (status) {
		case RequestStatus.Accepted:
			return "Accepted";
		case RequestStatus.Unassigned:
			return "Unassigned";
		case RequestStatus.Cancelled:
			return "Cancelled By Admin";
		case RequestStatus.Reserving:
			return "Reserving";
		case RequestStatus.MDEnRoute:
			return "MD Enroute";
		case RequestStatus.MDOnSite:
			return "MD Onsite";
		case RequestStatus.FollowUp:
			return "Follow Up";
		case RequestStatus.Closed:
			return "Closed";
		case RequestStatus.Locked:
			return "Locked";
		case RequestStatus.Rejected:
			return "Rejected";
		case RequestStatus.Consult:
			return "Consult";
		case RequestStatus.Clear:
			return "Clear";
		case RequestStatus.CancelledByProvider:
			return "Cancelled By Provider";
		case RequestStatus.CCUploadedByClient:
			return "Offline Payment";
		case RequestStatus.CCApprovedByAdmin:
			return "Approved Offline Payment";
		case RequestStatus.NotReserved:
			return "Not Reserved";
		case RequestStatus.Conclude:
			return "Conclude";
		case RequestStatus.DeclinedByTransaction:
			return "Declined";
		case RequestStatus.SettleOffline:
			return "Settle Offline";
		case RequestStatus.Deleted:
			return "Deleted";
		case RequestStatus.CloseCancelled:
			return "Close Cancelled";
		default:
			return "";
	}
};

export const getTabStatusWiseName = (status?: number) => {
	switch (status) {
		case InformationTabTypes.New:
			return "New";
		case InformationTabTypes.Active:
			return "Active";
		case InformationTabTypes.Conclude:
			return "Conclude";
		case InformationTabTypes.Closed:
			return "Close";
		case InformationTabTypes.Pending:
			return "Pending";
		case InformationTabTypes.UnPaid:
			return "Unpaid";
		default:
			return "New";
	}
};

export const getTabStatusIdByName = (name: string) => {
	switch (name.toLowerCase().toString()) {
		case "new":
			return InformationTabTypes.New;
		case "active":
			return InformationTabTypes.Active;
		case "conclude":
			return InformationTabTypes.Conclude;
		case "close":
			return InformationTabTypes.Closed;
		case "pending":
			return InformationTabTypes.Pending;
		case "unpaid":
			return InformationTabTypes.UnPaid;
		default:
			return InformationTabTypes.New;
	}
};
