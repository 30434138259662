import React from "react";
import { Divider } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { TermsAndConditions } from "assests/pdfs";

export function AccountFooter() {
	return (
		<div className="login-links">
			<Tooltip title="Terms of Conditions">
				<a href={TermsAndConditions}>Terms of Conditions</a>
			</Tooltip>
			<Divider orientation="vertical" variant="middle" flexItem />
			<Tooltip title="Privacy Policy">
				<a href="https://doctorstoyou.com/privacy-policy/">Privacy Policy</a>
			</Tooltip>
		</div>
	);
}
