import { yupResolver } from "@hookform/resolvers/yup";
import {
	Box,
	Button,
	IconButton,
	Modal,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { commonModalStyle } from "utility/helpers";
import { ISendLink } from "utility/interfaces";
import { sendLinkSchema } from "utility/validations";
import dashboardService from "services/dashboard-service";
import { createCommonAPICall } from "utility/helpers";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { CustomIntlTelInput, PhoneTypeModal } from "components";
import { CountryData } from "react-intl-tel-input-18";

type ComponentProps = {
	isSendLinkOpen: boolean;
	handleClickSendLinkClose: any;
};

export function SendLinkModal(props: ComponentProps) {
	// Page level interface
	interface IFormData {
		firstName: string;
		lastName: string;
		email: string;
		phoneNumber: string;
	}
	const clearFormData = () => {
		return {
			firstName: "",
			lastName: "",
			email: "",
			phoneNumber: "",
		};
	};

	// Usestates
	const [formValues, setFormValues] = useState<IFormData>(clearFormData());
	const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
	const [isMobile, setIsMobile] = useState("M");
	const [isPhoneTypeModalOpen, setIsPhoneTypeModalOpen] = useState(false);

	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// Extract Props
	const { isSendLinkOpen, handleClickSendLinkClose } = props;

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		reset,
	} = useForm({
		values: formValues,
		resolver: yupResolver(sendLinkSchema),
	});

	// Events and functions
	const handleOnClickSendLink = async () => {
		const request: ISendLink = {
			firstName: formValues.firstName,
			lastName: formValues.lastName,
			phoneNumber: formValues.phoneNumber,
			email: formValues.email,
			isMobile: isMobile,
		};
		console.log(request);
		const data = await createCommonAPICall({
			requestBody: request,
			apiService: dashboardService.sendLink,
			showSuccessMessage: true,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		handleClickSendLinkClose();
	};
	const onFormValueChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setFormValues({ ...formValues, [e.target.name]: e.target.value });
	};

	const handlePhoneNumberChange = (
		isValid: boolean,
		phoneNumber: string,
		countryData: CountryData
	) => {
		setIsPhoneNumberValid(isValid);
		let formattedPhoneNumber =
			phoneNumber != ""
				? phoneNumber.includes("+")
					? phoneNumber
					: `+${countryData.dialCode} ${phoneNumber}`
				: "";
		setFormValues({ ...formValues, phoneNumber: formattedPhoneNumber });
	};

	// useEffects
	useEffect(() => {
		reset();
		clearErrors();
		setFormValues(clearFormData());
	}, [isSendLinkOpen]);

	return (
		<>
			<Modal
				open={isSendLinkOpen}
				onClose={handleClickSendLinkClose}
				className="send-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">
							Send mail to patient for submitting request{" "}
						</Typography>
						<IconButton onClick={handleClickSendLinkClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<form>
							<div>
								<TextField
									{...register("firstName")}
									id="first-name"
									label="First Name"
									variant="outlined"
									value={formValues.firstName}
									onChange={onFormValueChange}
									name="firstName"
									helperText={errors?.firstName?.message?.toString()}
									error={errors?.firstName?.message ? true : false}
								/>
								<TextField
									{...register("lastName")}
									id="last-name"
									label="Last Name"
									value={formValues.lastName}
									variant="outlined"
									onChange={onFormValueChange}
									name="lastName"
									helperText={errors?.lastName?.message?.toString()}
									error={errors?.lastName?.message ? true : false}
								/>
								<CustomIntlTelInput
									isPhoneNumberValid={isPhoneNumberValid}
									onChange={handlePhoneNumberChange}
									onBlur={() => {
										if (formValues.phoneNumber !== "")
											setIsPhoneTypeModalOpen(true);
									}}
									value={formValues.phoneNumber}
									name="phoneNumber"
								/>
								{/* <TextField
									{...register("phoneNumber")}
									id="ph-number"
									type="text"
									label="Phone Number"
									value={formValues.phoneNumber}
									variant="outlined"
									onChange={(e) => {
										if (!isNaN(+e.target.value)) onFormValueChange(e);
									}}
									helperText={errors?.phoneNumber?.message?.toString()}
									error={errors?.phoneNumber?.message ? true : false}
									name="phoneNumber"
								/> */}
								<TextField
									{...register("email")}
									id="email"
									type="text"
									value={formValues.email}
									label="Email"
									variant="outlined"
									onChange={onFormValueChange}
									name="email"
									helperText={errors?.email?.message?.toString()}
									error={errors?.email?.message ? true : false}
								/>
							</div>
							<div className="modal-footer">
								<Tooltip title="Send">
									<Button
										variant="contained"
										type="button"
										onClick={() => {
											if (
												formValues.phoneNumber === "" ||
												!isPhoneNumberValid
											) {
												setIsPhoneNumberValid(false);
												handleSubmit(() => {})();
											} else {
												handleSubmit(handleOnClickSendLink)();
											}
										}}
									>
										Send
									</Button>
								</Tooltip>
								<Tooltip title="Cancel">
									<Button variant="outlined" onClick={handleClickSendLinkClose}>
										Cancel
									</Button>
								</Tooltip>
							</div>
						</form>
					</Box>
				</Box>
			</Modal>
			<PhoneTypeModal
				phoneTypeModelOpen={isPhoneTypeModalOpen}
				handlePhoneTypeModelClose={() => setIsPhoneTypeModalOpen(false)}
				onSelectPhoneType={(value: string) => {
					setIsMobile(value);
				}}
			/>
		</>
	);
}
