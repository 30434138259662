import {
	Button,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { UserContext } from "contexts/user-context";
import React, { useContext, useEffect, useRef, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import profileService from "services/profile-service";
import { AccountType } from "utility/enums/request-status";
import { createCommonAPICall } from "utility/helpers";
import { IDropDownList, IMyProfileInfo } from "utility/interfaces";
import { resetPasswordFromMyProfileSchema } from "utility/validations";

type ComponentProps = {
	formValues: IMyProfileInfo;
	handleOnChangeFormValue: any;
	roleList: IDropDownList[];
	formValidator: UseFormReturn<IMyProfileInfo, any>;
	getMyProfile: any;
	handleSaveProfile: any;
	isNew: boolean;
	isAdminProfile: boolean;
	isMyProfile: boolean;
};

export function AccountInformation(props: ComponentProps) {
	// Use Context
	const contextUser = useContext(UserContext);
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// Extract Props
	const {
		formValues,
		handleOnChangeFormValue,
		roleList,
		formValidator,
		getMyProfile,
		handleSaveProfile,
		isNew,
		isAdminProfile,
		isMyProfile,
	} = props;

	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setError,
		clearErrors,
	} = formValidator;

	// useStates
	const [isEditAccountInformation, setIsEditAccountInformation] =
		useState(false);

	const [statusList, setStatusList] = useState<IDropDownList[]>([
		{
			text: "Pending",
			value: 1,
		},
		{
			text: "Active",
			value: 2,
		},
		{
			text: "Not Active",
			value: 3,
		},
	]);

	const passwordInput = useRef<HTMLInputElement>(null);

	// TODO: Dynamic value

	// Handled events and functions
	const handleResetPasswordClick = async () => {
		console.log("Password Changed.");
		const data = {
			password: formValues.password,
		};
		try {
			await resetPasswordFromMyProfileSchema.validate(data, {
				abortEarly: false,
			});
			clearErrors();
			// formValues.password = "";
			const requestBody = {
				...data,
				userName: formValues.userName,
			};
			console.log(requestBody);
			createCommonAPICall({
				requestBody,
				apiService: profileService.resetPasswordFromMyProfile,
				showErrorMessage: true,
				showSuccessMessage: true,
				setSuccessErrorContext,
			}).then((data) => {
				if (data.isSuccessfull) {
					getMyProfile();
				}
			});
		} catch (error: any) {
			// alert("Password error");
			error.inner?.map((inner: any, index: number) => {
				const { type, path, errors } = inner;
				return setError(path, { type, message: errors[index] });
			});
		}
	};

	// useEffects

	return (
		<>
			<Typography variant="h4">Account Information</Typography>
			<div>
				<Grid container columnSpacing={{ sm: 2, lg: 3 }}>
					<Grid
						item
						xs={12}
						sm={6}
						{...(isNew ||
						(!contextUser.isSuperAdmin && isAdminProfile && !isMyProfile)
							? { md: 4 }
							: {})}
					>
						<TextField
							disabled={true}
							fullWidth
							id="user-name"
							label={`User Name ${
								isNew
									? isAdminProfile
										? "(LastnameF)"
										: "(MD.Lastname.F)"
									: ""
							}`}
							variant="outlined"
							name="userName"
							value={formValues.userName}
							onChange={handleOnChangeFormValue}
						/>
					</Grid>
					{(contextUser.isSuperAdmin ||
						(isAdminProfile && isMyProfile) ||
						!isAdminProfile) && (
						<Grid item xs={12} sm={6} {...(isNew ? { md: 4 } : {})}>
							<TextField
								{...register("password")}
								error={errors.password?.message ? true : false}
								helperText={errors.password?.message?.toString()}
								fullWidth
								id="password"
								type="password"
								label="Password"
								variant="outlined"
								name="password"
								value={formValues.password}
								onChange={handleOnChangeFormValue}
							/>
						</Grid>
					)}
					{contextUser.userRoleId === AccountType.Admin && (
						<>
							{!isNew && (
								<Grid
									item
									xs={12}
									sm={6}
									{...(!contextUser.isSuperAdmin &&
									isAdminProfile &&
									!isMyProfile
										? { md: 4 }
										: {})}
								>
									<FormControl fullWidth className="select-input">
										<InputLabel id="status-label">Status</InputLabel>
										<Select
											disabled={!isEditAccountInformation}
											labelId="status"
											id="status"
											value={formValues?.status || 0}
											label="Status"
											name="status"
											onChange={handleOnChangeFormValue}
											MenuProps={{
												className: "select-input-modal",
											}}
										>
											{statusList?.map((status) => {
												return (
													<MenuItem key={status.value} value={status.value}>
														{status.text}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</Grid>
							)}

							<Grid
								item
								xs={12}
								sm={6}
								{...(isNew ||
								(!contextUser.isSuperAdmin && isAdminProfile && !isMyProfile)
									? { md: 4 }
									: {})}
							>
								<FormControl fullWidth className="select-input">
									<InputLabel
										id="role-label"
										error={errors.role?.message ? true : false}
									>
										Role
									</InputLabel>
									<Select
										{...register("role")}
										error={errors.role?.message ? true : false}
										disabled={!isNew && !isEditAccountInformation}
										labelId="role-label"
										id="role"
										value={formValues?.role || 0}
										label="Role"
										name="role"
										onChange={handleOnChangeFormValue}
										MenuProps={{
											className: "select-input-modal custom-select-dropdown",
										}}
									>
										{roleList?.map((role) => {
											return (
												<MenuItem key={role.value} value={role.value}>
													{role.text}
												</MenuItem>
											);
										})}
									</Select>
									<FormHelperText error>{errors.role?.message}</FormHelperText>
								</FormControl>
							</Grid>
						</>
					)}
				</Grid>
			</div>
			<div className="request-btn-group">
				{isEditAccountInformation ? (
					<>
						<Tooltip title="Save">
							<Button
								onClick={() => {
									setIsEditAccountInformation(false);
									handleSubmit(handleSaveProfile)();
								}}
								variant="contained"
							>
								Save
							</Button>
						</Tooltip>
						<Tooltip title="Cancel">
							<Button
								variant="outlined"
								onClick={() => {
									getMyProfile();
									setIsEditAccountInformation(false);
								}}
							>
								Cancel
							</Button>
						</Tooltip>
					</>
				) : (
					<>
						{(contextUser.isSuperAdmin || !isAdminProfile) &&
							!isMyProfile &&
							!isNew && (
								<Tooltip title="Edit">
									<Button
										variant="contained"
										onClick={() => {
											setIsEditAccountInformation(true);
										}}
									>
										Edit
									</Button>
								</Tooltip>
							)}
						{(contextUser.isSuperAdmin ||
							(isAdminProfile && isMyProfile) ||
							!isAdminProfile) &&
							!isNew && (
								<Tooltip title="Reset Password">
									<Button variant="outlined" onClick={handleResetPasswordClick}>
										Reset Password
									</Button>
								</Tooltip>
							)}
					</>
				)}
			</div>
		</>
	);
}
