import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { SortOrder } from "utility/enums/sort-order";
import { Constants } from "utility/enums/constants";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import {
	IChatBoxInfo,
	IChatUploadRequest,
	IGroupChatBoxInfo,
	IPatientRecord,
	ISearchCriteria,
} from "utility/interfaces";
import {
	ChatBox,
	GroupChatBox,
	PatientRecordTableRow,
	TablePagination,
} from "components";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { toast } from "react-toastify";
import dashboardService from "services/dashboard-service";
import { AccountType } from "utility/enums/request-status";
import { showLoader, createCommonAPICall, hideLoader } from "utility/helpers";
import { useNavigate } from "react-router-dom";
import { UserContext } from "contexts/user-context";

const URL = Constants.HubUrl;

type ComponentProps = {
	patientHistoryPageInfo: IPatientRecord;
	searchCriteria: ISearchCriteria;
	handleChangeSearchCriteria: any;
	patientId: string;
};

export function PatientRecordTable(props: ComponentProps) {
	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const contextUser = useContext(UserContext);
	const navigate = useNavigate();

	// Extract props
	const {
		patientHistoryPageInfo,
		searchCriteria,
		handleChangeSearchCriteria,
		patientId,
	} = props;

	// Use states
	const [openChatBoxList, setOpenChatBoxList] = useState<IChatBoxInfo[]>([]);
	const [connection, setConnection] = useState<null | signalR.HubConnection>(
		null
	);
	const [openGroupChatBoxList, setOpenGroupChatBoxList] = useState<
		IGroupChatBoxInfo[]
	>([]);

	// Handled events and functions
	const handleOnPageNumberChange = (value: number) => {
		handleChangeSearchCriteria({ ...searchCriteria, PageIndexId: value });
	};
	const handleOnChangeSortDirection = (sortBy: string) => {
		handleChangeSearchCriteria({
			...searchCriteria,
			SortBy: sortBy,
			SortOrder:
				searchCriteria.SortOrder === SortOrder.ascending
					? SortOrder.descending
					: SortOrder.ascending,
		});
	};

	// event handlers for chat
	const handleOnCloseChat = async (
		receiverId: number,
		senderId: number,
		senderTypeId: number,
		receiverTypeId: number
	) => {
		const openChatBoxes = openChatBoxList.filter(
			(box) => box.receiverId !== receiverId || box.senderId !== senderId
		);
		setOpenChatBoxList([...openChatBoxes]);

		await connection!.send(
			"GetDisconnect",
			senderId,
			senderTypeId,
			receiverId,
			receiverTypeId
		);
	};
	const handleOnMinimized = (receiverId: number, senderId: number) => {
		const chatBoxes = openChatBoxList.map((box) => {
			if (box.receiverId === receiverId && box.senderId === senderId) {
				box.isMinimized = !box.isMinimized;
			}
			return box;
		});
		setOpenChatBoxList(chatBoxes);
	};

	const handleOpenChatBox = async (
		userId: number,
		requestId: number,
		adminName: string,
		confitmationNumber: string
	) => {
		const senderId = Constants.AdminId;
		const senderTypeId = AccountType.Admin;
		const senderName = adminName;
		showLoader();
		await connection!.send(
			"hubconnect",
			senderId,
			senderTypeId,
			senderName,
			requestId,
			AccountType.Patient,
			requestId,
			confitmationNumber,
			userId == null ? 0 : userId
		);
	};

	const keyDownHandler = async (
		event: React.KeyboardEvent<HTMLInputElement>,
		message: string,
		senderId: number,
		receiverId: number,
		receiverAccountType: AccountType,
		confirmationNumber: string,
		senderAccountType: AccountType
	) => {
		console.log("keyDownHandler");
		if (event.code === "Enter" && message !== "") {
			await connection!.send(
				"SendPrivateMessage",
				message,
				senderId,
				receiverId,
				receiverAccountType,
				confirmationNumber,
				senderAccountType,
				"",
				0
			);
		}
	};

	const handleStartGroupChat = async (senderId: number, recieverId: number) => {
		console.log("Start group chat");
		await connection!.send("StartGroupChat", senderId, recieverId);
	};

	const sendMessage = async (
		message: string,
		senderId: number,
		receiverId: number,
		receiverAccountType: AccountType,
		confirmationNumber: string,
		senderAccountType: AccountType
	) => {
		console.log("sendMessage", receiverAccountType);
		await connection!.send(
			"SendPrivateMessage",
			message,
			senderId,
			receiverId,
			receiverAccountType,
			confirmationNumber,
			senderAccountType,
			"",
			0
		);
	};

	const sendAudioVideo = (
		file: Blob,
		senderId: number,
		receiverId: number,
		receiverAccountType: AccountType,
		confirmationNumber: string,
		senderAccountType: AccountType
	) => {
		console.log("File Sent");
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			if (reader.result && typeof reader.result == "string") {
				const chatUploadMedia: IChatUploadRequest = {
					File: reader.result,
					SenderId: senderId,
					ReceiverId: receiverId,
					ReceiverAccountTypeId: receiverAccountType,
					ConfirmationNumber: confirmationNumber,
					SenderAccountTypeId: senderAccountType,
				};
				console.log(chatUploadMedia);
				createCommonAPICall({
					requestBody: chatUploadMedia,
					apiService: dashboardService.uploadAudioVideoForChat,
					showSuccessMessage: false,
					showErrorMessage: false,
					setSuccessErrorContext,
				}).then(async (data) => {
					console.log(data.data);
					let msg = "";
					let path = data.data[0].path;
					// let path = CLOUD_URL + data.data[0].path;
					// if (file.type.includes("audio")) {
					// 	msg = `<audio controls src="${path}"></audio>`;
					// } else if (file.type.includes("video")) {
					// 	msg = `<video controls src="${path}"></video>`;
					// }
					if (data.data[0].status) {
						await connection!.send(
							"SendPrivateMessage",
							path,
							senderId,
							receiverId,
							receiverAccountType,
							confirmationNumber,
							senderAccountType,
							file.type,
							0
						);
					} else {
						toast.error(data.data[0].message);
					}
				});
			}
		};
	};

	const updateChatBoxClasses = (openChatBoxList: IChatBoxInfo[]) => {
		const openChatBoxes = openChatBoxList.filter((box) => !box.isMinimized);
		openChatBoxes.length > 0
			? document.body.classList.toggle("chatbox-open", true)
			: document.body.classList.toggle("chatbox-open", false);
	};

	const handleOnCloseGroupChat = async (
		chatBoxIndex: number,
		senderId: number
	) => {
		const openChatBoxes = openGroupChatBoxList.filter(
			(box, index) => index !== chatBoxIndex
		);
		setOpenGroupChatBoxList([...openChatBoxes]);

		await connection!.send(
			"GetDisconnect",
			senderId,
			contextUser.userRoleId,
			0,
			0
		);
	};

	const handleOnGroupChatMinimized = (chatBoxIndex: number) => {
		const chatBoxes = openGroupChatBoxList.map((box, index) => {
			if (index === chatBoxIndex) {
				box.isMinimized = !box.isMinimized;
			}
			return box;
		});
		setOpenGroupChatBoxList(chatBoxes);
	};

	const handleSendGroupMessage = async (
		chatGroupId: number,
		message: string,
		senderId: number,
		senderTypeId: number
	) => {
		if (connection) {
			console.log(
				"Send group message: ",
				chatGroupId,
				message,
				senderId,
				senderTypeId
			);
			connection.send(
				"SendGroupMessage",
				chatGroupId,
				message,
				senderId,
				senderTypeId,
				"",
				0
			);
		}
	};

	const sendAudioVideoGroup = (
		file: Blob,
		chatGroupId: number,
		senderId: number,
		senderAccountTypeId: number
	) => {
		console.log(file, chatGroupId, senderId, senderAccountTypeId);
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			if (reader.result && typeof reader.result == "string") {
				const chatUploadMedia = {
					File: reader.result,
					SenderId: senderId,
					SenderAccountTypeId: senderAccountTypeId,
					chatGroupId: chatGroupId,
					IsGroup: true,
				};
				console.log(chatUploadMedia);
				createCommonAPICall({
					requestBody: chatUploadMedia,
					apiService: dashboardService.uploadAudioVideoForChat,
					showSuccessMessage: false,
					showErrorMessage: false,
					setSuccessErrorContext,
				}).then(async (data) => {
					console.log(data.data);
					let message = data.data[0].path;
					if (data.data[0].status) {
						await connection!.send(
							"SendGroupMessage",
							chatGroupId,
							message,
							senderId,
							senderAccountTypeId,
							file.type,
							0
						);
					} else {
						toast.error(data.data[0].message);
					}
				});
			}
		};
	};

	const updateGroupChatBoxClasses = (
		openGroupChatBoxList: IGroupChatBoxInfo[]
	) => {
		const openGroupChatBoxes = openGroupChatBoxList.filter(
			(box) => !box.isMinimized
		);
		openGroupChatBoxes.length > 0
			? document.body.classList.toggle("chatbox-open", true)
			: document.body.classList.toggle("chatbox-open", false);
	};

	const handlePrivateMessage = (data: any) => {
		console.log("SendPrivateMessage", data, openChatBoxList);
		if (
			openChatBoxList.find(
				(chat) =>
					chat.confirmationNumber == data.chatresponse.confirmationNumber
			)
		) {
			if (data) setOpenChatBoxList(data.chatresponse);
			const request = data.chatresponse;
			const openChatBoxes = openChatBoxList.filter(
				(box) =>
					box.receiverId === request.receiverId &&
					box.senderId === request.senderId
			);
			if (!openChatBoxes || openChatBoxes?.length === 0) {
				setOpenChatBoxList([
					...openChatBoxList,
					{
						receiverId: data.chatresponse.receiverId,
						receiverName: data.chatresponse.receiverName,
						// request.requestId == request.receiverId
						// 	? data.chatresponse.patientName
						// 	: data.chatresponse.physicianName,
						senderId: data.chatresponse.senderId,
						senderName: data.chatresponse.senderName,
						isMinimized: false,
						confirmationNumber: data.chatresponse.confirmationNumber,
						isChatWithPatient: request.requestId == request.receiverId,
						isChatWithAdmin:
							data.chatresponse.receiverId == Constants.AdminId ? true : false,
						chatView: data.chatView,
						physicianName: data.chatresponse.physicianName,
					},
				]);
				hideLoader();
				return;
			}
			const chatBoxes = openChatBoxList.filter(
				(box) =>
					box.receiverId !== request.receiverId ||
					box.senderId !== request.senderId
			);
			setOpenChatBoxList([
				...chatBoxes,
				{
					receiverId: data.chatresponse.receiverId,
					receiverName:
						request.requestId == request.receiverId
							? data.chatresponse.patientName
							: data.chatresponse.physicianName,
					senderId: data.chatresponse.senderId,
					senderName: data.chatresponse.senderName,
					isMinimized: false,
					confirmationNumber: data.chatresponse.confirmationNumber,
					isChatWithPatient: request.requestId == request.receiverId,
					isChatWithAdmin:
						data.chatresponse.receiverId == Constants.AdminId ? true : false,
					chatView: data.chatView,
					physicianName: data.chatresponse.physicianName,
				},
			]);
		}
	};

	useEffect(() => {
		const newConnection = new HubConnectionBuilder()
			.withUrl(URL)
			.withAutomaticReconnect()
			.build();

		setConnection(newConnection);
	}, []);

	useEffect(() => {
		if (connection) {
			connection
				.start()
				.then((result) => {
					console.log("Connected!");
					connection.on("SetChatHistoryList", (data) => {
						console.log(data);
						if (data) setOpenChatBoxList(data.chatresponse);
						const request = data.chatresponse;
						const openChatBoxes = openChatBoxList.filter(
							(box) =>
								box.receiverId === request.receiverId &&
								box.senderId === request.senderId
						);
						if (!openChatBoxes || openChatBoxes?.length === 0) {
							setOpenChatBoxList([
								...openChatBoxList,
								{
									receiverId: data.chatresponse.receiverId,
									receiverName: data.chatresponse.receiverName,
									// request.requestId == request.receiverId
									// 	? data.chatresponse.patientName
									// 	: data.chatresponse.physicianName,
									senderId: data.chatresponse.senderId,
									senderName: data.chatresponse.senderName,
									isMinimized: false,
									confirmationNumber: data.chatresponse.confirmationNumber,
									isChatWithPatient: request.requestId == request.receiverId,
									isChatWithAdmin:
										data.chatresponse.receiverId == Constants.AdminId
											? true
											: false,
									chatView: data.chatView,
									physicianName: data.chatresponse.physicianName,
								},
							]);
							hideLoader();
							return;
						}
						const chatBoxes = openChatBoxList.filter(
							(box) =>
								box.receiverId !== request.receiverId ||
								box.senderId !== request.senderId
						);
						setOpenChatBoxList([
							...chatBoxes,
							{
								receiverId: data.chatresponse.receiverId,
								receiverName:
									request.requestId == request.receiverId
										? data.chatresponse.patientName
										: data.chatresponse.physicianName,
								senderId: data.chatresponse.senderId,
								senderName: data.chatresponse.senderName,
								isMinimized: false,
								confirmationNumber: data.chatresponse.confirmationNumber,
								isChatWithPatient: request.requestId == request.receiverId,
								isChatWithAdmin:
									data.chatresponse.receiverId == Constants.AdminId
										? true
										: false,
								chatView: data.chatView,
								physicianName: data.chatresponse.physicianName,
							},
						]);
					});
					// connection.on("SendPrivateMessage", (data) =>
					// 	handlePrivateMessage(data)
					// );

					connection.on("openGroupChatBox", (data) => {
						console.log(data);
						setOpenGroupChatBoxList([data]);
					});
				})
				.catch((e) => console.log("Connection failed: ", e));
		}
	}, [connection]);

	useEffect(() => {
		if (connection)
			connection.on("SendPrivateMessage", (data) => handlePrivateMessage(data));
	}, [openChatBoxList]);

	useEffect(() => {
		updateGroupChatBoxClasses(openGroupChatBoxList);
		if (connection)
			connection.on("sendGroupMessage", (data) => {
				console.log(data);
				setOpenGroupChatBoxList([data]);
			});
	}, [openGroupChatBoxList]);

	useEffect(() => {
		updateChatBoxClasses(openChatBoxList);
	}, [openChatBoxList]);

	return (
		<>
			<TableContainer
				className="upload-table-container"
				sx={{ display: { xs: "none", laptop: "block" } }}
			>
				<Table className="upload-table conclude-table">
					<TableHead>
						<TableRow>
							<TableCell
								sortDirection={searchCriteria.SortOrder}
								className="min-w-190 max-w-190"
							>
								<TableSortLabel
									active={searchCriteria.SortBy === "ClientName"}
									direction={searchCriteria.SortOrder}
									onClick={() => handleOnChangeSortDirection("ClientName")}
								>
									CLient/Member
									<Box component="span" sx={visuallyHidden}>
										{searchCriteria.SortOrder === SortOrder.descending
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								</TableSortLabel>
							</TableCell>
							<TableCell
								sortDirection={searchCriteria.SortOrder}
								className="min-w-165 max-w-165"
							>
								<TableSortLabel
									active={searchCriteria.SortBy === "createdDate"}
									direction={searchCriteria.SortOrder}
									onClick={() => handleOnChangeSortDirection("createdDate")}
								>
									Created Date
									<Box component="span" sx={visuallyHidden}>
										{searchCriteria.SortOrder === SortOrder.descending
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								</TableSortLabel>
							</TableCell>
							<TableCell
								sortDirection={searchCriteria.SortOrder}
								sx={{ maxWidth: 170 }}
								className="min-w-170"
							>
								<TableSortLabel
									active={searchCriteria.SortBy === "ConfirmationNumber"}
									direction={searchCriteria.SortOrder}
									onClick={() =>
										handleOnChangeSortDirection("ConfirmationNumber")
									}
								>
									Conformation
									<Box component="span" sx={visuallyHidden}>
										{searchCriteria.SortOrder === SortOrder.descending
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								</TableSortLabel>
							</TableCell>
							<TableCell
								sortDirection={searchCriteria.SortOrder}
								sx={{ maxWidth: 180 }}
								className="min-w-180"
							>
								<TableSortLabel
									active={searchCriteria.SortBy === "PhysicianName"}
									direction={searchCriteria.SortOrder}
									onClick={() => handleOnChangeSortDirection("PhysicianName")}
								>
									Provider Name
									<Box component="span" sx={visuallyHidden}>
										{searchCriteria.SortOrder === SortOrder.descending
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								</TableSortLabel>
							</TableCell>
							<TableCell
								sortDirection={searchCriteria.SortOrder}
								className="min-w-190 max-w-190"
							>
								<TableSortLabel
									active={searchCriteria.SortBy === "concludedDate"}
									direction={searchCriteria.SortOrder}
									onClick={() => handleOnChangeSortDirection("concludedDate")}
								>
									Concluded Date
									<Box component="span" sx={visuallyHidden}>
										{searchCriteria.SortOrder === SortOrder.descending
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								</TableSortLabel>
							</TableCell>
							<TableCell
								sortDirection={searchCriteria.SortOrder}
								sx={{ maxWidth: 125 }}
								className="min-w-125"
							>
								<TableSortLabel
									active={searchCriteria.SortBy === "currentStatus"}
									direction={searchCriteria.SortOrder}
									onClick={() => handleOnChangeSortDirection("currentStatus")}
								>
									Status
									<Box component="span" sx={visuallyHidden}>
										{searchCriteria.SortOrder === SortOrder.descending
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								</TableSortLabel>
							</TableCell>
							<TableCell align="center" sx={{ width: 142 }}>
								Final Report
							</TableCell>
							<TableCell align="center" sx={{ width: 142 }}>
								Actions
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{patientHistoryPageInfo.requests.map((row, index) => {
							return (
								<TableRow hover tabIndex={-1} key={index}>
									<PatientRecordTableRow
										row={row}
										isSmallDevice={false}
										searchCriteria={searchCriteria}
										patientId={patientId}
										handleChatBoxOpen={handleOpenChatBox}
									/>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<Box
				sx={{ display: { xs: "block", laptop: "none" } }}
				className="tabledata-cards-group"
			>
				{patientHistoryPageInfo.requests.map((row, index) => {
					return (
						<PatientRecordTableRow
							row={row}
							key={index}
							isSmallDevice={true}
							searchCriteria={searchCriteria}
							patientId={patientId}
							handleChatBoxOpen={handleOpenChatBox}
						/>
					);
				})}
			</Box>
			<div className="small-table-pagination">
				<TablePagination
					currentPageNumber={searchCriteria.PageIndexId}
					handleOnPageNumberChange={handleOnPageNumberChange}
					totalRecords={patientHistoryPageInfo?.totalRecords || 0}
					rowCount={patientHistoryPageInfo.requests?.length || 0}
				/>
			</div>
			{openChatBoxList.map((chatBox, index) => {
				console.log(openChatBoxList);
				return (
					<ChatBox
						index={index}
						handleOnMinimized={handleOnMinimized}
						key={index}
						handleOnCloseChat={handleOnCloseChat}
						chatBoxInfo={chatBox}
						sendMessage={sendMessage}
						sendAudioVideo={sendAudioVideo}
						onKeyHandler={keyDownHandler}
						handleGroupChatStart={handleStartGroupChat}
					/>
				);
			})}

			{openGroupChatBoxList.map((chatBox, index) => {
				return (
					<GroupChatBox
						index={index}
						key={index}
						groupChatBoxInfo={chatBox}
						handleOnMinimized={handleOnGroupChatMinimized}
						handleOnCloseChat={handleOnCloseGroupChat}
						handleSendGroupMessage={handleSendGroupMessage}
						sendAudioVideo={sendAudioVideoGroup}
					/>
				);
			})}
			<div id="downloadBtn"></div>
		</>
	);
}
