import React from "react";
import { Divider, Tooltip } from "@mui/material";
import { FooterLogo, FooterLogoMobile } from "assests/images";
import { TermsAndConditions } from "assests/pdfs";

export function Footer() {
	return (
		<footer>
			<Tooltip title="Doctors To You">
				<a href="/designer-dashboard" className="footer-logo">
					<img src={FooterLogo} alt="Doctors To You" className="desktop" />
					<img src={FooterLogoMobile} alt="Doctors To You" className="mobile" />
				</a>
			</Tooltip>
			<div className="footer-links">
				<Tooltip title="Terms of Conditions">
					<a href={TermsAndConditions}>Terms of Conditions</a>
				</Tooltip>
				<Divider orientation="vertical" variant="middle" flexItem />
				<Tooltip title="Privacy Policy">
					<a href="https://doctorstoyou.com/privacy-policy/">Privacy Policy</a>
				</Tooltip>
			</div>
		</footer>
	);
}
