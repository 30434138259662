import { User } from "assests/images";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { addDays } from "utility/helpers/common";
import { IScheduleData, IWeeklyCoverageHours } from "utility/interfaces";

type ComponentProps = {
	scheduleData: IScheduleData;
	currDate: Date;
	weeklyCoverageHours: IWeeklyCoverageHours[];
	handleEditShiftClick: any;
};

export const DayView = (props: ComponentProps) => {
	//Extract props
	const { scheduleData, currDate, weeklyCoverageHours, handleEditShiftClick } =
		props;

	//Page level variables
	const thData = [
		{
			Name: "12A",
			Time: "0",
		},
		{
			Name: "1A",
			Time: "1",
		},
		{
			Name: "2A",
			Time: "2",
		},
		{
			Name: "3A",
			Time: "3",
		},
		{
			Name: "4A",
			Time: "4",
		},
		{
			Name: "5A",
			Time: "5",
		},
		{
			Name: "6A",
			Time: "6",
		},
		{
			Name: "7A",
			Time: "7",
		},
		{
			Name: "8A",
			Time: "8",
		},
		{
			Name: "9A",
			Time: "9",
		},
		{
			Name: "10A",
			Time: "10",
		},
		{
			Name: "11A",
			Time: "11",
		},
		{
			Name: "12P",
			Time: "12",
		},
		{
			Name: "1P",
			Time: "13",
		},
		{
			Name: "2P",
			Time: "14",
		},
		{
			Name: "3P",
			Time: "15",
		},
		{
			Name: "4P",
			Time: "16",
		},
		{
			Name: "5P",
			Time: "17",
		},
		{
			Name: "6P",
			Time: "18",
		},
		{
			Name: "7P",
			Time: "19",
		},
		{
			Name: "8P",
			Time: "20",
		},
		{
			Name: "9P",
			Time: "21",
		},
		{
			Name: "10P",
			Time: "22",
		},
		{
			Name: "11P",
			Time: "23",
		},
	];

	//use state
	const [objDate, setObjDate] = useState({
		fullDate: currDate,
		days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
		fullDays: [
			"Sunday",
			"Monday",
			"Tueday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
		],
		months: [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		],
	});

	//Handle events
	const renderDayView = () => {
		const ele = document.getElementById("dayViewDiv");
		if (ele) {
			ele.innerHTML = "";
			const $table = document.createElement("table"),
				$tbody = document.createElement("tbody");

			if (scheduleData.physicianList) {
				const $Thtr = document.createElement("tr");
				$Thtr.innerHTML = '<th><div class="dayStaff">Staff</div></th>';

				const $TCoverage = document.createElement("tr");

				$TCoverage.innerHTML = '<td class="whiteCoverage">Coverage</td>';

				for (var k = 0; k < thData.length; k++) {
					const $th = document.createElement("th");

					const $div = document.createElement("div");
					$div.textContent = thData[k].Name;
					$div.setAttribute("data-value", JSON.stringify(thData[k]));
					$th.append($div);

					$Thtr.append($th);

					const $td = document.createElement("td");
					$td.setAttribute("name", "coverage" + "_" + thData[k].Time);

					$td.setAttribute("shift", thData[k].Time);
					$TCoverage.append($td);
				}

				$tbody.append($Thtr);
				$tbody.append($TCoverage);

				for (var i = 0; i < scheduleData.physicianList.length; i++) {
					const $tr = document.createElement("tr"),
						$tdName = document.createElement("td");

					const $div2 = document.createElement("div");
					$div2.classList.add("dayDPhoto");

					const $img = document.createElement("img");
					$img.setAttribute(
						"src",
						scheduleData.physicianList[i].photo !== ""
							? scheduleData.physicianList[i].photo
							: User
					);
					$img.setAttribute("height", "29px");
					$img.setAttribute("width", "29px");
					$div2.append($img);

					$tdName.append($div2);

					const $div = document.createElement("div");
					$div.textContent = scheduleData.physicianList[i].name;
					$div.classList.add("dayDName");
					$tdName.append($div);

					$tdName.classList.add("physicianNames");
					$tr.append($tdName);
					for (var j = 0; j < thData.length; j++) {
						const $td = document.createElement("td");
						$td.setAttribute(
							"name",
							scheduleData.physicianList[i].physicianId + "_" + thData[j].Time
						);
						$td.setAttribute("shift", thData[j].Time);

						const $div = document.createElement("div");
						$td.append($div);

						$tr.append($td);
					}
					$tbody.append($tr);
				}
				$table.append($tbody);
				$table.classList.add("daygrid");
				ele.append($table);

				var currentDateShiftData = scheduleData.shifts.filter(function (val) {
					return val.date === moment(currDate).format("MM/DD/YYYY");
				});

				currentDateShiftData.sort(function (a, b) {
					return a._start - b._start;
				});

				for (var l = 0; l < currentDateShiftData.length; l++) {
					var startTime = currentDateShiftData[l]._start,
						endTime =
							currentDateShiftData[l]._endMin > 0
								? currentDateShiftData[l]._end + 1
								: currentDateShiftData[l]._end;

					if (endTime == 0) endTime = 24;

					var difference =
						endTime < startTime ? startTime - endTime : endTime - startTime;

					const $tdCol = document.createElement("td");
					$tdCol.setAttribute("colspan", difference.toString());
					$tdCol.classList.add("dayShiftBar");
					let $table = document.createElement("table"),
						$tbody = document.createElement("tbody"),
						$tr = document.createElement("tr");

					$table.setAttribute("style", `width: 100%`);

					var tdWidth = 100 / (difference * 4);

					if (endTime != 0) {
						for (var a = startTime; a < endTime; a++) {
							for (var b = 0; b < 4; b++) {
								const $td = document.createElement("td");
								$td.setAttribute(
									"name",
									currentDateShiftData[l].physicianId + "_" + a + "_" + 15 * b
								);
								$td.setAttribute("style", `width: ${tdWidth}%`);
								$tr.append($td);
							}
						}
					} else {
						for (var a = startTime; a <= 23; a++) {
							for (var b = 0; b < 4; b++) {
								const $td = document.createElement("td");
								$td.setAttribute(
									"name",
									currentDateShiftData[l].physicianId + "_" + a + "_" + 15 * b
								);
								$td.setAttribute("style", `width: ${tdWidth}%`);
								$tr.append($td);
							}
						}
					}

					$tbody.append($tr);
					$table.append($tbody);

					if (
						currentDateShiftData[l].iNoOfDays.split("")[currDate.getDay()] ===
						"1"
					) {
						var d1 = new Date();
						d1.setHours(
							currentDateShiftData[l]._end,
							currentDateShiftData[l]._endMin,
							0,
							0
						);
						var d2 = new Date();
						d2.setHours(
							currentDateShiftData[l]._start,
							currentDateShiftData[l]._startMin,
							0,
							0
						);

						if (moment(d1).format("HH:mm") == "00:00") d1 = addDays(d1, 1);

						var span =
							moment(d1).diff(moment(d2), "milliseconds") / 1000 / 60 / 15;

						if (span < 0) span = span * -1;

						const $tdS = document.createElement("td");
						$tdS.classList.add("dayShifts");
						$tdS.setAttribute(
							"ShiftDetail",
							currentDateShiftData[l].id.toString()
						);
						if (currentDateShiftData[l].status == 1)
							$tdS.classList.add("redShifts");
						else $tdS.classList.add("greenShifts");

						$tdS.setAttribute("colspan", span.toString());

						$tdS.innerHTML =
							'<div class="dayShiftTime">' +
							currentDateShiftData[l].startFull +
							" - " +
							currentDateShiftData[l].endFull +
							"</div> " +
							currentDateShiftData[l].region;

						$tdS.setAttribute(
							"data-ShiftData",
							JSON.stringify(currentDateShiftData[l])
						);

						$tdS.addEventListener("click", function (e) {
							// Edit Modal Show
						});

						var elementsToRemove = $tr.querySelectorAll(
							`[name="${currentDateShiftData[l].physicianId}_${currentDateShiftData[l]._start}_${currentDateShiftData[l]._startMin}"]`
						);

						for (var i = 0; i < span - 1; i++) {
							var element = elementsToRemove[0]?.nextElementSibling;
							if (element) {
								element.remove();
							}
						}

						var elementToReplace = elementsToRemove[0];
						if (elementToReplace?.parentNode)
							elementToReplace.parentNode.replaceChild($tdS, elementToReplace);

						$tdCol.style.padding = "0px";

						$tdCol.append($table);
						const targetSelector = `[name="${currentDateShiftData[l].physicianId}_${startTime}"]`;

						const targetElement = document.querySelector(targetSelector);
						if (targetElement) {
							const siblings = targetElement.parentElement
								? Array.from(targetElement.parentElement.children)
								: [];
							const elementsToRemove = siblings.slice(
								siblings.indexOf(targetElement) + 1,
								siblings.indexOf(targetElement) + difference
							);

							elementsToRemove.forEach((element) => {
								element.remove();
							});
						}

						if (document.querySelectorAll(targetSelector).length !== 0) {
							document.querySelectorAll(targetSelector).forEach((element) => {
								element.replaceWith($tdCol);
							});
						} else {
							let selEle = document.getElementsByName(
								currentDateShiftData[l].physicianId +
									"_" +
									startTime +
									"_" +
									currentDateShiftData[l]._startMin
							);
							const oldTable = selEle[0]?.parentElement?.parentElement
								?.parentElement
								? selEle[0].parentElement.parentElement.parentElement
								: null;
							$table = oldTable as HTMLTableElement;

							let selectedElement = document.getElementsByName(
								currentDateShiftData[l].physicianId +
									"_" +
									startTime +
									"_" +
									currentDateShiftData[l]._startMin
							);
							const siblingElements = selectedElement[0]?.nextElementSibling
								?.parentElement
								? Array.from(
										selectedElement[0].nextElementSibling.parentElement.children
									).slice(1, span)
								: [];

							siblingElements.forEach((element) => {
								element.remove();
							});

							document
								.getElementsByName(
									currentDateShiftData[l].physicianId +
										"_" +
										startTime +
										"_" +
										currentDateShiftData[l]._startMin +
										"]"
								)[0]
								?.replaceWith($tdS);
						}
					}
				}
				renderDayViewColors();
				document.querySelectorAll(".dayShifts").forEach((e) => {
					e.addEventListener("click", (eve) => {
						let div = eve.target as HTMLTableColElement;
						let selectedShift = scheduleData.shifts.find(
							(s) => s.id.toString() == div.getAttribute("ShiftDetail")
						);
						handleEditShiftClick(selectedShift);
					});
				});
			}
		}
	};

	const renderDayViewColors = () => {
		const coverageCells = document.querySelectorAll("td[name^='coverage']");
		coverageCells.forEach((val) => {
			const shift = val.getAttribute("shift")!;
			let nextShift = shift;

			if (shift !== "23") {
				nextShift = (parseInt(shift) + 1).toString().padStart(2, "0");
			} else {
				nextShift = "00";
			}

			const staffCount = document.querySelectorAll(
				`td[name$='_${shift}'][shift]:not(td[name='coverage_${shift}'])`
			).length;

			if (staffCount === scheduleData.shifts.length) {
				val.classList.add("pinkCoverage");
			} else {
				let isBlueShift = false;

				const greenShifts = document.querySelectorAll(
					".greenShifts .dayShiftTime"
				);
				greenShifts.forEach((v) => {
					const [startTime, endTime] = v.textContent!.split(" - ");
					const st = moment(startTime, "hh:mm A");
					const et = moment(endTime, "hh:mm A");
					const ct = moment(shift + ":00", "HH:mm");
					const ct2 = moment(nextShift + ":00", "HH:mm");

					if (
						(ct <= st && ct2 > st) ||
						(ct < et && ct2 >= et) ||
						(ct < st && ct2 > et) ||
						(ct > st &&
							((ct2 < et && ct2.format("HH:mm") !== "00:00") ||
								et.format("HH:mm") === "00:00")) ||
						(ct === st && ct2 === st)
					) {
						isBlueShift = true;
					}
				});

				if (isBlueShift) {
					val.classList.add("greyCoverage");
				} else {
					val.classList.add("pinkCoverage");
				}
			}
		});
	};

	//useEffects
	useEffect(() => {
		renderDayView();
	});

	return <div id="dayViewDiv"></div>;
};
