import { Box, Button, Tooltip, Typography } from "@mui/material";
import myCustomTheme from "app.theme";
import { AddIcon, ArrowBack } from "assests/images";
import { HaloHRTable } from "components";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IHaloHR } from "utility/interfaces";

const HaloHR: React.FC = () => {
	// Page level variable
	const navigate = useNavigate();

	// State variables
	const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
	const [modalData, setModalData] = useState<IHaloHR | null>(null);

	// Handle events
	const handleAddEditModalOpen = () => setIsAddEditModalOpen(true);

	const handleAddEditModalClose = () => {
		setModalData(null);
		setIsAddEditModalOpen(false);
	};

	const handleClickAddEdit = (data: IHaloHR | null) => {
		setModalData(data);
		handleAddEditModalOpen();
	};

	return (
		<Box>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="request-container-box">
					<Box className="tab-item-header">
						<Typography variant="h2">Halo Administrators</Typography>
						<Button variant="outlined" onClick={() => navigate(-1)}>
							<img src={ArrowBack} alt="arrow" />
							Back
						</Button>
					</Box>
					<Box className="request-box">
						<div className="attachment">
							<Box
								className="selected-action-btn"
								sx={{ justifyContent: "flex-end", width: "100%" }}
							>
								<Tooltip title="Add Halo HR">
									<Button
										variant="outlined"
										disableElevation
										disableRipple
										disableFocusRipple
										className="min-w-111px min-w-sm-0"
										onClick={() => handleClickAddEdit(null)}
									>
										<img src={AddIcon} alt="download" />
										<span>Add HR</span>
									</Button>
								</Tooltip>
							</Box>
						</div>
						<HaloHRTable
							handleClickEdit={handleClickAddEdit}
							isAddEditModalOpen={isAddEditModalOpen}
							handleModalClose={handleAddEditModalClose}
							haloHRData={modalData}
						/>
					</Box>
				</Box>
			</main>
		</Box>
	);
};

export default HaloHR;
