import {
	IconButton,
	InputAdornment,
	TextField,
	Typography,
} from "@mui/material";
import {
	CloseWhiteIcon,
	LinkIcon,
	MaximizeIcon,
	MinimizeIcon,
	MoodIcon,
	SendIcon,
	User,
	VerticalMenuIcon,
} from "assests/images";
import { AudioVideoRecordModal, ChatMenu } from "components";
import React, { useContext, useState } from "react";
import { IGroupChatBoxInfo } from "utility/interfaces";
import GroupsIcon from "@mui/icons-material/Groups";
import { UserContext } from "contexts/user-context";
import moment from "moment";
import { AccountType } from "utility/enums/request-status";

type ComponentProps = {
	handleOnCloseChat: any;
	handleOnMinimized: any;
	index: number;
	groupChatBoxInfo: IGroupChatBoxInfo;
	handleSendGroupMessage: any;
	sendAudioVideo: any;
};

export const GroupChatBox = (props: ComponentProps) => {
	// Context valiables
	const contextUser = useContext(UserContext);

	// useStates
	const [chatMenu, setChatMenu] = useState<null | HTMLElement>(null);
	const [isChatMenuRight, setIsChatMenuRight] = useState(false);
	const [minimizeClass, setMinimizeClass] = useState("maximize-chatbox");
	const [message, setMessage] = useState("");
	const [isAudioVideoModalOpen, setIsAudioVideoModalOpen] = useState(false);

	// Page level variables
	const chatMenuOpen = Boolean(chatMenu);

	// Extract props
	const {
		handleOnCloseChat,
		handleOnMinimized,
		index,
		groupChatBoxInfo,
		handleSendGroupMessage,
		sendAudioVideo,
	} = props;

	// Events and functions
	const handleOnClickMinimized = () => {
		if (groupChatBoxInfo.isMinimized) setMinimizeClass("maximize-chatbox");
		else setMinimizeClass("minimize-chatbox");

		handleOnMinimized(index);
	};

	const handleChatMenuClick = (
		event: React.MouseEvent<HTMLElement>,
		isRightSide: boolean
	) => {
		setIsChatMenuRight(isRightSide);
		setChatMenu(event.currentTarget);
	};

	const handleChatMenuClose = () => {
		setChatMenu(null);
	};

	const handleClickAudioVideoOpen = () => {
		setIsAudioVideoModalOpen(true);
	};

	const handleClickAudioVideoClose = () => setIsAudioVideoModalOpen(false);

	const handleSendAudioVideo = (mediaFile: Blob) => {
		console.log(mediaFile);
		let { senderId, chatGroupId } = groupChatBoxInfo;
		let roleId = contextUser.userRoleId;
		sendAudioVideo(
			mediaFile,
			chatGroupId,
			senderId,
			roleId == 1 ? AccountType.Admin : AccountType.Physician
		);
	};

	return (
		<div className={`chat-popover chat-${index} ${minimizeClass}`}>
			<div className="popover-header">
				<Typography variant="h5">
					<GroupsIcon
						sx={{
							marginRight: "10px",
							border: "2px solid #fff",
							padding: "5px",
							borderRadius: "50%",
						}}
					/>
					{/* <img src={User} alt="profile" /> */}
					{groupChatBoxInfo.chatGroupName}
				</Typography>
				<div className="chat-action-group">
					{groupChatBoxInfo.isMinimized ? (
						<IconButton
							disableFocusRipple
							disableRipple
							onClick={handleOnClickMinimized}
						>
							<img src={MaximizeIcon} alt="maximize" />
						</IconButton>
					) : (
						<IconButton
							disableFocusRipple
							disableRipple
							className="chatbox-size-btn minimize-btn"
							onClick={handleOnClickMinimized}
						>
							<img src={MinimizeIcon} alt="minimize" />
						</IconButton>
					)}
					<IconButton
						onClick={() => handleOnCloseChat(index, groupChatBoxInfo.senderId)}
						disableFocusRipple
						disableRipple
					>
						<img src={CloseWhiteIcon} alt="close" />
					</IconButton>
				</div>
			</div>
			<div className="popover-body">
				<div className="popover-scroll">
					<div className="popover-scroll-inner">
						{groupChatBoxInfo.chatView.map((item, index) => {
							const cdate = new Date(item.createdDate);
							const today = new Date();
							const msgDateTime =
								moment(today).format("DD-MM-YYYY") ===
								moment(cdate).format("DD-MM-YYYY")
									? new Intl.DateTimeFormat("en-US", {
											hour: "2-digit",
											minute: "2-digit",
										}).format(cdate)
									: new Intl.DateTimeFormat("en-US", {
											day: "2-digit",
											month: "2-digit",
											year: "2-digit",
											hour: "2-digit",
											minute: "2-digit",
										}).format(cdate);
							return contextUser.userRoleId === item.senderTypeId ? (
								<div className="messages right">
									<div className="sender-name">You</div>
									<IconButton
										onClick={(e) => handleChatMenuClick(e, true)}
										aria-haspopup="true"
										disableFocusRipple
										disableRipple
									>
										<img src={VerticalMenuIcon} alt="menu" />
									</IconButton>
									<div
										className="message"
										dangerouslySetInnerHTML={{ __html: item.message }}
									></div>
									<Typography variant="body1">{msgDateTime}</Typography>
								</div>
							) : (
								<div className="messages left">
									<div className="sender-name">{item.senderName}</div>
									<IconButton
										onClick={(e) => handleChatMenuClick(e, true)}
										aria-haspopup="true"
										disableFocusRipple
										disableRipple
									>
										<img src={VerticalMenuIcon} alt="menu" />
									</IconButton>
									<div
										className="message"
										dangerouslySetInnerHTML={{ __html: item.message }}
									></div>
									<Typography variant="body1">{msgDateTime}</Typography>
								</div>
							);
						})}
						{/* <div className="messages left">
							<div className="sender-name">Physician</div>
							<IconButton
								onClick={(e) => handleChatMenuClick(e, true)}
								aria-haspopup="true"
								disableFocusRipple
								disableRipple
							>
								<img src={VerticalMenuIcon} alt="menu" />
							</IconButton>
							<div className="message">Hello</div>
							<Typography variant="body1">11:52 AM</Typography>
						</div>
						<div className="messages right">
							<div className="sender-name">You</div>
							<IconButton
								onClick={(e) => handleChatMenuClick(e, true)}
								aria-haspopup="true"
								disableFocusRipple
								disableRipple
							>
								<img src={VerticalMenuIcon} alt="menu" />
							</IconButton>
							<div className="message">Hello</div>
							<Typography variant="body1">11:52 AM</Typography>
						</div> */}
					</div>
				</div>
			</div>
			<div className="popover-footer">
				<TextField
					placeholder="Type a message"
					id="chat"
					fullWidth
					className="with-icon-left"
					value={message}
					onKeyDown={(e) => {
						if (e.code == "Enter") {
							handleSendGroupMessage(
								groupChatBoxInfo.chatGroupId,
								message,
								groupChatBoxInfo.senderId,
								contextUser.userRoleId
							).then(() => setMessage(""));
						}
					}}
					onChange={(e) => setMessage(e.target.value)}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<IconButton edge="end" disableFocusRipple disableRipple>
									<img src={MoodIcon} alt="smiley" />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
				<IconButton
					className="contained"
					onClick={() => {
						handleSendGroupMessage(
							groupChatBoxInfo.chatGroupId,
							message,
							groupChatBoxInfo.senderId,
							contextUser.userRoleId
						).then(() => setMessage(""));
					}}
				>
					<img src={SendIcon} alt="send" />
				</IconButton>
				<IconButton className="outlined" onClick={handleClickAudioVideoOpen}>
					<img src={LinkIcon} alt="link" />
				</IconButton>
			</div>
			<ChatMenu
				handleChatMenuClose={handleChatMenuClose}
				isRight={isChatMenuRight}
				chatMenu={chatMenu}
				chatMenuOpen={chatMenuOpen}
			/>

			<AudioVideoRecordModal
				isAudioVideoModalOpen={isAudioVideoModalOpen}
				handleClickAudioVideoClose={handleClickAudioVideoClose}
				handleSendAudioVideo={handleSendAudioVideo}
			/>
		</div>
	);
};
