import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import {
	ArrowBack,
	CrossRedIcon,
	DeleteAllIcon,
	DownloadAllIcon,
	MailIconBlue,
} from "assests/images";
import {
	UploadDocument,
	WarningModal,
	DocumentList,
	PreviewFileModal,
} from "components";
import {
	IDocumentInfo,
	IDownloadFile,
	IFileDelete,
	ISendEmailFile,
} from "utility/interfaces";
import myCustomTheme from "app.theme";
// import JSZip from "jszip";
import { saveAs } from "file-saver";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import {
	openSucessErrorModal,
	createCommonAPICall,
	showLoader,
	hideLoader,
} from "utility/helpers";
import dashboardService from "services/dashboard-service";
import { AppRoutings } from "utility/enums/app-routings";
import { SendDocumetModal } from "./send-document-modal";
import moment from "moment";

interface EnhancedTableToolbarProps {
	selectedDocuments: readonly string[];
	handleOnClickSendEmail: any;
	handleOnClickDeleteAll: any;
	handleOnClickDownloadDocument: any;
}

type ComponentProps = {
	requestId: string;
	patientName: string;
	confirmationNo: string;
	documentList: IDocumentInfo[];
	handleOnClickBackCancel: any;
	callAfterUpload: any;
};

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
	const {
		selectedDocuments,
		handleOnClickSendEmail,
		handleOnClickDeleteAll,
		handleOnClickDownloadDocument,
	} = props;

	return (
		<Box>
			<Tooltip title="Download Selected">
				<Button
					variant="outlined"
					disableElevation
					disableRipple
					disableFocusRipple
					disabled={!(selectedDocuments.length > 0)}
					onClick={() => handleOnClickDownloadDocument(selectedDocuments, true)}
					className="min-w-111px min-w-sm-0"
				>
					<span>Download all</span>
					<img src={DownloadAllIcon} alt="download" />
				</Button>
			</Tooltip>
			<Tooltip title="Delete Selected">
				<Button
					variant="outlined"
					disableElevation
					disableRipple
					disableFocusRipple
					disabled={selectedDocuments.length > 0 ? false : true}
					className="min-w-111px min-w-sm-0"
					onClick={() =>
						selectedDocuments.length > 0 && handleOnClickDeleteAll()
					}
				>
					<span>Delete all</span>
					<img src={DeleteAllIcon} alt="delete" />
				</Button>
			</Tooltip>
			<Tooltip title="Send Mail">
				<Button
					variant="outlined"
					disableElevation
					disableRipple
					disableFocusRipple
					disabled={selectedDocuments.length > 0 ? false : true}
					className="min-w-111px min-w-sm-0"
					onClick={() =>
						selectedDocuments.length > 0 && handleOnClickSendEmail()
					}
				>
					<span>Send Mail</span>
					<img src={MailIconBlue} alt="mail" />
				</Button>
			</Tooltip>
		</Box>
	);
}

export function ViewDocuments(props: ComponentProps) {
	// props
	const {
		requestId,
		patientName,
		confirmationNo,
		documentList,
		handleOnClickBackCancel,
		callAfterUpload,
	} = props;

	// useStates
	const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isSendDocumentModalOpen, setIsSendDocumentModalOpen] = useState(false);
	const [documentsNeedToDeleteOrSend, setDocumentsNeedToDeleteOrSend] =
		useState<string[]>([]);
	const [email, setEmail] = useState("");
	const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
	const [previewFileUrl, setPreviewFileUrl] = useState("");

	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const navigate = useNavigate();

	const handleOnClickDeleteButton = async () => {
		console.log(documentsNeedToDeleteOrSend);
		const request: IFileDelete = {
			requestWiseFileId: documentsNeedToDeleteOrSend.toString(),
			requestId: requestId,
		};
		const data = await createCommonAPICall({
			requestBody: request,
			apiService: dashboardService.deleteFiles,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data.isSuccessfull) {
			openSucessErrorModal(
				setSuccessErrorContext,
				"Success",
				data.message,
				true,
				handleOnClickOk
			);
			callAfterUpload();
		}
	};

	const handleOnClickOk = () => {
		navigate(AppRoutings.ViewUploads.replace(":caseId", requestId));
	};

	const sendAgreementModalClose = () => {
		setIsSendDocumentModalOpen(false);
	};

	const onEmailChange = (email: string) => {
		setEmail(email);
	};

	const handleOnClickSendEmailButton = async () => {
		const request: ISendEmailFile = {
			requestWiseFileId: documentsNeedToDeleteOrSend.toString(),
			requestId: requestId,
			email: email,
		};
		const data = await createCommonAPICall({
			requestBody: request,
			apiService: dashboardService.sendFiles,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		console.log(data);
		if (data.isSuccessfull) {
			openSucessErrorModal(
				setSuccessErrorContext,
				"Success",
				data.message,
				true,
				handleOnClickOk
			);
			sendAgreementModalClose();
		}
	};

	const handleOnClickDownloadDocument = async (
		ids?: string[] | null,
		isDownloadMultiple?: boolean
	) => {
		console.log(ids);
		const request: IDownloadFile = {
			requestWiseFileId: ids!.toString(),
			requestId: requestId,
		};
		if (ids) {
			if (!isDownloadMultiple) {
				const data = await createCommonAPICall({
					requestBody: request,
					apiService: dashboardService.downloadFiles,
					showSuccessMessage: false,
					showErrorMessage: true,
					setSuccessErrorContext,
				});
				if (data.data.length == 1) {
					let link = document.createElement("a");
					link.href = data.data[0].filePath;
					link.download = data.data[0].fileName;
					link.target = "_blank";
					document.getElementById("downloadBtn")?.appendChild(link);
					link.click();
					link.parentNode?.removeChild(link);
				}
			} else if (isDownloadMultiple) {
				showLoader();
				dashboardService
					.downloadMultipleFiles(request)
					.then((data) => {
						let link = document.createElement("a");
						link.href = data;
						link.download = `Zip_${moment(new Date()).format(
							"MMDDyyyyhhmmss"
						)}.zip`;
						document.getElementById("downloadBtn")?.appendChild(link);
						link.click();
						link.parentNode?.removeChild(link);
						hideLoader();
					})
					.catch(() => hideLoader());
			}
		}
	};

	const handlePreviewFile = async (id: string) => {
		const request: IDownloadFile = {
			requestWiseFileId: [id].toString(),
			requestId: requestId,
		};
		const data = await createCommonAPICall({
			requestBody: request,
			apiService: dashboardService.downloadFiles,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data.data.length == 1) {
			setPreviewFileUrl(data.data[0].filePath);
		}
	};

	const handleOnClickDeleteDocument = (ids: string[]) => {
		setDocumentsNeedToDeleteOrSend(ids);
		setIsDeleteModalOpen(true);
	};
	const handleOnClickSendEmail = () => {
		setDocumentsNeedToDeleteOrSend(selectedDocuments);
		setIsSendDocumentModalOpen(true);
	};
	const handleOnClickDeleteAll = () => {
		console.log(selectedDocuments);
		setDocumentsNeedToDeleteOrSend(selectedDocuments);
		setIsDeleteModalOpen(true);
	};
	const handleOnSelectAllClick = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (event.target.checked) {
			const newSelected = documentList.map((n) =>
				n.requestWiseFileID.toString()
			);
			setSelectedDocuments(newSelected);
			return;
		}
		setSelectedDocuments([]);
	};

	const handleOnSelectDocument = (doc: number) => {
		const selectedIndex = selectedDocuments.indexOf(doc.toString());
		let newSelected: string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selectedDocuments, doc.toString());
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selectedDocuments.slice(1));
		} else if (selectedIndex === selectedDocuments.length - 1) {
			newSelected = newSelected.concat(selectedDocuments.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selectedDocuments.slice(0, selectedIndex),
				selectedDocuments.slice(selectedIndex + 1)
			);
		}
		setSelectedDocuments(newSelected);
	};

	return (
		<>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="request-container-box">
					<div className="request-header">
						<Typography variant="h2">Documents</Typography>
						<Button variant="outlined" onClick={handleOnClickBackCancel}>
							<img src={ArrowBack} alt="arrow" />
							Back
						</Button>
					</div>
					<Box className="request-box">
						<div className="confirm-number uploaded-data">
							<label>Patient Name</label>
							<Typography variant="h4">
								{patientName} <span>&nbsp;&#40;{confirmationNo}&#41;</span>
							</Typography>
						</div>
						<Typography variant="body1" className="instruction">
							Check here to review and add files that you or the Client/Member
							has attached to the Request.
						</Typography>
						<UploadDocument
							requestId={requestId}
							isCompensation={false}
							docType=""
							callAfterUpload={callAfterUpload}
						/>
						<div className="attachment">
							<Typography variant="h4">Documents</Typography>
							<Box className="selected-action-btn">
								<EnhancedTableToolbar
									handleOnClickSendEmail={handleOnClickSendEmail}
									selectedDocuments={selectedDocuments}
									handleOnClickDeleteAll={handleOnClickDeleteAll}
									handleOnClickDownloadDocument={handleOnClickDownloadDocument}
								/>
							</Box>
						</div>
						<DocumentList
							selectedDocuments={selectedDocuments}
							documentList={documentList}
							handleOnSelectAllClick={handleOnSelectAllClick}
							handleOnSelectDocument={handleOnSelectDocument}
							handleOnClickDeleteDocument={handleOnClickDeleteDocument}
							handleOnClickDownloadDocument={handleOnClickDownloadDocument}
							handleClickPreviewDocument={handlePreviewFile}
						/>
					</Box>
				</Box>
			</main>
			{/* Page Modals */}
			{/* Delete warning*/}
			<WarningModal
				isModalOpen={isDeleteModalOpen}
				handleOnClickCloseModal={() => {
					setDocumentsNeedToDeleteOrSend([]);
					setIsDeleteModalOpen(false);
				}}
				title="Delete"
				warningMessage="Are you sure, want to delete selected document(s)?"
				okButtonText="Delete"
				closeButtonText="Cancel"
				handleOnClickOk={handleOnClickDeleteButton}
				warningIcon={CrossRedIcon}
			/>
			{/* Send email modal*/}
			<SendDocumetModal
				isSendDocumentModalOpen={isSendDocumentModalOpen}
				handleOnClickSend={handleOnClickSendEmailButton}
				sendDocumentModalClose={sendAgreementModalClose}
				onSetEmail={onEmailChange}
			/>

			<PreviewFileModal
				isModelOpen={previewFileUrl !== ""}
				handleModalClose={() => setPreviewFileUrl("")}
				url={previewFileUrl}
			/>
		</>
	);
}
