import {
	Card,
	CardContent,
	CardHeader,
	Collapse,
	TableCell,
	TableRow,
	Typography,
} from "@mui/material";
import { ExpandMore } from "components";
import moment from "moment";
import React, { useState } from "react";
import { ITimesheetDetail } from "utility/interfaces";

type ComponentProps = {
	row: ITimesheetDetail;
	isSmallDevice: boolean;
};

export const TimeSheetDetailsTableRow = (props: ComponentProps) => {
	// Extract props
	const { row, isSmallDevice } = props;

	// useStates
	const [expanded, setExpanded] = useState(false);

	// Handle events
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<>
			{!isSmallDevice ? (
				<TableRow>
					<TableCell>
						{moment(row.timeSheetDate).format("MMM D, YYYY")}
					</TableCell>
					<TableCell>{row.shifts}</TableCell>
					<TableCell>{row.nightShifts}</TableCell>
					<TableCell>{row.houseCall}</TableCell>
					<TableCell>{row.houseCallNightWeekEnd}</TableCell>
					<TableCell>{row.phoneConsult}</TableCell>
					<TableCell>{row.phoneConsultNightWeekEnd}</TableCell>
					<TableCell>{row.batchTesting}</TableCell>
				</TableRow>
			) : (
				<Card className="bg-white">
					<div className="card-container">
						<ExpandMore
							expand={expanded}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label="show more"
						>
							<CardHeader
								title={
									<React.Fragment>
										<span>
											{moment(row.timeSheetDate).format("MMM D, YYYY")}
										</span>
									</React.Fragment>
								}
							/>
						</ExpandMore>
						<Collapse in={expanded} timeout="auto" unmountOnExit>
							<CardContent>
								<div>
									<Typography variant="h6">
										Shifts:&nbsp;
										<span>{row.shifts}</span>
									</Typography>
								</div>
								<div>
									<Typography variant="h6">
										Nightshift Weekend:&nbsp;
										<span>{row.nightShifts}</span>
									</Typography>
								</div>
								<div>
									<Typography variant="h6">
										HouseCall:&nbsp;
										<span>{row.houseCall}</span>
									</Typography>
								</div>
								<div>
									<Typography variant="h6">
										Housecall Nights Weekend:&nbsp;
										<span>{row.houseCallNightWeekEnd}</span>
									</Typography>
								</div>
								<div>
									<Typography variant="h6">
										Phone Consults:&nbsp;
										<span>{row.phoneConsult}</span>
									</Typography>
								</div>
								<div>
									<Typography variant="h6">
										Phone Consults Nights Weekend:&nbsp;
										<span>{row.phoneConsultNightWeekEnd}</span>
									</Typography>
								</div>
								<div>
									<Typography variant="h6">
										Batch Testing:&nbsp;
										<span>{row.batchTesting}</span>
									</Typography>
								</div>
							</CardContent>
						</Collapse>
					</div>
				</Card>
			)}
		</>
	);
};
