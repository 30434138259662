import { AxiosResponse } from "axios";
import { ProtectedEndPoints } from "./api-end-points";
import httpClient from "./base-service";
import {
	IDropDownList,
	IEditRequest,
	IHttpsResponse,
	ILoginRequest,
	IRegionSearchCriteria,
	ISendMessageRequest,
} from "utility/interfaces";

const getMyProfile = (): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.get(ProtectedEndPoints.GetMyProfile);

const resetPasswordFromMyProfile = (
	request: ILoginRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.ResetPasswordFromMyProfile, request);

const updateMyProfile = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.UpdateMyProfile, request);

const getRegionInfo = (
	request: IRegionSearchCriteria
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.GetRegionInfo, request);

const sendRequestDTYSupport = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.SendRequestDTYSupport, request);

const editRequest = (
	request: IEditRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.EditRequest, request);

const getLoggedInUserId = (): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.get(ProtectedEndPoints.GetLoggedInUserId);

const getPhysicianDropDownList = (): Promise<
	AxiosResponse<IHttpsResponse<IDropDownList[]>>
> => httpClient.get(ProtectedEndPoints.GetPhysicianDropDownList);

const requestToAdmin = (
	request: ISendMessageRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.RequestToAdmin, request);

const providerImageUpload = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.ProviderImageUpload, request);

const viewDownloadFile = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.ViewDownloadFile, request);

const changeLocationPreference = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.ChangeLocationPreference, request);

export default {
	getMyProfile,
	resetPasswordFromMyProfile,
	updateMyProfile,
	getRegionInfo,
	sendRequestDTYSupport,
	editRequest,
	getLoggedInUserId,
	getPhysicianDropDownList,
	requestToAdmin,
	providerImageUpload,
	viewDownloadFile,
	changeLocationPreference,
};
