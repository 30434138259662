import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	TableSortLabel,
	Tooltip,
	Button,
	MenuItem,
	Select,
	FormControl,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { visuallyHidden } from "@mui/utils";
import {
	IProviderInfo,
	IProviderInfoSearchCriteria,
	IDropDownList,
} from "utility/interfaces";
import { TablePagination, ProviderInfoTableRow } from "components";
import { Constants } from "utility/enums/constants";
import { SortOrder } from "utility/enums/sort-order";
import { createCommonAPICall } from "utility/helpers";
import roleService from "services/role-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { AppRoutings } from "utility/enums/app-routings";

type IPhysicianNotification = {
	physicianId: number;
	isNotificationStoped: boolean;
};

const ProviderInfo: React.FC = () => {
	// get query parameters
	const { caseId } = useParams();

	// Page level variables
	const navigate = useNavigate();

	// Use Context
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// useStates
	const [searchCriteria, setSearchCriteria] =
		useState<IProviderInfoSearchCriteria>({
			PageIndexId: 0,
			PageSize: Constants.DefaultTablePageSize.valueOf(),
			SortOrder: SortOrder.ascending,
			SortBy: "providerName",
			SearchBy: "",
			RegionId: 0,
		});
	// TODO: Dynamic
	const [providerInfo, setProviderInfo] = useState<IProviderInfo>({
		totalRecords: 0,
		providers: [],
	});
	const [regions, setRegions] = useState<IDropDownList[]>([]);

	const [isCheckBoxChanged, setIsCheckBoxChanged] = useState(false);
	const [physicianIds, setPhysicianIds] = useState<IPhysicianNotification[]>(
		[]
	);

	// Handled event(s)
	const handleOnPageNumberChange = (value: number) => {
		setSearchCriteria({ ...searchCriteria, PageIndexId: value });
	};

	const getNoteDetails = async () => {
		console.log(searchCriteria);
		const data = await createCommonAPICall({
			requestBody: searchCriteria,
			apiService: roleService.getProvidersInfo,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});

		console.log(data);
		setProviderInfo({
			providers: data.data.providers,
			totalRecords: data.data.totalRecords,
		});
		setRegions(data.data.regions);
	};
	const handleOnSelectRow = (providerId: string) => {
		const providers = providerInfo.providers.map((info) => {
			if (info.physicianId === providerId) {
				info.isNotificationStopped = !info.isNotificationStopped;
				const obj = {
					physicianId: parseInt(providerId),
					isNotificationStoped: info.isNotificationStopped,
				};
				if (physicianIds.some((p) => p.physicianId === obj.physicianId)) {
					let newPhyIds = physicianIds.filter(
						(p) => p.physicianId !== obj.physicianId
					);
					setPhysicianIds(newPhyIds);
				} else {
					setPhysicianIds([...physicianIds, obj]);
				}
			}
			return info;
		});

		setProviderInfo({ ...providerInfo, providers });
		// setIsCheckBoxChanged(true);
	};
	const handleOnChangeSortDirection = (sortBy: string) => {
		setSearchCriteria({
			...searchCriteria,
			SortBy: sortBy,
			SortOrder:
				searchCriteria.SortOrder === SortOrder.ascending
					? SortOrder.descending
					: SortOrder.ascending,
		});
	};

	const handleSaveChangedNotifications = async () => {
		console.log("Changed notification: ", physicianIds);
		const requestBody = {
			physicianNotifications: physicianIds,
		};
		const data = await createCommonAPICall({
			requestBody,
			apiService: roleService.changeProviderNotifications,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});

		if (data.isSuccessfull) {
			setPhysicianIds([]);
			getNoteDetails();
		}
	};

	// useEffects
	useEffect(() => {
		getNoteDetails();
	}, [searchCriteria]);

	useEffect(() => {
		console.log(physicianIds);
	}, [physicianIds]);

	return (
		<>
			<Box>
				<main className="main-content">
					<div
						className="overlay"
						onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
					></div>
					<Box className="request-container-box">
						<div className="request-header">
							<Typography variant="h2">Provider Information</Typography>
						</div>
						<Box className="request-box">
							<div className="table-header region-table-header responsive-header">
								<FormControl className="region-search">
									<Select
										labelId="region-label"
										id="region"
										value={searchCriteria.RegionId}
										name="region"
										label="Search by Region"
										onChange={(event) => {
											setSearchCriteria({
												...searchCriteria,
												RegionId: +event.target.value,
											});
										}}
										sx={{ marginBottom: "15px" }}
									>
										{regions.map((region) => {
											return (
												<MenuItem key={region.value} value={region.value}>
													{region.text}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
								<div
									className="tab-button-groups small-center"
									style={{ marginBottom: "15px" }}
								>
									{physicianIds.length > 0 && (
										<Tooltip title="Save changes">
											<Button
												variant="contained"
												style={{
													paddingTop: "9px",
													paddingBottom: "9px",
													marginRight: "15px",
												}}
												onClick={handleSaveChangedNotifications}
											>
												Save
											</Button>
										</Tooltip>
									)}
									<Tooltip title="Create new provider account">
										<Button
											variant="contained"
											style={{
												paddingTop: "9px",
												paddingBottom: "9px",
											}}
											onClick={() =>
												navigate(
													AppRoutings.CreateProvider.replace(
														":providerId",
														"TSpuh9yOuaA="
													)
												)
											}
										>
											Create Provider Account
										</Button>
									</Tooltip>
								</div>
							</div>
							<TableContainer
								className="upload-table-container"
								sx={{ display: { xs: "none", md: "block" } }}
							>
								<Table className="upload-table checkbox-table">
									<TableHead>
										<TableRow>
											<TableCell className="min-w-160" align="center">
												Stop Notification
											</TableCell>
											<TableCell
												sortDirection={searchCriteria.SortOrder}
												className="min-w-200"
											>
												<TableSortLabel
													active={searchCriteria.SortBy === "providerName"}
													direction={searchCriteria.SortOrder}
													onClick={() =>
														handleOnChangeSortDirection("providerName")
													}
												>
													Provider Name
													<Box component="span" sx={visuallyHidden}>
														{searchCriteria.SortOrder === SortOrder.descending
															? "sorted descending"
															: "sorted ascending"}
													</Box>
												</TableSortLabel>
											</TableCell>
											<TableCell
												sortDirection={searchCriteria.SortOrder}
												className="min-w-160"
											>
												<TableSortLabel
													active={searchCriteria.SortBy === "role"}
													direction={searchCriteria.SortOrder}
													onClick={() => handleOnChangeSortDirection("role")}
												>
													Role
													<Box component="span" sx={visuallyHidden}>
														{searchCriteria.SortOrder === SortOrder.descending
															? "sorted descending"
															: "sorted ascending"}
													</Box>
												</TableSortLabel>
											</TableCell>
											<TableCell
												sortDirection={searchCriteria.SortOrder}
												className="min-w-200"
											>
												<TableSortLabel
													active={searchCriteria.SortBy === "onCallStatus"}
													direction={searchCriteria.SortOrder}
													onClick={() =>
														handleOnChangeSortDirection("onCallStatus")
													}
												>
													On Call Status
													<Box component="span" sx={visuallyHidden}>
														{searchCriteria.SortOrder === SortOrder.descending
															? "sorted descending"
															: "sorted ascending"}
													</Box>
												</TableSortLabel>
											</TableCell>
											<TableCell
												sortDirection={searchCriteria.SortOrder}
												className="min-w-200"
											>
												<TableSortLabel
													active={searchCriteria.SortBy === "Status"}
													direction={searchCriteria.SortOrder}
													onClick={() => handleOnChangeSortDirection("Status")}
												>
													Status
													<Box component="span" sx={visuallyHidden}>
														{searchCriteria.SortOrder === SortOrder.descending
															? "sorted descending"
															: "sorted ascending"}
													</Box>
												</TableSortLabel>
											</TableCell>
											<TableCell align="center" sx={{ width: 142 }}>
												Actions
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{providerInfo.providers.map((row, index) => {
											return (
												<TableRow hover tabIndex={-1} key={index}>
													<ProviderInfoTableRow
														index={index}
														handleOnSelectRow={handleOnSelectRow}
														row={row}
														isSmallDevice={false}
													/>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
							<Box
								className="tabledata-cards-group"
								sx={{ display: { xs: "block", md: "none" } }}
							>
								{providerInfo.providers.map((row, index) => {
									return (
										<ProviderInfoTableRow
											index={index}
											handleOnSelectRow={handleOnSelectRow}
											row={row}
											isSmallDevice={true}
										/>
									);
								})}
							</Box>
							<div className="small-table-pagination">
								<TablePagination
									currentPageNumber={searchCriteria.PageIndexId}
									handleOnPageNumberChange={handleOnPageNumberChange}
									totalRecords={providerInfo?.totalRecords || 0}
									rowCount={providerInfo?.providers?.length || 0}
								/>
							</div>
						</Box>
					</Box>
				</main>
			</Box>
		</>
	);
};

export default ProviderInfo;
