import {
	Button,
	Card,
	CardContent,
	CardHeader,
	FormControl,
	InputAdornment,
	OutlinedInput,
	TableCell,
	TableRow,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { UploadcloudIcon } from "assests/images";
import { PreviewFileModal, WarningModal } from "components";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { UserContext } from "contexts/user-context";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import timehseetService from "services/timehseet-service";
import { AccountType } from "utility/enums/request-status";
import { createCommonAPICall } from "utility/helpers";
import { ITimesheetReimbursement } from "utility/interfaces";

type ComponentProps = {
	isBiWeekly: boolean;
	row: ITimesheetReimbursement;
	getFillTimeSheetData: any;
	isFinalized?: boolean;
	isSmallDevice: boolean;
};

export const FillReimbursementTableRow = (props: ComponentProps) => {
	// Extract props
	const { isBiWeekly, row, getFillTimeSheetData, isFinalized, isSmallDevice } =
		props;

	// Local variables
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const contextUser = useContext(UserContext);

	// State variables
	const [isViewDocumentModalOpen, setIsViewDocumentModalOpen] = useState(false);
	const [formValues, setFormValues] = useState<ITimesheetReimbursement>({
		providerTimesheetReimbursementId: 0,
		amount: 0,
		description: "",
		fileUrl: "",
		providerTimesheetId: 0,
		receiptPath: "",
		timesheetdate: "",
	});
	const [selectedFileNames, setSelectedFileNames] = useState<string[]>([]);
	const [isEdit, setIsEdit] = useState(false);
	const [descError, setDescError] = useState<string | null>(null);
	const [amntError, setAmntError] = useState<string | null>(null);
	const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);

	// Handle events
	const handleViewDocumentModalOpen = () => setIsViewDocumentModalOpen(true);

	const handleViewDocumentModalClose = () => setIsViewDocumentModalOpen(false);

	const handleOnChangeFormValues = (name: string, value: any) => {
		setFormValues({ ...formValues, [name]: value });
	};

	const handleUploadFile = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const fileNames: string[] = [];
		const formdata = new FormData();
		console.log(row);
		console.log(formValues);
		if (
			event.target.files &&
			event.target.files.length > 0 &&
			formValues.description !== "" &&
			formValues.amount.toString() != "" &&
			formValues.amount != 0
		) {
			setDescError(null);
			setAmntError(null);
			console.log(formValues);
			Array.prototype.forEach.call(event.target.files, (file: File) => {
				fileNames.push("C:\\fakepath\\" + file.name);
				formdata.append("file", file);
			});
			setSelectedFileNames(fileNames);

			formdata.append(
				"providerTimesheetId",
				row.providerTimesheetId.toString()
			);
			formdata.append("description", formValues.description);
			formdata.append("amount", formValues.amount.toString());

			const data = await createCommonAPICall({
				requestBody: formdata,
				apiService: timehseetService.uploadFileForOtherReimbursement,
				showErrorMessage: true,
				showSuccessMessage: false,
				setSuccessErrorContext,
			});

			if (data && data.isSuccessfull) {
				getFillTimeSheetData();
			}
		} else {
			if (formValues.description === "")
				setDescError("Please enter receipt description.");
			else setDescError(null);
			if (formValues.amount.toString() === "" || formValues.amount == 0)
				setAmntError("Please enter receipt amount.");
			else setAmntError(null);

			event.target.value = "";
		}
	};

	const updateReimbursement = async () => {
		if (
			formValues.description !== "" &&
			formValues.amount.toString() != "" &&
			formValues.amount != 0
		) {
			console.log(formValues);
			setDescError(null);
			setAmntError(null);

			const requestBody = {
				providerTimesheetReimbursementId: row.providerTimesheetReimbursementId,
				providerTimesheetId: row.providerTimesheetId,
				description: formValues.description,
				amount: formValues.amount,
			};

			const data = await createCommonAPICall({
				requestBody,
				apiService: timehseetService.editTimesheetReimbursement,
				showErrorMessage: true,
				showSuccessMessage: true,
				setSuccessErrorContext,
			});

			if (data && data.isSuccessfull) {
				setIsEdit(false);
				getFillTimeSheetData();
			}
		} else {
			if (formValues.description === "")
				setDescError("Please enter receipt description.");
			else setDescError(null);
			if (formValues.amount.toString() === "" || formValues.amount == 0)
				setAmntError("Please enter receipt amount.");
			else setAmntError(null);
		}
	};

	const clearFormValues = () => {
		setFormValues({
			providerTimesheetReimbursementId: 0,
			amount: 0,
			description: "",
			fileUrl: "",
			providerTimesheetId: 0,
			receiptPath: "",
			timesheetdate: "",
		});
	};

	const handleDeleteReimbursement = async () => {
		const requestBody = {
			providerTimesheetReimbursementId: row.providerTimesheetReimbursementId,
			providerTimesheetId: row.providerTimesheetId,
			fileName: row.receiptPath,
		};

		const data = await createCommonAPICall({
			requestBody,
			apiService: timehseetService.deleteReimbursement,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			clearFormValues();
			getFillTimeSheetData();
		}
	};

	// use Effects
	useEffect(() => {
		if (row.providerTimesheetReimbursementId > 0) {
			setFormValues(row);
		}
	}, []);

	return (
		<>
			{!isSmallDevice ? (
				<TableRow>
					{isBiWeekly && (
						<TableCell sx={{ maxWidth: 50 }} className="min-w-50">
							{moment(row.timesheetdate).format("MM/DD/YYYY")}
						</TableCell>
					)}
					<TableCell sx={{ maxWidth: 130 }} className="min-w-100">
						{row.description && !isEdit ? (
							row.description
						) : (
							<TextField
								className="form-table-field"
								value={formValues.description}
								error={descError ? true : false}
								helperText={descError}
								name="description"
								onChange={(e) =>
									handleOnChangeFormValues(e.target.name, e.target.value)
								}
							/>
						)}
					</TableCell>
					<TableCell sx={{ maxWidth: 130 }} className="min-w-100">
						{row.providerTimesheetReimbursementId > 0 && !isEdit ? (
							row.amount
						) : (
							<TextField
								className="form-table-field"
								value={formValues.amount}
								error={amntError ? true : false}
								helperText={amntError}
								name="amount"
								onChange={(e) =>
									handleOnChangeFormValues(e.target.name, e.target.value)
								}
							/>
						)}
					</TableCell>
					<TableCell sx={{ maxWidth: 130 }} className="min-w-100">
						{row.receiptPath ? (
							row.receiptPath
						) : (
							<>
								{isFinalized || contextUser.userRoleId === AccountType.Admin ? (
									<TextField
										className="form-table-field"
										disabled={true}
										name="amount"
									/>
								) : (
									<FormControl fullWidth variant="outlined">
										<OutlinedInput
											id="upload-file"
											type="text"
											disabled
											value={selectedFileNames}
											className="upload-input"
											placeholder="Select File"
											endAdornment={
												<InputAdornment position="end">
													<Tooltip title="Click Here To Upload">
														<Button
															variant="contained"
															component="label"
															disableElevation
															disableFocusRipple
															disableRipple
														>
															<img src={UploadcloudIcon} alt="upload" />
															<span>Upload</span>
															<input
																onChange={handleUploadFile}
																hidden
																accept="*"
																type="file"
																name="files"
															/>
														</Button>
													</Tooltip>
												</InputAdornment>
											}
											label="Password"
										/>
									</FormControl>
								)}
							</>
						)}
					</TableCell>
					<TableCell sx={{ maxWidth: 150 }} className="min-w-100">
						{row.receiptPath && (
							<>
								{isEdit ? (
									<>
										<Button
											variant="outlined"
											className="action-btn action-btn-primary"
											sx={{ marginRight: "10px" }}
											onClick={updateReimbursement}
										>
											Save
										</Button>
										<Button
											variant="outlined"
											className="action-btn action-btn-primary"
											onClick={() => {
												setFormValues(row);
												setIsEdit(false);
											}}
										>
											Cancel
										</Button>
									</>
								) : (
									<>
										{!isFinalized && (
											<>
												<Button
													variant="outlined"
													className="action-btn action-btn-primary"
													sx={{ marginRight: "10px" }}
													onClick={() => setIsEdit(true)}
												>
													Edit
												</Button>
												<Button
													variant="outlined"
													className="action-btn action-btn-primary"
													sx={{ marginRight: "10px" }}
													onClick={() => setIsDeleteWarningOpen(true)}
												>
													Delete
												</Button>
											</>
										)}

										<Button
											variant="outlined"
											className="action-btn action-btn-primary"
											onClick={handleViewDocumentModalOpen}
										>
											View
										</Button>
									</>
								)}
							</>
						)}
					</TableCell>
				</TableRow>
			) : (
				<Card className="bg-white">
					<div className="card-container">
						<CardHeader
							title={
								<React.Fragment>
									<span>{moment(row.timesheetdate).format("MMM D, YYYY")}</span>
								</React.Fragment>
							}
						/>
						<CardContent className="card-header-content">
							<Typography className="timesheet-form-rows">
								Item:&nbsp;
								<span>
									{row.description && !isEdit ? (
										row.description
									) : (
										<TextField
											className="form-table-field"
											value={formValues.description}
											error={descError ? true : false}
											helperText={descError}
											name="description"
											onChange={(e) =>
												handleOnChangeFormValues(e.target.name, e.target.value)
											}
										/>
									)}
								</span>
							</Typography>
							<Typography className="timesheet-form-rows">
								Amount:&nbsp;
								<span>
									{row.providerTimesheetReimbursementId > 0 && !isEdit ? (
										row.amount
									) : (
										<TextField
											className="form-table-field"
											value={formValues.amount}
											error={amntError ? true : false}
											helperText={amntError}
											name="amount"
											onChange={(e) =>
												handleOnChangeFormValues(e.target.name, e.target.value)
											}
										/>
									)}
								</span>
							</Typography>
							<Typography className="timesheet-form-rows">
								Bill:&nbsp;
								<span>
									{row.receiptPath ? (
										row.receiptPath
									) : (
										<>
											{isFinalized ||
											contextUser.userRoleId === AccountType.Admin ? (
												<TextField
													className="form-table-field"
													disabled={true}
													name="amount"
												/>
											) : (
												<FormControl
													fullWidth
													variant="outlined"
													className="form-table-field"
												>
													<OutlinedInput
														id="upload-file"
														type="text"
														disabled
														value={selectedFileNames}
														className="upload-input"
														placeholder="Select File"
														endAdornment={
															<InputAdornment position="end">
																<Tooltip title="Click Here To Upload">
																	<Button
																		variant="contained"
																		component="label"
																		disableElevation
																		disableFocusRipple
																		disableRipple
																	>
																		<img src={UploadcloudIcon} alt="upload" />
																		<span>Upload</span>
																		<input
																			onChange={handleUploadFile}
																			hidden
																			accept="*"
																			type="file"
																			name="files"
																		/>
																	</Button>
																</Tooltip>
															</InputAdornment>
														}
														label="Password"
													/>
												</FormControl>
											)}
										</>
									)}
								</span>
							</Typography>
							{row.receiptPath && (
								<div className="table-cell-btn-group">
									{isEdit ? (
										<>
											<Button
												variant="outlined"
												sx={{ marginRight: "10px" }}
												onClick={updateReimbursement}
											>
												Save
											</Button>
											<Button
												variant="outlined"
												onClick={() => {
													setFormValues(row);
													setIsEdit(false);
												}}
											>
												Cancel
											</Button>
										</>
									) : (
										<>
											{/* {!isFinalized && (
												<> */}
											<Button
												variant="outlined"
												sx={{ marginRight: "10px" }}
												onClick={() => setIsEdit(true)}
											>
												Edit
											</Button>
											<Button
												variant="outlined"
												sx={{ marginRight: "10px" }}
												onClick={() => setIsDeleteWarningOpen(true)}
											>
												Delete
											</Button>
											{/* </>
											)} */}

											<Button
												variant="outlined"
												onClick={handleViewDocumentModalOpen}
											>
												View
											</Button>
										</>
									)}
								</div>
							)}
						</CardContent>
					</div>
				</Card>
			)}

			{/* Page modals */}
			{row.receiptPath && (
				<PreviewFileModal
					isModelOpen={isViewDocumentModalOpen}
					handleModalClose={handleViewDocumentModalClose}
					url={row.fileUrl}
				/>
			)}
			<WarningModal
				title="Delete Confirmation"
				warningMessage="Are you sure you want to delete this File?"
				okButtonText="Confirm"
				closeButtonText="Cancel"
				isModalOpen={isDeleteWarningOpen}
				handleOnClickCloseModal={() => setIsDeleteWarningOpen(false)}
				handleOnClickOk={handleDeleteReimbursement}
			/>
		</>
	);
};
