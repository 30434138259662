import axios, { AxiosResponse } from "axios";
import {
	IAcceptcaseRequest,
	IAssignCaseInfo,
	IAssignCaseRequest,
	IChatUploadRequest,
	ICreateRequest,
	IDownloadFile,
	IFileDelete,
	IFileUpload,
	IFillPDFRequest,
	IFormRequest,
	IRegions,
	ISaveOrderDetailsRequest,
	ISaveRecommendationValues,
	ISendEmailFile,
	ISendMail,
	ICancelCaseInfo,
	ICancelCaseRequest,
	IDashboardInfo,
	IViewCaseInfo,
	IViewNoteInfo,
	ISaveViewNotes,
	IHttpsResponse,
	IDashboardSearchCriteria,
	IBlockCaseRequest,
	ISendLink,
	IRequestIdModel,
	IRequest,
	IDropDownList,
	ISendRecommendationRequest,
	IEncounter,
	IEmailAndPhoneEditRequest,
	IClearCaseRequest,
} from "utility/interfaces";
import { ProtectedEndPoints, UnProtectedEndPoints } from "./api-end-points";
import httpClient from "./base-service";

const getDashboardInfo = async (
	request: IDashboardSearchCriteria
): Promise<AxiosResponse<IHttpsResponse<IDashboardInfo>>> =>
	httpClient.post<IHttpsResponse<IDashboardInfo>>(
		ProtectedEndPoints.GetDashboardDetails,
		request
	);

const getViewCaseInfo = async (
	request: IRequestIdModel
): Promise<AxiosResponse<IHttpsResponse<IViewCaseInfo>>> =>
	httpClient.post<IHttpsResponse<IViewCaseInfo>>(
		ProtectedEndPoints.GetViewCaseInfo,
		request
	);

const getViewNoteInfo = async (
	request: IRequestIdModel
): Promise<AxiosResponse<IHttpsResponse<IViewNoteInfo>>> =>
	httpClient.post<IHttpsResponse<IViewNoteInfo>>(
		ProtectedEndPoints.GetViewNoteInfo,
		request
	);

const saveViewNotes = async (
	request: ISaveViewNotes
): Promise<AxiosResponse<IHttpsResponse<ISaveViewNotes>>> =>
	httpClient.post<IHttpsResponse<ISaveViewNotes>>(
		ProtectedEndPoints.SaveViewNotes,
		request
	);

const getCancelCaseModalInfo = async (
	request: IRequestIdModel
): Promise<AxiosResponse<IHttpsResponse<ICancelCaseInfo>>> =>
	httpClient.post<IHttpsResponse<ICancelCaseInfo>>(
		ProtectedEndPoints.GetCancelCaseInfo,
		request
	);

const getAssignCaseModalInfo = async (
	request: IRequestIdModel
): Promise<AxiosResponse<IHttpsResponse<IAssignCaseInfo>>> =>
	httpClient.post<IHttpsResponse<IAssignCaseInfo>>(
		ProtectedEndPoints.GetAssignCaseInfo,
		request
	);

const assignCase = async (
	request: IAssignCaseRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(ProtectedEndPoints.AssignCase, request);

const cancelCase = async (
	request: ICancelCaseRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(ProtectedEndPoints.CancelCase, request);

const blockCase = async (
	request: IBlockCaseRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(ProtectedEndPoints.BlockCase, request);

const sendLink = async (
	request: ISendLink
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(ProtectedEndPoints.SendLink, request);

const createRequest = async (
	request: ICreateRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.CreateRequest,
		request
	);

const sendEmail = async (
	request: ISendMail
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(ProtectedEndPoints.SendEmail, request);

const exportAll = async (
	request: IDashboardSearchCriteria
): Promise<AxiosResponse<IHttpsResponse<IRequest[]>>> =>
	httpClient.post<IHttpsResponse<IRequest[]>>(
		ProtectedEndPoints.ExportAll,
		request
	);

const getChatHistory = async (
	request: IDashboardSearchCriteria
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.getChatHistory,
		request
	);

const getRegions = async (
	request: IRegions
): Promise<AxiosResponse<IHttpsResponse<IRegions[]>>> =>
	httpClient.post<IHttpsResponse<IRegions[]>>(
		UnProtectedEndPoints.getRegions,
		request
	);

const acceptCase = async (
	request: IAcceptcaseRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(ProtectedEndPoints.acceptCase, request);

const getPhysicianListByRegion = async (
	request: IAssignCaseInfo
): Promise<AxiosResponse<IHttpsResponse<IDropDownList[]>>> =>
	httpClient.post<IHttpsResponse<IDropDownList[]>>(
		ProtectedEndPoints.getPhysicianListByRegion,
		request
	);

const uploadAudioVideoForChat = async (
	request: IChatUploadRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.uploadAudioVideoForChat,
		request
	);

const getSendOrderInfo = async (
	request: IFormRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.GetSendOrderInfo, request);

const fillPDFToSendOrder = async (
	request: IFillPDFRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.FillPDFToSendOrder, request);

const saveOrderDetails = async (
	request: ISaveOrderDetailsRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.SaveOrderDetails, request);

const getRecommendationForm = async (
	request: IFormRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.GetRecommendationForm, request);

const saveRecommendationForm = async (
	request: ISaveRecommendationValues
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.SaveRecommendationForm, request);

const getViewUploadsInfo = async (
	request: IRequestIdModel
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.getViewUploadsInfo,
		request
	);

const postUploadFiles = async (
	request: IFileUpload
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(ProtectedEndPoints.uploadFiles, request);

const deleteFiles = async (
	request: IFileDelete
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(ProtectedEndPoints.deleteFiles, request);

const sendFiles = async (
	request: ISendEmailFile
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(ProtectedEndPoints.sendFiles, request);

const downloadFiles = async (
	request: IDownloadFile
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.downloadFiles,
		request
	);

const downloadMultipleFiles = async (request: IDownloadFile) => {
	var data = await httpClient.post<Blob>(
		ProtectedEndPoints.downloadMultipleFiles,
		request,
		{
			responseType: "blob",
		}
	);
	return URL.createObjectURL(data.data);
};

const sendMailForRecommendationForm = (
	request: ISendRecommendationRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.SendMailForRecommendationForm, request);

const getEncounterFormInfo = (
	request: IFormRequest
): Promise<AxiosResponse<IHttpsResponse<IEncounter>>> =>
	httpClient.post(ProtectedEndPoints.GetEncounterFormInfo, request);

const saveEncounterForm = (
	request: IEncounter
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.SaveEncounterForm, request);

const sendMailForEncounterForm = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.SendMailForEncounterForm, request);

const saveCallTypeForRequest = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.SaveCallTypeForRequest, request);

const regenerateRecommendation = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.RegenerateRecommendation, request);

const getConcludeCareInfo = (
	request: IFormRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.GetConcludeCareInfo, request);

const confirmConcludeCare = (
	request: IFormRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.ConfirmConcludeCare, request);

const getCloseCaseInfo = (
	request: IFormRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.GetCloseCaseInfo, request);

const updateEmailAndPhone = (
	request: IEmailAndPhoneEditRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.UpdateEmailAndPhone, request);

const generateInvoice = (
	request: IFormRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.GenerateInvoice, request);

const sendAgreement = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.SendAgreement, request);

const clearCase = (
	request: IClearCaseRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.ClearCase, request);

const getBusinessByProfession = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.GetBusinessByProfession, request);

// WhatsApp services
const initiateChat = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.InitiateChat, request);

const getAllZipCodes = (): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.get(UnProtectedEndPoints.GetAllZipCodes);

const skipAgreement = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.SkipAgreement, request);

const updateProviderLocation = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.UpdateProviderLocation, request);

const sendDefaultMessage = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.SendDefaultMessage, request);

export default {
	getDashboardInfo,
	getViewCaseInfo,
	getViewNoteInfo,
	saveViewNotes,
	getCancelCaseModalInfo,
	cancelCase,
	getAssignCaseModalInfo,
	assignCase,
	blockCase,
	sendLink,
	createRequest,
	sendEmail,
	exportAll,
	getChatHistory,
	getRegions,
	acceptCase,
	getPhysicianListByRegion,
	uploadAudioVideoForChat,
	getSendOrderInfo,
	fillPDFToSendOrder,
	saveOrderDetails,
	getRecommendationForm,
	saveRecommendationForm,
	getViewUploadsInfo,
	postUploadFiles,
	deleteFiles,
	sendFiles,
	downloadFiles,
	sendMailForRecommendationForm,
	getEncounterFormInfo,
	saveEncounterForm,
	sendMailForEncounterForm,
	saveCallTypeForRequest,
	regenerateRecommendation,
	getConcludeCareInfo,
	confirmConcludeCare,
	getCloseCaseInfo,
	updateEmailAndPhone,
	generateInvoice,
	sendAgreement,
	clearCase,
	downloadMultipleFiles,
	initiateChat,
	getBusinessByProfession,
	getAllZipCodes,
	skipAgreement,
	updateProviderLocation,
	sendDefaultMessage,
};
