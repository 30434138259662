import { phoneNumberRegExp } from "utility/helpers";
import * as yup from "yup";

export const requestDTYSupportSchema = yup.object().shape({
	message: yup.string().required("Message is required."),
});

export const resetPasswordFromMyProfileSchema = yup.object().shape({
	password: yup.string().required("Please enter a password to reset."),
});

export const myProfileSchema = yup.object().shape({
	firstName: yup.string().required("Please enter your first name."),
	lastName: yup.string().required("Please enter your last name."),
	email: yup
		.string()
		.email("Please enter valid email.")
		.required("Please enter your email."),
	confirmEmail: yup
		.string()
		.email("Please enter valid email.")
		.required("Please confirm your email.")
		.oneOf([yup.ref("email")], "Please enter correct email"),
	// mobileNumber: yup.string().required("Please enter your phone number."),
	regionIds: yup.array().required("Please select at least one region."),
});

export const providerMyProfile = yup.object().shape({
	firstName: yup.string().required("Please enter your first name."),
	lastName: yup.string().required("Please enter your last name."),
	email: yup
		.string()
		.email("Please enter valid email.")
		.required("Please enter your email."),
	confirmEmail: yup
		.string()
		.email("Please enter valid email.")
		.required("Please confirm your email.")
		.oneOf([yup.ref("email")], "Please enter correct email"),
	// mobileNumber: yup.string().required("Please enter your phone number."),
	regionIds: yup.array().required("Please select at least one region."),
	businessName: yup.string().required("Business name is required."),
	businessWebsite: yup.string().required("Business website is required."),
});

export const createProviderSchema = yup.object().shape({
	password: yup
		.string()
		.required("Password is required.")
		.min(8, "Password must be between 8-12 characters long")
		.max(12, "Password must be between 8-12 characters long"),
	role: yup.number().moreThan(0, "Please select a role."),
	firstName: yup.string().required("FIrstname is required."),
	lastName: yup.string().required("Lastname is required."),
	email: yup
		.string()
		.email("Please enter valid email.")
		.required("Please enter your email."),
	// mobileNumber: yup.string().required("Please enter your phone number."),
	regionIds: yup.array().min(1, "Please select atleast one region."),
	businessName: yup.string().required("Business name is required."),
	businessWebsite: yup.string().required("Business website is required."),
});

export const createAdminSchema = yup.object().shape({
	password: yup
		.string()
		.required("Password is required.")
		.min(8, "Password must be between 8-12 characters long")
		.max(12, "Password must be between 8-12 characters long"),
	role: yup.number().moreThan(0, "Please select a role."),
	firstName: yup.string().required("FIrstname is required."),
	lastName: yup.string().required("Lastname is required."),
	email: yup
		.string()
		.email("Please enter valid email.")
		.required("Please enter your email."),
	confirmEmail: yup
		.string()
		.email("Please enter valid email.")
		.required("Please confirm your email.")
		.oneOf([yup.ref("email")], "Please enter correct email"),
	regionIds: yup.array().min(1, "Please select atleast one region."),
});

export const addEditPayrateSchema = yup.object().shape({
	payrate: yup
		.string()
		.required("Payrate is required.")
		.test("Number validation", "Value must be a valid number.", (value) => {
			if (value) {
				if (value.split(".").length > 2) return false;
				let bp1 = Number.parseFloat(value);
				return !isNaN(bp1) && bp1 >= 0;
			}
		}),
});
