import React from "react";
import { copyTextOnClipBoard } from "utility/helpers";
import { Tooltip } from "@mui/material";

type ComponentProps = {
	copyText?: string;
	children: any;
	canDoCopy?: boolean;
	text: string;
};
export function CustomCopyTooltip(props: ComponentProps) {
	const { text, children, canDoCopy, copyText } = props;

	return (
		<div className="cursor-pointer">
			<Tooltip
				title={
					<div
						className="cursor-pointer"
						onClick={() => {
							canDoCopy && copyTextOnClipBoard(copyText || "");
						}}
					>
						{text}
					</div>
				}
				arrow
				placement="bottom"
				enterDelay={100}
				leaveDelay={500}
			>
				<em>{children}</em>
			</Tooltip>
		</div>
	);
}
