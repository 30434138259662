import { yupResolver } from "@hookform/resolvers/yup";
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	TableCell,
	TextField,
} from "@mui/material";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import roleService from "services/role-service";
import { createCommonAPICall } from "utility/helpers";
import { IPayrateInfo } from "utility/interfaces";
import { addEditPayrateSchema } from "utility/validations";

type ComponentProps = {
	row: IPayrateInfo;
	getPayrates: any;
	isSmallDevice: boolean;
};

export const PayrateTableRow = (props: ComponentProps) => {
	// Extract props
	const { row, getPayrates, isSmallDevice } = props;

	// Context variables
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// state variables
	const [formValues, setFormvalues] = useState({
		providerId: row.providerId,
		providerPayRateId: row.providerPayRateId,
		medicalExpenseCategoryId: row.medicalExpenseCategoryId,
		payrate: row.payrate,
	});

	// Handle events
	const handleChangeFormValues = (value: any) => {
		if (value == "") setFormvalues({ ...formValues, payrate: 0 });
		else if (!isNaN(parseFloat(value)))
			setFormvalues({ ...formValues, payrate: parseFloat(value) });
	};

	const handleSaveValues = async () => {
		const data = await createCommonAPICall({
			requestBody: formValues,
			apiService: roleService.saveProviderPayrate,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			console.log(data.data);
			setFormvalues({ ...formValues, providerPayRateId: data.data.id });
			await getPayrates();
		}
	};

	// Validator
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(addEditPayrateSchema),
	});

	return (
		<>
			{isSmallDevice ? (
				<>
					<Card className="bg-white">
						<div className="card-container">
							<CardHeader
								title={
									<React.Fragment>
										<span>{row.categoryName}</span>
									</React.Fragment>
								}
							/>
							<CardContent className="card-header-content">
								<div style={{ display: "flex" }}>
									<TextField
										{...register("payrate")}
										error={errors.payrate?.message ? true : false}
										helperText={errors.payrate?.message?.toString()}
										fullWidth
										label="Payrate"
										name="payrate"
										value={formValues.payrate ? formValues.payrate : ""}
										onChange={(e) => handleChangeFormValues(e.target.value)}
									/>
									<Button
										variant="contained"
										sx={{
											width: "100px",
											height: "55px",
											marginLeft: "10px",
										}}
										onClick={handleSubmit(handleSaveValues)}
									>
										{row.providerPayRateId > 0 ? "Edit" : "Submit"}
									</Button>
								</div>
							</CardContent>
						</div>
					</Card>
				</>
			) : (
				<>
					<TableCell align="center">{row.categoryName}</TableCell>
					<TableCell align="center">
						<TextField
							{...register("payrate")}
							error={errors.payrate?.message ? true : false}
							helperText={errors.payrate?.message?.toString()}
							fullWidth
							sx={{ maxWidth: "400px", marginBottom: "0" }}
							label="Payrate"
							name="payrate"
							value={formValues.payrate ? formValues.payrate : ""}
							onChange={(e) => handleChangeFormValues(e.target.value)}
						/>
					</TableCell>
					<TableCell>
						<Button
							variant="contained"
							sx={{ width: "100px" }}
							onClick={handleSubmit(handleSaveValues)}
						>
							{row.providerPayRateId > 0 ? "Edit" : "Submit"}
						</Button>
					</TableCell>
				</>
			)}
		</>
	);
};
