import { Button, Grid, Hidden } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { LoginBanner, DarkIcon, LightIcon, Logo } from "assests/images";
import {
	LightDarkButton,
	AccountFooter,
	ForgotPasswordForm,
	LoginForm,
} from "components";
import { MenuDispatchContext } from "contexts/menu-context";
import { UserDispatchContext } from "contexts/user-context";
import React, { useContext, useEffect, useState } from "react";
import { IMenu } from "utility/interfaces";

const LoginPage: React.FC = () => {
	// Page level local variables
	const setUserContext = useContext(UserDispatchContext);
	const setMenuContext = useContext(MenuDispatchContext);

	// useStates
	const [isForgotPwdOpen, setIsForgotPwdOpen] = React.useState(false);
	const [theme, setTheme] = useState(
		localStorage.getItem("theme") || "light-theme"
	);

	// Handled events and functions
	const handleClickForgotPassword = () => setIsForgotPwdOpen(true);
	const handleClickBackToLogin = () => setIsForgotPwdOpen(false);

	useEffect(() => {
		setUserContext(null);
		const menuContext: IMenu = {
			selectedMenuKey: "",
			menus: [],
		};
		setMenuContext(menuContext);
	}, []);

	return (
		<>
			<section className="login-wrapper">
				<Grid container className="login-container">
					{/* <Hidden mdDown> */}
					<Grid item xs={12} md={6} laptop={6} xl={6} className="login-banner">
						<img src={LoginBanner} alt="Banner" />
					</Grid>
					{/* </Hidden> */}
					<Grid
						item
						xs={12}
						md={6}
						laptop={6}
						xl={6}
						className="login-content-container"
					>
						<LightDarkButton />
						<div className="login-content">
							<Tooltip title="Doctors To You">
								<a href="/" className="logo">
									<img src={Logo} alt="Doctors To You" />
								</a>
							</Tooltip>
							<div className="login-content-form">
								{isForgotPwdOpen ? (
									<ForgotPasswordForm
										handleClickBackToLogin={handleClickBackToLogin}
									></ForgotPasswordForm>
								) : (
									<LoginForm
										handleClickForgotPassword={handleClickForgotPassword}
									/>
								)}
							</div>
							<AccountFooter />
						</div>
					</Grid>
				</Grid>
			</section>
		</>
	);
};

export default LoginPage;
