import {
	Button,
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Tooltip,
	Typography,
} from "@mui/material";
import { AccountIcon, EyeClose, EyeOpen } from "assests/images";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import authService from "services/auth-service";
import tokenManager from "utility/auth-guards/token-manager";
import { AppRoutings } from "utility/enums/app-routings";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import {
	createCommonAPICall,
	getTabStatusWiseName,
	getUserLocation,
	menuPermissions,
} from "utility/helpers";
import { InformationTabTypes } from "utility/enums/request-status";
import { UserDispatchContext } from "contexts/user-context";
import { MenuDispatchContext } from "contexts/menu-context";
import {
	IHttpsResponse,
	ILoginRequest,
	ILoginResponse,
	IMenu,
	IUserDetails,
} from "utility/interfaces";
import { ChatCountDispatchContext } from "contexts/chat-count-context";

type ComponentProps = {
	handleClickForgotPassword: any;
};

export function LoginForm(props: ComponentProps) {
	// get query parameters
	const search = useLocation().search;
	const backUrl = new URLSearchParams(search).get("back");

	// Page level local variables
	const navigate = useNavigate();
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const setUserContext = useContext(UserDispatchContext);
	const setMenuContext = useContext(MenuDispatchContext);
	const setChatContext = useContext(ChatCountDispatchContext);

	// Extract Props
	const { handleClickForgotPassword } = props;

	// State variables
	const [userLocationLat, setUserLocationLat] = useState<number | null>(null);
	const [userLocationLong, setUserLocationLong] = useState<number | null>(null);

	// Handle events
	const isLoginFormValidate = () => {
		const errors: ILoginFormError = {
			passwordError: "",
			userNameError: "",
		};

		if (formValues.password.trim() === "")
			errors.passwordError = "Password is required.";
		if (formValues.userName.trim() === "")
			errors.userNameError = "User name is required.";

		setFormError(errors);

		return !(errors.passwordError !== "" || errors.userNameError !== "");
	};

	const handleClickLogin = async () => {
		if (!isLoginFormValidate()) return;
		const data: IHttpsResponse<ILoginResponse> = await createCommonAPICall({
			requestBody: formValues,
			apiService: authService.login,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
			isHideLoader: false,
		});

		if (data && data.isSuccessfull) {
			const userDetails: IUserDetails = {
				email: data.data.email,
				firstName: data.data.firstName,
				isOnCall: data.data.isOnCall,
				lastName: data.data.lastName,
				photo: data.data.photo,
				regionId: data.data.regionId,
				regionName: data.data.regionName,
				signature: data.data.signature,
				encUserName: data.data.encUserName,
				userRoleId: data.data.userRoleId,
				menuPermissions: "",
				isSuperAdmin: data.data.isSuperAdmin,
			};
			console.log("Menus:", data.data.menuPermission);
			const menus = data.data.menuPermission;
			// const menus = JSON.stringify(menuPermissions);
			tokenManager.setAuthorization(
				data.data.dtySecret,
				data.data.accessToken,
				JSON.stringify(userDetails),
				menus
			);
			setUserContext(userDetails);
			const menuContext: IMenu = {
				selectedMenuKey: "",
				menus: JSON.parse(menus) || [],
			};
			setMenuContext(menuContext);
			setChatContext(data.data.chatCount);
			navigate(
				backUrl
					? backUrl
					: AppRoutings.Dashboard.replace(
							":tabStatus",
							getTabStatusWiseName(InformationTabTypes.New).toLowerCase()
						),
				{ replace: true }
			);
		}
	};

	// page level interfaces
	interface ILoginFormError {
		userNameError: string;
		passwordError: string;
	}

	// useStates
	const [showPassword, setShowPassword] = useState(false);
	const [formValues, setFormValues] = useState<ILoginRequest>({
		userName: "",
		password: "",
	});

	const [formError, setFormError] = useState<ILoginFormError>({
		userNameError: "",
		passwordError: "",
	});

	// Handled events
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
		event.preventDefault();
	};
	const handleOnChangeFormValue = (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setFormValues({
			...formValues,
			[e.target.name]: e.target.value,
		});
	};

	return (
		<>
			<Typography variant="h1">Login to your account</Typography>
			<div className="login-form-control d-flex-column">
				<FormControl variant="outlined">
					<InputLabel htmlFor="username">Username</InputLabel>
					<OutlinedInput
						error={formError.userNameError !== "" ? true : false}
						id="username"
						className="with-icon"
						name="userName"
						onChange={handleOnChangeFormValue}
						autoFocus
						tabIndex={1}
						endAdornment={
							<InputAdornment position="end" tabIndex={-1}>
								<IconButton
									tabIndex={-1}
									edge="end"
									disableFocusRipple
									disableRipple
								>
									<img tabIndex={-1} src={AccountIcon} alt="account" />
								</IconButton>
							</InputAdornment>
						}
						label="Username"
					/>
					<FormHelperText error id="component-error-text">
						<>{formError.userNameError}</>
					</FormHelperText>
				</FormControl>
				<FormControl variant="outlined">
					<InputLabel htmlFor="password">Password</InputLabel>
					<OutlinedInput
						id="password"
						type={showPassword ? "text" : "password"}
						name="password"
						className="with-icon"
						error={formError.passwordError !== "" ? true : false}
						tabIndex={2}
						onChange={handleOnChangeFormValue}
						endAdornment={
							<Tooltip title={showPassword ? "Hide" : "Show"}>
								<InputAdornment position="end" tabIndex={-1}>
									<IconButton
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
										disableFocusRipple
										disableRipple
										tabIndex={-1}
									>
										{showPassword ? (
											<img tabIndex={-1} src={EyeClose} alt="show" />
										) : (
											<img tabIndex={-1} src={EyeOpen} alt="hide" />
										)}
									</IconButton>
								</InputAdornment>
							</Tooltip>
						}
						label="Password"
					/>
					<FormHelperText error id="component-error-text">
						<>{formError.passwordError}</>
					</FormHelperText>
				</FormControl>
				<Tooltip title="Log In">
					<Button variant="contained" onClick={handleClickLogin}>
						Log In
					</Button>
				</Tooltip>
				<Tooltip title="Forgot password?">
					<Button onClick={handleClickForgotPassword} className="back-login">
						Forgot password?
					</Button>
				</Tooltip>
			</div>
		</>
	);
}
