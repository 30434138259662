import {
	Box,
	Button,
	IconButton,
	Modal,
	Tooltip,
	Typography,
} from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import React, { useEffect, useRef } from "react";
import { pdfjs } from "react-pdf";
import { commonModalStyle } from "utility/helpers";
// import { PDFWorker } from "pdfjs-dist";
// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
// const PDFJS: PDFWorker = require("pdfjs-dist/build/pdf.worker.entry");

type ComponentProps = {
	isPDFModalOpen: boolean;
	handleClickPDFModalClose: any;
	filePath: string;
	handleClickSend: any;
};

export function SendOrderPDFModal(props: ComponentProps) {
	const {
		isPDFModalOpen,
		handleClickPDFModalClose,
		filePath,
		handleClickSend,
	} = props;

	const divRef = useRef<HTMLDivElement>(null);
	let pageRendering = false;
	let pageNumPending = 0;
	let pageLeftPending = 0;
	let pageTopPending = 0;

	useEffect(() => {
		if (filePath !== "") {
			pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

			console.log(pdfjs.GlobalWorkerOptions.workerSrc);

			console.log(filePath);
			pdfjs.getDocument(filePath).promise.then((pdfDoc: any) => {
				console.log(pdfDoc);
				if (pdfDoc.numPages > 0) {
					for (
						var i = 1;
						pdfDoc.numPages == 1 ? i <= pdfDoc.numPages : i < pdfDoc.numPages;
						i++
					) {
						// Initial/first page rendering
						renderPage(pdfDoc, i, 0, 0, true);
					}
				}
			});
		}
	}, [filePath]);

	const renderPage = (
		pdfDoc: any,
		pageNum: number,
		xOffset: number,
		yOffSet: number,
		renderAcroFieldSelect?: boolean
	) => {
		pageRendering = true;
		pdfDoc.getPage(pageNum).then((page: any) => {
			var scale = 0.8;
			var viewport = page.getViewport({ scale });
			console.log(viewport);
			var pageDisplayWidth = viewport.width;
			var pageDisplayHeight = viewport.height;
			const pageDivHolder = document.createElement("div");
			pageDivHolder.className = "pdfpage";
			pageDivHolder.style.width = `${pageDisplayWidth + 1}px`;
			pageDivHolder.style.height = `${pageDisplayHeight + 5}px`;
			divRef.current?.appendChild(pageDivHolder);

			// Prepare canvas using PDF page dimensions
			var canvas = document.createElement("canvas");
			var context = canvas.getContext("2d");
			canvas.id = "CanvasPdf";
			canvas.width = pageDisplayWidth;
			canvas.height = pageDisplayHeight;
			pageDivHolder.appendChild(canvas);

			// Render PDF page into canvas context
			var renderContext = {
				canvasContext: context,
				viewport: viewport,
			};
			var renderTask = page.render(renderContext);

			// Wait for rendering to finish
			renderTask.promise.then(function () {
				pageRendering = false;
				if (pageNumPending !== 0) {
					// New page rendering is pending
					renderPage(pdfDoc, pageNumPending, pageLeftPending, pageTopPending);
					pageNumPending = 0;
					pageLeftPending = 0;
					pageTopPending = 0;
				}
			});

			// // Prepare and populate form elements layer
			// var formDiv = document.createElement("div");
			// pageDivHolder.appendChild(formDiv);
			// setupForm(formDiv, page, viewport);

			if (pageNum >= pdfDoc.numPages) {
				return;
			}
			pageNum++;
			queueRenderPage(pdfDoc, pageNum, 0, 0);
		});
	};

	function queueRenderPage(
		pdfDoc: any,
		num: number,
		xOffset: number,
		yOffset: number
	) {
		if (pageRendering == true) {
			pageNumPending = num;
			pageLeftPending = xOffset;
			pageTopPending = yOffset;
		} else {
			renderPage(pdfDoc, num, xOffset, yOffset, false);
		}
	}

	return (
		<>
			<Modal
				open={isPDFModalOpen}
				onClose={handleClickPDFModalClose}
				className="send-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">Send Order</Typography>
						<IconButton onClick={handleClickPDFModalClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<div
							ref={divRef}
							id="pdfContainer"
							className="scrollable-modal"
						></div>
						{/* <Document file={filePath}>
							<Page pageNumber={1} />
						</Document> */}
						<Box className="modal-footer">
							<Button variant="contained" onClick={handleClickSend}>
								<Tooltip title={"Send"}>
									<p>Send</p>
								</Tooltip>
							</Button>
							<Button variant="outlined" onClick={handleClickPDFModalClose}>
								<Tooltip title={"Cancel"}>
									<p>Cancel</p>
								</Tooltip>
							</Button>
						</Box>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
