import {
	Box,
	Button,
	FormHelperText,
	IconButton,
	Modal,
	Typography,
} from "@mui/material";
import { CloseWhiteIcon, SignWatermark } from "assests/images";
import React, { useEffect, useRef, useState } from "react";
import { commonModalStyle } from "utility/helpers";
import SignaturePad from "react-signature-canvas";
import ReactSignatureCanvas from "react-signature-canvas";

type ComponentProps = {
	isOpen: boolean;
	handleClose: any;
	handleSubmit: any;
};

export const CreateSignatureModal = (props: ComponentProps) => {
	// Page variables
	const sigCanvas = useRef<ReactSignatureCanvas | null>(null);

	// Extract props
	const { isOpen, handleClose, handleSubmit } = props;

	// State Variables
	const [signatureError, setSignatureError] = useState("");

	// Handle events
	const handleOnClickClear = () => {
		setSignatureError("");
		sigCanvas.current?.clear();
	};

	const handleOnClickSubmit = () =>
		sigCanvas.current?.getTrimmedCanvas().toBlob((blob) => {
			if (blob && blob.size > 81) {
				setSignatureError("");
				handleSubmit(blob);
			} else {
				setSignatureError("Please draw a signature to submit.");
			}
		}, "image/jpg");

	const handleOnClose = () => {
		setSignatureError("");
		handleClose();
	};

	return (
		<>
			<Modal open={isOpen} onClose={handleOnClose} className="send-modal">
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">Please Sign</Typography>
						<IconButton onClick={handleOnClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<div>
							<div className="signature-pad">
								<img src={SignWatermark} className="sign-label" />
								<SignaturePad
									ref={sigCanvas}
									canvasProps={{ className: "signature-area" }}
								/>
							</div>
							<FormHelperText error>{signatureError}</FormHelperText>
						</div>
						<div className="modal-footer">
							<Button variant="contained" onClick={handleOnClickSubmit}>
								Submit
							</Button>
							<Button variant="outlined" onClick={handleOnClickClear}>
								Clear Signature
							</Button>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
};
