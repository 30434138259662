import { Box, Typography, Button } from "@mui/material";
import { ArrowBack } from "assests/images";
import { HaloRequestsTable } from "components";
import React from "react";
import { useNavigate } from "react-router-dom";

const HaloRequests: React.FC = () => {
	// Page level variable
	const navigate = useNavigate();

	return (
		<Box>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="tab-item-header">
					<Typography variant="h2">Halo Requests</Typography>
					<Button variant="outlined" onClick={() => navigate(-1)}>
						<img src={ArrowBack} alt="arrow" />
						Back
					</Button>
				</Box>
				<Box className="request-box">
					<div className="attachment"></div>
					<HaloRequestsTable />
				</Box>
			</main>
		</Box>
	);
};

export default HaloRequests;
