import * as yup from "yup";

export const cresteRoleSchema = yup.object().shape({
	name: yup.string().required("Role name is required."),
	accountType: yup.string().required("Please select account type"),
});

export const contactProviderSchema = yup.object().shape({
	message: yup.string().required("Message is required."),
});

export const addShiftSchema = yup.object().shape({
	region: yup
		.array()
		.of(yup.number())
		.min(1, "Please select at least one region."),
	physicianId: yup.number().moreThan(0, "Please select a physician"),
});
