import React from "react";
import { Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "utility/auth-guards/protected-route";
import { UnProtectedRoute } from "utility/auth-guards/unprotected-route";
import { AppRoutes } from "utility/auth-guards/app-routes";
import { AppRoutings } from "utility/enums/app-routings";
import { RouterType } from "utility/enums/router-type";
import { AccessDenied, PageNotFound, Layout } from "components";

export const RouterConfig: React.FC = () => {
	return (
		<Routes>
			{AppRoutes.filter(
				(route) => route.routeType === RouterType.unprotectedRoute
			).map((item) => {
				return (
					<Route
						key={item.id}
						path={item.path}
						element={<UnProtectedRoute element={<item.element />} />}
					/>
				);
			})}
			<Route path="/" element={<Layout />}>
				{AppRoutes.filter(
					(route) => route.routeType === RouterType.protectedRoute
				).map((item) => {
					return (
						<Route
							key={item.id}
							path={item.path}
							element={
								<ProtectedRoute
									element={<item.element />}
									menuKey={item.menuKey}
								/>
							}
						/>
					);
				})}
			</Route>
			<Route path={AppRoutings.NotFound} element={<PageNotFound />} />
			<Route path={AppRoutings.AccessDenied} element={<AccessDenied />} />

			{/* <Route
				path={AppRoutings.DesignerDashboard}
				element={<DesignerDashboard />}
			/>
			<Route
				path={AppRoutings.DesignerViewNotes}
				element={<DesignerViewNotes />}
			/>
			<Route
				path={AppRoutings.DesignerViewCase}
				element={<DesignerViewCase />}
			/> */}
			{/* Designer-Routes ------*/}
			{/* <Route
				path={AppRoutings.DesignerDashboard}
				element={<DesignerDashboard />}
			/>
			<Route path={AppRoutings.ViewNotes} element={<ViewNotes />} />
			<Route path={AppRoutings.Root} element={<DesignerLoginPage />} />
			<Route
				path={AppRoutings.CreateRequest1}
				element={<DesignerCreateRequest />}
			/>
			<Route path={AppRoutings.ViewUploads} element={<DesignerViewUploads />} />
			<Route path={AppRoutings.Orders} element={<DesignerOrders />} />
			<Route path={AppRoutings.DoctorsNote} element={<DesignerDoctorsNote />} />
			<Route path={AppRoutings.ChatHistory} element={<DesignerChatHistory />} />
			<Route
				path={AppRoutings.ConcludeCare}
				element={<DesignerConcludeCare />}
			/>
			<Route path={AppRoutings.CloseCase} element={<DesignerCloseCase />} />
			<Route
				path={AppRoutings.ItemizedCosts}
				element={<DesignerItemizedCosts />}
			/>*/}
		</Routes>
	);
};
