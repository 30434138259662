import React from "react";
import { Box, Typography } from "@mui/material";
import { VendorTable } from "components";

const Vendors: React.FC = () => {
	return (
		<>
			<Box>
				<div className="chatbox-overlay"></div>
				<main className="main-content">
					<div
						className="overlay"
						onClick={() => document.body.classList.toggle("sidebar-toggle")}
					></div>
					<Box className="tab-item-header">
						<Typography variant="h2">Vendor(s)</Typography>
					</Box>
					<VendorTable />
				</main>
			</Box>
		</>
	);
};

export default Vendors;
