import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	FormControl,
	FormGroup,
	IconButton,
	Modal,
	Tooltip,
	Typography,
} from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import { CheckBoxComponent } from "components";
import { commonModalStyle } from "utility/helpers";
import { IHistory, IDownload } from "utility/interfaces";
import { toast } from "react-toastify";

type ComponentProps = {
	modalOpen: boolean;
	onModalClose: any;
	row: IHistory;
};

export function DownloadDocModal(props: ComponentProps) {
	// Extract Props
	const { modalOpen, onModalClose, row } = props;

	// useStates
	const [formValues, setFormValues] = useState<IDownload>({
		isSelectEncounter: false,
		isSelectInsurance: false,
	});

	const handleOnClickDownload = () => {
		if (!formValues.isSelectEncounter && !formValues.isSelectInsurance) {
			toast.error("Please select atleast one document type.");
			return;
		}
		console.log(formValues);
	};

	// useEffects
	useEffect(() => {
		setFormValues({
			isSelectEncounter: false,
			isSelectInsurance: false,
		});
	}, [modalOpen]);

	return (
		<>
			<Modal
				open={modalOpen}
				onClose={onModalClose}
				className="send-modal select-input-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">Download form</Typography>
						<IconButton onClick={onModalClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<FormControl component="fieldset" className="radio-inputs">
							<FormGroup row>
								<CheckBoxComponent
									label="Encounter Form"
									isChecked={formValues.isSelectEncounter}
									handleOnChangeCheckbox={() =>
										setFormValues({
											...formValues,
											isSelectEncounter: !formValues.isSelectEncounter,
										})
									}
								/>
								<CheckBoxComponent
									label="Insurance Form"
									isChecked={formValues.isSelectInsurance}
									handleOnChangeCheckbox={() =>
										setFormValues({
											...formValues,
											isSelectInsurance: !formValues.isSelectInsurance,
										})
									}
								/>
							</FormGroup>
						</FormControl>
						<div className="modal-footer">
							<Tooltip title="Download">
								<Button
									variant="contained"
									disabled={
										!formValues.isSelectEncounter &&
										!formValues.isSelectInsurance
									}
									onClick={handleOnClickDownload}
								>
									Download
								</Button>
							</Tooltip>
							<Tooltip title="Cancel">
								<Button variant="outlined" onClick={onModalClose}>
									Cancel
								</Button>
							</Tooltip>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
