import {
	Button,
	FormControl,
	InputAdornment,
	OutlinedInput,
	Tooltip,
} from "@mui/material";
import { UploadcloudIcon } from "assests/images";
import React, { useState, useContext } from "react";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { createCommonAPICall } from "utility/helpers";
import dashboardService from "services/dashboard-service";
import { IFileUpload } from "utility/interfaces";

type ComponentProps = {
	requestId: string;
	isCompensation: any;
	docType: string;
	callAfterUpload: any;
};

export function UploadDocument(props: ComponentProps) {
	// props
	const { requestId, isCompensation, docType } = props;

	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// useStates
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
	const [selectedFileNames, setSelectedFileNames] = useState<string[]>([]);

	// Handled event(s)
	const onSelectNewFiles = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const fileNames: string[] = [];
		const files: File[] = [];
		const formdata = new FormData();
		if (event.target.files && event.target.files.length > 0) {
			Array.prototype.forEach.call(event.target.files, (file: File) => {
				fileNames.push("C:\\fakepath\\" + file.name);
				files.push(file);
			});
			setSelectedFileNames(fileNames);
			setSelectedFiles(files);
			// files.forEach((fileItem, i) => formdata.append(`files[${i}]`, fileItem));
			for (var i = 0; i < event.target.files.length; i++) {
				formdata.append("files", event.target.files[i]);
			}
		}

		formdata.append("RequestId", requestId);
		formdata.append("RequestGuid", "");
		formdata.append("docType", docType != "" ? docType : "12");
		formdata.append("isCompensation", isCompensation);
		console.log(formdata.get("files"));
		console.log(formdata.get("RequestId"));
		console.log(formdata.get("isCompensation"));

		const request: IFileUpload = {
			files: files,
			requestId: requestId,
			requestGuid: "",
			isCompensation: isCompensation,
			docType: docType,
		};
		console.log(request);
		const data = await createCommonAPICall({
			requestBody: formdata,
			apiService: dashboardService.postUploadFiles,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		setSelectedFileNames([]);
		console.log(data.data.fileNames);
		props.callAfterUpload();
	};

	return (
		<>
			<FormControl fullWidth variant="outlined">
				<OutlinedInput
					id="upload-file"
					type="text"
					disabled
					value={selectedFileNames}
					className="upload-input"
					placeholder="Select File"
					endAdornment={
						<InputAdornment position="end">
							<Tooltip title="Click Here To Upload">
								<Button
									variant="contained"
									component="label"
									disableElevation
									disableFocusRipple
									disableRipple
								>
									<img src={UploadcloudIcon} alt="upload" />
									<span>Upload</span>
									<input
										onChange={onSelectNewFiles}
										hidden
										accept="*"
										multiple
										type="file"
										name="files"
									/>
								</Button>
							</Tooltip>
						</InputAdornment>
					}
					label="Password"
				/>
			</FormControl>
		</>
	);
}
