import { Link, Menu, MenuItem } from "@mui/material";
import { ChatIllustration } from "assests/images";
import { UploadfileIcon, UploadfileIconDark } from "assests/images";
import React from "react";
import { useNavigate } from "react-router-dom";
import { AppRoutings } from "utility/enums/app-routings";
import { RequestStatus } from "utility/enums/request-status";
import { IPatientRecordHistory, ISearchCriteria } from "utility/interfaces";

type ComponentProps = {
	row: IPatientRecordHistory;
	tableMenuOpen: boolean;
	tableUser: HTMLElement | null;
	handleTableMenuClose: any;
	searchCriteria: ISearchCriteria;
	patientId: string;
	handleOpenChatBox: any;
};

export const PatientRecordTableRowMenu = (props: ComponentProps) => {
	// Extract props
	const {
		tableUser,
		tableMenuOpen,
		handleTableMenuClose,
		row,
		searchCriteria,
		patientId,
		handleOpenChatBox,
	} = props;

	// Page level variables
	const navigate = useNavigate();

	return (
		<Menu
			id="table-menu"
			anchorEl={tableUser}
			open={tableMenuOpen}
			className="new-table-menu"
			onClose={handleTableMenuClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
		>
			<MenuItem
				onClick={() => {
					navigate(AppRoutings.ViewCase.replace(":caseId", row.queryString), {
						state: {
							backURL: AppRoutings.PatientRecord.replace(
								":patientId",
								patientId
							),
							searchCriteria,
						},
					});
				}}
			>
				<Link>View Case</Link>
			</MenuItem>
			{row.status !== RequestStatus.Unassigned && (
				<MenuItem
					onClick={() => {
						handleOpenChatBox(
							row.userId,
							row.requestId,
							"Admin",
							row.confirmationNumber
						);
						handleTableMenuClose();
					}}
				>
					<Link>Chat</Link>
				</MenuItem>
			)}
			<MenuItem
				onClick={() => {
					navigate(
						AppRoutings.ViewUploads.replace(":caseId", row.queryString),
						{
							state: {
								backURL: AppRoutings.PatientRecord.replace(
									":patientId",
									patientId
								),
								searchCriteria,
							},
						}
					);
				}}
			>
				<Link>{row.fileCount} Documents</Link>
			</MenuItem>
		</Menu>
	);
};
