import { Box, IconButton, Modal, Typography } from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import React from "react";
import { commonModalStyle } from "utility/helpers";

type ComponentProps = {
	isModalOpen: boolean;
	handleModalClose: any;
	imageSrc: string;
};

export const PreviewImageModal = (props: ComponentProps) => {
	// Extract props
	const { isModalOpen, handleModalClose, imageSrc } = props;

	return (
		<>
			<Modal
				open={isModalOpen}
				onClose={handleModalClose}
				className="send-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">Show Image</Typography>
						<IconButton onClick={handleModalClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body scrollable-modal">
						<div className="modal-image">
							<img src={imageSrc} />
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
};
