import {
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Box,
} from "@mui/material";
import React from "react";
import { FillReimbursementTableRow } from "./fill-reimbursement-table-row";
import { ITimesheetReimbursement } from "utility/interfaces";

type ComponentProps = {
	isBiWeekly: boolean;
	timesheetReimbursementInfo: ITimesheetReimbursement[];
	getFillTimeSheetData: any;
	isFinalized?: boolean;
};

export const FillReimbursementTable = (props: ComponentProps) => {
	// Extract props
	const {
		isBiWeekly,
		timesheetReimbursementInfo,
		getFillTimeSheetData,
		isFinalized,
	} = props;

	return (
		<>
			<TableContainer
				className="upload-table-container"
				sx={{ display: { xs: "none", md: "block" } }}
			>
				<Table className="upload-table">
					<TableHead>
						<TableRow>
							{isBiWeekly && <TableCell className="min-w-50">Date</TableCell>}
							<TableCell className="min-w-100">Item</TableCell>
							<TableCell className="min-w-100">Amount</TableCell>
							<TableCell className="min-w-100">Bill</TableCell>
							<TableCell className="min-w-100">Action</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{timesheetReimbursementInfo.map((row, index) => (
							<FillReimbursementTableRow
								isBiWeekly={isBiWeekly}
								row={row}
								key={index}
								getFillTimeSheetData={getFillTimeSheetData}
								isFinalized={isFinalized}
								isSmallDevice={false}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Box
				className="tabledata-cards-group custom-card-margin"
				sx={{ display: { xs: "block", md: "none" } }}
			>
				{timesheetReimbursementInfo.map((row, index) => (
					<FillReimbursementTableRow
						isBiWeekly={isBiWeekly}
						row={row}
						key={index}
						getFillTimeSheetData={getFillTimeSheetData}
						isFinalized={isFinalized}
						isSmallDevice={true}
					/>
				))}
			</Box>
		</>
	);
};
