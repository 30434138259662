import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { ArrowBack } from "assests/images";
import {
	FillDailyTimeSheetTable,
	FillReimbursementTable,
	WarningModal,
} from "components";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import timehseetService from "services/timehseet-service";
import tokenManager from "utility/auth-guards/token-manager";
import { AppRoutings } from "utility/enums/app-routings";
import { createCommonAPICall } from "utility/helpers";
import {
	IFillTimesheetDetailsInfo,
	ITimesheetReimbursement,
} from "utility/interfaces";
import { DailyTimesheetSchema } from "utility/validations";

const FillDailyTimeSheet: React.FC = () => {
	// Use Param
	const data = useParams().data;

	// Page level local variables
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const navigate = useNavigate();

	// State variables
	const [isReceiptVisible, setIsReceiptVisible] = useState(false);
	const [fillTimesheetDetailsInfo, setFillTimesheetDetailsInfo] =
		useState<IFillTimesheetDetailsInfo | null>(null);
	const [timesheetReimbursementInfo, setTimeSheetReimbursementInfo] = useState<
		ITimesheetReimbursement[]
	>([]);
	const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
	const [formValues, setFormValues] = useState<IFillTimesheetDetailsInfo>({
		physicianId: 0,
		providerTimesheetId: 0,
		timeSheetDate: "",
		totalHours: 0,
		shiftDuration: 0,
		housecallDuration: 0,
		phoneConsultDuration: 0,
		weekendHoliday: false,
	});

	// Handle Events
	const getDailyTimeSheetData = async () => {
		if (data) {
			console.log(data);
			const requestBody = {
				data: data,
			};
			const res = await createCommonAPICall({
				requestBody,
				apiService: timehseetService.getDailyTimeSheetDetails,
				showErrorMessage: true,
				showSuccessMessage: false,
				setSuccessErrorContext,
			});

			if (res && res.isSuccessfull) {
				if (res.data.isAlreadySubmitted) {
					navigate(AppRoutings.SubmitTimesheetSuccess);
				} else {
					console.log(res);
					setFillTimesheetDetailsInfo(res.data);
					setFormValues(res.data);
					setTimeSheetReimbursementInfo(
						res.data.providerTimeSheetReimbursementView
					);
					if (res.data.providerTimeSheetReimbursementView.length > 1)
						setIsReceiptVisible(true);
				}
			} else {
				tokenManager.clearCookiesAndLocalStorage();
				navigate(AppRoutings.Root);
			}
		}
	};

	const handleOnChangeFormValues = (name: string, value: any) => {
		setFormValues({ ...formValues, [name]: value });
	};

	const handleClickSave = async () => {
		const timesheetInfos: IFillTimesheetDetailsInfo[] = [];
		timesheetInfos.push({
			providerTimesheetId: formValues.providerTimesheetId,
			physicianId: formValues.physicianId,
			totalHours: formValues.totalHours,
			shiftDuration: formValues.shiftDuration,
			timeSheetDate: formValues.timeSheetDate,
			housecallDuration: parseInt(
				formValues.housecallDuration?.toString() ?? "0"
			),
			phoneConsultDuration: parseInt(
				formValues.phoneConsultDuration?.toString() ?? "0"
			),
			weekendHoliday: formValues.weekendHoliday,
		});
		const requestBody = { providerTimesheetViews: timesheetInfos };

		console.log(requestBody);

		const data = await createCommonAPICall({
			requestBody,
			apiService: timehseetService.submitTimesheetData,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			console.log(data);
			navigate(AppRoutings.SubmitTimesheetSuccess);
		} else {
			tokenManager.clearCookiesAndLocalStorage();
			navigate(AppRoutings.Root);
		}
	};

	const handleOnClickBackCancel = () => {
		navigate(AppRoutings.Dashboard.replace(":tabStatus", "new"));
	};

	// Form validations
	const formValidator = useForm({
		values: formValues,
		resolver: yupResolver(DailyTimesheetSchema),
	});

	// Use effects
	useEffect(() => {
		getDailyTimeSheetData();
	}, []);

	return (
		<Box>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="request-container-box">
					<div className="request-header">
						<Typography variant="h2">
							Today's Timesheet for{" "}
							{moment(fillTimesheetDetailsInfo?.timeSheetDate).format(
								"MM/DD/YYYY"
							)}
						</Typography>
						<Button variant="outlined" onClick={handleOnClickBackCancel}>
							<img src={ArrowBack} alt="arrow" />
							Back
						</Button>
					</div>
					<Box className="request-box">
						{fillTimesheetDetailsInfo && (
							<FillDailyTimeSheetTable
								fillTimesheetDetailsInfo={fillTimesheetDetailsInfo}
								formValidator={formValidator}
								formValues={formValues}
								handleOnChangeFormValues={handleOnChangeFormValues}
							/>
						)}
					</Box>
					<br />
					<Button
						variant="contained"
						onClick={() => setIsReceiptVisible(!isReceiptVisible)}
					>
						Add Receipts
					</Button>
					{isReceiptVisible && (
						<Box className="request-box another-table">
							<FillReimbursementTable
								isBiWeekly={false}
								timesheetReimbursementInfo={timesheetReimbursementInfo}
								getFillTimeSheetData={getDailyTimeSheetData}
							/>
						</Box>
					)}
					<div className="request-btn-group">
						<Button
							variant="contained"
							onClick={() => setIsSubmitModalOpen(true)}
						>
							Submit
						</Button>
					</div>
				</Box>
			</main>

			{/* Page Modal */}
			<WarningModal
				isModalOpen={isSubmitModalOpen}
				handleOnClickCloseModal={() => setIsSubmitModalOpen(false)}
				okButtonText="Submit"
				closeButtonText="Cancel"
				title="Submit Confirmation"
				warningMessage="Are you sure you want to submit ? Once you submit you cannot make changes."
				handleOnClickOk={formValidator.handleSubmit(handleClickSave)}
			/>
		</Box>
	);
};

export default FillDailyTimeSheet;
