import {
	Button,
	Card,
	CardContent,
	CardHeader,
	TableCell,
	TableRow,
	Typography,
} from "@mui/material";
import { PreviewFileModal } from "components";
import moment from "moment";
import React, { useState } from "react";
import { ITimesheetReimbursement } from "utility/interfaces";

type ComponentProps = {
	row: ITimesheetReimbursement;
	isSmallDevice: boolean;
};

export const TimesheetReimbursementTableRow = (props: ComponentProps) => {
	// Extract props
	const { row, isSmallDevice } = props;

	// State variables
	const [isViewDocumentModalOpen, setIsViewDocumentModalOpen] = useState(false);

	// Handle events
	const handleViewDocumentModalOpen = () => setIsViewDocumentModalOpen(true);
	const handleViewDocumentModalClose = () => setIsViewDocumentModalOpen(false);

	return (
		<>
			{!isSmallDevice ? (
				<TableRow>
					<TableCell>
						{moment(row.timesheetdate).format("MMM D, YYYY")}
					</TableCell>
					<TableCell>{row.description ?? "-"}</TableCell>
					<TableCell>{row.amount ?? "-"}</TableCell>
					<TableCell>{row.receiptPath ?? "-"}</TableCell>
					<TableCell>
						<Button
							variant="outlined"
							className="action-btn action-btn-primary"
							onClick={handleViewDocumentModalOpen}
						>
							View
						</Button>
					</TableCell>
				</TableRow>
			) : (
				<Card className="bg-white">
					<div className="card-container">
						<CardHeader
							title={
								<React.Fragment>
									<span>{moment(row.timesheetdate).format("MMM D, YYYY")}</span>
								</React.Fragment>
							}
						/>
						<CardContent className="card-header-content">
							<div className="card-subheader">
								<Typography variant="body2" color="text.secondary">
									Item: &nbsp;{row.description}
								</Typography>
							</div>
							<div className="card-subheader">
								<Typography variant="body2">
									Amount:&nbsp;{row.amount}
								</Typography>
							</div>
							<div className="card-subheader">
								<Typography variant="body2">
									Bill:&nbsp;{row.receiptPath}
								</Typography>
							</div>
							<Button
								variant="outlined"
								className="action-btn action-btn-primary"
								onClick={handleViewDocumentModalOpen}
							>
								View
							</Button>
						</CardContent>
					</div>
				</Card>
			)}

			{/* Page modals */}
			<PreviewFileModal
				isModelOpen={isViewDocumentModalOpen}
				handleModalClose={handleViewDocumentModalClose}
				url={row.fileUrl}
			/>
		</>
	);
};
