import React, { useState } from "react";
import {
	Button,
	TableCell,
	TableRow,
	ButtonProps,
	Card,
	CardHeader,
	CardContent,
	Typography,
	Collapse,
	Checkbox,
} from "@mui/material";
import {
	CalendarIcon,
	CheckIcon,
	PhonePrimaryIcon,
	RequestorIcon,
	UncheckIcon,
} from "assests/images";
import { styled } from "@mui/material/styles";
import { IHistoryInfoSearchCriteria, IHistory } from "utility/interfaces";
import { SendMailModal } from "./send-mail-modal";
import { DownloadDocModal } from "./download-doc-modal";
import { ExpandMore, HistoryInfoTableRowMenu } from "components";

type ComponentProps = {
	row: IHistory;
	isSmallDevice: boolean;
	searchCriteria: IHistoryInfoSearchCriteria;
	selectedRows: number[];
	handleOnSelectRow: any;
};

export function HistoryInfoTableRowData(props: ComponentProps) {
	// useStates
	const [tableUser, setTableUser] = useState<null | HTMLElement>(null);
	const [expanded, setExpanded] = useState(false);
	const [isEmailModal, setIsEmailModal] = useState(false);
	const [isDownloadModal, setIsDownloadModal] = useState(false);

	// Extract Props
	const {
		row,
		isSmallDevice,
		searchCriteria,
		selectedRows,
		handleOnSelectRow,
	} = props;

	// Handled events and functions
	function CheckBoxIcon() {
		return (
			<img src={UncheckIcon} style={{ display: "block" }} alt="checkbox" />
		);
	}
	function CheckedBoxIcon() {
		return <img src={CheckIcon} style={{ display: "block" }} alt="checkbox" />;
	}
	const handleTableMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setTableUser(event.currentTarget);
	};
	const handleTableMenuClose = () => {
		setTableUser(null);
	};
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	// Page level variables
	const tableMenuOpen = Boolean(tableUser);

	return (
		<>
			{!isSmallDevice ? (
				<>
					<TableRow className="bg-white">
						<TableCell
							component="th"
							style={{ maxWidth: 50 }}
							scope="row"
							className="wrap min-w-50"
						>
							<Checkbox
								disableFocusRipple
								disableRipple
								checked={selectedRows.includes(row.id)}
								onChange={() => handleOnSelectRow(row.id)}
								icon={<CheckBoxIcon />}
								checkedIcon={<CheckedBoxIcon />}
							/>
						</TableCell>
						<TableCell
							component="th"
							style={{ maxWidth: 140 }}
							scope="row"
							className="wrapmin-w-140"
						>
							{row.patientName}
						</TableCell>
						<TableCell
							style={{ maxWidth: 140 }}
							component="th"
							scope="row"
							className="wrapmin-w-140"
						>
							{row.phoneNumber}
						</TableCell>
						<TableCell component="th" scope="row" className="wrap min-w-160">
							{row.email}
						</TableCell>
						<TableCell
							style={{ maxWidth: 120 }}
							component="th"
							scope="row"
							className="wrap min-w-120"
						>
							{row.dateOfService}
						</TableCell>
						<TableCell
							style={{ maxWidth: 130 }}
							component="th"
							scope="row"
							className="wrap min-w-130"
						>
							{row.caseCloseDate}
						</TableCell>
						<TableCell
							style={{ maxWidth: 130 }}
							className="wrap min-w-130"
							component="th"
							scope="row"
						>
							{row.requestor}
						</TableCell>
						<TableCell
							style={{ maxWidth: 140 }}
							className="wrap min-w-140"
							component="th"
							scope="row"
						>
							{row.provider}
						</TableCell>
						<TableCell
							className="wrap min-w-100 max-w-100"
							component="th"
							scope="row"
						>
							{row.confirmation}
						</TableCell>
						<TableCell
							style={{ maxWidth: 80 }}
							className="wrap min-w-80"
							component="th"
							scope="row"
						>
							{row.zipCode}
						</TableCell>
						<TableCell
							style={{ maxWidth: 140 }}
							className="wrap min-w-140"
							component="th"
							scope="row"
						>
							{row.transactionId}
						</TableCell>
						<TableCell
							className="wrap min-w-100 max-w-100"
							component="th"
							scope="row"
						>
							{row.status}
						</TableCell>
						<TableCell align="center" component="th" scope="row">
							<span className="table-cell-title">Actions</span>
							<Button
								id="table-button"
								aria-controls={tableMenuOpen ? "table-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={tableMenuOpen ? "true" : undefined}
								onClick={handleTableMenu}
								className="action-btn action-btn-primary"
							>
								Actions
							</Button>
						</TableCell>
					</TableRow>
				</>
			) : (
				// Mobile view
				<Card className="bg-white">
					<div className="card-container">
						<ExpandMore
							expand={expanded}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label="show more"
						>
							<Button
								id="table-button"
								aria-controls={tableMenuOpen ? "table-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={tableMenuOpen ? "true" : undefined}
								onClick={handleTableMenu}
								className="action-btn"
							>
								Actions
							</Button>
							<CardHeader
								title={
									<React.Fragment>
										<span>
											<Checkbox
												disableFocusRipple
												disableRipple
												aria-controls={tableMenuOpen ? "table-menu" : undefined}
												aria-expanded={tableMenuOpen ? "true" : undefined}
												aria-haspopup="true"
												checked={selectedRows.includes(row.id)}
												onChange={(event) => {
													event.stopPropagation();
													handleOnSelectRow(row.id);
												}}
												icon={<CheckBoxIcon />}
												checkedIcon={<CheckedBoxIcon />}
											/>
										</span>
										<span>{row.patientName}</span>
									</React.Fragment>
								}
							/>
							<CardContent className="card-header-content">
								<Typography>
									Email:&nbsp;
									<span>{row.email}</span>
								</Typography>
							</CardContent>
						</ExpandMore>
						<Collapse in={expanded} timeout="auto" unmountOnExit>
							<CardContent>
								<div>
									<span>
										<img src={PhonePrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Phone:&nbsp;
										<span>{row.phoneNumber}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={CalendarIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Date Of Service:&nbsp;
										<span>{row.dateOfService}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={CalendarIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Case Closed Date:&nbsp;
										<span>{row.caseCloseDate}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={RequestorIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Requestor:&nbsp;
										<span>{row.requestor}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={PhonePrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Provider:&nbsp;
										<span>{row.provider}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={PhonePrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Confirmation:&nbsp;
										<span>{row.confirmation}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={PhonePrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										ZipCode:&nbsp;
										<span>{row.zipCode}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={PhonePrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Transaction Id:&nbsp;
										<span>{row.transactionId}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={PhonePrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Status:&nbsp;
										<span>{row.status}</span>
									</Typography>
								</div>
								<div className="table-cell-btn-group"></div>
							</CardContent>
						</Collapse>
					</div>
				</Card>
			)}
			<HistoryInfoTableRowMenu
				row={row}
				tableMenuOpen={tableMenuOpen}
				tableUser={tableUser}
				handleTableMenuClose={handleTableMenuClose}
				searchCriteria={searchCriteria}
				handleOnClickSendMail={() => {
					setIsEmailModal(true);
					handleTableMenuClose();
				}}
				handleOnClickDownload={() => {
					setIsDownloadModal(true);
					handleTableMenuClose();
				}}
			/>
			<SendMailModal
				onModalClose={() => setIsEmailModal(false)}
				row={row}
				modalOpen={isEmailModal}
			/>
			<DownloadDocModal
				onModalClose={() => setIsDownloadModal(false)}
				row={row}
				modalOpen={isDownloadModal}
			/>
		</>
	);
}
