import React, { useContext, useState } from "react";
import { Box, Button, Grid, TextField, Tooltip } from "@mui/material";
import { Constants } from "utility/enums/constants";
import { SortOrder } from "utility/enums/sort-order";
import { createCommonAPICall } from "utility/helpers";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import historyService from "services/history-service";
import {
	IHistorySearchFields,
	ICancelHistorySearchCriteria,
} from "utility/interfaces";
import { CustomDatePicker } from "components";

type ComponentProps = {
	handleOnSearchCriteriaChange: any;
};

export function CancelHistoryTableSearch(props: ComponentProps) {
	// Extract Props
	const { handleOnSearchCriteriaChange } = props;

	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// useStates
	const [formValues, setFormValues] = useState<ICancelHistorySearchCriteria>({
		PageIndexId: 0,
		PageSize: Constants.DefaultTablePageSize.valueOf(),
		SortOrder: SortOrder.descending,
		SortBy: "CreatedDate",
		SearchBy: "",
		name: "",
		date: "",
		email: "",
		phoneNumber: "",
	});
	const [searchFields, setSearchFields] = useState<IHistorySearchFields>({
		requestStatus: [],
		requestType: [],
		caseTags: [],
	});

	// Handled events and functions
	const handleFormValueChange = (name: string, value: any) => {
		setFormValues({ ...formValues, [name]: value });
	};
	const handleOnClearForm = () => {
		handleOnSearchCriteriaChange({
			PageIndexId: 0,
			PageSize: Constants.DefaultTablePageSize.valueOf(),
			SortOrder: SortOrder.descending,
			SortBy: "CreatedDate",
			SearchBy: "",
			name: null,
			date: null,
			email: null,
			phoneNumber: null,
		});
		setFormValues({
			PageIndexId: 0,
			PageSize: Constants.DefaultTablePageSize.valueOf(),
			SortOrder: SortOrder.descending,
			SortBy: "CreatedDate",
			SearchBy: "",
			name: "",
			date: "",
			email: "",
			phoneNumber: "",
		});
	};

	const handleOnClickSearch = () => {
		handleOnSearchCriteriaChange({
			...formValues,
			name: formValues.name != "" ? formValues.name : null,
			date: formValues.date != "" ? formValues.date : null,
			email: formValues.email != "" ? formValues.email : null,
			phoneNumber: formValues.phoneNumber != "" ? formValues.phoneNumber : null,
		});
	};

	return (
		<>
			<div>
				<div className="history-table-header">
					<Grid container columnSpacing={{ sm: 2, lg: 3 }}>
						<Grid item xs={12} sm={3}>
							<TextField
								fullWidth
								id="name"
								label="Name"
								variant="outlined"
								name="name"
								value={formValues.name}
								onChange={(e) => handleFormValueChange("name", e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<CustomDatePicker
								value={formValues.date ?? undefined}
								label="Date"
								onChange={handleFormValueChange}
								name="date"
								disabled={false}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextField
								fullWidth
								id="email"
								label="Email"
								variant="outlined"
								name="email"
								value={formValues.email}
								onChange={(e) => handleFormValueChange("email", e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextField
								fullWidth
								id="phoneNumber"
								label="Phone Number"
								variant="outlined"
								name="phoneNumber"
								value={formValues.phoneNumber}
								onChange={(e) =>
									handleFormValueChange("phoneNumber", e.target.value)
								}
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Box
								sx={{
									display: "flex",
									columnGap: "12px",
									justifyContent: "flex-end",
									marginBottom: "14px",
								}}
							>
								<div className="history-search-btn">
									<Tooltip title="Click To Search">
										<Button variant="outlined" onClick={handleOnClearForm}>
											<span className="button-link">Clear</span>
										</Button>
									</Tooltip>
								</div>
								<div className="history-search-btn">
									<Tooltip title="Click To Search">
										<Button variant="contained" onClick={handleOnClickSearch}>
											<span className="button-link">Search</span>
										</Button>
									</Tooltip>
								</div>
							</Box>
						</Grid>
					</Grid>
				</div>
			</div>
		</>
	);
}
