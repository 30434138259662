import { TextField, TextFieldProps } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import {
	DatePicker,
	DesktopDatePicker,
	LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CalendarDarkIcon } from "assests/images";
import dayjs from "dayjs";
import React from "react";
import { UseFormRegister, FieldValues } from "react-hook-form";
import { DateInputFormat } from "utility/helpers";

type ComponentProps = {
	value: string | undefined;
	label: string;
	onChange: any;
	name: string;
	disabled: boolean;
	register?: any;
	error?: boolean;
	message?: string;
	disablePast?: boolean;
};

export function CustomDatePicker(props: ComponentProps) {
	const {
		value,
		onChange,
		label,
		name,
		disabled,
		register,
		error,
		message,
		disablePast,
	} = props;
	function datePickerIcon() {
		return (
			<>
				<img src={CalendarDarkIcon} alt="calendar" />
			</>
		);
	}
	return (
		<>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DatePicker
					disabled={disabled}
					label={label}
					value={value || null}
					inputFormat={DateInputFormat}
					// {...(register ? { ...register(name) } : "")}
					onChange={(newValue) => {
						onChange(name, newValue);
					}}
					components={{
						OpenPickerIcon: datePickerIcon,
					}}
					disablePast={disablePast}
					PopperProps={{ className: "z-index-max" }}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							id="date"
							className="datepicker"
							autoComplete="off"
							inputProps={{
								...params.inputProps,
							}}
							error={error}
							helperText={message ? message : ""}
						/>
					)}
				/>
				{/* <DesktopDatePicker
					disabled={disabled}
					label={label}
					value={value || null}
					inputFormat={DateInputFormat}
					// {...(register ? { ...register(name) } : "")}
					onChange={(newValue) => {
						onChange(name, newValue);
					}}
					components={{
						OpenPickerIcon: datePickerIcon,
					}}
					disablePast={disablePast}
					PopperProps={{ className: "z-index-max" }}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							id="date"
							className="datepicker"
							autoComplete="off"
							inputProps={{
								...params.inputProps,
							}}
							error={error}
							helperText={message ? message : ""}
						/>
					)}
				/> */}
			</LocalizationProvider>
		</>
	);
}
