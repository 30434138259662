import { yupResolver } from "@hookform/resolvers/yup";
import {
	Box,
	Button,
	IconButton,
	Modal,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import profileService from "services/profile-service";
import { commonModalStyle, createCommonAPICall } from "utility/helpers";
import { requestDTYSupportSchema } from "utility/validations";

type ComponentProps = {
	modalOpen: boolean;
	onModalClose: any;
	regionId: number;
};

export function RequestDTYSupportModal(props: ComponentProps) {
	//Use context
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// Extract Props
	const { modalOpen, onModalClose, regionId } = props;
	// useStates
	const [message, setMessage] = useState("");

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		reset,
	} = useForm({
		resolver: yupResolver(requestDTYSupportSchema),
	});

	// Events and functions
	const handleOnChangeMessage = (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setMessage(e.target.value);
	};
	const handleOnClickConfirm = async () => {
		const requestBody = {
			regionId,
			message,
		};
		console.log(requestBody);
		await createCommonAPICall({
			requestBody,
			apiService: profileService.sendRequestDTYSupport,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		}).then((data) => {
			console.log(data);
			onModalClose();
		});
	};

	// useEffects
	useEffect(() => {
		reset();
		clearErrors();
		setMessage("");
	}, [modalOpen]);

	return (
		<>
			<Modal
				open={modalOpen}
				onClose={onModalClose}
				className="send-modal select-input-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">Request DTY Support</Typography>
						<IconButton onClick={onModalClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<div>
							<Typography variant="h6" className="patient-name">
								To all unscheduled Physicians: DTY is short on coverage and
								needs additional support On Call to respond to Requests.
							</Typography>
							<TextField
								{...register("message")}
								id="block"
								label="Message"
								value={message}
								name="message"
								onChange={handleOnChangeMessage}
								multiline
								maxRows={1}
								inputProps={{
									style: { minHeight: "88px" },
								}}
								fullWidth
								error={errors?.message?.message ? true : false}
								helperText={errors?.message?.message?.toString()}
							/>
						</div>
						<div className="modal-footer">
							<Tooltip title="Send">
								<Button
									variant="contained"
									onClick={handleSubmit(handleOnClickConfirm)}
								>
									Send
								</Button>
							</Tooltip>
							<Tooltip title="Cancel">
								<Button variant="outlined" onClick={onModalClose}>
									Cancel
								</Button>
							</Tooltip>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
