import React, { useState } from "react";
import { Button, FormControl, MenuItem, Select, Tooltip } from "@mui/material";
import { IRegionSearchCriteria, IDropDownList } from "utility/interfaces";
import { RequestDTYSupportModal } from "../my-profile/request-dty-support-modal";
import { Constants } from "utility/enums/constants";

type ComponentProps = {
	searchCriteria: IRegionSearchCriteria;
	handleOnSearchCriteriaChange: any;
	regions: IDropDownList[];
};

export function RegionTableSearch(props: ComponentProps) {
	// Extract Props
	const { handleOnSearchCriteriaChange, searchCriteria, regions } = props;

	// useStates
	// TODO: Dynamic value
	const [requestDTYSupportOpen, setRequestDTYSupportOpen] = useState(false);
	const [selectedRegion, setSelectedRegion] = useState(0);
	// const [regions, setRegions] = useState<IDropDownList[]>([
	// 	{
	// 		text: "All",
	// 		value: 0,
	// 	},
	// 	{
	// 		text: "Regions 1",
	// 		value: 1,
	// 	},
	// 	{
	// 		text: "Regions 2",
	// 		value: 2,
	// 	},
	// 	{
	// 		text: "Regions 3",
	// 		value: 3,
	// 	},
	// ]);

	return (
		<>
			<div className="table-header region-table-header">
				<FormControl className="region-search">
					<Select
						labelId="region-label"
						id="region"
						value={selectedRegion}
						name="region"
						label="Search by Region"
						onChange={(event) => {
							setSelectedRegion(
								typeof event.target.value === "number" ? event.target.value : 0
							);
							handleOnSearchCriteriaChange({
								...searchCriteria,
								PageIndexId: 0,
								PageSize: Constants.DefaultTablePageSize.valueOf(),
								regionId: event.target.value,
							});
						}}
					>
						{regions.map((region) => {
							return (
								<MenuItem key={region.value} value={region.value}>
									{region.text}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
				<div className="tab-button-groups">
					<Tooltip title="Request DTY Support">
						<Button
							onClick={() => {
								setRequestDTYSupportOpen(true);
							}}
							variant="contained"
							style={{ paddingTop: "9px", paddingBottom: "9px" }}
						>
							Request DTY Support
						</Button>
					</Tooltip>
				</div>
			</div>
			<RequestDTYSupportModal
				modalOpen={requestDTYSupportOpen}
				onModalClose={() => {
					setRequestDTYSupportOpen(false);
				}}
				regionId={selectedRegion}
			/>
		</>
	);
}
