import React, { useContext, useState } from "react";
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Collapse,
	TableCell,
	Tooltip,
	Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { IPatientRecordHistory, ISearchCriteria } from "utility/interfaces";
import moment from "moment";
import { ExpandMore } from "components";
import {
	CalendarIcon,
	StatusCheckPrimaryIcon,
	DoctorPrimaryIcon,
	PdfIcon,
} from "assests/images";
import { PatientRecordTableRowMenu } from "./table-row-menu";
import { AppRoutings } from "utility/enums/app-routings";
import { RequestStatus } from "utility/enums/request-status";

type ComponentProps = {
	row: IPatientRecordHistory;
	isSmallDevice: boolean;
	searchCriteria: ISearchCriteria;
	patientId: string;
	handleChatBoxOpen: any;
};

export function PatientRecordTableRow(props: ComponentProps) {
	// Extract Props
	const { row, isSmallDevice, searchCriteria, patientId, handleChatBoxOpen } =
		props;

	// useStates
	const [expanded, setExpanded] = useState(false);
	const [tableUser, setTableUser] = useState<null | HTMLElement>(null);

	// Page level variable
	const navigate = useNavigate();
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const tableMenuOpen = Boolean(tableUser);

	//handle events
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const handleTableMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setTableUser(event.currentTarget);
	};

	const handleTableMenuClose = () => {
		setTableUser(null);
	};

	const handleOnClickView = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		event.stopPropagation();
		console.log(row.mobileReports);
		row.mobileReports.forEach((url, index) => {
			if (url !== "") {
				let link = document.createElement("a");
				link.href = url;
				link.download = row.reports[index];
				link.target = "_blank";
				document.getElementById("downloadBtn")?.appendChild(link);
				link.click();
				link.parentNode?.removeChild(link);
			}
		});
	};

	return !isSmallDevice ? (
		<>
			<TableCell className="wrap">
				<div>
					<Box className="display-small-table-label">CLient/Member: &nbsp;</Box>
					<span>{row.clientName}</span>
				</div>
			</TableCell>
			<TableCell>
				<Box className="display-small-table-label">Created Date: &nbsp;</Box>
				<span>
					{row.createdDate
						? moment(row.createdDate).format("MMM D, YYYY")
						: "-"}
				</span>
			</TableCell>
			<TableCell>
				<div>
					<Box className="display-small-table-label">Conformation: &nbsp;</Box>
					<span>{row.confirmationNumber}</span>
				</div>
			</TableCell>
			<TableCell className="wrap">
				<Box className="display-small-table-label">Provider Name: &nbsp;</Box>
				<span>{row.physicianName}</span>
			</TableCell>
			<TableCell>
				<div>
					<Box className="display-small-table-label">
						Concluded Date: &nbsp;
					</Box>
					<span>
						{row.concludedDate
							? moment(row.concludedDate).format("MMM D, YYYY")
							: "-"}
					</span>
				</div>
			</TableCell>
			<TableCell>
				<Box className="display-small-table-label">Status: &nbsp;</Box>
				<span>{row.currentStatus}</span>
			</TableCell>
			<TableCell align="center">
				<div className="upload-actions">
					{row.reports.length > 0 ? (
						<Tooltip title="View Final Report">
							<Button
								id="table-button-edit"
								variant="outlined"
								className="action-btn-primary action-btn"
								style={{ marginRight: 4 }}
								onClick={handleOnClickView}
							>
								View
							</Button>
						</Tooltip>
					) : (
						"-"
					)}
				</div>
			</TableCell>
			<TableCell align="center">
				<div className="upload-actions">
					<Tooltip title="Actions">
						<Button
							id="table-button-edit"
							variant="outlined"
							className="action-btn-primary action-btn"
							aria-controls={tableMenuOpen ? "table-menu" : undefined}
							aria-haspopup="true"
							aria-expanded={tableMenuOpen ? "true" : undefined}
							onClick={handleTableMenu}
							style={{ marginRight: 4 }}
						>
							Actions
						</Button>
					</Tooltip>
				</div>
			</TableCell>

			<PatientRecordTableRowMenu
				row={row}
				tableMenuOpen={tableMenuOpen}
				tableUser={tableUser}
				handleTableMenuClose={handleTableMenuClose}
				searchCriteria={searchCriteria}
				patientId={patientId}
				handleOpenChatBox={handleChatBoxOpen}
			/>
		</>
	) : (
		//Mobile View
		<>
			<Card className="bg-white">
				<div className="card-container">
					<ExpandMore
						expand={expanded}
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label="show more"
					>
						<CardHeader
							title={
								<React.Fragment>
									<span>{row.clientName}</span>
								</React.Fragment>
							}
						/>
						<CardContent className="card-header-content">
							<div className="card-subheader">
								<Typography variant="body2" color="text.secondary">
									{row.confirmationNumber}
								</Typography>
							</div>
						</CardContent>
					</ExpandMore>
					<Collapse in={expanded} timeout="auto" unmountOnExit>
						<CardContent>
							<div>
								<span>
									<img src={CalendarIcon} alt="calender" />
								</span>
								<Typography variant="h6">
									Created Date:&nbsp;
									<span>
										{row.createdDate
											? moment(row.createdDate).format("MMM D, YYYY")
											: "-"}
									</span>
								</Typography>
							</div>
							<div>
								<span>
									<img src={DoctorPrimaryIcon} alt="calender" />
								</span>
								<Typography variant="h6">
									Provider:&nbsp;
									<span>{row.physicianName}</span>
								</Typography>
							</div>
							<div>
								<span>
									<img src={CalendarIcon} alt="calender" />
								</span>
								<Typography variant="h6">
									Concluded Date:&nbsp;
									<span>
										{row.concludedDate
											? moment(row.concludedDate).format("MMM D, YYYY")
											: "-"}
									</span>
								</Typography>
							</div>
							<div>
								<span>
									<img src={StatusCheckPrimaryIcon} alt="calender" />
								</span>
								<Typography variant="h6">
									Status:&nbsp;
									<span>{row.currentStatus}</span>
								</Typography>
							</div>

							<div className="table-cell-btn-group table-btn-group-sm">
								{row.reports.length > 0 && (
									<Button
										variant="outlined"
										style={{ marginRight: "10px" }}
										onClick={handleOnClickView}
									>
										View Final Report
									</Button>
								)}
								<Button
									variant="outlined"
									style={{ marginRight: "10px" }}
									onClick={() =>
										navigate(
											AppRoutings.ViewCase.replace(":caseId", row.queryString),
											{
												state: {
													searchCriteria,
													backURL: AppRoutings.PatientRecord.replace(
														":patientId",
														patientId
													),
												},
											}
										)
									}
								>
									View Case
								</Button>
								{row.status !== RequestStatus.Unassigned && (
									<Button
										variant="outlined"
										style={{ marginRight: "10px" }}
										onClick={() => {
											handleChatBoxOpen(
												row.userId,
												row.requestId,
												"Admin",
												row.confirmationNumber
											);
										}}
									>
										Chat
									</Button>
								)}

								<Button
									variant="outlined"
									onClick={() =>
										navigate(
											AppRoutings.ViewUploads.replace(
												":caseId",
												row.queryString
											),
											{
												state: {
													backURL: AppRoutings.PatientRecord.replace(
														":patientId",
														patientId
													),
													searchCriteria,
												},
											}
										)
									}
								>
									({row.fileCount}) Documents
								</Button>
							</div>
						</CardContent>
					</Collapse>
				</div>
			</Card>
		</>
	);
}
