import { Modal, Box, Typography, IconButton, Button } from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import React from "react";
import { commonModalStyle } from "utility/helpers";

type ComponentProps = {
	isViewInvoiceModalOpen: boolean;
	viewInvoiceModalClose: any;
	message: string;
	invoiceLink: string;
};

export function ViewInvoiceModal(props: ComponentProps) {
	const {
		isViewInvoiceModalOpen,
		viewInvoiceModalClose,
		message,
		invoiceLink,
	} = props;

	return (
		<>
			<Modal
				open={isViewInvoiceModalOpen}
				onClose={viewInvoiceModalClose}
				className="send-modal select-input-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">Invoice Creation</Typography>
						{/* <Typography variant="h5">Encounter Form</Typography> */}
						<IconButton onClick={viewInvoiceModalClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						{message}
						<div className="record-btn-group">
							<Button
								variant="contained"
								color="primary"
								href={invoiceLink}
								target="_blank"
							>
								View In QuickBooks
							</Button>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
