import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { AppRoutings } from "utility/enums/app-routings";
import { IPendingBiWeeklyTimesheet } from "utility/interfaces";

type ComponentProps = {
	pendingTimesheetInfo: IPendingBiWeeklyTimesheet;
};

export const PendingTimesheettable = (props: ComponentProps) => {
	// Extract props
	const { pendingTimesheetInfo } = props;

	// Local variables
	const navigate = useNavigate();

	// Handle events
	const handleOnClickApprove = () => {
		navigate(
			AppRoutings.BiWeeklyTimesheet.replace(
				":data",
				pendingTimesheetInfo.queryString
			),
			{
				state: {
					backURL: AppRoutings.Invoicing,
				},
			}
		);
	};

	return (
		<>
			<TableContainer
				className="upload-table-container"
				sx={{ display: { xs: "none", md: "block" } }}
			>
				<Table className="upload-table">
					<TableHead>
						<TableRow>
							<TableCell>Start date</TableCell>
							<TableCell>End date</TableCell>
							<TableCell>Status</TableCell>
							<TableCell>Action</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>
								{moment(pendingTimesheetInfo.startDate).format("MMM D,YYYY")}
							</TableCell>
							<TableCell>
								{moment(pendingTimesheetInfo.endDate).format("MMM D,YYYY")}
							</TableCell>
							<TableCell>Pending</TableCell>
							<TableCell>
								<Button
									variant="outlined"
									className="action-btn action-btn-primary"
									onClick={handleOnClickApprove}
								>
									Approve
								</Button>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			<Box
				className="tabledata-cards-group custom-card-margin"
				sx={{ display: { xs: "block", md: "none" } }}
			>
				<Card className="bg-white">
					<div className="card-container">
						<CardHeader />
						<CardContent className="card-header-content">
							<Typography>
								Start date: &nbsp;
								<span>
									{moment(pendingTimesheetInfo.startDate).format("MMM D,YYYY")}
								</span>
							</Typography>
							<Typography>
								End date: &nbsp;
								<span>
									{moment(pendingTimesheetInfo.endDate).format("MMM D,YYYY")}
								</span>
							</Typography>
							<Typography>
								Status: &nbsp;
								<span>Pending</span>
							</Typography>
							<div className="table-cell-btn-group">
								<Button variant="outlined" onClick={handleOnClickApprove}>
									Approve
								</Button>
							</div>
						</CardContent>
					</div>
				</Card>
			</Box>
		</>
	);
};
