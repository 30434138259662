import React, { useContext, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	IconButton,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	SelectChangeEvent,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import { useForm } from "react-hook-form";
import { commonModalStyle, createCommonAPICall } from "utility/helpers";
import { assignCaseSchema, transferCaseSchema } from "utility/validations";
import dashboardService from "services/dashboard-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import {
	IAssignCaseInfo,
	IAssignCaseRequest,
	IDropDownList,
	IPhysicianListByRegionRequest,
} from "utility/interfaces";
import { UserContext } from "contexts/user-context";
import { AccountType } from "utility/enums/request-status";

type ComponentProps = {
	assignTransferCaseModalOpen: boolean;
	isTransfer: boolean;
	assignTransferCaseModalClose: any;
	queryString: string;
	requestId: number;
	requestStatus: number;
	regionId: number;
};

export function AssignTransferCaseModal(props: ComponentProps) {
	// Page level interface
	interface IAssignCase {
		region: number;
		physician: string;
		description: string;
	}

	const userrole = React.useContext(UserContext);
	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// useStates
	const [formValues, setFormValues] = useState<IAssignCase>({
		region: 0,
		physician: "",
		description: "",
	});

	const [assignCaseModalInfo, setAssignCaseModalInfo] =
		useState<IAssignCaseInfo>({
			physicianList: [],
			regionList: [],
			description: "",
		});

	// Extract Props
	const {
		assignTransferCaseModalOpen,
		assignTransferCaseModalClose,
		isTransfer,
		queryString,
		requestId,
		requestStatus,
		regionId,
	} = props;

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
		setValue,
	} = useForm({
		resolver: yupResolver(
			userrole.userRoleId == AccountType.Admin
				? assignCaseSchema
				: transferCaseSchema
		),
	});

	// Events and functions
	const handleFormValueChange = (e: any) => {
		setFormValues({ ...formValues, [e.target.name]: e.target.value });
	};
	const handleOnChangeRegion = async (e: any) => {
		const request: IPhysicianListByRegionRequest = {
			requestId: queryString,
			regionId:
				userrole.userroleId == AccountType.Physician ? 0 : e.target.value,
			physicianId: 0,
		};
		//userrole.userroleId == AccountType.Physician ? (request.regionId = 0) : "";
		console.log(request);
		const data = await createCommonAPICall({
			requestBody: request,
			apiService: dashboardService.getPhysicianListByRegion,
			showSuccessMessage: false,
			showErrorMessage: false,
			setSuccessErrorContext,
		});
		if (data && data.isSuccessfull) {
			console.log(data.data);
			var index = assignCaseModalInfo.regionList.findIndex(
				(re) => re.value === e.target.value
			);
			assignCaseModalInfo.regionList[index].isSelected = true;
			setAssignCaseModalInfo({
				regionList: assignCaseModalInfo.regionList,
				physicianList: data.data,
				description: formValues.description,
			});
			setFormValues({
				region: e.target.value,
				physician:
					data.data
						?.find((phy: IDropDownList) => phy.isSelected)
						?.value?.toString() || "",
				description: formValues.description || "",
			});
		}
	};
	const handleOnClickSubmit = async () => {
		const request: IAssignCaseRequest = {
			description: formValues.description,
			physicianId: +formValues.physician,
			requestId: queryString,
			regionId:
				userrole.userroleId == AccountType.Physician ? 0 : formValues.region,
			requestStatus: requestStatus,
		};
		console.log(request);
		const data = await createCommonAPICall({
			requestBody: request,
			apiService: dashboardService.assignCase,
			showSuccessMessage: true,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.isSuccessfull) {
			assignTransferCaseModalClose(true);
		}
	};
	const getModalInfo = async () => {
		const data = await createCommonAPICall({
			requestBody: { requestId: queryString, regionId: regionId },
			apiService: dashboardService.getAssignCaseModalInfo,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.isSuccessfull && data.data) {
			console.log("Here", data.data);

			const info: IAssignCaseInfo = data.data;
			// info.physicianList = [
			// 	{
			// 		text: "test1",
			// 		value: 1,
			// 		parentId: 1,
			// 		isSelected: false,
			// 	},
			// 	{
			// 		text: "test2",
			// 		value: 2,
			// 		parentId: 1,
			// 		isSelected: false,
			// 	},
			// ];
			setAssignCaseModalInfo(info);
			setFormValues({
				region:
					info.regionList?.find((region) => region.isSelected)?.value || 0,
				physician:
					info.physicianList
						?.find((phy) => phy.isSelected)
						?.value?.toString() || "",
				description: info.description || "",
			});
			return;
		}
		assignTransferCaseModalClose(false);
	};

	// useEffects
	useEffect(() => {
		reset();
		clearErrors();
		if (assignTransferCaseModalOpen) getModalInfo();
	}, [assignTransferCaseModalOpen]);
	useEffect(() => {
		Object.entries(formValues).forEach((child) => {
			setValue(child[0] as any, child[1] || "");
		});
	}, [formValues]);
	return (
		<>
			<Modal
				open={assignTransferCaseModalOpen}
				onClose={() => assignTransferCaseModalClose(false)}
				className="send-modal select-input-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">
							{isTransfer ? "Transfer Request" : "Assign Request"}
						</Typography>
						<IconButton onClick={() => assignTransferCaseModalClose(false)}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<div>
							{userrole.userRoleId == AccountType.Admin ? (
								<div>
									<Typography variant="body1" className="instruction">
										{`To ${
											isTransfer ? "transfer" : "assign"
										} this request, search and select another Physician.`}
									</Typography>
									<FormControl fullWidth>
										<InputLabel id="region-label">
											Narrow Search by Region
										</InputLabel>
										<Select
											autoFocus
											labelId="region-label"
											id="region"
											value={formValues.region}
											name="region"
											label="Narrow Search by Region"
											onChange={handleOnChangeRegion}
										>
											{assignCaseModalInfo?.regionList?.map((region) => {
												return (
													<MenuItem key={region.value} value={region.value}>
														{region.text}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
									<FormControl fullWidth>
										<InputLabel id="physician-label">
											Select Physician
										</InputLabel>
										<Select
											{...register("physician")}
											labelId="physician-label"
											id="physician"
											value={formValues.physician}
											label="Select Physician"
											name="physician"
											onChange={handleFormValueChange}
											error={errors?.physician?.message ? true : false}
											MenuProps={{
												className: "select-input-modal custom-select-dropdown",
											}}
										>
											{assignCaseModalInfo?.physicianList?.map((physican) => {
												return (
													<MenuItem key={physican.value} value={physican.value}>
														{physican.text}
													</MenuItem>
												);
											})}
											{/* {assignCaseModalInfo?.physicianList
												?.filter(
													(physician) =>
														physician.parentId.toString() ===
														formValues.region.toString()
												)
												.map((physician) => {
													return (
														<MenuItem
															key={physician.value}
															value={physician.value}
														>
															{physician.text}
														</MenuItem>
													);
												})} */}
										</Select>
										<FormHelperText error id="component-error-text">
											<>{errors?.physician?.message}</>
										</FormHelperText>
									</FormControl>
								</div>
							) : (
								<div>
									<Typography variant="body1" className="instruction">
										This request will be transferred to admin.
									</Typography>
								</div>
							)}
							<TextField
								{...register("description")}
								name="description"
								id="block"
								label="Description"
								value={formValues.description}
								multiline
								maxRows={1}
								inputProps={{ className: "min-h-88px" }}
								onChange={handleFormValueChange}
								fullWidth
								error={errors?.description?.message ? true : false}
								helperText={errors?.description?.message?.toString()}
							/>
						</div>
						<div className="modal-footer">
							<Tooltip title="Submit">
								<Button
									variant="contained"
									onClick={handleSubmit(handleOnClickSubmit)}
								>
									Submit
								</Button>
							</Tooltip>
							<Tooltip title="Cancel">
								<Button
									variant="outlined"
									onClick={() => assignTransferCaseModalClose(false)}
								>
									Cancel
								</Button>
							</Tooltip>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
