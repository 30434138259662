import {
	TableCell,
	Box,
	Button,
	Tooltip,
	Card,
	CardHeader,
	CardContent,
	Typography,
	Collapse,
} from "@mui/material";
import {
	CallIconPrimary,
	StatusCheckPrimaryIcon,
	UnassignedPrimaryIcon,
} from "assests/images";
import { ExpandMore } from "components";
import { UserContext } from "contexts/user-context";
import React, { useContext, useState } from "react";
import { IAccount } from "utility/interfaces";

type ComponentProps = {
	row: IAccount;
	index: number;
	isSmallDevice: boolean;
	handleClickEdit: any;
};

export function AccountsInfoTableRow(props: ComponentProps) {
	// Context variables
	const contextUser = useContext(UserContext);

	// Extract props
	const { row, index, isSmallDevice, handleClickEdit } = props;

	// State variables
	const [expanded, setExpanded] = useState(false);

	// Handle events and functions
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<>
			{!isSmallDevice ? (
				<>
					<TableCell component="th" scope="row" className="upload-file-col">
						<div className="upload-file" style={{ paddingLeft: "5px" }}>
							<Box className="display-small-table-label">
								Account type: &nbsp;
							</Box>
							<span>{row.accountType}</span>
						</div>
					</TableCell>
					<TableCell component="th" scope="row" className="upload-file-col">
						<div className="upload-file">
							<Box className="display-small-table-label">
								Account POC: &nbsp;
							</Box>
							<span>{row.accountPoc}</span>
						</div>
					</TableCell>
					<TableCell component="th" scope="row" className="upload-file-col">
						<div className="upload-file">
							<Box className="display-small-table-label">Phone: &nbsp;</Box>
							<span>{row.phone}</span>
						</div>
					</TableCell>
					<TableCell component="th" scope="row" className="upload-file-col">
						<div className="upload-file">
							<Box className="display-small-table-label">Status: &nbsp;</Box>
							<span>{row.acctStatus}</span>
						</div>
					</TableCell>
					<TableCell component="th" scope="row" className="upload-file-col">
						<div className="upload-file">
							<Box className="display-small-table-label">
								Open Requests: &nbsp;
							</Box>
							<span>{row.openRequests}</span>
						</div>
					</TableCell>
					<TableCell align="center">
						<div className="upload-actions">
							<Tooltip
								title={
									!contextUser.isSuperAdmin && row.accountType.includes("Admin")
										? "View"
										: "Edit"
								}
							>
								<Button
									id="table-button-delete"
									className="action-btn action-btn-primary"
									onClick={() =>
										handleClickEdit(row.queryString, row.accountType)
									}
								>
									{!contextUser.isSuperAdmin &&
									row.accountType.includes("Admin")
										? "View"
										: "Edit"}
								</Button>
							</Tooltip>
						</div>
					</TableCell>
				</>
			) : (
				//Mobile view
				<Card className="bg-white">
					<div className="card-container">
						<ExpandMore
							expand={expanded}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label="show more"
						>
							<CardHeader
								title={
									<React.Fragment>
										<span>{row.accountPoc}</span>
									</React.Fragment>
								}
							/>
							<CardContent className="card-header-content">
								<div className="card-subheader">
									<Typography variant="body2" color="text.secondary">
										Account type:&nbsp;{row.accountType}
									</Typography>
								</div>
							</CardContent>
						</ExpandMore>
						<Collapse in={expanded} timeout="auto" unmountOnExit>
							<CardContent>
								<div>
									<span>
										<img src={CallIconPrimary} alt="calender" />
									</span>
									<Typography variant="h6">
										Phone:&nbsp;
										<span>{row.phone}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={StatusCheckPrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Status:&nbsp;
										<span>{row.acctStatus}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={UnassignedPrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Open Requests:&nbsp;
										<span>{row.openRequests}</span>
									</Typography>
								</div>
								<div className="table-cell-btn-group">
									<Button
										variant="outlined"
										onClick={() =>
											handleClickEdit(row.queryString, row.accountType)
										}
									>
										{!contextUser.isSuperAdmin &&
										row.accountType.includes("Admin")
											? "View"
											: "Edit"}
									</Button>
								</div>
							</CardContent>
						</Collapse>
					</div>
				</Card>
			)}
		</>
	);
}
