import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Grid,
	IconButton,
	InputLabel,
	ListItemText,
	MenuItem,
	Modal,
	Select,
	SelectChangeEvent,
	Switch,
	Typography,
} from "@mui/material";
import { CheckIcon, CloseWhiteIcon } from "assests/images";
import dayjs from "dayjs";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { commonModalStyle, createCommonAPICall } from "utility/helpers";
import { IDropDownList, IShiftSchedule } from "utility/interfaces";
import { RepeatShift } from "./repeat-shift";
import dashboardService from "services/dashboard-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserContext } from "contexts/user-context";
import { AccountType } from "utility/enums/request-status";
import roleService from "services/role-service";
import { addDays } from "utility/helpers/common";
import { CustomDatePicker, CustomTimePicker, WarningModal } from "components";
import { addShiftSchema } from "utility/validations";

type ComponentProps = {
	isOpen: boolean;
	handleClose: any;
	shiftData: IShiftSchedule | null;
	regions: IDropDownList[];
	refreshCalender: any;
	loggedInPhysicianId?: number;
	shiftHistory?: string;
};

export const AddEditShiftModal = (props: ComponentProps) => {
	// Extract props
	const {
		isOpen,
		handleClose,
		shiftData,
		regions,
		refreshCalender,
		loggedInPhysicianId,
		shiftHistory,
	} = props;

	//useContext
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const contextUser = useContext(UserContext);

	// useStates
	const [formValues, setFormValues] = useState({
		shiftId: 0,
		shiftDetailId: 0,
		region: [] as number[],
		physicianId: 0,
		shiftDate: "",
		startTime: "",
		endTime: "",
	});

	const [oldFormValues, setOldFormValues] = useState({
		shiftId: 0,
		shiftDetailId: 0,
		region: [] as number[],
		physicianId: 0,
		shiftDate: "",
		startTime: "",
		endTime: "",
	});

	const [physicanList, setPhysicianList] = useState<IDropDownList[]>([
		{
			text: "Select",
			value: 0,
		},
	]);

	const [isEdit, setIsEdit] = useState(true);
	const [isRepeat, setIsrepeat] = useState(false);
	const [selectedRepeatDays, setSelectedRepeatDays] = useState<number[]>([]);
	const [repeatEnd, setRepeatEnd] = useState(2);
	const [timeError, setTimeError] = useState("");
	const [dateError, setDateError] = useState("");
	const [repeatEndOptions, setRepeatEndOptions] = useState<IDropDownList[]>([
		{
			text: "2-times",
			value: 2,
		},
		{
			text: "3-times",
			value: 3,
		},
		{
			text: "4-times",
			value: 4,
		},
		{
			text: "5-times",
			value: 5,
		},
	]);
	const [isLastDayOfMonth, setIsLastDayOfMonth] = useState(false);
	const [weekdaysCheckBox, setWeekdaysCheckBox] = useState([
		0, 1, 2, 3, 4, 5, 6,
	]);
	const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);

	// handle events
	const skipTimeSteps = () => {
		let time = new Date().getMinutes();
		time = time % 15 !== 0 ? time + (15 - (time % 15)) : time;
		let newDate = new Date();
		newDate.setMinutes(time);
		return newDate;
	};

	const handleRepeatDaysChange = (arr: number[]) => {
		console.log(arr);
		setSelectedRepeatDays(arr);
	};

	const getPhysicianListByRegion = async () => {
		if (formValues.region.length > 0) {
			const requestBody = formValues.region;
			const data = await createCommonAPICall({
				requestBody,
				apiService: roleService.getPhysicianListByRegionIds,
				showErrorMessage: true,
				showSuccessMessage: false,
				setSuccessErrorContext,
			});
			if (data && data.isSuccessfull) {
				setPhysicianList(data.data);
			}
		}
	};

	const handleFormValueChange = (name: string, value: any) => {
		setFormValues({ ...formValues, [name]: value });
	};

	const handleDateChange = (name: string, value: any) => {
		setTimeError("");
		setDateError("");
		let monthDate = new Date(value);
		monthDate.setHours(0);
		monthDate.setMinutes(0);
		monthDate.setSeconds(0);
		monthDate.setMilliseconds(0);
		let month = monthDate.getMonth(),
			year = monthDate.getUTCFullYear();
		let lastDay = new Date(year, month + 1, 0);
		var diff = (lastDay.getTime() - monthDate.getTime()) / 1000;
		diff /= 60 * 60 * 24 * 7;
		var week = Math.abs(Math.round(diff));
		if (week === 0 || week === 1) {
			var date = monthDate.getDate(),
				lastDate = lastDay.getDate();
			var startDateMonth = new Date(year, month, date + 1);
			var diffDate = lastDate - date;
			if (diffDate == 0) {
				setIsLastDayOfMonth(true);
			} else if (diffDate < 7 && diffDate != 0) {
				var dateArray = getDates(startDateMonth, lastDay);
				setWeekdaysCheckBox(dateArray);
				setIsLastDayOfMonth(false);
			} else {
				setIsLastDayOfMonth(false);
				setWeekdaysCheckBox([0, 1, 2, 3, 4, 5, 6]);
			}
			setRepeatEndOptions([
				{
					text: "2-times",
					value: 2,
				},
			]);
		} else if (week === 2) {
			setWeekdaysCheckBox([0, 1, 2, 3, 4, 5, 6]);
			setIsLastDayOfMonth(false);
			setRepeatEndOptions([
				{
					text: "2-times",
					value: 2,
				},
				{
					text: "3-times",
					value: 3,
				},
			]);
		} else {
			setWeekdaysCheckBox([0, 1, 2, 3, 4, 5, 6]);
			setIsLastDayOfMonth(false);
			setRepeatEndOptions([
				{
					text: "2-times",
					value: 2,
				},
				{
					text: "3-times",
					value: 3,
				},
				{
					text: "4-times",
					value: 4,
				},
				{
					text: "5-times",
					value: 5,
				},
			]);
		}
		handleFormValueChange(name, value);
	};

	const getDates = (startDate: Date, stopDate: Date) => {
		var dateArray = [];
		var currentDate = startDate;
		while (currentDate <= stopDate) {
			dateArray.push(currentDate.getDay());
			currentDate = addDays(currentDate, 1);
		}
		return dateArray;
	};

	const handleFormSave = async () => {
		let checkShiftDate = new Date(formValues.shiftDate).toString();
		let startTime = new Date(formValues.startTime),
			endTime = new Date(formValues.endTime);

		let isFormValid = true;
		if (startTime >= endTime) {
			setTimeError("Please enter appropriate Start Time and End Time");
			isFormValid = false;
		} else {
			setTimeError("");
		}
		if (checkShiftDate === "Invalid Date") {
			setDateError("Please enter appropriate Shift date");
			isFormValid = false;
		} else {
			setDateError("");
		}

		if (isFormValid) {
			setTimeError("");
			startTime.setSeconds(0);
			endTime.setSeconds(0);
			let shiftDate = new Date(new Date(formValues.shiftDate).toDateString());
			const requestBody = {
				shiftId: 0, // Change when start edit shift
				physicianId: formValues.physicianId,
				regionIds: formValues.region,
				shiftDate: shiftDate,
				startDate: shiftDate,
				strShiftDate: shiftDate.toLocaleString("en-US"),
				strStartTime: startTime.toLocaleString("en-US"),
				strEndTime: endTime.toLocaleString("en-US"),
				isRepeat: isRepeat, // Add WeekDays and RepeatUpto after Repeat done
				status: contextUser.userRoleId === AccountType.Admin ? 2 : 1,
				repeatUpto: repeatEnd,
				listOfWeekDays: selectedRepeatDays,
			};
			const data = await createCommonAPICall({
				requestBody,
				apiService: roleService.saveShift,
				showErrorMessage: true,
				showSuccessMessage: true,
				setSuccessErrorContext,
			});
			if (data && data.isSuccessfull) {
				refreshCalender();
			}
			setRepeatEnd(2);
			handleClose();
		}
	};

	const handleEditSave = async () => {
		handleFormEdit(false);
	};

	const handleEditSaveAndApprove = async () => {
		handleFormEdit(true);
	};

	const handleFormEdit = async (isSaveAndApprove: boolean) => {
		let checkShiftDate = new Date(formValues.shiftDate).toString();
		let startTime = new Date(formValues.startTime),
			endTime = new Date(formValues.endTime);

		let isFormValid = true;

		if (startTime >= endTime) {
			setTimeError("Please enter appropriate Start Time and End Time");
			isFormValid = false;
		} else {
			setTimeError("");
		}
		if (checkShiftDate === "Invalid Date") {
			setDateError("Please enter appropriate Shift date");
			isFormValid = false;
		} else {
			setDateError("");
		}

		if (isFormValid) {
			setTimeError("");
			setDateError("");
			startTime.setSeconds(0);
			endTime.setSeconds(0);
			let shiftDate = new Date(new Date(formValues.shiftDate).toDateString());
			const requestBody = {
				shiftId: formValues.shiftId,
				shiftDetailId: formValues.shiftDetailId,
				physicianId: formValues.physicianId,
				regionIds: formValues.region,
				shiftDate: shiftDate,
				startDate: shiftDate,
				strShiftDate: shiftDate.toLocaleString("en-US"),
				strStartTime: startTime.toLocaleString("en-US"),
				strEndTime: endTime.toLocaleString("en-US"),
				isRepeat: false,
				status: shiftData?.status,
				isSaveAndApprove: isSaveAndApprove,
			};
			const data = await createCommonAPICall({
				requestBody,
				apiService: roleService.saveShift,
				showErrorMessage: true,
				showSuccessMessage: true,
				setSuccessErrorContext,
			});
			if (data && data.isSuccessfull) {
				console.log(data);
				refreshCalender();
			}
			handleClose();
		}
	};

	const handleChangeShiftStatus = async (status: number) => {
		const requestBody = {
			shiftDetailId: formValues.shiftDetailId,
			status,
		};
		console.log(requestBody);
		const data = await createCommonAPICall({
			requestBody,
			apiService: roleService.changeShiftStatus,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.isSuccessfull) {
			refreshCalender();
		}
		handleClose();
	};

	const handleShiftDelete = async () => {
		const requestBody = {
			shiftDetailId: formValues.shiftDetailId,
		};
		console.log("Delete ", requestBody);
		const data = await createCommonAPICall({
			requestBody,
			apiService: roleService.deleteShift,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			refreshCalender();
			handleClose();
		}
	};

	const onChangeRepeatEnd = (e: SelectChangeEvent<number>) => {
		setRepeatEnd(
			typeof e.target.value === "string"
				? parseInt(e.target.value)
				: e.target.value
		);
	};

	// Validations
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm({
		values: formValues,
		resolver: yupResolver(addShiftSchema),
	});

	// useEffects
	useEffect(() => {
		setTimeError("");
		setDateError("");
		setIsrepeat(false);
		setSelectedRepeatDays([]);
		setIsLastDayOfMonth(false);
		if (isOpen) {
			// regions.push({ text: "Nothing Selected", value: 0 });
			regions.sort((r1, r2) => r1.value - r2.value);
			if (shiftData) {
				setIsEdit(false);
				// if (loggedInPhysicianId) setIsEdit(true);
				setPhysicianList([
					{ text: shiftData.fullName, value: shiftData.physicianId },
				]);
				let startDateWithTime = new Date(shiftData.shiftDate);
				let endDateWithTime = new Date(shiftData.shiftDate);
				startDateWithTime.setHours(shiftData._start, shiftData._startMin);
				endDateWithTime.setHours(shiftData._end, shiftData._endMin);
				setFormValues({
					shiftId: shiftData.shiftId,
					shiftDetailId: shiftData.id,
					region: shiftData.regionId.split(",").map((s: string) => {
						return parseInt(s);
					}),
					physicianId: shiftData.physicianId,
					shiftDate: shiftData.shiftDate,
					startTime: dayjs(startDateWithTime).toString(),
					endTime: dayjs(endDateWithTime).toString(),
				});

				setOldFormValues({
					shiftId: shiftData.shiftId,
					shiftDetailId: shiftData.id,
					region: shiftData.regionId.split(",").map((s: string) => {
						return parseInt(s);
					}),
					// region: [shiftData.regionId],
					physicianId: shiftData.physicianId,
					shiftDate: shiftData.shiftDate,
					startTime: dayjs(startDateWithTime).toString(),
					endTime: dayjs(endDateWithTime).toString(),
				});
			} else {
				setIsEdit(true);
				setPhysicianList([
					{
						text: "Select",
						value: 0,
					},
				]);
				setFormValues({
					shiftId: 0,
					shiftDetailId: 0,
					region: [],
					physicianId: loggedInPhysicianId ?? 0,
					shiftDate: dayjs(new Date()).toString(),
					startTime: dayjs(skipTimeSteps()).toString(),
					endTime: dayjs(skipTimeSteps()).toString(),
				});
				var today = new Date();
				var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
				if (today === lastDay) {
					setIsLastDayOfMonth(true);
				}
			}
		}
	}, [isOpen]);

	useEffect(() => {
		if (
			contextUser.userRoleId === AccountType.Admin &&
			moment(new Date(formValues.shiftDate)).format("YYYYMMDD") >=
				moment(new Date()).format("YYYYMMDD")
		)
			getPhysicianListByRegion();
	}, [formValues.region]);

	useEffect(() => {
		if (isRepeat) {
			handleDateChange("shiftDate", formValues.shiftDate);
		}
	}, [isRepeat]);

	return (
		<>
			<Modal
				open={isOpen}
				onClose={handleClose}
				className="send-modal custom-width"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">
							{shiftData
								? shiftData.status == 2
									? "View Shift"
									: "Review Shift"
								: "Create Shift"}
						</Typography>
						<IconButton onClick={handleClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<div className="scrollable-modal">
							<FormControl fullWidth>
								<InputLabel id="region-label">Region</InputLabel>
								<Select
									disabled={
										moment(new Date(formValues.shiftDate)).format("YYYYMMDD") <
											moment(new Date()).format("YYYYMMDD") ||
										(contextUser.userRoleId === AccountType.Admin &&
											(!isEdit || shiftData != null))
									}
									{...register("region")}
									error={errors.region?.message ? true : false}
									labelId="region-label"
									id="region"
									value={
										isEdit || contextUser.userRoleId !== AccountType.Admin
											? formValues.region
											: oldFormValues.region
									}
									name="region"
									onChange={(e) =>
										handleFormValueChange(e.target.name, e.target.value)
									}
									multiple
									renderValue={(selected) =>
										selected
											.map((value) => {
												return regions.find((i) => i.value === value)?.text;
											})
											.join(", ")
									}
								>
									{/* {regions.map((region) => {
										return (
											<MenuItem key={region.value} value={region.value}>
												{region.text}
											</MenuItem>
										);
									})} */}
									{regions.map((region) => {
										return (
											<MenuItem key={region.value} value={region.value}>
												<ListItemText primary={region.text} />
												<Checkbox
													checked={formValues.region.indexOf(region.value) > -1}
												/>
											</MenuItem>
										);
									})}
								</Select>
								<FormHelperText error>
									{errors.region?.message?.toString()}
								</FormHelperText>
							</FormControl>
							{contextUser.userRoleId === AccountType.Admin && (
								<FormControl fullWidth>
									<InputLabel id="physician-label">Physician</InputLabel>
									<Select
										{...register("physicianId")}
										error={errors.physicianId?.message ? true : false}
										disabled={
											moment(new Date(formValues.shiftDate)).format(
												"YYYYMMDD"
											) < moment(new Date()).format("YYYYMMDD") ||
											!isEdit ||
											shiftData != null
										}
										labelId="physician-label"
										id="physicianId"
										value={
											isEdit
												? formValues.physicianId
												: oldFormValues.physicianId
										}
										name="physicianId"
										onChange={(e) =>
											handleFormValueChange(e.target.name, e.target.value)
										}
										MenuProps={{ className: "custom-select-dropdown" }}
									>
										{physicanList.map((physician) => {
											return (
												<MenuItem key={physician.value} value={physician.value}>
													{physician.text}
												</MenuItem>
											);
										})}
									</Select>
									<FormHelperText error>
										{errors.physicianId?.message?.toString()}
									</FormHelperText>
								</FormControl>
							)}
							<Grid
								container
								columnSpacing={{ xs: 2, lg: 3 }}
								sx={{ marginBottom: "7px" }}
							>
								<Grid item xs={12}>
									<CustomDatePicker
										value={
											isEdit || contextUser.userRoleId !== AccountType.Admin
												? formValues.shiftDate
												: oldFormValues.shiftDate
										}
										label="ShiftDate"
										onChange={handleDateChange}
										name="shiftDate"
										disabled={
											moment(new Date(formValues.shiftDate)).format(
												"YYYYMMDD"
											) < moment(new Date()).format("YYYYMMDD") ||
											(contextUser.userRoleId === AccountType.Admin && !isEdit)
										}
										disablePast={true}
									/>
									<FormHelperText error>{dateError}</FormHelperText>
								</Grid>
							</Grid>

							<Grid container columnSpacing={{ xs: 2, lg: 3 }}>
								<Grid item xs={6}>
									<CustomTimePicker
										value={
											isEdit || contextUser.userRoleId !== AccountType.Admin
												? formValues.startTime
												: oldFormValues.startTime
										}
										onChange={handleFormValueChange}
										name="startTime"
										disabled={
											moment(new Date(formValues.shiftDate)).format(
												"YYYYMMDD"
											) < moment(new Date()).format("YYYYMMDD") ||
											(contextUser.userRoleId === AccountType.Admin && !isEdit)
										}
										label="Start"
										minuteStep={15}
									/>
									<FormHelperText error>{timeError}</FormHelperText>
								</Grid>
								<Grid item xs={6}>
									<CustomTimePicker
										value={
											isEdit || contextUser.userRoleId !== AccountType.Admin
												? formValues.endTime
												: oldFormValues.endTime
										}
										onChange={handleFormValueChange}
										name="endTime"
										disabled={
											moment(new Date(formValues.shiftDate)).format(
												"YYYYMMDD"
											) < moment(new Date()).format("YYYYMMDD") ||
											(contextUser.userRoleId === AccountType.Admin && !isEdit)
										}
										label="End"
										minuteStep={15}
									/>
								</Grid>
							</Grid>
							{moment(new Date(formValues.shiftDate)).format("YYYYMMDD") >=
								moment(new Date()).format("YYYYMMDD") &&
								!shiftData &&
								!isLastDayOfMonth && (
									<>
										<FormControlLabel
											value="repeat"
											control={
												<Switch
													color="primary"
													value={isRepeat}
													checked={isRepeat}
													onChange={(e) => {
														setIsrepeat(e.target.checked);
													}}
												/>
											}
											label="Repeat"
											labelPlacement="start"
										/>
										{isRepeat && (
											<RepeatShift
												selectedDays={selectedRepeatDays}
												handleSelectedDaysChange={handleRepeatDaysChange}
												repeatEnd={repeatEnd}
												onChangeRepeatEnd={onChangeRepeatEnd}
												repeatEndOptions={repeatEndOptions}
												weekdaysCheckbox={weekdaysCheckBox}
											/>
										)}
									</>
								)}
							{isLastDayOfMonth && !shiftData && (
								<FormHelperText error>
									*Note: As you have selected last day of the month Repeat will
									not be available
								</FormHelperText>
							)}
							{shiftHistory && (
								<>
									{shiftHistory.split("\n\n").map((str) => {
										if (str.trim() !== "")
											return (
												<div>
													<span style={{ color: "#6f6f6f" }}>
														{str.split(":")[0]}:
													</span>
													<div style={{ fontSize: "15px" }}>
														{str.split(":")[1]}
													</div>
												</div>
											);
										else return "";
									})}
								</>
							)}
						</div>
						<div className="modal-footer">
							{!shiftData ? (
								<>
									{moment(new Date(formValues.shiftDate)).format("YYYYMMDD") >=
										moment(new Date()).format("YYYYMMDD") && (
										<Button
											variant="contained"
											onClick={handleSubmit(handleFormSave)}
										>
											Save
										</Button>
									)}

									<Button variant="outlined" onClick={handleClose}>
										Cancel
									</Button>
								</>
							) : moment(new Date(formValues.shiftDate)).format("YYYYMMDD") >=
							  moment(new Date()).format("YYYYMMDD") ? (
								<>
									{isEdit ? (
										<>
											{shiftData.status == 1 && (
												<Button
													variant="contained"
													onClick={handleSubmit(handleEditSaveAndApprove)}
												>
													Save and Approve
												</Button>
											)}
											<Button
												variant="contained"
												onClick={handleSubmit(handleEditSave)}
											>
												Save
											</Button>
											<Button
												variant="outlined"
												onClick={() => {
													setFormValues(oldFormValues);
													setIsEdit(false);
												}}
											>
												Cancel
											</Button>
										</>
									) : (
										<>
											{contextUser.userRoleId === AccountType.Admin ? (
												<>
													{shiftData.status == 1 ? (
														<Button
															variant="contained"
															onClick={() => handleChangeShiftStatus(2)}
														>
															Approve
														</Button>
													) : (
														<Button
															variant="contained"
															onClick={() => handleChangeShiftStatus(1)}
														>
															Return
														</Button>
													)}
													<Button
														variant="contained"
														onClick={() => setIsEdit(true)}
													>
														Edit
													</Button>
												</>
											) : (
												<Button
													variant="contained"
													onClick={handleSubmit(handleEditSave)}
												>
													Edit
												</Button>
											)}

											<Button
												variant="contained"
												color="error"
												onClick={() => setIsDeleteWarningOpen(true)}
											>
												Delete
											</Button>
										</>
									)}
								</>
							) : (
								<Button variant="outlined" onClick={handleClose}>
									Cancel
								</Button>
							)}
						</div>
					</Box>
				</Box>
			</Modal>
			<WarningModal
				isModalOpen={isDeleteWarningOpen}
				okButtonText="Ok"
				closeButtonText="Cancel"
				warningMessage="Are you sure you want to delete this Shift?"
				title="Confirmation"
				handleOnClickCloseModal={() => setIsDeleteWarningOpen(false)}
				handleOnClickOk={handleShiftDelete}
			/>
		</>
	);
};
