import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	Box,
	Button,
	Divider,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import {
	ArrowBack,
	CallIconPrimary,
	LocationIcon,
	SmartphoneIcon,
} from "assests/images";
import { AppRoutings } from "utility/enums/app-routings";
import { IEditRequest, IViewCaseInfo } from "utility/interfaces";
import dashboardService from "services/dashboard-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import {
	getTabStatusWiseName,
	createCommonAPICall,
	showLoader,
	hideLoader,
	openSucessErrorModal,
	isMobile,
	isIOS,
} from "utility/helpers";
import {
	CustomDatePicker,
	AssignTransferCaseModal,
	CustomIntlTelInput,
	CancelCaseModal,
	AcceptCaseModal,
	WarningModal,
} from "components";
import {
	AccountType,
	RequestStatus,
	RequestTypes,
} from "utility/enums/request-status";
import { editRequestSchema } from "utility/validations";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import profileService from "services/profile-service";
import { CountryData } from "react-intl-tel-input-18";
import { UserContext } from "contexts/user-context";
import { Constants } from "utility/enums/constants";

const ViewCase: React.FC = () => {
	// get query parameters
	const caseId = useParams()?.caseId || "";

	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const contextUser = useContext(UserContext);

	// get state value
	const { state } = window.history;

	// useStates
	const [assignCaseModalOpen, setAssignCaseModalOpen] = React.useState(false);
	const [viewCasePageInfo, setViewCasePageInfo] =
		React.useState<IViewCaseInfo | null>({
			requestId: 0,
			requestTypeId: 0,
			physicianId: 0,
			regionId: 0,
			requestStatus: 0,
			queryString: "",
			requestTypeName: "",
			firstName: "",
			lastName: "",
			phoneNumber: "",
			email: "",
			dateOfBirth: "",
			physicianName: "",
			confirmationNumber: "",
			patientNotes: "",
			regionName: "",
			address: "",
			roomNumber: "",
			requesterName: "",
			requesterPhoneNumber: "",
		});

	const [formValues, setFormValues] = useState<IEditRequest>({
		firstName: viewCasePageInfo?.firstName || "",
		lastName: viewCasePageInfo?.lastName || "",
		phoneNumber: viewCasePageInfo?.phoneNumber || "",
		email: viewCasePageInfo?.email || "",
		dateOfBirth: viewCasePageInfo?.dateOfBirth || "",
	});
	const [edit, setEdit] = useState(false);

	const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

	const [renderer, setRenderer] = useState(0);

	const [isCancelCaseModalOpen, setIsCancelCaseModalOpen] = useState(false);

	const [isAcceptCaseModalOpen, setIsAcceptCaseModalOpen] = useState(false);

	const [ringCentral, setringCentral] = useState<null | HTMLCollection>(null);

	const [isAppOpenInfoModalOpen, setIsAppOpenInfoModalOpen] = useState(false);

	const isPageHidden = useRef(false);

	// Handled events and functions
	const handleAssignCaseModalOpen = () => setAssignCaseModalOpen(true);
	const assignCaseModalClose = (isSuccess: boolean) => {
		if (isSuccess) getCaseDetails();
		setAssignCaseModalOpen(false);
	};
	const handleOnClickBackCancel = () =>
		navigate(
			state?.usr?.backURL ||
				AppRoutings.Dashboard.replace(
					":tabStatus",
					getTabStatusWiseName(
						state?.usr?.searchCriteria?.RequestStatusId
					).toLocaleLowerCase()
				),
			{
				state: {
					searchCriteria: state?.usr?.searchCriteria,
				},
			}
		);

	const getCaseDetails = async () => {
		const data = await createCommonAPICall({
			requestBody: { requestId: caseId },
			apiService: dashboardService.getViewCaseInfo,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.data) {
			setViewCasePageInfo(data.data);
			setFormValues(data.data);
			setRenderer(1);
		}
	};

	const handleFormChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setFormValues({ ...formValues, [e.target.name]: e.target.value });
	};

	const handleRequestSave = () => {
		var dateOfBirth = new Date(formValues.dateOfBirth).toLocaleString("en-us");

		if (dateOfBirth === "Invalid Date") {
			openSucessErrorModal(
				setSuccessErrorContext,
				"Error",
				"Please enter valid Date of Birth.",
				false
			);
		} else {
			var requestBody = {
				...formValues,
				dateOfBirth: dateOfBirth,
			};
			createCommonAPICall({
				requestBody: requestBody,
				apiService: profileService.editRequest,
				showErrorMessage: true,
				showSuccessMessage: true,
				setSuccessErrorContext,
			}).then((data) => {
				if (data && data.isSuccessfull) {
					getCaseDetails();
					setEdit(false);
				}
			});
		}
	};

	const handlePhoneNumberChange = (
		isValid: boolean,
		phoneNumber: string,
		countryData: CountryData
	) => {
		setIsPhoneNumberValid(isValid || phoneNumber === "");
		let formattedPhoneNumber =
			phoneNumber != ""
				? phoneNumber.includes("+")
					? phoneNumber
					: `+${countryData.dialCode} ${phoneNumber}`
				: "";
		setFormValues({ ...formValues, phoneNumber: formattedPhoneNumber });
	};

	const handleAcceptCase = (isSuccess: boolean) => {
		if (isSuccess) getCaseDetails();
		setIsAcceptCaseModalOpen(false);
	};

	const handleVisibiltyChange = () => {
		if (document.visibilityState === "hidden") {
			hideLoader();
			isPageHidden.current = true;
			// setIsPageHidden(true);
		}
	};

	const handleOnCall = (phone: string) => {
		console.log("call", ringCentral!);
		if (phone !== "") {
			if (isMobile()) {
				console.log("Mobile device detected");
				try {
					var w = window.parent ? window.parent : window;
					showLoader();
					w.location.assign("rcapp://r/dialer?number=" + phone);
					document.addEventListener("visibilitychange", handleVisibiltyChange);
					setTimeout(() => {
						hideLoader();
						if (!isPageHidden.current) {
							setIsAppOpenInfoModalOpen(true);
						}
						document.removeEventListener(
							"visibilitychange",
							handleVisibiltyChange
						);
						isPageHidden.current = false;
					}, 5000);
					// w.location.assign("rcmobile://call?number=" + row.phone);
				} catch (e) {
					openSucessErrorModal(
						setSuccessErrorContext,
						"Error in calling",
						"Something went wrong for calling",
						false
					);
				}
			} else {
				document.getElementById("rc-widget")!.style.display = "block";
				const btn1 = document.querySelector(".Adapter_button.Adapter_popup");
				const btn2 = document.querySelector(".Adapter_button.Adapter_toggle");
				btn1?.addEventListener("click", () => {
					const popup = document.querySelector(".Adapter_right");
					if (popup!.classList.contains("Adapter_minimized")) {
						popup!.setAttribute("style", "display : none");
					} else {
						popup!.setAttribute("style", "display : block");
					}
				});
				btn2?.addEventListener("click", () => {
					const popup = document.querySelector(".Adapter_right");
					if (popup!.classList.contains("Adapter_minimized")) {
						popup!.setAttribute("style", "display : none");
					} else {
						popup!.setAttribute("style", "display : block");
					}
				});
			}
		}
	};

	const handleGetLoaction = (address: string) => {
		var url = "https://www.google.com/maps/search/?api=1&query=" + address + "";
		// var url = "http://www.google.com/maps?q=" + address + "";
		window.open(url, "_blank");
	};

	// Page level variables
	const navigate = useNavigate();

	// useEffects
	useEffect(() => {
		if (caseId) getCaseDetails();
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		var script = document.createElement("script");
		script.setAttribute("id", "ringCentral");
		script.src = Constants.RingCentralScript;
		var rcs0 = document.getElementsByTagName("script")[0];
		rcs0.parentNode!.insertBefore(script, rcs0);

		document.body.appendChild(script);

		const test = document.getElementsByClassName("Adapter_right");
		if (test) {
			setringCentral(test);
		}

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		values: formValues,
		resolver: yupResolver(editRequestSchema),
	});

	return (
		<Box>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="request-container-box">
					<div className="request-header">
						<Typography variant="h2">
							{viewCasePageInfo?.requestStatus == RequestStatus.Unassigned &&
								"New Request"}
							{viewCasePageInfo?.requestStatus != RequestStatus.Unassigned &&
								"View Reservation"}
							<span>{RequestTypes[viewCasePageInfo?.requestTypeId!]}</span>
							{/* <span>Patient</span> */}
						</Typography>
						<Button variant="outlined" onClick={handleOnClickBackCancel}>
							<img src={ArrowBack} alt="arrow" />
							Back
						</Button>
					</div>
					<Box className="request-box">
						<div>
							<Typography variant="h4">Patient Information</Typography>
							<div className="confirm-number">
								<label>Confirmation Number</label>
								<Typography variant="h4">
									{viewCasePageInfo?.confirmationNumber}
								</Typography>
							</div>
							<TextField
								id="patient-notes"
								label="Patient Notes"
								value={viewCasePageInfo?.patientNotes || ""}
								multiline
								inputProps={{
									style: { minHeight: "80px" },
								}}
								autoFocus
								fullWidth
								disabled
							/>
						</div>
						<Divider />
						<div>
							<Grid container columnSpacing={{ sm: 2, lg: 3 }}>
								<Grid item xs={12} sm={6}>
									<TextField
										{...register("firstName")}
										error={errors.firstName?.message ? true : false}
										helperText={errors.firstName?.message?.toString()}
										fullWidth
										id="first-name"
										label="First Name"
										variant="outlined"
										name="firstName"
										value={
											edit
												? formValues.firstName
												: viewCasePageInfo?.firstName || ""
										}
										disabled={!edit}
										onChange={(e) => {
											if (edit) handleFormChange(e);
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										{...register("lastName")}
										error={errors.lastName?.message ? true : false}
										helperText={errors.lastName?.message?.toString()}
										fullWidth
										id="last-name"
										label="Last Name"
										variant="outlined"
										name="lastName"
										value={
											edit
												? formValues.lastName
												: viewCasePageInfo?.lastName || ""
										}
										disabled={!edit}
										onChange={(e) => {
											if (edit) handleFormChange(e);
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<CustomDatePicker
										disabled={!edit}
										label="Date of Birth"
										name="dateOfBirth"
										value={
											edit
												? formValues.dateOfBirth
												: viewCasePageInfo?.dateOfBirth || ""
										}
										onChange={(name: string, value: any) => {
											if (edit) setFormValues({ ...formValues, [name]: value });
										}}
										message={errors.dateOfBirth?.message?.toString()}
										error={errors.dateOfBirth?.message ? true : false}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									{renderer > 0 && (
										<div className="input-with-button">
											<CustomIntlTelInput
												isPhoneNumberValid={isPhoneNumberValid}
												onBlur={() => {}}
												onChange={handlePhoneNumberChange}
												disabled={!edit}
												value={
													edit
														? formValues.phoneNumber
														: viewCasePageInfo?.phoneNumber || ""
												}
											/>

											<IconButton
												onClick={() =>
													handleOnCall(viewCasePageInfo?.phoneNumber || "")
												}
											>
												<Tooltip title="Call">
													<img src={CallIconPrimary} alt="call" />
												</Tooltip>
											</IconButton>
										</div>
									)}
									{/* <div className="input-with-button">
										<FormControl variant="outlined" fullWidth>
											<InputLabel htmlFor="phone-num">Phone Number</InputLabel>
											<OutlinedInput
												{...register("phoneNumber")}
												error={errors.phoneNumber?.message ? true : false}
												id="phone-num"
												type="tel"
												value={
													edit
														? formValues.phoneNumber
														: viewCasePageInfo?.phoneNumber || ""
												}
												name="phoneNumber"
												disabled={!edit}
												fullWidth
												className="with-icon"
												endAdornment={
													<InputAdornment position="end">
														<IconButton
															edge="end"
															disableFocusRipple
															disableRipple
														>
															<img src={SmartphoneIcon} alt="phone" />
														</IconButton>
													</InputAdornment>
												}
												label="phone-num"
												onChange={(e) => {
													if (edit) handleFormChange(e);
												}}
											/>
											<FormHelperText error>
												{errors.phoneNumber?.message?.toString()}
											</FormHelperText>
										</FormControl>
										<IconButton href={`tel: ${viewCasePageInfo?.phoneNumber}`}>
											<Tooltip title="Call">
												<img src={CallIconPrimary} alt="call" />
											</Tooltip>
										</IconButton>
									</div> */}
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										{...register("email")}
										error={errors.email?.message ? true : false}
										helperText={errors.email?.message?.toString()}
										fullWidth
										id="email"
										label="Email"
										variant="outlined"
										name="email"
										value={
											edit ? formValues.email : viewCasePageInfo?.email || ""
										}
										disabled={!edit}
										onChange={(e) => {
											if (edit) handleFormChange(e);
										}}
									/>
								</Grid>
								{viewCasePageInfo?.physicianId &&
									viewCasePageInfo?.physicianId > 0 && (
										<Grid item xs={12} sm={6}>
											{edit ? (
												<div className="verify-btn-group">
													<Button
														variant="contained"
														onClick={() => {
															if (
																formValues.phoneNumber === "" ||
																!isPhoneNumberValid
															) {
																setIsPhoneNumberValid(false);
																handleSubmit(() => {})();
															} else {
																handleSubmit(handleRequestSave)();
															}
														}}
													>
														Save
													</Button>
													<Button
														variant="outlined"
														onClick={(e) => {
															setRenderer(0);
															getCaseDetails();
															setEdit(false);
														}}
													>
														Cancel
													</Button>
												</div>
											) : (
												<div className="verify-btn-group">
													<Button
														variant="outlined"
														onClick={(e) => setEdit(true)}
														disabled={
															contextUser.userRoleId ===
																AccountType.Physician &&
															viewCasePageInfo.requestStatus ===
																RequestStatus.Unassigned
														}
													>
														Edit
													</Button>
												</div>
											)}
										</Grid>
									)}
							</Grid>
						</div>
						{viewCasePageInfo?.requestTypeId != RequestTypes.Patient && (
							<div>
								<Typography variant="h4">Requester Information</Typography>
								<div>
									<Grid container columnSpacing={{ sm: 2, lg: 3 }}>
										<Grid item xs={12} sm={6}>
											<TextField
												fullWidth
												id="requestername"
												label="Name"
												variant="outlined"
												value={
													viewCasePageInfo?.requesterName.replace(",", "") || ""
												}
												disabled
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											{renderer > 0 && (
												<div className="input-with-button">
													<CustomIntlTelInput
														isPhoneNumberValid={true}
														onBlur={() => {}}
														onChange={() => {}}
														disabled={true}
														value={viewCasePageInfo?.requesterPhoneNumber || ""}
													/>
													<IconButton
														onClick={() =>
															handleOnCall(
																viewCasePageInfo?.requesterPhoneNumber || ""
															)
														}
													>
														<Tooltip title="Call">
															<img src={CallIconPrimary} alt="call" />
														</Tooltip>
													</IconButton>
												</div>
											)}
											{/* <div className="input-with-button">												
												<FormControl variant="outlined" fullWidth>
													<InputLabel htmlFor="phone-num">
														Phone Number
													</InputLabel>
													<OutlinedInput
														id="phone-num"
														type="tel"
														value={viewCasePageInfo?.requesterPhoneNumber || ""}
														disabled
														fullWidth
														className="with-icon"
														endAdornment={
															<InputAdornment position="end">
																<IconButton
																	edge="end"
																	disableFocusRipple
																	disableRipple
																>
																	<img src={SmartphoneIcon} alt="phone" />
																</IconButton>
															</InputAdornment>
														}
														label="phone-num"
													/>
												</FormControl>
												<IconButton
													href={`tel: ${viewCasePageInfo?.requesterPhoneNumber}`}
												>
													<Tooltip title="Call">
														<img src={CallIconPrimary} alt="call" />
													</Tooltip>
												</IconButton>
											</div> */}
										</Grid>
									</Grid>
								</div>
							</div>
						)}

						<div>
							<Typography variant="h4">Location Information</Typography>
							<div>
								<Grid container columnSpacing={{ sm: 2, lg: 3 }}>
									<Grid item xs={12} sm={6}>
										<TextField
											fullWidth
											id="region"
											label="Region"
											variant="outlined"
											value={viewCasePageInfo?.regionName || ""}
											disabled
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<div className="input-with-button">
											<TextField
												fullWidth
												id="business-add"
												label="Business Name/Address"
												variant="outlined"
												value={viewCasePageInfo?.address || ""}
												disabled
											/>
											<IconButton
												onClick={() =>
													handleGetLoaction(viewCasePageInfo?.address || "")
												}
											>
												<img src={LocationIcon} alt="location" />
											</IconButton>
										</div>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											fullWidth
											id="room"
											label="Room #"
											variant="outlined"
											disabled
											value={viewCasePageInfo?.roomNumber || ""}
										/>
									</Grid>
								</Grid>
							</div>
						</div>
						<div className="request-btn-group">
							{viewCasePageInfo?.requestStatus === RequestStatus.Unassigned &&
								(contextUser.userRoleId === AccountType.Admin ? (
									<Button
										variant="contained"
										onClick={handleAssignCaseModalOpen}
									>
										<Tooltip title="Assign">
											<>Assign</>
										</Tooltip>
									</Button>
								) : (
									<>
										<Tooltip title="Accept">
											<Button
												variant="contained"
												onClick={() => setIsAcceptCaseModalOpen(true)}
												color="success"
											>
												Accept
											</Button>
										</Tooltip>
										{/* <Tooltip title="Reject">
											<Button
												variant="contained"
												onClick={() => setIsCancelCaseModalOpen(true)}
												color="error"
											>
												Reject
											</Button>
										</Tooltip> */}
									</>
								))}

							<Tooltip title="View Notes">
								<Button
									variant="contained"
									onClick={() => {
										navigate(
											AppRoutings.ViewNotes.replace(
												":caseId",
												viewCasePageInfo?.queryString || ""
											),
											{
												state: {
													backURL: AppRoutings.ViewCase.replace(
														":caseId",
														viewCasePageInfo?.queryString || ""
													),
													searchCriteria: state?.usr?.searchCriteria,
													prevBackURL: state?.usr?.backURL,
												},
											}
										);
									}}
								>
									View Notes
								</Button>
							</Tooltip>
							{viewCasePageInfo?.requestStatus != RequestStatus.Unassigned &&
								state?.usr?.isEdit && (
									<Tooltip title="Documents">
										<Button
											variant="contained"
											onClick={() => {
												navigate(
													AppRoutings.ViewUploads.replace(
														":caseId",
														viewCasePageInfo?.queryString || ""
													),
													{
														state: {
															backURL: AppRoutings.ViewCase.replace(
																":caseId",
																viewCasePageInfo?.queryString || ""
															),
															searchCriteria: state?.usr?.searchCriteria,
															prevBackURL: state?.usr?.backURL,
														},
													}
												);
											}}
										>
											Documents
										</Button>
									</Tooltip>
								)}
							{contextUser.userRoleId === AccountType.Admin &&
								viewCasePageInfo?.requestStatus !==
									RequestStatus.CloseCancelled && (
									<Tooltip title="Cancel case">
										<Button
											variant="contained"
											onClick={() => setIsCancelCaseModalOpen(true)}
											color="error"
										>
											Cancel
										</Button>
									</Tooltip>
								)}
						</div>
					</Box>
				</Box>
			</main>

			{/* Page Modals */}
			<AssignTransferCaseModal
				assignTransferCaseModalClose={assignCaseModalClose}
				assignTransferCaseModalOpen={assignCaseModalOpen}
				isTransfer={false}
				queryString={viewCasePageInfo?.queryString || ""}
				requestId={viewCasePageInfo?.requestId || 0}
				requestStatus={state?.usr?.searchCriteria.RequestStatusId}
				regionId={viewCasePageInfo?.regionId || 0}
			/>
			<CancelCaseModal
				cancelCaseModalOpen={isCancelCaseModalOpen}
				cancelCaseModalClose={(isSuccess: boolean) => {
					if (isSuccess) handleOnClickBackCancel();
					setIsCancelCaseModalOpen(false);
				}}
				queryString={caseId}
				requestId={viewCasePageInfo?.requestId ?? 0}
				requestStatus={state?.usr?.searchCriteria.RequestStatusId}
			/>
			<AcceptCaseModal
				handleAccpetCaseModalClose={handleAcceptCase}
				isModalOpen={isAcceptCaseModalOpen}
				queryString={caseId}
			/>

			<WarningModal
				isModalOpen={isAppOpenInfoModalOpen}
				handleOnClickOk={() => {
					var fallbackUrl =
						"https://play.google.com/store/apps/details?id=com.glip.mobile";
					if (isIOS()) {
						fallbackUrl =
							"https://apps.apple.com/in/app/ringcentral/id715886894";
					}

					window.location.assign(fallbackUrl);
				}}
				handleOnClickCloseModal={() => setIsAppOpenInfoModalOpen(false)}
				okButtonText="Install"
				closeButtonText="Cancel"
				title="Redirecting..."
				warningMessage="If RingCentral App is installed it will redirect you automatically, if not installed please install the app by clicking Install."
			/>
		</Box>
	);
};

export default ViewCase;
