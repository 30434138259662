import { AxiosResponse } from "axios";
import {
	ILoginResponse,
	ILoginRequest,
	IHttpsResponse,
} from "utility/interfaces";
import { UnProtectedEndPoints } from "./api-end-points";
import httpClient from "./base-service";

const login = async (
	request: ILoginRequest
): Promise<AxiosResponse<IHttpsResponse<ILoginResponse>>> =>
	httpClient.post<IHttpsResponse<ILoginResponse>>(
		UnProtectedEndPoints.Login,
		request
	);

const ssoLogin = async (
	request: any
): Promise<AxiosResponse<IHttpsResponse<ILoginResponse>>> =>
	httpClient.post<IHttpsResponse<ILoginResponse>>(
		UnProtectedEndPoints.SSOLogin,
		request
	);

const generateDefaultToken = async (): Promise<
	AxiosResponse<IHttpsResponse<ILoginResponse>>
> =>
	httpClient.get<IHttpsResponse<ILoginResponse>>(
		UnProtectedEndPoints.GenerateDefaultToken
	);

const forgotPassword = async (
	userName: string
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(UnProtectedEndPoints.ForgotPassword, {
		userName,
	});

const resetPasswordVerifyLink = async (
	linkToVerify: string | null
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		UnProtectedEndPoints.ResetPasswordVerifyLink,
		{ linkToVerify }
	);

const resetPassword = async (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		UnProtectedEndPoints.ResetPassword,
		request
	);

export default {
	login,
	ssoLogin,
	generateDefaultToken,
	forgotPassword,
	resetPasswordVerifyLink,
	resetPassword,
};
