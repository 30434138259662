import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
	Logo,
	MobileLogo,
	MenuIcon,
	LogoutIcon,
	ArrowDownIcon,
	ArrowDownIconDark,
} from "assests/images";
import {
	AppBar,
	Box,
	Button,
	Collapse,
	IconButton,
	List,
	ListItemButton,
	MenuItem,
	MenuList,
	Popover,
	Toolbar,
	Tooltip,
	Typography,
} from "@mui/material";
import { AppRoutings } from "utility/enums/app-routings";
import tokenManager from "utility/auth-guards/token-manager";
import { UserContext } from "contexts/user-context";
import { IMenu, IMenuPermissions, IUserDetails } from "utility/interfaces";
import { getTabStatusWiseName } from "utility/helpers";
import { InformationTabTypes } from "utility/enums/request-status";
import { WarningModal } from "./warning-modal";
import { MenuContext } from "contexts/menu-context";
import { InstallOption, LightDarkButton } from "components";
import { ChatCountContext } from "contexts/chat-count-context";

export function Header() {
	const [hoverMenuId, setHoverMenuId] = React.useState(0);
	const [isLogoutWarning, setIsLogoutWarning] = React.useState(false);
	const [subMenu, setSubMenu] = React.useState<null | HTMLElement>(null);
	const handleSubMenuHover = (
		event: React.MouseEvent<HTMLButtonElement>,
		hoverMenuId: number
	) => {
		setSubMenu(event.currentTarget);
		setHoverMenuId(hoverMenuId);
	};
	const handleSubMenuClose = () => {
		setSubMenu(null);
		setHoverMenuId(0);
	};

	// Page level local variables
	const navigate = useNavigate();
	const currentUserDetails: IUserDetails = useContext(UserContext);
	const menuDetails: IMenu = useContext(MenuContext);
	const chatCount = useContext(ChatCountContext);
	const menuPermissions: IMenuPermissions[] = menuDetails.menus || [];
	const [currentActiveMenu, setCurrentActiveMenu] = React.useState(
		menuDetails?.selectedMenuKey
	);
	const isSubMenuActive = (menuKey: string) => {
		return menuDetails.selectedMenuKey === menuKey ? true : false;
	};
	const isParentMenuActive = (menuKey: string) => {
		return menuDetails.selectedMenuKey.includes(menuKey) ? true : false;
	};

	useEffect(() => {
		setCurrentActiveMenu(menuDetails?.selectedMenuKey);
	}, [menuDetails]);

	console.log(menuPermissions, menuDetails);

	return (
		<>
			<AppBar position="static" className="header">
				<Toolbar disableGutters sx={{ flexWrap: "wrap" }}>
					<Box className="header-container">
						<Box className="d-flex align-item-center cursor-pointer">
							<IconButton
								size="large"
								onClick={() => document.body.classList.toggle("sidebar-toggle")}
								sx={{ display: { xs: "flex", laptop: "none" } }} // change from sm: "none" after menu changes
								className="menu-btn"
								disableRipple
								disableFocusRipple
							>
								<img src={MenuIcon} alt="menu" />
							</IconButton>
							<Tooltip title="Doctors To You">
								<a
									onClick={() =>
										navigate(
											AppRoutings.Dashboard.replace(
												":tabStatus",
												getTabStatusWiseName(
													InformationTabTypes.New
												).toLocaleLowerCase()
											)
										)
									}
									className="header-logo"
								>
									<img src={Logo} alt="Doctors To You" className="desktop" />
									<img
										src={MobileLogo}
										alt="Doctors To You"
										className="mobile"
									/>
								</a>
							</Tooltip>
						</Box>
						<Box sx={{ flexGrow: 0 }} className="header-content">
							<Typography
								variant="h6"
								className="greetings"
								sx={{ display: { xs: "none", sm: "flex" } }}
							>
								Welcome&nbsp;
								<span>{`${currentUserDetails?.firstName} ${currentUserDetails?.lastName}`}</span>
							</Typography>
							<Tooltip title="Logout">
								<Button
									variant="outlined"
									size="small"
									onClick={() => {
										setIsLogoutWarning(true);
									}}
									className="logout-btn"
								>
									<img src={LogoutIcon} alt="logout" />
									<span>Logout</span>
								</Button>
							</Tooltip>
							<LightDarkButton />
						</Box>
					</Box>
					<Box
						sx={{
							flexGrow: 1,
							display: { xs: "none", laptop: "flex" }, // change from sm: "flex" after menu changes
						}}
						className="menu-container min-w-100per"
					>
						{menuPermissions
							.filter((menu) => !menu.ParentId || menu.ParentId === 0)
							.map((parentMenu, index) => {
								const childMenus = menuPermissions?.filter(
									(childMenu) => childMenu.ParentId === parentMenu.Id
								);
								return (
									<div key={index}>
										{childMenus.length > 0 ? (
											<ul key={parentMenu.Id}>
												<li onMouseLeave={handleSubMenuClose}>
													<Button
														id="provider-btn"
														aria-haspopup="true"
														className={
															isParentMenuActive(parentMenu.MenuKey)
																? "menu-item active"
																: "menu-item"
														}
														onMouseEnter={(
															e: React.MouseEvent<HTMLButtonElement, MouseEvent>
														) => handleSubMenuHover(e, parentMenu.Id)}
													>
														<strong>{parentMenu.Title}</strong>
														<span className="link-item">
															{parentMenu.Title}
														</span>
													</Button>
													<Popover
														id="provider-menu"
														anchorEl={
															hoverMenuId === parentMenu.Id ? subMenu : null
														}
														open={
															hoverMenuId === parentMenu.Id
																? Boolean(subMenu)
																: false
														}
														onClose={handleSubMenuClose}
														className="provider-menu"
														anchorOrigin={{
															vertical: "bottom",
															horizontal: "left",
														}}
														transformOrigin={{
															vertical: "top",
															horizontal: "left",
														}}
													>
														<MenuList>
															{childMenus.map((childMenu, index) => {
																return (
																	<MenuItem
																		className={
																			isSubMenuActive(childMenu.MenuKey)
																				? "active"
																				: ""
																		}
																		key={index}
																		onClick={() => {
																			handleSubMenuClose();
																			if (!isSubMenuActive(childMenu.MenuKey))
																				navigate(childMenu.URL || "");
																		}}
																	>
																		{childMenu.Title}
																	</MenuItem>
																);
															})}
														</MenuList>
													</Popover>
												</li>
											</ul>
										) : (
											<>
												<Button
													variant="text"
													className={
														isParentMenuActive(parentMenu.MenuKey)
															? "menu-item active"
															: "menu-item"
													}
													onClick={() => {
														navigate(parentMenu.URL ? parentMenu.URL : "");
													}}
												>
													<strong>
														{parentMenu.Title}
														{parentMenu.MenuKey === "chats" &&
															chatCount > 0 &&
															`(${chatCount})`}
													</strong>
													<span className="link-item">
														{parentMenu.Title}
														{parentMenu.MenuKey === "chats" &&
															chatCount > 0 &&
															`(${chatCount})`}
													</span>
												</Button>
											</>
										)}
									</div>
								);
							})}
					</Box>
				</Toolbar>
				<nav className="sidebar">
					<Typography
						variant="h6"
						className="greetings"
						sx={{ display: "flex" }}
					>
						Welcome,&nbsp;
						<span>{`${currentUserDetails?.firstName} ${currentUserDetails?.lastName}`}</span>
					</Typography>
					<List className="sidebar-menu">
						{menuPermissions
							.filter(
								(menu) =>
									menu.ParentId === undefined ||
									menu.ParentId === null ||
									menu.ParentId === 0
							)
							.map((parentMenu, index) => {
								const childMenus = menuPermissions?.filter(
									(childMenu) => childMenu.ParentId === parentMenu.Id
								);
								return (
									<div key={index}>
										{childMenus.length > 0 ? (
											<>
												<ListItemButton disableRipple disableTouchRipple>
													<Link
														to={window.location.pathname}
														id="provider-link"
														onClick={() =>
															currentActiveMenu.includes(parentMenu.MenuKey)
																? setCurrentActiveMenu(
																		menuDetails?.selectedMenuKey
																	)
																: setCurrentActiveMenu(parentMenu.MenuKey)
														}
														className={
															currentActiveMenu.includes(parentMenu.MenuKey)
																? "active-menu"
																: "default-menu"
														}
													>
														{parentMenu.Title}
														<img
															src={ArrowDownIcon}
															alt="arrow"
															className="light-icon"
														/>
														<img
															src={ArrowDownIconDark}
															alt="arrow"
															className="dark-icon"
														/>
													</Link>
												</ListItemButton>
												<Collapse
													in={currentActiveMenu.includes(parentMenu.MenuKey)}
													timeout="auto"
													unmountOnExit
												>
													<List component="div">
														{childMenus.map((child, index) => {
															return (
																<ListItemButton
																	className={
																		isSubMenuActive(child.MenuKey)
																			? "active"
																			: ""
																	}
																	key={index}
																	onClick={() => {
																		if (!isSubMenuActive(child.MenuKey))
																			navigate(child.URL ? child.URL : "");
																		document.body.classList.toggle(
																			"sidebar-toggle"
																		);
																	}}
																	disableRipple
																	disableTouchRipple
																>
																	{child.Title}
																</ListItemButton>
															);
														})}
													</List>
												</Collapse>
											</>
										) : (
											<>
												<ListItemButton disableRipple disableTouchRipple>
													<Link
														onClick={() => {
															currentActiveMenu.includes(parentMenu.MenuKey)
																? setCurrentActiveMenu("")
																: setCurrentActiveMenu(parentMenu.MenuKey);
															document.body.classList.toggle("sidebar-toggle");
														}}
														className={
															isParentMenuActive(parentMenu.MenuKey)
																? "active-menu"
																: "default-menu"
														}
														to={parentMenu.URL ? parentMenu.URL : ""}
													>
														{parentMenu.Title}
														{parentMenu.MenuKey === "chats" &&
															chatCount > 0 &&
															`(${chatCount})`}
													</Link>
												</ListItemButton>
											</>
										)}
									</div>
								);
							})}
						<ListItemButton disableRipple disableTouchRipple>
							<InstallOption />
						</ListItemButton>
					</List>
				</nav>
			</AppBar>
			<WarningModal
				isModalOpen={isLogoutWarning}
				handleOnClickCloseModal={() => setIsLogoutWarning(false)}
				handleOnClickOk={() => tokenManager.clearSession()}
				warningMessage="Are you sure, you want to logout from here?"
				title="Logout"
				okButtonText="Logout"
				closeButtonText="Cancel"
			/>
		</>
	);
}
