import React from "react";
import { Logo } from "assests/images";
import "./loader-style.css";

export function Loader() {
	return (
		<div className="loader-wrapper">
			<div className="loader-inner">
				<div className="lds-spinner">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<img className="loader-icon tree-mask" src={Logo} alt="" />
			</div>
		</div>
	);
}

export default Loader;
