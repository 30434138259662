import Cookies from "js-cookie";
import { Constants } from "utility/enums/constants";
import authService from "services/auth-service";
import { AppRoutings } from "utility/enums/app-routings";
import { publicIpv4 } from "public-ip";
import { IMenu, IUserDetails } from "utility/interfaces";
import { ENVIRONMENT } from "config";
import { showLoader, createCommonAPICall } from "utility/helpers";
import { ITrackContextInfo } from "utility/interfaces/common/track-context-info";

const getNewDtySecret = async () => {
	const data = await createCommonAPICall({
		apiService: authService.generateDefaultToken,
		showSuccessMessage: false,
		showErrorMessage: false,
		isHideLoader: false,
	});
	if (data && data.data) return data.data.dtySecret;
	return "";
};

const getDeviceIpAddress = async () => {
	const ip = await publicIpv4();
	return ip;
};
const clearCookiesAndLocalStorage = () => {
	Cookies.remove(ENVIRONMENT + Constants.DtySecretKey);
	Cookies.remove(ENVIRONMENT + Constants.AppTokenKey);
	Cookies.remove(ENVIRONMENT + Constants.UserDetails);
	localStorage.removeItem(ENVIRONMENT + Constants.MenuPermissions);
	localStorage.removeItem(ENVIRONMENT + Constants.ChatCount);
};

const clearSession = (returnUrl?: string) => {
	showLoader();
	clearCookiesAndLocalStorage();
	if (window) {
		const url = returnUrl
			? `${AppRoutings.Root}?back=${returnUrl}`
			: AppRoutings.Root;
		window.location.replace(url);
	}
};

const getToken = (): string | undefined => {
	const encryptToken = Cookies.get(ENVIRONMENT + Constants.AppTokenKey);
	const token = doEncryptDecrypt(false, encryptToken);
	const sessionToken = token?.slice(1, -1);
	if (sessionToken) return sessionToken;
	else {
		clearCookiesAndLocalStorage();
		return undefined;
	}
};

const getDtySecret = (): string => {
	const encryptSecret = Cookies.get(ENVIRONMENT + Constants.DtySecretKey);
	return doEncryptDecrypt(false, encryptSecret).slice(1, -1);
};

const getChatCount = (): number => {
	const encryptedChatCount = localStorage.getItem(
		ENVIRONMENT + Constants.ChatCount
	);
	const chatCount = parseInt(doEncryptDecrypt(false, encryptedChatCount));
	return chatCount;
};

const setChatCount = (chatCount: number) => {
	localStorage.setItem(
		ENVIRONMENT + Constants.ChatCount,
		doEncryptDecrypt(true, chatCount)
	);
};

const setYOffset = (yOffset: number) => {
	sessionStorage.setItem(
		ENVIRONMENT + Constants.PageYOffset,
		yOffset.toString()
	);
};

const getYOffset = () => {
	return sessionStorage.getItem(ENVIRONMENT + Constants.PageYOffset);
};

const removeYOffset = () => {
	sessionStorage.removeItem(ENVIRONMENT + Constants.PageYOffset);
};

const setAuthorization = (
	dtySecret: string,
	jwtToken: string,
	userDetails: string,
	menuPermissions: string
): void => {
	Cookies.set(
		ENVIRONMENT + Constants.DtySecretKey,
		doEncryptDecrypt(true, dtySecret),
		{
			expires: 30,
		}
	);
	Cookies.set(
		ENVIRONMENT + Constants.AppTokenKey,
		doEncryptDecrypt(true, "bearer " + jwtToken),
		{
			expires: 30,
		}
	);
	Cookies.set(
		ENVIRONMENT + Constants.UserDetails,
		doEncryptDecrypt(true, userDetails),
		{
			expires: 30,
		}
	);
	localStorage.setItem(
		ENVIRONMENT + Constants.MenuPermissions,
		doEncryptDecrypt(true, menuPermissions)
	);
};
const getUserDetails = (): IUserDetails | undefined => {
	const userDetailString = doEncryptDecrypt(
		false,
		Cookies.get(ENVIRONMENT + Constants.UserDetails)
	);
	if (userDetailString) {
		const userDetails: IUserDetails = JSON.parse(
			JSON.parse(userDetailString ? userDetailString : "")
		);
		userDetails.menuPermissions = "";
		return userDetails;
	} else return undefined;
};

const getMenuDetails = (): IMenu | undefined => {
	const menuPermissions = doEncryptDecrypt(
		false,
		localStorage.getItem(ENVIRONMENT + Constants.MenuPermissions)
	);
	const menus: IMenu = {
		selectedMenuKey: "",
		menus: menuPermissions ? JSON.parse(JSON.parse(menuPermissions)) : [],
	};
	return menus;
};

const doEncryptDecrypt = (isEncrypt: boolean, data: any): string => {
	if (data) {
		const CryptoJS = require("crypto-js");
		if (isEncrypt === true) {
			// Encrypt
			const ciphertext = CryptoJS.AES.encrypt(
				JSON.stringify(data),
				Constants.LocalAppEncryptkey
			).toString();
			return ciphertext;
		}
		// Decrypt
		const bytes = CryptoJS.AES.decrypt(data, Constants.LocalAppEncryptkey);
		const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
		return decryptedData;
	}
	return "";
};

const setTrackIds = (trackIds: ITrackContextInfo[]) => {
	Cookies.set(
		ENVIRONMENT + Constants.TrackIdKey,
		doEncryptDecrypt(true, trackIds),
		{
			expires: 30,
		}
	);
};

const getTrackIds = (): ITrackContextInfo[] => {
	const trackIdsString = doEncryptDecrypt(
		false,
		Cookies.get(ENVIRONMENT + Constants.TrackIdKey)
	);
	if (trackIdsString && trackIdsString !== "" && trackIdsString !== "[]") {
		console.log(trackIdsString);
		const trackIds: ITrackContextInfo[] = JSON.parse(trackIdsString);
		return trackIds;
	} else return [];
};

export default {
	getDtySecret,
	setAuthorization,
	getToken,
	clearSession,
	getNewDtySecret,
	getDeviceIpAddress,
	getUserDetails,
	getMenuDetails,
	clearCookiesAndLocalStorage,
	getChatCount,
	setChatCount,
	setYOffset,
	getYOffset,
	removeYOffset,
	setTrackIds,
	getTrackIds,
};
