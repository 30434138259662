import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { SendIcon } from "assests/images";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Constants } from "utility/enums/constants";
import { SortOrder } from "utility/enums/sort-order";
import { IHistoryInfo, IHistoryInfoSearchCriteria } from "utility/interfaces";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { HistoryInfoTable, SendHistoryModal } from "components";

const HistoryInfo: React.FC = () => {
	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// useStates
	const [selectedRows, setSelectedRows] = React.useState<number[]>([]);
	const [sendHistoryModalOpen, setSendHistoryModalOpen] = useState(false);
	const [historyPageInfo, setHistoryPageInfo] =
		React.useState<IHistoryInfo | null>(null);

	const [searchCriteria, setSearchCriteria] =
		useState<IHistoryInfoSearchCriteria>({
			PageIndexId: 0,
			PageSize: Constants.DefaultTablePageSize.valueOf(),
			SortOrder: SortOrder.ascending,
			SortBy: "",
			SearchBy: "",
			paymentStatus: 0,
			name: "",
			date: "",
			requestType: 0,
			providerName: "",
			email: "",
			phoneNumber: "",
		});

	// Handled events and functions
	const handleOnSearchCriteriaChange = (
		newSearchCriteria: IHistoryInfoSearchCriteria
	) => {
		setSearchCriteria(newSearchCriteria);
	};
	const getHistoryPageGrids = async () => {
		console.log(searchCriteria);
		// const data = await createCommonAPICall({
		// 	requestBody: searchCriteria,
		// 	apiService: historyService.getSearchFields,
		// 	showSuccessMessage: false,
		// 	showErrorMessage: true,
		// 	setSuccessErrorContext,
		// });
		// if (data && data.data) setHistoryPageInfo(data.data);
		setHistoryPageInfo({
			histories: [
				{
					id: 1,
					queryString: "Renish ribadiya",
					patientName: "Renish ribadiya",
					phoneNumber: "Renish ribadiya",
					email: "renishkumar.ribadiya@tatvasoft.com",
					dateOfService: "Jun 8, 2018",
					caseCloseDate: "Jun 8, 2018",
					requestor: "Renish ribadiya",
					provider: "Renish ribadiya",
					confirmation: "Renish ribadiya",
					zipCode: "888888",
					transactionId: "Renish ribadiya",
					status: "Renish ribadiya",
				},
				{
					id: 1324,
					queryString: "Renish ribadiya",
					patientName: "Renish ribadiya",
					phoneNumber: "Renish ribadiya",
					email: "renishkumar.ribadiya@tatvasoft.com",
					dateOfService: "Jun 8, 2018",
					caseCloseDate: "Jun 8, 2018",
					requestor: "Renish ribadiya",
					provider: "Renish ribadiya",
					confirmation: "Renish ribadiya",
					zipCode: "888888",
					transactionId: "Renish ribadiya",
					status: "Renish ribadiya",
				},
				{
					id: 1222,
					queryString: "Renish ribadiya",
					patientName: "Renish ribadiya",
					phoneNumber: "Renish ribadiya",
					email: "renishkumar.ribadiya@tatvasoft.com",
					dateOfService: "Jun 8, 2018",
					caseCloseDate: "Jun 8, 2018",
					requestor: "Renish ribadiya",
					provider: "Renish ribadiya",
					confirmation: "Renish ribadiya",
					zipCode: "888888",
					transactionId: "Renish ribadiya",
					status: "Renish ribadiya",
				},
				{
					id: 133,
					queryString: "Renish ribadiya",
					patientName: "Renish ribadiya",
					phoneNumber: "Renish ribadiya",
					email: "renishkumar.ribadiya@tatvasoft.com",
					dateOfService: "Jun 8, 2018",
					caseCloseDate: "Jun 8, 2018",
					requestor: "Renish ribadiya",
					provider: "Renish ribadiya",
					confirmation: "Renish ribadiya",
					zipCode: "888888",
					transactionId: "Renish ribadiya",
					status: "Renish ribadiya",
				},
				{
					id: 13,
					queryString: "Renish ribadiya",
					patientName: "Renish ribadiya",
					phoneNumber: "Renish ribadiya",
					email: "renishkumar.ribadiya@tatvasoft.com",
					dateOfService: "Jun 8, 2018",
					caseCloseDate: "Jun 8, 2018",
					requestor: "Renish ribadiya",
					provider: "Renish ribadiya",
					confirmation: "Renish ribadiya",
					zipCode: "888888",
					transactionId: "Renish ribadiya",
					status: "Renish ribadiya",
				},
				{
					id: 112,
					queryString: "Renish ribadiya",
					patientName: "Renish ribadiya",
					phoneNumber: "Renish ribadiya",
					email: "renishkumar.ribadiya@tatvasoft.com",
					dateOfService: "Jun 8, 2018",
					caseCloseDate: "Jun 8, 2018",
					requestor: "Renish ribadiya",
					provider: "Renish ribadiya",
					confirmation: "Renish ribadiya",
					zipCode: "888888",
					transactionId: "Renish ribadiya",
					status: "Renish ribadiya",
				},
				{
					id: 122,
					queryString: "Renish ribadiya",
					patientName: "Renish ribadiya",
					phoneNumber: "Renish ribadiya",
					email: "renishkumar.ribadiya@tatvasoft.com",
					dateOfService: "Jun 8, 2018",
					caseCloseDate: "Jun 8, 2018",
					requestor: "Renish ribadiya",
					provider: "Renish ribadiya",
					confirmation: "Renish ribadiya",
					zipCode: "888888",
					transactionId: "Renish ribadiya",
					status: "Renish ribadiya",
				},
				{
					id: 12,
					queryString: "Renish ribadiya",
					patientName: "Renish ribadiya",
					phoneNumber: "Renish ribadiya",
					email: "renishkumar.ribadiya@tatvasoft.com",
					dateOfService: "Jun 8, 2018",
					caseCloseDate: "Jun 8, 2018",
					requestor: "Renish ribadiya",
					provider: "Renish ribadiya",
					confirmation: "Renish ribadiya",
					zipCode: "888888",
					transactionId: "Renish ribadiya",
					status: "Renish ribadiya",
				},
				{
					id: 111,
					queryString: "Renish ribadiya",
					patientName: "Renish ribadiya",
					phoneNumber: "Renish ribadiya",
					email: "renishkumar.ribadiya@tatvasoft.com",
					dateOfService: "Jun 8, 2018",
					caseCloseDate: "Jun 8, 2018",
					requestor: "Renish ribadiya",
					provider: "Renish ribadiya",
					confirmation: "Renish ribadiya",
					zipCode: "888888",
					transactionId: "Renish ribadiya",
					status: "Renish ribadiya",
				},
				{
					id: 11,
					queryString: "Renish ribadiya",
					patientName: "Renish ribadiya",
					phoneNumber: "Renish ribadiya",
					email: "renishkumar.ribadiya@tatvasoft.com",
					dateOfService: "Jun 8, 2018",
					caseCloseDate: "Jun 8, 2018",
					requestor: "Renish ribadiya",
					provider: "Renish ribadiya",
					confirmation: "Renish ribadiya",
					zipCode: "888888",
					transactionId: "Renish ribadiya",
					status: "Renish ribadiya",
				},
			],
			totalRecords: 100,
		});
	};
	const handleOnSelectRow = (id: number) => {
		const isSelected = selectedRows.filter((rowId) => rowId === id).length > 0;
		const updatedSelectedRow = selectedRows.filter((rowId) => rowId !== id);
		if (!isSelected) {
			updatedSelectedRow.push(id);
		}
		setSelectedRows(updatedSelectedRow);
	};
	const handleOnClickSendHistory = () => {
		if (selectedRows.length === 0) {
			toast.error("Please select atleast one record.");
			return;
		}
		setSendHistoryModalOpen(true);
	};

	//Page level local variable
	const navigate = useNavigate();

	// useEffects
	useEffect(() => {
		getHistoryPageGrids();
	}, [searchCriteria]);

	return (
		<>
			<Box>
				<div className="chatbox-overlay"></div>
				<main className="main-content">
					<div
						className="overlay"
						onClick={() => document.body.classList.toggle("sidebar-toggle")}
					></div>
					<Box className="tab-item-header">
						<Typography variant="h2">Case History </Typography>
						<Box className="tab-button-groups">
							<Button
								disabled={selectedRows.length === 0}
								variant="contained"
								onClick={handleOnClickSendHistory}
							>
								<img src={SendIcon} alt="send history" />
								<span className="button-link">Send History </span>
							</Button>
						</Box>
					</Box>
					<HistoryInfoTable
						selectedRows={selectedRows}
						handleOnSelectRow={handleOnSelectRow}
						handleOnSearchCriteriaChange={handleOnSearchCriteriaChange}
						searchCriteria={searchCriteria}
						historyPageInfo={historyPageInfo}
					/>
				</main>
			</Box>
			<SendHistoryModal
				modalOpen={sendHistoryModalOpen}
				onModalClose={() => setSendHistoryModalOpen(false)}
			/>
		</>
	);
};
export default HistoryInfo;
