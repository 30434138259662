import LoginPage from "pages/account/login";
import ResetPassword from "pages/account/reset-password";
import CreateRequest from "pages/dashboard/create-request";
import Dashboard from "pages/dashboard/dashboard";
import DoctorNote from "pages/dashboard/doctor-note";
import Orders from "pages/dashboard/orders";
import ViewCase from "pages/dashboard/view-case";
import ViewNotes from "pages/dashboard/view-notes";
import ViewUploads from "pages/dashboard/view-uploads";
import { AppRoutings } from "utility/enums/app-routings";
import ConcludeCare from "pages/dashboard/conclude-care";
import CloseCase from "pages/dashboard/close-case";
import ItemizedCosts from "pages/dashboard/itemized-costs";
import Encounter from "pages/dashboard/encounter";
import Region from "pages/profile/region";
import MyProfile from "pages/profile/my-profile";
import Role from "pages/provider/role/role";
import SSOLogin from "pages/account/sso-login";
import CreateRole from "pages/provider/role/create-role";
import UpdateRole from "pages/provider/role/update-role";
import ProviderInfo from "pages/provider/provider-info/provider-info";
import Vendors from "pages/profession/vendors/vendors";
import AddBusiness from "pages/profession/vendors/add-business";
import UpdateBusiness from "pages/profession/vendors/update-business";
import ProfessionInfo from "pages/profession/profession-info/profession-info";
import HistoryInfo from "pages/history/history-info/history-info";
import PatientRecord from "pages/history/history-info/patient-record";
import RequestData from "pages/history/request-data/request-data";
import CancelHistory from "pages/history/cancel-history/cancel-history";
import PatientHistory from "pages/history/patient-history/patient-history";
import { RouterType } from "utility/enums/router-type";
import Chats from "pages/profile/chats";
import Accounts from "pages/provider/accounts/accounts";
import Scheduling from "pages/provider/scheduling/scheduling";
import ProviderOnCall from "pages/provider/scheduling/provider-on-call";
import ReviewShifts from "pages/provider/scheduling/review-shifts";
import EmailLogs from "pages/history/email-logs/email-logs";
import SMSLogs from "pages/history/sms-logs/sms-logs";
import ProviderPayrate from "pages/provider/provider-info/provider-payrate";
import BlockHistory from "pages/history/block-history/block-history";
import HaloHR from "pages/Halo/halo-hr/halo-hr";
import HaloRequests from "pages/Halo/halo-requests/halo-requests";
import HaloEmployee from "pages/Halo/halo-employee/halo-employee";
import HaloWorkPlace from "pages/Halo/halo-workplace/halo-workplace";
import TimesheetDetails from "pages/provider/invoicing/timesheet-details";
import FillDailyTimeSheet from "pages/provider/invoicing/fill-daily-timesheet";
import SubmitTimesheetSuccess from "pages/provider/invoicing/submit-success";
import BiWeeklyTimesheet from "pages/provider/invoicing/bi-weekly-timesheet";
import ProviderLocation from "pages/provider/provider-location/provider-location";

interface IRoute {
	id: number;
	path: string;
	element: any;
	// Notes:
	//  -required if protected route
	//  -connected with menu permission -> menukey
	menuKey?: string;
	routeType: number;
	moduleName?: string;
	pageName?: string;
	pageTitle?: string;
	description?: string;
}

export const AppRoutes: IRoute[] = [
	{
		id: 1,
		path: AppRoutings.Root,
		element: LoginPage,
		routeType: RouterType.unprotectedRoute,
		moduleName: "Login",
		pageName: "Login",
		pageTitle: "Login",
		description: "Login",
	},
	{
		id: 2,
		path: AppRoutings.Dashboard,
		element: Dashboard,
		routeType: RouterType.protectedRoute,
		moduleName: "Dashboard",
		pageName: "Dashboard",
		pageTitle: "Dashboard",
		description: "Dashboard",
		menuKey: "dashboard",
	},
	{
		id: 3,
		path: AppRoutings.CreateRequest,
		element: CreateRequest,
		routeType: RouterType.protectedRoute,
		moduleName: "Dashboard-Create-Request",
		pageName: "Dashboard-Create-Request",
		pageTitle: "Dashboard-Create-Request",
		description: "Dashboard-Create-Request",
		menuKey: "dashboard",
	},
	{
		id: 4,
		path: AppRoutings.ResetPassword,
		element: ResetPassword,
		routeType: RouterType.unprotectedRoute,
		moduleName: "Reset-Password",
		pageName: "Reset-Password",
		pageTitle: "Reset-Password",
		description: "Reset-Password",
	},
	{
		id: 5,
		path: AppRoutings.ViewNotes,
		element: ViewNotes,
		routeType: RouterType.protectedRoute,
		moduleName: "View-Notes",
		pageName: "View-Notes",
		pageTitle: "View-Notes",
		description: "View-Notes",
		menuKey: "dashboard",
	},
	{
		id: 6,
		path: AppRoutings.ViewCase,
		element: ViewCase,
		routeType: RouterType.protectedRoute,
		moduleName: "View-Case",
		pageName: "View-Case",
		pageTitle: "View-Case",
		description: "View-Case",
		menuKey: "dashboard",
	},
	{
		id: 7,
		path: AppRoutings.ViewUploads,
		element: ViewUploads,
		routeType: RouterType.protectedRoute,
		moduleName: "View-Uploads",
		pageName: "View-Uploads",
		pageTitle: "View-Uploads",
		description: "View-Uploads",
		menuKey: "dashboard",
	},
	{
		id: 8,
		path: AppRoutings.Orders,
		element: Orders,
		routeType: RouterType.protectedRoute,
		moduleName: "Order",
		pageName: "Order",
		pageTitle: "Order",
		description: "Order",
		menuKey: "dashboard",
	},
	{
		id: 9,
		path: AppRoutings.DoctorsNote,
		element: DoctorNote,
		routeType: RouterType.protectedRoute,
		moduleName: "Doctor-Note",
		pageName: "Doctor-Note",
		pageTitle: "Doctor-Note",
		description: "Doctor-Note",
		menuKey: "dashboard",
	},
	{
		id: 10,
		path: AppRoutings.ConcludeCare,
		element: ConcludeCare,
		routeType: RouterType.protectedRoute,
		moduleName: "Conclude-Care",
		pageName: "Conclude-Care",
		pageTitle: "Conclude-Care",
		description: "Conclude-Care",
		menuKey: "dashboard",
	},
	{
		id: 10,
		path: AppRoutings.CloseCase,
		element: CloseCase,
		routeType: RouterType.protectedRoute,
		moduleName: "Close-Case",
		pageName: "Close-Case",
		pageTitle: "Close-Case",
		description: "Close-Case",
		menuKey: "dashboard",
	},
	{
		id: 11,
		path: AppRoutings.ItemizedCosts,
		element: ItemizedCosts,
		routeType: RouterType.protectedRoute,
		moduleName: "Itemized-Cost",
		pageName: "Itemized-Cost",
		pageTitle: "Itemized-Cost",
		description: "Itemized-Cost",
		menuKey: "dashboard",
	},
	{
		id: 12,
		path: AppRoutings.Encounter,
		element: Encounter,
		routeType: RouterType.protectedRoute,
		moduleName: "Encounter",
		pageName: "Encounter",
		pageTitle: "Encounter",
		description: "Encounter",
		menuKey: "dashboard",
	},
	{
		id: 13,
		path: AppRoutings.Region,
		element: Region,
		routeType: RouterType.protectedRoute,
		moduleName: "Region",
		pageName: "Region",
		pageTitle: "Region",
		description: "Region",
		menuKey: "profile/regions",
	},
	{
		id: 14,
		path: AppRoutings.MyProfile,
		element: MyProfile,
		routeType: RouterType.protectedRoute,
		moduleName: "My profile",
		pageName: "My profile",
		pageTitle: "My profile",
		description: "My profile",
		menuKey: "profile/my-profile",
	},
	{
		id: 15,
		path: AppRoutings.Role,
		element: Role,
		routeType: RouterType.protectedRoute,
		moduleName: "Role",
		pageName: "Role",
		pageTitle: "Role",
		description: "Role",
		// menuKey: "access",
		menuKey: "access/account-access",
	},
	// {
	// 	id: 15,
	// 	path: AppRoutings.Role,
	// 	element: Role,
	// 	routeType: RouterType.protectedRoute,
	// 	moduleName: "Role",
	// 	pageName: "Role",
	// 	pageTitle: "Role",
	// 	description: "Role",
	// 	menuKey: "provider/role",
	// },
	{
		id: 16,
		path: AppRoutings.SSOLogin,
		element: SSOLogin,
		routeType: RouterType.unprotectedRoute,
		moduleName: "SSO Login",
		pageName: "SSO Login",
		pageTitle: "SSO Login",
		description: "SSO Login",
	},
	{
		id: 17,
		path: AppRoutings.CreateRole,
		element: CreateRole,
		routeType: RouterType.protectedRoute,
		moduleName: "Create Role",
		pageName: "Create Role",
		pageTitle: "Create Role",
		description: "Create Role",
		menuKey: "access",
	},
	{
		id: 18,
		path: AppRoutings.UpdateRole,
		element: UpdateRole,
		routeType: RouterType.protectedRoute,
		moduleName: "Update Role",
		pageName: "Update Role",
		pageTitle: "Update Role",
		description: "Update Role",
		menuKey: "access",
	},
	{
		id: 19,
		path: AppRoutings.ProviderInfo,
		element: ProviderInfo,
		routeType: RouterType.protectedRoute,
		moduleName: "Provider Information",
		pageName: "Provider Information",
		pageTitle: "Provider Information",
		description: "Provider Information",
		menuKey: "provider/info",
	},
	// {
	// 	id: 20,
	// 	path: AppRoutings.Vendors,
	// 	element: Vendors,
	// 	routeType: RouterType.protectedRoute,
	// 	moduleName: "Vendors",
	// 	pageName: "Vendors",
	// 	pageTitle: "Vendors",
	// 	description: "Vendors",
	// 	menuKey: "profession/vendors",
	// },
	{
		id: 20,
		path: AppRoutings.Vendors,
		element: Vendors,
		routeType: RouterType.protectedRoute,
		moduleName: "Vendors",
		pageName: "Vendors",
		pageTitle: "Vendors",
		description: "Vendors",
		menuKey: "partners",
	},
	{
		id: 21,
		path: AppRoutings.AddBusiness,
		element: AddBusiness,
		routeType: RouterType.protectedRoute,
		moduleName: "Add Business",
		pageName: "Add Business",
		pageTitle: "Add Business",
		description: "Add Business",
		menuKey: "partners",
	},
	{
		id: 22,
		path: AppRoutings.UpdateBusiness,
		element: UpdateBusiness,
		routeType: RouterType.protectedRoute,
		moduleName: "Update Business",
		pageName: "Update Business",
		pageTitle: "Update Business",
		description: "Update Business",
		menuKey: "partners",
	},
	{
		id: 23,
		path: AppRoutings.ProfessionInfo,
		element: ProfessionInfo,
		routeType: RouterType.protectedRoute,
		moduleName: "Profession Info",
		pageName: "Profession Info",
		pageTitle: "Profession Info",
		description: "Profession Info",
		menuKey: "profession/info",
	},
	{
		id: 24,
		path: AppRoutings.HistoryInfo,
		element: HistoryInfo,
		routeType: RouterType.protectedRoute,
		moduleName: "History Info",
		pageName: "History Info",
		pageTitle: "History Info",
		description: "History Info",
		menuKey: "history/info",
	},
	{
		id: 25,
		path: AppRoutings.PatientRecord,
		element: PatientRecord,
		routeType: RouterType.protectedRoute,
		moduleName: "Patient Record",
		pageName: "Patient Record",
		pageTitle: "Patient Record",
		description: "Patient Record",
		menuKey: "history/patient-records",
	},
	{
		id: 26,
		path: AppRoutings.HistoryRequestData,
		element: RequestData,
		routeType: RouterType.protectedRoute,
		moduleName: "Request Data History",
		pageName: "Request Data History",
		pageTitle: "Request Data History",
		description: "Request Data History",
		menuKey: "history/request-data",
	},
	{
		id: 27,
		path: AppRoutings.CancelHistory,
		element: CancelHistory,
		routeType: RouterType.protectedRoute,
		moduleName: "Cancel History",
		pageName: "Cancel History",
		pageTitle: "Cancel History",
		description: "Cancel History",
		menuKey: "history/cancel",
	},
	{
		id: 28,
		path: AppRoutings.PatientHistory,
		element: PatientHistory,
		routeType: RouterType.protectedRoute,
		moduleName: "Patient History",
		pageName: "Patient History",
		pageTitle: "Patient History",
		description: "Patient History",
		menuKey: "history/patient-records",
	},
	{
		id: 29,
		path: AppRoutings.Chats,
		element: Chats,
		routeType: RouterType.protectedRoute,
		moduleName: "Chats",
		pageName: "Chats",
		pageTitle: "Chats",
		description: "Chats",
		menuKey: "chats",
	},
	{
		id: 30,
		path: AppRoutings.EditProvider,
		element: MyProfile,
		routeType: RouterType.protectedRoute,
		moduleName: "EditProfile",
		pageName: "EditProfile",
		pageTitle: "Edit Profile",
		description: "EditProfile",
		menuKey: "provider/info",
	},
	{
		id: 31,
		path: AppRoutings.Accounts,
		element: Accounts,
		routeType: RouterType.protectedRoute,
		moduleName: "Accounts",
		pageName: "Accounts",
		pageTitle: "Accounts",
		description: "Accounts",
		// menuKey: "provider/accounts",
		menuKey: "access/user-access",
	},
	{
		id: 32,
		path: AppRoutings.Scheduling,
		element: Scheduling,
		routeType: RouterType.protectedRoute,
		moduleName: "Scheduling",
		pageName: "Scheduling",
		pageTitle: "Scheduling",
		description: "Scheduling",
		menuKey: "provider/scheduling",
	},
	{
		id: 33,
		path: AppRoutings.ProviderOnCall,
		element: ProviderOnCall,
		routeType: RouterType.protectedRoute,
		moduleName: "ProviderOnCall",
		pageName: "ProviderOnCall",
		pageTitle: "Provider On Call",
		description: "ProviderOnCall",
		menuKey: "provider/scheduling",
	},
	{
		id: 34,
		path: AppRoutings.ReviewShifts,
		element: ReviewShifts,
		routeType: RouterType.protectedRoute,
		moduleName: "ReviewShifts",
		pageName: "ReviewShifts",
		pageTitle: "Review Shifts",
		description: "ReviewShifts",
		menuKey: "provider/scheduling",
	},
	{
		id: 35,
		path: AppRoutings.EmailLogs,
		element: EmailLogs,
		routeType: RouterType.protectedRoute,
		moduleName: "EmailLogs",
		pageName: "EmailLogs",
		pageTitle: "Email Logs",
		description: "EmailLogs",
		menuKey: "history/email-logs",
	},
	{
		id: 36,
		path: AppRoutings.SMSLogs,
		element: SMSLogs,
		routeType: RouterType.protectedRoute,
		moduleName: "SMSLogs",
		pageName: "SMSLogs",
		pageTitle: "SMS Logs",
		description: "SMSLogs",
		menuKey: "history/sms-logs",
	},
	{
		id: 37,
		path: AppRoutings.ProviderPayrate,
		element: ProviderPayrate,
		routeType: RouterType.protectedRoute,
		moduleName: "ProviderPayrate",
		pageName: "ProviderPayrate",
		pageTitle: "Payrate",
		description: "ProviderPayrate",
		menuKey: "provider/info",
	},
	{
		id: 38,
		path: AppRoutings.CreateProvider,
		element: MyProfile,
		routeType: RouterType.protectedRoute,
		moduleName: "CreateProvider",
		pageName: "CreateProvider",
		pageTitle: "Create Profile",
		description: "CreateProvider",
		menuKey: "provider/info",
	},
	{
		id: 39,
		path: AppRoutings.BlockHistory,
		element: BlockHistory,
		routeType: RouterType.protectedRoute,
		moduleName: "BlockHistory",
		pageName: "BlockHistory",
		pageTitle: "BlockHistory",
		description: "BlockHistory",
		menuKey: "history/block",
	},
	{
		id: 40,
		path: AppRoutings.HaloHR,
		element: HaloHR,
		routeType: RouterType.protectedRoute,
		moduleName: "HaloHR",
		pageName: "HaloHR",
		pageTitle: "HaloHR",
		description: "HaloHR",
		menuKey: "halo/hr",
	},
	{
		id: 41,
		path: AppRoutings.HaloRequest,
		element: HaloRequests,
		routeType: RouterType.protectedRoute,
		moduleName: "HaloRequests",
		pageName: "HaloRequests",
		pageTitle: "Halo Requests",
		description: "HaloRequests",
		menuKey: "halo/request",
	},
	{
		id: 42,
		path: AppRoutings.HaloEmployee,
		element: HaloEmployee,
		routeType: RouterType.protectedRoute,
		moduleName: "HaloEmployee",
		pageName: "HaloEmployee",
		pageTitle: "Halo Employee",
		description: "Halo Employee",
		menuKey: "halo/employee",
	},
	{
		id: 43,
		path: AppRoutings.HaloWorkplace,
		element: HaloWorkPlace,
		routeType: RouterType.protectedRoute,
		moduleName: "HaloWorkPlace",
		pageName: "HaloWorkPlace",
		pageTitle: "Halo WorkPlace",
		description: "HaloWorkPlace",
		menuKey: "halo/workplace",
	},
	{
		id: 44,
		path: AppRoutings.Invoicing,
		element: TimesheetDetails,
		routeType: RouterType.protectedRoute,
		moduleName: "Invoicing",
		pageName: "Invoicing",
		pageTitle: "Invoicing",
		description: "Invoicing",
		menuKey: "provider/invoicing",
	},
	{
		id: 45,
		path: AppRoutings.FillDailyTimeSheet,
		element: FillDailyTimeSheet,
		routeType: RouterType.protectedRoute,
		moduleName: "FillDailyTimeSheet",
		pageName: "FillDailyTimeSheet",
		pageTitle: "Fill Daily TimeSheet",
		description: "Fill Daily TimeSheet",
		menuKey: "provider/invoicing",
	},
	{
		id: 46,
		path: AppRoutings.SubmitTimesheetSuccess,
		element: SubmitTimesheetSuccess,
		routeType: RouterType.protectedRoute,
		moduleName: "SubmitTimesheetSuccess",
		pageName: "SubmitTimesheetSuccess",
		pageTitle: "Submit Timesheet Success",
		description: "Submit Timesheet Success",
		menuKey: "provider/invoicing",
	},
	{
		id: 47,
		path: AppRoutings.BiWeeklyTimesheet,
		element: BiWeeklyTimesheet,
		routeType: RouterType.protectedRoute,
		moduleName: "BiWeeklyTimesheet",
		pageName: "BiWeeklyTimesheet",
		pageTitle: "BiWeeklyTimesheet",
		description: "BiWeeklyTimesheet",
		menuKey: "provider/invoicing",
	},
	{
		id: 48,
		path: AppRoutings.ProviderLocation,
		element: ProviderLocation,
		routeType: RouterType.protectedRoute,
		moduleName: "ProviderLocation",
		pageName: "ProviderLocation",
		pageTitle: "ProviderLocation",
		description: "ProviderLocation",
		menuKey: "physician/location",
	},
	{
		id: 49,
		path: AppRoutings.CreateAdmin,
		element: MyProfile,
		routeType: RouterType.protectedRoute,
		moduleName: "CreateAdmin",
		pageName: "CreateAdmin",
		pageTitle: "CreateAdmin",
		description: "CreateAdmin",
		menuKey: "access/user-access",
	},
	{
		id: 50,
		path: AppRoutings.EditAdmin,
		element: MyProfile,
		routeType: RouterType.protectedRoute,
		moduleName: "EditAdmin",
		pageName: "EditAdmin",
		pageTitle: "EditAdmin",
		description: "EditAdmin",
		menuKey: "access/user-access",
	},
];
