import React from "react";
import {
	Button,
	IconButton,
	TableCell,
	TableRow,
	ButtonProps,
	Card,
	CardHeader,
	CardContent,
	Typography,
	Collapse,
} from "@mui/material";
import {
	RegionIcon,
	CopyIcon,
	CopyDarkIcon,
	ClockPrimaryIcon,
	StatusCheckPrimaryIcon,
	PhysicianIcon,
} from "assests/images";
import { styled } from "@mui/material/styles";
import { copyTextOnClipBoard } from "utility/helpers";
import {
	IRegionGridButtons,
	IRegionRequest,
	IRegionGridColumns,
	IRegionSearchCriteria,
} from "utility/interfaces";
import { CustomCopyTooltip, RegionTableRowMenu } from "components";

type ComponentProps = {
	row: IRegionRequest;
	isSmallDevice: boolean;
	gridButtonsPermissions: IRegionGridButtons;
	gridColumnPermissions: IRegionGridColumns;
	searchCriteria: IRegionSearchCriteria;
};

interface ExpandMoreProps extends ButtonProps {
	expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
	const { expand, ...other } = props;
	return (
		<Button {...other} disableFocusRipple disableElevation disableRipple />
	);
})(({ theme, expand }) => ({
	marginLeft: "auto",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));

export function RegionTableRowData(props: ComponentProps) {
	// useStates
	const [tableUser, setTableUser] = React.useState<null | HTMLElement>(null);
	const [expanded, setExpanded] = React.useState(false);

	// Extract Props
	const {
		row,
		isSmallDevice,
		gridButtonsPermissions,
		gridColumnPermissions,
		searchCriteria,
	} = props;

	// Handled events and functions
	const handleTableMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setTableUser(event.currentTarget);
	};
	const handleTableMenuClose = () => {
		setTableUser(null);
	};
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};
	const handleOnClickCopy = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		text: string
	) => {
		e.stopPropagation();
		copyTextOnClipBoard(text);
	};

	// Page level variables
	const tableMenuOpen = Boolean(tableUser);

	return (
		<>
			{!isSmallDevice ? (
				<>
					<TableRow key={row.requestId} className="bg-white">
						{gridColumnPermissions?.isDisplayWaitTime && (
							<TableCell
								component="th"
								style={{ maxWidth: 130 }}
								scope="row"
								className="wrap min-w-130"
							>
								<CustomCopyTooltip
									canDoCopy={true}
									text="Copy"
									copyText={row.waitTime}
								>
									{row.waitTime}
								</CustomCopyTooltip>
							</TableCell>
						)}
						{gridColumnPermissions?.isDisplayClientMember && (
							<TableCell
								style={{ maxWidth: 170 }}
								component="th"
								scope="row"
								className="wrap min-w-170"
							>
								<CustomCopyTooltip
									canDoCopy={true}
									text="Copy"
									copyText={row.clientMember}
								>
									{row.clientMember}
								</CustomCopyTooltip>
							</TableCell>
						)}
						{gridColumnPermissions?.isDisplayBusiness && (
							<TableCell
								style={{ maxWidth: 150 }}
								component="th"
								scope="row"
								className="wrap min-w-150"
							>
								<CustomCopyTooltip
									canDoCopy={true}
									text="Copy"
									copyText={row.business}
								>
									{row.business}
								</CustomCopyTooltip>
							</TableCell>
						)}
						{gridColumnPermissions?.isDisplayAddress && (
							<TableCell component="th" scope="row" className="wrap min-w-200">
								<CustomCopyTooltip
									canDoCopy={true}
									text="Copy"
									copyText={row.address}
								>
									{row.address}
								</CustomCopyTooltip>
							</TableCell>
						)}
						{gridColumnPermissions?.isDisplayRequestStatus && (
							<TableCell
								style={{ maxWidth: 130 }}
								component="th"
								scope="row"
								className="wrap min-w-130"
							>
								<CustomCopyTooltip
									canDoCopy={true}
									text="Copy"
									copyText={row.requestStatus}
								>
									{row.requestStatus}
								</CustomCopyTooltip>
							</TableCell>
						)}
						{gridColumnPermissions?.isDisplayPhysician && (
							<TableCell
								style={{ maxWidth: 200 }}
								className="wrap min-w-200"
								component="th"
								scope="row"
							>
								<CustomCopyTooltip
									canDoCopy={true}
									text="Copy"
									copyText={row.physicianName}
								>
									{row.physicianName}
								</CustomCopyTooltip>
							</TableCell>
						)}
						{gridColumnPermissions?.isDisplayRegion && (
							<TableCell
								className="wrap min-w-100 max-w-100"
								component="th"
								scope="row"
							>
								<CustomCopyTooltip
									canDoCopy={true}
									text="Copy"
									copyText={row.regionName}
								>
									{row.regionName}
								</CustomCopyTooltip>
							</TableCell>
						)}
						<TableCell align="center" component="th" scope="row">
							<span className="table-cell-title">Actions</span>
							<Button
								id="table-button"
								aria-controls={tableMenuOpen ? "table-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={tableMenuOpen ? "true" : undefined}
								onClick={handleTableMenu}
								className="action-btn action-btn-primary"
							>
								Actions
							</Button>
						</TableCell>
					</TableRow>
				</>
			) : (
				// Mobile view
				<Card className="bg-white">
					<div className="card-container">
						<ExpandMore
							expand={expanded}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label="show more"
						>
							<Button
								id="table-button"
								aria-controls={tableMenuOpen ? "table-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={tableMenuOpen ? "true" : undefined}
								onClick={handleTableMenu}
								className="action-btn"
							>
								Actions
							</Button>
							{gridColumnPermissions?.isDisplayClientMember && (
								<CardHeader
									title={
										<React.Fragment>
											<span>{row.clientMember}</span>
											{row.clientMember && (
												<IconButton
													className="copy-btn"
													onClick={(e) =>
														handleOnClickCopy(e, row.clientMember)
													}
												>
													<img
														src={CopyDarkIcon}
														alt="copy"
														className="light-icon"
													/>
													<img
														src={CopyIcon}
														className="dark-icon"
														alt="copy"
													/>
												</IconButton>
											)}
										</React.Fragment>
									}
								/>
							)}
							<CardContent className="card-header-content">
								{gridColumnPermissions?.isDisplayAddress ? (
									<div className="card-subheader">
										<Typography variant="body2" color="text.secondary">
											{row.address}
											{row.address && (
												<IconButton
													className="copy-btn"
													onClick={(e) => handleOnClickCopy(e, row.address)}
												>
													<img
														src={CopyDarkIcon}
														alt="copy"
														className="light-icon"
													/>
													<img
														src={CopyIcon}
														className="dark-icon"
														alt="copy"
													/>
												</IconButton>
											)}
										</Typography>
									</div>
								) : (
									<>
										{gridColumnPermissions.isDisplayPhysician && (
											<div className="card-subheader">
												<Typography variant="body2">
													<span
														style={{
															lineBreak: "anywhere",
															textTransform: "lowercase",
														}}
													>
														{row.physicianName}
													</span>
													{row.physicianName && (
														<IconButton
															className="copy-btn"
															onClick={(e) =>
																handleOnClickCopy(e, row.physicianName)
															}
														>
															<img
																src={CopyDarkIcon}
																alt="copy"
																className="light-icon"
															/>
															<img
																src={CopyIcon}
																className="dark-icon"
																alt="copy"
															/>
														</IconButton>
													)}
												</Typography>
											</div>
										)}
									</>
								)}
								{gridColumnPermissions.isDisplayWaitTime && (
									<Typography>
										<img src={ClockPrimaryIcon} alt="time" />
										Wait Time:&nbsp;
										<span>&nbsp;({row?.waitTime})&nbsp;</span>
										{row.waitTime && (
											<IconButton
												className="copy-btn"
												onClick={(e) => handleOnClickCopy(e, row.waitTime)}
											>
												<img
													src={CopyDarkIcon}
													alt="copy"
													className="light-icon"
												/>
												<img src={CopyIcon} className="dark-icon" alt="copy" />
											</IconButton>
										)}
									</Typography>
								)}
							</CardContent>
						</ExpandMore>
						<Collapse in={expanded} timeout="auto" unmountOnExit>
							<CardContent>
								{gridColumnPermissions.isDisplayRequestStatus && (
									<div>
										<span>
											<img src={StatusCheckPrimaryIcon} alt="check" />
										</span>
										<Typography variant="h6">
											Status: &nbsp; <span>{row.requestStatus}</span>
											{row.requestStatus && (
												<IconButton
													className="copy-btn"
													onClick={(e) =>
														handleOnClickCopy(e, row.requestStatus)
													}
												>
													<img
														src={CopyDarkIcon}
														alt="copy"
														className="light-icon"
													/>
													<img
														src={CopyIcon}
														className="dark-icon"
														alt="copy"
													/>
												</IconButton>
											)}
										</Typography>
									</div>
								)}
								{gridColumnPermissions.isDisplayPhysician && (
									<div>
										<span>
											<img src={PhysicianIcon} alt="calender" />
										</span>
										<Typography variant="h6">
											Physician: &nbsp;
											<span>{row.physicianName}</span>
											{row.physicianName && (
												<IconButton
													className="copy-btn"
													onClick={(e) =>
														handleOnClickCopy(e, row.physicianName)
													}
												>
													<img
														src={CopyDarkIcon}
														alt="copy"
														className="light-icon"
													/>
													<img
														src={CopyIcon}
														className="dark-icon"
														alt="copy"
													/>
												</IconButton>
											)}
										</Typography>
									</div>
								)}
								{gridColumnPermissions.isDisplayBusiness && (
									<div>
										<span>
											<img src={PhysicianIcon} alt="check" />
										</span>
										<Typography variant="h6">
											<span>Business: &nbsp;</span>
											<span>{row.business}</span>
											{row.business && (
												<IconButton
													className="copy-btn"
													onClick={(e) => handleOnClickCopy(e, row.business)}
												>
													<img
														src={CopyDarkIcon}
														alt="copy"
														className="light-icon"
													/>
													<img
														src={CopyIcon}
														className="dark-icon"
														alt="copy"
													/>
												</IconButton>
											)}
										</Typography>
									</div>
								)}
								{gridColumnPermissions.isDisplayRegion && (
									<div>
										<span>
											<img src={RegionIcon} alt="check" />
										</span>
										<Typography variant="h6">
											Region: &nbsp; <span>{row.regionName}</span>
											{row.regionName && (
												<IconButton
													className="copy-btn"
													onClick={(e) => handleOnClickCopy(e, row.regionName)}
												>
													<img
														src={CopyDarkIcon}
														alt="copy"
														className="light-icon"
													/>
													<img
														src={CopyIcon}
														className="dark-icon"
														alt="copy"
													/>
												</IconButton>
											)}
										</Typography>
									</div>
								)}
								<div className="table-cell-btn-group"></div>
							</CardContent>
						</Collapse>
					</div>
				</Card>
			)}
			<RegionTableRowMenu
				row={row}
				tableMenuOpen={tableMenuOpen}
				tableUser={tableUser}
				handleTableMenuClose={handleTableMenuClose}
				gridButtonsPermissions={gridButtonsPermissions}
				searchCriteria={searchCriteria}
			/>
		</>
	);
}
