import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	FormControl,
	FormGroup,
	IconButton,
	Modal,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import { CheckBoxComponent } from "components";
import { commonModalStyle } from "utility/helpers";
import { IHistory, ISendMail } from "utility/interfaces";
import { toast } from "react-toastify";

type ComponentProps = {
	modalOpen: boolean;
	onModalClose: any;
	row: IHistory;
};

export function SendMailModal(props: ComponentProps) {
	// Extract Props
	const { modalOpen, onModalClose, row } = props;

	// useStates
	const [formValues, setFormValues] = useState<ISendMail>({
		email: row.email,
		isSelectEncounter: false,
		isSelectInsurance: false,
	});

	// Events and functions
	const handleOnChangeMessage = (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setFormValues({ ...formValues, email: e.target.value });
	};
	const handleOnClickSend = () => {
		if (
			formValues.email.trim() === "" ||
			(!formValues.isSelectEncounter && !formValues.isSelectInsurance)
		) {
			toast.error(
				"Please select atleast one document type and enter email address."
			);
			return;
		}
		console.log(formValues);
	};

	// useEffects
	useEffect(() => {
		setFormValues({
			email: row.email,
			isSelectEncounter: false,
			isSelectInsurance: false,
		});
	}, [modalOpen]);

	return (
		<>
			<Modal
				open={modalOpen}
				onClose={onModalClose}
				className="send-modal select-input-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">Send Email Form</Typography>
						<IconButton onClick={onModalClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<FormControl component="fieldset" className="radio-inputs">
							<FormGroup row>
								<CheckBoxComponent
									label="Encounter Form"
									isChecked={formValues.isSelectEncounter}
									handleOnChangeCheckbox={() =>
										setFormValues({
											...formValues,
											isSelectEncounter: !formValues.isSelectEncounter,
										})
									}
								/>
								<CheckBoxComponent
									label="Insurance Form"
									isChecked={formValues.isSelectInsurance}
									handleOnChangeCheckbox={() =>
										setFormValues({
											...formValues,
											isSelectInsurance: !formValues.isSelectInsurance,
										})
									}
								/>
							</FormGroup>
						</FormControl>
						<div>
							<TextField
								id="block"
								autoFocus
								label="Email"
								value={formValues.email}
								name="email"
								onChange={handleOnChangeMessage}
								fullWidth
							/>
						</div>
						<div className="modal-footer">
							<Tooltip title="Send">
								<Button
									variant="contained"
									disabled={
										formValues.email.trim() === "" ||
										(!formValues.isSelectEncounter &&
											!formValues.isSelectInsurance)
									}
									onClick={handleOnClickSend}
								>
									Send
								</Button>
							</Tooltip>
							<Tooltip title="Cancel">
								<Button variant="outlined" onClick={onModalClose}>
									Cancel
								</Button>
							</Tooltip>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
