import {
	TableRow,
	TableCell,
	Tooltip,
	Button,
	Card,
	CardContent,
	CardHeader,
	Collapse,
	Typography,
} from "@mui/material";
import { CrossRedIcon, LocationIcon } from "assests/images";
import { AddEditHaloEmployeeModal, ExpandMore, WarningModal } from "components";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import React, { useContext, useState } from "react";
import haloService from "services/halo-service";
import { createCommonAPICall } from "utility/helpers";
import { IHaloEmployee } from "utility/interfaces";

type ComponentProps = {
	row: IHaloEmployee;
	getHaloEmpData: any;
	isSmallDevice: boolean;
};

export const HaloEmployeeTableRow = (props: ComponentProps) => {
	// Extract props
	const { row, isSmallDevice, getHaloEmpData } = props;

	// Page level variables
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// Use state
	const [expanded, setExpanded] = useState(false);

	const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);

	const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);

	// handle events
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const handleAddEditModalOpen = () => setIsAddEditModalOpen(true);

	const handleAddEditModalClose = () => setIsAddEditModalOpen(false);

	const handleDeleteConfirm = async () => {
		console.log("delete", row.workPlaceEmployeeId);
		const requestBody = {
			workPlaceEmployeeId: row.workPlaceEmployeeId,
		};

		const data = await createCommonAPICall({
			requestBody,
			apiService: haloService.deleteHaloEmployee,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) getHaloEmpData();
	};

	return (
		<>
			{!isSmallDevice ? (
				<>
					<TableRow>
						<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
							{row.lastName + ", " + row.firstName}
						</TableCell>
						<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
							{row.email ?? "-"}
						</TableCell>
						<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
							{row.isIndividual ? "Individual" : (row.workPlaceName ?? "-")}
						</TableCell>
						<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
							<Tooltip title="Edit Employee">
								<Button
									id="table-button-edit"
									variant="outlined"
									className="action-btn-primary action-btn"
									style={{ marginRight: 4 }}
									onClick={handleAddEditModalOpen}
								>
									Edit
								</Button>
							</Tooltip>
							<Tooltip title="Delete Employee">
								<Button
									id="table-button-edit"
									variant="outlined"
									className="action-btn-primary action-btn"
									style={{ marginRight: 4 }}
									onClick={() => setIsDeleteWarningOpen(true)}
								>
									Delete
								</Button>
							</Tooltip>
						</TableCell>
					</TableRow>
				</>
			) : (
				<Card className="bg-white">
					<div className="card-container">
						<ExpandMore
							expand={expanded}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label="show more"
						>
							<CardHeader
								title={
									<React.Fragment>
										<span>{row.lastName + ", " + row.firstName}</span>
									</React.Fragment>
								}
							/>
							<CardContent className="card-header-content">
								<div className="card-subheader">
									<Typography variant="body2" color="text.secondary">
										{row.email}
									</Typography>
								</div>
							</CardContent>
						</ExpandMore>
						<Collapse in={expanded} timeout="auto" unmountOnExit>
							<CardContent>
								<div>
									<span>
										<img src={LocationIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Work Place Name:&nbsp;
										<span>
											{row.isIndividual
												? "Individual"
												: (row.workPlaceName ?? "-")}
										</span>
									</Typography>
								</div>
								<div className="table-cell-btn-group">
									<Button variant="outlined" onClick={handleAddEditModalOpen}>
										Edit
									</Button>
									<Button
										variant="outlined"
										onClick={() => setIsDeleteWarningOpen(true)}
									>
										Delete
									</Button>
								</div>
							</CardContent>
						</Collapse>
					</div>
				</Card>
			)}

			{/* Page Modal */}
			<AddEditHaloEmployeeModal
				isOpen={isAddEditModalOpen}
				handleClose={handleAddEditModalClose}
				haloEmpData={row}
				getHaloEmpData={getHaloEmpData}
			/>

			<WarningModal
				isModalOpen={isDeleteWarningOpen}
				handleOnClickCloseModal={() => setIsDeleteWarningOpen(false)}
				handleOnClickOk={handleDeleteConfirm}
				title="Confirmation"
				warningMessage="Are you sure you want to delete this Employee?"
				okButtonText="Confirm"
				closeButtonText="cancel"
				warningIcon={CrossRedIcon}
			/>
		</>
	);
};
