import {
	Button,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	OutlinedInput,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { ArrowDownIcon, DownloadIcon, UploadcloudIcon } from "assests/images";
import { UserContext } from "contexts/user-context";
import React, { useContext, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { AccountType } from "utility/enums/request-status";
import { IMyProfileInfo } from "utility/interfaces";
import EditIcon from "@mui/icons-material/Edit";
import { createCommonAPICall } from "utility/helpers";
import profileService from "services/profile-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import {
	CheckBoxComponent,
	CreateSignatureModal,
	PreviewFileModal,
	PreviewImageModal,
	WarningModal,
} from "components";
import PreviewIcon from "@mui/icons-material/Preview";
import { Constants } from "utility/enums/constants";
import { useNavigate } from "react-router-dom";

type ComponentProps = {
	formValues: IMyProfileInfo;
	getMyProfile: any;
	handleFormValuesChange: any;
	handleSaveProfile: any;
	formValidator: UseFormReturn<IMyProfileInfo, any>;
	isNew: boolean;
	setFormValues: any;
};

export function ProviderProfile(props: ComponentProps) {
	// Extract props
	const {
		formValues,
		getMyProfile,
		handleFormValuesChange,
		handleSaveProfile,
		formValidator,
		isNew,
		setFormValues,
	} = props;

	const contextUser = useContext(UserContext);
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	const navigate = useNavigate();

	// State variables

	const [selectedFileNames, setSelectedFileNames] = useState<string[]>([]);

	const [isEditProviderProfile, setIsEditProviderProfile] = useState(false);

	const [photoErrorMessage, setPhotoErrorMessage] = useState("");

	const [signatureErrorMessage, setSignatureErrorMessage] = useState("");

	const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);

	const [isPreviewImageOpen, setIsPreviewImageOpen] = useState(false);

	const {
		handleSubmit,
		register,
		formState: { errors },
	} = formValidator;

	// handle events
	const handlePhotoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files.length > 0) {
			if (e.target.files[0].size > Constants.MaxImageSize.valueOf()) {
				setPhotoErrorMessage(
					"Maximum file size limit exceeded. Please upload a smaller file"
				);
			} else {
				setPhotoErrorMessage("");
				await handleUpload("Photo", e.target.files[0]);
			}
		} else setPhotoErrorMessage("Please Upload a image");
	};

	const handleSignatureUpload = async (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		debugger;
		if (e.target.files && e.target.files.length > 0) {
			if (e.target.files[0].size > Constants.MaxImageSize.valueOf()) {
				setSignatureErrorMessage(
					"Maximum file size limit exceeded. Please upload a smaller file"
				);
			} else {
				setSignatureErrorMessage("");
				await handleUpload("Signature", e.target.files[0]);
			}
		} else setSignatureErrorMessage("Please Upload a signature");
	};

	const handleUpload = async (category: string, file: File) => {
		const formData = new FormData();
		// Array.prototype.forEach.call(e.target.files, (file: File) => {
		// 	formData.append("file", file);
		// });

		// for (var i = 0; i < e.target.files.length; i++) {
		// 	formData.append("files", e.target.files[i]);
		// }
		formData.append("files", file);
		console.log("File: ", formData.get("files"));
		formData.append("providerId", formValues.physicianId.toString());
		formData.append("imageCategory", category);
		formData.append("physicianGuid", formValues.physicianGuid ?? "");

		const data = await createCommonAPICall({
			requestBody: formData,
			apiService: profileService.providerImageUpload,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			console.log(data);
			if (isNew) {
				if (category === "Photo") {
					setFormValues({
						...formValues,
						photo: data.data.fileName,
						physicianGuid: data.data.physicianGuid,
						photoPath: data.data.path,
					});
				} else if (category == "Agreement") {
					setFormValues({
						...formValues,
						isAgreementDoc: true,
						physicianGuid: data.data.physicianGuid,
					});
				} else if (category == "Background") {
					setFormValues({
						...formValues,
						isBackgroundDoc: true,
						physicianGuid: data.data.physicianGuid,
					});
				} else if (category == "Training") {
					setFormValues({
						...formValues,
						isTrainingDoc: true,
						physicianGuid: data.data.physicianGuid,
					});
				} else if (category == "nondisclosure") {
					setFormValues({
						...formValues,
						isNonDisclosureDoc: true,
						physicianGuid: data.data.physicianGuid,
					});
				}
			} else {
				getMyProfile();
			}
		}
	};

	const handleSignatureModalOpen = () => setIsSignatureModalOpen(true);

	const handleSignatureModalClose = () => setIsSignatureModalOpen(false);

	const handleSignatureSubmit = async (signature: Blob) => {
		console.log(URL.createObjectURL(signature));
		const file = new File([signature], "signature.jpg", signature);

		if (file.size > Constants.MaxImageSize.valueOf()) {
			setSignatureErrorMessage(
				"Maximum file size limit exceeded. Please upload a smaller file"
			);
		} else {
			setSignatureErrorMessage("");
			await handleUpload("Signature", file);
		}

		handleSignatureModalClose();
	};

	const handleViewDownloadDoc = async (filename: string) => {
		console.log(filename);
		const requestBody = {
			fileName: filename,
			physicianId: formValues.physicianId,
		};

		const data = await createCommonAPICall({
			requestBody,
			apiService: profileService.viewDownloadFile,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			console.log(data);
			let link = document.createElement("a");
			link.href = data.data.filePath;
			link.download = data.data.fileName;
			link.target = "_blank";
			document.getElementById("downloadBtn")?.appendChild(link);
			link.click();
			link.parentNode?.removeChild(link);
		}
	};

	return (
		<>
			<Typography variant="h4">Provider Profile</Typography>
			<div>
				<Grid container columnSpacing={{ sm: 2, lg: 3 }}>
					<Grid item xs={12} sm={6}>
						<TextField
							{...register("businessName")}
							error={errors.businessName?.message ? true : false}
							helperText={errors.businessName?.message?.toString()}
							disabled={!isNew && !isEditProviderProfile}
							fullWidth
							id="business-name"
							label="Business Name"
							variant="outlined"
							name="businessName"
							value={formValues.businessName}
							onChange={handleFormValuesChange}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							{...register("businessWebsite")}
							error={errors.businessWebsite?.message ? true : false}
							helperText={errors.businessWebsite?.message?.toString()}
							disabled={!isNew && !isEditProviderProfile}
							fullWidth
							id="business-website"
							label="Business Website"
							variant="outlined"
							name="businessWebsite"
							value={formValues.businessWebsite}
							onChange={handleFormValuesChange}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl fullWidth variant="outlined">
							<OutlinedInput
								label="Photo"
								id="upload-photo"
								type="text"
								value={selectedFileNames}
								disabled={!isEditProviderProfile}
								className="upload-input"
								placeholder="Select Photo"
								endAdornment={
									<InputAdornment position="end">
										<Tooltip title="Click Here To Upload">
											<Button
												variant="contained"
												component="label"
												disableElevation
												disableFocusRipple
												disableRipple
												disabled={
													contextUser.userRoleId !== AccountType.Physician &&
													!isNew &&
													!isEditProviderProfile
												}
											>
												<img src={UploadcloudIcon} alt="upload" />
												<span>Upload</span>
												<input
													onChange={handlePhotoUpload}
													hidden
													accept="image/*"
													type="file"
													name="files"
												/>
											</Button>
										</Tooltip>
									</InputAdornment>
								}
							/>
							<FormHelperText error>{photoErrorMessage}</FormHelperText>
						</FormControl>
						{formValues.photoPath !== "" && (
							<>
								<img
									src={formValues.photoPath}
									className="provider-profile-img"
								/>
								{contextUser.userRoleId === AccountType.Admin && (
									<Tooltip title="Preview">
										<IconButton onClick={() => setIsPreviewImageOpen(true)}>
											<PreviewIcon />
										</IconButton>
									</Tooltip>
								)}
							</>
						)}
					</Grid>
					{!isNew && (
						<Grid item xs={12} sm={6}>
							<Grid container columnSpacing={{ xs: "5px" }}>
								<Grid item xs={8} md={9}>
									<FormControl fullWidth variant="outlined">
										<OutlinedInput
											label="Photo"
											id="upload-signature"
											type="text"
											disabled={!isEditProviderProfile}
											value={selectedFileNames}
											className="upload-input"
											placeholder="Select Signature"
											endAdornment={
												<InputAdornment position="end">
													<Tooltip title="Click Here To Upload">
														<Button
															variant="contained"
															component="label"
															disableElevation
															disableFocusRipple
															disableRipple
															disabled={
																contextUser.userRoleId !==
																	AccountType.Physician &&
																!isNew &&
																!isEditProviderProfile
															}
														>
															<img src={UploadcloudIcon} alt="upload" />
															<span>Upload</span>
															<input
																onChange={handleSignatureUpload}
																hidden
																accept="image/*"
																type="file"
																name="files"
															/>
														</Button>
													</Tooltip>
												</InputAdornment>
											}
										/>
										<FormHelperText error>
											{signatureErrorMessage}
										</FormHelperText>
									</FormControl>
									{formValues.signaturePath !== "" && (
										<img
											src={formValues.signaturePath}
											className="provider-signature"
										/>
									)}
								</Grid>
								<Grid item xs={4} md={3}>
									<Tooltip title="Click Here To Create">
										<Button
											variant="contained"
											component="label"
											disableElevation
											disableFocusRipple
											disableRipple
											onClick={handleSignatureModalOpen}
											disabled={
												contextUser.userRoleId !== AccountType.Physician &&
												!isNew &&
												!isEditProviderProfile
											}
											className="create-sogn-btn"
										>
											<EditIcon />
											<span>Create</span>
										</Button>
									</Tooltip>
								</Grid>
							</Grid>
						</Grid>
					)}

					{contextUser.userRoleId === AccountType.Admin && (
						<Grid item xs={12}>
							<TextField
								fullWidth
								disabled={!isNew && !isEditProviderProfile}
								value={formValues.adminNotes}
								id="admin-notes"
								label={<React.Fragment>Admin Notes</React.Fragment>}
								variant="outlined"
								name="adminNotes"
								inputProps={{
									style: { minHeight: "80px" },
								}}
								onChange={handleFormValuesChange}
								multiline
								rows={2}
							/>
						</Grid>
					)}
				</Grid>
			</div>
			{contextUser.userRoleId === AccountType.Admin && (
				<div className="request-btn-group">
					{isEditProviderProfile ? (
						<>
							<Tooltip title="Save">
								<Button
									onClick={() => {
										setIsEditProviderProfile(false);
										handleSubmit(handleSaveProfile)();
									}}
									variant="contained"
								>
									Save
								</Button>
							</Tooltip>
							<Tooltip title="Cancel">
								<Button
									variant="outlined"
									onClick={() => {
										getMyProfile();
										setIsEditProviderProfile(false);
									}}
								>
									Cancel
								</Button>
							</Tooltip>
						</>
					) : (
						<>
							{contextUser.userRoleId === AccountType.Admin && !isNew && (
								<Tooltip title="Edit">
									<Button
										variant="contained"
										onClick={() => {
											setIsEditProviderProfile(true);
										}}
									>
										Edit
									</Button>
								</Tooltip>
							)}
						</>
					)}
				</div>
			)}

			{(isNew || formValues.isAnyDocUploaded) && (
				<>
					<hr />
					{contextUser.userRoleId === AccountType.Admin && (
						<Typography variant="h4">Onboarding</Typography>
					)}

					<table style={{ width: "100%" }}>
						{(contextUser.userRoleId === AccountType.Admin ||
							formValues.isAgreementDoc) && (
							<tr className="profile-files-row">
								{contextUser.userRoleId === AccountType.Admin && (
									<td className="checkbox-table-cell">
										<CheckBoxComponent
											label=""
											isChecked={formValues.isAgreementDoc}
											handleOnChangeCheckbox={() => {}}
											disabled
										/>
									</td>
								)}
								<td>
									<Typography variant="h5" sx={{ margin: "10px 15px" }}>
										{contextUser.userRoleId === AccountType.Admin
											? "Independent Contractor Agreement"
											: "Provider Agreement"}
									</Typography>
								</td>
								<td>
									{contextUser.userRoleId === AccountType.Admin && (
										<Button
											variant="contained"
											component="label"
											className="profile-files-btn"
										>
											<img src={UploadcloudIcon} />
											<span>Upload</span>
											<input
												onChange={(e) => {
													if (e.target.files && e.target.files.length > 0)
														handleUpload("Agreement", e.target.files[0]);
												}}
												hidden
												accept="*"
												type="file"
												name="files"
											/>
										</Button>
									)}
									{formValues.isAgreementDoc && (
										<Button
											variant="contained"
											onClick={() => handleViewDownloadDoc("Agreement")}
											className="profile-files-btn"
										>
											<PreviewIcon />
											<span>View</span>
										</Button>
									)}
								</td>
							</tr>
						)}

						{contextUser.userRoleId === AccountType.Admin && (
							<tr className="profile-files-row">
								<td className="checkbox-table-cell">
									<CheckBoxComponent
										label=""
										isChecked={formValues.isBackgroundDoc}
										handleOnChangeCheckbox={() => {}}
										disabled
									/>
								</td>
								<td>
									<Typography variant="h5" sx={{ margin: "10px 15px" }}>
										Background Check
									</Typography>
								</td>
								<td>
									<Button
										variant="contained"
										component="label"
										className="profile-files-btn"
									>
										<img src={UploadcloudIcon} />
										<span>Upload</span>
										<input
											onChange={(e) => {
												if (e.target.files && e.target.files.length > 0)
													handleUpload("Background", e.target.files[0]);
											}}
											hidden
											accept="*"
											type="file"
											name="files"
										/>
									</Button>
									{formValues.isBackgroundDoc && (
										<Button
											variant="contained"
											onClick={() => handleViewDownloadDoc("Background")}
											className="profile-files-btn"
										>
											<PreviewIcon />
											<span>View</span>
										</Button>
									)}
								</td>
							</tr>
						)}

						{(contextUser.userRoleId === AccountType.Admin ||
							formValues.isTrainingDoc) && (
							<tr className="profile-files-row">
								{contextUser.userRoleId === AccountType.Admin && (
									<td className="checkbox-table-cell">
										<CheckBoxComponent
											label=""
											isChecked={formValues.isTrainingDoc}
											handleOnChangeCheckbox={() => {}}
											disabled
										/>
									</td>
								)}
								<td>
									<Typography variant="h5" sx={{ margin: "10px 15px" }}>
										HIPAA Compliance
									</Typography>
								</td>
								<td>
									{contextUser.userRoleId === AccountType.Admin && (
										<Button
											variant="contained"
											component="label"
											className="profile-files-btn"
										>
											<img src={UploadcloudIcon} />
											<span>Upload</span>
											<input
												onChange={(e) => {
													if (e.target.files && e.target.files.length > 0)
														handleUpload("Training", e.target.files[0]);
												}}
												hidden
												accept="*"
												type="file"
												name="files"
											/>
										</Button>
									)}
									{formValues.isTrainingDoc && (
										<Button
											variant="contained"
											onClick={() => handleViewDownloadDoc("Training")}
											className="profile-files-btn"
										>
											<PreviewIcon />
											<span>View</span>
										</Button>
									)}
								</td>
							</tr>
						)}

						{(contextUser.userRoleId === AccountType.Admin ||
							formValues.isNonDisclosureDoc) && (
							<tr className={!isNew ? "profile-files-row" : ""}>
								{contextUser.userRoleId === AccountType.Admin && (
									<td className="checkbox-table-cell">
										<CheckBoxComponent
											label=""
											isChecked={formValues.isNonDisclosureDoc}
											handleOnChangeCheckbox={() => {}}
											disabled
										/>
									</td>
								)}
								<td>
									<Typography variant="h5" sx={{ margin: "10px 15px" }}>
										Non-disclosure Agreement
									</Typography>
								</td>
								<td>
									{contextUser.userRoleId === AccountType.Admin && (
										<Button
											variant="contained"
											component="label"
											className="profile-files-btn"
										>
											<img src={UploadcloudIcon} />
											<span>Upload</span>
											<input
												onChange={(e) => {
													if (e.target.files && e.target.files.length > 0)
														handleUpload("NonDisclosure", e.target.files[0]);
												}}
												hidden
												accept="*"
												type="file"
												name="files"
											/>
										</Button>
									)}
									{formValues.isNonDisclosureDoc && (
										<Button
											variant="contained"
											onClick={() => handleViewDownloadDoc("NonDisclosure")}
											className="profile-files-btn"
										>
											<PreviewIcon />
											<span>View</span>
										</Button>
									)}
								</td>
							</tr>
						)}

						{(contextUser.userRoleId === AccountType.Admin ||
							formValues.isLicenseDoc) &&
							!isNew && (
								<tr>
									{contextUser.userRoleId === AccountType.Admin && (
										<td className="checkbox-table-cell">
											<CheckBoxComponent
												label=""
												isChecked={formValues.isLicenseDoc}
												handleOnChangeCheckbox={() => {}}
												disabled
											/>
										</td>
									)}
									<td>
										<Typography variant="h5" sx={{ margin: "10px 15px" }}>
											License Document
										</Typography>
									</td>
									<td>
										{contextUser.userRoleId === AccountType.Admin && (
											<Button
												variant="contained"
												component="label"
												className="profile-files-btn"
											>
												<img src={UploadcloudIcon} />
												<span>Upload</span>
												<input
													onChange={(e) => {
														if (e.target.files && e.target.files.length > 0)
															handleUpload("License", e.target.files[0]);
													}}
													hidden
													accept="*"
													type="file"
													name="files"
												/>
											</Button>
										)}
										{formValues.isLicenseDoc && (
											<Button
												variant="contained"
												onClick={() => handleViewDownloadDoc("License")}
												className="profile-files-btn"
											>
												<PreviewIcon />
												<span>View</span>
											</Button>
										)}
									</td>
								</tr>
							)}
					</table>
					<hr />
				</>
			)}

			<div id="downloadBtn"></div>

			{/* Page modals */}
			<CreateSignatureModal
				isOpen={isSignatureModalOpen}
				handleClose={handleSignatureModalClose}
				handleSubmit={handleSignatureSubmit}
			/>

			<PreviewImageModal
				isModalOpen={isPreviewImageOpen}
				handleModalClose={() => setIsPreviewImageOpen(false)}
				imageSrc={formValues.photoPath}
			/>
		</>
	);
}
