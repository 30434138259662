import { Box, Typography } from "@mui/material";
import { BlockHistoryTable } from "components";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import React, { useContext, useEffect, useState } from "react";
import historyService from "services/history-service";
import { Constants } from "utility/enums/constants";
import { SortOrder } from "utility/enums/sort-order";
import { createCommonAPICall } from "utility/helpers";
import {
	IBlockHistoryList,
	ICancelHistorySearchCriteria,
} from "utility/interfaces";

const BlockHistory: React.FC = () => {
	// Page level variables
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// State variables
	const [blockHistoryPageInfo, setBlockHistoryPageInfo] =
		useState<IBlockHistoryList>({
			requests: [],
			totalRecords: 0,
		});
	const [searchCriteria, setSearchCriteria] =
		useState<ICancelHistorySearchCriteria>({
			PageIndexId: 0,
			PageSize: Constants.DefaultTablePageSize.valueOf(),
			SortOrder: SortOrder.descending,
			SortBy: "CreatedDate",
			SearchBy: "",
			name: null,
			date: null,
			email: null,
			phoneNumber: null,
		});

	// Handle Evnets
	const getBlockHistoryInfo = async () => {
		console.log(searchCriteria);
		let date = searchCriteria.date
			? new Date(searchCriteria.date).toLocaleString("en-US")
			: null;
		const requestBody: ICancelHistorySearchCriteria = {
			...searchCriteria,
			date: date == "Invalid Date" ? null : date,
		};
		const data = await createCommonAPICall({
			requestBody,
			apiService: historyService.getBlockHistoryGrid,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			console.log(data);
			setBlockHistoryPageInfo(data.data);
		}
	};

	const handleOnSearchCriteriaChange = (
		searchCriteria: ICancelHistorySearchCriteria
	) => {
		setSearchCriteria(searchCriteria);
	};

	// Use Effects
	useEffect(() => {
		getBlockHistoryInfo();
	}, [searchCriteria]);

	return (
		<>
			<Box>
				<div className="chatbox-overlay"></div>
				<main className="main-content">
					<div
						className="overlay"
						onClick={() => document.body.classList.toggle("sidebar-toggle")}
					></div>
					<Box className="tab-item-header">
						<Typography variant="h2">Block History</Typography>
					</Box>
					<BlockHistoryTable
						blockHistoryPageInfo={blockHistoryPageInfo}
						handleOnSearchCriteriaChange={handleOnSearchCriteriaChange}
						searchCriteria={searchCriteria}
						getBlockHistoryInfo={getBlockHistoryInfo}
					/>
				</main>
			</Box>
		</>
	);
};

export default BlockHistory;
