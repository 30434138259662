import * as yup from "yup";

export const addVendorSchema = yup.object().shape({
	vendorName: yup.string().required("Business name is required."),
	faxNumber: yup.string().required("Fax number is required."),
	// phoneNumber: yup.string().required("Phone number is required."),
	email: yup
		.string()
		.email("Email is not valid.")
		.required("Email is required."),
	profession: yup.number().moreThan(0, "Please select a profession."),
});

export const createProfession = yup.object().shape({
	professionName: yup.string().required("Profession name is required."),
});
