import React, { useState } from "react";
import tokenManager from "utility/auth-guards/token-manager";
const PhysicianTrackContext = React.createContext();
const PhysicianTrackDispatchContext = React.createContext();

function PhysicianTrackProvider({ children }) {
	const ids = tokenManager.getTrackIds();
	const [trackIds, setTrackIds] = useState(ids);

	const setTrackingIds = (trackIds) => {
		setTrackIds(trackIds);
		tokenManager.setTrackIds(trackIds);
	};

	return (
		<PhysicianTrackContext.Provider value={trackIds}>
			<PhysicianTrackDispatchContext.Provider value={setTrackingIds}>
				{children}
			</PhysicianTrackDispatchContext.Provider>
		</PhysicianTrackContext.Provider>
	);
}

export {
	PhysicianTrackProvider,
	PhysicianTrackContext,
	PhysicianTrackDispatchContext,
};
