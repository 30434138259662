import React, { useContext, useState } from "react";
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Checkbox,
	Collapse,
	TableCell,
	Tooltip,
	Typography,
} from "@mui/material";
import { IContactProvider, IProvider } from "utility/interfaces";
import {
	UncheckIcon,
	CheckIcon,
	DoctorPrimaryIcon,
	StatusCheckPrimaryIcon,
} from "assests/images";
import { ContactProviderModal } from "./contact-provider-modal";
import { useNavigate } from "react-router-dom";
import { AppRoutings } from "utility/enums/app-routings";
import { createCommonAPICall } from "utility/helpers";
import roleService from "services/role-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { ExpandMore } from "components";

type ComponentProps = {
	row: IProvider;
	handleOnSelectRow: any;
	index: number;
	isSmallDevice: boolean;
};

export function ProviderInfoTableRow(props: ComponentProps) {
	// use context
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// Extract Props
	const { row, handleOnSelectRow, index, isSmallDevice } = props;

	// page level variables
	const navigate = useNavigate();

	// useStates
	const [openContactProvider, setOpenContactProvider] = useState(false);
	const [expanded, setExpanded] = useState(false);

	// handle events
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const handleOnClickDelete = () => {
		console.log("Delete logic here");
	};

	const handleSendMessage = (values: IContactProvider) => {
		console.log("Send message:", row.physicianId, values);
		const requestBody = {
			providerId: row.physicianId,
			message: values.message,
			communicationBy: values.communicationBy,
		};
		createCommonAPICall({
			requestBody,
			apiService: roleService.sendMsgProvider,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		}).then((data) => {
			console.log(data);
			setOpenContactProvider(false);
		});
	};

	const CheckedboxIcon = () => {
		return <img src={CheckIcon} alt="checkbox" />;
	};
	const CheckboxIcon = () => {
		return <img src={UncheckIcon} alt="checkbox" />;
	};

	return (
		<>
			{!isSmallDevice ? (
				<>
					<TableCell padding="checkbox" align="center">
						<Checkbox
							onClick={() => handleOnSelectRow(row.physicianId)}
							icon={<CheckboxIcon />}
							checkedIcon={<CheckedboxIcon />}
							checked={row.isNotificationStopped}
							disableRipple
							disableFocusRipple
							inputProps={{
								"aria-labelledby": `enhanced-table-checkbox-${index}`,
							}}
						/>
					</TableCell>
					<TableCell component="th" scope="row" className="upload-file-col">
						<div className="upload-file">
							<Box className="display-small-table-label">
								Provider Name: &nbsp;
							</Box>
							<span>{row.providerName}</span>
						</div>
					</TableCell>
					<TableCell scope="row">
						<div>
							<Box className="display-small-table-label">Role: &nbsp;</Box>
							<span>{row.role}</span>
						</div>
					</TableCell>
					<TableCell scope="row">
						<div>
							<Box className="display-small-table-label">
								On Call Status: &nbsp;
							</Box>
							<span>{row.onCallStatus}</span>
						</div>
					</TableCell>
					<TableCell scope="row">
						<div>
							<Box className="display-small-table-label">Status: &nbsp;</Box>
							<span>{row.statusStr}</span>
						</div>
					</TableCell>
					<TableCell align="center">
						<div className="upload-actions">
							<Tooltip title="Contact">
								<Button
									id="table-button-edit"
									onClick={() => setOpenContactProvider(true)}
									className="action-btn action-btn-primary"
									style={{ marginRight: 4 }}
								>
									Contact
								</Button>
							</Tooltip>
							<Tooltip title="Edit">
								<Button
									id="table-button-delete"
									className="action-btn action-btn-primary"
									onClick={() => {
										navigate(
											AppRoutings.EditProvider.replace(
												":providerId",
												row.queryString
											),
											{
												state: {
													backURL: AppRoutings.ProviderInfo,
												},
											}
										);
									}}
								>
									Edit
								</Button>
							</Tooltip>
						</div>
					</TableCell>
				</>
			) : (
				<Card className="bg-white">
					<div className="card-container">
						<ExpandMore
							expand={expanded}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label="show more"
						>
							<CardHeader
								title={
									<React.Fragment>
										<Checkbox
											onClick={(e) => {
												e.stopPropagation();
												handleOnSelectRow(row.physicianId);
											}}
											icon={<CheckboxIcon />}
											checkedIcon={<CheckedboxIcon />}
											checked={row.isNotificationStopped}
											disableRipple
											disableFocusRipple
											inputProps={{
												"aria-labelledby": `enhanced-table-checkbox-${index}`,
											}}
										/>
										<div>
											<span>{row.providerName}</span>
										</div>
									</React.Fragment>
								}
							/>
							<CardContent className="card-header-content">
								<Typography>
									On Call Status:&nbsp;
									<span>{row.onCallStatus}</span>
								</Typography>
							</CardContent>
						</ExpandMore>
						<Collapse in={expanded} timeout="auto" unmountOnExit>
							<CardContent>
								<div>
									<span>
										<img src={DoctorPrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Role:&nbsp;
										<span>{row.role}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={StatusCheckPrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Status:&nbsp;
										<span>{row.statusStr}</span>
									</Typography>
								</div>
								<div className="table-cell-btn-group">
									<Button
										id="table-button-edit"
										onClick={() => setOpenContactProvider(true)}
										variant="outlined"
									>
										Contact
									</Button>
									<Button
										id="table-button-delete"
										variant="outlined"
										onClick={() => {
											navigate(
												AppRoutings.EditProvider.replace(
													":providerId",
													row.queryString
												),
												{
													state: {
														backURL: AppRoutings.ProviderInfo,
													},
												}
											);
										}}
									>
										Edit
									</Button>
								</div>
							</CardContent>
						</Collapse>
					</div>
				</Card>
			)}

			<ContactProviderModal
				modalOpen={openContactProvider}
				onModalClose={() => setOpenContactProvider(false)}
				providerId={row.physicianId}
				handleSendMessage={handleSendMessage}
			/>
		</>
	);
}
