import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { ArrowBack } from "assests/images";
import {
	AdministratorInformation,
	AccountInformation,
	MailingAndBillingInformation,
	ContactProviderModal,
	WarningModal,
	ProviderProfile,
	CheckBoxComponent,
} from "components";
import { useNavigate, useParams } from "react-router-dom";
import {
	IContactProvider,
	IDropDownList,
	IMyProfileInfo,
} from "utility/interfaces";
import { createCommonAPICall } from "utility/helpers";
import profileService from "services/profile-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { UserContext } from "contexts/user-context";
import { AccountType } from "utility/enums/request-status";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
	createAdminSchema,
	createProviderSchema,
	myProfileSchema,
	providerMyProfile,
} from "utility/validations";
import roleService from "services/role-service";
import { AppRoutings } from "utility/enums/app-routings";

const MyProfile: React.FC = () => {
	const { providerId, adminId } = useParams();

	// Context variables
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const contextUser = useContext(UserContext);

	// useStates
	const [formValues, setFormValues] = useState<IMyProfileInfo>({
		adminId: 0,
		physicianId: 0,
		userName: "",
		password: "",
		status: null,
		role: 0,
		firstName: "",
		lastName: "",
		email: "",
		confirmEmail: "",
		mobileNumber: "",
		regionIds: [],
		districtOfColumbia: false,
		newYork: false,
		virginia: false,
		maryland: false,
		city: "",
		state: null,
		zip: "",
		alternativePhone: "",
		address1: "",
		address2: "",
		medicalLicense: "",
		NPINumber: "",
		businessName: "",
		businessWebsite: "",
		adminNotes: "",
		photoPath: "",
		signaturePath: "",
		isAgreementDoc: false,
		isLicenseDoc: false,
		isNonDisclosureDoc: false,
		isTrainingDoc: false,
		isBackgroundDoc: false,
		syncEmailAddress: null,
		isAnyDocUploaded: false,
		photo: null,
		signature: null,
		physicianGuid: null,
	});

	const [stateList, setStateList] = useState<IDropDownList[]>([]);

	const [roleList, setRoleList] = useState<IDropDownList[]>([]);

	const [sendRequestModalOpen, setSendRequestModalOpen] = useState(false);

	const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);

	const [deleteMessage, setDeleteMessage] = useState("");

	const [isNew, setIsNew] = useState(false);

	const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

	const [isAltPhoneValid, setIsAltPhoneValid] = useState(true);

	const [renderer, setRenderer] = useState(0);

	const [isAdminProfile, setIsAdminProfile] = useState(false);

	const [isMyProfile, setIsMyProfile] = useState(false);

	const [isAutomaticLocationUpdate, setIsAutomaticLocationUpdate] =
		useState(false);

	const [isEditLocationPreference, setIsEditLocationPreference] =
		useState(false);

	//Page level local variable
	const navigate = useNavigate();

	// Handled events and functions
	const handleOnChangeFormValue = (e: any) => {
		if (formValues.physicianId > 0 && e.target.name === "email")
			setFormValues({
				...formValues,
				[e.target.name]: e.target.value,
				confirmEmail: e.target.value,
			});
		else setFormValues({ ...formValues, [e.target.name]: e.target.value });
	};
	const handleOnCheckBoxCLick = (
		name: string,
		value: boolean,
		regionId: number
	) => {
		let regionIds: number[] = [];
		if (formValues.regionIds.includes(regionId))
			regionIds = formValues.regionIds.filter((r) => r !== regionId);
		else {
			regionIds = formValues.regionIds;
			regionIds.push(regionId);
		}
		setFormValues({ ...formValues, [name]: value, regionIds: regionIds });
	};
	const handleOnClickBackCancel = () => navigate(-1);

	const getProfile = () => {
		setRenderer(0);
		if (providerId) {
			console.log(providerId);
			getEditProviderProfile();
		} else if (adminId) {
			console.log("Create/edit admin called", adminId);
			getEditAdminProfile();
		} else {
			getMyProfile();
		}
	};

	const getMyProfile = () => {
		console.log("My profile called.");
		createCommonAPICall({
			apiService: profileService.getMyProfile,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		}).then((data) => {
			console.log(data);
			setFormValues({
				...data.data.myProfile,
				regionIds: data.data.region,
				districtOfColumbia: data.data.region.includes(1),
				maryland: data.data.region.includes(2),
				newYork: data.data.region.includes(3),
				virginia: data.data.region.includes(4),
				password: "",
			});
			setStateList(data.data.state);
			setRoleList(data.data.roles);
			setRenderer(renderer + 1);
			setIsAdminProfile(data.data.myProfile.adminId !== 0);
			setIsMyProfile(true);
			setIsAutomaticLocationUpdate(
				data.data.myProfile.isAutomaticLocationUpdate
			);
		});
	};

	const getEditProviderProfile = () => {
		console.log("Edit profile called.");
		createCommonAPICall({
			requestBody: {
				Id: providerId,
			},
			apiService: roleService.getEditProviderProfile,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		}).then((data) => {
			console.log(data);
			if (!data.data.isNew) {
				setFormValues({
					...data.data.myProfile,
					regionIds: data.data.region,
					districtOfColumbia: data.data.region.includes(1),
					maryland: data.data.region.includes(2),
					newYork: data.data.region.includes(3),
					virginia: data.data.region.includes(4),
					password: "",
				});
			}
			setIsNew(data.data.isNew);
			setIsAdminProfile(data.data.isAdminProfile);
			setStateList(data.data.state);
			setRoleList(data.data.roles);
			setRenderer(renderer + 1);
			setIsMyProfile(false);
		});
	};

	const getEditAdminProfile = async () => {
		const requestBody = {
			id: adminId,
		};

		const data = await createCommonAPICall({
			requestBody,
			apiService: roleService.getEditAdminProfile,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			console.log(data);
			if (!data.data.isNew) {
				setFormValues({
					...data.data.myProfile,
					regionIds: data.data.region,
					districtOfColumbia: data.data.region.includes(1),
					maryland: data.data.region.includes(2),
					newYork: data.data.region.includes(3),
					virginia: data.data.region.includes(4),
					password: "",
				});
			}
			setIsNew(data.data.isNew);
			setStateList(data.data.state);
			setIsAdminProfile(data.data.isAdminProfile);
			setRoleList(data.data.roles);
			setRenderer(renderer + 1);
			setIsMyProfile(data.data.loggedInAdminId === data.data.myProfile.adminId);
		}
	};

	const handleSaveMyProfile = () => {
		const regionIds: number[] = [];

		if (formValues.districtOfColumbia) regionIds.push(1);
		if (formValues.maryland) regionIds.push(2);
		if (formValues.newYork) regionIds.push(3);
		if (formValues.virginia) regionIds.push(4);
		const requestBody = {
			adminId: formValues.adminId,
			username: formValues.userName,
			firstName: formValues.firstName,
			lastName: formValues.lastName,
			email: formValues.email,
			mobile: formValues.mobileNumber,
			address1: formValues.address1,
			address2: formValues.address2,
			city: formValues.city,
			regionId: formValues.state,
			zip: formValues.zip,
			altPhone: formValues.alternativePhone,
			status: formValues.status,
			roleId: formValues.role,
			regionIds: regionIds,
		};
		console.log("Saved my prfile", requestBody);
		createCommonAPICall({
			requestBody,
			apiService: profileService.updateMyProfile,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		}).then((data) => {
			if (data.isSuccessfull) {
				getMyProfile();
			}
		});
	};

	const handleSaveProviderProfile = () => {
		const regionIds: number[] = [];

		if (formValues.districtOfColumbia) regionIds.push(1);
		if (formValues.maryland) regionIds.push(2);
		if (formValues.newYork) regionIds.push(3);
		if (formValues.virginia) regionIds.push(4);
		const requestBody = {
			physicianId: formValues.physicianId,
			username: formValues.userName,
			firstName: formValues.firstName,
			lastName: formValues.lastName,
			email: formValues.email,
			mobile: formValues.mobileNumber,
			medicalLicense: formValues.medicalLicense,
			NPINumber: formValues.NPINumber,
			address1: formValues.address1,
			address2: formValues.address2,
			city: formValues.city,
			regionId: formValues.state,
			zip: formValues.zip,
			altPhone: formValues.alternativePhone,
			status: formValues.status,
			roleId: formValues.role,
			businessName: formValues.businessName,
			businessWebsite: formValues.businessWebsite,
			adminNotes: formValues.adminNotes,
			regionIds: regionIds,
			syncEmailAddress: formValues.syncEmailAddress,
		};
		console.log("Saved provider", requestBody);
		createCommonAPICall({
			requestBody,
			apiService: roleService.updateProviderProfile,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		}).then((data) => {
			if (data.isSuccessfull) {
				getProfile();
			}
		});
	};

	const handleSaveAdminProfile = async () => {
		const regionIds: number[] = [];

		if (formValues.districtOfColumbia) regionIds.push(1);
		if (formValues.maryland) regionIds.push(2);
		if (formValues.newYork) regionIds.push(3);
		if (formValues.virginia) regionIds.push(4);
		const requestBody = {
			adminId: formValues.adminId,
			username: formValues.userName,
			firstName: formValues.firstName,
			lastName: formValues.lastName,
			email: formValues.email,
			mobile: formValues.mobileNumber,
			address1: formValues.address1,
			address2: formValues.address2,
			city: formValues.city,
			regionId: formValues.state,
			zip: formValues.zip,
			altPhone: formValues.alternativePhone,
			status: formValues.status,
			roleId: formValues.role,
			regionIds: regionIds,
		};
		console.log("Admin profile: ", requestBody);
		const data = await createCommonAPICall({
			requestBody,
			apiService: roleService.saveAdminProfile,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			console.log(data);
			getProfile();
		}
	};

	const handleSaveProfile = () => {
		if (providerId) {
			console.log("Saved provider called");
			handleSaveProviderProfile();
		} else if (adminId) {
			console.log("Admin profile save");
			handleSaveAdminProfile();
		} else {
			console.log("Saved my prfile called");
			handleSaveMyProfile();
		}
	};

	const handleSendMessage = (values: IContactProvider) => {
		console.log("Send request to admin:", formValues.physicianId, values);
		const requestBody = {
			providerId: formValues.physicianId,
			message: values.message,
			communicationBy: "",
		};
		createCommonAPICall({
			requestBody,
			apiService: profileService.requestToAdmin,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		}).then((data) => {
			console.log(data);
			setSendRequestModalOpen(false);
		});
	};

	const handleDeleteWarningOpen = () => {
		setIsDeleteWarningOpen(true);
		if (isAdminProfile) {
			setDeleteMessage("Are you sure you want to delete this Admin?");
		} else {
			setDeleteMessage("Are you sure you want to delete this Physician?");
		}
	};
	const handleDeleteWarningClose = () => setIsDeleteWarningOpen(false);
	const handleDeleteConfirm = async () => {
		console.log(isAdminProfile ? adminId : providerId);
		const requestBody = {
			Id: isAdminProfile ? adminId : providerId,
		};

		const data = await createCommonAPICall({
			requestBody,
			apiService: isAdminProfile
				? roleService.deleteAdminAccount
				: roleService.deletePhysician,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});
		handleOnClickBackCancel();
	};

	const handleCreateAccount = async () => {
		const regionIds: number[] = [];

		if (formValues.districtOfColumbia) regionIds.push(1);
		if (formValues.maryland) regionIds.push(2);
		if (formValues.newYork) regionIds.push(3);
		if (formValues.virginia) regionIds.push(4);
		const requestBody = {
			...formValues,
			mobile: formValues.mobileNumber,
			regionId: formValues.state,
			altPhone: formValues.alternativePhone,
			status: 1,
			roleId: formValues.role,
			regionIds: regionIds,
		};

		let data: any = null;

		if (isAdminProfile) {
			data = await handleCreateAdminAccount(requestBody);
		} else {
			data = await handleCreatePhysicianAccount(requestBody);
		}

		if (data && data.isSuccessfull) {
			console.log(data);
			handleOnClickBackCancel();
		}
	};

	const handleCreatePhysicianAccount = async (requestBody: any) => {
		console.log("Created provider", requestBody);

		const data = await createCommonAPICall({
			requestBody,
			apiService: roleService.updateProviderProfile,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});

		return data;
	};

	const handleCreateAdminAccount = async (requestBody: any) => {
		console.log("Created admin", requestBody);

		const data = await createCommonAPICall({
			requestBody,
			apiService: roleService.saveAdminProfile,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});

		return data;
	};

	const handleChangeLocationPreference = async () => {
		const requestBody = {
			isAutomaticLocationUpdate: isAutomaticLocationUpdate,
		};
		const data = await createCommonAPICall({
			requestBody: requestBody,
			apiService: profileService.changeLocationPreference,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			setIsEditLocationPreference(false);
		}
	};

	// Use effects
	useEffect(() => {
		getProfile();
		window.scrollTo(0, 0);
	}, [providerId]);

	// useEffect(() => {
	// 	if (renderer === 1) getProfile();
	// }, [renderer]);

	useEffect(() => {
		getProfile();
		window.scrollTo(0, 0);
	}, [window.location.pathname]);

	const formValidator = useForm<IMyProfileInfo>({
		values: formValues,
		resolver: isNew
			? isAdminProfile
				? yupResolver(createAdminSchema)
				: yupResolver(createProviderSchema)
			: formValues.physicianId > 0
				? yupResolver(providerMyProfile)
				: yupResolver(myProfileSchema),
	});

	return (
		<Box>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="request-container-box">
					<div className="request-header">
						<Typography variant="h2">
							{isMyProfile
								? "My Profile"
								: providerId
									? isNew
										? "Create Provider Account"
										: "Edit Physician Account"
									: adminId
										? isNew
											? "Create Admin account"
											: "Edit Admin Account"
										: "My Profile"}
						</Typography>
						<Button variant="outlined" onClick={handleOnClickBackCancel}>
							<img src={ArrowBack} alt="arrow" />
							Back
						</Button>
					</div>
					<Box className="request-box">
						{contextUser.userRoleId === AccountType.Physician && (
							<div>
								<div className="modal-footer">
									{/* TODO: Add onclick modal open logic */}
									<Tooltip title="Request Profile Edit">
										<Button
											variant="outlined"
											onClick={() => setSendRequestModalOpen(true)}
										>
											Request to Admin
										</Button>
									</Tooltip>
								</div>
								<div>
									<Typography variant="h4">
										Location Update Preference
									</Typography>
									<div className="location-preference-wrapper">
										<div className="location-preference-field">
											<CheckBoxComponent
												disabled={!isEditLocationPreference}
												label={"Update my location automatically"}
												isChecked={isAutomaticLocationUpdate}
												handleOnChangeCheckbox={() =>
													setIsAutomaticLocationUpdate(
														!isAutomaticLocationUpdate
													)
												}
											/>
										</div>
										<div className="location-preference-btn">
											{isEditLocationPreference ? (
												<>
													<Tooltip title="Save">
														<Button
															variant="contained"
															onClick={handleChangeLocationPreference}
														>
															Save
														</Button>
													</Tooltip>
													<Tooltip title="Cancel">
														<Button
															variant="outlined"
															onClick={() => {
																getMyProfile();
																setIsEditLocationPreference(false);
															}}
														>
															Cancel
														</Button>
													</Tooltip>
												</>
											) : (
												<>
													<Tooltip title="Edit">
														<Button
															variant="contained"
															onClick={() => {
																setIsEditLocationPreference(true);
															}}
														>
															Edit
														</Button>
													</Tooltip>
												</>
											)}
										</div>
									</div>
								</div>
							</div>
						)}
						<AccountInformation
							formValues={formValues}
							handleOnChangeFormValue={handleOnChangeFormValue}
							roleList={roleList}
							formValidator={formValidator}
							getMyProfile={getProfile}
							handleSaveProfile={handleSaveProfile}
							isNew={isNew}
							isAdminProfile={isAdminProfile}
							isMyProfile={isMyProfile}
						/>
						<AdministratorInformation
							formValues={formValues}
							handleOnChangeFormValue={handleOnChangeFormValue}
							handleOnCheckBoxCLick={handleOnCheckBoxCLick}
							formValidator={formValidator}
							getMyProfile={getProfile}
							handleSaveProfile={handleSaveProfile}
							setFormValues={setFormValues}
							providerId={providerId}
							isNew={isNew}
							renderer={renderer}
							isPhoneNumberValid={isPhoneNumberValid}
							setIsPhoneNumberValid={setIsPhoneNumberValid}
							isAdminProfile={isAdminProfile}
							isMyProfile={isMyProfile}
						/>
						<MailingAndBillingInformation
							formValues={formValues}
							handleOnChangeFormValue={handleOnChangeFormValue}
							stateList={stateList}
							formValidator={formValidator}
							getMyProfile={getProfile}
							handleSaveProfile={handleSaveProfile}
							isNew={isNew}
							renderer={renderer}
							isPhoneNumberValid={isAltPhoneValid}
							setIsPhoneNumberValid={setIsAltPhoneValid}
							isAdminProfile={isAdminProfile}
							isMyProfile={isMyProfile}
						/>
						{/* Provider profile part for physician */}
						{(contextUser.userRoleId === AccountType.Physician ||
							providerId) && (
							<ProviderProfile
								formValues={formValues}
								getMyProfile={getProfile}
								handleFormValuesChange={handleOnChangeFormValue}
								formValidator={formValidator}
								handleSaveProfile={handleSaveProfile}
								isNew={isNew}
								setFormValues={setFormValues}
							/>
						)}
						<div className="request-btn-group">
							{contextUser.userRoleId === AccountType.Admin &&
								formValues.physicianId > 0 && (
									<Tooltip title="Save">
										<Button
											variant="contained"
											onClick={() => {
												if (
													formValues.mobileNumber === "" ||
													!isPhoneNumberValid
												) {
													setIsPhoneNumberValid(false);
													formValidator.handleSubmit(() => {})();
												} else if (!isAltPhoneValid) {
													formValidator.handleSubmit(() => {})();
												} else {
													formValidator.handleSubmit(handleSaveProfile)();
												}
											}}
										>
											Save
										</Button>
									</Tooltip>
								)}

							{(contextUser.isSuperAdmin || !isAdminProfile) &&
								formValues.userName.toLowerCase() !== "admin" &&
								!isMyProfile &&
								!isNew && (
									<Tooltip title="Delete account">
										<Button
											variant="contained"
											color="error"
											onClick={handleDeleteWarningOpen}
										>
											Delete Account
										</Button>
									</Tooltip>
								)}
							{isNew && (
								<Tooltip title="Save">
									<Button
										variant="contained"
										color="success"
										size="large"
										onClick={() => {
											if (
												formValues.mobileNumber === "" ||
												!isPhoneNumberValid
											) {
												setIsPhoneNumberValid(false);
												formValidator.handleSubmit(() => {})();
											} else if (!isAltPhoneValid) {
												formValidator.handleSubmit(() => {})();
											} else {
												formValidator.handleSubmit(handleCreateAccount)();
											}
										}}
									>
										Create Account
									</Button>
								</Tooltip>
							)}
						</div>
					</Box>
				</Box>
			</main>
			{formValues.physicianId && formValues.physicianId !== 0 && (
				<ContactProviderModal
					modalOpen={sendRequestModalOpen}
					onModalClose={() => setSendRequestModalOpen(false)}
					providerId={formValues.physicianId.toString()}
					handleSendMessage={handleSendMessage}
				/>
			)}
			<WarningModal
				isModalOpen={isDeleteWarningOpen}
				handleOnClickCloseModal={handleDeleteWarningClose}
				okButtonText="Confirm"
				title="Confirmation"
				warningMessage={deleteMessage}
				closeButtonText="Cancel"
				handleOnClickOk={handleDeleteConfirm}
			/>
		</Box>
	);
};

export default MyProfile;
