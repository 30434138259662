import { Grid } from "@mui/material";
import { User, UserImage } from "assests/images";
import React from "react";
import { IProviderGridInfo } from "utility/interfaces";

type ComponentProps = {
	physicianList: IProviderGridInfo[];
};

export const ProviderGrid = (props: ComponentProps) => {
	// Extract Props
	const { physicianList } = props;

	return (
		<>
			<Grid container>
				{physicianList.map((physician) => (
					<Grid
						item
						xs={12}
						sm={6}
						md={4}
						key={physician.physicianId}
						display={"flex"}
						marginBottom={"20px"}
					>
						<div className="doc-photo">
							<img
								src={physician.photo != "" ? physician.photo : UserImage}
								height={70}
								width={60}
							/>
						</div>
						<p className="doc-name">{physician.name}</p>
					</Grid>
				))}
			</Grid>
		</>
	);
};
