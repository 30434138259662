import * as yup from "yup";
import { phoneNumberRegExp } from "utility/helpers";

export const createRequestSchema = yup.object().shape({
	firstName: yup.string().required("First name is required."),
	lastName: yup.string().required("Last name is required."),
	street: yup.string().required("Street is required."),
	city: yup.string().required("City is required."),
	state: yup.string().required("State is required."),
	// phoneNumber: yup.string().required("Phone number is required."),
	email: yup
		.string()
		.email("Please provide a valid email address")
		.required("Email address is required"),
	dateOfBirth: yup.date().typeError("Enter valid date (MM/DD/YYYY)."),
});

export const editRequestSchema = yup.object().shape({
	firstName: yup.string().required("First name is required."),
	lastName: yup.string().required("Last name is required."),
	// phoneNumber: yup.string().required("Phone number is required."),
	email: yup
		.string()
		.email("Please provide a valid email address")
		.required("Email address is required"),
	dateOfBirth: yup.date().typeError("Enter valid date (MM/DD/YYYY)."),
});

export const createRequestPhoneNumberSchema = yup.object().shape({
	phoneNumber: yup
		.string()
		.matches(phoneNumberRegExp, "Phone number is not valid")
		.required("Phone number is required."),
});

export const sendLinkSchema = yup.object().shape({
	firstName: yup.string().required("First name is required."),
	lastName: yup.string().required("Last name is required."),
	// phoneNumber: yup
	// 	.string()
	// 	.required("Phone number is required.")
	// 	.matches(phoneNumberRegExp, "Phone number is not valid"),
	email: yup
		.string()
		.email("Email is not valid.")
		.required("Email is required."),
});

export const sendEmailSchema = yup.object().shape({
	message: yup.string().required("Message is required."),
});

export const blockRequestSchema = yup.object().shape({
	reason: yup.string().required("Reason is required."),
});

export const assignCaseSchema = yup.object().shape({
	physician: yup.string().required("Please select physician"),
	description: yup.string().required("Description is required."),
});

export const transferCaseSchema = yup.object().shape({
	description: yup.string().required("Description is required."),
});

export const cancelCaseSchema = yup.object().shape({
	reasons: yup
		.array()
		.required("Please select at least one reason.")
		.nullable(),
	note: yup.string().required("Note is required."),
});

export const sendOrderSchema = yup.object().shape({
	business: yup.string().required("Please select business type."),
	prescription: yup.string().required("Prescription is required."),
	email: yup.string().email("Email is not valid."),
});

export const closeCaseSchema = yup.object().shape({
	// updatedPhoneNumber: yup
	// 	.string()
	// 	.required("Phone number is required.")
	// 	.matches(phoneNumberRegExp, "Phone number is not valid"),
	updatedEmail: yup
		.string()
		.email("Email is not valid.")
		.required("Email is required."),
});
export const sendAgreementSchema = yup.object().shape({
	// phoneNumber: yup
	// 	.string()
	// 	.required("Phone number is required.")
	// 	.matches(phoneNumberRegExp, "Phone number is not valid"),
	email: yup
		.string()
		.email("Email is not valid.")
		.required("Email is required."),
});
export const saveViewNoteSchema = yup.object().shape({
	note: yup.string().required("Note is required."),
});

export const sendDocumentSchema = yup.object().shape({
	email: yup.string().required("Email is required."),
});

export const encounterFormSchema = yup.object().shape({
	firstName: yup.string().required("Patient first name is required."),
	lastName: yup.string().required("Patient last name is required."),
	dateOfBirth: yup
		.date()
		.typeError("Enter valid date (MM/DD/YYYY).")
		.required("Patient date of birth is required."),
	location: yup.string().required("Patient address is required."),
	date: yup
		.date()
		.typeError("Enter valid date (DD/MM/YYYY).")
		.required("Date of examination is required."),
	// phoneNumber: yup
	// 	.string()
	// 	.matches(phoneNumberRegExp, "Phone number is not valid")
	// 	.required("Patient phone number is required."),
	email: yup
		.string()
		.email("Please provide a valid email address")
		.required("Patient email is required."),
	allergies: yup.string().required(),
	treatmentPlan: yup.string().required(),
	medicationsDispensed: yup.string().required(),
	procedures: yup.string().required(),
	followup: yup.string().required(),
	bp1: yup
		.string()
		.test("BP validation", "Value must be greater than 0.", (value) => {
			if (value) {
				let bp1 = Number.parseInt(value);
				return bp1 > 0;
			}
			return true;
		})
		.test(
			"BP validation",
			"Systolic must be greater than diastolic.",
			(value, context) => {
				if (value) {
					let bp1 = Number.parseInt(value);
					let bp2 = Number.parseInt(context.parent.bp2);
					return bp1 > bp2;
				}
				return true;
			}
		),
	bp2: yup
		.string()
		.test("BP validation", "Value must be greater than 0.", (value) => {
			if (value) {
				let bp2 = Number.parseInt(value);
				return bp2 > 0;
			}
			return true;
		})
		.test(
			"BP validation",
			"Systolic must be greater than diastolic.",
			(value, context) => {
				if (value) {
					let bp2 = Number.parseInt(value);
					let bp1 = Number.parseInt(context.parent.bp1);
					return bp1 > bp2 && bp1 > 0 && bp2 > 0;
				}
				return true;
			}
		),
});

export const saveCallTypeSchema = yup.object().shape({
	isHouseCallOrConsult: yup.number().moreThan(0, "Please select care type."),
});

export const sendDefaultMessageSchema = yup.object().shape({
	email: yup.string().required("Message is required."),
});
