import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from "@mui/material";
import React from "react";
import { SortOrder } from "utility/enums/sort-order";
import { IHaloHRInfo, ISearchCriteria } from "utility/interfaces";
import { visuallyHidden } from "@mui/utils";
import { HaloWorkPlaceTableRow, TablePagination } from "components";

type ComponentProps = {
	haloWorkPlacePageInfo: IHaloHRInfo;
	searchCriteria: ISearchCriteria;
	handleOnSearchCriteriaChange: any;
	getHaloWorkplaceInfo: any;
};

export const HaloWorkPlaceTable = (props: ComponentProps) => {
	// Extract props
	const {
		haloWorkPlacePageInfo,
		searchCriteria,
		handleOnSearchCriteriaChange,
		getHaloWorkplaceInfo,
	} = props;

	// Handle events
	const handleOnPageNumberChange = (value: number) => {
		handleOnSearchCriteriaChange({ ...searchCriteria, PageIndexId: value });
	};
	const handleOnChangeSortDirection = (sortBy: string) => {
		handleOnSearchCriteriaChange({
			...searchCriteria,
			SortBy: sortBy,
			SortOrder:
				searchCriteria.SortOrder === SortOrder.ascending
					? SortOrder.descending
					: SortOrder.ascending,
		});
	};

	return (
		<>
			{haloWorkPlacePageInfo.totalRecords > 0 ? (
				<>
					<TableContainer
						className="upload-table-container"
						sx={{ display: { xs: "none", md: "block" } }}
					>
						<Table className="upload-table conclude-table">
							<TableHead>
								<TableRow>
									<TableCell className="min-w-160px">
										<TableSortLabel
											active={searchCriteria.SortBy === "WorkPlaceName"}
											direction={searchCriteria.SortOrder}
											onClick={() =>
												handleOnChangeSortDirection("WorkPlaceName")
											}
										>
											Work Place Name
											<Box component="span" sx={visuallyHidden}>
												{searchCriteria.SortOrder === SortOrder.descending
													? "sorted descending"
													: "sorted ascending"}
											</Box>
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
										Action
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{haloWorkPlacePageInfo.halos.map((row, index) => (
									<HaloWorkPlaceTableRow
										row={row}
										key={index}
										isSmallDevice={false}
										getHaloWorkplaceInfo={getHaloWorkplaceInfo}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Box
						sx={{ display: { xs: "block", md: "none" } }}
						className="tabledata-cards-group"
					>
						{haloWorkPlacePageInfo.halos.map((row, index) => (
							<HaloWorkPlaceTableRow
								row={row}
								key={index}
								isSmallDevice={true}
								getHaloWorkplaceInfo={getHaloWorkplaceInfo}
							/>
						))}
					</Box>
					<div className="small-table-pagination">
						<TablePagination
							currentPageNumber={searchCriteria.PageIndexId}
							handleOnPageNumberChange={handleOnPageNumberChange}
							totalRecords={haloWorkPlacePageInfo.totalRecords || 0}
							rowCount={haloWorkPlacePageInfo.halos.length || 0}
						/>
					</div>
				</>
			) : (
				<Typography variant="h5" sx={{ padding: 3 }}>
					No Record(s) Found{" "}
				</Typography>
			)}
		</>
	);
};
