import { AxiosResponse } from "axios";
import { ProtectedEndPoints } from "./api-end-points";
import httpClient from "./base-service";
import {
	IApproveTimesheetRequest,
	IBiWeeklyTimesheetDetails,
	IDropDownList,
	IEditTimesheetReimbursement,
	IFillTimesheetDetailsInfo,
	IHttpsResponse,
	IQueryStringRequest,
	ISubmitTimsheetRequest,
	ITimeSheetDetailsSearchCriteria,
} from "utility/interfaces";

const getTimesheetPeriodDropDownList = (): Promise<
	AxiosResponse<IHttpsResponse<IDropDownList[]>>
> => httpClient.get(ProtectedEndPoints.GetTimesheetPeriodList);

const getTimeSheetGrid = (
	request: ITimeSheetDetailsSearchCriteria
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.GetTimeSheetGrid, request);

const getDailyTimeSheetDetails = async (
	request: IQueryStringRequest
): Promise<AxiosResponse<IHttpsResponse<IFillTimesheetDetailsInfo>>> =>
	httpClient.post(ProtectedEndPoints.GetDailyTimeSheetDetails, request);

const uploadFileForOtherReimbursement = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.UploadFileForOtherReimbursement, request);

const editTimesheetReimbursement = (
	request: IEditTimesheetReimbursement
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.EditTimesheetReimbursement, request);

const deleteReimbursement = (
	request: IEditTimesheetReimbursement
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.DeleteReimbursement, request);

const submitTimesheetData = (
	request: ISubmitTimsheetRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.SubmitTimesheetData, request);

const getBiWeeklyTimeSheetDetail = (
	request: IQueryStringRequest
): Promise<AxiosResponse<IHttpsResponse<IBiWeeklyTimesheetDetails[]>>> =>
	httpClient.post(ProtectedEndPoints.GetBiWeeklyTimeSheetDetail, request);

const finalizeBiWeeklyTimesheet = (
	request: ISubmitTimsheetRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.FinalizeBiWeeklyTimesheet, request);

const approveTimesheet = (
	request: IApproveTimesheetRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post(ProtectedEndPoints.ApproveTimesheet, request);

export default {
	getTimesheetPeriodDropDownList,
	getTimeSheetGrid,
	getDailyTimeSheetDetails,
	uploadFileForOtherReimbursement,
	editTimesheetReimbursement,
	deleteReimbursement,
	submitTimesheetData,
	getBiWeeklyTimeSheetDetail,
	finalizeBiWeeklyTimesheet,
	approveTimesheet,
};
