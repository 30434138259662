import { Box, Button, Typography } from "@mui/material";
import { PromptEventContext } from "contexts/prompt-event-context";
import React, { useContext, useEffect, useState } from "react";

export const InstallOption = () => {
	// Use context
	const promptEventContext = useContext(PromptEventContext);

	// State variables
	const [promptEvent, setPromptEvent] = useState<Event | null>(null);

	// Handle events
	const handleBeforeInstallEvent = (event: Event) => {
		setPromptEvent(event);
	};

	const handleOnClickInstall = async () => {
		if (promptEvent) {
			let deferredPrompt = promptEvent as any;
			deferredPrompt.prompt();

			// Find out whether the user confirmed the installation or not
			const { outcome } = await deferredPrompt.userChoice;
			// The deferredPrompt can only be used once.
			deferredPrompt = null;
			// Act on the user's choice
			if (outcome === "accepted") {
				setPromptEvent(null);
				console.log("User accepted the install prompt.");
			} else if (outcome === "dismissed") {
				console.log("User dismissed the install prompt");
			}
		}
	};

	// Use effects
	useEffect(() => {
		window.addEventListener("beforeinstallprompt", handleBeforeInstallEvent);

		if (promptEventContext) {
			setPromptEvent(promptEventContext);
		}

		return () => {
			window.removeEventListener(
				"beforeinstallprompt",
				handleBeforeInstallEvent
			);
		};
	}, []);

	return promptEvent ? (
		<Box className="install-option">
			<Typography>
				To have secure access to our provider portal, click here to install.
			</Typography>
			<div className="modal-footer">
				<Button
					variant="contained"
					color="secondary"
					onClick={handleOnClickInstall}
				>
					Install
				</Button>
			</div>
		</Box>
	) : (
		<></>
	);
};
