import { yupResolver } from "@hookform/resolvers/yup";
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	IconButton,
	Modal,
	Radio,
	RadioGroup,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import { UserContext } from "contexts/user-context";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AccountType } from "utility/enums/request-status";
import { commonModalStyle } from "utility/helpers";
import { IContactProvider } from "utility/interfaces";
import { contactProviderSchema } from "utility/validations";

type ComponentProps = {
	modalOpen: boolean;
	onModalClose: any;
	providerId: string;
	handleSendMessage: any;
};

export function ContactProviderModal(props: ComponentProps) {
	//Use Context
	const contextUser = useContext(UserContext);

	// Extract Props
	const { modalOpen, onModalClose, providerId, handleSendMessage } = props;

	// useStates
	const [formValues, setFormValues] = useState<IContactProvider>({
		communicationBy: "sms",
		message: "",
	});

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		reset,
	} = useForm({
		resolver: yupResolver(contactProviderSchema),
	});

	// Events and functions
	const handleOnChangeMessage = (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setFormValues({ ...formValues, message: e.target.value });
	};
	const handleOnClickConfirm = () => {
		console.log(formValues);
		handleSendMessage(formValues);
	};

	// useEffects
	useEffect(() => {
		reset();
		clearErrors();
		setFormValues({
			communicationBy: "sms",
			message: "",
		});
	}, [modalOpen]);

	return (
		<>
			<Modal
				open={modalOpen}
				onClose={onModalClose}
				className="send-modal select-input-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">
							{contextUser.userRoleId === AccountType.Admin
								? "Contact Your Provider"
								: "Request To Administrator"}
						</Typography>
						<IconButton onClick={onModalClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<div>
							<Typography variant="h6" className="patient-name">
								{contextUser.userRoleId === AccountType.Admin
									? "Choose communication to send message"
									: "Need to send message to edit"}
							</Typography>
							{contextUser.userRoleId === AccountType.Admin && (
								<FormControl>
									<RadioGroup
										defaultValue={formValues.communicationBy}
										name="communicationBy"
										onChange={(e) => {
											setFormValues({
												...formValues,
												communicationBy: e.target.value,
											});
										}}
									>
										<FormControlLabel
											value="sms"
											control={<Radio />}
											label="SMS"
										/>
										<FormControlLabel
											value="email"
											control={<Radio />}
											label="Email"
										/>
										<FormControlLabel
											value="both"
											control={<Radio />}
											label="Both"
										/>
									</RadioGroup>
								</FormControl>
							)}

							<TextField
								{...register("message")}
								id="block"
								label="Message"
								value={formValues.message}
								name="message"
								onChange={handleOnChangeMessage}
								multiline
								maxRows={1}
								inputProps={{
									style: { minHeight: "88px" },
								}}
								fullWidth
								error={errors?.message?.message ? true : false}
								helperText={errors?.message?.message?.toString()}
							/>
						</div>
						<div className="modal-footer">
							<Tooltip title="Send">
								<Button
									variant="contained"
									onClick={handleSubmit(handleOnClickConfirm)}
								>
									Send
								</Button>
							</Tooltip>
							<Tooltip title="Cancel">
								<Button variant="outlined" onClick={onModalClose}>
									Cancel
								</Button>
							</Tooltip>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
