import { ArrowBack } from "assests/images";
import {
	Box,
	Button,
	FormControl,
	Select,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "contexts/user-context";
import { AccountType } from "utility/enums/request-status";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { Constants } from "utility/enums/constants";
import { createCommonAPICall, hideLoader, showLoader } from "utility/helpers";
import profileService from "services/profile-service";
import { AddChatModal, ChatBox, ChatList, GroupChatBox } from "components";
import { toast } from "react-toastify";
import dashboardService from "services/dashboard-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import {
	IChatListView,
	IChatBoxInfo,
	IChatUploadRequest,
	IGroupChatBoxInfo,
} from "utility/interfaces";
import {
	ChatCountContext,
	ChatCountDispatchContext,
} from "contexts/chat-count-context";

const URL = Constants.HubUrl;

const Chats: React.FC = () => {
	// Use context
	const contextUser = useContext(UserContext);
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const chatCount = useContext(ChatCountContext);
	const setChatCount = useContext(ChatCountDispatchContext);

	//Page level local variable
	const navigate = useNavigate();

	// Use state
	const [chatList, setChatList] = useState<IChatListView[]>([]);
	const [recieverType, setRecieverType] = useState(AccountType.Patient);
	const [connection, setConnection] = useState<null | signalR.HubConnection>(
		null
	);
	const [senderId, setSenderId] = useState(0);
	const [openChatBoxList, setOpenChatBoxList] = useState<IChatBoxInfo[]>([]);
	const [isChatOpend, setIsChatOpend] = useState(false);
	const [addChatMOdalOpen, setAddChatModalOpen] = useState(false);
	const [searchPatientName, setSearchPatientName] = useState("");

	const [searchPhysicianName, setSearchPhysicianName] = useState("");

	const [openGroupChatBoxList, setOpenGroupChatBoxList] = useState<
		IGroupChatBoxInfo[]
	>([]);

	// Handle Event and Functions
	const handleOnClickBackCancel = () => navigate(-1);

	const handleClickAddChatModalOpen = () => {
		setAddChatModalOpen(true);
	};

	const handleClickAddChatModalClose = () => {
		setAddChatModalOpen(false);
	};

	const handleOnPatientSearchChange = (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setSearchPatientName(e.target.value);
	};

	const handleSearchKeyUp = (event: any) => {
		// Enter keycode = 13
		if (event.keyCode === 13 || event.keyCode === 9) {
			console.log("search: ", searchPatientName, searchPhysicianName);
			getChatList();
		}
	};

	const handleOnPhysicianSearchChange = (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setSearchPhysicianName(e.target.value);
	};

	const handleNewChat = async (recieverId: number) => {
		try {
			console.log(recieverId);
			handleClickAddChatModalClose();
			let recieverType =
				recieverId > 0 ? AccountType.Physician : AccountType.Admin;
			let senderName = "Admin";
			if (contextUser.userRoleId === AccountType.Physician) {
				senderName = `Dr. ${contextUser.lastName}`;
			}
			if (connection) {
				showLoader();
				await connection.send(
					"hubconnect",
					senderId,
					contextUser.userRoleId,
					senderName,
					recieverId > 0 ? recieverId : Constants.AdminId,
					recieverType,
					0,
					"",
					0
				);
			}
		} catch (error) {
			console.log(error);
		} finally {
			hideLoader();
		}
	};

	const getChatList = () => {
		showLoader();
		if (connection) {
			connection!.send(
				"GetChatList",
				senderId,
				contextUser.userRoleId,
				recieverType,
				0,
				searchPatientName,
				searchPhysicianName
			);
		}
	};

	const handleOpenChatClick = async (index: number) => {
		let chatView = chatList.find((v, i) => i === index);
		// let chatView = chatList.at(index);
		if (!chatView) return;
		setChatCount(chatCount - chatView.readCount);
		if (recieverType !== AccountType.Groups) {
			let recieverId = 0;
			if (chatView.requestType == AccountType.Admin) {
				recieverId = chatView.adminId;
			} else if (chatView.requestType == AccountType.Patient) {
				recieverId = chatView.requestId;
			} else {
				recieverId = chatView.physicianId;
			}

			let senderName = "Admin";
			if (contextUser.userRoleId === AccountType.Physician) {
				senderName = `Dr. ${contextUser.lastName}`;
			}
			const request = {
				senderId,
				userRoleId: contextUser.userRoleId,
				senderName,
				recieverId,
				recieverType,
				requestId: chatView.requestId || 0,
				confirmationNumber: chatView.confirmationNumber || "",
				userId: chatView.userId,
			};
			if (connection) {
				showLoader();
				await connection.send(
					"hubconnect",
					senderId,
					contextUser.userRoleId,
					senderName,
					recieverId,
					recieverType,
					chatView.requestId || 0,
					chatView.confirmationNumber || "",
					chatView.userId || 0
				);
			}
		} else {
			//console.log("Group Chat open not implemented");
			console.log(chatView);
			const request = {
				adminId: chatView.adminId,
				physicianId: chatView.physicianId,
				requestId: chatView.requestId,
				senderTypeId: contextUser.userRoleId,
			};
			console.log("OpenGroupChat: ", request);
			if (connection) {
				showLoader();
				await connection.send(
					"OpenGroupChat",
					chatView.adminId,
					chatView.physicianId,
					chatView.requestId,
					contextUser.userRoleId
				);
			}

			// setIsChatOpend(true);
			// setOpenGroupChatBoxList([
			// 	{
			// 		adminId: chatView.adminId,
			// 		chatGroupId: 1,
			// 		chatGroupName: chatView.groupName,
			// 		isMinimized: false,
			// 		physicianId: chatView.physicianId,
			// 		requestId: chatView.requestId,
			// 		chatView: [],
			// 	},
			// ]);
		}
	};

	const handleOnCloseChat = async (
		receiverId: number,
		senderId: number,
		senderTypeId: number,
		receiverTypeId: number
	) => {
		const openChatBoxes = openChatBoxList.filter(
			(box) => box.receiverId !== receiverId || box.senderId !== senderId
		);
		setOpenChatBoxList([...openChatBoxes]);

		await connection!.send(
			"GetDisconnect",
			senderId,
			senderTypeId,
			receiverId,
			receiverTypeId
		);
	};

	const handleOnCloseGroupChat = async (
		chatBoxIndex: number,
		senderId: number
	) => {
		const openChatBoxes = openGroupChatBoxList.filter(
			(box, index) => index !== chatBoxIndex
		);
		setOpenGroupChatBoxList([...openChatBoxes]);

		await connection!.send(
			"GetDisconnect",
			senderId,
			contextUser.userRoleId,
			0,
			0
		);
	};

	const handleOnMinimized = (receiverId: number, senderId: number) => {
		const chatBoxes = openChatBoxList.map((box) => {
			if (box.receiverId === receiverId && box.senderId === senderId) {
				box.isMinimized = !box.isMinimized;
			}
			return box;
		});
		setOpenChatBoxList(chatBoxes);
	};

	const handleOnGroupChatMinimized = (chatBoxIndex: number) => {
		const chatBoxes = openGroupChatBoxList.map((box, index) => {
			if (index === chatBoxIndex) {
				box.isMinimized = !box.isMinimized;
			}
			return box;
		});
		setOpenGroupChatBoxList(chatBoxes);
	};

	const sendMessage = async (
		message: string,
		senderId: number,
		receiverId: number,
		receiverAccountType: AccountType,
		confirmationNumber: string,
		senderAccountType: AccountType
	) => {
		console.log("sendMessage", receiverAccountType);
		await connection!.send(
			"SendPrivateMessage",
			message,
			senderId,
			receiverId,
			receiverAccountType,
			confirmationNumber,
			senderAccountType,
			"",
			0
		);
	};

	const handleSendGroupMessage = async (
		chatGroupId: number,
		message: string,
		senderId: number,
		senderTypeId: number
	) => {
		if (connection) {
			console.log(
				"Send group message: ",
				chatGroupId,
				message,
				senderId,
				senderTypeId
			);
			connection.send(
				"SendGroupMessage",
				chatGroupId,
				message,
				senderId,
				senderTypeId,
				"",
				0
			);
		}
	};

	const sendAudioVideo = (
		file: Blob,
		senderId: number,
		receiverId: number,
		receiverAccountType: AccountType,
		confirmationNumber: string,
		senderAccountType: AccountType
	) => {
		console.log("File Sent");
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			if (reader.result && typeof reader.result == "string") {
				const chatUploadMedia: IChatUploadRequest = {
					File: reader.result,
					SenderId: senderId,
					ReceiverId: receiverId,
					ReceiverAccountTypeId: receiverAccountType,
					ConfirmationNumber: confirmationNumber,
					SenderAccountTypeId: senderAccountType,
				};
				console.log(chatUploadMedia);
				createCommonAPICall({
					requestBody: chatUploadMedia,
					apiService: dashboardService.uploadAudioVideoForChat,
					showSuccessMessage: false,
					showErrorMessage: false,
					setSuccessErrorContext,
				}).then(async (data) => {
					console.log(data.data);
					let msg = "";
					let path = data.data[0].path;
					// let path = CLOUD_URL + data.data[0].path;
					// if (file.type.includes("audio")) {
					// 	msg = `<audio controls src="${path}"></audio>`;
					// } else if (file.type.includes("video")) {
					// 	msg = `<video controls src="${path}"></video>`;
					// }
					if (data.data[0].status) {
						await connection!.send(
							"SendPrivateMessage",
							path,
							senderId,
							receiverId,
							receiverAccountType,
							confirmationNumber,
							senderAccountType,
							file.type,
							0
						);
					} else {
						toast.error(data.data[0].message);
					}
				});
			}
		};
	};

	const sendAudioVideoGroup = (
		file: Blob,
		chatGroupId: number,
		senderId: number,
		senderAccountTypeId: number
	) => {
		console.log(file, chatGroupId, senderId, senderAccountTypeId);
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			if (reader.result && typeof reader.result == "string") {
				const chatUploadMedia = {
					File: reader.result,
					SenderId: senderId,
					SenderAccountTypeId: senderAccountTypeId,
					chatGroupId: chatGroupId,
					IsGroup: true,
				};
				console.log(chatUploadMedia);
				createCommonAPICall({
					requestBody: chatUploadMedia,
					apiService: dashboardService.uploadAudioVideoForChat,
					showSuccessMessage: false,
					showErrorMessage: false,
					setSuccessErrorContext,
				}).then(async (data) => {
					console.log(data.data);
					let message = data.data[0].path;
					if (data.data[0].status) {
						await connection!.send(
							"SendGroupMessage",
							chatGroupId,
							message,
							senderId,
							senderAccountTypeId,
							file.type,
							0
						);
					} else {
						toast.error(data.data[0].message);
					}
				});
			}
		};
	};

	const handleStartGroupChat = async (senderId: number, recieverId: number) => {
		console.log("Start group chat");
		await connection!.send("StartGroupChat", senderId, recieverId);
		setRecieverType(AccountType.Groups);
	};

	const keyDownHandler = async (
		event: React.KeyboardEvent<HTMLInputElement>,
		message: string,
		senderId: number,
		receiverId: number,
		receiverAccountType: AccountType,
		confirmationNumber: string,
		senderAccountType: AccountType
	) => {
		console.log("keyDownHandler");
		if (event.code === "Enter" && message !== "") {
			await connection!.send(
				"SendPrivateMessage",
				message,
				senderId,
				receiverId,
				receiverAccountType,
				confirmationNumber,
				senderAccountType,
				"",
				0
			);
		}
	};

	const updateChatBoxClasses = (openChatBoxList: IChatBoxInfo[]) => {
		const openChatBoxes = openChatBoxList.filter((box) => !box.isMinimized);
		openChatBoxes.length > 0
			? isChatOpend
				? document.body.classList.toggle("chatbox-open", true)
				: document.body.classList.toggle("chatbox-open", false)
			: document.body.classList.toggle("chatbox-open", false);
	};

	const updateGroupChatBoxClasses = (
		openGroupChatBoxList: IGroupChatBoxInfo[]
	) => {
		const openGroupChatBoxes = openGroupChatBoxList.filter(
			(box) => !box.isMinimized
		);
		openGroupChatBoxes.length > 0
			? isChatOpend
				? document.body.classList.toggle("chatbox-open", true)
				: document.body.classList.toggle("chatbox-open", false)
			: document.body.classList.toggle("chatbox-open", false);
	};

	const handleConnection = () => {
		const newConnection = new HubConnectionBuilder()
			.withUrl(URL)
			.withAutomaticReconnect()
			.build();

		setConnection(newConnection);
	};

	// Use effects
	useEffect(() => {
		showLoader();
		handleConnection();
	}, []);

	useEffect(() => {
		if (connection) {
			connection
				.start()
				.then(() => {
					console.log("Connected.");
					connection.on("setChatList", (data) => {
						console.log(data);
						setChatList(data.chatHistory);
						hideLoader();
					});

					connection.on("SetChatHistoryList", (data) => {
						console.log(data);
						setIsChatOpend(true);
						if (data) setOpenChatBoxList(data.chatresponse);
						const request = data.chatresponse;
						const openChatBoxes = openChatBoxList.filter(
							(box) =>
								box.receiverId === request.receiverId &&
								box.senderId === request.senderId
						);
						if (!openChatBoxes || openChatBoxes?.length === 0) {
							setOpenChatBoxList([
								...openChatBoxList,
								{
									receiverId: data.chatresponse.receiverId,
									receiverName: data.chatresponse.receiverName,
									// request.requestId == request.receiverId
									// 	? data.chatresponse.patientName
									// 	: data.chatresponse.physicianName,
									senderId: data.chatresponse.senderId,
									senderName: data.chatresponse.senderName,
									isMinimized: false,
									confirmationNumber: data.chatresponse.confirmationNumber,
									isChatWithPatient: request.requestId == request.receiverId,
									isChatWithAdmin:
										data.chatresponse.receiverId == Constants.AdminId
											? true
											: false,
									chatView: data.chatView,
									physicianName: data.chatresponse.physicianName,
								},
							]);
							hideLoader();
							return;
						}
						const chatBoxes = openChatBoxList.filter(
							(box) =>
								box.receiverId !== request.receiverId ||
								box.senderId !== request.senderId
						);
						setOpenChatBoxList([
							...chatBoxes,
							{
								receiverId: data.chatresponse.receiverId,
								receiverName:
									request.requestId == request.receiverId
										? data.chatresponse.patientName
										: data.chatresponse.physicianName,
								senderId: data.chatresponse.senderId,
								senderName: data.chatresponse.senderName,
								isMinimized: false,
								confirmationNumber: data.chatresponse.confirmationNumber,
								isChatWithPatient: request.requestId == request.receiverId,
								isChatWithAdmin:
									data.chatresponse.receiverId == Constants.AdminId
										? true
										: false,
								chatView: data.chatView,
								physicianName: data.chatresponse.physicianName,
							},
						]);
					});

					connection.on("openGroupChatBox", (data) => {
						console.log(data);
						setIsChatOpend(true);
						setOpenGroupChatBoxList([data]);
					});

					if (senderId == 0) {
						createCommonAPICall({
							apiService: profileService.getLoggedInUserId,
							isHideLoader: false,
						}).then((data) => {
							if (data.isSuccessfull) {
								console.log(data);
								setSenderId(data.data.id);
								connection!.send(
									"GetChatList",
									data.data.id,
									contextUser.userRoleId,
									AccountType.Patient,
									0,
									searchPatientName,
									searchPhysicianName
								);
							}
						});
					} else {
						connection!.send(
							"GetChatList",
							senderId,
							contextUser.userRoleId,
							AccountType.Patient,
							0,
							searchPatientName,
							searchPhysicianName
						);
					}
				})
				.catch((e) => {
					console.log("Error in connection: ", e);
					navigate(0);
				});
		}
	}, [connection]);

	useEffect(() => {
		getChatList();
		updateChatBoxClasses(openChatBoxList);
		if (connection)
			connection.on("SendPrivateMessage", (data) => handlePrivateMessage(data));
	}, [openChatBoxList]);

	useEffect(() => {
		getChatList();
		updateGroupChatBoxClasses(openGroupChatBoxList);
		if (connection)
			connection.on("sendGroupMessage", (data) => {
				console.log(data);
				setOpenGroupChatBoxList([data]);
			});
	}, [openGroupChatBoxList]);

	const handlePrivateMessage = (data: any) => {
		console.log("SendPrivateMessage", data, openChatBoxList);
		if (
			openChatBoxList.find(
				(chat) =>
					chat.confirmationNumber == data.chatresponse.confirmationNumber
			)
		) {
			if (data) setOpenChatBoxList(data.chatresponse);
			const request = data.chatresponse;
			const openChatBoxes = openChatBoxList.filter(
				(box) =>
					box.receiverId === request.receiverId &&
					box.senderId === request.senderId
			);
			if (!openChatBoxes || openChatBoxes?.length === 0) {
				setOpenChatBoxList([
					...openChatBoxList,
					{
						receiverId: data.chatresponse.receiverId,
						receiverName: data.chatresponse.receiverName,
						// request.requestId == request.receiverId
						// 	? data.chatresponse.patientName
						// 	: data.chatresponse.physicianName,
						senderId: data.chatresponse.senderId,
						senderName: data.chatresponse.senderName,
						isMinimized: false,
						confirmationNumber: data.chatresponse.confirmationNumber,
						isChatWithPatient: request.requestId == request.receiverId,
						isChatWithAdmin:
							data.chatresponse.receiverId == Constants.AdminId ? true : false,
						chatView: data.chatView,
						physicianName: data.chatresponse.physicianName,
					},
				]);
				hideLoader();
				return;
			}
			const chatBoxes = openChatBoxList.filter(
				(box) =>
					box.receiverId !== request.receiverId ||
					box.senderId !== request.senderId
			);
			setOpenChatBoxList([
				...chatBoxes,
				{
					receiverId: data.chatresponse.receiverId,
					receiverName:
						request.requestId == request.receiverId
							? data.chatresponse.patientName
							: data.chatresponse.physicianName,
					senderId: data.chatresponse.senderId,
					senderName: data.chatresponse.senderName,
					isMinimized: false,
					confirmationNumber: data.chatresponse.confirmationNumber,
					isChatWithPatient: request.requestId == request.receiverId,
					isChatWithAdmin:
						data.chatresponse.receiverId == Constants.AdminId ? true : false,
					chatView: data.chatView,
					physicianName: data.chatresponse.physicianName,
				},
			]);
		}
	};

	useEffect(() => {
		getChatList();
	}, [recieverType]);

	return (
		<Box>
			<div className="chatbox-overlay"></div>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="request-container-box">
					<div className="request-header">
						<Typography variant="h2">Chat History</Typography>
						<Button variant="outlined" onClick={handleOnClickBackCancel}>
							<img src={ArrowBack} alt="arrow" />
							Back
						</Button>
					</div>
					<Box className="request-box">
						<div className="request-header responsive-header">
							<Typography variant="h4">
								Chats ({AccountType[recieverType]})
							</Typography>
							<div
								className="small-center request-btn-group"
								style={{ paddingTop: 0 }}
							>
								<Tooltip title="Add new Chat">
									<Button
										variant="contained"
										onClick={handleClickAddChatModalOpen}
										color="secondary"
									>
										Add
									</Button>
								</Tooltip>
								<Tooltip title="Get Chats with Patient">
									<Button
										variant={
											recieverType == AccountType.Patient
												? "contained"
												: "outlined"
										}
										onClick={() => {
											setRecieverType(AccountType.Patient);
										}}
									>
										Patient
									</Button>
								</Tooltip>
								{contextUser.userRoleId === AccountType.Admin ? (
									<Tooltip title="Get Chats with Physician">
										<Button
											variant={
												recieverType == AccountType.Physician
													? "contained"
													: "outlined"
											}
											onClick={() => {
												setRecieverType(AccountType.Physician);
											}}
										>
											Physician
										</Button>
									</Tooltip>
								) : (
									<Tooltip title="Get Chats with Admin">
										<Button
											variant={
												recieverType == AccountType.Admin
													? "contained"
													: "outlined"
											}
											onClick={() => {
												setRecieverType(AccountType.Admin);
											}}
										>
											Admin
										</Button>
									</Tooltip>
								)}
								<Tooltip title="Get Chat Groups">
									<Button
										variant={
											recieverType == AccountType.Groups
												? "contained"
												: "outlined"
										}
										onClick={() => {
											setRecieverType(AccountType.Groups);
										}}
									>
										Groups
									</Button>
								</Tooltip>
							</div>
						</div>
						<div className="request-header responsive-header">
							<div className="table-header">
								<TextField
									id="search"
									placeholder="Search by Patient"
									variant="outlined"
									onKeyDown={handleSearchKeyUp}
									value={searchPatientName}
									onChange={handleOnPatientSearchChange}
									style={{ marginRight: "15px", marginBottom: "10px" }}
								/>
								{contextUser.userRoleId === AccountType.Admin && (
									<TextField
										id="search"
										placeholder="Search by Physician"
										variant="outlined"
										onKeyDown={handleSearchKeyUp}
										value={searchPhysicianName}
										onChange={handleOnPhysicianSearchChange}
										style={{ marginRight: "15px", marginBottom: "10px" }}
									/>
								)}
							</div>
						</div>
						{chatList && (
							<ChatList
								chatList={chatList}
								handleOpenChatClick={handleOpenChatClick}
							/>
						)}
					</Box>
				</Box>
			</main>

			<AddChatModal
				addChatModalOpen={addChatMOdalOpen}
				handleAddChatModalClose={handleClickAddChatModalClose}
				handleClickOk={handleNewChat}
			/>

			{isChatOpend &&
				openChatBoxList.map((chatBox, index) => {
					console.log(openChatBoxList);
					return (
						<ChatBox
							index={index}
							handleOnMinimized={handleOnMinimized}
							key={index}
							handleOnCloseChat={handleOnCloseChat}
							chatBoxInfo={chatBox}
							sendMessage={sendMessage}
							sendAudioVideo={sendAudioVideo}
							onKeyHandler={keyDownHandler}
							handleGroupChatStart={handleStartGroupChat}
						/>
					);
				})}

			{isChatOpend &&
				openGroupChatBoxList.map((chatBox, index) => {
					return (
						<GroupChatBox
							index={index}
							key={index}
							groupChatBoxInfo={chatBox}
							handleOnMinimized={handleOnGroupChatMinimized}
							handleOnCloseChat={handleOnCloseGroupChat}
							handleSendGroupMessage={handleSendGroupMessage}
							sendAudioVideo={sendAudioVideoGroup}
						/>
					);
				})}
		</Box>
	);
};

export default Chats;
