import { yupResolver } from "@hookform/resolvers/yup";
import {
	Box,
	Button,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { commonModalStyle } from "utility/helpers";
import { IProfessionInfo } from "utility/interfaces";
import { createProfession } from "utility/validations";

type ComponentProps = {
	isModelOpen: boolean;
	handleOnModalClose: any;
	formValues: IProfessionInfo;
	handleOnChangeFormValue: any;
};

export function CreateProfessionModal(props: ComponentProps) {
	// Extract Props
	const {
		isModelOpen,
		handleOnModalClose,
		formValues,
		handleOnChangeFormValue,
	} = props;

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		reset,
	} = useForm({
		resolver: yupResolver(createProfession),
	});

	// Events and functions
	const handleOnClickCreateOrUpdate = () => {
		console.log("create");
	};

	// useEffects
	useEffect(() => {
		reset();
		clearErrors();
	}, [isModelOpen]);

	return (
		<>
			<Modal
				open={isModelOpen}
				onClose={handleOnModalClose}
				className="send-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">
							{formValues.professionId ? " Update " : "Create "}Profession
						</Typography>
						<IconButton onClick={handleOnModalClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<div>
							<TextField
								{...register("professionName")}
								id="block"
								label="Profession Name"
								value={formValues.professionName}
								name="professionName"
								onChange={handleOnChangeFormValue}
								fullWidth
								error={errors?.professionName?.message ? true : false}
								helperText={errors?.professionName?.message?.toString()}
							/>
							<FormControl fullWidth>
								<InputLabel id="reason-label">Select Status </InputLabel>
								<Select
									labelId="region-label"
									id="region"
									name="status"
									value={formValues.status}
									label="Select Status"
									onChange={handleOnChangeFormValue}
									MenuProps={{
										className: "select-input-modal",
									}}
								>
									<MenuItem value={"active"}>Active</MenuItem>
									<MenuItem value={"inActive"}>InActive</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div className="modal-footer">
							<Tooltip title="Create">
								<Button
									variant="contained"
									onClick={handleSubmit(handleOnClickCreateOrUpdate)}
								>
									{formValues.professionId ? " Update" : "Create"}
								</Button>
							</Tooltip>
							<Tooltip title="Cancel">
								<Button variant="outlined" onClick={handleOnModalClose}>
									Cancel
								</Button>
							</Tooltip>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
