import React, { useState } from "react";
import {
	Box,
	Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
} from "@mui/material";
import {
	IRequestDataSearchCriteria,
	IHistorySearchFields,
} from "utility/interfaces";
import { CustomDatePicker } from "components";
import { Constants } from "utility/enums/constants";
import { SortOrder } from "utility/enums/sort-order";

type ComponentProps = {
	handleOnSearchCriteriaChange: any;
	searchFields: IHistorySearchFields;
	selectedRequests: number[];
	handleDeleteSelectedClick: any;
	selectAll: boolean;
	handleSelectAll: any;
};

export function RequestDataTableSearch(props: ComponentProps) {
	// Extract Props
	const {
		handleOnSearchCriteriaChange,
		handleDeleteSelectedClick,
		searchFields,
		selectedRequests,
		handleSelectAll,
		selectAll,
	} = props;

	// useStates
	const [formValues, setFormValues] = useState<IRequestDataSearchCriteria>({
		PageIndexId: 0,
		PageSize: Constants.DefaultTablePageSize.valueOf(),
		SortOrder: SortOrder.descending,
		SortBy: "CaseClosedDate",
		SearchBy: "",
		status: 0,
		requestType: 0,
		providerName: "",
		email: "",
		phoneNumber: "",
		fromDateOfService: "",
		patientName: "",
		toDateOfService: "",
		cancellationReason: null,
		notes: "",
	});

	// Handled events and functions
	const handleFormValueChange = (name: string, value: any) => {
		setFormValues({ ...formValues, [name]: value });
	};
	const handleOnClearForm = () => {
		handleOnSearchCriteriaChange({
			PageIndexId: 0,
			PageSize: Constants.DefaultTablePageSize.valueOf(),
			SortOrder: SortOrder.descending,
			SortBy: "CaseClosedDate",
			SearchBy: "",
			status: 0,
			requestType: 0,
			providerName: null,
			email: null,
			phoneNumber: null,
			fromDateOfService: null,
			patientName: null,
			toDateOfService: null,
			cancellationNotes: null,
			notes: null,
		});
		setFormValues({
			PageIndexId: 0,
			PageSize: Constants.DefaultTablePageSize.valueOf(),
			SortOrder: SortOrder.descending,
			SortBy: "CaseClosedDate",
			SearchBy: "",
			status: 0,
			requestType: 0,
			providerName: "",
			email: "",
			phoneNumber: "",
			fromDateOfService: "",
			patientName: "",
			toDateOfService: "",
			cancellationReason: null,
			notes: "",
		});
		if (selectAll) {
			handleSelectAll();
		}
	};

	const handleOnClickSearch = () => {
		handleOnSearchCriteriaChange({
			...formValues,
			providerName:
				formValues.providerName != "" ? formValues.providerName : null,
			email: formValues.email != "" ? formValues.email : null,
			phoneNumber: formValues.phoneNumber != "" ? formValues.phoneNumber : null,
			fromDateOfService:
				formValues.fromDateOfService != ""
					? formValues.fromDateOfService
					: null,
			patientName: formValues.patientName != "" ? formValues.patientName : null,
			toDateOfService:
				formValues.toDateOfService != "" ? formValues.toDateOfService : null,
			cancellationReason: formValues.cancellationReason,
			notes: formValues.notes != "" ? formValues.notes : null,
		});
		if (selectAll) {
			handleSelectAll();
		}
	};

	return (
		<>
			<div>
				<div className="history-table-header">
					<Grid container columnSpacing={{ sm: 2, lg: 3 }}>
						<Grid item xs={12} sm={6} md={4} laptop={3}>
							<FormControl fullWidth className="select-input">
								<InputLabel id="status-label">Select Request Status</InputLabel>
								<Select
									labelId="status-label"
									id="status-name"
									name="paymentStatus"
									value={
										formValues.status > 0
											? formValues.status.toString()
											: undefined || ""
									}
									onChange={(e) =>
										handleFormValueChange("status", e.target.value)
									}
									label="Select Payment Status"
									MenuProps={{
										className: "custom-select-dropdown",
									}}
								>
									{searchFields.requestStatus.map((item, index) => {
										return (
											<MenuItem key={index} value={item.value}>
												{item.text}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6} md={4} laptop={3}>
							<TextField
								fullWidth
								id="patient_name"
								label="Patient Name"
								variant="outlined"
								name="patientName"
								value={formValues.patientName}
								onChange={(e) =>
									handleFormValueChange("patientName", e.target.value)
								}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4} laptop={3}>
							<FormControl fullWidth className="select-input">
								<InputLabel id="request-type-label">
									Select Request Type
								</InputLabel>
								<Select
									labelId="request-type-label"
									id="request-type"
									name="requestType"
									value={
										formValues.requestType > 0
											? formValues.requestType.toString()
											: undefined || ""
									}
									onChange={(e) =>
										handleFormValueChange("requestType", e.target.value)
									}
									label="Select Payment Status"
									MenuProps={{
										className: "custom-select-dropdown",
									}}
								>
									{searchFields.requestType.map((item, index) => {
										return (
											<MenuItem key={index} value={item.value}>
												{item.text}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6} md={4} laptop={3}>
							<CustomDatePicker
								value={formValues.fromDateOfService ?? undefined}
								label="From Date Of Service"
								onChange={handleFormValueChange}
								name="fromDateOfService"
								disabled={false}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4} laptop={3}>
							<CustomDatePicker
								value={formValues.toDateOfService ?? undefined}
								label="To Date Of Service"
								onChange={handleFormValueChange}
								name="toDateOfService"
								disabled={false}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4} laptop={3}>
							<TextField
								fullWidth
								id="provider-name"
								label="Provider Name"
								variant="outlined"
								name="providerName"
								value={formValues.providerName}
								onChange={(e) =>
									handleFormValueChange("providerName", e.target.value)
								}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4} laptop={3}>
							<TextField
								fullWidth
								id="email"
								label="Email"
								variant="outlined"
								name="email"
								value={formValues.email}
								onChange={(e) => handleFormValueChange("email", e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4} laptop={3}>
							<TextField
								fullWidth
								id="phoneNumber"
								label="Phone Number"
								variant="outlined"
								name="phoneNumber"
								value={formValues.phoneNumber}
								onChange={(e) =>
									handleFormValueChange("phoneNumber", e.target.value)
								}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4} laptop={3}>
							{/* {formValues.status === 3 && ( */}
							<FormControl fullWidth className="select-input">
								<InputLabel id="cancellationReason-label">
									Select Reason for Cancellation
								</InputLabel>
								<Select
									labelId="cancellationReason-label"
									id="status-name"
									name="cancellationReason"
									value={formValues.cancellationReason?.toString() || ""}
									onChange={(e) =>
										handleFormValueChange("cancellationReason", e.target.value)
									}
									label="Select Reason for Cancellation"
									MenuProps={{
										className: "custom-select-dropdown",
									}}
								>
									{searchFields.caseTags.map((item, index) => {
										return (
											<MenuItem key={index} value={item.value}>
												{item.text}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
							{/* )} */}
						</Grid>
						<Grid item xs={12} sm={6} md={4} laptop={3}>
							<TextField
								fullWidth
								id="notes"
								label="Notes"
								variant="outlined"
								name="notes"
								value={formValues.notes}
								onChange={(e) => handleFormValueChange("notes", e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} laptop={6}>
							<Box
								className="request-btn-group"
								sx={{
									marginBottom: "14px",
								}}
							>
								{/* <div className="history-search-btn"> */}

								{selectedRequests.length > 0 && (
									<Tooltip title="Delete Selected">
										<Button
											variant="contained"
											color="error"
											onClick={handleDeleteSelectedClick}
										>
											<span className="button-link">Delete Selected</span>
										</Button>
									</Tooltip>
								)}
								<Tooltip title={selectAll ? "Unselect All" : "Select All"}>
									<Button
										sx={{ display: { xs: "block", laptop: "none" } }}
										onClick={handleSelectAll}
										variant={selectAll ? "contained" : "outlined"}
									>
										{selectAll ? "Unselect All" : "Select All"}
									</Button>
								</Tooltip>
								{/* </div> */}
								{/* <div className="history-search-btn"> */}
								<Tooltip title="Clear Search">
									<Button variant="outlined" onClick={handleOnClearForm}>
										<span className="button-link">Clear</span>
									</Button>
								</Tooltip>
								{/* </div> */}
								{/* <div className="history-search-btn"> */}
								<Tooltip title="Click To Search">
									<Button variant="contained" onClick={handleOnClickSearch}>
										<span className="button-link">Search</span>
									</Button>
								</Tooltip>
								{/* </div> */}
							</Box>
						</Grid>
					</Grid>
				</div>
			</div>
		</>
	);
}
