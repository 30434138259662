import React, { useState } from "react";
import myCustomTheme from "app.theme";
import { useNavigate } from "react-router-dom";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { AddIcon, ArrowBack } from "assests/images";
import { IProfessionInfo } from "utility/interfaces";
import { ProfessionInfoTable, CreateProfessionModal } from "components";

const ProfessionInfo: React.FC = () => {
	// Page level variable
	const navigate = useNavigate();

	// useStates
	const [isOpenCreateProfession, setIsOpenCreateProfession] = useState(false);
	const [formValues, setFormValues] = useState<IProfessionInfo>({
		professionName: "",
		status: "active",
	});

	// handled events or functions
	const handleOnChangeFormValue = (e: any) => {
		setFormValues({
			...formValues,
			[e.target.name]: e.target.value,
		});
	};
	const OnClickCreateProfession = () => {
		setFormValues({
			professionName: "",
			status: "active",
		});
		setIsOpenCreateProfession(true);
	};
	const OnClickEditProfession = (intialFormValue: IProfessionInfo) => {
		setFormValues(intialFormValue);
		setIsOpenCreateProfession(true);
	};

	return (
		<>
			<Box>
				<main className="main-content">
					<div
						className="overlay"
						onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
					></div>
					<Box className="request-container-box">
						<div className="request-header">
							<Typography variant="h2">Profession(s)</Typography>
							<Button variant="outlined" onClick={() => navigate(-1)}>
								<img src={ArrowBack} alt="arrow" />
								Back
							</Button>
						</div>
						<Box className="request-box">
							<div className="attachment">
								<Box
									className="selected-action-btn"
									sx={{ justifyContent: "flex-end", width: "100%" }}
								>
									<Tooltip title="Create Profession">
										<Button
											variant="outlined"
											disableElevation
											disableRipple
											disableFocusRipple
											className="min-w-111px min-w-sm-0"
											onClick={OnClickCreateProfession}
										>
											<img src={AddIcon} alt="download" />
											<span>Create</span>
										</Button>
									</Tooltip>
								</Box>
							</div>
							<ProfessionInfoTable handleOnClickEdit={OnClickEditProfession} />
						</Box>
					</Box>
				</main>
			</Box>
			<CreateProfessionModal
				isModelOpen={isOpenCreateProfession}
				handleOnModalClose={() => setIsOpenCreateProfession(false)}
				formValues={formValues}
				handleOnChangeFormValue={handleOnChangeFormValue}
			/>
		</>
	);
};

export default ProfessionInfo;
