import { User } from "assests/images";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { IScheduleData, IWeeklyCoverageHours } from "utility/interfaces";

type ComponentProps = {
	scheduleData: IScheduleData;
	currDate: Date;
	weeklyCoverageHours: IWeeklyCoverageHours[];
	handleEditShiftClick: any;
};

export const WeekView = (props: ComponentProps) => {
	//Extract props
	const { scheduleData, currDate, weeklyCoverageHours, handleEditShiftClick } =
		props;

	//use state
	const [objDate, setObjDate] = useState({
		fullDate: currDate,
		days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
		fullDays: [
			"Sunday",
			"Monday",
			"Tueday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
		],
		months: [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		],
	});

	//Handle events
	const renderWeekView = () => {
		let ele = document.getElementById("weekViewDiv");
		if (ele) {
			ele.innerHTML = "";
			let d = currDate;
			let currentDate = moment(currDate);

			const $table = document.createElement("table"),
				$tbody = document.createElement("tbody");

			let first = parseInt(moment(currDate).startOf("week").format("D"));

			if (scheduleData.physicianList) {
				const $Thtr = document.createElement("tr");
				$Thtr.innerHTML = `<th><div class="weekStaff">Staff</div></th>`;

				const $TCoverage = document.createElement("tr");
				$TCoverage.innerHTML = `<td class="whiteCoverage">Coverage</td>`;

				var da = first;
				for (var i = 0; i < objDate.days.length; i++) {
					const $th = document.createElement("th");

					const $div = document.createElement("div");
					$div.textContent = objDate.days[i] + " " + first;
					$th.append($div);

					$Thtr.append($th);

					var $td = document.createElement("td");
					$td.setAttribute("name", "coverage" + "_" + da);
					$td.setAttribute("shift", da.toString());
					$TCoverage.append($td);

					first = parseInt(
						moment(currDate)
							.startOf("week")
							.add(i + 1, "day")
							.format("D")
					);
					da = first;
				}

				$tbody.append($Thtr);
				$tbody.append($TCoverage);

				for (var k = 0; k < scheduleData.physicianList.length; k++) {
					da = parseInt(moment(currDate).startOf("week").format("D"));

					const $tr = document.createElement("tr"),
						$tdName = document.createElement("td");

					const $div2 = document.createElement("div");
					$div2.classList.add("dayDPhoto");

					const $img = document.createElement("img");
					$img.setAttribute(
						"src",
						scheduleData.physicianList[k].photo !== ""
							? scheduleData.physicianList[k].photo
							: User
					);
					$img.setAttribute("height", "29px");
					$img.setAttribute("width", "29px");
					$div2.append($img);

					$tdName.append($div2);

					const $div = document.createElement("div");
					$div.textContent = scheduleData.physicianList[k].name;
					$div.classList.add("dayDName");
					$tdName.append($div);

					$tdName.classList.add("physicianNames");

					$tr.append($tdName);
					for (var j = 0; j < objDate.days.length; j++) {
						const $td = document.createElement("td");
						$td.setAttribute(
							"name",
							"w_" + scheduleData.physicianList[k].physicianId + "_" + da
						);

						const $div = document.createElement("div");
						$td.append($div);

						$tr.append($td);
						da = parseInt(
							moment(currDate)
								.startOf("week")
								.add(j + 1, "day")
								.format("D")
						);
					}
					$tbody.append($tr);
				}

				for (var l = 0; l < scheduleData.shifts.length; l++) {
					var shiftProcessed = false;
					var color = "";
					currentDate = moment(
						new Date(
							moment(currDate).startOf("week").year(),
							moment(currDate).startOf("week").month(),
							moment(currDate).startOf("week").date()
						)
					);
					for (var i = 0; i < 7; i++) {
						if (
							scheduleData.shifts[l].date == currentDate.format("MM/DD/YYYY") &&
							!shiftProcessed
						) {
							if (scheduleData.shifts[l].status == 1) color = "redShifts";
							else color = "greenShifts";

							let oldHtmlEle = $tbody.querySelector(
								"[name=w_" +
									scheduleData.shifts[l].physicianId +
									"_" +
									parseInt(
										moment(scheduleData.shifts[l].date, "MM/DD/YYYY").format(
											"D"
										)
									) +
									"]"
							);
							if (oldHtmlEle) {
								var oldHtml = oldHtmlEle.innerHTML + "<br/>";
								var shiftDate = moment(
									moment(scheduleData.shifts[l].date)
										.startOf("day")
										.format("MM/DD/YYYY")
								);
								var curDate = moment(
									moment().startOf("day").format("MM/DD/YYYY")
								);

								oldHtmlEle.innerHTML =
									oldHtml +
									'<div class="weekShifts ' +
									(shiftDate < curDate ? "disableShifts " : "") +
									color +
									'" ShiftDetail="' +
									scheduleData.shifts[l].id +
									'"><div class="weekShiftTime">' +
									scheduleData.shifts[l].startFull +
									" - " +
									scheduleData.shifts[l].endFull +
									"</div> " +
									scheduleData.shifts[l].name +
									", " +
									scheduleData.shifts[l].region +
									"</div>";
							}

							shiftProcessed = true;
							continue;
						}
						currentDate.add(1, "day");
					}
				}

				$table.append($tbody);
				$table.classList.add("weekgrid");
				ele.append($table);
			}
			renderWeekViewColors();
			document.querySelectorAll(".weekShifts").forEach((e) => {
				e.addEventListener("click", (eve) => {
					let div = eve.target as HTMLDivElement;
					let selectedShift = scheduleData.shifts.find(
						(s) => s.id.toString() == div.getAttribute("ShiftDetail")
					);
					handleEditShiftClick(selectedShift);
				});
			});
		}
	};

	const renderWeekViewColors = () => {
		var currentDate = moment(currDate).startOf("week");
		var initialized = false;
		var date = moment();

		document.querySelectorAll("td[name^='coverage']").forEach(function (val) {
			var day = val.getAttribute("shift")!;

			if (!initialized) {
				date = moment(
					new Date(currentDate.year(), currentDate.month(), parseInt(day))
				);
				initialized = true;
			} else {
				date.add(1, "day");
			}

			var dayMatch = weeklyCoverageHours.find(function (val2) {
				return (
					moment(val2.date).format("MM/DD/YYYY") === date.format("MM/DD/YYYY")
				);
			});

			if (dayMatch) {
				if (dayMatch.totalHours >= 24) {
					val.classList.add("greyCoverage");
				} else if (dayMatch.totalHours >= 16 && dayMatch.totalHours < 24) {
					val.classList.add("orangeCoverage");
				} else {
					val.classList.add("pinkCoverage");
				}
				val.innerHTML = "<b>" + dayMatch.totalHours + "/24" + "</b>";
			} else {
				val.classList.add("pinkCoverage");
				val.innerHTML = "<b>" + "0/24" + "</b>";
			}
		});
	};

	//use effects
	useEffect(() => {
		renderWeekView();
	});

	return <div id="weekViewDiv"></div>;
};
