import { FormHelperText, InputLabel } from "@mui/material";
import React from "react";
import IntlTelInput from "react-intl-tel-input-18";

type ComponentProps = {
	isPhoneNumberValid: boolean;
	onChange: any;
	onBlur: any;
	value?: string;
	disabled?: boolean;
	name?: string;
};

export const CustomIntlTelInput = (props: ComponentProps) => {
	const { isPhoneNumberValid, onChange, onBlur, value, disabled, name } = props;

	return (
		<div style={{ marginBottom: "24px", width: "-webkit-fill-available" }}>
			<IntlTelInput
				disabled={disabled ?? false}
				defaultCountry="us"
				containerClassName="intl-tel-input intl-tel-container-custom"
				preferredCountries={["us"]}
				inputClassName={`intl-tel-input-custom ${
					!isPhoneNumberValid && !disabled ? "invalid" : ""
				} ${disabled ? "disabled" : ""}`}
				onPhoneNumberChange={onChange}
				onPhoneNumberBlur={onBlur}
				formatOnInit
				format
				useMobileFullscreenDropdown={false}
				{...(value !== "" && { defaultValue: value })}
				fieldName={name}
			/>
			<FormHelperText error id="component-error-text">
				<>
					{!isPhoneNumberValid && !disabled
						? value != ""
							? "Enter valid phone number"
							: "Phone number is required"
						: ""}
				</>
			</FormHelperText>
		</div>
	);
};
