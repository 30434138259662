import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import authService from "services/auth-service";
import tokenManager from "utility/auth-guards/token-manager";
import { UserDispatchContext } from "contexts/user-context";
import { AppRoutings } from "utility/enums/app-routings";
import {
	getTabStatusWiseName,
	showLoader,
	createCommonAPICall,
} from "utility/helpers";
import { InformationTabTypes } from "utility/enums/request-status";
import {
	ILoginResponse,
	IHttpsResponse,
	IMenu,
	IUserDetails,
} from "utility/interfaces";
import { MenuDispatchContext } from "contexts/menu-context";

const SSOLogin: React.FC = () => {
	// get query parameters
	const search = useLocation().search;
	const encodeString = new URLSearchParams(search).get("q");

	// Page level local variables
	const navigate = useNavigate();
	const setUserContext = useContext(UserDispatchContext);
	const setMenuContext = useContext(MenuDispatchContext);

	// Handled events and functions
	const DoSSOLogin = async () => {
		const data: IHttpsResponse<ILoginResponse> = await createCommonAPICall({
			requestBody: { encodeString },
			apiService: authService.ssoLogin,
			showSuccessMessage: false,
			showErrorMessage: false,
			isHideLoader: false,
		});

		if (data && data.isSuccessfull) {
			const userDetails: IUserDetails = {
				email: data.data.email,
				firstName: data.data.firstName,
				isOnCall: data.data.isOnCall,
				lastName: data.data.lastName,
				photo: data.data.photo,
				regionId: data.data.regionId,
				regionName: data.data.regionName,
				signature: data.data.signature,
				encUserName: data.data.encUserName,
				userRoleId: data.data.userRoleId,
				menuPermissions: "",
				isSuperAdmin: data.data.isSuperAdmin,
			};
			const menus = data.data.menuPermission;
			// const menus = JSON.stringify(menuPermissions);
			tokenManager.setAuthorization(
				data.data.dtySecret,
				data.data.accessToken,
				JSON.stringify(userDetails),
				menus
			);
			setUserContext(userDetails);
			const menuContext: IMenu = {
				selectedMenuKey: "",
				menus: JSON.parse(menus) || [],
			};
			setMenuContext(menuContext);
			navigate(
				AppRoutings.Dashboard.replace(
					":tabStatus",
					getTabStatusWiseName(InformationTabTypes.New).toLowerCase()
				)
			);
		}
	};

	// useEffects
	useEffect(() => {
		showLoader();
		DoSSOLogin();
		console.log("background process will be here.");
	}, []);

	return <></>;
};

export default SSOLogin;
