import { Popover } from "@mui/material";
import {
	CalendarPicker,
	DatePicker,
	LocalizationProvider,
} from "@mui/x-date-pickers";
import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

type ComponentProps = {
	isOpen: boolean;
	anchorEl: any;
	onChange: any;
	date: Date;
	handleClose: any;
};

export const CustomDatePopover = (props: ComponentProps) => {
	// Extract Props
	const { isOpen, anchorEl, onChange, date, handleClose } = props;
	return (
		<Popover
			open={isOpen}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "left",
			}}
		>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<CalendarPicker
					date={dayjs(date)}
					onChange={(e) => {
						onChange(e?.toDate());
					}}
				/>
			</LocalizationProvider>
		</Popover>
	);
};
