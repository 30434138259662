import React, { useEffect, useState } from "react";
import {
	Box,
	Checkbox,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Tab,
	Tabs,
	TextField,
	Typography,
} from "@mui/material";
import {
	CheckIcon,
	RadioCheckIcon,
	RadioUncheckIcon,
	RadioUncheckIconDark,
	UncheckIcon,
} from "assests/images";
import { IDoctorNote } from "utility/interfaces";
import { getArrayOfNumber } from "utility/helpers";
import { CustomDatePicker } from "components";

type ComponentProps = {
	formValues: IDoctorNote;
	handleFormValueChange: any;
};

export function CheckBoxIcon() {
	return <img src={UncheckIcon} alt="checkbox" />;
}

export function CheckedBoxIcon() {
	return <img src={CheckIcon} alt="checkbox" />;
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}
function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`radio-tabpanel-${index}`}
			aria-labelledby={`radio-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box className="radio-tab-content">
					<>{children}</>
				</Box>
			)}
		</div>
	);
}

export function DoctorNoteForm(props: ComponentProps) {
	// Extract props
	const { formValues, handleFormValueChange } = props;

	// local variables
	const improveAfterDays = getArrayOfNumber(1, 31);

	// handled events and functions
	function a11yProps(index: number) {
		return {
			id: `radio-tab-${index}`,
			"aria-controls": `radio-tabpanel-${index}`,
		};
	}

	const getCheckBoxInpputs = () => {
		return (
			<FormControl component="fieldset" className="radio-inputs">
				<FormGroup row>
					<FormControlLabel
						value="end"
						control={
							<Checkbox
								disableFocusRipple
								disableRipple
								icon={<CheckBoxIcon />}
								checked={formValues.travel}
								onChange={() =>
									handleFormValueChange("travel", !formValues.travel)
								}
								checkedIcon={<CheckedBoxIcon />}
							/>
						}
						label="Travel"
						labelPlacement="end"
					/>
					<FormControlLabel
						value="end"
						control={
							<Checkbox
								disableFocusRipple
								disableRipple
								checked={formValues.flight}
								onChange={() =>
									handleFormValueChange("flight", !formValues.flight)
								}
								icon={<CheckBoxIcon />}
								checkedIcon={<CheckedBoxIcon />}
							/>
						}
						label="Flight"
						labelPlacement="end"
					/>
					<FormControlLabel
						value="end"
						control={
							<Checkbox
								disableFocusRipple
								disableRipple
								checked={formValues.school}
								onChange={() =>
									handleFormValueChange("school", !formValues.school)
								}
								icon={<CheckBoxIcon />}
								checkedIcon={<CheckedBoxIcon />}
							/>
						}
						label="School"
						labelPlacement="end"
					/>
					<FormControlLabel
						value="end"
						control={
							<Checkbox
								disableFocusRipple
								disableRipple
								checked={formValues.work}
								onChange={() => handleFormValueChange("work", !formValues.work)}
								icon={<CheckBoxIcon />}
								checkedIcon={<CheckedBoxIcon />}
							/>
						}
						label="Work"
						labelPlacement="end"
					/>
					<FormControlLabel
						value="end"
						control={
							<Checkbox
								disableFocusRipple
								disableRipple
								checked={formValues.sports}
								onChange={() =>
									handleFormValueChange("sports", !formValues.sports)
								}
								icon={<CheckBoxIcon />}
								checkedIcon={<CheckedBoxIcon />}
							/>
						}
						label="Sports"
						labelPlacement="end"
					/>
				</FormGroup>
			</FormControl>
		);
	};

	return (
		<Box className="request-box">
			<div>
				<Typography variant="h4">Patient Information</Typography>
				<Grid container columnSpacing={{ sm: 2, lg: 3 }}>
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							id="first-name"
							label="First Name"
							variant="outlined"
							value={formValues.firstName}
							name="firstName"
							onChange={(e) =>
								handleFormValueChange("firstName", e.target.value)
							}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							id="last-name"
							label="Last Name"
							variant="outlined"
							value={formValues.lastName}
							name="lastName"
							onChange={(e) =>
								handleFormValueChange("lastName", e.target.value)
							}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CustomDatePicker
							name="dateOfBirth"
							value={formValues.dateOfBirth}
							label="Date of Birth"
							onChange={handleFormValueChange}
							disabled={false}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CustomDatePicker
							name="dateOfExamination"
							value={formValues.dateOfExamination}
							label="Date of Examination"
							onChange={handleFormValueChange}
							disabled={false}
						/>
					</Grid>
				</Grid>
			</div>
			<div className="radio-tab-container">
				<Box className="radio-tabs">
					<Tabs
						value={formValues.mayResume}
						onChange={(e, newValue) => {
							handleFormValueChange("mayResume", newValue);
						}}
						variant="fullWidth"
						scrollButtons="auto"
					>
						<Tab
							disableFocusRipple
							disableRipple
							label={
								<React.Fragment>
									<img
										src={RadioCheckIcon}
										alt="radio"
										className="checked-icon"
									/>
									<img
										src={RadioUncheckIcon}
										alt="radio"
										className="check-icon light-icon"
									/>
									<img
										src={RadioUncheckIconDark}
										alt="radio"
										className="check-icon dark-icon"
									/>
									May Resume
								</React.Fragment>
							}
							{...a11yProps(0)}
						/>
						<Tab
							disableFocusRipple
							disableRipple
							label={
								<React.Fragment>
									<img
										src={RadioCheckIcon}
										alt="radio"
										className="checked-icon"
									/>
									<img
										src={RadioUncheckIcon}
										alt="radio"
										className="check-icon light-icon"
									/>
									<img
										src={RadioUncheckIconDark}
										alt="radio"
										className="check-icon dark-icon"
									/>
									May Not Resume
								</React.Fragment>
							}
							{...a11yProps(1)}
						/>
					</Tabs>
				</Box>
				<TabPanel value={formValues.mayResume} index={0}>
					<>{getCheckBoxInpputs()}</>
					<div>
						<TextField
							id="others"
							label="Others"
							multiline
							maxRows={1}
							value={formValues.other}
							name="other"
							onChange={(e) => handleFormValueChange("other", e.target.value)}
							inputProps={{ className: "min-h-80px overflow-auto" }}
							fullWidth
							className="radio-inputs textarea"
						/>
					</div>
					<Grid container sx={{ marginBottom: "-1px" }}>
						<Grid item xs={12} sm={6}>
							<CustomDatePicker
								name="onDate"
								value={formValues.onDate}
								label="On Date"
								onChange={handleFormValueChange}
								disabled={false}
							/>
						</Grid>
					</Grid>
				</TabPanel>
				<TabPanel value={formValues.mayResume} index={1}>
					<>{getCheckBoxInpputs()}</>
					<div>
						<TextField
							id="others"
							label="Others"
							multiline
							maxRows={1}
							value={formValues.other}
							name="other"
							onChange={(e) => handleFormValueChange("other", e.target.value)}
							inputProps={{ className: "min-h-80px overflow-auto" }}
							fullWidth
							className="radio-inputs textarea"
						/>
					</div>
					<Typography variant="body1" className="input-title">
						Until condition has improved in days after examination:
					</Typography>
					<Grid container sx={{ marginBottom: "-1px" }}>
						<Grid item xs={12} sm={6}>
							<FormControl fullWidth className="select-input radio-inputs">
								<InputLabel id="business-label">Select Value</InputLabel>
								<Select
									labelId="business-label"
									id="business-name"
									name="improveDayAfter"
									value={formValues.improveDayAfter}
									onChange={(e) =>
										handleFormValueChange("improveDayAfter", e.target.value)
									}
									label="Until condition has improved in days after examination:"
									MenuProps={{
										className: "select-input-modal custom-select-dropdown",
									}}
								>
									{improveAfterDays.map((value, index) => {
										return (
											<MenuItem key={index} value={value}>
												{value}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</TabPanel>
			</div>
			<Divider />
			<div>
				<TextField
					id="restriction"
					label="Restriction or Accommodations"
					multiline
					className="textarea"
					maxRows={1}
					name="restriction"
					value={formValues.restriction}
					onChange={(e) => handleFormValueChange("restriction", e.target.value)}
					inputProps={{ className: "min-h-80px overflow-auto" }}
					fullWidth
				/>
			</div>
		</Box>
	);
}
