import { yupResolver } from "@hookform/resolvers/yup";
import {
	Modal,
	Box,
	Typography,
	IconButton,
	Button,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
} from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import haloService from "services/halo-service";
import { commonModalStyle, createCommonAPICall } from "utility/helpers";
import { IDropDownList, IHaloEmployee } from "utility/interfaces";
import { AddEditHaloEmployeeSchema } from "utility/validations";
import { CheckIcon, UncheckIcon } from "assests/images";
import { Checkbox, FormControlLabel } from "@mui/material";

type ComponentProps = {
	isOpen: boolean;
	handleClose: any;
	haloEmpData: IHaloEmployee | null;
	getHaloEmpData: any;
};

export const AddEditHaloEmployeeModal = (props: ComponentProps) => {
	// Extract props
	const { isOpen, handleClose, haloEmpData, getHaloEmpData } = props;

	// Page level variables
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// State variables
	const [formValues, setFormValues] = useState({
		workPlaceEmployeeId: 0,
		firstName: "",
		lastName: "",
		email: "",
		isIndividual: false,
		workPlaceId: 0,
	});

	const [workPlacesList, setWorkPlacesList] = useState<IDropDownList[]>([]);

	// Handle events
	const handleOnChangeFormValue = (name: string, value: any) => {
		setFormValues({ ...formValues, [name]: value });
	};

	const getEmployeeFormDetails = async () => {
		let queryString = "0";
		if (haloEmpData) queryString = haloEmpData.queryString;

		const requestBody = {
			workPlaceEmployeeId: queryString,
		};

		const data = await createCommonAPICall({
			requestBody,
			apiService: haloService.getHaloEmployee,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			console.log(data);
			setWorkPlacesList(data.data.workPlaces);
			if (haloEmpData && data.data.employee) {
				setFormValues(data.data.employee);
			}
		}
	};

	function CheckBoxIcon() {
		return <img src={UncheckIcon} alt="checkbox" />;
	}
	function CheckedBoxIcon() {
		return <img src={CheckIcon} alt="checkbox" />;
	}

	const clearFormValues = () => {
		setFormValues({
			workPlaceEmployeeId: 0,
			firstName: "",
			lastName: "",
			email: "",
			isIndividual: false,
			workPlaceId: 0,
		});
	};

	const handleCloseModal = () => {
		clearFormValues();
		handleClose();
	};

	const handleSaveEmployee = async (isUpdate: boolean) => {
		const data = await createCommonAPICall({
			requestBody: {
				...formValues,
				workPlaceName: workPlacesList.find(
					(w) => w.value === formValues.workPlaceId
				)?.text,
			},
			apiService: isUpdate
				? haloService.updateHaloEmployee
				: haloService.createHaloEmployee,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			getHaloEmpData();
			handleCloseModal();
		}
	};

	// Form validations
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
	} = useForm({
		values: formValues,
		resolver: yupResolver(AddEditHaloEmployeeSchema),
	});

	// Use effects
	useEffect(() => {
		clearErrors();
		if (isOpen) getEmployeeFormDetails();
	}, [isOpen]);

	return (
		<>
			<Modal open={isOpen} onClose={handleCloseModal} className="send-modal">
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">
							{haloEmpData ? "Edit" : "Create"}
						</Typography>
						<IconButton onClick={handleCloseModal}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<div>
							<TextField
								{...register("firstName")}
								error={errors.firstName?.message ? true : false}
								helperText={errors.firstName?.message?.toString()}
								name="firstName"
								id="firstName"
								label="First Name"
								fullWidth
								value={formValues.firstName}
								onChange={(e) =>
									handleOnChangeFormValue(e.target.name, e.target.value)
								}
							/>
							<TextField
								{...register("lastName")}
								error={errors.lastName?.message ? true : false}
								helperText={errors.lastName?.message?.toString()}
								name="lastName"
								id="lastName"
								label="Last Name"
								fullWidth
								value={formValues.lastName}
								onChange={(e) =>
									handleOnChangeFormValue(e.target.name, e.target.value)
								}
							/>
							<TextField
								{...register("email")}
								error={errors.email?.message ? true : false}
								helperText={errors.email?.message?.toString()}
								name="email"
								id="email"
								label="Email"
								fullWidth
								value={formValues.email}
								onChange={(e) =>
									handleOnChangeFormValue(e.target.name, e.target.value)
								}
							/>

							{!haloEmpData && (
								<FormControlLabel
									value="end"
									control={
										<Checkbox
											{...register("isIndividual")}
											disableFocusRipple
											disableRipple
											onChange={() => {
												handleOnChangeFormValue(
													"isIndividual",
													!formValues.isIndividual
												);
											}}
											icon={<CheckBoxIcon />}
											value={formValues.isIndividual}
											checked={formValues.isIndividual}
											checkedIcon={<CheckedBoxIcon />}
										/>
									}
									label="Is Individual"
									labelPlacement="end"
								/>
							)}

							{!formValues.isIndividual && (
								<FormControl fullWidth className="select-input">
									<InputLabel id="refill-label">Work Place Name</InputLabel>
									<Select
										{...register("workPlaceId")}
										error={errors.workPlaceId?.message ? true : false}
										labelId="refill-label"
										id="refill-name"
										value={formValues.workPlaceId}
										label="Work Place Name"
										name="workPlaceId"
										onChange={(e) =>
											handleOnChangeFormValue(e.target.name, e.target.value)
										}
										MenuProps={{
											className: "select-input-modal custom-select-dropdown",
											style: {
												width: "450px",
											},
										}}
									>
										{workPlacesList?.map((workPlace) => {
											return (
												<MenuItem key={workPlace.value} value={workPlace.value}>
													{workPlace.text}
												</MenuItem>
											);
										})}
									</Select>
									<FormHelperText error>
										{errors.workPlaceId?.message?.toString()}
									</FormHelperText>
								</FormControl>
							)}
						</div>
						<div className="modal-footer">
							{haloEmpData ? (
								<Button
									variant="contained"
									onClick={handleSubmit(() => {
										handleSaveEmployee(true);
									})}
								>
									Update
								</Button>
							) : (
								<Button
									variant="contained"
									onClick={handleSubmit(() => {
										handleSaveEmployee(false);
									})}
								>
									Create
								</Button>
							)}
							<Button variant="outlined" onClick={handleCloseModal}>
								Cancel
							</Button>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
};
