import {
	ButtonProps,
	styled,
	Button,
	Card,
	CardContent,
	CardHeader,
	Collapse,
	TableCell,
	TableRow,
	Typography,
} from "@mui/material";
import {
	PatientPrimaryIcon,
	CalendarIcon,
	StatusCheckPrimaryIcon,
	MailIconBlue,
} from "assests/images";
import { ExpandMore } from "components";
import moment from "moment";
import React, { useState } from "react";
import { ISMSLog } from "utility/interfaces";

type ComponentProps = {
	row: ISMSLog;
	isSmallDevice: boolean;
};

export const SMSLogsTableRow = (props: ComponentProps) => {
	//Extract props
	const { row, isSmallDevice } = props;

	// Use state
	const [expanded, setExpanded] = useState(false);

	// handle events
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<>
			{!isSmallDevice ? (
				<>
					<TableRow className="bg-white">
						<TableCell component="th" scope="row" className="wrap">
							{row.firstName ?? "-"}
						</TableCell>
						<TableCell component="th" scope="row" className="wrap">
							{row.actionName ?? "-"}
						</TableCell>
						<TableCell component="th" scope="row" className="wrap">
							{row.roleName}
						</TableCell>
						<TableCell component="th" scope="row" className="wrap">
							{row.mobileNumber}
						</TableCell>
						<TableCell component="th" scope="row" className="wrap">
							{row.createDateTime}
						</TableCell>
						<TableCell component="th" scope="row" className="wrap">
							{row.sentDate ? moment(row.sentDate).format("MMM DD, YYYY") : "-"}
						</TableCell>
						<TableCell component="th" scope="row" className="wrap">
							{row.isEmailSent ? "Yes" : "No"}
						</TableCell>
						<TableCell component="th" scope="row" className="wrap">
							{row.sentTries ?? "-"}
						</TableCell>
						<TableCell component="th" scope="row" className="wrap">
							{row.confirmationNumber === ""
								? "-"
								: (row.confirmationNumber ?? "-")}
						</TableCell>
					</TableRow>
				</>
			) : (
				// Mobile view
				<Card className="bg-white">
					<div className="card-container">
						<ExpandMore
							expand={expanded}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label="show more"
						>
							<CardHeader
								title={
									<React.Fragment>
										<span>{row.firstName}</span>
									</React.Fragment>
								}
							/>
							<CardContent className="card-header-content">
								<Typography>
									Action Name:&nbsp;
									<span>{row.actionName}</span>
								</Typography>
								<Typography>
									Mobile Number:&nbsp;
									<span>{row.mobileNumber}</span>
								</Typography>
							</CardContent>
						</ExpandMore>
						<Collapse in={expanded} timeout="auto" unmountOnExit>
							<CardContent>
								<div>
									<span>
										<img src={PatientPrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Role Name:&nbsp;
										<span>{row.roleName}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={CalendarIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Create Date:&nbsp;
										<span>{row.createDateTime}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={CalendarIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Sent Date:&nbsp;
										<span>
											{row.sentDate
												? moment(row.sentDate).format("MMM DD, YYYY")
												: "-"}
										</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={StatusCheckPrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Sent:&nbsp;
										<span>{row.isEmailSent ? "Yes" : "No"}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={MailIconBlue} alt="calender" />
									</span>
									<Typography variant="h6">
										Sent Tries:&nbsp;
										<span>{row.sentTries}</span>
									</Typography>
								</div>
								<div>
									<span>
										<img src={StatusCheckPrimaryIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Confirmation Number:&nbsp;
										<span>{row.confirmationNumber ?? "-"}</span>
									</Typography>
								</div>
								<div></div>
							</CardContent>
						</Collapse>
					</div>
				</Card>
			)}
		</>
	);
};
