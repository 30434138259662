import { yupResolver } from "@hookform/resolvers/yup";
import {
	Box,
	Button,
	IconButton,
	Modal,
	TextField,
	Typography,
} from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { commonModalStyle, getTypeWiseClassName } from "utility/helpers";
import { IRequest } from "utility/interfaces";
import { sendDocumentSchema } from "utility/validations";

// Page level interface
interface ISendDocument {
	email: string;
}

type ComponentProps = {
	isSendDocumentModalOpen: boolean;
	sendDocumentModalClose: any;
	handleOnClickSend: any;
	onSetEmail: any;
	values?: ISendDocument;
};

export function SendDocumetModal(props: ComponentProps) {
	// useStates
	const [formValues, setFormValues] = useState<ISendDocument>({
		email: "",
	});

	const resetFormValue = () => {
		setFormValues({
			email: "",
		});
	};

	// Extract Props
	const { isSendDocumentModalOpen, sendDocumentModalClose, handleOnClickSend } =
		props;

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
	} = useForm({
		values: formValues,
		resolver: yupResolver(sendDocumentSchema),
	});

	// useEffects
	useEffect(() => {
		if (props.values) {
			setFormValues({
				email: props.values.email,
			});
		} else {
			reset();
			clearErrors();
			resetFormValue();
		}
	}, [isSendDocumentModalOpen]);

	// Events and functions
	const handleFormValueChange = (e: any) => {
		setFormValues({ ...formValues, [e.target.name]: e.target.value });
		props.onSetEmail(e.target.value);
		console.log(e.target.value);
	};

	return (
		<>
			<Modal
				open={isSendDocumentModalOpen}
				onClose={sendDocumentModalClose}
				className="send-modal select-input-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">Send Document</Typography>
						<IconButton onClick={sendDocumentModalClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<div>
							<TextField
								{...register("email")}
								name="email"
								id="block"
								label="Email"
								value={formValues.email}
								onChange={handleFormValueChange}
								fullWidth
								error={errors?.email?.message ? true : false}
								helperText={errors?.email?.message?.toString()}
							/>
						</div>
						<div className="modal-footer">
							<Button
								variant="contained"
								onClick={handleSubmit(handleOnClickSend)}
							>
								Send
							</Button>
							<Button variant="outlined" onClick={sendDocumentModalClose}>
								Cancel
							</Button>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
