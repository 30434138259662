import { yupResolver } from "@hookform/resolvers/yup";
import {
	Box,
	Button,
	IconButton,
	Modal,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import React, { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { commonModalStyle, createCommonAPICall } from "utility/helpers";
import { sendEmailSchema } from "utility/validations";
import myCustomTheme from "app.theme";
import { CustomCopyTooltip } from "components";
import dashboardService from "services/dashboard-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { IRequest, ISendEmail } from "utility/interfaces";

type ComponentProps = {
	sendMailOpen: boolean;
	handleSendEmailModalClose: any;
	row: IRequest;
};

export function SendEmailModal(props: ComponentProps) {
	// Usestates
	const [message, setMessage] = useState("");

	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// Extract Props
	const { sendMailOpen, handleSendEmailModalClose, row } = props;

	// Yup resolver
	const {
		register,
		handleSubmit,
		reset,
		clearErrors,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(sendEmailSchema),
	});

	// Handled events and functions
	const handleOnClickConfirm = async () => {
		setMessage("");
		console.log(row);
		const request: ISendEmail = {
			requestId: row.requestId,
			emailBody: message,
			email: row.email,
		};
		console.log(request);
		const data = await createCommonAPICall({
			requestBody: request,
			apiService: dashboardService.sendEmail,
			showSuccessMessage: true,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.isSuccessfull) handleSendEmailModalClose(true);
	};

	// useEffects
	useEffect(() => {
		reset();
		clearErrors();
		setMessage("");
	}, [sendMailOpen]);

	return (
		<>
			<Modal
				open={sendMailOpen}
				onClose={handleSendEmailModalClose}
				className="send-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">Send Mail</Typography>
						<IconButton onClick={handleSendEmailModalClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<div>
							<Typography variant="h6" className="patient-name">
								<Box className="min-w-125 min-w-sm-110px">
									Patient Name :&nbsp;
								</Box>
								<Box className="name">
									<span>{row.name}</span>
									<CustomCopyTooltip
										canDoCopy={true}
										text="Copy"
										copyText={row.email}
									>
										<span className="email-wrapper">
											&#40;<span>{row.email}</span>&#41;
										</span>
									</CustomCopyTooltip>
								</Box>
							</Typography>
							<TextField
								{...register("message")}
								id="block"
								label="Message"
								value={message}
								onChange={(e) => setMessage(e.target.value)}
								multiline
								maxRows={1}
								inputProps={{ className: "min-h-88px" }}
								fullWidth
								error={errors?.message?.message ? true : false}
								helperText={errors?.message?.message?.toString()}
							/>
						</div>
						<div className="modal-footer">
							<Tooltip title="Confirm">
								<Button
									variant="contained"
									onClick={handleSubmit(handleOnClickConfirm)}
								>
									Confirm
								</Button>
							</Tooltip>
							<Tooltip title="Cancel">
								<Button variant="outlined" onClick={handleSendEmailModalClose}>
									Cancel
								</Button>
							</Tooltip>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
