import {
	Button,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	TextField,
	Tooltip,
	Typography,
	Checkbox,
	FormHelperText,
} from "@mui/material";
import { CheckIcon, UncheckIcon } from "assests/images";
import { CustomIntlTelInput } from "components";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { UserContext } from "contexts/user-context";
import React, { useContext, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { CountryData } from "react-intl-tel-input-18";
import { useNavigate } from "react-router-dom";
import roleService from "services/role-service";
import { AppRoutings } from "utility/enums/app-routings";
import { AccountType } from "utility/enums/request-status";
import { createCommonAPICall } from "utility/helpers";
import { IMyProfileInfo } from "utility/interfaces";

type ComponentProps = {
	formValues: IMyProfileInfo;
	handleOnChangeFormValue: any;
	handleOnCheckBoxCLick: any;
	formValidator: UseFormReturn<IMyProfileInfo, any>;
	getMyProfile: any;
	handleSaveProfile: any;
	setFormValues: any;
	providerId?: string;
	isNew: boolean;
	renderer: number;
	isPhoneNumberValid: boolean;
	setIsPhoneNumberValid: any;
	isAdminProfile: boolean;
	isMyProfile: boolean;
};

export function AdministratorInformation(props: ComponentProps) {
	// Use Context
	const contextUser = useContext(UserContext);
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	//Page level local variable
	const navigate = useNavigate();

	// Extract Props
	const {
		formValues,
		handleOnChangeFormValue,
		handleOnCheckBoxCLick,
		formValidator,
		getMyProfile,
		handleSaveProfile,
		providerId,
		isNew,
		setFormValues,
		renderer,
		isPhoneNumberValid,
		setIsPhoneNumberValid,
		isAdminProfile,
		isMyProfile,
	} = props;

	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setError,
		clearErrors,
		reset,
	} = formValidator;

	// useStates
	const [isEditAdministratorInformation, setIsEditAdministratorInformation] =
		useState(false);

	// Handled events and functions
	function CheckBoxIcon() {
		return <img src={UncheckIcon} alt="checkbox" />;
	}
	function CheckedBoxIcon() {
		return <img src={CheckIcon} alt="checkbox" />;
	}

	const handleCheckBoxValuesSave = () => {
		setIsEditAdministratorInformation(false);
		handleSaveProfile();
	};

	const handleGenerateUsername = async () => {
		console.log(formValues.firstName, formValues.lastName);
		if (formValues.firstName != "" && formValues.lastName != "") {
			let username = providerId
				? `MD.${formValues.lastName}.${formValues.firstName[0]}`
				: `AD.${formValues.lastName}.${formValues.firstName[0]}`;
			console.log(username);
			const requestBody = {
				oldUsername: username,
				firstName: formValues.firstName,
				lastName: formValues.lastName,
				role: providerId ? AccountType.Physician : AccountType.Admin,
			};
			const data = await createCommonAPICall({
				requestBody,
				apiService: roleService.getFreshUsername,
				showErrorMessage: true,
				showSuccessMessage: false,
				setSuccessErrorContext,
			});

			if (data && data.isSuccessfull) {
				console.log(data);
				setFormValues({ ...formValues, userName: data.data });
			}
		} else {
			setFormValues({ ...formValues, userName: "" });
		}
	};

	const handlePhoneNumberChange = (
		isValid: boolean,
		phoneNumber: string,
		countryData: CountryData
	) => {
		setIsPhoneNumberValid(isValid);
		let formattedPhoneNumber =
			phoneNumber != ""
				? phoneNumber.includes("+")
					? phoneNumber
					: `+${countryData.dialCode} ${phoneNumber}`
				: "";
		setFormValues({ ...formValues, mobileNumber: formattedPhoneNumber });
	};

	return (
		<>
			<Typography variant="h4">
				{contextUser.userRoleId === AccountType.Physician ||
				formValues.physicianId > 0
					? "Physician "
					: "Administrator "}
				Information
			</Typography>
			<div>
				<Grid container columnSpacing={{ sm: 2, lg: 3 }}>
					<Grid item xs={12} sm={6}>
						<TextField
							{...register("firstName")}
							error={errors.firstName?.message ? true : false}
							helperText={errors.firstName?.message?.toString()}
							disabled={!isNew && !isEditAdministratorInformation}
							fullWidth
							id="first-name"
							label="First Name"
							variant="outlined"
							name="firstName"
							value={formValues.firstName}
							onChange={handleOnChangeFormValue}
							{...(isNew
								? {
										onBlurCapture: handleGenerateUsername,
									}
								: {})}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							{...register("lastName")}
							error={errors.lastName?.message ? true : false}
							helperText={errors.lastName?.message?.toString()}
							disabled={!isNew && !isEditAdministratorInformation}
							fullWidth
							id="last-name"
							label="Last Name"
							variant="outlined"
							name="lastName"
							value={formValues.lastName}
							onChange={handleOnChangeFormValue}
							{...(isNew
								? {
										onBlurCapture: handleGenerateUsername,
									}
								: {})}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							{...register("email")}
							error={errors.email?.message ? true : false}
							helperText={errors.email?.message?.toString()}
							disabled={!isNew && !isEditAdministratorInformation}
							fullWidth
							id="email"
							label="Email"
							variant="outlined"
							name="email"
							value={formValues.email}
							onChange={handleOnChangeFormValue}
						/>
					</Grid>
					{isAdminProfile && (
						<Grid item xs={12} sm={6}>
							<TextField
								{...register("confirmEmail")}
								error={errors.confirmEmail?.message ? true : false}
								helperText={errors.confirmEmail?.message?.toString()}
								disabled={!isNew && !isEditAdministratorInformation}
								fullWidth
								id="confirm-email"
								label="Confirm Email"
								variant="outlined"
								name="confirmEmail"
								value={formValues.confirmEmail}
								onChange={handleOnChangeFormValue}
							/>
						</Grid>
					)}

					<Grid item xs={12} sm={6}>
						{renderer > 0 && (
							<CustomIntlTelInput
								isPhoneNumberValid={isPhoneNumberValid}
								onChange={handlePhoneNumberChange}
								value={formValues.mobileNumber}
								onBlur={() => {}}
								disabled={!isNew && !isEditAdministratorInformation}
							/>
						)}

						{/* <TextField
							{...register("mobileNumber")}
							error={errors.mobileNumber?.message ? true : false}
							helperText={errors.mobileNumber?.message?.toString()}
							disabled={!isNew && !isEditAdministratorInformation}
							fullWidth
							id="mobile-number"
							label="Mobile Number"
							variant="outlined"
							name="mobileNumber"
							value={formValues.mobileNumber}
							onChange={handleOnChangeFormValue}
						/> */}
					</Grid>
					{!isAdminProfile && (
						<Grid item xs={12} sm={6}>
							<TextField
								disabled={!isNew && !isEditAdministratorInformation}
								fullWidth
								id="medical-license"
								label="Medical License #"
								variant="outlined"
								name="medicalLicense"
								value={formValues.medicalLicense}
								onChange={handleOnChangeFormValue}
							/>
						</Grid>
					)}
					{!isAdminProfile && (
						<Grid item xs={12} sm={6}>
							<TextField
								disabled={!isNew && !isEditAdministratorInformation}
								fullWidth
								id="npi-number"
								label="NPI Number"
								variant="outlined"
								name="npiNumber"
								value={formValues.NPINumber}
								onChange={handleOnChangeFormValue}
							/>
						</Grid>
					)}
					{contextUser.userRoleId === AccountType.Admin &&
						formValues.physicianId > 0 && (
							<Grid item xs={12} sm={6}>
								<TextField
									disabled={!isEditAdministratorInformation}
									fullWidth
									id="sync-email-address"
									label="Synchronization Email Address"
									variant="outlined"
									name="syncEmailAddress"
									value={formValues.syncEmailAddress}
									onChange={handleOnChangeFormValue}
								/>
							</Grid>
						)}
					<Grid item xs={12} sm={6}>
						<FormControl component="fieldset" className="radio-inputs">
							<FormGroup row>
								<FormControlLabel
									value="end"
									control={
										<Checkbox
											disableFocusRipple
											disabled={!isNew && !isEditAdministratorInformation}
											disableRipple
											icon={<CheckBoxIcon />}
											value={1}
											checked={formValues.districtOfColumbia}
											onChange={(e) =>
												handleOnCheckBoxCLick(
													"districtOfColumbia",
													!formValues.districtOfColumbia,
													parseInt(e.target.value)
												)
											}
											checkedIcon={<CheckedBoxIcon />}
										/>
									}
									label="District Of Columbia"
									labelPlacement="end"
								/>
								<FormControlLabel
									value="end"
									control={
										<Checkbox
											disabled={!isNew && !isEditAdministratorInformation}
											disableFocusRipple
											disableRipple
											checked={formValues.newYork}
											value={3}
											onChange={(e) =>
												handleOnCheckBoxCLick(
													"newYork",
													!formValues.newYork,
													parseInt(e.target.value)
												)
											}
											icon={<CheckBoxIcon />}
											checkedIcon={<CheckedBoxIcon />}
										/>
									}
									label="New York"
									labelPlacement="end"
								/>
								<FormControlLabel
									value="end"
									control={
										<Checkbox
											disabled={!isNew && !isEditAdministratorInformation}
											disableFocusRipple
											disableRipple
											checked={formValues.virginia}
											value={4}
											onChange={(e) =>
												handleOnCheckBoxCLick(
													"virginia",
													!formValues.virginia,
													parseInt(e.target.value)
												)
											}
											icon={<CheckBoxIcon />}
											checkedIcon={<CheckedBoxIcon />}
										/>
									}
									label="Virginia"
									labelPlacement="end"
								/>
								<FormControlLabel
									value="end"
									control={
										<Checkbox
											disabled={!isNew && !isEditAdministratorInformation}
											disableFocusRipple
											disableRipple
											checked={formValues.maryland}
											value={2}
											onChange={(e) =>
												handleOnCheckBoxCLick(
													"maryland",
													!formValues.maryland,
													parseInt(e.target.value)
												)
											}
											icon={<CheckBoxIcon />}
											checkedIcon={<CheckedBoxIcon />}
										/>
									}
									label="Maryland"
									labelPlacement="end"
								/>
								<FormHelperText error>
									{errors.regionIds?.message?.toString()}
								</FormHelperText>
							</FormGroup>
						</FormControl>
					</Grid>
				</Grid>
			</div>

			<div className="request-btn-group">
				{contextUser.userRoleId === AccountType.Admin &&
					formValues.physicianId > 0 &&
					providerId && (
						<Tooltip title="Enter Payrate">
							<Button
								variant="contained"
								onClick={() =>
									navigate(
										AppRoutings.ProviderPayrate.replace(
											":providerId",
											providerId
										)
									)
								}
							>
								Enter Payrate
							</Button>
						</Tooltip>
					)}
				{isEditAdministratorInformation ? (
					<>
						<Tooltip title="Save">
							<Button
								onClick={() => {
									if (formValues.mobileNumber === "" || !isPhoneNumberValid) {
										setIsPhoneNumberValid(false);
										handleSubmit(() => {})();
									} else {
										handleSubmit(handleCheckBoxValuesSave, () =>
											console.log(errors)
										)();
									}
								}}
								variant="contained"
							>
								Save
							</Button>
						</Tooltip>
						<Tooltip title="Cancel">
							<Button
								variant="outlined"
								onClick={() => {
									getMyProfile();
									setIsEditAdministratorInformation(false);
								}}
							>
								Cancel
							</Button>
						</Tooltip>
					</>
				) : (
					<>
						{(contextUser.isSuperAdmin ||
							(isAdminProfile && isMyProfile) ||
							!isAdminProfile) &&
							contextUser.userRoleId === AccountType.Admin &&
							!isNew && (
								<Tooltip title="Edit">
									<Button
										variant="contained"
										onClick={() => {
											setIsEditAdministratorInformation(true);
										}}
									>
										Edit
									</Button>
								</Tooltip>
							)}
					</>
				)}
			</div>
		</>
	);
}
