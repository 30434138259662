import { Box, Typography, Button, Tooltip } from "@mui/material";
import myCustomTheme from "app.theme";
import { AddIcon, ArrowBack } from "assests/images";
import { AddEditHaloWorkPlaceModal, HaloWorkPlaceTable } from "components";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import haloService from "services/halo-service";
import { Constants } from "utility/enums/constants";
import { SortOrder } from "utility/enums/sort-order";
import { createCommonAPICall } from "utility/helpers";
import { IHaloHRInfo, ISearchCriteria } from "utility/interfaces";

const HaloWorkPlace: React.FC = () => {
	// Page level variable
	const navigate = useNavigate();
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// State Variables
	const [searchCriteria, setSearchCriteria] = useState<ISearchCriteria>({
		PageIndexId: 0,
		PageSize: Constants.DefaultTablePageSize.valueOf(),
		SortBy: "WorkPlaceId",
		SortOrder: SortOrder.descending,
		SearchBy: "",
	});

	const [haloWorkPlacePageInfo, setHaloWorkPlacePageInfo] =
		useState<IHaloHRInfo>({
			halos: [],
			totalRecords: 0,
		});

	const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);

	// Handle events
	const handleChangeSearchCriteria = (searchCriteria: ISearchCriteria) => {
		setSearchCriteria(searchCriteria);
	};

	const getHaloWorkplaceInfo = async () => {
		const data = await createCommonAPICall({
			requestBody: searchCriteria,
			apiService: haloService.getHaloWorkPlaceGrid,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			console.log(data);
			setHaloWorkPlacePageInfo(data.data);
		}
	};

	const handleAddEditModalOpen = () => setIsAddEditModalOpen(true);

	const handleAddEditModalClose = () => setIsAddEditModalOpen(false);

	// Use effects
	useEffect(() => {
		getHaloWorkplaceInfo();
	}, [searchCriteria]);

	return (
		<Box>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="request-container-box">
					<Box className="tab-item-header">
						<Typography variant="h2">Halo WorkPlace</Typography>
						<Button variant="outlined" onClick={() => navigate(-1)}>
							<img src={ArrowBack} alt="arrow" />
							Back
						</Button>
					</Box>
					<Box className="request-box">
						<div className="attachment">
							<Box
								className="selected-action-btn"
								sx={{ justifyContent: "flex-end", width: "100%" }}
							>
								<Tooltip title="Add Halo WorkPlace">
									<Button
										variant="outlined"
										disableElevation
										disableRipple
										disableFocusRipple
										className="min-w-111px min-w-sm-0"
										onClick={handleAddEditModalOpen}
									>
										<img src={AddIcon} alt="download" />
										<span>Add WorkPlace</span>
									</Button>
								</Tooltip>
							</Box>
						</div>
						<HaloWorkPlaceTable
							haloWorkPlacePageInfo={haloWorkPlacePageInfo}
							searchCriteria={searchCriteria}
							handleOnSearchCriteriaChange={handleChangeSearchCriteria}
							getHaloWorkplaceInfo={getHaloWorkplaceInfo}
						/>
					</Box>
				</Box>
			</main>

			{/* Page modals */}
			<AddEditHaloWorkPlaceModal
				isOpen={isAddEditModalOpen}
				handleClose={handleAddEditModalClose}
				workPlaceData={null}
				getWorkPlaceData={getHaloWorkplaceInfo}
			/>
		</Box>
	);
};

export default HaloWorkPlace;
