import { yupResolver } from "@hookform/resolvers/yup";
import {
	Modal,
	Box,
	Typography,
	IconButton,
	TextField,
	Button,
} from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import haloService from "services/halo-service";
import { commonModalStyle, createCommonAPICall } from "utility/helpers";
import { ICreateUpdateWorkPlaceRequest, IHaloHR } from "utility/interfaces";
import { AddEditWorkPlaceSchema } from "utility/validations";

type ComponentProps = {
	isOpen: boolean;
	handleClose: any;
	workPlaceData: IHaloHR | null;
	getWorkPlaceData: any;
};

export const AddEditHaloWorkPlaceModal = (props: ComponentProps) => {
	// Extract props
	const { isOpen, handleClose, workPlaceData, getWorkPlaceData } = props;

	// Page level variables
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// State variables
	const [formValues, setFormValues] = useState<ICreateUpdateWorkPlaceRequest>({
		workPlaceId: 0,
		workPlaceName: "",
	});

	// Handle events
	const clearFormValues = () => {
		setFormValues({
			workPlaceId: 0,
			workPlaceName: "",
		});
	};

	const handleOnChangeFormValue = (name: string, value: any) => {
		setFormValues({ ...formValues, [name]: value });
	};

	const handleCloseModal = () => {
		clearErrors();
		clearFormValues();
		handleClose();
	};

	const handleSaveWorkPlace = async () => {
		console.log(formValues);
		const data = await createCommonAPICall({
			requestBody: formValues,
			apiService: haloService.createUpdateHaloWorkPlace,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			handleCloseModal();
			getWorkPlaceData();
		}
	};

	// Form validations
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
	} = useForm({
		values: formValues,
		resolver: yupResolver(AddEditWorkPlaceSchema),
	});

	// Use effects
	useEffect(() => {
		if (isOpen && workPlaceData) {
			setFormValues({
				workPlaceId: workPlaceData.workPlaceId,
				workPlaceName: workPlaceData.workPlaceName,
			});
		}
	}, [isOpen]);

	return (
		<>
			<Modal open={isOpen} onClose={handleCloseModal} className="send-modal">
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">
							{workPlaceData ? "Edit" : "Create"}
						</Typography>
						<IconButton onClick={handleCloseModal}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<div>
							<TextField
								{...register("workPlaceName")}
								error={errors.workPlaceName?.message ? true : false}
								helperText={errors.workPlaceName?.message?.toString()}
								name="workPlaceName"
								id="workPlaceName"
								label="Workplace Name"
								fullWidth
								value={formValues.workPlaceName}
								onChange={(e) =>
									handleOnChangeFormValue(e.target.name, e.target.value)
								}
							/>
						</div>
						<div className="modal-footer">
							<Button
								variant="contained"
								onClick={handleSubmit(handleSaveWorkPlace)}
							>
								{workPlaceData ? "Update" : "Create"}
							</Button>
							<Button variant="outlined" onClick={handleCloseModal}>
								Cancel
							</Button>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
};
