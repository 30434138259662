import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Checkbox,
	TableCell,
	Typography,
} from "@mui/material";
import { CheckIcon, UncheckIcon } from "assests/images";
import { ExpandMore } from "components";
import React, { useState } from "react";
import { IShift } from "utility/interfaces";

type ComponentProps = {
	row: IShift;
	selected: boolean;
	index: number;
	handleOnSelectRow: any;
	isSmallDevice: boolean;
};

export function RequestShiftsTableRow(props: ComponentProps) {
	// Extract props
	const { row, selected, index, handleOnSelectRow, isSmallDevice } = props;

	// handle events
	const CheckedboxIcon = () => {
		return <img src={CheckIcon} alt="checkbox" />;
	};
	const CheckboxIcon = () => {
		return <img src={UncheckIcon} alt="checkbox" />;
	};

	return (
		<>
			{!isSmallDevice ? (
				<>
					<TableCell padding="checkbox" align="center">
						<Checkbox
							onClick={() => handleOnSelectRow(row.shiftDetailId)}
							icon={<CheckboxIcon />}
							checkedIcon={<CheckedboxIcon />}
							checked={selected}
							disableRipple
							disableFocusRipple
							inputProps={{
								"aria-labelledby": `enhanced-table-checkbox-${index}`,
							}}
						/>
					</TableCell>
					<TableCell scope="row">
						<div>
							<Box className="display-small-table-label">Staff: &nbsp;</Box>
							<span>{row.physicianName}</span>
						</div>
					</TableCell>
					<TableCell scope="row">
						<div>
							<Box className="display-small-table-label">Day: &nbsp;</Box>
							<span>{row.day}</span>
						</div>
					</TableCell>
					<TableCell scope="row">
						<div>
							<Box className="display-small-table-label">Staff: &nbsp;</Box>
							<span>
								{row.startFull} - {row.endFull}
							</span>
						</div>
					</TableCell>
					<TableCell scope="row">
						<div>
							<Box className="display-small-table-label">Region: &nbsp;</Box>
							<span>{row.region}</span>
						</div>
					</TableCell>
				</>
			) : (
				<Card className="bg-white">
					<div className="card-container">
						<CardHeader
							title={
								<React.Fragment>
									<Checkbox
										onClick={() => handleOnSelectRow(row.shiftDetailId)}
										icon={<CheckboxIcon />}
										checkedIcon={<CheckedboxIcon />}
										checked={selected}
										disableRipple
										disableFocusRipple
										inputProps={{
											"aria-labelledby": `enhanced-table-checkbox-${index}`,
										}}
									/>
									<div>
										<span>{row.physicianName}</span>
									</div>
								</React.Fragment>
							}
						/>
						<CardContent className="card-header-content">
							<div className="card-subheader">
								<Typography variant="body2" color="text.secondary">
									{row.day}
								</Typography>
							</div>
							<div className="card-subheader">
								<Typography variant="body2" color="text.secondary">
									{row.startFull} - {row.endFull}
								</Typography>
							</div>
							<div className="card-subheader">
								<Typography variant="body2" color="text.secondary">
									{row.region}
								</Typography>
							</div>
						</CardContent>
					</div>
				</Card>
			)}
		</>
	);
}
