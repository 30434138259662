import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Collapse,
	TableCell,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import { CrossRedIcon, LocationIcon } from "assests/images";
import { ExpandMore, WarningModal } from "components";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import React, { useContext, useState } from "react";
import haloService from "services/halo-service";
import { createCommonAPICall } from "utility/helpers";
import { IHaloHR } from "utility/interfaces";

type ComponentProps = {
	row: IHaloHR;
	isSmallDevice: boolean;
	handleClickEdit: any;
	getHaloHRInfo: any;
};

export const HaloHRTableRow = (props: ComponentProps) => {
	// Page level local variables
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// Extract props
	const { row, isSmallDevice, handleClickEdit, getHaloHRInfo } = props;

	// Use state
	const [expanded, setExpanded] = useState(false);
	const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);

	// handle events
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const handleOnClickDelete = async () => {
		const requestBody = {
			administratorId: row.administratorId,
		};

		const data = await createCommonAPICall({
			requestBody,
			apiService: haloService.deleteHaloAdministrator,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});

		getHaloHRInfo();
	};

	return (
		<>
			{!isSmallDevice ? (
				<TableRow>
					<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
						{row.username}
					</TableCell>
					<TableCell sx={{ maxWidth: "180px" }} className="min-w-180px">
						{row.email}
					</TableCell>
					<TableCell className="min-w-200px">{row.workPlaceName}</TableCell>
					<TableCell>
						<Tooltip title="Edit Halo HR">
							<Button
								id="table-button-edit"
								variant="outlined"
								className="action-btn-primary action-btn"
								style={{ marginRight: 4 }}
								onClick={() => handleClickEdit(row)}
							>
								Edit
							</Button>
						</Tooltip>
						<Tooltip title="Delete Halo HR">
							<Button
								id="table-button-edit"
								variant="outlined"
								className="action-btn-primary action-btn"
								style={{ marginRight: 4 }}
								onClick={() => setIsDeleteWarningOpen(true)}
							>
								Delete
							</Button>
						</Tooltip>
					</TableCell>
				</TableRow>
			) : (
				// Mobile View
				<Card className="bg-white">
					<div className="card-container">
						<ExpandMore
							expand={expanded}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label="show more"
						>
							<CardHeader
								title={
									<React.Fragment>
										<span>{row.username}</span>
									</React.Fragment>
								}
							/>
							<CardContent className="card-header-content">
								<div className="card-subheader">
									<Typography variant="body2" color="text.secondary">
										{row.email}
									</Typography>
								</div>
							</CardContent>
						</ExpandMore>
						<Collapse in={expanded} timeout="auto" unmountOnExit>
							<CardContent>
								<div>
									<span>
										<img src={LocationIcon} alt="calender" />
									</span>
									<Typography variant="h6">
										Work Place Name:&nbsp;
										<span>{row.workPlaceName}</span>
									</Typography>
								</div>

								<div className="table-cell-btn-group">
									<Button
										variant="outlined"
										onClick={() => handleClickEdit(row)}
									>
										Edit
									</Button>
									<Button
										variant="outlined"
										onClick={() => setIsDeleteWarningOpen(true)}
									>
										Delete
									</Button>
								</div>
							</CardContent>
						</Collapse>
					</div>
				</Card>
			)}

			{/* Page modal */}
			<WarningModal
				isModalOpen={isDeleteWarningOpen}
				title="Confirmation"
				warningMessage="Are you sure you want to delete this HR?"
				okButtonText="Confirm"
				closeButtonText="Cancel"
				handleOnClickCloseModal={() => setIsDeleteWarningOpen(false)}
				handleOnClickOk={handleOnClickDelete}
				warningIcon={CrossRedIcon}
			/>
		</>
	);
};
