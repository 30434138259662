import * as yup from "yup";

export const EditHaloHRSchema = yup.object().shape({
	username: yup
		.string()
		.required("To Create Username please enter firstname and lastname"),
	firstName: yup.string().required("Firstname is required."),
	lastName: yup.string().required("Lastname is required."),
	workPlaceName: yup.string().required("Work place name is required."),
	email: yup
		.string()
		.email("Please enter valid email")
		.required("Email is required."),
});

export const AddHaloHRSchema = yup.object().shape({
	username: yup
		.string()
		.required("To Create Username please enter firstname and lastname"),
	firstName: yup.string().required("Firstname is required."),
	lastName: yup.string().required("Lastname is required."),
	password: yup.string().required("Password is required."),
	workPlaceName: yup.string().required("Work place name is required."),
	email: yup
		.string()
		.email("Please enter valid email")
		.required("Email is required."),
});

export const AddEditHaloEmployeeSchema = yup.object().shape({
	firstName: yup.string().required("First name is required."),
	lastName: yup.string().required("Last name is required."),
	email: yup
		.string()
		.email("Please enter valid email.")
		.required("Email is required."),
	isIndividual: yup.boolean(),
	workPlaceId: yup
		.number()
		.test(
			"Work place validation",
			"Please select work place.",
			(value, context) => {
				if (value != undefined) {
					let isIndividual = context.parent.isIndividual;
					if (!isIndividual) {
						return value > 0;
					}
					return true;
				}
				return false;
			}
		),
});

export const AddEditWorkPlaceSchema = yup.object().shape({
	workPlaceName: yup.string().required("Work Place name is required."),
});
