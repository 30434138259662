import { yupResolver } from "@hookform/resolvers/yup";
import {
	Box,
	Button,
	IconButton,
	Modal,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import React, { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { commonModalStyle, createCommonAPICall } from "utility/helpers";
import { sendEmailSchema } from "utility/validations";
import { CustomCopyTooltip } from "components";
import { IRequest } from "utility/interfaces";
import { Constants } from "utility/enums/constants";
import { ISendDefaultMessage } from "utility/interfaces/dashboard/send-busy-notification";

type ComponentProps = {
	isSendDefaultMessageModalOpen: boolean;
	SendDefaultMessageModalClose: any;
	handleOnClickSend: any;
	row: IRequest;
};

export function SendDefaultMessageModal(props: ComponentProps) {
	// Usestates
	const [message, setMessage] = useState(
		Constants.ProviderBusyDefaultMessage.toString()
	);

	// Extract Props
	const {
		isSendDefaultMessageModalOpen,
		SendDefaultMessageModalClose,
		handleOnClickSend,
		row,
	} = props;

	// Yup resolver
	const {
		register,
		handleSubmit,
		reset,
		clearErrors,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(sendEmailSchema),
	});

	const handleSendMessage = () => {
		const request: ISendDefaultMessage = {
			message: message,
			email: row.email,
			phonenumber: row.phone,
		};
		handleOnClickSend(request);
	};

	// useEffects
	useEffect(() => {
		reset();
		clearErrors();
		setMessage(Constants.ProviderBusyDefaultMessage.toString());
	}, [isSendDefaultMessageModalOpen]);

	return (
		<>
			<Modal
				open={isSendDefaultMessageModalOpen}
				onClose={SendDefaultMessageModalClose}
				className="send-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">Send Busy Message</Typography>
						<IconButton onClick={SendDefaultMessageModalClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<div>
							<Typography variant="h6" className="patient-name">
								<Box className="min-w-125 min-w-sm-110px">
									Patient Name :&nbsp;
								</Box>
								<Box className="name">
									<span>{row.name}</span>
									<CustomCopyTooltip
										canDoCopy={true}
										text="Copy"
										copyText={row.email}
									>
										<span className="email-wrapper">
											&#40;<span>{row.email}</span>&#41;
										</span>
									</CustomCopyTooltip>
									<CustomCopyTooltip
										canDoCopy={true}
										text="Copy"
										copyText={row.phone}
									>
										<span className="email-wrapper">
											&#40;<span>{row.phone}</span>&#41;
										</span>
									</CustomCopyTooltip>
								</Box>
							</Typography>
							<TextField
								{...register("message")}
								id="block"
								label="Message"
								value={message}
								onChange={(e) => setMessage(e.target.value)}
								multiline
								maxRows={1}
								inputProps={{ className: "min-h-88px" }}
								fullWidth
								error={errors?.message?.message ? true : false}
								helperText={errors?.message?.message?.toString()}
							/>
						</div>
						<div className="modal-footer">
							<Tooltip title="Confirm">
								<Button
									variant="contained"
									onClick={handleSubmit(handleSendMessage)}
								>
									Send
								</Button>
							</Tooltip>
							<Tooltip title="Cancel">
								<Button
									variant="outlined"
									onClick={SendDefaultMessageModalClose}
								>
									Cancel
								</Button>
							</Tooltip>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
