import { Box, Button, Modal, Tooltip, Typography } from "@mui/material";
import { ArrowBackWhite, PasswordReset } from "assests/images";
import React from "react";
import { successErrorModalStyle } from "utility/helpers";

type ComponentProps = {
	handleClickBackToLogin: any;
	isResetPwdopen: boolean;
	handleOutsideClick: any;
};

export function ResetPasswordModal(props: ComponentProps) {
	// Extract Props
	const { handleClickBackToLogin, handleOutsideClick, isResetPwdopen } = props;

	return (
		<>
			<Modal
				open={isResetPwdopen}
				onClose={handleOutsideClick}
				className="reset-modal"
			>
				<Box sx={successErrorModalStyle}>
					<img src={PasswordReset} alt="Done" className="reset-img" />
					<Typography variant="h2">Forgot Password Confirmation</Typography>
					<Typography variant="body1">
						We've sent an email to you to reset your password. Please follow the
						instructions in it.
					</Typography>
					<Tooltip title="Back to Login">
						<Button
							focusRipple
							tabIndex={0}
							onClick={handleClickBackToLogin}
							variant="contained"
						>
							<img src={ArrowBackWhite} alt="arrow" />
							Back to Login
						</Button>
					</Tooltip>
				</Box>
			</Modal>
		</>
	);
}
