import { Box, Typography, Button, Tooltip } from "@mui/material";
import myCustomTheme from "app.theme";
import { AddIcon, ArrowBack } from "assests/images";
import { AddEditHaloEmployeeModal, HaloEmployeeTable } from "components";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import haloService from "services/halo-service";
import { Constants } from "utility/enums/constants";
import { SortOrder } from "utility/enums/sort-order";
import { createCommonAPICall } from "utility/helpers";
import { IHaloEmployeesInfo, ISearchCriteria } from "utility/interfaces";

const HaloEmployee: React.FC = () => {
	// Page level variable
	const navigate = useNavigate();
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// State Variables
	const [searchCriteria, setSearchCriteria] = useState<ISearchCriteria>({
		PageIndexId: 0,
		PageSize: Constants.DefaultTablePageSize.valueOf(),
		SortBy: "CreatedDate",
		SortOrder: SortOrder.descending,
		SearchBy: "",
	});

	const [haloEmployeePageInfo, setHaloEmployeePageInfo] =
		useState<IHaloEmployeesInfo>({
			employees: [],
			totalRecords: 0,
		});

	const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);

	// Handle events
	const getHaloEmployeesInfo = async () => {
		const data = await createCommonAPICall({
			requestBody: searchCriteria,
			apiService: haloService.getHaloEmployeeGrid,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			setHaloEmployeePageInfo(data.data);
		}
	};

	const handleOnSearchCriteriaChange = (searchCriteria: ISearchCriteria) => {
		setSearchCriteria(searchCriteria);
	};

	const handleAddEditModalOpen = () => setIsAddEditModalOpen(true);

	const handleAddEditModalClose = () => setIsAddEditModalOpen(false);

	// Use effects
	useEffect(() => {
		getHaloEmployeesInfo();
	}, [searchCriteria]);

	return (
		<Box>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="request-container-box">
					<Box className="tab-item-header">
						<Typography variant="h2">Halo Employees</Typography>
						<Button variant="outlined" onClick={() => navigate(-1)}>
							<img src={ArrowBack} alt="arrow" />
							Back
						</Button>
					</Box>
					<Box className="request-box">
						<div className="attachment">
							<Box
								className="selected-action-btn"
								sx={{ justifyContent: "flex-end", width: "100%" }}
							>
								<Tooltip title="Add Halo Employee">
									<Button
										variant="outlined"
										disableElevation
										disableRipple
										disableFocusRipple
										className="min-w-111px min-w-sm-0"
										onClick={handleAddEditModalOpen}
									>
										<img src={AddIcon} alt="download" />
										<span>Add Employee</span>
									</Button>
								</Tooltip>
							</Box>
						</div>
						<HaloEmployeeTable
							haloEmployeePageInfo={haloEmployeePageInfo}
							searchCriteria={searchCriteria}
							handleChangeSearchCriteria={handleOnSearchCriteriaChange}
							getHaloEmpData={getHaloEmployeesInfo}
						/>
					</Box>
				</Box>
			</main>

			{/* Page Modals */}
			<AddEditHaloEmployeeModal
				isOpen={isAddEditModalOpen}
				handleClose={handleAddEditModalClose}
				haloEmpData={null}
				getHaloEmpData={getHaloEmployeesInfo}
			/>
		</Box>
	);
};

export default HaloEmployee;
