import React, { useEffect } from "react";
import {
	Box,
	Button,
	FormControl,
	Grid,
	Hidden,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	TextField,
	Typography,
} from "@mui/material";
import { ArrowBack, DollarIcon } from "assests/images";
import { useNavigate, useParams } from "react-router-dom";
import { ItemizedInvoiceModal } from "components";
import { AppRoutings } from "utility/enums/app-routings";
import { IItemizedCost } from "utility/interfaces";
import { getTabStatusWiseName } from "utility/helpers";

const ItemizedCosts: React.FC = () => {
	// get query parameters
	const { caseId } = useParams();

	// get state value
	const { state } = window.history;

	// useStates
	const [invoiceModalOpen, setInvoiceModalOpen] = React.useState(false);
	const [formValues, setFormValues] = React.useState<IItemizedCost>({
		houseCallCost: 0,
		travelCost: 0,
		weekendCost: 0,
		afterHoursCost: 0,
		holidayCost: 0,
		medicationCost: 0,
		suppliesCost: 0,
		proceduresCost: 0,
		diagnosticsCost: 0,
		otherCost: 0,
		houseCallDescription: "",
		travelDescription: "",
		weekendDescription: "",
		afterHoursDescription: "",
		holidayDescription: "",
		medicationDescription: "",
		suppliesDescription: "",
		proceduresDescription: "",
		diagnosticsDescription: "",
		otherDescription: "",
	});

	// Handled events and functions
	const handleOnClickBackCancel = () =>
		navigate(AppRoutings.CloseCase.replace(":caseId", caseId ? caseId : ""), {
			state: {
				searchCriteria: state?.usr?.searchCriteria,
				backURL: AppRoutings.Dashboard.replace(
					":tabStatus",
					getTabStatusWiseName(
						state?.usr?.searchCriteria?.RequestStatusId
					).toLocaleLowerCase()
				),
			},
		});

	const handleOnFormValueChange = (e: any) => {
		setFormValues({ ...formValues, [e.target.name]: e.target.value });
	};
	const getCostDetails = () => {
		// TODO API call here
		console.log(caseId);
	};
	const onInvoiceModalClose = () => {
		setInvoiceModalOpen(false);
	};
	const onInvoiceModalOpen = () => {
		setInvoiceModalOpen(true);
	};

	// Page level variables
	const navigate = useNavigate();

	// useEffects
	useEffect(() => {
		if (caseId) getCostDetails();
	}, []);

	return (
		<>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="request-container-box">
					<div className="request-header">
						<Typography variant="h2">Itemized Costs</Typography>
						<Button variant="outlined" onClick={handleOnClickBackCancel}>
							<img src={ArrowBack} alt="arrow" />
							Back
						</Button>
					</div>
					<Box className="request-box">
						<div>
							<Typography variant="h1" className="total-cost">
								$
								{+formValues.houseCallCost +
									+formValues.travelCost +
									+formValues.weekendCost +
									+formValues.afterHoursCost +
									+formValues.holidayCost +
									+formValues.medicationCost +
									+formValues.suppliesCost +
									+formValues.proceduresCost +
									+formValues.diagnosticsCost +
									+formValues.otherCost}
							</Typography>
							<Typography variant="h6" className="total-cost-label">
								Total Cost of Care
							</Typography>
							<Grid
								container
								maxWidth="768px"
								mx="auto"
								rowSpacing={{ xs: "30px", md: "10px" }}
							>
								<Grid item xs={12}>
									<Grid
										container
										className="itemized-subdata-container"
										columnSpacing="10px"
										rowSpacing="14px"
										alignItems="center"
									>
										<Grid item xs={4} sm={2}>
											<Typography variant="body1">House Call</Typography>
										</Grid>
										<Grid item xs={8} sm={10} md={3}>
											<FormControl variant="outlined" fullWidth>
												<InputLabel htmlFor="house-call">Enter Cost</InputLabel>
												<OutlinedInput
													label="Enter Cost"
													type="number"
													id="house-call"
													name="houseCallCost"
													value={formValues.houseCallCost}
													onChange={handleOnFormValueChange}
													endAdornment={
														<InputAdornment position="end">
															<img src={DollarIcon} alt="dollar" />
														</InputAdornment>
													}
												/>
											</FormControl>
										</Grid>
										<Hidden smDown mdUp>
											<Grid item xs={12} sm={2}></Grid>
										</Hidden>
										<Grid item xs={12} sm={10} md={7}>
											<TextField
												id="house-call-desc"
												label="Description"
												variant="outlined"
												name="houseCallDescription"
												value={formValues.houseCallDescription}
												onChange={handleOnFormValueChange}
												fullWidth
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid
										container
										className="itemized-subdata-container"
										columnSpacing="10px"
										rowSpacing="14px"
										alignItems="center"
									>
										<Grid item xs={4} sm={2}>
											<Typography variant="body1">Travel</Typography>
										</Grid>
										<Grid item xs={8} sm={10} md={3}>
											<FormControl variant="outlined" fullWidth>
												<InputLabel htmlFor="travel">Enter Cost</InputLabel>
												<OutlinedInput
													label="Enter Cost"
													type="number"
													id="travel"
													name="travelCost"
													value={formValues.travelCost}
													onChange={handleOnFormValueChange}
													endAdornment={
														<InputAdornment position="end">
															<img src={DollarIcon} alt="dollar" />
														</InputAdornment>
													}
												/>
											</FormControl>
										</Grid>
										<Hidden smDown mdUp>
											<Grid item xs={12} sm={2}></Grid>
										</Hidden>
										<Grid item xs={12} sm={10} md={7}>
											<TextField
												id="travel-desc"
												label="Description"
												variant="outlined"
												name="travelDescription"
												value={formValues.travelDescription}
												onChange={handleOnFormValueChange}
												fullWidth
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid
										container
										className="itemized-subdata-container"
										columnSpacing="10px"
										rowSpacing="14px"
										alignItems="center"
									>
										<Grid item xs={4} sm={2}>
											<Typography variant="body1">After Hours</Typography>
										</Grid>
										<Grid item xs={8} sm={10} md={3}>
											<FormControl variant="outlined" fullWidth>
												<InputLabel htmlFor="after-hrs">Enter Cost</InputLabel>
												<OutlinedInput
													label="Enter Cost"
													type="number"
													id="after-hrs"
													name="afterHoursCost"
													value={formValues.afterHoursCost}
													onChange={handleOnFormValueChange}
													endAdornment={
														<InputAdornment position="end">
															<img src={DollarIcon} alt="dollar" />
														</InputAdornment>
													}
												/>
											</FormControl>
										</Grid>
										<Hidden smDown mdUp>
											<Grid item xs={12} sm={2}></Grid>
										</Hidden>
										<Grid item xs={12} sm={10} md={7}>
											<TextField
												name="afterHoursDescription"
												value={formValues.afterHoursDescription}
												onChange={handleOnFormValueChange}
												id="after-hrs-desc"
												label="Description"
												variant="outlined"
												fullWidth
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid
										container
										className="itemized-subdata-container"
										columnSpacing="10px"
										rowSpacing="14px"
										alignItems="center"
									>
										<Grid item xs={4} sm={2}>
											<Typography variant="body1">Weekend</Typography>
										</Grid>
										<Grid item xs={8} sm={10} md={3}>
											<FormControl variant="outlined" fullWidth>
												<InputLabel htmlFor="weekend">Enter Cost</InputLabel>
												<OutlinedInput
													label="Enter Cost"
													type="number"
													id="weekend"
													name="weekendCost"
													value={formValues.weekendCost}
													onChange={handleOnFormValueChange}
													endAdornment={
														<InputAdornment position="end">
															<img src={DollarIcon} alt="dollar" />
														</InputAdornment>
													}
												/>
											</FormControl>
										</Grid>
										<Hidden smDown mdUp>
											<Grid item xs={12} sm={2}></Grid>
										</Hidden>
										<Grid item xs={12} sm={10} md={7}>
											<TextField
												name="weekendDescription"
												value={formValues.weekendDescription}
												onChange={handleOnFormValueChange}
												id="weekend-desc"
												label="Description"
												variant="outlined"
												fullWidth
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid
										container
										className="itemized-subdata-container"
										columnSpacing="10px"
										rowSpacing="14px"
										alignItems="center"
									>
										<Grid item xs={4} sm={2}>
											<Typography variant="body1">Holiday</Typography>
										</Grid>
										<Grid item xs={8} sm={10} md={3}>
											<FormControl variant="outlined" fullWidth>
												<InputLabel htmlFor="holiday">Enter Cost</InputLabel>
												<OutlinedInput
													label="Enter Cost"
													type="number"
													id="holiday"
													name="holidayCost"
													value={formValues.holidayCost}
													onChange={handleOnFormValueChange}
													endAdornment={
														<InputAdornment position="end">
															<img src={DollarIcon} alt="dollar" />
														</InputAdornment>
													}
												/>
											</FormControl>
										</Grid>
										<Hidden smDown mdUp>
											<Grid item xs={12} sm={2}></Grid>
										</Hidden>
										<Grid item xs={12} sm={10} md={7}>
											<TextField
												name="holidayDescription"
												value={formValues.holidayDescription}
												onChange={handleOnFormValueChange}
												id="holiday-desc"
												label="Description"
												variant="outlined"
												fullWidth
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid
										container
										className="itemized-subdata-container"
										columnSpacing="10px"
										rowSpacing="14px"
										alignItems="center"
									>
										<Grid item xs={4} sm={2}>
											<Typography variant="body1">Medication</Typography>
										</Grid>
										<Grid item xs={8} sm={10} md={3}>
											<FormControl variant="outlined" fullWidth>
												<InputLabel htmlFor="medication">Enter Cost</InputLabel>
												<OutlinedInput
													label="Enter Cost"
													type="number"
													id="medication"
													name="medicationCost"
													value={formValues.medicationCost}
													onChange={handleOnFormValueChange}
													endAdornment={
														<InputAdornment position="end">
															<img src={DollarIcon} alt="dollar" />
														</InputAdornment>
													}
												/>
											</FormControl>
										</Grid>
										<Hidden smDown mdUp>
											<Grid item xs={12} sm={2}></Grid>
										</Hidden>
										<Grid item xs={12} sm={10} md={7}>
											<TextField
												name="medicationDescription"
												value={formValues.medicationDescription}
												onChange={handleOnFormValueChange}
												id="medication-desc"
												label="Description"
												variant="outlined"
												fullWidth
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid
										container
										className="itemized-subdata-container"
										columnSpacing="10px"
										rowSpacing="14px"
										alignItems="center"
									>
										<Grid item xs={4} sm={2}>
											<Typography variant="body1">Supplies</Typography>
										</Grid>
										<Grid item xs={8} sm={10} md={3}>
											<FormControl variant="outlined" fullWidth>
												<InputLabel htmlFor="supplies">Enter Cost</InputLabel>
												<OutlinedInput
													label="Enter Cost"
													type="number"
													id="supplies"
													name="suppliesCost"
													value={formValues.suppliesCost}
													onChange={handleOnFormValueChange}
													endAdornment={
														<InputAdornment position="end">
															<img src={DollarIcon} alt="dollar" />
														</InputAdornment>
													}
												/>
											</FormControl>
										</Grid>
										<Hidden smDown mdUp>
											<Grid item xs={12} sm={2}></Grid>
										</Hidden>
										<Grid item xs={12} sm={10} md={7}>
											<TextField
												name="suppliesDescription"
												value={formValues.suppliesDescription}
												onChange={handleOnFormValueChange}
												id="supplies-desc"
												label="Description"
												variant="outlined"
												fullWidth
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid
										container
										className="itemized-subdata-container"
										columnSpacing="10px"
										rowSpacing="14px"
										alignItems="center"
									>
										<Grid item xs={4} sm={2}>
											<Typography variant="body1">Procedures</Typography>
										</Grid>
										<Grid item xs={8} sm={10} md={3}>
											<FormControl variant="outlined" fullWidth>
												<InputLabel htmlFor="procedure">Enter Cost</InputLabel>
												<OutlinedInput
													name="proceduresCost"
													value={formValues.proceduresCost}
													onChange={handleOnFormValueChange}
													label="Enter Cost"
													type="number"
													id="procedure"
													endAdornment={
														<InputAdornment position="end">
															<img src={DollarIcon} alt="dollar" />
														</InputAdornment>
													}
												/>
											</FormControl>
										</Grid>
										<Hidden smDown mdUp>
											<Grid item xs={12} sm={2}></Grid>
										</Hidden>
										<Grid item xs={12} sm={10} md={7}>
											<TextField
												name="proceduresDescription"
												value={formValues.proceduresDescription}
												onChange={handleOnFormValueChange}
												id="procedure-desc"
												label="Description"
												variant="outlined"
												fullWidth
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid
										container
										className="itemized-subdata-container"
										columnSpacing="10px"
										rowSpacing="14px"
										alignItems="center"
									>
										<Grid item xs={4} sm={2}>
											<Typography variant="body1">Diagnostics</Typography>
										</Grid>
										<Grid item xs={8} sm={10} md={3}>
											<FormControl variant="outlined" fullWidth>
												<InputLabel htmlFor="diagnostics">
													Enter Cost
												</InputLabel>
												<OutlinedInput
													name="diagnosticsCost"
													value={formValues.diagnosticsCost}
													onChange={handleOnFormValueChange}
													label="Enter Cost"
													type="number"
													id="diagnostics"
													endAdornment={
														<InputAdornment position="end">
															<img src={DollarIcon} alt="dollar" />
														</InputAdornment>
													}
												/>
											</FormControl>
										</Grid>
										<Hidden smDown mdUp>
											<Grid item xs={12} sm={2}></Grid>
										</Hidden>
										<Grid item xs={12} sm={10} md={7}>
											<TextField
												name="diagnosticsDescription"
												value={formValues.diagnosticsDescription}
												onChange={handleOnFormValueChange}
												id="diagnostics-desc"
												label="Description"
												variant="outlined"
												fullWidth
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid
										container
										className="itemized-subdata-container"
										columnSpacing="10px"
										rowSpacing="14px"
										alignItems="center"
									>
										<Grid item xs={4} sm={2}>
											<Typography variant="body1">Other</Typography>
										</Grid>
										<Grid item xs={8} sm={10} md={3}>
											<FormControl variant="outlined" fullWidth>
												<InputLabel htmlFor="other">Enter Cost</InputLabel>
												<OutlinedInput
													name="otherCost"
													value={formValues.otherCost}
													onChange={handleOnFormValueChange}
													label="Enter Cost"
													type="number"
													id="other"
													endAdornment={
														<InputAdornment position="end">
															<img src={DollarIcon} alt="dollar" />
														</InputAdornment>
													}
												/>
											</FormControl>
										</Grid>
										<Hidden smDown mdUp>
											<Grid item xs={12} sm={2}></Grid>
										</Hidden>
										<Grid item xs={12} sm={10} md={7}>
											<TextField
												name="otherDescription"
												value={formValues.otherDescription}
												onChange={handleOnFormValueChange}
												id="other-desc"
												label="Description"
												variant="outlined"
												fullWidth
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</div>
						<div className="request-btn-group itemized-btn-group">
							<Button variant="contained" onClick={onInvoiceModalOpen}>
								Submit
							</Button>
							<Button variant="outlined" onClick={handleOnClickBackCancel}>
								Cancel
							</Button>
						</div>
					</Box>
				</Box>
			</main>
			{/* page level modals  */}
			<ItemizedInvoiceModal
				invoiceModalOpen={invoiceModalOpen}
				onInvoiceModalClose={onInvoiceModalClose}
				invoiceData={formValues}
			/>
		</>
	);
};

export default ItemizedCosts;
