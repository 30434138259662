import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
} from "@mui/material";
import {
	IRole,
	IRoleInfo,
	IHttpsResponse,
	ISearchCriteria,
} from "utility/interfaces";
import { visuallyHidden } from "@mui/utils";
import { SortOrder } from "utility/enums/sort-order";
import { Constants } from "utility/enums/constants";
import roleService from "services/role-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { createCommonAPICall } from "utility/helpers";
import { RoleTableRow, TablePagination } from "components";

export function RoleTable() {
	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// useStates
	const [searchCriteria, setSearchCriteria] = useState<ISearchCriteria>({
		PageIndexId: 0,
		PageSize: Constants.DefaultTablePageSize.valueOf(),
		SortOrder: SortOrder.descending,
		SortBy: "",
		SearchBy: "",
	});
	const [rolePageInfo, setRolePageInfo] = React.useState<IRoleInfo>({
		roles: [],
		totalRecords: 0,
	});

	// Handled events and functions
	const handleOnPageNumberChange = (value: number) => {
		setSearchCriteria({ ...searchCriteria, PageIndexId: value });
	};
	const handleOnChangeSortDirection = (sortBy: string) => {
		setSearchCriteria({
			...searchCriteria,
			SortBy: sortBy,
			SortOrder:
				searchCriteria.SortOrder === SortOrder.ascending
					? SortOrder.descending
					: SortOrder.ascending,
		});
	};
	const getAllRoles = async () => {
		const data = await createCommonAPICall({
			requestBody: searchCriteria,
			apiService: roleService.getGridRoles,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.data) setRolePageInfo(data.data);
	};
	const handleOnClickDelete = async (row: IRole) => {
		const data: IHttpsResponse<any> = await createCommonAPICall({
			requestBody: row.roleId || 0,
			apiService: roleService.deleteRole,
			showSuccessMessage: true,
			showErrorMessage: true,
			setSuccessErrorContext,
			successMessage: "Record deleted successfully",
		});
		if (data && data.isSuccessfull) {
			const updatedRoles = rolePageInfo.roles.filter(
				(role) => role.roleId !== row.roleId
			);
			setRolePageInfo({
				...rolePageInfo,
				roles: updatedRoles,
				totalRecords: rolePageInfo.totalRecords - 1,
			});
		}
	};

	// useEffects
	useEffect(() => {
		getAllRoles();
	}, [searchCriteria]);

	return (
		<>
			<TableContainer className="upload-table-container">
				<Table className="upload-table conclude-table">
					<TableHead>
						<TableRow>
							<TableCell
								sortDirection={searchCriteria.SortOrder}
								className="min-w-200"
							>
								<TableSortLabel
									active={searchCriteria.SortBy === "name"}
									direction={searchCriteria.SortOrder}
									onClick={() => handleOnChangeSortDirection("name")}
								>
									Name
									<Box component="span" sx={visuallyHidden}>
										{searchCriteria.SortOrder === SortOrder.descending
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								</TableSortLabel>
							</TableCell>
							<TableCell
								sortDirection={searchCriteria.SortOrder}
								className="min-w-200"
							>
								<TableSortLabel
									active={searchCriteria.SortBy === "accountType"}
									direction={searchCriteria.SortOrder}
									onClick={() => handleOnChangeSortDirection("accountType")}
								>
									Account Type
									<Box component="span" sx={visuallyHidden}>
										{searchCriteria.SortOrder === SortOrder.descending
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								</TableSortLabel>
							</TableCell>
							<TableCell align="center" sx={{ width: 142 }}>
								Actions
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rolePageInfo.roles.map((row, index) => {
							return (
								<TableRow hover tabIndex={-1} key={index}>
									<RoleTableRow
										row={row}
										handleOnClickDelete={handleOnClickDelete}
									/>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<div className="small-table-pagination">
				<TablePagination
					currentPageNumber={searchCriteria.PageIndexId}
					handleOnPageNumberChange={handleOnPageNumberChange}
					totalRecords={rolePageInfo?.totalRecords || 0}
					rowCount={rolePageInfo?.roles?.length || 0}
				/>
			</div>
		</>
	);
}
