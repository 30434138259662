import {
	Box,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { ArrowBack } from "assests/images";
import { PayrateTableRow } from "components";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import roleService from "services/role-service";
import { createCommonAPICall } from "utility/helpers";
import { IPayrateInfo } from "utility/interfaces";

const ProviderPayrate: React.FC = () => {
	//Page level local variable
	const navigate = useNavigate();
	const { providerId } = useParams();

	// Context variables
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// State variable
	const [payrateInfo, setPayrateInfo] = useState<IPayrateInfo[]>([]);

	//Handle event
	const handleOnClickBackCancel = () => navigate(-1);

	const getPayRates = async () => {
		const requestBody = {
			Id: providerId,
		};

		const data = await createCommonAPICall({
			requestBody,
			apiService: roleService.getProviderPayrate,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			console.log(data);
			setPayrateInfo(data.data);
		}
	};

	// Use effects
	useEffect(() => {
		getPayRates();
	}, []);

	return (
		<>
			<Box>
				<main className="main-content">
					<div
						className="overlay"
						onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
					></div>
					<Box className="request-container-box">
						<div className="request-header">
							<Typography variant="h2">Payrate</Typography>
							<Button variant="outlined" onClick={handleOnClickBackCancel}>
								<img src={ArrowBack} alt="arrow" />
								Back
							</Button>
						</div>
						<Box className="request-box">
							<TableContainer
								className="upload-table-container"
								sx={{ display: { xs: "none", sm: "block" } }}
							>
								<Table className="payrate-table">
									<TableHead>
										<TableRow>
											<TableCell align="center">Category</TableCell>
											<TableCell align="center">Payrate</TableCell>
											<TableCell>Action</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{payrateInfo.map((row, index) => (
											<TableRow key={index}>
												<PayrateTableRow
													row={row}
													key={index}
													getPayrates={getPayRates}
													isSmallDevice={false}
												/>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
						<Box
							sx={{ display: { xs: "block", sm: "none" } }}
							className="tabledata-cards-group"
						>
							{payrateInfo.map((row, index) => (
								<PayrateTableRow
									row={row}
									getPayrates={getPayRates}
									isSmallDevice={true}
									key={index}
								/>
							))}
						</Box>
					</Box>
				</main>
			</Box>
		</>
	);
};

export default ProviderPayrate;
