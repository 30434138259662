import {
	TableCell,
	Checkbox,
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Typography,
} from "@mui/material";
import { CheckIcon, UncheckIcon } from "assests/images";
import moment from "moment";
import React from "react";
import { IProviderDropdownList } from "utility/interfaces";

type ComponentProps = {
	row: IProviderDropdownList;
	selected: boolean;
	index: number;
	handleOnSelectRow: any;
	handleViewAllBtnClick: any;
	isSmallDevice: boolean;
};

export function CurrentMonthShiftsTableRow(props: ComponentProps) {
	// Extract props
	const {
		row,
		selected,
		index,
		handleOnSelectRow,
		handleViewAllBtnClick,
		isSmallDevice,
	} = props;

	// Page level variables
	const today = new Date();
	const monthFirstDay = new Date(today.getFullYear(), today.getMonth(), 1);
	const monthLastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

	const CheckedboxIcon = () => {
		return <img src={CheckIcon} alt="checkbox" />;
	};
	const CheckboxIcon = () => {
		return <img src={UncheckIcon} alt="checkbox" />;
	};

	return (
		<>
			{!isSmallDevice ? (
				<>
					<TableCell padding="checkbox" align="center">
						<Checkbox
							onClick={() => handleOnSelectRow(row.physicianId)}
							icon={<CheckboxIcon />}
							checkedIcon={<CheckedboxIcon />}
							checked={selected}
							disableRipple
							disableFocusRipple
							inputProps={{
								"aria-labelledby": `enhanced-table-checkbox-${index}`,
							}}
						/>
					</TableCell>
					<TableCell scope="row">
						<div>
							<Box className="display-small-table-label">Staff: &nbsp;</Box>
							<span>{row.name}</span>
						</div>
					</TableCell>
					<TableCell scope="row">
						<div>
							<Box className="display-small-table-label">Day: &nbsp;</Box>
							<span>
								{moment(monthFirstDay).format("YYYY-MM-DD")} to{" "}
								{moment(monthLastDay).format("YYYY-MM-DD")}
							</span>
							{/* <span>{row.day}</span> */}
						</div>
					</TableCell>
					<TableCell scope="row">
						<div>
							<Box className="display-small-table-label">Staff: &nbsp;</Box>
							<Button onClick={() => handleViewAllBtnClick(row.physicianId)}>
								View All Shifts
							</Button>
						</div>
					</TableCell>
				</>
			) : (
				<Card className="bg-white">
					<div className="card-container">
						<CardHeader
							title={
								<React.Fragment>
									<Checkbox
										onClick={() => handleOnSelectRow(row.physicianId)}
										icon={<CheckboxIcon />}
										checkedIcon={<CheckedboxIcon />}
										checked={selected}
										disableRipple
										disableFocusRipple
										inputProps={{
											"aria-labelledby": `enhanced-table-checkbox-${index}`,
										}}
									/>
									<span>{row.name}</span>
								</React.Fragment>
							}
						/>
						<CardContent className="card-header-content">
							<div className="card-subheader">
								<Typography variant="body2" color="text.secondary">
									{moment(monthFirstDay).format("YYYY-MM-DD")} to{" "}
									{moment(monthLastDay).format("YYYY-MM-DD")}
								</Typography>
							</div>
							<Button
								onClick={() => handleViewAllBtnClick(row.physicianId)}
								disableRipple
								variant="outlined"
							>
								View All Shifts
							</Button>
						</CardContent>
					</div>
				</Card>
			)}
		</>
	);
}
