import React, { useContext, useState } from "react";
import {
	Box,
	Checkbox,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Tooltip,
} from "@mui/material";
import {
	CheckIcon,
	DeleteIcon,
	DownloadIcon,
	UncheckIcon,
} from "assests/images";
import myCustomTheme from "app.theme";
import { getComparator, getFileIconByType, stableSort } from "utility/helpers";
import { IDocumentInfo } from "utility/interfaces";
import moment from "moment";
import PreviewIcon from "@mui/icons-material/Preview";
import { visuallyHidden } from "@mui/utils";
import { PreviewFileModal } from "components";
import { UserContext } from "contexts/user-context";
import { AccountType } from "utility/enums/request-status";

type ComponentProps = {
	selectedDocuments: readonly string[];
	documentList: IDocumentInfo[];
	handleOnSelectAllClick: any;
	handleOnSelectDocument: any;
	handleOnClickDeleteDocument: any;
	handleOnClickDownloadDocument: any;
	handleClickPreviewDocument: any;
};
export function DocumentList(props: ComponentProps) {
	// Use context
	const contextUser = useContext(UserContext);

	// useStates
	type Order = "asc" | "desc";
	const [orderDirection, setOrderDirection] = useState<Order>("asc");

	// Extract Props
	const {
		selectedDocuments,
		documentList,
		handleOnSelectAllClick,
		handleOnSelectDocument,
		handleOnClickDeleteDocument,
		handleOnClickDownloadDocument,
		handleClickPreviewDocument,
	} = props;

	// Page level interfaces
	interface EnhancedTableProps {
		numSelected: number;
		onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
		rowCount: number;
	}

	const CheckedboxIcon = () => {
		return <img src={CheckIcon} alt="checkbox" />;
	};
	const CheckboxIcon = () => {
		return <img src={UncheckIcon} alt="checkbox" />;
	};
	const handleOnChangeSortDirection = () => {
		const isAsc = orderDirection === "asc";
		setOrderDirection(isAsc ? "desc" : "asc");
	};

	const EnhancedTableHead = (props: EnhancedTableProps) => {
		const { onSelectAllClick, numSelected, rowCount } = props;
		return (
			<TableHead>
				<TableRow>
					<TableCell
						padding="checkbox"
						sx={{ className: "min-w-70 min-w-sm-62" }}
						align="center"
					>
						<Checkbox
							icon={<CheckboxIcon />}
							checkedIcon={<CheckedboxIcon />}
							color="primary"
							checked={rowCount > 0 && numSelected === rowCount}
							onChange={onSelectAllClick}
							disableRipple
							disableFocusRipple
							inputProps={{
								"aria-label": "select all files",
							}}
						/>
					</TableCell>
					<TableCell className="min-w-210">Documents</TableCell>
					<TableCell sortDirection={orderDirection} className="min-w-160">
						<TableSortLabel
							active={true}
							direction={orderDirection}
							onClick={handleOnChangeSortDirection}
						>
							Upload Date
							<Box component="span" sx={visuallyHidden}>
								{orderDirection === "desc"
									? "sorted descending"
									: "sorted ascending"}
							</Box>
						</TableSortLabel>
					</TableCell>
					<TableCell align="center" className="w-142">
						Actions
					</TableCell>
				</TableRow>
			</TableHead>
		);
	};

	return (
		<>
			<TableContainer className="upload-table-container">
				<Table className="upload-table">
					<EnhancedTableHead
						numSelected={selectedDocuments.length}
						onSelectAllClick={handleOnSelectAllClick}
						rowCount={documentList.length}
					/>
					<TableBody>
						{stableSort(
							documentList,
							getComparator(orderDirection, "createdDate")
						).map((document, index) => {
							return (
								<TableRow
									hover
									role="checkbox"
									tabIndex={-1}
									key={document.requestWiseFileID}
									selected={
										selectedDocuments.indexOf(
											document.requestWiseFileID.toString()
										) !== -1
									}
								>
									<TableCell padding="checkbox" align="center">
										<Checkbox
											onClick={() =>
												handleOnSelectDocument(document.requestWiseFileID)
											}
											icon={<CheckboxIcon />}
											checkedIcon={<CheckedboxIcon />}
											checked={
												selectedDocuments.indexOf(
													document.requestWiseFileID.toString()
												) !== -1
											}
											disableRipple
											disableFocusRipple
											inputProps={{
												"aria-labelledby": `enhanced-table-checkbox-${index}`,
											}}
										/>
									</TableCell>
									<TableCell
										component="th"
										id={`enhanced-table-checkbox-${index}`}
										scope="row"
										padding="none"
										className="upload-file-col"
									>
										<div className="upload-file">
											<img
												src={getFileIconByType(document.mimeType)}
												alt="file"
											/>
											<span>{document.fileName}</span>
										</div>
									</TableCell>
									<TableCell>
										{moment(document.createdDate).format("MMM D, YYYY")}
									</TableCell>
									<TableCell align="center">
										<div className="upload-actions">
											<Tooltip title="Download">
												<IconButton
													id="donwloadBtn"
													onClick={() =>
														handleOnClickDownloadDocument(
															[document.requestWiseFileID.toString()],
															false
														)
													}
													disableFocusRipple
													disableRipple
												>
													<img src={DownloadIcon} alt="download" />
												</IconButton>
											</Tooltip>
											<Tooltip title="Preview">
												<IconButton
													id="previewBtn"
													disableFocusRipple
													disableRipple
													onClick={() =>
														handleClickPreviewDocument([
															document.requestWiseFileID.toString(),
														])
													}
												>
													<PreviewIcon />
												</IconButton>
											</Tooltip>
											{(contextUser.userRoleId === AccountType.Admin ||
												document.docType === 12 ||
												document.docType === 0 ||
												document.docType === null) && (
												<Tooltip title="Delete" arrow placement="bottom">
													<IconButton
														onClick={() =>
															handleOnClickDeleteDocument([
																document.requestWiseFileID.toString(),
															])
														}
														disableFocusRipple
														disableRipple
													>
														<img src={DeleteIcon} alt="delete" />
													</IconButton>
												</Tooltip>
											)}
										</div>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}
