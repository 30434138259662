import React from "react";
import { Link, Menu, MenuItem } from "@mui/material";
import {
	AnalysisDarkIcon,
	AnalysisIcon,
	NotesDarkIcon,
	NotesIcon,
	UploadfileIcon,
	UploadfileIconDark,
} from "assests/images";
import { IHistoryInfoSearchCriteria, IHistory } from "utility/interfaces";
import { useNavigate } from "react-router-dom";
import { AppRoutings } from "utility/enums/app-routings";

type ComponentProps = {
	row: IHistory;
	tableMenuOpen: boolean;
	tableUser: HTMLElement | null;
	handleTableMenuClose: any;
	searchCriteria: IHistoryInfoSearchCriteria;
	handleOnClickSendMail: any;
	handleOnClickDownload: any;
};

export function HistoryInfoTableRowMenu(props: ComponentProps) {
	// Page level variable
	const navigate = useNavigate();

	// Extract Props
	const {
		row,
		tableMenuOpen,
		tableUser,
		handleTableMenuClose,
		searchCriteria,
		handleOnClickSendMail,
		handleOnClickDownload,
	} = props;

	return (
		<>
			<Menu
				id="table-menu"
				anchorEl={tableUser}
				open={tableMenuOpen}
				className="new-table-menu"
				onClose={handleTableMenuClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<MenuItem onClick={handleOnClickSendMail}>
					<Link>
						<img src={AnalysisIcon} alt="edit" className="light-icon" />
						<img src={AnalysisDarkIcon} alt="edit" className="dark-icon" />
						Mail
					</Link>
				</MenuItem>
				<MenuItem onClick={handleOnClickDownload}>
					<Link>
						<img src={UploadfileIcon} alt="call" className="light-icon" />
						<img src={UploadfileIconDark} alt="call" className="dark-icon" />
						Download
					</Link>
				</MenuItem>
				<MenuItem
					onClick={() =>
						navigate(
							AppRoutings.PatientHistory.replace(":patientId", row.queryString)
						)
					}
				>
					<Link>
						<img src={NotesIcon} alt="h-call" className="light-icon" />
						<img src={NotesDarkIcon} alt="h-call" className="dark-icon" />
						Patient Records
					</Link>
				</MenuItem>
			</Menu>
		</>
	);
}
