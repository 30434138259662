import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from "@mui/material";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import React, { useContext, useEffect, useState } from "react";
import haloService from "services/halo-service";
import { Constants } from "utility/enums/constants";
import { RequestTypes } from "utility/enums/request-status";
import { SortOrder } from "utility/enums/sort-order";
import { createCommonAPICall } from "utility/helpers";
import { IHaloRequestsInfo, ISearchCriteria } from "utility/interfaces";
import { visuallyHidden } from "@mui/utils";
import { HaloRequestsTableRow } from "./table-row";
import { TablePagination } from "components";

export const HaloRequestsTable = () => {
	// Page level local variables
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// State Variables
	const [searchCriteria, setSearchCriteria] = useState<ISearchCriteria>({
		PageIndexId: 0,
		PageSize: Constants.DefaultTablePageSize.valueOf(),
		SortBy: "ModifiedDate",
		SortOrder: SortOrder.descending,
		SearchBy: "",
	});

	const [haloRequestPageInfo, setHaloRequestPageInfo] =
		useState<IHaloRequestsInfo>({
			haloRequests: [],
			totalRecords: 0,
		});

	// Handle events
	const getHaloRequestsInfo = async () => {
		const requestBody = {
			...searchCriteria,
			requestTypeId: RequestTypes.Patient,
		};

		const data = await createCommonAPICall({
			requestBody,
			apiService: haloService.getHaloRequestGrid,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			setHaloRequestPageInfo(data.data);
		}
	};

	const handleOnPageNumberChange = (value: number) => {
		setSearchCriteria({ ...searchCriteria, PageIndexId: value });
	};
	const handleOnChangeSortDirection = (sortBy: string) => {
		setSearchCriteria({
			...searchCriteria,
			SortBy: sortBy,
			SortOrder:
				searchCriteria.SortOrder === SortOrder.ascending
					? SortOrder.descending
					: SortOrder.ascending,
		});
	};

	// Use effects
	useEffect(() => {
		getHaloRequestsInfo();
	}, [searchCriteria]);

	return (
		<>
			{haloRequestPageInfo.totalRecords > 0 ? (
				<>
					<TableContainer
						className="upload-table-container"
						sx={{ display: { xs: "none", laptop: "block" } }}
					>
						<Table className="upload-table conclude-table">
							<TableHead>
								<TableRow>
									<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
										<TableSortLabel
											active={searchCriteria.SortBy === "ClientName"}
											direction={searchCriteria.SortOrder}
											onClick={() => handleOnChangeSortDirection("ClientName")}
										>
											Client/Member
											<Box component="span" sx={visuallyHidden}>
												{searchCriteria.SortOrder === SortOrder.descending
													? "sorted descending"
													: "sorted ascending"}
											</Box>
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
										<TableSortLabel
											active={searchCriteria.SortBy === "PhoneNumber"}
											direction={searchCriteria.SortOrder}
											onClick={() => handleOnChangeSortDirection("PhoneNumber")}
										>
											Phone
											<Box component="span" sx={visuallyHidden}>
												{searchCriteria.SortOrder === SortOrder.descending
													? "sorted descending"
													: "sorted ascending"}
											</Box>
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
										<TableSortLabel
											active={searchCriteria.SortBy === "email"}
											direction={searchCriteria.SortOrder}
											onClick={() => handleOnChangeSortDirection("email")}
										>
											Email
											<Box component="span" sx={visuallyHidden}>
												{searchCriteria.SortOrder === SortOrder.descending
													? "sorted descending"
													: "sorted ascending"}
											</Box>
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
										<TableSortLabel
											active={searchCriteria.SortBy === "WorkPlaceName"}
											direction={searchCriteria.SortOrder}
											onClick={() =>
												handleOnChangeSortDirection("WorkPlaceName")
											}
										>
											Workplace
											<Box component="span" sx={visuallyHidden}>
												{searchCriteria.SortOrder === SortOrder.descending
													? "sorted descending"
													: "sorted ascending"}
											</Box>
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
										<TableSortLabel
											active={searchCriteria.SortBy === "Status"}
											direction={searchCriteria.SortOrder}
											onClick={() => handleOnChangeSortDirection("Status")}
										>
											Status
											<Box component="span" sx={visuallyHidden}>
												{searchCriteria.SortOrder === SortOrder.descending
													? "sorted descending"
													: "sorted ascending"}
											</Box>
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
										<TableSortLabel
											active={searchCriteria.SortBy === "PhysicianName"}
											direction={searchCriteria.SortOrder}
											onClick={() =>
												handleOnChangeSortDirection("PhysicianName")
											}
										>
											Physician Name
											<Box component="span" sx={visuallyHidden}>
												{searchCriteria.SortOrder === SortOrder.descending
													? "sorted descending"
													: "sorted ascending"}
											</Box>
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ maxWidth: "160px" }} className="min-w-160px">
										<TableSortLabel
											active={searchCriteria.SortBy === "Address"}
											direction={searchCriteria.SortOrder}
											onClick={() => handleOnChangeSortDirection("Address")}
										>
											Address
											<Box component="span" sx={visuallyHidden}>
												{searchCriteria.SortOrder === SortOrder.descending
													? "sorted descending"
													: "sorted ascending"}
											</Box>
										</TableSortLabel>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{haloRequestPageInfo.haloRequests.map((row, index) => (
									<HaloRequestsTableRow
										row={row}
										isSmallDevice={false}
										key={index}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Box
						sx={{ display: { xs: "block", laptop: "none" } }}
						className="tabledata-cards-group"
					>
						{haloRequestPageInfo.haloRequests.map((row, index) => (
							<HaloRequestsTableRow
								row={row}
								isSmallDevice={true}
								key={index}
							/>
						))}
					</Box>
					<div className="small-table-pagination">
						<TablePagination
							currentPageNumber={searchCriteria.PageIndexId}
							handleOnPageNumberChange={handleOnPageNumberChange}
							totalRecords={haloRequestPageInfo.totalRecords || 0}
							rowCount={haloRequestPageInfo.haloRequests.length || 0}
						/>
					</div>
				</>
			) : (
				<Typography variant="h5" sx={{ padding: 3 }}>
					No Record(s) Found{" "}
				</Typography>
			)}
		</>
	);
};
