import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
} from "@mui/material";
import {
	IHistoryInfoSearchCriteria,
	IHistorySearchFields,
} from "utility/interfaces";
import { Constants } from "utility/enums/constants";
import { SortOrder } from "utility/enums/sort-order";
import { createCommonAPICall } from "utility/helpers";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import historyService from "services/history-service";
import { CustomDatePicker } from "components";

type ComponentProps = {
	handleOnSearchCriteriaChange: any;
};

export function HistoryInfoTableSearch(props: ComponentProps) {
	// Extract Props
	const { handleOnSearchCriteriaChange } = props;

	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// useStates
	const [formValues, setFormValues] = useState<IHistoryInfoSearchCriteria>({
		PageIndexId: 0,
		PageSize: Constants.DefaultTablePageSize.valueOf(),
		SortOrder: SortOrder.ascending,
		SortBy: "",
		SearchBy: "",
		paymentStatus: 0,
		name: "",
		date: "",
		requestType: 0,
		providerName: "",
		email: "",
		phoneNumber: "",
	});
	const [searchFields, setSearchFields] = useState<IHistorySearchFields>({
		requestStatus: [],
		requestType: [],
		caseTags: [],
	});

	// Handled events and functions
	const handleFormValueChange = (name: string, value: any) => {
		setFormValues({ ...formValues, [name]: value });
	};
	const handleOnClearForm = () => {
		handleOnSearchCriteriaChange({
			PageIndexId: 0,
			PageSize: Constants.DefaultTablePageSize.valueOf(),
			SortOrder: SortOrder.ascending,
			SortBy: "",
			SearchBy: "",
			paymentStatus: 0,
			name: "",
			date: "",
			requestType: 0,
			providerName: "",
			email: "",
			phoneNumber: "",
		});
		setFormValues({
			PageIndexId: 0,
			PageSize: Constants.DefaultTablePageSize.valueOf(),
			SortOrder: SortOrder.ascending,
			SortBy: "",
			SearchBy: "",
			paymentStatus: 0,
			name: "",
			date: "",
			requestType: 0,
			providerName: "",
			email: "",
			phoneNumber: "",
		});
	};
	const handleOnClickSearch = () => {
		handleOnSearchCriteriaChange(formValues);
	};
	const getSearchFields = async () => {
		const data = await createCommonAPICall({
			apiService: historyService.getSearchFields,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.data) setSearchFields(data.data);
	};

	// useEffects
	useEffect(() => {
		getSearchFields();
	}, []);

	return (
		<>
			<div>
				<div className="history-table-header">
					<Grid container columnSpacing={{ sm: 2, lg: 3 }}>
						<Grid item xs={12} sm={3}>
							<FormControl fullWidth className="select-input">
								<InputLabel id="business-label">
									Select Payment Status
								</InputLabel>
								<Select
									labelId="business-label"
									id="business-name"
									name="paymentStatus"
									value={formValues.paymentStatus.toString() || ""}
									onChange={(e) =>
										handleFormValueChange("paymentStatus", e.target.value)
									}
									label="Select Payment Status"
									MenuProps={{
										className: "select-input-modal",
									}}
								>
									{searchFields.requestStatus.map((item, index) => {
										return (
											<MenuItem key={index} value={item.value}>
												{item.text}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextField
								fullWidth
								id="name"
								label="Name"
								variant="outlined"
								name="name"
								value={formValues.name}
								onChange={(e) => handleFormValueChange("name", e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<CustomDatePicker
								value={formValues.date}
								label="Date"
								onChange={handleFormValueChange}
								name="date"
								disabled={false}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<FormControl fullWidth className="select-input">
								<InputLabel id="business-label">Select Request Type</InputLabel>
								<Select
									labelId="business-label"
									id="request-type"
									name="requestType"
									value={formValues.requestType.toString() || ""}
									onChange={(e) =>
										handleFormValueChange("requestType", e.target.value)
									}
									label="Select Payment Status"
									MenuProps={{
										className: "select-input-modal",
									}}
								>
									{searchFields.requestType.map((item, index) => {
										return (
											<MenuItem key={index} value={item.value}>
												{item.text}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextField
								fullWidth
								id="provider-name"
								label="Provider Name"
								variant="outlined"
								name="providerName"
								value={formValues.providerName}
								onChange={(e) =>
									handleFormValueChange("providerName", e.target.value)
								}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextField
								fullWidth
								id="email"
								label="Email"
								variant="outlined"
								name="email"
								value={formValues.email}
								onChange={(e) => handleFormValueChange("email", e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextField
								fullWidth
								id="phoneNumber"
								label="Phone Number"
								variant="outlined"
								name="phoneNumber"
								value={formValues.phoneNumber}
								onChange={(e) =>
									handleFormValueChange("phoneNumber", e.target.value)
								}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Box
								sx={{
									display: "flex",
									columnGap: "12px",
									justifyContent: "flex-end",
								}}
							>
								<div className="history-search-btn">
									<Tooltip title="Click To Search">
										<Button variant="outlined" onClick={handleOnClearForm}>
											<span className="button-link">Clear</span>
										</Button>
									</Tooltip>
								</div>
								<div className="history-search-btn">
									<Tooltip title="Click To Search">
										<Button variant="contained" onClick={handleOnClickSearch}>
											<span className="button-link">Search</span>
										</Button>
									</Tooltip>
								</div>
							</Box>
						</Grid>
					</Grid>
				</div>
			</div>
		</>
	);
}
