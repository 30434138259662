import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { RouterConfig } from "utility/auth-guards/router-config";
import myCustomTheme from "app.theme";
import { SuccessErrorModal, Loader, InstallationBanner } from "components";
import { SuccessErrorModalProvider } from "contexts/success-error-context";
import { UserProvider } from "contexts/user-context";
import { ToastContainer } from "react-toastify";
import { MenuProvider } from "contexts/menu-context";
import { ChatCountProvider } from "contexts/chat-count-context";
import { PhysicianTrackProvider } from "contexts/physician-track-context";
import { PromptEventProvider } from "contexts/prompt-event-context";

const App: React.FC = () => {
	return (
		<>
			<SuccessErrorModalProvider>
				<UserProvider>
					<MenuProvider>
						<ChatCountProvider>
							<PhysicianTrackProvider>
								<PromptEventProvider>
									<ThemeProvider theme={myCustomTheme}>
										<SuccessErrorModal />
										<InstallationBanner />
										<React.Suspense fallback={<Loader />}>
											<BrowserRouter>
												<RouterConfig />
												<ToastContainer />
											</BrowserRouter>
										</React.Suspense>
									</ThemeProvider>
								</PromptEventProvider>
							</PhysicianTrackProvider>
						</ChatCountProvider>
					</MenuProvider>
				</UserProvider>
			</SuccessErrorModalProvider>
		</>
	);
};

export default App;
