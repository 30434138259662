import {
	ICreateUpdateWorkPlaceRequest,
	IHaloEmployeesInfo,
	IHaloHR,
	IHaloHRInfo,
	IHaloRequestsInfo,
	IHttpsResponse,
	ISearchCriteria,
} from "utility/interfaces";
import httpClient from "./base-service";
import { AxiosResponse } from "axios";
import { ProtectedEndPoints } from "./api-end-points";

const getHaloAdministratorsGrid = (
	request: ISearchCriteria
): Promise<AxiosResponse<IHttpsResponse<IHaloHRInfo>>> =>
	httpClient.post<IHttpsResponse<IHaloHRInfo>>(
		ProtectedEndPoints.GetHaloAdministratorsGrid,
		request
	);

const createHaloAdministrator = (
	request: IHaloHR
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.CreateHaloAdministrator,
		request
	);

const updateHaloAdministrator = (
	request: IHaloHR
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.UpdateHaloAdministrator,
		request
	);

const deleteHaloAdministrator = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.DeleteHaloAdministrator,
		request
	);

const getHaloRequestGrid = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<IHaloRequestsInfo>>> =>
	httpClient.post<IHttpsResponse<IHaloRequestsInfo>>(
		ProtectedEndPoints.GetHaloRequestGrid,
		request
	);

const getHaloEmployeeGrid = (
	request: ISearchCriteria
): Promise<AxiosResponse<IHttpsResponse<IHaloEmployeesInfo>>> =>
	httpClient.post<IHttpsResponse<IHaloEmployeesInfo>>(
		ProtectedEndPoints.GetHaloEmployeeGrid,
		request
	);

const getHaloEmployee = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.GetHaloEmployee,
		request
	);

const createHaloEmployee = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.CreateHaloEmployee,
		request
	);

const updateHaloEmployee = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.UpdateHaloEmployee,
		request
	);

const deleteHaloEmployee = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.DeleteHaloEmployee,
		request
	);

const getHaloWorkPlaceGrid = (
	request: ISearchCriteria
): Promise<AxiosResponse<IHttpsResponse<IHaloHRInfo>>> =>
	httpClient.post<IHttpsResponse<IHaloHRInfo>>(
		ProtectedEndPoints.GetHaloWorkPlaceGrid,
		request
	);

const createUpdateHaloWorkPlace = (
	request: ICreateUpdateWorkPlaceRequest
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.CreateUpdateHaloWorkPlace,
		request
	);

const deleteHaloWorkPlace = (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		ProtectedEndPoints.DeleteHaloWorkPlace,
		request
	);

export default {
	getHaloAdministratorsGrid,
	createHaloAdministrator,
	updateHaloAdministrator,
	deleteHaloAdministrator,
	getHaloRequestGrid,
	getHaloEmployeeGrid,
	getHaloEmployee,
	createHaloEmployee,
	updateHaloEmployee,
	deleteHaloEmployee,
	getHaloWorkPlaceGrid,
	createUpdateHaloWorkPlace,
	deleteHaloWorkPlace,
};
