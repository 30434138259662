import React, { useEffect, useState, useContext } from "react";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from "@mui/material";
import { ChatBox } from "../common/chat-box";
import { createCommonAPICall, hideLoader, showLoader } from "utility/helpers";
import dashboardService from "services/dashboard-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import {
	IChatUploadRequest,
	IDashboardInfo,
	IDashboardSearchCriteria,
	IGroupChatBoxInfo,
} from "utility/interfaces";
import { SortOrder } from "utility/enums/sort-order";
import { IChatBoxInfo } from "utility/interfaces";
import {
	DashboardTableRowData,
	DashboardTableSearch,
	GroupChatBox,
	TablePagination,
} from "components";
import { AccountType } from "utility/enums/request-status";
import { UserContext } from "contexts/user-context";
import { Constants } from "utility/enums/constants";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { toast } from "react-toastify";

const URL = Constants.HubUrl;

type ComponentProps = {
	searchCriteria: IDashboardSearchCriteria;
	handleOnSearchCriteriaChange: any;
	dashboardPageInfo: IDashboardInfo | null;
	getDashboardDetails: any;
	isShowDob: boolean;
};

export function DashboardTable(props: ComponentProps) {
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const userrole = useContext(UserContext);

	// Extract Props
	const {
		searchCriteria,
		handleOnSearchCriteriaChange,
		dashboardPageInfo,
		getDashboardDetails,
		isShowDob,
	} = props;

	// useStates
	const [openChatBoxList, setOpenChatBoxList] = useState<IChatBoxInfo[]>([]);
	const [connection, setConnection] = useState<null | signalR.HubConnection>(
		null
	);
	const [openGroupChatBoxList, setOpenGroupChatBoxList] = useState<
		IGroupChatBoxInfo[]
	>([]);

	useEffect(() => {
		const newConnection = new HubConnectionBuilder()
			.withUrl(URL)
			.withAutomaticReconnect()
			.build();

		setConnection(newConnection);
	}, []);

	useEffect(() => {
		if (connection) {
			connection
				.start()
				.then((result) => {
					console.log("Connected!");
					connection.on("SetChatHistoryList", (data) => {
						console.log(data);
						if (data) setOpenChatBoxList(data.chatresponse);
						const request = data.chatresponse;
						const openChatBoxes = openChatBoxList.filter(
							(box) =>
								box.receiverId === request.receiverId &&
								box.senderId === request.senderId
						);
						if (!openChatBoxes || openChatBoxes?.length === 0) {
							setOpenChatBoxList([
								...openChatBoxList,
								{
									receiverId: data.chatresponse.receiverId,
									receiverName: data.chatresponse.receiverName,
									// request.requestId == request.receiverId
									// 	? data.chatresponse.patientName
									// 	: data.chatresponse.physicianName,
									senderId: data.chatresponse.senderId,
									senderName: data.chatresponse.senderName,
									isMinimized: false,
									confirmationNumber: data.chatresponse.confirmationNumber,
									isChatWithPatient: request.requestId == request.receiverId,
									isChatWithAdmin:
										data.chatresponse.receiverId == Constants.AdminId
											? true
											: false,
									chatView: data.chatView,
									physicianName: data.chatresponse.physicianName,
								},
							]);
							hideLoader();
							return;
						}
						const chatBoxes = openChatBoxList.filter(
							(box) =>
								box.receiverId !== request.receiverId ||
								box.senderId !== request.senderId
						);
						setOpenChatBoxList([
							...chatBoxes,
							{
								receiverId: data.chatresponse.receiverId,
								receiverName:
									request.requestId == request.receiverId
										? data.chatresponse.patientName
										: data.chatresponse.physicianName,
								senderId: data.chatresponse.senderId,
								senderName: data.chatresponse.senderName,
								isMinimized: false,
								confirmationNumber: data.chatresponse.confirmationNumber,
								isChatWithPatient: request.requestId == request.receiverId,
								isChatWithAdmin:
									data.chatresponse.receiverId == Constants.AdminId
										? true
										: false,
								chatView: data.chatView,
								physicianName: data.chatresponse.physicianName,
							},
						]);
					});

					connection.on("openGroupChatBox", (data) => {
						console.log(data);
						setOpenGroupChatBoxList([data]);
					});
					// connection.on("SendPrivateMessage", (data) =>
					// 	handlePrivateMessage(data)
					// );
				})
				.catch((e) => console.log("Connection failed: ", e));
		}
	}, [connection]);

	useEffect(() => {
		if (connection)
			connection.on("SendPrivateMessage", (data) => handlePrivateMessage(data));
	}, [openChatBoxList]);

	useEffect(() => {
		updateGroupChatBoxClasses(openGroupChatBoxList);
		if (connection)
			connection.on("sendGroupMessage", (data) => {
				console.log(data);
				setOpenGroupChatBoxList([data]);
			});
	}, [openGroupChatBoxList]);

	const handlePrivateMessage = (data: any) => {
		console.log("SendPrivateMessage", data, openChatBoxList);
		if (
			openChatBoxList.find(
				(chat) =>
					chat.confirmationNumber == data.chatresponse.confirmationNumber
			)
		) {
			if (data) setOpenChatBoxList(data.chatresponse);
			const request = data.chatresponse;
			const openChatBoxes = openChatBoxList.filter(
				(box) =>
					box.receiverId === request.receiverId &&
					box.senderId === request.senderId
			);
			if (!openChatBoxes || openChatBoxes?.length === 0) {
				setOpenChatBoxList([
					...openChatBoxList,
					{
						receiverId: data.chatresponse.receiverId,
						receiverName: data.chatresponse.receiverName,
						// request.requestId == request.receiverId
						// 	? data.chatresponse.patientName
						// 	: data.chatresponse.physicianName,
						senderId: data.chatresponse.senderId,
						senderName: data.chatresponse.senderName,
						isMinimized: false,
						confirmationNumber: data.chatresponse.confirmationNumber,
						isChatWithPatient: request.requestId == request.receiverId,
						isChatWithAdmin:
							data.chatresponse.receiverId == Constants.AdminId ? true : false,
						chatView: data.chatView,
						physicianName: data.chatresponse.physicianName,
					},
				]);
				hideLoader();
				return;
			}
			const chatBoxes = openChatBoxList.filter(
				(box) =>
					box.receiverId !== request.receiverId ||
					box.senderId !== request.senderId
			);
			setOpenChatBoxList([
				...chatBoxes,
				{
					receiverId: data.chatresponse.receiverId,
					receiverName:
						request.requestId == request.receiverId
							? data.chatresponse.patientName
							: data.chatresponse.physicianName,
					senderId: data.chatresponse.senderId,
					senderName: data.chatresponse.senderName,
					isMinimized: false,
					confirmationNumber: data.chatresponse.confirmationNumber,
					isChatWithPatient: request.requestId == request.receiverId,
					isChatWithAdmin:
						data.chatresponse.receiverId == Constants.AdminId ? true : false,
					chatView: data.chatView,
					physicianName: data.chatresponse.physicianName,
				},
			]);
		}
	};

	// Handled events and functions
	const handleOnCloseChat = async (
		receiverId: number,
		senderId: number,
		senderTypeId: number,
		receiverTypeId: number
	) => {
		const openChatBoxes = openChatBoxList.filter(
			(box) => box.receiverId !== receiverId || box.senderId !== senderId
		);
		setOpenChatBoxList([...openChatBoxes]);

		await connection!.send(
			"GetDisconnect",
			senderId,
			senderTypeId,
			receiverId,
			receiverTypeId
		);
	};
	const handleOnMinimized = (receiverId: number, senderId: number) => {
		const chatBoxes = openChatBoxList.map((box) => {
			if (box.receiverId === receiverId && box.senderId === senderId) {
				box.isMinimized = !box.isMinimized;
			}
			return box;
		});
		setOpenChatBoxList(chatBoxes);
	};

	const handleHubCOnnect = async (
		senderId: number,
		senderTypeId: AccountType,
		senderName: string,
		receiverId: number,
		receiverTypeId: AccountType,
		requestId: number,
		confirmationNo: string,
		userId: number
	) => {
		await connection!.send(
			"hubconnect",
			senderId,
			senderTypeId,
			senderName,
			receiverId,
			receiverTypeId,
			requestId,
			confirmationNo,
			userId == null ? 0 : userId
		);
	};

	const handleOpenChatBox = async (
		userId: number,
		requestId: number,
		physicianId: number,
		physicianName: string,
		adminName: string,
		confitmationNumber: string,
		isChatWithPatient: boolean,
		isChatWithAdmin: boolean
	) => {
		const senderId =
			userrole.userRoleId == AccountType.Admin
				? Constants.AdminId
				: physicianId;
		const senderTypeId =
			userrole.userRoleId == AccountType.Admin
				? AccountType.Admin
				: AccountType.Physician;
		const senderName =
			userrole.userRoleId == AccountType.Admin ? adminName : physicianName;
		showLoader();
		await connection!.send(
			"hubconnect",
			senderId,
			senderTypeId,
			senderName,
			isChatWithPatient
				? requestId
				: isChatWithAdmin
					? Constants.AdminId
					: physicianId,
			isChatWithPatient
				? AccountType.Patient
				: isChatWithAdmin
					? AccountType.Admin
					: AccountType.Physician,
			requestId,
			confitmationNumber,
			userId == null ? 0 : userId
		);
	};

	const keyDownHandler = async (
		event: React.KeyboardEvent<HTMLInputElement>,
		message: string,
		senderId: number,
		receiverId: number,
		receiverAccountType: AccountType,
		confirmationNumber: string,
		senderAccountType: AccountType
	) => {
		console.log("keyDownHandler");
		if (event.code === "Enter" && message !== "") {
			await connection!.send(
				"SendPrivateMessage",
				message,
				senderId,
				receiverId,
				receiverAccountType,
				confirmationNumber,
				senderAccountType,
				"",
				0
			);
		}
	};

	const handleStartGroupChat = async (senderId: number, recieverId: number) => {
		console.log("Start group chat");
		await connection!.send("StartGroupChat", senderId, recieverId);
	};

	const sendMessage = async (
		message: string,
		senderId: number,
		receiverId: number,
		receiverAccountType: AccountType,
		confirmationNumber: string,
		senderAccountType: AccountType
	) => {
		console.log("sendMessage", receiverAccountType);
		await connection!.send(
			"SendPrivateMessage",
			message,
			senderId,
			receiverId,
			receiverAccountType,
			confirmationNumber,
			senderAccountType,
			"",
			0
		);
	};

	const sendAudioVideo = (
		file: Blob,
		senderId: number,
		receiverId: number,
		receiverAccountType: AccountType,
		confirmationNumber: string,
		senderAccountType: AccountType
	) => {
		console.log("File Sent");
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			if (reader.result && typeof reader.result == "string") {
				const chatUploadMedia: IChatUploadRequest = {
					File: reader.result,
					SenderId: senderId,
					ReceiverId: receiverId,
					ReceiverAccountTypeId: receiverAccountType,
					ConfirmationNumber: confirmationNumber,
					SenderAccountTypeId: senderAccountType,
				};
				console.log(chatUploadMedia);
				createCommonAPICall({
					requestBody: chatUploadMedia,
					apiService: dashboardService.uploadAudioVideoForChat,
					showSuccessMessage: false,
					showErrorMessage: false,
					setSuccessErrorContext,
				}).then(async (data) => {
					console.log(data.data);
					let msg = "";
					let path = data.data[0].path;
					// let path = CLOUD_URL + data.data[0].path;
					// if (file.type.includes("audio")) {
					// 	msg = `<audio controls src="${path}"></audio>`;
					// } else if (file.type.includes("video")) {
					// 	msg = `<video controls src="${path}"></video>`;
					// }
					if (data.data[0].status) {
						await connection!.send(
							"SendPrivateMessage",
							path,
							senderId,
							receiverId,
							receiverAccountType,
							confirmationNumber,
							senderAccountType,
							file.type,
							0
						);
					} else {
						toast.error(data.data[0].message);
					}
				});
			}
		};
	};

	const updateChatBoxClasses = (openChatBoxList: IChatBoxInfo[]) => {
		const openChatBoxes = openChatBoxList.filter((box) => !box.isMinimized);
		openChatBoxes.length > 0
			? document.body.classList.toggle("chatbox-open", true)
			: document.body.classList.toggle("chatbox-open", false);
	};
	const handleOnPageNumberChange = (value: number) => {
		handleOnSearchCriteriaChange({ ...searchCriteria, PageIndexId: value });
	};
	const handleOnChangeSortDirection = (sortBy: string) => {
		handleOnSearchCriteriaChange({
			...searchCriteria,
			PageIndexId: 0,
			SortBy: sortBy,
			SortOrder:
				searchCriteria.SortOrder === SortOrder.ascending
					? SortOrder.descending
					: SortOrder.ascending,
		});
	};

	const handleOnCloseGroupChat = async (
		chatBoxIndex: number,
		senderId: number
	) => {
		const openChatBoxes = openGroupChatBoxList.filter(
			(box, index) => index !== chatBoxIndex
		);
		setOpenGroupChatBoxList([...openChatBoxes]);

		await connection!.send(
			"GetDisconnect",
			senderId,
			userrole.userRoleId,
			0,
			0
		);
	};

	const handleOnGroupChatMinimized = (chatBoxIndex: number) => {
		const chatBoxes = openGroupChatBoxList.map((box, index) => {
			if (index === chatBoxIndex) {
				box.isMinimized = !box.isMinimized;
			}
			return box;
		});
		setOpenGroupChatBoxList(chatBoxes);
	};

	const handleSendGroupMessage = async (
		chatGroupId: number,
		message: string,
		senderId: number,
		senderTypeId: number
	) => {
		if (connection) {
			console.log(
				"Send group message: ",
				chatGroupId,
				message,
				senderId,
				senderTypeId
			);
			connection.send(
				"SendGroupMessage",
				chatGroupId,
				message,
				senderId,
				senderTypeId,
				"",
				0
			);
		}
	};

	const sendAudioVideoGroup = (
		file: Blob,
		chatGroupId: number,
		senderId: number,
		senderAccountTypeId: number
	) => {
		console.log(file, chatGroupId, senderId, senderAccountTypeId);
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			if (reader.result && typeof reader.result == "string") {
				const chatUploadMedia = {
					File: reader.result,
					SenderId: senderId,
					SenderAccountTypeId: senderAccountTypeId,
					chatGroupId: chatGroupId,
					IsGroup: true,
				};
				console.log(chatUploadMedia);
				createCommonAPICall({
					requestBody: chatUploadMedia,
					apiService: dashboardService.uploadAudioVideoForChat,
					showSuccessMessage: false,
					showErrorMessage: false,
					setSuccessErrorContext,
				}).then(async (data) => {
					console.log(data.data);
					let message = data.data[0].path;
					if (data.data[0].status) {
						await connection!.send(
							"SendGroupMessage",
							chatGroupId,
							message,
							senderId,
							senderAccountTypeId,
							file.type,
							0
						);
					} else {
						toast.error(data.data[0].message);
					}
				});
			}
		};
	};

	const updateGroupChatBoxClasses = (
		openGroupChatBoxList: IGroupChatBoxInfo[]
	) => {
		const openGroupChatBoxes = openGroupChatBoxList.filter(
			(box) => !box.isMinimized
		);
		openGroupChatBoxes.length > 0
			? document.body.classList.toggle("chatbox-open", true)
			: document.body.classList.toggle("chatbox-open", false);
	};

	// useEffects
	useEffect(() => {
		updateChatBoxClasses(openChatBoxList);
	}, [openChatBoxList]);

	useEffect(() => {
		setOpenChatBoxList(openChatBoxList);
	}, [openChatBoxList]);

	return (
		<Box className="table-box">
			<DashboardTableSearch
				handleOnSearchCriteriaChange={handleOnSearchCriteriaChange}
				searchCriteria={searchCriteria}
				regions={dashboardPageInfo?.regions ?? []}
			/>
			{dashboardPageInfo && dashboardPageInfo?.totalRecords > 0 ? (
				<>
					{/* replaced sm or md with laptop for custom view  - 23 May 2023*/}
					<TableContainer sx={{ display: { xs: "none", laptop: "block" } }}>
						<Table sx={{ tableLayout: { laptop: "fixed", xl: "auto" } }}>
							<TableHead>
								<TableRow>
									{dashboardPageInfo?.gridColumns?.isDisplayName && (
										<TableCell
											sx={{
												maxWidth: { xl: 120, laptop: 120 },
												width: { laptop: 120, xl: "auto" },
											}}
											className="min-w-xl-100 min-w-laptop-120"
										>
											<TableSortLabel
												active={searchCriteria.SortBy === "Client"}
												direction={
													searchCriteria.SortOrder === SortOrder.ascending
														? "asc"
														: "desc"
												}
												onClick={() => handleOnChangeSortDirection("Client")}
											>
												Name
											</TableSortLabel>
										</TableCell>
									)}
									{dashboardPageInfo?.gridColumns?.isDisplayEmail && (
										<TableCell className="max-w-55"></TableCell>
									)}

									{dashboardPageInfo?.gridColumns?.isDisplayDateOfBirth &&
										isShowDob && (
											<TableCell
												sx={{
													maxWidth: { xl: 130, laptop: 130 },
													width: { laptop: 130, xl: "auto" },
												}}
												className="min-w-xl-100 min-w-laptop-120"
											>
												<TableSortLabel
													active={searchCriteria.SortBy === "dateOfBirth"}
													direction={
														searchCriteria.SortOrder === SortOrder.ascending
															? "asc"
															: "desc"
													}
													onClick={() =>
														handleOnChangeSortDirection("dateOfBirth")
													}
												>
													Date Of Birth
												</TableSortLabel>
											</TableCell>
										)}
									{dashboardPageInfo?.gridColumns?.isDisplayRegion && (
										<TableCell
											sx={{
												maxWidth: { xl: 130, laptop: 130 },
												width: { laptop: 130, xl: "auto" },
											}}
											className="min-w-xl-100 min-w-laptop-120"
										>
											<TableSortLabel
												active={searchCriteria.SortBy === "regionName"}
												direction={
													searchCriteria.SortOrder === SortOrder.ascending
														? "asc"
														: "desc"
												}
												onClick={() =>
													handleOnChangeSortDirection("regionName")
												}
											>
												Region
											</TableSortLabel>
										</TableCell>
									)}
									{dashboardPageInfo?.gridColumns?.isDisplayRequestorName && (
										<TableCell
											sx={{
												maxWidth: { xl: 50, laptop: 140 },
												width: { laptop: 140, xl: "auto" },
											}}
											className="min-w-xl-50 min-w-laptop-140"
										>
											<TableSortLabel
												active={searchCriteria.SortBy === "businessName"}
												direction={
													searchCriteria.SortOrder === SortOrder.ascending
														? "asc"
														: "desc"
												}
												onClick={() =>
													handleOnChangeSortDirection("businessName")
												}
											>
												Requestor
											</TableSortLabel>
										</TableCell>
									)}
									{dashboardPageInfo?.gridColumns?.isDisplayRequestedDate && (
										<TableCell
											sx={{
												maxWidth: { xl: 130, laptop: 130 },
												width: { laptop: 130, xl: "auto" },
											}}
											className="min-w-xl-80 min-w-laptop-130"
										>
											<TableSortLabel
												active={searchCriteria.SortBy === "createdDate"}
												direction={
													searchCriteria.SortOrder === SortOrder.ascending
														? "asc"
														: "desc"
												}
												onClick={() =>
													handleOnChangeSortDirection("createdDate")
												}
											>
												Requested Date
											</TableSortLabel>
										</TableCell>
									)}
									{dashboardPageInfo?.gridColumns?.isDisplayPhysicianName && (
										<TableCell
											sx={{
												maxWidth: { xl: 130, laptop: 130 },
												width: { laptop: 130, xl: "auto" },
											}}
											className="min-w-xl-140 min-w-laptop-140"
										>
											<TableSortLabel
												active={searchCriteria.SortBy === "physicianName"}
												direction={
													searchCriteria.SortOrder === SortOrder.ascending
														? "asc"
														: "desc"
												}
												onClick={() =>
													handleOnChangeSortDirection("physicianName")
												}
											>
												Physician Name
											</TableSortLabel>
										</TableCell>
									)}
									{dashboardPageInfo?.gridColumns?.isDisplayAcceptedDate && (
										<TableCell
											sx={{
												maxWidth: { xl: 130, laptop: 130 },
												width: { laptop: 130, xl: "auto" },
											}}
											className="min-w-xl-100 min-w-laptop-100"
										>
											<TableSortLabel
												active={searchCriteria.SortBy === "name"}
												direction={
													searchCriteria.SortOrder === SortOrder.ascending
														? "asc"
														: "desc"
												}
												onClick={() =>
													handleOnChangeSortDirection("acceptedDate")
												}
											>
												Date Of Service
											</TableSortLabel>
										</TableCell>
									)}
									{dashboardPageInfo?.gridColumns?.isDisplayPhone && (
										<TableCell
											sx={{
												maxWidth: { xl: 200, laptop: 200 },
												width: { laptop: 200, xl: "auto" },
											}}
											className="min-w-xl-80 min-w-laptop-200"
										>
											Phone
										</TableCell>
									)}
									{dashboardPageInfo?.gridColumns?.isDisplayAddress && (
										<TableCell
											sx={{
												maxWidth: { xl: 130, laptop: 130 },
												width: { laptop: 130, xl: "auto" },
											}}
											className="min-w-xl-120 min-w-laptop-130"
										>
											Address
										</TableCell>
									)}
									{dashboardPageInfo?.gridColumns?.isDisplayRequestStatus && (
										<TableCell
											sx={{
												maxWidth: { xl: 120, laptop: 120 },
												width: { laptop: 120, xl: "auto" },
											}}
											className="min-w-xl-100 min-w-laptop-120"
										>
											<TableSortLabel
												active={searchCriteria.SortBy === "name"}
												direction={
													searchCriteria.SortOrder === SortOrder.ascending
														? "asc"
														: "desc"
												}
												onClick={() => handleOnChangeSortDirection("status")}
											>
												Status
											</TableSortLabel>
										</TableCell>
									)}
									{dashboardPageInfo?.gridColumns?.isDisplayTransferNotes && (
										<TableCell
											sx={{
												maxWidth: { xl: 120, laptop: 120 },
												width: { laptop: 120, xl: "auto" },
											}}
											className="min-w-xl-100 min-w-laptop-120"
										>
											Notes
										</TableCell>
									)}
									{dashboardPageInfo?.gridColumns?.isDisplayChatWithAdmin ||
									dashboardPageInfo?.gridColumns?.isDisplayChatWithPatient ||
									dashboardPageInfo?.gridColumns?.isDisplayChatWithPhysician ? (
										<TableCell
											sx={{
												maxWidth: { xl: 120, laptop: 120 },
												width: { laptop: 120, xl: "auto" },
											}}
											className="min-w-xl-110 min-w-laptop-120"
										>
											Chat With
										</TableCell>
									) : null}

									<TableCell
										align="center"
										sx={{
											maxWidth: { xl: 104, laptop: 104 },
											width: { laptop: 104, xl: "auto" },
										}}
										className="min-w-xl-110 min-w-laptop-104"
									>
										Actions
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{dashboardPageInfo?.requests?.map((row, index) => (
									<DashboardTableRowData
										getDashboardDetails={getDashboardDetails}
										searchCriteria={searchCriteria}
										handleOpenChatBox={handleOpenChatBox}
										key={index}
										row={row}
										isSmallDevice={false}
										gridButtonsPermissions={dashboardPageInfo?.gridButtons}
										gridColumnPermissions={dashboardPageInfo?.gridColumns}
										toShowDob={isShowDob}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Box
						sx={{ display: { xs: "block", laptop: "none" } }}
						className="tabledata-cards-group"
					>
						{/* replaced sm or md with laptop for custom view */}
						{dashboardPageInfo?.requests?.map((row, index) => (
							<DashboardTableRowData
								getDashboardDetails={getDashboardDetails}
								searchCriteria={searchCriteria}
								handleOpenChatBox={handleOpenChatBox}
								key={index}
								row={row}
								isSmallDevice={true}
								gridButtonsPermissions={dashboardPageInfo?.gridButtons}
								gridColumnPermissions={dashboardPageInfo?.gridColumns}
								toShowDob={isShowDob}
							/>
						))}
					</Box>
					<TablePagination
						currentPageNumber={searchCriteria.PageIndexId}
						handleOnPageNumberChange={handleOnPageNumberChange}
						totalRecords={dashboardPageInfo?.totalRecords || 0}
						rowCount={dashboardPageInfo?.requests?.length || 0}
					/>
				</>
			) : (
				<Typography variant="h5" className="no-record" sx={{ padding: 3 }}>
					No Record(s) Found
				</Typography>
			)}
			{openChatBoxList.map((chatBox, index) => {
				console.log(openChatBoxList);
				return (
					// TODO: set sender and receiver details in table after chat box will work proper
					<ChatBox
						index={index}
						handleOnMinimized={handleOnMinimized}
						key={index}
						handleOnCloseChat={handleOnCloseChat}
						chatBoxInfo={chatBox}
						sendMessage={sendMessage}
						sendAudioVideo={sendAudioVideo}
						onKeyHandler={keyDownHandler}
						handleGroupChatStart={handleStartGroupChat}
					/>
				);
			})}

			{openGroupChatBoxList.map((chatBox, index) => {
				return (
					<GroupChatBox
						index={index}
						key={index}
						groupChatBoxInfo={chatBox}
						handleOnMinimized={handleOnGroupChatMinimized}
						handleOnCloseChat={handleOnCloseGroupChat}
						handleSendGroupMessage={handleSendGroupMessage}
						sendAudioVideo={sendAudioVideoGroup}
					/>
				);
			})}
		</Box>
	);
}
