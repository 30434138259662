import { Box, Typography } from "@mui/material";
import {
	FollowupDefaultIcon,
	FollowupIcon,
	TocloseIconActive,
	TocloseIconDefault,
	UnassignedDefaultIcon,
	UnassignedIcon,
	WorkingIconActive,
	WorkingIconDefault,
} from "assests/images";
import { InformationTab, RegionTable } from "components";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import profileService from "services/profile-service";
import { AppRoutings } from "utility/enums/app-routings";
import { Constants } from "utility/enums/constants";
import { RegionInformationTabStatus } from "utility/enums/region-tab-status";
import { SortOrder } from "utility/enums/sort-order";
import {
	createCommonAPICall,
	getRegionTabStatusIdByName,
	getRegionTabStatusWiseName,
} from "utility/helpers";
import {
	IRegionSearchCriteria,
	IInformationTab,
	IRegionInfo,
} from "utility/interfaces";

const Region: React.FC = () => {
	// get query parameters
	const { tabStatus } = useParams();
	const { state } = window.history;

	// useStates
	const [regionPageInfo, setRegionPageInfo] =
		React.useState<IRegionInfo | null>({
			requestCounts: {
				unAssignedCount: 0,
				followupCount: 0,
				workingCount: 0,
				closeCount: 0,
			},
			requests: [],
			gridButtons: {
				isEdit: true,
				isCall: true,
				isHouseCall: true,
				isFollowup: true,
				isCloseCase: true,
			},
			gridColumns: {
				isDisplayWaitTime: true,
				isDisplayClientMember: true,
				isDisplayBusiness: true,
				isDisplayAddress: true,
				isDisplayRequestStatus: true,
				isDisplayPhysician: true,
				isDisplayRegion: true,
			},
			totalRecords: 0,
			regions: [],
		});
	const [searchCriteria, setSearchCriteria] = useState<IRegionSearchCriteria>({
		PageIndexId: state?.usr?.searchCriteria?.PageIndexId || 0,
		PageSize:
			state?.usr?.searchCriteria?.PageSize ||
			Constants.DefaultTablePageSize.valueOf(),
		SortOrder: state?.usr?.searchCriteria?.SortOrder || SortOrder.ascending,
		SortBy: state?.usr?.searchCriteria?.SortBy || "",
		SearchBy: state?.usr?.searchCriteria?.SearchBy || "",
		RequestStatusId:
			state?.usr?.searchCriteria?.RequestStatusId ||
			getRegionTabStatusIdByName(tabStatus ? tabStatus : ""),
		RegionId: state?.usr?.searchCriteria?.RegionId || 0,
	});

	// Handled events and functions
	const handleOnSearchCriteriaChange = (
		newSearchCriteria: IRegionSearchCriteria
	) => {
		setSearchCriteria(newSearchCriteria);
	};
	const getRegionDetails = async () => {
		createCommonAPICall({
			requestBody: searchCriteria,
			apiService: profileService.getRegionInfo,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		}).then((data) => {
			console.log(data);
			let newInfo = {
				gridColumns: {
					isDisplayWaitTime: data.data.gridColumns.isDisplayWaitTime,
					isDisplayClientMember: data.data.gridColumns.isDisplayName,
					isDisplayBusiness: data.data.gridColumns.isDisplayRequestorName,
					isDisplayAddress: data.data.gridColumns.isDisplayAddress,
					isDisplayRequestStatus: data.data.gridColumns.isDisplayRequestStatus,
					isDisplayPhysician: data.data.gridColumns.isDisplayPhysicianName,
					isDisplayRegion: data.data.gridColumns.isDisplayRegion,
				},
				gridButtons: data.data.gridButtons,
				requests: data.data.requests,
				requestCounts: data.data.requestCounts,
				totalRecords: data.data.totalRecords,
				regions: data.data.regions,
			};
			setRegionPageInfo(newInfo);
		});
	};
	const handleInfoTabChange = (tab: IInformationTab) => {
		const newSearchCriteria = {
			...searchCriteria,
			PageIndexId: 0,
			PageSize: Constants.DefaultTablePageSize.valueOf(),
			// SortOrder: SortOrder.ascending,
			// SortBy: "",
			// SearchBy: "",
			// RegionId: 0,
			RequestStatusId: tab.type,
		};
		navigate(
			`${AppRoutings.Region.replace(
				":tabStatus",
				`${getRegionTabStatusWiseName(tab.type).toLowerCase()}`
			)}`
		);
		handleOnSearchCriteriaChange(newSearchCriteria);
	};

	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const navigate = useNavigate();
	const informationTabs: IInformationTab[] = [
		{
			id: 1,
			type: RegionInformationTabStatus.Unassigned,
			defaultIcon: UnassignedDefaultIcon,
			activeIcon: UnassignedIcon,
			title: "Unassigned",
			count: regionPageInfo?.requestCounts.unAssignedCount || 0,
			class: "new",
		},
		{
			id: 2,
			type: RegionInformationTabStatus.Working,
			defaultIcon: WorkingIconDefault,
			activeIcon: WorkingIconActive,
			title: "Working",
			count: regionPageInfo?.requestCounts.workingCount || 0,
			class: "working",
		},
		{
			id: 3,
			type: RegionInformationTabStatus.FollowUp,
			defaultIcon: FollowupDefaultIcon,
			activeIcon: FollowupIcon,
			title: "Follow up",
			count: regionPageInfo?.requestCounts.followupCount || 0,
			class: "active",
		},
		{
			id: 4,
			type: RegionInformationTabStatus.Closed,
			defaultIcon: TocloseIconDefault,
			activeIcon: TocloseIconActive,
			title: "To Close",
			count: regionPageInfo?.requestCounts.closeCount || 0,
			class: "to-close",
		},
	];

	// useEffects
	useEffect(() => {
		getRegionDetails();
		console.log(searchCriteria);
	}, [searchCriteria]);

	return (
		<>
			<Box>
				<div className="chatbox-overlay"></div>
				<main className="main-content">
					<div
						className="overlay"
						onClick={() => document.body.classList.toggle("sidebar-toggle")}
					></div>
					<InformationTab
						tabs={informationTabs}
						currentTabValue={+searchCriteria.RequestStatusId}
						handleTabChange={handleInfoTabChange}
						repeatSequence={4}
					/>
					<Box className="tab-item-header">
						<Typography variant="h2">
							Patients
							<span className="new">
								&nbsp;&#40;
								{getRegionTabStatusWiseName(searchCriteria.RequestStatusId)}
								&#41;
							</span>
						</Typography>
					</Box>
					<RegionTable
						handleOnSearchCriteriaChange={handleOnSearchCriteriaChange}
						searchCriteria={searchCriteria}
						regionPageInfo={regionPageInfo}
					></RegionTable>
				</main>
			</Box>
		</>
	);
};
export default Region;
