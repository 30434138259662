import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { ArrowBack } from "assests/images";
import { DoctorNoteForm, SendDocumetModal } from "components";
import {
	IDoctorNote,
	IFormRequest,
	ISaveRecommendationValues,
	ISendDocument,
} from "utility/interfaces";
import { AppRoutings } from "utility/enums/app-routings";
import { createCommonAPICall, getTabStatusWiseName } from "utility/helpers";
import dashboardService from "services/dashboard-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { UserContext } from "contexts/user-context";
import { AccountType } from "utility/enums/request-status";
import { toast } from "react-toastify";

const DoctorNote: React.FC = () => {
	// get query parameters
	const { caseId } = useParams();

	// get state value
	const { state } = window.history;

	// Use state
	const [fileName, setFileName] = useState("");
	const [isSendDocumentModalOpen, setIsSendDocumentModalOpen] = useState(false);
	const [modalValues, setModalValues] = useState<ISendDocument>();

	// Page level variables
	const navigate = useNavigate();
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const contextUser = useContext(UserContext);

	// useStates
	const [formValues, setFormValues] = useState<IDoctorNote>({
		firstName: "",
		lastName: "",
		dateOfBirth: "",
		dateOfExamination: "",
		mayResume: 0,
		travel: false,
		flight: false,
		school: false,
		work: false,
		sports: false,
		other: "",
		onDate: "",
		improveDayAfter: 0,
		restriction: "",
	});

	// Use Effects
	useEffect(() => {
		window.scrollTo(0, 0);
		const requestBody: IFormRequest = {
			Id: caseId ? caseId : "",
		};
		createCommonAPICall({
			requestBody: requestBody,
			apiService: dashboardService.getRecommendationForm,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext: setSuccessErrorContext,
		}).then((data) => {
			console.log(data.data);
			const isResume = data.data.isResume;
			const others = isResume ? data.data.others : data.data.notothers;
			const newFormValues = {
				firstName: data.data.firstname,
				lastName: data.data.lastname,
				dateOfBirth: data.data.datebirth,
				dateOfExamination: data.data.dateservice,
				mayResume: isResume ? 0 : 1,
				travel: isResume ? data.data.travel : data.data.notTravel,
				flight: isResume ? data.data.flight : data.data.notFlight,
				school: isResume ? data.data.school : data.data.notSchool,
				work: isResume ? data.data.work : data.data.notWork,
				sports: isResume ? data.data.sports : data.data.notSports,
				other: others ? others : "",
				onDate: data.data.resumedate ? data.data.resumedate : "",
				improveDayAfter: data.data.condition ? data.data.condition : 0,
				restriction: data.data.restrictions ? data.data.restrictions : "",
			};
			setFormValues(newFormValues);
			// console.log(data);
			setFileName(data.data.filename);
		});
	}, []);

	// Handled events and functions
	const handleOnClickBackCancel = () =>
		navigate(
			AppRoutings.Dashboard.replace(
				":tabStatus",
				getTabStatusWiseName(
					state?.usr?.searchCriteria?.RequestStatusId
				).toLocaleLowerCase()
			),
			{
				state: {
					searchCriteria: state?.usr?.searchCriteria,
				},
			}
		);

	const handleFormValueChange = (name: string, value: any) => {
		setFormValues({ ...formValues, [name]: value });
	};

	const handleClickSave = () => {
		submitRecommendationForm(false);
	};
	const handleClickFinalize = () => {
		submitRecommendationForm(true);
	};

	const submitRecommendationForm = (isFinalize: boolean) => {
		let dateOfBirth = "";
		let dateOfExamination = "";
		let resumeOnDate = "";
		if (typeof formValues.dateOfBirth == "object") {
			dateOfBirth = `${formValues.dateOfBirth["$M"] + 1}-${
				formValues.dateOfBirth["$D"]
			}-${formValues.dateOfBirth["$y"]}`;
		}
		if (typeof formValues.dateOfExamination == "object") {
			dateOfExamination = `${formValues.dateOfExamination["$M"] + 1}-${
				formValues.dateOfExamination["$D"]
			}-${formValues.dateOfExamination["$y"]}`;
		}
		if (typeof formValues.onDate == "object") {
			resumeOnDate = `${formValues.onDate["$M"] + 1}-${
				formValues.onDate["$D"]
			}-${formValues.onDate["$y"]}`;
		}

		const requestBody: ISaveRecommendationValues = {
			RequestId: caseId ? caseId : "",
			FirstName: formValues.firstName,
			LastName: formValues.lastName,
			DateOfBirth: dateOfBirth == "" ? formValues.dateOfBirth : dateOfBirth,
			DateOfExamination:
				dateOfExamination == ""
					? formValues.dateOfExamination
					: dateOfExamination,
			ResumeOnDate: resumeOnDate == "" ? formValues.onDate : resumeOnDate,
			MayResume: formValues.mayResume == 0,
			Travel: formValues.travel,
			Flight: formValues.flight,
			School: formValues.school,
			Work: formValues.work,
			Sports: formValues.sports,
			Others: formValues.other,
			Restrictions: formValues.restriction,
			ImproveDayAfter: formValues.improveDayAfter,
			IsFinalize: isFinalize,
			FileName: fileName,
		};

		console.log("Final form values: ", requestBody);
		createCommonAPICall({
			requestBody: requestBody,
			apiService: dashboardService.saveRecommendationForm,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		}).then((data) => {
			if (isFinalize && data.isSuccessfull) {
				console.log(data.data.email);
				setModalValues({ email: data.data.email });
				handleOpenSendModal();
			}
		});
	};

	const handleOpenSendModal = () => {
		setIsSendDocumentModalOpen(true);
	};
	const handleCloseSendModal = () => {
		setIsSendDocumentModalOpen(false);
	};

	const handleOnSetEmail = (email: string) => {
		setModalValues({ email: email });
	};

	const handleClickOnEmailSend = () => {
		createCommonAPICall({
			requestBody: {
				Id: caseId ? caseId : "",
				Email: modalValues?.email,
				FileName: fileName,
			},
			apiService: dashboardService.sendMailForRecommendationForm,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		}).then((data) => {
			handleCloseSendModal();
			if (data.isSuccessfull) {
				// toast.success(data.message);
				navigate(
					AppRoutings.Dashboard.replace(
						":tabStatus",
						getTabStatusWiseName(
							state?.usr?.searchCriteria?.RequestStatusId
						).toLocaleLowerCase()
					),
					{
						state: {
							searchCriteria: state?.usr?.searchCriteria,
						},
					}
				);
			}
		});
	};

	return (
		<>
			<Box>
				<main className="main-content">
					<div
						className="overlay"
						onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
					></div>
					<Box className="request-container-box">
						<div className="request-header">
							<Typography variant="h2">Physician Recommendations</Typography>
							<Button variant="outlined" onClick={handleOnClickBackCancel}>
								<img src={ArrowBack} alt="arrow" />
								Back
							</Button>
						</div>
						<DoctorNoteForm
							handleFormValueChange={handleFormValueChange}
							formValues={formValues}
						/>
						<div className="request-btn-group orders-btn-group">
							<Button variant="contained" onClick={handleClickSave}>
								Save
							</Button>
							{contextUser.userRoleId == AccountType.Physician && (
								<Button
									variant="contained"
									color="secondary"
									onClick={handleClickFinalize}
								>
									Finalize
								</Button>
							)}
							<Button variant="outlined" onClick={handleOnClickBackCancel}>
								Cancel
							</Button>
						</div>
					</Box>
				</main>
			</Box>
			{/* Send email modal*/}
			<SendDocumetModal
				isSendDocumentModalOpen={isSendDocumentModalOpen}
				handleOnClickSend={handleClickOnEmailSend}
				sendDocumentModalClose={handleCloseSendModal}
				onSetEmail={handleOnSetEmail}
				values={modalValues}
			/>
		</>
	);
};
export default DoctorNote;
