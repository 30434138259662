import { Box, Button, FormControl, Grid, TextField } from "@mui/material";
import { ArrowBack } from "assests/images";
import { BiWeeklyTimesheetTable, FillReimbursementTable } from "components";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { UserContext } from "contexts/user-context";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import timehseetService from "services/timehseet-service";
import tokenManager from "utility/auth-guards/token-manager";
import { AppRoutings } from "utility/enums/app-routings";
import { AccountType } from "utility/enums/request-status";
import { createCommonAPICall } from "utility/helpers";
import {
	IBiWeeklyTimesheetInfo,
	ITimesheetReimbursement,
} from "utility/interfaces";

const BiWeeklyTimesheet: React.FC = () => {
	// Get Query params
	const queryString = useParams().data;

	// get state value
	const { state } = window.history;

	// Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const contextUser = useContext(UserContext);
	const navigate = useNavigate();

	// State variables
	const [biweeklyTimesheetInfo, setBiweeklyTimesheetInfo] =
		useState<IBiWeeklyTimesheetInfo>({
			timesheets: [],
		});
	const [isReceiptVisible, setIsReceiptVisible] = useState(false);
	const [timesheetReimbursementInfo, setTimeSheetReimbursementInfo] = useState<
		ITimesheetReimbursement[]
	>([]);
	const [approveFormValues, setApproveFormValues] = useState({
		bonusAmount: 0,
		adminDescription: "",
	});

	// Handle events
	const getBiWeeklyTimesheetInfo = async () => {
		console.log(queryString);
		const data = await createCommonAPICall({
			requestBody: { data: queryString },
			apiService: timehseetService.getBiWeeklyTimeSheetDetail,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			console.log(data);
			setBiweeklyTimesheetInfo({ timesheets: data.data });
			let tempArr = data.data
				.filter((r: any, i: number) => i > 0)
				.map((r: any) => r.providerTimeSheetReimbursementView);
			let newArr: ITimesheetReimbursement[] = [];
			tempArr.forEach((a: ITimesheetReimbursement[]) => {
				console.log(a);
				a.forEach((b) => {
					newArr.push(b);
				});
			});

			setTimeSheetReimbursementInfo(newArr);
			if (data.data.length > 0) {
				let firstObj = data.data[0];
				setApproveFormValues({
					bonusAmount: firstObj.bonusAmount,
					adminDescription: firstObj.adminDescription,
				});
			}
		} else {
			tokenManager.clearCookiesAndLocalStorage();
			navigate(AppRoutings.Root);
		}
	};

	const finalizeBiWeeklyTimesheet = async () => {
		const requestBody = {
			providerTimesheetViews: biweeklyTimesheetInfo.timesheets,
		};

		const data = await createCommonAPICall({
			requestBody,
			apiService: timehseetService.finalizeBiWeeklyTimesheet,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			navigate(AppRoutings.Root);
		}
	};

	const handleOnChangeApporveForm = (name: string, value: any) => {
		if (name == "bonusAmount") {
			let newValue = 0;
			if (value) newValue = parseInt(value);
			setApproveFormValues({ ...approveFormValues, bonusAmount: newValue });
		} else {
			setApproveFormValues({ ...approveFormValues, adminDescription: value });
		}
	};
	const handleOnClickApprove = async () => {
		const requestBody = {
			providerId: biweeklyTimesheetInfo.timesheets[1].physicianId,
			providerTimeSheetPeriodId:
				biweeklyTimesheetInfo.timesheets[1].providerTimeSheetPeriodId,
			bonusAmount: approveFormValues.bonusAmount,
			adminDescription:
				approveFormValues.adminDescription !== ""
					? approveFormValues.adminDescription
					: null,
		};

		const data = await createCommonAPICall({
			requestBody,
			apiService: timehseetService.approveTimesheet,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			getBiWeeklyTimesheetInfo();
		}
	};

	const handleOnClickQuickbooks = () => {
		var payrollLink = "https://app.sandbox.qbo.intuit.com/app/contractors";
		window.open(
			payrollLink,
			"_blank",
			"location=yes,height=1080,width=1920,scrollbars=yes,status=yes"
		);
	};

	const handleClickBack = () => {
		navigate(
			state?.usr?.backURL || AppRoutings.Dashboard.replace(":tabStatus", "new")
		);
	};

	// Use effects
	useEffect(() => {
		if (queryString) getBiWeeklyTimesheetInfo();
	}, []);

	return (
		<Box>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="request-container-box">
					<div className="request-btn-group">
						<Button variant="outlined" onClick={handleClickBack}>
							<img src={ArrowBack} alt="arrow" />
							Back
						</Button>
					</div>
					<br />
					{contextUser.userRoleId === AccountType.Physician && (
						<>
							<Box className="request-box">
								<BiWeeklyTimesheetTable
									biweeklyTimesheetInfo={biweeklyTimesheetInfo}
									getBiWeeklyTimesheetInfo={getBiWeeklyTimesheetInfo}
									isFieldsDisabled={biweeklyTimesheetInfo.timesheets.some(
										(t) => t.isSubmitted !== undefined && t.isSubmitted == true
									)}
								/>
							</Box>

							<br />
							<Button
								variant="contained"
								onClick={() => setIsReceiptVisible(!isReceiptVisible)}
							>
								Add Receipts
							</Button>
							{isReceiptVisible && (
								<Box className="request-box another-table">
									<FillReimbursementTable
										isBiWeekly={true}
										timesheetReimbursementInfo={timesheetReimbursementInfo}
										getFillTimeSheetData={getBiWeeklyTimesheetInfo}
										isFinalized={biweeklyTimesheetInfo.timesheets.some(
											(t) =>
												t.isSubmitted !== undefined && t.isSubmitted == true
										)}
									/>
								</Box>
							)}
							<div className="request-btn-group">
								<Button
									variant="contained"
									onClick={finalizeBiWeeklyTimesheet}
									disabled={biweeklyTimesheetInfo.timesheets.some(
										(t) => t.isSubmitted !== undefined && t.isSubmitted == true
									)}
								>
									Finalize
								</Button>
							</div>
						</>
					)}
					{contextUser.userRoleId === AccountType.Admin && (
						<>
							{biweeklyTimesheetInfo.timesheets.at(0) &&
							biweeklyTimesheetInfo.timesheets.at(0)?.isAdminApproved ? (
								<Box className="request-box">
									<div className="request-btn-group">
										<Grid container justifyContent={"center"}>
											<Grid item>
												<TextField
													className="page-footer-form-field"
													label="Invoice Total"
													value={
														biweeklyTimesheetInfo.timesheets[0].totalInvoice
													}
													disabled
												/>
											</Grid>
											<Grid item>
												<TextField
													className="page-footer-form-field"
													label="Bonus Amount"
													value={approveFormValues.bonusAmount}
													disabled
												/>
											</Grid>
											<Grid item>
												<TextField
													className="page-footer-form-field"
													label="Total(Total Invoice+Bonus Amount)"
													value={
														approveFormValues.bonusAmount +
														biweeklyTimesheetInfo.timesheets[0].totalInvoice
													}
													disabled
												/>
											</Grid>
											<Grid item>
												<Button
													variant="contained"
													onClick={handleOnClickQuickbooks}
													sx={{ height: "55px" }}
												>
													Go to Quickbooks Payroll
												</Button>
											</Grid>
										</Grid>
									</div>
								</Box>
							) : (
								<>
									<Box className="request-box">
										<BiWeeklyTimesheetTable
											biweeklyTimesheetInfo={biweeklyTimesheetInfo}
											getBiWeeklyTimesheetInfo={getBiWeeklyTimesheetInfo}
										/>
									</Box>

									<br />
									<Button
										variant="contained"
										onClick={() => setIsReceiptVisible(!isReceiptVisible)}
									>
										Add Receipts
									</Button>
									{isReceiptVisible && (
										<Box className="request-box another-table">
											<FillReimbursementTable
												isBiWeekly={true}
												timesheetReimbursementInfo={timesheetReimbursementInfo}
												getFillTimeSheetData={getBiWeeklyTimesheetInfo}
												isFinalized={biweeklyTimesheetInfo.timesheets.some(
													(t) =>
														t.isSubmitted !== undefined && t.isSubmitted == true
												)}
											/>
										</Box>
									)}
									<div className="request-btn-group">
										<Grid container sx={{ width: "fit-content" }}>
											<Grid item>
												<TextField
													className="page-footer-form-field"
													label="Bonus Amount"
													value={approveFormValues.bonusAmount}
													name="bonusAmount"
													onChange={(e) =>
														handleOnChangeApporveForm(
															e.target.name,
															e.target.value
														)
													}
												/>
											</Grid>
											<Grid item>
												<TextField
													className="page-footer-form-field"
													label="Admin Description"
													value={approveFormValues.adminDescription}
													name="adminDescription"
													onChange={(e) =>
														handleOnChangeApporveForm(
															e.target.name,
															e.target.value
														)
													}
												/>
											</Grid>
											<Grid item>
												<Button
													variant="contained"
													onClick={handleOnClickApprove}
													sx={{ height: "55px" }}
												>
													Approve
												</Button>
											</Grid>
										</Grid>
									</div>
								</>
							)}
						</>
					)}
				</Box>
			</main>
		</Box>
	);
};

export default BiWeeklyTimesheet;
