import React, { useContext, useEffect } from "react";
import { Box } from "@mui/material";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import {
	IAddUpdateBusiness,
	IProfession,
	IDropDownList,
} from "utility/interfaces";
import { AddBusinessForm } from "components";
import vendorService from "services/vendor-service";
import { createCommonAPICall } from "utility/helpers";
const AddBusiness: React.FC = () => {
	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// useStates
	const [formValues, setFormValues] = React.useState<IAddUpdateBusiness>({
		vendorName: "",
		profession: 0,
		faxNumber: "",
		phoneNumber: "",
		email: "",
		businessContact: null,
		street: null,
		city: null,
		state: null,
		zip: null,
	});

	// handled method(s)
	const setUpFormValues = (details: IProfession[]) => {
		const professionTypes: IDropDownList[] = [];
		details.forEach((account) => {
			const profession: IDropDownList = {
				value: account.value,
				text: account.text,
			};
			professionTypes.push(profession);
		});
		setFormValues({ ...formValues, professionTypes });
	};
	const getAddBusinessDetails = async () => {
		const data = await createCommonAPICall({
			apiService: vendorService.getAddVendorDetails,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.data) setUpFormValues(data.data);
	};

	// useEffect
	useEffect(() => {
		getAddBusinessDetails();
	}, []);

	return (
		<Box>
			<AddBusinessForm
				formValues={formValues}
				setFormValues={setFormValues}
				title="Add Business"
				renderer={1}
			/>
		</Box>
	);
};

export default AddBusiness;
