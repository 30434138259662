import React, { useState } from "react";
import tokenManager from "utility/auth-guards/token-manager";
const ChatCountContext = React.createContext();
const ChatCountDispatchContext = React.createContext();

function ChatCountProvider({ children }) {
	const count = tokenManager.getChatCount();
	const [chatCount, setChatCount] = useState(count);

	// Handle events
	const handleChangeChatCount = (newChatCount) => {
		tokenManager.setChatCount(newChatCount);
		setChatCount(newChatCount);
	};

	return (
		<ChatCountContext.Provider value={chatCount}>
			<ChatCountDispatchContext.Provider value={handleChangeChatCount}>
				{children}
			</ChatCountDispatchContext.Provider>
		</ChatCountContext.Provider>
	);
}

export { ChatCountProvider, ChatCountContext, ChatCountDispatchContext };
