import { Checkbox, FormControlLabel } from "@mui/material";
import { CheckIcon, UncheckIcon } from "assests/images";
import React from "react";

type CompProps = {
	label: string;
	isChecked: boolean;
	handleOnChangeCheckbox: any;
	disabled?: boolean;
};
export function CheckBoxComponent(comProps: CompProps) {
	const { label, isChecked, handleOnChangeCheckbox, disabled } = comProps;
	function CheckBoxIcon() {
		return <img src={UncheckIcon} alt="checkbox" />;
	}
	function CheckedBoxIcon() {
		return <img src={CheckIcon} alt="checkbox" />;
	}
	return (
		<FormControlLabel
			value="end"
			control={
				<Checkbox
					disableFocusRipple
					disableRipple
					onChange={handleOnChangeCheckbox}
					icon={<CheckBoxIcon />}
					checked={isChecked}
					checkedIcon={<CheckedBoxIcon />}
					disabled={disabled}
				/>
			}
			label={label}
			labelPlacement="end"
		/>
	);
}
