import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { IInformationTab } from "utility/interfaces";
interface LinkTabProps {
	label?: any;
	href?: string;
	value?: number;
}

type ComponentProps = {
	tabs: IInformationTab[];
	currentTabValue: number;
	handleTabChange: any;
	repeatSequence: number;
};

function LinkTab(props: LinkTabProps) {
	return (
		<Tab
			component="a"
			onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
				event.preventDefault();
			}}
			disableFocusRipple
			disableTouchRipple
			disableRipple
			{...props}
		/>
	);
}

export function InformationTab(props: ComponentProps) {
	// Extract Props
	const { tabs, currentTabValue, handleTabChange, repeatSequence } = props;

	return (
		<Tabs
			value={+currentTabValue}
			className={`main-tabs main-tabs-repeat-${repeatSequence}`}
		>
			{tabs.map((tab) => {
				return (
					<LinkTab
						key={tab.type}
						label={
							<React.Fragment>
								<div
									className={`tab-item ${tab.class}`}
									onClick={() => handleTabChange(tab)}
								>
									<Box>
										<div className="tab-title">
											<img
												src={tab.defaultIcon}
												alt={tab.title}
												className="default"
											/>
											<img
												src={tab.activeIcon}
												alt={tab.title}
												className="active"
											/>
											{tab.title}
										</div>
										<h2 className="counter">{tab.count}</h2>
									</Box>
								</div>
							</React.Fragment>
						}
						value={tab.type}
					/>
				);
			})}
		</Tabs>
	);
}
