import React, { useState, useEffect } from "react";
import { Link, Menu, MenuItem } from "@mui/material";
import {
	AnalysisDarkIcon,
	AnalysisIcon,
	BlockDarkIcon,
	BlockIcon,
	DocnotesIcon,
	DocnotesIconDark,
	ErrorDarkIcon,
	ErrorDarkModeIcon,
	NotesDarkIcon,
	NotesIcon,
	OrdersIcon,
	OrdersIconDark,
	TasksDarkIcon,
	TasksIcon,
	UploadfileIcon,
	UploadfileIconDark,
	HeartbeatIcon,
	HeartbeatIconDark,
	ClearCaseDarkIcon,
	ClearCaseIcon,
} from "assests/images";
import { useNavigate } from "react-router-dom";
import { AppRoutings } from "utility/enums/app-routings";
import {
	IRequest,
	IGridButtons,
	IDashboardSearchCriteria,
} from "utility/interfaces";
import { getTabStatusWiseName } from "utility/helpers";
import { UserContext } from "contexts/user-context";
import {
	AccountType,
	InformationTabTypes,
	RequestStatus,
	RequestTypes,
} from "utility/enums/request-status";
import tokenManager from "utility/auth-guards/token-manager";

type ComponentProps = {
	row: IRequest;
	tableMenuOpen: boolean;
	tableUser: HTMLElement | null;
	handleTableMenuClose: any;
	handleBlockCaseModalOpen: any;
	handleAssignCaseModalOpen: any;
	handleTransferCaseModalOpen: any;
	handleAcceptCaseModalOpen: any;
	handleCancelCaseModalOpen: any;
	handleClearCaseModalOpen: any;
	handleSendAgreemenrModalOpen: any;
	handleSelectCallTypeModalOpen: any;
	handleAlreadyFinalizedModalOpen: any;
	handleClickSkipAgreement: any;
	handleSendDefaultNotificationModalOpen: any;
	gridButtonsPermissions: IGridButtons;
	searchCriteria: IDashboardSearchCriteria;
};

export function DashboardTableRowMenu(props: ComponentProps) {
	// Page level variables
	const navigate = useNavigate();

	const userrole = React.useContext(UserContext);
	const [rejectCase, setRejectCase] = useState("Cancel Case");

	// Extract Props
	const {
		row,
		tableMenuOpen,
		tableUser,
		handleTableMenuClose,
		handleBlockCaseModalOpen,
		handleAssignCaseModalOpen,
		handleTransferCaseModalOpen,
		handleAcceptCaseModalOpen,
		handleCancelCaseModalOpen,
		gridButtonsPermissions,
		handleClearCaseModalOpen,
		handleSendAgreemenrModalOpen,
		handleSelectCallTypeModalOpen,
		handleAlreadyFinalizedModalOpen,
		searchCriteria,
		handleClickSkipAgreement,
		handleSendDefaultNotificationModalOpen,
	} = props;
	//console.log(props);

	useEffect(() => {
		if (gridButtonsPermissions.isReject) {
			setRejectCase("Reject");
		}
	}, []);

	return (
		<Menu
			id="table-menu"
			anchorEl={tableUser}
			open={tableMenuOpen}
			className="new-table-menu"
			onClose={handleTableMenuClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
		>
			{gridButtonsPermissions.isSendAgreement &&
			userrole.userRoleId === AccountType.Physician ? (
				<MenuItem onClick={handleSendAgreemenrModalOpen}>
					<Link>
						<img src={DocnotesIcon} alt="notes" className="light-icon" />
						<img src={DocnotesIconDark} alt="notes" className="dark-icon" />
						Send Agreement
					</Link>
				</MenuItem>
			) : null}

			{gridButtonsPermissions.isConcludeCare ? (
				<MenuItem
					onClick={() => {
						navigate(
							AppRoutings.ConcludeCare.replace(":caseId", row.queryString),
							{
								state: {
									searchCriteria,
									backURL: AppRoutings.Dashboard.replace(
										":tabStatus",
										getTabStatusWiseName(
											searchCriteria?.RequestStatusId
										).toLocaleLowerCase()
									),
								},
							}
						);
					}}
				>
					<Link>
						<img src={HeartbeatIcon} alt="heart-beat" className="light-icon" />
						<img
							src={HeartbeatIconDark}
							alt="heart-beat"
							className="dark-icon"
						/>
						Conclude Care
					</Link>
				</MenuItem>
			) : null}
			{gridButtonsPermissions.isAccept ? (
				<MenuItem onClick={handleAcceptCaseModalOpen}>
					<Link>
						<img src={TasksIcon} alt="tasks" className="light-icon" />
						<img src={TasksDarkIcon} alt="tasks" className="dark-icon" />
						Accept
					</Link>
				</MenuItem>
			) : null}
			{gridButtonsPermissions.isAssignCase ? (
				<MenuItem onClick={handleAssignCaseModalOpen}>
					<Link>
						<img src={TasksIcon} alt="tasks" className="light-icon" />
						<img src={TasksDarkIcon} alt="tasks" className="dark-icon" />
						Assign Case
					</Link>
				</MenuItem>
			) : null}
			{(gridButtonsPermissions.isCancelCase &&
				(searchCriteria.RequestStatusId !== InformationTabTypes.Closed ||
					row.requestStatusId === RequestStatus.Cancelled ||
					row.requestStatusId === RequestStatus.CancelledByProvider ||
					row.requestStatusId === RequestStatus.CancelledByPatient)) ||
			gridButtonsPermissions.isReject ? (
				<MenuItem onClick={handleCancelCaseModalOpen}>
					<Link>
						<img src={ErrorDarkIcon} alt="error" className="light-icon" />
						<img src={ErrorDarkModeIcon} alt="error" className="dark-icon" />
						{rejectCase}
					</Link>
				</MenuItem>
			) : null}
			{userrole.userRoleId === AccountType.Admin &&
				row.requestStatusId === RequestStatus.Unassigned && (
					<MenuItem onClick={handleSendDefaultNotificationModalOpen}>
						<Link>
							<img src={ErrorDarkIcon} alt="error" className="light-icon" />
							<img src={ErrorDarkModeIcon} alt="error" className="dark-icon" />
							Send Busy Notification
						</Link>
					</MenuItem>
				)}
			{gridButtonsPermissions.isViewCase ? (
				<MenuItem
					onClick={() => {
						tokenManager.setYOffset(window.scrollY);

						navigate(AppRoutings.ViewCase.replace(":caseId", row.queryString), {
							state: {
								backURL: AppRoutings.Dashboard.replace(
									":tabStatus",
									getTabStatusWiseName(
										searchCriteria?.RequestStatusId
									).toLocaleLowerCase()
								),
								searchCriteria,
							},
						});
					}}
				>
					<Link>
						<img src={AnalysisIcon} alt="analysis" className="light-icon" />
						<img src={AnalysisDarkIcon} alt="analysis" className="dark-icon" />
						View Case
					</Link>
				</MenuItem>
			) : null}
			{gridButtonsPermissions.isViewUploads ? (
				<MenuItem
					onClick={() => {
						navigate(
							AppRoutings.ViewUploads.replace(":caseId", row.queryString),
							{
								state: {
									backURL: AppRoutings.Dashboard.replace(
										":tabStatus",
										getTabStatusWiseName(
											searchCriteria?.RequestStatusId
										).toLocaleLowerCase()
									),
									searchCriteria,
								},
							}
						);
					}}
				>
					<Link>
						<img src={UploadfileIcon} alt="files" className="light-icon" />
						<img src={UploadfileIconDark} alt="files" className="dark-icon" />
						View Uploads
					</Link>
				</MenuItem>
			) : null}
			{gridButtonsPermissions.isViewNotes ? (
				<MenuItem
					onClick={() => {
						navigate(
							AppRoutings.ViewNotes.replace(":caseId", row.queryString),
							{
								state: {
									searchCriteria,
									backURL: AppRoutings.Dashboard.replace(
										":tabStatus",
										getTabStatusWiseName(
											searchCriteria?.RequestStatusId
										).toLocaleLowerCase()
									),
								},
							}
						);
					}}
				>
					<Link>
						<img src={NotesIcon} alt="notes" className="light-icon" />
						<img src={NotesDarkIcon} alt="notes" className="dark-icon" />
						View Notes
					</Link>
				</MenuItem>
			) : null}

			{gridButtonsPermissions.isSkipAgreement &&
			(row.requestTypeId === RequestTypes.Business ||
				row.requestTypeId === RequestTypes.Concierge) ? (
				<MenuItem onClick={handleClickSkipAgreement}>
					<Link>
						<img src={DocnotesIcon} alt="notes" className="light-icon" />
						<img src={DocnotesIconDark} alt="notes" className="dark-icon" />
						Skip Agreement
					</Link>
				</MenuItem>
			) : null}

			{gridButtonsPermissions.isOrders ? (
				<MenuItem
					onClick={() => {
						navigate(AppRoutings.Orders.replace(":caseId", row.queryString), {
							state: {
								searchCriteria,
								backURL: AppRoutings.Dashboard.replace(
									":tabStatus",
									getTabStatusWiseName(
										searchCriteria?.RequestStatusId
									).toLocaleLowerCase()
								),
							},
						});
					}}
				>
					<Link>
						<img src={OrdersIcon} alt="orders" className="light-icon" />
						<img src={OrdersIconDark} alt="notes" className="dark-icon" />
						Orders
					</Link>
				</MenuItem>
			) : null}

			{gridButtonsPermissions.isTransfer ? (
				<MenuItem onClick={handleTransferCaseModalOpen}>
					<Link>
						<img src={TasksIcon} alt="tasks" className="light-icon" />
						<img src={TasksDarkIcon} alt="tasks" className="dark-icon" />
						Transfer
					</Link>
				</MenuItem>
			) : null}

			{gridButtonsPermissions.isBlockCase ? (
				<MenuItem onClick={handleBlockCaseModalOpen}>
					<Link>
						<img src={BlockIcon} alt="error" className="light-icon" />
						<img src={BlockDarkIcon} alt="error" className="dark-icon" />
						Block Patient
					</Link>
				</MenuItem>
			) : null}

			{gridButtonsPermissions.isCloseCase ? (
				<MenuItem
					onClick={() => {
						navigate(
							AppRoutings.CloseCase.replace(":caseId", row.queryString),
							{
								state: {
									searchCriteria,
									backURL: AppRoutings.Dashboard.replace(
										":tabStatus",
										getTabStatusWiseName(
											searchCriteria?.RequestStatusId
										).toLocaleLowerCase()
									),
								},
							}
						);
					}}
				>
					<Link>
						<img src={ErrorDarkIcon} alt="error" className="light-icon" />
						<img src={ErrorDarkModeIcon} alt="error" className="dark-icon" />
						Close Case
					</Link>
				</MenuItem>
			) : null}
			{gridButtonsPermissions.isDoctorNotes ? (
				<MenuItem
					onClick={() => {
						if (
							row.finalizedRecommendationFormName == "" ||
							userrole.userRoleId == AccountType.Admin
						) {
							navigate(
								AppRoutings.DoctorsNote.replace(":caseId", row.queryString),
								{
									state: {
										searchCriteria,
										backURL: AppRoutings.Dashboard.replace(
											":tabStatus",
											getTabStatusWiseName(
												searchCriteria?.RequestStatusId
											).toLocaleLowerCase()
										),
									},
								}
							);
						} else {
							handleAlreadyFinalizedModalOpen(2);
						}
					}}
				>
					<Link>
						<img src={DocnotesIcon} alt="notes" className="light-icon" />
						<img src={DocnotesIconDark} alt="notes" className="dark-icon" />
						Doctors Note
					</Link>
				</MenuItem>
			) : null}
			{gridButtonsPermissions.isClear &&
			row.requestStatusId !== RequestStatus.Cancelled &&
			row.requestStatusId !== RequestStatus.CancelledByProvider &&
			row.requestStatusId !== RequestStatus.CancelledByPatient ? (
				<MenuItem onClick={handleClearCaseModalOpen}>
					<Link>
						<img src={ClearCaseIcon} alt="notes" className="light-icon" />
						<img src={ClearCaseDarkIcon} alt="notes" className="dark-icon" />
						Clear Case
					</Link>
				</MenuItem>
			) : null}
			{gridButtonsPermissions.isSendAgreement &&
			userrole.userRoleId === AccountType.Admin ? (
				<MenuItem onClick={handleSendAgreemenrModalOpen}>
					<Link>
						<img src={DocnotesIcon} alt="notes" className="light-icon" />
						<img src={DocnotesIconDark} alt="notes" className="dark-icon" />
						Send Agreement
					</Link>
				</MenuItem>
			) : null}
			{gridButtonsPermissions.isEncounter ? (
				<MenuItem
					onClick={() => {
						if (
							row.isHouseCallOrCounsult != null ||
							(row.isHouseCallOrCounsult == null &&
								searchCriteria.RequestStatusId != InformationTabTypes.Active) ||
							userrole.userRoleId == AccountType.Admin
						) {
							if (
								row.finalizedEncounterFileName == "" ||
								userrole.userRoleId == AccountType.Admin
							) {
								navigate(
									AppRoutings.Encounter.replace(":caseId", row.queryString),
									{
										state: {
											searchCriteria,
											backURL: AppRoutings.Dashboard.replace(
												":tabStatus",
												getTabStatusWiseName(
													searchCriteria?.RequestStatusId
												).toLocaleLowerCase()
											),
										},
									}
								);
							} else {
								handleAlreadyFinalizedModalOpen(1);
							}
						} else {
							handleSelectCallTypeModalOpen();
						}
					}}
				>
					<Link>
						<img src={DocnotesIcon} alt="notes" className="light-icon" />
						<img src={DocnotesIconDark} alt="notes" className="dark-icon" />
						Encounter
					</Link>
				</MenuItem>
			) : null}
		</Menu>
	);
}
