import { Box, Button, Typography } from "@mui/material";
import { ArrowBack } from "assests/images";
import { SMSLogsTable } from "components";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import historyService from "services/history-service";
import { Constants } from "utility/enums/constants";
import { SortOrder } from "utility/enums/sort-order";
import { createCommonAPICall } from "utility/helpers";
import {
	IDropDownList,
	ISMSLogsInfo,
	ISMSLogsSearchCriteria,
} from "utility/interfaces";

const SMSLogs: React.FC = () => {
	// Use context
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// Page level variables
	const navigate = useNavigate();

	// use state
	const [searchCriteria, setSearchCriteria] = useState<ISMSLogsSearchCriteria>({
		roleId: 0,
		receiverName: "",
		mobileNumber: "",
		PageIndexId: 0,
		PageSize: Constants.DefaultTablePageSize.valueOf(),
		SortBy: "CreateDate",
		SortOrder: SortOrder.descending,
		SearchBy: "",
	});

	const [smsLogsInfo, setSMSLogsInfo] = useState<ISMSLogsInfo>({
		smsLogs: [],
		totalRecords: 0,
	});

	const [roles, setRoles] = useState<IDropDownList[]>([]);

	// Handle Events
	const handleOnClickBackCancel = () => navigate(-1);

	const handleOnSearchCriteriaChange = (
		newSearchCriteria: ISMSLogsSearchCriteria
	) => {
		setSearchCriteria(newSearchCriteria);
	};

	const getEmailLogs = async () => {
		let strCreatedDate = searchCriteria.createdDate
			? new Date(searchCriteria.createdDate).toLocaleString("en-us")
			: null;
		let strSentDate = searchCriteria.sentDate
			? new Date(searchCriteria.sentDate).toLocaleString("en-us")
			: null;

		const data = await createCommonAPICall({
			requestBody: {
				...searchCriteria,
				roleId: searchCriteria.roleId == 0 ? null : searchCriteria.roleId,
				receiverName:
					searchCriteria.receiverName == ""
						? null
						: searchCriteria.receiverName,
				emailId:
					searchCriteria.mobileNumber == ""
						? null
						: searchCriteria.mobileNumber,
				strCreatedDate:
					strCreatedDate == "Invalid Date" ? null : strCreatedDate,
				strSentDate: strSentDate == "Invalid Date" ? null : strSentDate,
			},
			apiService: historyService.getSMSLogs,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});
		if (data && data.isSuccessfull) {
			console.log(data);
			setSMSLogsInfo({
				smsLogs: data.data.smsLogs,
				totalRecords: data.data.totalRecords,
			});
			setRoles(data.data.roles);
		}
	};

	// Use Effects
	useEffect(() => {
		getEmailLogs();
	}, [searchCriteria]);

	return (
		<Box>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="tab-item-header">
					<Typography variant="h2">SMS Logs (Twilio)</Typography>
					<Button variant="outlined" onClick={handleOnClickBackCancel}>
						<img src={ArrowBack} alt="arrow" />
						Back
					</Button>
				</Box>
				<SMSLogsTable
					searchCriteria={searchCriteria}
					handleOnSearchCriteriaChange={handleOnSearchCriteriaChange}
					smsLogsInfo={smsLogsInfo}
					roles={roles}
				/>
			</main>
		</Box>
	);
};

export default SMSLogs;
