import { yupResolver } from "@hookform/resolvers/yup";
import {
	Box,
	Button,
	IconButton,
	Modal,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { commonModalStyle } from "utility/helpers";
import { IBlockCaseRequest, IRequest } from "utility/interfaces";
import { blockRequestSchema } from "utility/validations";
import dashboardService from "services/dashboard-service";
import { createCommonAPICall } from "utility/helpers";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";

type ComponentProps = {
	blockCaseModelOpen: boolean;
	handleBlockCaseModalClose: any;
	row: IRequest;
};

export function BlockCaseModal(props: ComponentProps) {
	// Usestates
	const [reason, setReason] = useState("");

	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// Extract Props
	const { blockCaseModelOpen, handleBlockCaseModalClose, row } = props;

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		reset,
	} = useForm({
		resolver: yupResolver(blockRequestSchema),
	});

	// Events and functions
	const handleOnClickConfirm = async () => {
		//console.log(row.email, reason);
		setReason("");
		const request: IBlockCaseRequest = {
			requestId: row.requestId,
			reasonsForBlockRequest: reason,
			phoneNumber: row.phone,
			email: row.email,
		};
		console.log(request);
		const data = await createCommonAPICall({
			requestBody: request,
			apiService: dashboardService.blockCase,
			showSuccessMessage: true,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.isSuccessfull) handleBlockCaseModalClose(true);
	};

	// useEffects
	useEffect(() => {
		reset();
		clearErrors();
		setReason("");
	}, [blockCaseModelOpen]);

	return (
		<>
			<Modal
				open={blockCaseModelOpen}
				onClose={handleBlockCaseModalClose}
				className="send-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">Confirm Block</Typography>
						<IconButton onClick={handleBlockCaseModalClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<div>
							<Typography variant="h6" className="patient-name">
								Patient Name :&nbsp;
								<span>{row.name}</span>
							</Typography>
							<TextField
								{...register("reason")}
								id="block"
								label="Reason for Block Request"
								value={reason}
								onChange={(e) => setReason(e.target.value)}
								multiline
								maxRows={1}
								inputProps={{ className: "min-h-88px" }}
								fullWidth
								error={errors?.reason?.message ? true : false}
								helperText={errors?.reason?.message?.toString()}
							/>
						</div>
						<div className="modal-footer">
							<Tooltip title="Confirm">
								<Button
									variant="contained"
									onClick={handleSubmit(handleOnClickConfirm)}
								>
									Confirm
								</Button>
							</Tooltip>
							<Tooltip title="Cancel">
								<Button variant="outlined" onClick={handleBlockCaseModalClose}>
									Cancel
								</Button>
							</Tooltip>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
