import React, { createContext, useState } from "react";
const PromptEventContext = createContext();
const PromptEventDispatchContext = createContext();

function PromptEventProvider({ children }) {
	const [promptEvent, setPromptEvent] = useState(null);
	return (
		<PromptEventContext.Provider value={promptEvent}>
			<PromptEventDispatchContext.Provider value={setPromptEvent}>
				{children}
			</PromptEventDispatchContext.Provider>
		</PromptEventContext.Provider>
	);
}

export { PromptEventProvider, PromptEventContext, PromptEventDispatchContext };
