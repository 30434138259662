import { Box, Button, Typography } from "@mui/material";
import { ArrowBack } from "assests/images";
import React from "react";
import { useNavigate } from "react-router-dom";
import { AppRoutings } from "utility/enums/app-routings";

const SubmitTimesheetSuccess: React.FC = () => {
	// Page level local variables
	const navigate = useNavigate();

	// Handle events
	const handleOnClickBackCancel = () => {
		navigate(AppRoutings.Dashboard.replace(":tabStatus", "new"));
	};

	return (
		<Box>
			<main className="main-content">
				<div
					className="overlay"
					onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
				></div>
				<Box className="request-container-box">
					<div className="request-header">
						<Typography variant="h2"></Typography>
						<Button variant="outlined" onClick={handleOnClickBackCancel}>
							<img src={ArrowBack} alt="arrow" />
							Back
						</Button>
					</div>
					<Box className="request-box">
						<div className="success-msg-container">
							<Typography variant="h1" align="center">
								Timesheet has been Submitted Successfully.
							</Typography>
						</div>
					</Box>
				</Box>
			</main>
		</Box>
	);
};

export default SubmitTimesheetSuccess;
